import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { setBaseUrl } from "../../config";

import Layout from "../Layout";
import { useToast } from "../../Context/ToastContext";
const SalesForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [form, setForm] = useState({
    email: "",
  });

  const handleSubmit = async () => {
    // console.log(form)

    setLoading(true); // Start Loading
    try {
      const response = await fetch(
        `${setBaseUrl}/sale-service/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(form),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      addToast(
        "Password has been sent successfully! Please check your email.",
        "success"
      );
      navigate("/sales-services/login");
    } catch (error) {
      console.error(error);
      addToast("Failed authentication! Please try again.", "error");
    }
    setLoading(false); // Stop Loading
  };

  return (
    <Layout>
      {/* Background Video */}
      <div className="relative w-full h-full">
        {/* Overlay */}
        <div className="absolute inset-0 flex items-center justify-center  bg-opacity-0">
          <div className="bg-white   w-[389.42px] h-[430.66px] rounded-lg shadow-lg">
            {/* Logo */}
            <div className="flex flex-col  text-left gap-3 items-start border-b-[1.5px] p-6 border-light">
              {/* Header */}
              <header className="text-[32px] font-bold">
                {" "}
                Forgot Password
              </header>
              <div className="flex flex-col ">
                <span className="font-extrabold text-[14px]">
                  Sales & Service
                </span>
                <span className="font-normal text-[12px]">
                  Enter your email below
                </span>
              </div>
            </div>

            <div className="flex flex-col p-6">
              {/* Email */}
              <div className="mt-4">
                <label htmlFor="email" className="block text-gray-600 text-sm">
                  Email Address
                </label>
                <input
                  title="Enter your email address"
                  type="email"
                  id="email"
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 mt-1 text-sm border rounded-lg focus:ring-2 focus:ring-blue-400 outline-none"
                />
              </div>

              {/* Register Button */}
              <button
                onClick={handleSubmit}
                title="click to get you password"
                className="w-full py-3 mt-6 text-white font-normal bg-green-2 rounded-lg hover:bg-green-3 transition-all duration-300"
              >
                Forgot Password
              </button>

              {/* Already Have an Account */}
              <p className="text-xs text-center mt-4">
                Back to login?{" "}
                <span
                  className="text-green-2 font-normal hover:underline cursor-pointer"
                  onClick={() => navigate("/sales-services/login/")}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SalesForgetPassword;
