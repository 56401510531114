import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import MaintenanceTable from "../../tables/MaintenanceTable/MaintenanceTable";
import MaintenanceTab from "./MaintenanceTab";
import { useNavigate, useParams } from "react-router-dom";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import { LoaderContext } from "../../../Context/LoaderContext";
import Loader from "../../Loader";
import LeaseTab from "./LeaseTab";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { PermissionContext } from "../../../Context/PermissionsContext";
import Documents from "../../InnerTabs/Documents";
import DeviceEvents from "../Telematics/DeviceEvents";
import Timeline from "../../Timeline";
import { NoImage } from "./Tabs/NoImage";
import AssetDashboards from "./Tabs/AssetDashboards";
import CommercialDetails from "./Tabs/CommercialDetails";
import PhotoData from "./Tabs/PhotoData";
import AssetCommercial from "./Tabs/AssetCommercial";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssetHeader from "./Tabs/AssetHeader";
import TimeLineDuration from "./TimeLineDuration.jsx";
import PhotoDataTab from "./bottomTabs/PhotoDataTab.jsx";
import RemittenceTable from "./Tabs/RemittenceTable.jsx";
import UserLogData from "./bottomTabs/UserLogData.jsx";
import { useGetAssetWorkingTimeline } from "../../../apis/AssetsApis/api.js";
import { Button } from "@mui/material";


function AssetDetails({ setShowCurrentTab }) {
  const [perms] = useContext(PermissionContext);
  const [loader, setLoader] = useContext(LoaderContext);
  const [assetDetails, setAssetBasicDetails] = useState({});
  const [commercialDetails, setcommercialDetails] = useState({});
  const [innerTab, setInnerTab] = useState(1);
  // Timeline Duration Reference

  const [imageUrl, setImageUrl] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [commercialDetailsExist, setcommercialDetailsExist] = useState(false);
  const [epocToHumanDate, setEpocToHumanDate] = useState("");
  // const [reload, setReload] = useState(false);
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [revenueView, setRevenueView] = useState(null);
  const [error, setError] = useState(null);

  let section = null;
  const [timeLine, setTimeLine] = useState({
    instance_time: {},
    working_time: [],
  });
  // console.log(sessionStorage.getItem("currentTab"))
  // let currentTab = sessionStorage.getItem("currentTab");
  // console.log(currentTab);

  useEffect(() => {
    const currentTab = sessionStorage.getItem("currentTab");

    if (currentTab === "5") {
      setRevenueView("revenue");
    } else {
      setRevenueView(null); // Reset to null if currentTab is not 5
    }
  }, [innerTab]);

  // console.log(revenueView)
  const getAssetDetails = async () => {
    // console.log(id);
    if (revenueView) {
      section = "revenue";
    } else {
      section = "asset_dashboard";
    }

    try {
      setLoader(true);
      setLoading(true);
      const response = await fetch(`${setBaseUrl}/asset/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
          section: revenueView || "asset_dashboard",
        },
      });

      const data = await response.json();
      // console.log(data);
      setDeviceId(data?.device_id);

      if (revenueView && response.status === 200) {
        setLoading(false);
      }

      if (section === "asset_dashboard") {
        setTimeLine({
          instance_time: data?.tele_data?.instance_data,
          working_time: data?.tele_data?.working_time,
        });
      }
      setEpocToHumanDate((prev) => {
        let humanDateFormat = new Date(0);
        humanDateFormat.setUTCSeconds(data?.created_at);
        return humanDateFormat;
      });
      if (data.commercial_detail !== undefined) {
        let lenOfCommDetail = Object.keys(data.commercial_detail).length;
        // if commercial details exist in data then we set commercial details state and set commercialDetailsExist to true
        if (lenOfCommDetail > 0) {
          setcommercialDetails(data.commercial_detail);
          setcommercialDetailsExist(true);
        }
      }
      // console.log(data?.photo_data[0]?.image_uri);
      setAssetBasicDetails(data);
      setImageUrl(data?.photo_data);
      setDocumentUrl(data?.attachment_data);
    } catch (error) {
      console.error(error);
      // setError(error)
      // setLoading(false)
      // alert("There having some issue please reload!!")
    } finally {
      setLoader(false);
    }
  };

  // console.log(commercialDetails[0].total_landed_cost);

  const handleCurrentTab = (tab) => {
    sessionStorage.setItem("currentTab", tab);
    setInnerTab(tab);
  };

  useEffect(() => {
    // console.log(typeof tab);
    if (tab == "true") {
      setInnerTab(1);
    } else {
      let getCurrentTabName = sessionStorage.getItem("currentTab");
      if (getCurrentTabName == 2) {
        setInnerTab(2);
      } else if (getCurrentTabName == 3) {
        setInnerTab(3);
      } else if (getCurrentTabName == 4) {
        setInnerTab(4);
      } else if (getCurrentTabName == 5) {
        setInnerTab(5);
      } else if (getCurrentTabName == 6) {
        setInnerTab(6);
      } else {
        setInnerTab(1);
      }
    }
    getAssetDetails();
  }, [innerTab]);

  const [showMachine, setShowMachine] = useState(false);
  const [bottomTab, setBottomTab] = useState(1); // Default tab
  const { bottom } = useParams();

  const handleBottomTab = (tab) => {
    setBottomTab(tab);
    sessionStorage.setItem("bottomTab", tab);
  };

  // useEffect to initialize the tab state based on session storage or URL params
  useEffect(() => {
    let storedTab = sessionStorage.getItem("bottomTab");

    if (bottom === "true") {
      setBottomTab(1); // Override with tab 1 if bottom is true
    } else if (storedTab) {
      setBottomTab(Number(storedTab)); // Set tab based on session storage
    }
  }, [bottom]);

  // console.log(imageUrl)

  let imageArray = [];
  if (imageUrl) {
    for (let i = 0; i < imageUrl.length; i++) {
      imageArray.push(imageUrl[i]);
    }
  }

  const [photoDataTab, setPhotoDataTab] = useState(false)

  useEffect(() => {
    if (photoDataTab) {
      // Smooth scroll to the bottom tab with an ID of 'photos'
      const photosElement = document.getElementById("photos");
      if (photosElement) {
        photosElement.scrollIntoView({ behavior: "smooth" });
      }
    }

  if(showMachine) {
    const timeElement = document.getElementById("timeline");
    if(timeElement) {
      timeElement.scrollIntoView({ behavior: "smooth" });
    }
  }
  }, [photoDataTab, showMachine]);

  // const {timeLine} = useGetAssetWorkingTimeline(`${setBaseUrl}/asset/get_data_by_id`, id, )

  return (
    // <>
    //   <div className="">
    //     {/* Next Item  */}
    //     <AssetHeader assetDetails={assetDetails} />
    //     <div className=" ">
    //       <div
    //         class="nav-tabs flex justify-between text-xs "
    //       >

    //         <div className="flex justify-between">
    // <span
    //   onClick={() => handleCurrentTab(1)}
    //   className={`asset-details-tab py-2 sm:mr-8 cursor-pointer   hover:scale-105  transition-transform duration-300  dark:text-white
    //     ${innerTab === 1 ? "assetDetailsActive" : ""}`}
    // >
    //   Dashboard
    // </span>
    // {perms.indexOf("ADMIN.ALL") > -1 ? (
    //   <>
    //     <span
    //       onClick={() => handleCurrentTab(2)}
    //       className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white
    //      ${innerTab === 2 ? "assetDetailsActive" : ""}`}
    //     >
    //       Commercial Details
    //     </span>
    //   </>
    // ) : (
    //   ""
    // )}

    // <span
    //   onClick={() => handleCurrentTab(3)}
    //   className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
    //     innerTab === 3 ? "assetDetailsActive" : ""
    //   }`}
    // >
    //   Maintenance
    // </span>
    // <span
    //   onClick={() => handleCurrentTab(4)}
    //   className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
    //     innerTab === 4 ? "assetDetailsActive" : ""
    //   }`}
    // >
    //   Lease
    // </span>

    // {perms.indexOf("ADMIN.ALL") > -1 ? (
    //   <>
    //     {commercialDetails.length > 0 && commercialDetails !== null ? (
    //       <>
    //         <span
    //           onClick={() => {
    //             handleCurrentTab(5);
    //             setRevenueView("revenue");
    //           }}
    //           className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
    //             innerTab === 5 ? "assetDetailsActive" : ""
    //           }`}
    //         >
    //           Revenue
    //         </span>
    //       </>
    //     ) : (
    //       ""
    //     )}
    //   </>
    // ) : (
    //   ""
    // )}

    //         </div>
    //         <div className="flex">
    //           <button>Back</button>
    //           <button className="">Edit</button>
    //         </div>
    //       </div>

    //     {loader ? (
    //       <Loader />
    //     ) : (
    //       <>
    //         <div className=" mt-5 ">
    // {/* tab 1 */}
    // {innerTab === 1 && (
    //   <>
    //     <>
    //       {/* Asset Details Data */}

    //       {/* Photos Data */}
    //       <div className="flex justify-start gap-3 flex-col md:flex-row ">
    //         <CommercialDetails
    //           assetDetails={assetDetails}
    //           imageUrl={imageUrl}
    //           NoImage={NoImage}
    //         />

    //         <div className="flex flex-col ">
    //           <DeviceEvents devId={deviceId} />

    //           {deviceId && (
    //             <>
    //               <Link
    //                 to="timeline"
    //                 smooth
    //                 duration={500}
    //                 className=""
    //               >
    //                 <Timeline
    //                   devId={deviceId}
    //                   setShowMachine={setShowMachine}
    //                   handleBottomTab={handleBottomTab}
    //                   timeLine={timeLine}
    //                 />
    //               </Link>
    //             </>
    //           )}
    //         </div>
    //       </div>
    //       <hr className="w-screen h-1 text-dark-6" />
    //       {/* Bottom Tabs */}
    //       <div className="flex items-center gap-4 text-xs pl-2 mt-[15px] pb-20 text-dark-6 ">
    //         <Link to="photos">
    //           <button
    //             className={` px-0 py-1 ${
    //               bottomTab === 1 ? "border-b border-dark-6 " : ""
    //             }`}
    //             onClick={() => handleBottomTab(1)}
    //           >
    //             Photos & Videos
    //           </button>
    //         </Link>
    //         <button
    //           className={` px-0 py-1 ${
    //             bottomTab === 2 ? "border-b border-dark-6 " : ""
    //           }`}
    //           onClick={() => handleBottomTab(2)}
    //         >
    //           Documents
    //         </button>
    //         <button
    //           className={` px-0 py-1 ${
    //             bottomTab === 4 ? "border-b border-dark-6 " : ""
    //           }`}
    //           onClick={() => {
    //             handleBottomTab(4);
    //             setShowMachine(true);
    //           }}
    //         >
    //           Working Time
    //         </button>
    //         {/* <button
    //           className={`border px-4 py-1 ${
    //             bottomTab === 5 ? "bg-[#8a8989] text-white" : ""
    //           }`}
    //           onClick={() => {
    //             handleBottomTab(5);
    //             setShowMachine(true);
    //           }}
    //         >
    //          Engine Runtime
    //         </button> */}
    //         <button
    //           className={` px-0 py-1 ${
    //             bottomTab === 3 ? "border-b border-dark-6 " : ""
    //           }`}
    //           onClick={() => handleBottomTab(3)}
    //         >
    //           Log Data
    //         </button>
    //       </div>
    //       {bottomTab === 1 && (
    //         <>
    //           <div id="photos">
    //             <PhotoDataTab
    //               imageArray={imageArray}
    //               assetName={assetDetails?.asset_no}
    //             />
    //           </div>
    //         </>
    //       )}

    //       {bottomTab === 2 && (
    //         <>
    //           <Documents documentUrl={documentUrl} />
    //         </>
    //       )}

    //       {bottomTab === 3 && (
    //         <>
    //           <UserLogData id={assetDetails.id} />
    //         </>
    //       )}

    //       {bottomTab === 4 && deviceId && showMachine && (
    //         <>
    //           <div id="timeline">
    //             <TimeLineDuration
    //               devId={deviceId}
    //               setShowMachine={setShowMachine}
    //               timeLine={timeLine}
    //             />
    //           </div>
    //         </>
    //       )}
    //     </>
    //   </>
    // )}

    // {/* tab 2 */}
    // {perms.indexOf("ADMIN.ALL") > -1 ? (
    //   <>
    //     {innerTab === 2 && (
    //       <>
    //         <div
    //           id="assetD-dashboard"
    //           class="assetD-tabs flex flex-col justify-between space-y-2 mt-4 mb-16 "
    //         >
    //           {/*Left column  */}

    //           <AssetCommercial
    //             commercialDetails={commercialDetails}
    //             epocToHumanDate={epocToHumanDate}
    //           />
    //         </div>
    //       </>
    //     )}
    //   </>
    // ) : (
    //   ""
    // )}

    // {/* tab 3 */}
    // {innerTab === 3 && (
    //   <div id="assetD-maintenance" className="assetD-tabs mt-4">
    //     <div className="text-right dark:text-white">
    //       <NewAssetBtn tabName="maintenance" />
    //     </div>
    //     <MaintenanceTab asset_id={id} />
    //   </div>
    // )}

    // {/* tab 4 */}
    // {innerTab === 4 && (
    //   <div
    //     id="assetD-maintenance"
    //     className="text-black assetD-tabs"
    //   >
    //     <div className="text-right">
    //       <NewAssetBtn tabName="lease" />
    //     </div>
    //     <LeaseTab asset_id={id} />
    //   </div>
    // )}

    // {commercialDetails !== null ? (
    //   <>
    //     {perms.indexOf("ADMIN.ALL") > -1 ? (
    //       <>
    //         {innerTab === 5 && (
    //           <>
    //             {loading ? (
    //               "loading..."
    //             ) : (
    //               <>
    //                 {" "}
    //                 <AssetDashboards
    //                   commercialDetails={commercialDetails[0]}
    //                 />
    //                 {assetDetails?.revnew_data && (
    //                   <RemittenceTable
    //                     revnew_data={assetDetails.revnew_data}
    //                     asset_sold_date={
    //                       assetDetails.asset_sold_date
    //                     }
    //                   />
    //                 )}
    //               </>
    //             )}
    //           </>
    //         )}
    //       </>
    //     ) : (
    //       ""
    //     )}
    //   </>
    // ) : (
    //   ""
    // )}
    //         </div>

    //       </>
    //     )}
    //   </div>
    // </div>

    //   <div className="flex items-end justify-end z-[9999] space-x-4 h-12 border bg-white fixed bottom-0 pb-2 left-0 w-screen">
    //     <div className="flex items-center gap-2 mr-6">
    // {(perms.indexOf("ADMIN.ALL") > -1 ||
    //   perms.indexOf("ASSETS.ALL") > -1 ||
    //   perms.indexOf("ASSETS.CRU") > -1) && (
    //   <>
    //     <button
    //       className="bg-blue-2 text-white px-3 py-1 text-xs rounded
    //         "
    //       onClick={() => navigate("/assets/updateAsset/" + id)}
    //     >
    //       Edit
    //     </button>
    //   </>
    // )}
    //     </div>
    //   </div>
    // </>
    <>
      <div className=" px-2">
        <div className="">
          <AssetHeader assetDetails={assetDetails} loading={loading} />

          {/* Button Header */}
          <div className="flex justify-between gap-6 mt-2  ">
            <div className="text-[10px] items-center flex justify-start gap-3  w-full">
              <span
                onClick={() => handleCurrentTab(1)}
                className={`asset-details-tab py-2 sm:mr-8 cursor-pointer   hover:scale-105  transition-transform duration-300  dark:text-white 
                ${innerTab === 1 ? "assetDetailsActive " : ""}`}
              >
                Dashboard
              </span>
              {perms.indexOf("ADMIN.ALL") > -1 ? (
                <>
                  <span
                    onClick={() => handleCurrentTab(2)}
                    className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white
                 ${innerTab === 2 ? "assetDetailsActive " : ""}`}
                  >
                    Commercial Details
                  </span>
                </>
              ) : (
                ""
              )}

              <span
                onClick={() => handleCurrentTab(3)}
                className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                  innerTab === 3 ? "assetDetailsActive " : ""
                }`}
              >
                Maintenance
              </span>
              <span
                onClick={() => handleCurrentTab(4)}
                className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                  innerTab === 4 ? "assetDetailsActive " : ""
                }`}
              >
                Lease
              </span>

              {perms.indexOf("ADMIN.ALL") > -1 ? (
                <>
                  {commercialDetails.length > 0 &&
                  commercialDetails !== null ? (
                    <>
                      <span
                        onClick={() => {
                          handleCurrentTab(5);
                          setRevenueView("revenue");
                        }}
                        className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                          innerTab === 5 ? "assetDetailsActive " : ""
                        }`}
                      >
                        Revenue
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>

            {/* Buttons */}
            <div className="pr-4 flex gap-4 justify-between max-sm:fixed max-sm:bottom-0 max-sm:bg-white max-sm:border max-sm:left-0 max-sm:right-0 max-sm:py-2 max-sm:z-[9999] max-sm:justify-end">
              <Button
              onClick={() => navigate('/assets')}
              variant="outlined"
              sx={{
                fontSize: "12px",
                color: "#7C7C7C",
                borderColor: '#7C7C7C',
                textTransform: "none", // To disable uppercase text transformation
              }}
              >
                Back
              </Button>
              {(perms.indexOf("ADMIN.ALL") > -1 ||
                perms.indexOf("ASSETS.ALL") > -1 ||
                perms.indexOf("ASSETS.CRU") > -1) && (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      color: "white",
                      textTransform: "none", // To disable uppercase text transformation
                    }}
                    onClick={() => navigate("/assets/updateAsset/" + id)}
                  >
                    Edit
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        {/* details page  */}
       

        {loader ? (
          <Loader />
        ) : (
          <div className="mt-5">
            {/* tab 1 */}
            {innerTab === 1 && (
              <>
                <>
                  {/* Asset Details Data */}

                  {/* Photos Data */}
                  <div className="flex justify-between gap-1 flex-row max-sm:flex-col">
                    <CommercialDetails
                      assetDetails={assetDetails}
                      imageUrl={imageUrl}
                      NoImage={NoImage}
                      handleBottomTab={handleBottomTab}
                      setPhotoDataTab={setPhotoDataTab}
                    />

                    <div className="flex flex-col">
                      <DeviceEvents devId={deviceId} />

                      {deviceId && (
                        <>
                          <Link
                            to="timeline"
                            smooth
                            duration={500}
                            className=""
                          >
                            <Timeline
                              devId={deviceId}
                              setShowMachine={setShowMachine}
                              handleBottomTab={handleBottomTab}
                              timeLine={timeLine}
                            />
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                  <hr className="w-full h-1 text-dark-6" />
                  {/* Bottom Tabs */}
                  <div className="flex items-center gap-4 text-xs pl-2 mt-[15px] pb-20 text-dark-6 ">
                    <Link to="photos">
                      <button
                        className={` px-0 py-1 ${
                          bottomTab === 1 ? "border-b border-dark-6 " : ""
                        }`}
                        onClick={() => {handleBottomTab(1); setPhotoDataTab(true)}}
                      >
                        Photos & Videos
                      </button>
                    </Link>
                    <button
                      className={` px-0 py-1 ${
                        bottomTab === 2 ? "border-b border-dark-6 " : ""
                      }`}
                      onClick={() => handleBottomTab(2)}
                    >
                      Documents
                    </button>
                    <button
                      className={` px-0 py-1 ${
                        bottomTab === 4 ? "border-b border-dark-6 " : ""
                      }`}
                      onClick={() => {
                        handleBottomTab(4);
                        setShowMachine(true);
                      }}
                    >
                      Working Time
                    </button>
                    {/* <button
                          className={`border px-4 py-1 ${
                            bottomTab === 5 ? "bg-[#8a8989] text-white" : ""
                          }`}
                          onClick={() => {
                            handleBottomTab(5);
                            setShowMachine(true);
                          }}
                        >
                         Engine Runtime
                        </button> */}
                    <button
                      className={` px-0 py-1 ${
                        bottomTab === 3 ? "border-b border-dark-6 " : ""
                      }`}
                      onClick={() => handleBottomTab(3)}
                    >
                      Log Data
                    </button>
                  </div>
                  
                  {bottomTab === 1 && photoDataTab &&  (
                    <>
                      <div id="photos">
                        <PhotoDataTab
                          imageArray={imageArray}
                          assetName={assetDetails?.asset_no}
                        />
                      </div>
                    </>
                  )}

                  {bottomTab === 2 && (
                    <>
                      <Documents documentUrl={documentUrl} />
                    </>
                  )}

                  {bottomTab === 3 && (
                    <>
                      <UserLogData id={assetDetails.id} />
                    </>
                  )}

                  {bottomTab === 4 && deviceId && showMachine && (
                    <>
                      <div id="timeline">
                        <TimeLineDuration
                          devId={deviceId}
                          setShowMachine={setShowMachine}
                          timeLine={timeLine}
                        />
                      </div>
                    </>
                  )}
                </>
              </>
            )}

            {/* tab 2 */}
            {perms.indexOf("ADMIN.ALL") > -1 ? (
              <>
                {innerTab === 2 && (
                  <>
                    <div
                      id="assetD-dashboard"
                      class="assetD-tabs flex flex-col justify-between space-y-2 mt-4 mb-16 "
                    >
                      {/*Left column  */}

                      <AssetCommercial
                        commercialDetails={commercialDetails}
                        epocToHumanDate={epocToHumanDate}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {/* tab 3 */}
            {innerTab === 3 && (
              <div id="assetD-maintenance" className="assetD-tabs mt-4">
                <div className="text-right dark:text-white">
                  <NewAssetBtn tabName="maintenance" />
                </div>
                <MaintenanceTab asset_id={id} />
              </div>
            )}

            {/* tab 4 */}
            {innerTab === 4 && (
              <div id="assetD-maintenance" className="text-black assetD-tabs">
                <div className="text-right">
                  <NewAssetBtn tabName="lease" />
                </div>
                <LeaseTab asset_id={id} />
              </div>
            )}

            {commercialDetails !== null ? (
              <>
                {perms.indexOf("ADMIN.ALL") > -1 ? (
                  <>
                    {innerTab === 5 && (
                      <>
                        {loading ? (
                          <>
                          <div className="flex justify-center items-center">
                  Loading..
                          </div>
                          </>
                        ) : !commercialDetails[0]? "No Data Available" : (
                          <>
                          <div className="flex flex-col gap-6">

                            {" "}
                            <AssetDashboards
                              commercialDetails={commercialDetails[0]}
                              />
                            {assetDetails?.revnew_data && (
                              <RemittenceTable
                              revnew_data={assetDetails.revnew_data}
                              asset_sold_date={assetDetails.asset_sold_date}
                              />
                            )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AssetDetails;
