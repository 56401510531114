import React, { useEffect, useState } from "react";
import { get_log_table_data } from "../../../apis/LeaseApis";
import {
  convertToHoursAndMinutes,
  currency,
  currencyInLakhs,
  formatDate,
  formatMonth,
} from "../AssetDetails/Tabs/FormatDate";
import CreateLogTable from "./CreateLogTable";

function LogTable({ rso_id, asset_no, setRunDuration , leaseId}) {


  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState(true);
  const [expandedSections, setExpandedSections] = useState([]);

  const fetchLogTableData = async () => {
    try {
      setLoading(true);
      const { data } = await get_log_table_data(rso_id);
      setTableData(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLogTableData();
  }, []);

  let table = tableData[0] || {};
  let keys = Object.keys(table);
  let totalAmount = tableData[1] ? Object.values(tableData[1]).reduce((sum, amt) => sum + amt, 0) : 0;

  setRunDuration(convertToHoursAndMinutes(totalAmount));

  let calculatedOdooAmount = 0;
  let overtimeAmount = 0;

  const toggleSection = (index) => {
    setExpandedSections((prev) => {
      const newExpandedSections = [...prev];
      newExpandedSections[index] = !newExpandedSections[index];
      return newExpandedSections;
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[50vh]">
        <div className="animate-pulse w-full max-w-lg p-5 text-center border rounded-xl shadow-lg bg-gray-300">
          Loading data...
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-end justify-end gap-2 mt-5">
        <button
          className="text-white bg-light-1 w-[90px] py-1 text-xs rounded"
          onClick={() => setViewData(false)}
        >
          Add New
        </button>
        <button
          className="text-white bg-light-1 w-[90px] py-1 text-xs rounded"
          onClick={() => setViewData(true)}
        >
          View
        </button>
      </div>

      {viewData ? (
        <div className="mt-5">
          <div className="p-3 mb-5 text-xs text-gray-800 border">
            Total Working Time: {convertToHoursAndMinutes(totalAmount)}
          </div>

          {keys.reverse().map((key, index) => {
            const { formattedMonth, year } = formatMonth(key);
            let monthData = table[key] || [];

            return (
              <div key={index} className="mb-4 border ">
                <div
                  onClick={() => toggleSection(index)}
                  className="flex items-center justify-between px-4 py-2 bg-[#EEE] cursor-pointer"
                >
                  <div className="text-sm font-semibold">
                    {formattedMonth}, {year}
                  </div>
                  <div className="flex gap-2 text-xs">
                    <span>Total Time: {convertToHoursAndMinutes(totalAmount)}</span>
                    <span>Total Billing Amount: ₹{currency(calculatedOdooAmount)}</span>
                    <span>Total Overtime Amount: ₹{currencyInLakhs(overtimeAmount)}</span>
                  </div>
                </div>

                {expandedSections[index] && (
                  <div className="overflow-x-auto text-xs">
                    <table className="w-full table-auto text-center">
                      <thead className="bg-gray-100">
                        <tr>
                          <th className="p-2 border">Date</th>
                          <th className="p-2 border">Day Type</th>
                          <th className="p-2 border">Time In</th>
                          <th className="p-2 border">Time Out</th>
                          <th className="p-2 border">Total Time</th>
                          <th className="p-2 border">Overtime</th>
                          <th className="p-2 border">Time Adjust</th>
                          <th className="p-2 border">Odoo Bill Amount</th>
                          <th className="p-2 border">Overtime Amount</th>
                          <th className="p-2 border">Operator 1</th>
                        </tr>
                      </thead>
                      <tbody>
                        {monthData.map((item, i) => {
                          calculatedOdooAmount += item.x_studio_normal_bill_amount;
                          overtimeAmount += item.x_studio_overtime_amount;

                          return (
                            <tr key={i} className="border-b">
                              <td className="p-2 border">
                                <input
                                  value={formatDate(item.x_studio_date)}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 border">
                                <input
                                  value={item.x_studio_day_type}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 border">
                                <input
                                  value={item.x_studio_time_in}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 border">
                                <input
                                  value={item.x_studio_time_out}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 border">
                                <input
                                  value={item.x_studio_total_time}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 border">
                                <input
                                  value={item.x_studio_overtime}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 border">
                                <input
                                  value={item.x_studio_time_adjust}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 border">
                                <div className="flex items-center justify-center">
                                  <input
                                    value={parseFloat(item.x_studio_normal_bill_amount).toFixed(2)}
                                    className="w-full text-right focus:outline-none"
                                  />
                                  <span className="pl-1">₹</span>
                                </div>
                              </td>
                              <td className="p-2 border">
                                <div className="flex items-center justify-center">
                                  <input
                                    value={parseFloat(item.x_studio_overtime_amount).toFixed(2)}
                                    className="w-full text-right focus:outline-none"
                                  />
                                  <span className="pl-1">₹</span>
                                </div>
                              </td>
                              <td className="p-2 border">
                                <input
                                  value={item.x_studio_operator1}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <CreateLogTable rso_id={rso_id} asset_no={asset_no}  leaseId={leaseId} tableData={tableData[0]}/>
      )}
    </>
  );
}

export default LogTable;
