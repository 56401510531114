// import React, { useState } from "react";

// const PhotoData = ({ imageUrl, NoImage, handleBottomTab, setPhotoDataTab, title }) => {
//   const [openPhoto, setOpenPhoto] = useState(false);

  
// const handlePhotoTabClick = () => {
//   handleBottomTab(1);
//   setPhotoDataTab(true);
// };

//   return (
//     <>
//       {/* Container */}
//       <div className="flex flex-col items-center justify-start h-[200px] w-[247px]">
//         {imageUrl && imageUrl.length > 0 ? (
//           <>
//             {/* Image Display */}
//             <div className="flex items-center justify-start w-full p-1 h-[200px]  border-gray-200 bg-white rounded-l-[22px]  overflow-hidden">
//               <img
//                 src={imageUrl[imageUrl.length - 1]?.image_uri || NoImage[0]?.imgUrl}
//                 alt="Device Images"
//                 title={title}
//                 className="object-contain w-full h-full cursor-pointer"
//                 onClick={handlePhotoTabClick}

//               />
//             </div>

          
//           </>
//         ) : (
//           <>
//             {/* Placeholder for No Image */}
//             <div className="flex items-center justify-start w-full p-1  border  h-[200px]  rounded-l-[22px] bg-gray-50">
//               <img
//                 src={NoImage[0]?.imgUrl}
//                 alt="No Image"
//                 className="object-contain w-full h-full"
//               />
//             </div>
//           </>
//         )}
//       </div>

//       {/* Modal for Viewing Image */}
//       {openPhoto && (
//         <>
//           {/* Modal Overlay */}
//           <div
//             className="fixed inset-0 bg-black bg-opacity-50 z-40"
//             onClick={() => setOpenPhoto(false)}
//           ></div>

//           {/* Modal Content */}
//           <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-6 z-50 max-w-md w-full">
//             <div className="flex flex-col items-center space-y-4">
//               {/* Full-size Image */}
//               <div className="w-full h-96 overflow-hidden">
//                 <img
//                   src={imageUrl[imageUrl.length - 1]?.image_uri || NoImage[0]?.imgUrl}
//                   alt="Device Images"
//                   className="object-contain w-full h-full"
//                 />
//               </div>

//               {/* Close Button */}
//               <button
//                 onClick={() => setOpenPhoto(false)}
//                 className="bg-red-500 text-white font-semibold text-sm px-4 py-2 rounded hover:bg-red-600 transition"
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </>
//       )}
//     </>
//   );
// };

// export default PhotoData;


import React, { useState } from "react";
import { FiX, FiZoomIn } from "react-icons/fi";

const PhotoData = ({ imageUrl, NoImage, handleBottomTab, setPhotoDataTab, title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePhotoTabClick = () => {
    handleBottomTab(1);
    setPhotoDataTab(true);
  };

  const openImageModal = (index = 0) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const mainImageUrl = imageUrl?.length > 0 
    ? imageUrl[imageUrl.length - 1]?.image_uri 
    : NoImage[0]?.imgUrl;

  return (
    <div className="relative group h-[200px] mt-3 p-2 pl-4 overflow-hidden w-[247px] flex-shrink-0">
      {/* Main Image Container */}
      <div 
        className={`h-full w-full  overflow-hidden  ${
          imageUrl?.length > 0 ? "bg-transparent" : ""
        }`}
      >
        {/* Image with hover effect */}
        <div className="relative h-full w-full  rounded-[12px] overflow-hidden">
          <img
            src={mainImageUrl}
            alt={title || "Asset image"}
            className="object-contain w-full h-full cursor-pointer transition-transform duration-300 scale-110"
            onClick={() => imageUrl?.length > 0 ? openImageModal(imageUrl.length - 1) : handlePhotoTabClick()}
          />
          
          {/* View indicator for images */}
          {imageUrl?.length > 0 && (
            <div 
              className="absolute bottom-2 right-2 bg-black bg-opacity-60 text-white text-xs px-2 py-1 rounded-full flex items-center"
              onClick={(e) => {
                e.stopPropagation();
                handlePhotoTabClick();
              }}
            >
              <FiZoomIn className="mr-1" size={14} />
              {imageUrl.length} {imageUrl.length === 1 ? "photo" : "photos"}
            </div>
          )}
        </div>
      </div>

      {/* Image Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
          {/* Backdrop */}
          <div 
            className="absolute inset-0 bg-black bg-opacity-75"
            onClick={() => setIsModalOpen(false)}
          />
          
          {/* Modal Content */}
          <div className="relative bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] flex flex-col">
            {/* Modal Header */}
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="font-medium text-lg">{title || "Asset Image"}</h3>
              <button 
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <FiX size={24} />
              </button>
            </div>
            
            {/* Main Image */}
            <div className="flex-1 overflow-hidden flex items-center justify-center p-4">
              <img
                src={imageUrl[currentImageIndex]?.image_uri}
                alt={`${title} - ${currentImageIndex + 1} of ${imageUrl.length}`}
                className="max-h-[70vh] object-contain"
              />
            </div>
            
            {/* Thumbnail Gallery */}
            {imageUrl.length > 1 && (
              <div className="p-4 border-t bg-gray-50 overflow-x-auto">
                <div className="flex space-x-2">
                  {imageUrl.map((img, index) => (
                    <div 
                      key={index}
                      className={`flex-shrink-0 w-16 h-16 border-2 rounded cursor-pointer overflow-hidden ${
                        currentImageIndex === index ? "border-blue-500" : "border-transparent"
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                    >
                      <img
                        src={img.image_uri}
                        alt={`Thumbnail ${index + 1}`}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoData;