import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { TableLoader } from "../../TableLoader";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { useContext } from "react";
import telematicsLogo from "../../../assets/images/telematics/telematics.svg";
import { IoFilter } from "react-icons/io5";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";
import "./currentitems.css";
import { sortAssetDetails, toggleSortOrder } from "../../../utils/sortUtils";
export const CurrentItems = ({
  assetDetails,
  setLoader,
  deleted,
  setDeleted,
  selected,
  selectedItem,
  selectTableData,

  selectedCategory,
  selectedYear,
  device,
  openSold,
  sortHeader,
  sort,
  setSort,
  sortOrder,
  setSortOrder,
  filteredDataStack,
  setFilteredDataStack,
  // sortedAssetDetails,
  // setShortAssetDetails
  setSortedAssetDetails,
}) => {
  const navigate = useNavigate();
  const [tableAnimation, setTableAnimation] = useState(false);
  const openAssetDetails = (id, telematics = false) => {
    if (telematics) navigate("/assets/AssetDetails/" + id + "/" + telematics);
    else navigate("/assets/AssetDetails/" + id);
  };

  const openAssetUpdateTab = (id) => {
    navigate("updateAsset/" + id);
  };

  const deleteAsset = async (id) => {
    try {
      setLoader(true);
      const { data } = axios.delete(
        `${setBaseUrl}/asset/delete`,
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
      setDeleted((prev) => !prev);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoader(false);
      return;
    }
  };

  useEffect(() => {
    // console.log(assetDetails)
    setTimeout(() => {
      setTableAnimation(true);
    }, 400);
  }, [deleted]);

  // sortedAssetDetails = [...assetDetails].sort((a, b) => {
  //   if (!sort) return 0; // No sorting if sort is null

  //   const aValue =
  //     a[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || ""; // Convert the sort string to match the object key
  //   const bValue =
  //     b[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";

  //   if (aValue < bValue) {
  //     return sortOrder === "asc" ? -1 : 1;
  //   }
  //   if (aValue > bValue) {
  //     return sortOrder === "asc" ? 1 : -1;
  //   }
  //   return 0;
  // });

  // const toggleSortOrder = () => {
  //   setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  // };

  const sortedAssetDetails = sortAssetDetails(assetDetails, sort, sortOrder);

  // I will create a empty stack of the size which is selecting and will display the stack and after null i will empty the stack

  let filteredData = [];

  // Storing the filtered data into the stack

  useEffect(() => {
    // console.log(sortedAssetDetails);
    // console.log(selectedItem[1])

    filteredData = sortedAssetDetails.filter((data) => {
      return (
        // (selectedItem === "all Assets" ||
        //   (data?.lease_status.indexOf(selectedItem) !== -1 &&
        //     selectedItem[1][0] !== "active") ||
        //   ((data?.lease_status === "active" ||
        //     data?.lease_status.startsWith("expiring")) &&
        //     selectedItem[1][0] === "active") ||
        //   (selectedItem[1] === "expired" && data?.lease_status === "expired") ||
        //   (data?.lease_status === "inactive" &&
        //     data?.lease_status === "inactive") ||
        //   (data?.lease_status === "never assigned" &&
        //     data?.lease_status === "never assigned")) 
            (selectedItem === "all Assets"
              ||
              (data?.lease_status.indexOf(selectedItem[1][0]) !== -1 &&  selectedItem[1][0] !== "active") || 
              ((data?.lease_status === "active" || data?.lease_status.startsWith("expiring")) && selectedItem[1][0] === "active") || 
              (data?.lease_status === selectedItem[1] )
            )
           
            
            &&
        (device === "all device" ||
          (data?.device_no && device === "tracker installed")) &&
        (selectedYear === null ||
          data?.yom?.indexOf(selectedYear) !== -1 ||
          selectedYear === `${data?.yom}`) &&
        (selectedCategory === null ||
          data?.category.indexOf(selectedCategory) !== -1 ||
          selectedCategory === `${data?.category}`) &&
        (openSold === null ||
          openSold === `${data?.is_sold}` ||
          `${data?.is_sold}`.indexOf(openSold) !== -1)
      );
    });

    // Storing the filtered data into our stack
    setFilteredDataStack(filteredData);
  }, [
    // sortedAssetDetails,
    selectedItem,
    device,
    selectedYear,
    selectedCategory,
    openSold,
  ]);

  // input select
  // const [items, setItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const handleSelectAllChange = (event) => {
    const newAllSelected = event.target.checked;
    setAllSelected(newAllSelected);

    const updatedItems = assetDetails.map((item) => ({
      ...item,
      selected: newAllSelected,
    }));
    setFilteredDataStack(updatedItems);
  };

const handleItemChange = (itemId) => {
  const updatedItems = filteredDataStack.map((item) =>
    item.id === itemId ? { ...item, selected: !item.selected } : item
  );

  setFilteredDataStack(updatedItems);

  // Update `allSelected` if all items are selected after the change
  setAllSelected(updatedItems.every((item) => item.selected));
};

  

  return (
    <>
      {/* So if we want to create in this way then  */}
      <div className="  w-full h-[74vh] my-4 overflow-hidden border hide-scrollbar  max-lg:overflow-x-auto overflow-y-auto">
        {assetDetails === undefined || assetDetails.length === 0 ? (
          <>
            <div className="text-xs h-[50vh] flex rounded-lg justify-center items-center dark:text-white">
              No Data available
            </div>
          </>
        ) : (
          <>
            <table
              className={`table ${
                tableAnimation ? "show-rows" : ""
              }  border-separate border-spacing-y-0  text-xs  w-full relative `}
            >
              <thead className="h-8 sticky bg-[#efefef]  dark:bg-gray-700">
                <tr className="">
                  <th>
                    <input
                      type="checkbox"
                      className="focus:outline-none focus:ring-0 rounded-[3px] text-light-1 bg-transparent"
                      checked={allSelected}
                      onChange={handleSelectAllChange}
                    />
                  </th>
                  <th>Serial No</th>
                  {sortHeader?.map((header) => (
                    <th
                      key={header}
                      className="cursor-pointer"
                      onClick={() => {
                        if (sort === header) {
                          setSortOrder(toggleSortOrder(sortOrder));
                        } else {
                          setSort(header);
                          setSortOrder("asc");
                        }
                      }}
                    >
                      {header}{" "}
                      {sort === header ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white  dark:bg-gray-900">
                {filteredDataStack?.sort((a, b) => {
                      // Sorting logic for RSO No
                     if (sort === "Asset No") {
                        const aValue = a.asset_no || "";
                        const bValue = b.asset_no || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.asset_no)
                          : bValue.localeCompare(a.asset_no);
                      }
                       else if (sort === "Model") {
                        const aValue = a.model || "";
                        const bValue = b.model || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.model)
                          : bValue.localeCompare(a.model);
                      } else if (sort === "Make") {
                        const aValue = a.make || "";
                        const bValue = b.make || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.make)
                          : bValue.localeCompare(a.make);
                      } 
                      else if (sort === "YOM") {
                        const aValue = a.yom || "";
                        const bValue = b.yom || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.yom)
                          : bValue.localeCompare(a.yom);
                      }
                      else if (sort === "Category") {
                        const aValue = a.category || "";
                        const bValue = b.category || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.category)
                          : bValue.localeCompare(a.category);
                      }
                      else if (sort === "Lease Status") {
                        const aValue = a.lease_status || "";
                        const bValue = b.lease_status || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.lease_status)
                          : bValue.localeCompare(a.lease_status);
                      }
                      return 0; // No sorting if RSO No isn't selected
                    }).map((data, index) => {
                  let numb = 0;
                  if (data?.lease_status?.startsWith("expiring")) {
                    // Match a number within the string "expiring in X days"
                    const match = data.lease_status.match(/\d+/);

                    if (match) {
                      numb = parseInt(match[0], 10);
                    }
                  }

                  if (selectTableData - 1 >= index || !selectTableData) {
                    return (
                      <>
                        <tr
                          key={index}
                          className="cursor-pointer text-center items-center hover:bg-[#2222] justify-center h-10"
                        >
                          <td className="border-b w-[50px] ">
                            <input
                              type="checkbox"
                              checked={data.selected}
                              onChange={() => handleItemChange(data.id)}
                              className="focus:outline-none focus:ring-0 rounded-[3px]  text-light-1 bg-transparent "
                            />
                          </td>
                          <td className="border-b w-[60px]">{index + 1}</td>
                          {/* !assetDetails?.device_id === null || !assetDetails?.device_id === " " || !assetDetails?.device_id === undefined ? */}
                          <td
                            className={`border-b ${
                              !data?.device_id === null || !data?.device_id === " " || !data?.device_id === undefined 
                                ? "text-green-500"
                                : "text-gray-600"
                            }`}
                            onClick={() => openAssetDetails(data?.id)}
                          >
                            {data?.is_sold === true ? (
                              <img
                                src="/images/sold.png"
                                alt=""
                                className="absolute left-[8%] h-6 w-12  mb-5"
                              />
                            ) :  !data?.device_id === null || !data?.device_id === " " || !data?.device_id === undefined  ? (
                              <>
                                {" "}
                                <img
                                  src="/images/wifi.svg"
                                  alt=""
                                  className="absolute left-[11%] h-4 w-4 -rotate-45 mb-7"
                                />
                              </>
                            ) : (
                              ""
                            )}
                            {data?.asset_no}
                          </td>

                          <td
                            className="border-b"
                            onClick={() => openAssetDetails(data?.id)}
                          >
                            {data?.model}
                          </td>
                          <td
                            className="border-b"
                            onClick={() => openAssetDetails(data?.id)}
                          >
                            {data?.make.toUpperCase()}
                          </td>
                          <td
                            className="border-b text-green-600 font-bold"
                            onClick={() => openAssetDetails(data?.id)}
                          >
                            {data?.yom}
                          </td>

                          <td
                            className="border-b"
                            onClick={() => openAssetDetails(data?.id)}
                          >
                            {data?.category}
                          </td>
                          <td
                            className={`border-b font-bold ${
                              // data?.lease_status === "never assigned"
                              //   ? "text-red-600"
                              //   :
                                
                                data?.lease_status === "inactive" || data?.lease_status ===  "never assigned"
                                ? "text-slate-500"
                                : data?.lease_status === "expired"
                                ? "text-[#511414]"
                                : "text-green-500"
                            }`}
                            onClick={() => openAssetDetails(data?.id)}
                          >
                            {data?.lease_status?.startsWith("expiring")
                              ? `Active (${numb} ${" "} days)`
                              : data?.lease_status === 'never assigned' || data?.lease_status ===  'inactive' ? 'Free' : data?.lease_status === "active"? "Active" : capitalizeFirstLetter(data?.lease_status)}
                               {/* {capitalizeFirstLetter(data?.lease_status)} */}
                          </td>

                          {/* <td
                                className="border-b"
                                onClick={() => openAssetDetails(data?.id)}
                              >
                                {formatDate(data?.created_at * 1000)}
                              </td> */}
                        </tr>
                      </>
                    );
                  }
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};
