import { Paper, TablePagination } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../customFunctions/FormatDate";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import {
  FaCheckCircle,
  FaDoorOpen,
  FaSpinner,
  FaLock,
  FaQuestionCircle,
  FaTools,
  FaFileExcel,
} from "react-icons/fa";
import { MdTaskAlt } from "react-icons/md";
import * as XLSX from "xlsx";

const NewMaintenanceTable = ({ maintenance }) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(maintenance);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setPageSize(value === "All data" ? filteredData.length : value);
    setPage(0); // Reset to the first page
  };

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <span title="Row Number" arrow placement="top">
          <span>#</span>
        </span>
      ),
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },
    {
      field: "ticket_no",
      headerName: "ticket_no",
      width: 150,
      renderHeader: () => <>Ticket ID</>,
    },
    {
      field: "compaint_date",
      headerName: "Date of Complain",
      width: 150,
      renderHeader: () => <>Date of Complain</>,
      renderCell: (params) => <>{formatDate(params.row.compaint_date)}</>,
    },
    {
      field: "asset_no",
      headerName: "asset_no",
      width: 150,
      renderHeader: () => <>Asset No</>,
      renderCell: (params) => <span>{params.row.asset_no}</span>,
    },
    {
      field: "title",
      headerName: "title",
      width: 150,
      renderHeader: () => <>BD Title</>,
    },
    {
      field: "issue_date",
      headerName: "issue_date",
      width: 150,
      renderHeader: () => <>Breakdown Date</>,
      renderCell: (params) => (
        <span className="">{params.value && formatDate(params.value)}</span>
      ),
    },
    {
      field: "types",
      headerName: "Types",
      width: 150,
      renderHeader: () => <>Service Category</>,
      renderCell: (params) => (
        <span className="">{params.value === "types" ? "" : params.value}</span>
      ),
    },
    {
      field: "serviceSalePerson",
      headerName: "Assigned Service Person",
      width: 150,
      renderHeader: () => <>Assigned Engineer</>,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 150,
      renderHeader: () => <>Priority</>,
      renderCell: (params) => {
        // Determine styling based on priority value
        const priority = params.value?.toLowerCase();
        let priorityClass = "";
        let displayText = params.value || "";

        switch (priority) {
          case "high":
            priorityClass = "bg-red-500 text-red-50";
            break;
          case "medium":
            priorityClass = "bg-yellow-500 text-yellow-50 b";
            break;
          case "low":
            priorityClass = "bg-green-500 text-green-50 ";
            break;
          default:
            priorityClass = "bg-gray-100 text-gray-800";
            displayText = "Not Set";
        }

        return (
          <span
            className={`px-2 py-1 rounded-full text-xs font-medium ${priorityClass}`}
          >
            {displayText}
          </span>
        );
      },
    },
    // {
    //     field: 'location',
    //     headerName: 'Location',
    //     width: 150,
    //     renderHeader: () => (
    //         <>Location</>
    //     )
    // },
    {
      field: "status",
      headerName: "Status",
      width: 240,
      renderHeader: () => (
        <div className="font-medium text-gray-700">Status</div>
      ),
      renderCell: (params) => {
        // Enhanced status badge configuration
        const statusConfig = {
          active: {
            className: "bg-green-600 text-white",
            displayText: "Active",
            icon: <FaCheckCircle className="mr-1" />,
          },
          open: {
            className: "bg-green-600 text-white",
            displayText: "Open",
            icon: <FaDoorOpen className="mr-1" />,
          },
          in_progress: {
            className: "bg-yellow-500 text-white",
            displayText: "In Progress",
            icon: <FaSpinner className="mr-1 animate-spin" />,
          },
          temporary_closed: {
            className: "bg-gray-300 text-gray-800",
            displayText: "Temporary Closed",
            icon: <FaQuestionCircle className="mr-1" />,
          },
          closed: {
            className: "bg-gray-500 text-white",
            displayText: "Closed",
            icon: <FaLock className="mr-1" />,
          },
          default: {
            className: "bg-gray-300 text-gray-800",
            displayText: params.value,
            icon: <FaQuestionCircle className="mr-1" />,
          },
        };

        const currentStatus =
          statusConfig[params.value.toLowerCase()] || statusConfig.default;

        return (
          <div className="flex items-center gap-3 mt-3">
            {/* Main Status Badge */}
            <div className="flex items-center">
              <span
                className={`px-3 py-1.5 text-xs rounded-full flex items-center ${currentStatus.className}`}
                title={`Status: ${currentStatus.displayText}`}
              >
                {currentStatus.icon}
                {currentStatus.displayText}
              </span>
            </div>

            {/* Additional Indicators */}
            <div className="flex items-center gap-1">
              {/* Parts Requested Indicator */}
              {params.row.is_part_approval_needed && (
                <span
                  className="p-1.5 rounded-full bg-orange-500 text-white"
                  title="Parts requested by engineer"
                >
                  <FaTools className="text-xs" />
                </span>
              )}

              {/* Ready for Closure Indicator */}
              {params.row.is_ready_for_closer && (
                <span
                  className="p-1.5 rounded-full bg-red-1 text-white"
                  title="Ready for closure approval"
                >
                  <MdTaskAlt className="text-xs" />
                </span>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const exportToExcel = () => {
    // Prepare data for export with professional formatting
    const dataToExport = maintenance.map((item) => ({
      "Ticket ID": item.ticket_no || "N/A",
      "Asset Number": item.asset_no || "N/A",
      "Complaint Date": formatDateForExcel(item.compaint_date),
      "Breakdown Date": formatDateForExcel(item.issue_date),
      "Issue Title": item.title || "Not Specified",
      "Service Type":
        item.types === "types" ? "N/A" : formatServiceType(item.types),
      "Assigned Engineers": formatEngineers(item.serviceSalePerson),
      "Priority Level": formatPriority(item.priority),
      Location: item.location || "Not Specified",
      "Current Status": formatStatus(item.status),

      "Parts Details": formatParts(item.parts),
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(dataToExport, {
      header: Object.keys(dataToExport[0]),
    });

    // Add Excel styling through cell objects
    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]);

      // Style headers (first row)
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const headerCell = XLSX.utils.encode_cell({ r: 0, c: C });
        if (!ws[headerCell]) continue;

        ws[headerCell].s = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "4472C4" } }, // Blue background
          alignment: { horizontal: "center", vertical: "center" },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };
      }

      // Style date columns
      ["C", "D"].forEach((col) => {
        for (let R = 1; R <= range.e.r; ++R) {
          const cell = XLSX.utils.encode_cell({
            r: R,
            c: col.charCodeAt(0) - 65,
          });
          if (ws[cell]) {
            ws[cell].z = "dd-mmm-yyyy"; // Date format
            ws[cell].s = {
              alignment: { horizontal: "center" },
              numFmt: "dd-mmm-yyyy;@",
            };
          }
        }
      });

      // Style priority column with conditional formatting
      for (let R = 1; R <= range.e.r; ++R) {
        const cell = XLSX.utils.encode_cell({ r: R, c: 7 }); // Priority column (H)
        if (ws[cell]) {
          const priority = ws[cell].v?.toLowerCase();
          ws[cell].s = {
            font: { bold: true },
            ...(priority === "high"
              ? {
                  fill: { fgColor: { rgb: "FF0000" } },
                  font: { color: { rgb: "FFFFFF" } },
                }
              : priority === "medium"
              ? { fill: { fgColor: { rgb: "FFC000" } } }
              : priority === "low"
              ? {
                  fill: { fgColor: { rgb: "00B050" } },
                  font: { color: { rgb: "FFFFFF" } },
                }
              : { fill: { fgColor: { rgb: "D9D9D9" } } }),
          };
        }
      }
    }

    // Set column widths
    ws["!cols"] = [
      { wch: 15 }, // Ticket ID
      { wch: 12 }, // Asset Number
      { wch: 15 }, // Complaint Date
      { wch: 15 }, // Breakdown Date
      { wch: 25 }, // Issue Title
      { wch: 15 }, // Service Type
      { wch: 30 }, // Assigned Engineers
      { wch: 15 }, // Priority Level
      { wch: 20 }, // Location
      { wch: 15 }, // Current Status
      { wch: 12 }, // Parts Required
      { wch: 40 }, // Parts Details
      { wch: 15 }, // Closure Status
    ];

    // Freeze header row
    ws["!freeze"] = { x: 0, y: 1 };

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Maintenance Report");

    // Generate filename
    const date = new Date();
    const fileName = `Maintenance_Report_${date.getFullYear()}${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}.xlsx`;

    // Export the workbook
    XLSX.writeFile(wb, fileName);
  };

  // Helper functions for formatting
  const formatDateForExcel = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  const formatEngineers = (engineers) => {
    if (!engineers || !Array.isArray(engineers)) return "Not Assigned";
    return engineers.filter((e) => e).join(", ");
  };

  const formatPriority = (priority) => {
    if (!priority) return "Not Specified";
    return priority.charAt(0).toUpperCase() + priority.slice(1);
  };

  const formatStatus = (status) => {
    if (!status) return "";
    return status
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatParts = (parts) => {
    if (!parts || !Array.isArray(parts)) return "None";
    return parts.map((p) => `${p.part_no} (Qty: ${p.quantity})`).join(";\n");
  };

  const formatServiceType = (type) => {
    if (!type) return "N/A";
    return type === "non_warranty"
      ? "Non-Warranty"
      : type === "warranty"
      ? "Warranty"
      : type;
  };

  const applyFilters = () => {
    let data = [...maintenance];
    // Search Query Filter
    if (searchQuery.trim()) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      data = data.filter((item) =>
        Object.values(item).some(
          (value) =>
            value && value.toString().toLowerCase().includes(lowerCaseQuery)
        )
      );
    }

    // Set Filtered Data
    setFilteredData(data);
    setPage(0);
  };

  useEffect(() => {
    applyFilters();
  }, [searchQuery]);

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between z-0">
        <span className="text-xs flex flex-col">
          {/* Search */}
          <input
            title="Search Assets"
            type="text"
            placeholder="Search Assets..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border-light-5 border  max-md:w-[300px] w-[200px] p-[4px] rounded-[4px] text-[12px] items-center"
          />
        </span>
        <div className="flex items-center gap-2">
          <NewAssetBtn tabName="maintenance" />
          {filteredData.length > 0 && (

          <button
            className="flex items-center buttons border border-green-2 text-green-2 text-center justify-center gap-1"
            onClick={exportToExcel}
            title="Click to export in excel"
          >
            <FaFileExcel /> Export Excel
          </button>
          )}
        </div>
      </div>
      {/* Table */}
      <Paper
        style={{
          height: "70vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        <TablePagination
          title="Add pagination"
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 30, 50, "All data"]}
          labelRowsPerPage="Maintenance:"
          Select={{
            renderValue: (value) => (value === "All data" ? "All data" : value),
          }}
        />

        {filteredData.length > 0 ? (
          <DataGrid
            rows={
              pageSize === filteredData.length
                ? filteredData.map((row, index) => ({
                    ...row,
                    counter: index + 1, // Add row number for all rows
                  }))
                : filteredData
                    .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
                    .map((row, index) => ({
                      ...row,
                      counter: page * pageSize + index + 1, // Adjust counter for paginated rows
                    }))
            }
            columns={columns}
            paginationMode="server"
            pagination={false} // Disable built-in pagination
            pageSize={pageSize}
            onRowClick={(params) => {
              const rowId = params.row.id; // Use the row ID or another property to navigate
              navigate(`/maintenance/update-maintenance/${rowId}`); // Adjust the route as needed
            }}
            sx={{
              "& .MuiDataGrid-footerContainer": {
                display: "none", // Hides the footer container
              },
              "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
              "& .MuiDataGrid-columnHeaders": {
                fontSize: "12px",
                backgroundColor: "#000",
                fontWeight: "bold",
                color: "#006DB6",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
                height: 20,
              },
              "& .MuiDataGrid-cell": { fontSize: "12px" },
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
          />
        ) : (
          <div className="h-[50vh] flex flex-col justify-center items-center dark:text-white text-gray-500">
            <svg
              className="w-16 h-16 mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="text-lg">No maintenance tickets found</p>
            <p className="text-sm mt-1">
              Create a new ticket or check back later
            </p>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default NewMaintenanceTable;
