// import React, { useState } from "react";
// import { setBaseUrl } from "../../../config";
// import moment from "moment";

// const MonthlySalary = ({ operatorId, operatorDetail }) => {
//     const [formData, setFormData] = useState(null);
//     const [selectedMonth, setSelectedMonth] = useState(null);
//     const [inputArray, setInputArray] = useState({
//         id: operatorId,
//         working_days: 0,
//         month_days: 0,
//     });

//     // Fetch monthly salary data by operator ID
//     const getMonthlySalaryData = async () => {
//         try {
//             const res = await fetch(`${setBaseUrl}/operator/get-salary-by-entry`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                     "x-access-tokens": sessionStorage.getItem("token"),
//                 },
//                 body: JSON.stringify(inputArray),
//             });

//             const data = await res.json();
//             setFormData(data);
//         } catch (error) {
//             console.error(error);
//         }
//     };

//     // Get number of days in a month
//     function getDaysInMonth(year, month) {
//         return moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
//     }

//     // Handle month selection
//     const handleUploadMonth = (e) => {
//         const value = e.target.value;
//         if (value) {
//             const [year, month] = value.split("-");
//             const daysInMonth = getDaysInMonth(year, month);

//             setInputArray((prev) => ({
//                 ...prev,
//                 month_days: daysInMonth,
//             }));

//             setSelectedMonth(value);
//         }
//     };

//     return (
//         <div className="flex justify-between">

//             <div className="p-4">
//                 <h2 className="text-lg font-bold mb-4">Monthly Salary for Operator</h2>

//                 {/* Inputs for selecting month & working days */}
//                 <div className="flex flex-col gap-2 mb-4 w-[200px]">
//                     <input type="month" onChange={handleUploadMonth} className="border p-2 rounded" />

//                     <input
//                         type="number"
//                         placeholder="Number of working days"
//                         className="border p-2 rounded"
//                         onChange={(e) =>
//                             setInputArray((prev) => ({
//                                 ...prev,
//                                 working_days: Number(e.target.value),
//                             }))
//                         }
//                     />

//                     <button
//                         className="bg-blue-500 text-white buttons"
//                         onClick={getMonthlySalaryData}
//                     >
//                         Submit
//                     </button>
//                 </div>

//                 <div className="flex justify-between">
//                     <div className="">

//                         <div className="bg-white text-dark-6 dark:bg-gray-900 p-4 rounded-[2px] shadow-md">
//                             <h2 className="text-xx font-bold mb-4">Attendance</h2>
//                             <table className="w-full text-xs">
//                                 <thead className="text-dark-6 text-left">
//                                     <tr>
//                                         <th className="px-4 py-2 border-b border-gray-300 w-[105px]">
//                                             Date
//                                         </th>
//                                         <th className="px-4 py-2 border-b border-gray-300">Day Type</th>
//                                         <th className="px-4 py-2 border-b border-gray-300">Time In</th>
//                                         <th className="px-4 py-2 border-b border-gray-300">Time Out</th>
//                                         <th className="px-4 py-2 border-b border-gray-300">Attendance</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody className="text-dark-600 h-[100px] overflow-y-auto">
//                                     {operatorDetail?.log_in_out?.map((log, idx) => (
//                                         <tr key={idx} className="hover:bg-gray-50">
//                                             <td className="px-4 py-2 border-b border-gray-300 w-[105px]">
//                                                 {moment(log.in).format("DD-MM-YYYY")}
//                                             </td>
//                                             <td className="px-4 py-2 border-b border-gray-300">
//                                               <select name="" id="">
//                                                 <option value="" className="">Select</option>
//                                                 <option value="WD" className="">WD</option>
//                                                 <option value="HD" className="">HD</option>
//                                               </select>
//                                             </td>
//                                             <td className="px-4 py-2 border-b border-gray-300">
//                                                 {moment(log.in).format("HH:mm")}
//                                             </td>
//                                             <td className="px-4 py-2 border-b border-gray-300">
//                                                 {moment(log.out).format("HH:mm")}
//                                             </td>

//                                             <td className="px-4 py-2 border-b border-gray-300">
//                                                 <select name="" id="">
//                                                     <option value="" className="">Select</option>
//                                                     <option value="Present" className="">Present</option>
//                                                     <option value="Absent" className="">Absent</option>
//                                                   <option value="Leave" className="">Leave</option>
//                                                 </select>
//                                             </td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>

//                     {/* Salary Data Table */}
//                     {formData && (
//                         <table className="w-full text-xs border border-gray-300 rounded-md shadow-md">
//                             <tbody>
//                                 {[
//                                     { label: "Basic Pay", value: formData?.basic_pay ? `₹${formData.basic_pay}` : "--" },
//                                     { label: "Allowances", value: formData?.allowances ? `₹${formData.allowances}` : "--" },
//                                     { label: "Gross Salary", value: formData?.gross ? `₹${formData.gross}` : "--" },
//                                     { label: "Employee PF", value: formData?.employee_pf ? `₹${formData.employee_pf}` : "--" },
//                                     { label: "Employee ESI", value: formData?.employee_esi ? `₹${formData.employee_esi}` : "--" },
//                                     { label: "Employer PF", value: formData?.employer_pf ? `₹${formData.employer_pf}` : "--" },
//                                     { label: "Employer ESI", value: formData?.employer_esi ? `₹${formData.employer_esi}` : "--" },
//                                     { label: "Net Payment", value: formData?.net_payment ? `₹${formData.net_payment}` : "--" },
//                                     { label: "Take Home", value: formData?.takehome ? `₹${formData.takehome}` : "--" },
//                                     { label: "TDS", value: formData?.tds ? `₹${formData.tds}` : "--" },
//                                 ].map(({ label, value }, idx) => (
//                                     <tr key={idx} className="border-b hover:bg-gray-100 transition duration-200">
//                                         <td className="py-3 px-4 font-medium text-gray-600">{label}:</td>
//                                         <td className="py-3 px-4 text-right font-semibold text-gray-800">{value}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>

//                     )}
//                 </div>
//             </div>

//         </div>
//     );
// };

// export default MonthlySalary;

import React, { useState, useEffect } from "react";
import { setBaseUrl } from "../../../config";
import moment from "moment";
import { toast } from "react-toastify";

const MonthlySalary = ({ operatorId, operatorDetail }) => {
    const [formData, setFormData] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [inputArray, setInputArray] = useState({
        id: operatorId,
        working_days: 0,
        month_days: 0,
    });
    const [attendanceData, setAttendanceData] = useState([]);
    const [updateSalary, setUpdateSalary] = useState({
        operator_id: operatorId,
    });

    const [updateAttendanceData, setUpdateAttendanceData] = useState({})

    // Fetch monthly salary data by operator ID
    const getMonthlySalaryData = async () => {
        try {
            const res = await fetch(`${setBaseUrl}/operator/get-salary-by-entry`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-tokens": sessionStorage.getItem("token"),
                },
                body: JSON.stringify(inputArray),
            });

            const data = await res.json();
            setFormData(data);
        } catch (error) {
            console.error(error);
        }
    };

    // Get number of days in a selected month
    function getDaysInMonth(year, month) {
        return moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    }

    // Handle month selection
    const handleUploadMonth = (e) => {
        const value = e.target.value;
        if (value) {
            const [year, month] = value.split("-");
            const daysInMonth = getDaysInMonth(year, month);

            setInputArray((prev) => ({
                ...prev,
                month_days: daysInMonth,
            }));

            setSelectedMonth(value);

            // Generate attendance table for the selected month
            generateAttendanceTable(year, month, daysInMonth);
        }
    };

    // Generate attendance table for the month
    const generateAttendanceTable = (year, month, daysInMonth) => {
        let attendanceArray = [];

        for (let day = 1; day <= daysInMonth; day++) {
            let dateString = moment(`${year}-${month}-${day}`, "YYYY-MM-DD").format("YYYY-MM-DD");
            let logEntry = operatorDetail?.log_in_out?.find(log => moment(log.in).format("YYYY-MM-DD") === dateString);

            attendanceArray.push({
                date: dateString,
                dayType: "WD",
                timeIn: logEntry?.in ? moment(logEntry.in).format("HH:mm") : "--",
                timeOut: logEntry?.out ? moment(logEntry.out).format("HH:mm") : "--",
                status: logEntry?.in && logEntry?.out ? "Present" : "Absent",
            });
        }

        setAttendanceData(attendanceArray);
    };

    // Handle update salary button click
    const handleAttendaceSubmit = async () => {
        console.log(attendanceData)
    }

    const handleSalarySubmit = async () => {
        console.log(updateSalary)

        // try {
        //     const res = await fetch(`${setBaseUrl}/operator/entry-operator-salary`, {
        //         method: "POST",
        //         headers: {
        //             "Content-Type": "application/json",
        //             "x-access-tokens": sessionStorage.getItem("token"),
        //         },
        //         body: JSON.stringify(updateSalary),
        //     })

        //     toast.success("Updated Salary!")
        // } catch (error) {
        //     console.error(error)
        //     toast.error("Failed to update Salary!")
        // }
    }

    return (
        <div className="flex flex-col p-4">
            <h2 className="text-base font-bold mb-2">Monthly Salary for Operator</h2>

            {/* Month & Working Days Input */}
            <div className="flex flex-col gap-2 mb-4  items-start">
                <input type="month" onChange={handleUploadMonth} className="w-[320px] focus:outline-none focus:ring-0 focus:border-none border-none p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none" />

                <input
                    type="number"
                    min={0}
                    max={31}
                    maxLength={25}
                    placeholder="Number of working days"
                    className="w-[320px] focus:outline-none focus:ring-0 focus:border-none border-none p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                    onChange={(e) =>
                        setInputArray((prev) => ({
                            ...prev,
                            working_days: Number(e.target.value),
                        }))
                    }
                />

                <button className="bg-blue-500 text-white buttons" onClick={getMonthlySalaryData}>
                    Submit
                </button>
            </div>

            <div className="grid grid-cols-2 text-xs gap-4">
                {/* Attendance Table for the Selected Month */}
                {selectedMonth && (
                    <div className="bg-white text-dark-6 dark:bg-gray-900 p-4 rounded-md shadow-md">
                        <h2 className="text-xs font-bold mb-4">Attendance for {selectedMonth}</h2>
                        <table className="w-full text-xs border-collapse border border-gray-300">
                            <thead className="bg-gray-100 text-left">
                                <tr>
                                    <th className="px-4 py-2 border border-gray-300">Date</th>
                                    <th className="px-4 py-2 border border-gray-300">Day Type</th>
                                    <th className="px-4 py-2 border border-gray-300">Time In</th>
                                    <th className="px-4 py-2 border border-gray-300">Time Out</th>
                                    <th className="px-4 py-2 border border-gray-300">Attendance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendanceData.map((day, idx) => (
                                    <tr key={idx} className="hover:bg-gray-50">
                                        <td className="px-4 py-2 border border-gray-300">{moment(day.date).format("DD-MM-YYYY")}</td>
                                        <td className="px-4 py-2 border border-gray-300">
                                            <select name="" value={day.dayType} id="" className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none">

                                                <option value="WD">WD</option>
                                                <option value="HD">HD</option>

                                            </select>
                                        </td>
                                        <td className="px-4 py-2 border border-gray-300">
                                            <input type="time" value={day.timeIn}
                                                onChange={(e) => {
                                                    day.timeIn = e.target.value
                                                    setAttendanceData([...attendanceData])
                                                }}
                                                className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"

                                            />
                                        </td>
                                        <td className="px-4 py-2 border border-gray-300">
                                            <input type="time" value={day.timeOut}
                                                onChange={(e) => {
                                                    day.timeOut = e.target.value
                                                    setAttendanceData([...attendanceData])
                                                }}
                                                className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"

                                            />
                                        </td>
                                        <td className={`px-4 py-2 border border-gray-300 font-bold ${day.status === "Present" ? "text-green-600" : "text-red-600"}`}>
                                            <select name="" value={day.status} id=""
                                                onChange={(e) => {
                                                    day.status = e.target.value;
                                                    setAttendanceData([...attendanceData]);

                                                }}
                                                className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            >
                                                <option value="Absent">Absent</option>
                                                <option value="Present">Present</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button className="buttons bg-green-2 text-white" onClick={handleAttendaceSubmit}>Submit</button>
                    </div>
                )}

                {/* Salary Data Table */}
                {formData && (
                    <div className="mt-6 text-xs bg-white p-4 rounded-md shadow-md">
                        <h2 className="text-xs font-bold mb-4">Salary Details</h2>
                        <table className="w-full text-xs border border-gray-300 rounded-md">
                            <tbody>
                                {/* Allowances */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Allowances:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            placeholder="Allowances"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.allowances || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, allowances: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "allowances": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Basic Pay */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Basic Pay:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            placeholder="Basic Pay"
                                            min={0}
                                            maxLength={25}
                                            title="Basic Pay"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.basic_pay || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, basic_pay: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "basic_pay": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Employee CTC */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Employee CTC:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            placeholder="Employee CTC"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.ctc || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, ctc: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "ctc": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Employee PF */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Employee PF:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            placeholder="Employee PF"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.employee_pf || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, employee_pf: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "employee_pf": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Employee ESI */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Employee ESI:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            placeholder="Employee ESI"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.employee_esi || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, employee_esi: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "employee_esi": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Employer PF */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Employer's PF:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number" min={0}
                                            maxLength={25}
                                            placeholder="Employer PF"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.employer_pf || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, employer_pf: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "employer_pf": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Employer ESI */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Employer ESI:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            placeholder="Employer ESI"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.employer_esi || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, employer_esi: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "employer_esi": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Gross Salary */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Gross Salary:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            placeholder="Gross Salary"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.gross || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, gross: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "gross": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* GST Amount */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">GST Amount:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            placeholder="GST Amount"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.gst || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, gst: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "gst": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Net Payment */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Net Payment:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            placeholder="Net Payment"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.net_payment || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, net_payment: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "net_payment": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* Take Home */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Take Home:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            min={0}
                                            maxLength={25}
                                            type="number"
                                            placeholder="Take Home"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.takehome || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, takehome: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "takehome": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                {/* TDS */}
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">TDS:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            placeholder="TDS"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.tds || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, tds: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "tds": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Remittable 3P:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            placeholder="Remittable 3P"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.remittable_3p || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, remittable_3p: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "remittable_3p": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Service Charge:</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            placeholder="Service Charge"
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.service_charge || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, service_charge: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "service_charge": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Sub Total</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.sub_total || ""}
                                            placeholder="Subtotal"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, sub_total: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "sub_total": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Days Ratio</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.days_ratio || ""}
                                            placeholder="Days Ratio"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, days_ratio: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "days_ratio": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Attend Days</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.attend_days || ""}
                                            placeholder="Attend Days"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, attend_days: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "attend_days": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Actual Days</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.actual_days || ""}
                                            placeholder="Actual days"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, actual_days: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "actual_days": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Overtime Amount</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            placeholder="Overtime Amount"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.overtime_amount || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, overtime_amount: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "overtime_amount": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Month Year</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            placeholder="Month Year"
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.month_year || ""}
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, month_year: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "month_year": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr className="border-b hover:bg-gray-100 transition duration-200">
                                    <td className="py-3 px-4 font-medium text-gray-600">Advanced Money Detuced</td>
                                    <td className="py-3 px-4 text-right">
                                        <input
                                            type="number"
                                            min={0}
                                            maxLength={25}
                                            className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                                            value={formData?.advanced_money_detuced || ""}
                                            placeholder="Advanced Money Detuced"
                                            onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, advanced_money_detuced: e.target.value }))
                                                setUpdateSalary({ ...updateSalary, "advanced_money_detuced": e.target.value })
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <button className="buttons bg-green-2 text-white" onClick={handleSalarySubmit}>Submit</button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default MonthlySalary;
