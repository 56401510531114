import React, { useContext, useEffect, useState } from 'react'
import { LoaderContext } from '../../../Context/LoaderContext'
import { setBaseUrl } from '../../../config';
import axios from 'axios';
import {CurrentItems} from "../../tables/LeaseTable/CurrentItems"
import { currency, formatDate } from './Tabs/FormatDate';
import { Link, useNavigate } from 'react-router-dom';

function LeaseTab({asset_id}) {
  console.log(asset_id);
  
    const setLoader = useContext(LoaderContext)
    const [leaseDetails, setLeaseDetails] = useState([])
    const getAllLease = async () => {
        try {
          const { data } = await axios.get(`${setBaseUrl}/lease/get_all`, {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": sessionStorage.getItem("token"),
              //   offset: itemOffset,
              //   limit: itemsPerPage,
              "asset-id": asset_id
            },
          });
          // console.log(data);
          setLeaseDetails(data);
        } catch (error) {
          console.error(error);
        } 
      };
      useEffect(() => {
        getAllLease();
      },[])


      const sortList = (a,b) => {
        const statusOrder = {
          'active': 1,
          'inactive': 2,
          'never assigned': 3
        };

        return (statusOrder[a.lease_status] || 4) - (statusOrder[b.lease_status] || 4);
      }

      const navigate = useNavigate()
  return (
    // <CurrentItems leaseDetails={leaseDetails} setLoader={setLoader}/>
    <>
      <div className=' mt-6 w- fixed border overflow-y-auto rounded-2xl'>

        <table className='  text-xs w-[94.5vw] text-center h-[200px]'>
          <thead className='bg-[#efefef] dark:bg-gray-700 h-10'>
            <tr>
              {/* <th>Asset No</th> */}
              <th>Odoo Order Id </th>
              <th>Lease Status</th>
              <th>Renatal Start Date</th>
              <th>Rental End Date</th>
              <th>Total Claimable Account</th>
              <th>Total Data</th>
            </tr>
          </thead>
          <tbody className=''>
            {leaseDetails.sort(sortList).map((lease) => (
              <>
                   {/* <Link to={`/lease/leaseDetails/${lease.id}`}>
                   </Link> */}
              <tr className='h-7 border-b cursor-pointer' onClick={() => navigate(`/lease/leaseDetails/${lease.id}`)}>
                <td className='borde'>
         
                  {lease?.odoo_order_id}
                  </td>
                <td className={`borde font-bold ${lease?.lease_status === 'inactive'? "text-slate-500": lease?.lease_status === 'active'? "text-green-500": lease?.lease_status === "never assinged" ? "text-red-600" : 'text-green-500'}`}>
                               {lease?.lease_status}
                  </td>
                <td className='border-b'>
              
                  {formatDate(lease?.rental_start_date)}
                  </td>
                <td className='borde'>
                                 {lease?.rental_extended_date? `${formatDate(lease?.rental_extended_date)}`: `${formatDate(lease?.rental_end_date)}`}
                  </td>
                <td className='borde'>
              
                  ₹{currency(parseInt(lease?.total_claimable_amount))}
                  </td>
                <td className='borde'>
              
                  {lease?.total_data}
                  </td>
            </tr>
              </>
            ))}
              
          </tbody>
        </table>

      </div>
    </>
  )
}

export default LeaseTab