import React from 'react'
import { MapContainer, TileLayer } from "react-leaflet";
import LocationMarker from './LocationMarker';

const DeviceMap = ({mapPosition, setMapPosition}) => {
  return (
    <div className=''>
        <MapContainer
        center={mapPosition}
        zoom={13}
        scrollWheelZoom={true}
        style={{height: '200px', width: '100vh', zIndex:0, objectFit:'cover' }}
        >

        <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
        <LocationMarker 
        position={mapPosition}
        setPosition={setMapPosition}
        />
        </MapContainer>
    </div>
  )
}

export default DeviceMap



// import { useRef, useEffect } from 'react'
// import mapboxgl from 'mapbox-gl'

// import 'mapbox-gl/dist/mapbox-gl.css';



// const  DeviceMap = () => {

//   const mapRef = useRef()
//   const mapContainerRef = useRef()

//   useEffect(() => {
//     mapboxgl.accessToken =  process.env.REACT_APP_MAP_BOX_TOKEN;

//     mapRef.current = new mapboxgl.Map({
//       container: mapContainerRef.current,
//     });

//     return () => {
//       mapRef.current.remove()
//     }
//   }, [])

//   return (
//     <>
//       <div id='map-container' ref={mapContainerRef}/>
//     </>
//   )
// }

// export default DeviceMap