// import React from "react";

// import moment from "moment/moment";
// import { Tooltip } from "@mui/material";
// import { currency, formatDate } from "../../../../customFunctions/FormatDate";

// const AssetCommercial = ({
//   commercialDetails,
//   CustomDutyDateOfClearance,
// }) => {
//   if (commercialDetails === null) {
//     return <div className="text-xs text-red-1">No Commercial Details Added</div>;
//   }


//   let CommercialData = [];
// if (commercialDetails) {

//   CommercialData = [
//     {
//       title: "Total Landed Cost",
//       label: `${
//         commercialDetails.total_landed_cost === null ||   commercialDetails.total_landed_cost === "nill" || commercialDetails.total_landed_cost === "N/A"
//           ? "N/A"
//           : `₹${
//              currency( commercialDetails?.total_landed_cost)
//             }/-`
//       }`,
//       tooltip: "Total Landed Cost",
//     },
//     {
//       title: "Ex-Works Price",
//       label:
//         commercialDetails?.exworks_price === null ||  commercialDetails?.exworks_price === "nill" ||  commercialDetails.exworks_price === "N/A"
//           ? "N/A"
//           : `$${currency(commercialDetails?.exworks_price)}/-`,
//       tooltip: "Ex-Works Price",
//     },
//     {
//       title: "CIF Charges",
//       label:
//         commercialDetails?.cif_charges === null ||  commercialDetails?.cif_charges === "nill" ||  commercialDetails.cif_charges === "N/A"
//           ? "N/A"
//           : `$${currency(commercialDetails?.cif_charges)}/-`,
//       tooltip: "Cost, Insurance, and Freight (CIF) Charges",
//     },
//     {
//       title: "Total Cost (Ex-Works + CIF)",
//       label:
//         commercialDetails?.total_cost === "nill" || commercialDetails?.total_cost ===  null ||  commercialDetails.total_cost === "N/A"
//           ? "N/A"
//           : `$${currency(commercialDetails?.total_cost)}/-`,
//       tooltip:
//         "Total Cost = Ex Works (EXW) price + Cost, Insurance, and Freight (CIF) Charges",
//     },
//     {
//       title: "Port of Clearance",
//       label:
//         commercialDetails?.port_of_clearance === null ||  commercialDetails?.port_of_clearance === "nill" ||  commercialDetails.port_of_clearance === "N/A"
//           ? "N/A"
//           : (commercialDetails?.port_of_clearance),
//       tooltip: "Port of Clearance",
//     },
    
//     {
//       title: "Custom Duty Date of Clearance",
//       label:
//         CustomDutyDateOfClearance === "" ||   CustomDutyDateOfClearance === "nill" || CustomDutyDateOfClearance === null ||  CustomDutyDateOfClearance === "N/A" || CustomDutyDateOfClearance === undefined
//           ? "N/A"
//           : moment(CustomDutyDateOfClearance).format("DD-MM-YYYY"),
//       tooltip: "Custom Duty Date of Clearance",
//     },
//     {
//       title: "Purchase Order No",
//       label:
//         commercialDetails?.purchase_order_no === "nill" || commercialDetails?.purchase_order_no === null ||  commercialDetails.purchase_order_no === "N/A"
//           ? "N/A"
//           : commercialDetails?.purchase_order_no,
//       tooltip: "Purchase Order No",
//     },
//     {
//       title: "Purchase Order Date",
//       label: commercialDetails?.purchase_order_date === null ||  commercialDetails?.purchase_order_date === "nill" ||  commercialDetails.purchase_order_date === "N/A"
//         ? "N/A"
//         : moment(commercialDetails?.purchase_order_date).format("DD-MM-YYYY"),
//       tooltip: "Purchase Order Date",
//     },
//     {
//       title: "Insurance Renewal",
//       label: commercialDetails?.insurance_renewal
//         ? moment(commercialDetails?.insurance_renewal).format("DD-MM-YYYY")
//         : "--",
//       tooltip: "Insurance Renewal ",
//     },
//     {
//       title: "Exchange Rate (at Remittance)",
//       label:
//         commercialDetails?.exchange_rate_rem === "nill" ||  commercialDetails.exchange_rate_rem === null ||  commercialDetails.exchange_rate_rem === "N/A"
//           ? "N/A"
//           : `1 USD = ${parseFloat(commercialDetails?.exchange_rate_rem).toFixed(
//               2
//             )} INR`,
//       tooltip: "Exchange Rate of Remitted OEM",
//     },
//     {
//       title: "Amount of Remitted OEM",
//       label:
//         commercialDetails?.amount_rem_to_oem === null ||  commercialDetails.amount_rem_to_oem === "nill" ||  commercialDetails.amount_rem_to_oem === "N/A"
//           ? "N/A"
//           : `₹${currency(
//               commercialDetails?.amount_rem_to_oem
//             )}/-`,
//       tooltip: "Amount of Remitted OEM",
//     },
//     {
//       title: "Date of Remittance to OEM",
//       label:
//         commercialDetails?.date_of_rem_to_oem === null ||  commercialDetails.date_of_rem_to_oem === "nill" ||  commercialDetails.date_of_rem_to_oem === "N/A"
//           ? "N/A"
//           : formatDate(commercialDetails?.date_of_rem_to_oem),
//       tooltip: "Date of Remittence to OEM",
//     },
//     {
//       title: "BOE No",
//       label:
//         commercialDetails.boe_no === null ||   commercialDetails.boe_no === "nill" ||  commercialDetails.boe_no === "N/A" || commercialDetails.boe_no === "None"
//           ? "N/A"
//           : commercialDetails?.boe_no,
//       tooltip: "Bill of entry (BOE)",
//     },
//     {
//       title: "Exchange Rate (at BOE)",
//       label:
//         commercialDetails?.exrate_boe === null ||  commercialDetails.exrate_boe === "nill" ||  commercialDetails.exrate_boe === "N/A"
//           ? "N/A"
//           : `1 USD = ${parseFloat(commercialDetails?.exrate_boe).toFixed(
//               2
//             )}  INR`,
//       tooltip: "Exchange rate at bill of entry",
//     },
//     {
//       title: "Custom Duty Value",
//       label:
//         `${
//           commercialDetails?.custom_duty_value === "nill" ||
//           commercialDetails?.custom_duty_value === "" ||  commercialDetails.custom_duty_value === null ||  commercialDetails.custom_duty_value === "N/A"
//             ? "N/A"
//             : `₹${currency(commercialDetails?.custom_duty_value)}/-`
//         }` || "N/A",
//       tooltip: "Custom duty value",
//     },
//     {
//       title: "Clearing Charges",
//       label:
//         commercialDetails?.clearing_charges === null ||  commercialDetails.clearing_charges === "nill" ||  commercialDetails.clearing_charges === "N/A" 
//           ? "N/A"
//           : `₹${currency(commercialDetails?.clearing_charges)}/-`,
//       tooltip: "Clearing Charges Amount",
//     },
//     {
//       title: "CHA Charge",
//       label:
//         commercialDetails?.cha_charges === null ||  commercialDetails.cha_charges === "nill" ||  commercialDetails.cha_charges === "N/A"
//           ? "N/A"
//           : `₹${currency(commercialDetails?.cha_charges)}/-`,
//       tooltip: "Customs House Agent (CHA) charge",
//     },
//     {
//       title: "GST Amount",
//       label: `${
//         commercialDetails?.gst_amount === null ||  commercialDetails.gst_amount === "nill" ||  commercialDetails.gst_amount === "N/A"
//           ? "N/A"
//           : `₹${currency(commercialDetails?.gst_amount)}/-`
//       }`,
//       tooltip: "GST Amount",
//     },
//     {
//       title: "Total Landed Cost with GST",
//       label: `${
//         commercialDetails?.total_landed_cost_with_gst === null  ||  commercialDetails.total_landed_cost_with_gst === "nill" ||   commercialDetails.total_landed_cost_with_gst === "N/A"
//           ? "N/A"
//           : `₹${currency(
//               commercialDetails?.total_landed_cost_with_gst
//             )}/-`
//       }`,
//       tooltip: "Total landed cost with gst",
//     },
//     {
//       title: "Transportation Charges (INR)",
//       label:
//         commercialDetails?.transportation_charges === null ||  commercialDetails.transportation_charges === "nill" ||  commercialDetails.transportation_charges === "N/A"
//           ? "N/A"
//           : `₹${currency(commercialDetails?.transportation_charges)}/-`,
//       tooltip: "Transportation Charges",
//     },
//     {
//       title: "Value of Sold Asset",
//       label: commercialDetails?.value_of_sold_asset === null ||  commercialDetails.value_of_sold_asset === "nill" ||  commercialDetails.value_of_sold_asset === "N/A" ? "N/A" : `₹${currency(commercialDetails?.value_of_sold_asset)}/-`,
//        tooltip: "Value of Sold Asset",
//     },
//     {
//       title: "Sold to customer name",
//       label:  commercialDetails?.sold_customer_name === null ||  commercialDetails.sold_customer_name === "nill" ||  commercialDetails.sold_customer_name === "N/A" ? "N/A" : commercialDetails?.sold_customer_name,
//        tooltip: "Sold to customer name",
//     },
//     // {
//     //   title: "Period of insurance",
//     //   label: commercialDetails?.period_of_insurance === null ||  commercialDetails.period_of_insurance === "nill" ||  commercialDetails.period_of_insurance === "N/A" ? "N/A" : commercialDetails?.period_of_insurance,
//     //    tooltip: "Period of insurance",
//     // },
//     // {
//     //   title: "Invoice Date",
//     //   label: commercialDetails?.invoice_date === null ||  commercialDetails.invoice_date === "nill" ||  commercialDetails.invoice_date === "N/A" ? "N/A" : moment(commercialDetails?.invoice_date).format("DD-MM-YYYY"),
//     //    tooltip: "Invoice Date",
//     // },
//     // {
//     //   title: "Payment Terms",
//     //   label: commercialDetails?.payment_terms === null ||  commercialDetails.payment_terms === "nill" ||  commercialDetails.payment_terms === "N/A" ? "N/A" : commercialDetails?.payment_terms,
//     //    tooltip: "Payment Terms",
//     // },

//   ];
// }

//   return (
//     <>
//       <div className=" text-xs w-full">
//         <h1 className="text-[#4b5563] font-extrabold mb-4">
//           Asset Commercial Details
//         </h1>
//         {commercialDetails === null ? (
//           "No Commercial Details Added"
//         ) : (
//           <div className="grid grid-cols-4 gap-2 ">
            
//             {/* First column with first 6 items */}
//             <div className="space-y-0 bg-white  shadow-md rounded-[4px] p-3">
//               {CommercialData?.slice(0, 6).map((item, index) => (
//                 <div title={item.tooltip}
//                   className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
//                   key={index}
//                 >
                  
//                     <div className="font-normal">{item.title}</div>
//                     <div
//                       className={`flex justify-start font-semibold text-xs    w-full items-center ${
//                         item.cssLabel
//                       }`}
//                     >
//                       {item.label}
//                     </div>
                  
//                 </div>
//               ))}
//             </div>

//             {/* Second column */}
//             <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
//               {CommercialData.slice(6, 11).map((item, index) => (
//                 <div title={item.tooltip}
//                   className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
//                   key={index}
//                 >
                  
//                     <div className="font-normal">{item.title}</div>
//                     <div
//                       className={`flex justify-start  font-semibold text-xs    w-full items-center ${
//                         item.cssLabel || ""
//                       }`}
//                     >
//                       {item.label}
//                     </div>
                  
//                 </div>
//               ))}
//             </div>

//             {/* Third column */}
//             <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
//               {CommercialData.slice(11, 16).map((item, index) => (
//                 <div title={item.tooltip}
//                   className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
//                   key={index}
//                 >
                  
//                     <div className="font-normal">{item.title}</div>
//                     <div
//                       className={`flex justify-start  font-semibold text-xs    w-full items-center ${
//                         item.cssLabel || ""
//                       }`}
//                     >
//                       {item.label}
//                     </div>
                  
//                 </div>
//               ))}
//             </div>

//             {/* Fourth column */}
//             <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
//               {CommercialData.slice(16).map((item, index) => (
//                 <div title={item.tooltip}
//                   className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start"
//                   key={index}
//                 >
                  
//                     <div className="font-normal">{item.title}</div>
//                     <div
//                       className={`flex justify-start  font-semibold text-xs    w-full items-center ${
//                         item.cssLabel
//                       }`}
//                     >
//                       {item.label}
//                     </div>
                  
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default AssetCommercial;
import React from "react";
import moment from "moment/moment";
import { Tooltip } from "@mui/material";
import { currency, formatDate } from "../../../../customFunctions/FormatDate";

const AssetCommercial = ({ commercialDetails, CustomDutyDateOfClearance }) => {
  if (commercialDetails === null) {
    return <div className="text-sm text-red-500 p-4 bg-white rounded-lg shadow">No Commercial Details Available</div>;
  }

  // Group data into sections like in the image
  const sectionData = {
    "Purchase Cost": [
      {
        title: "Total Landed Cost (+GST)",
        value: commercialDetails?.total_landed_cost_with_gst,
        currency: "₹",
        tooltip: "Total Landed Cost with GST"
      },
      {
        title: "Total Landed Cost",
        value: commercialDetails?.total_landed_cost,
        currency: "₹",
        tooltip: "Total Landed Cost"
      },
      {
        title: "Transportation Charge",
        value: commercialDetails?.transportation_charges,
        currency: "₹",
        tooltip: "Transportation Charges"
      }
    ],
    "Procurement Summary": [
      {
        title: "Order No",
        value: commercialDetails?.purchase_order_no,
        tooltip: "Purchase Order No"
      },
      {
        title: "Order Date",
        value: commercialDetails?.purchase_order_date,
        isDate: true,
        tooltip: "Purchase Order Date"
      },
      {
        title: "XXXXXXXXXX",
        value: "XXXXXXXXXX",
        tooltip: ""
      }
    ],
    
   
    "OEM/Seller Price": [
      {
        title: "Ex-Works Price",
        value: commercialDetails?.exworks_price,
        currency: "$",
        tooltip: "Ex-Works Price"
      },
      {
        title: "CIF charges($)",
        value: commercialDetails?.cif_charges,
        currency: "$",
        tooltip: "Cost, Insurance, and Freight (CIF) Charges"
      },
      {
        title: "Total Cost (Ex-Works+CIF)",
        value: commercialDetails?.total_cost,
        currency: "$",
        tooltip: "Total Cost = Ex Works (EXW) price + CIF Charges"
      }
    ],
    "Remittance Summary": [
      {
        title: "Exchange Rate at Rem.",
        value: commercialDetails?.exchange_rate_rem,
        format: val => `1 USD = ${parseFloat(val).toFixed(2)} INR`,
        tooltip: "Exchange Rate of Remitted OEM"
      },
      {
        title: "Amount of Rem. OEM",
        value: commercialDetails?.amount_rem_to_oem,
        currency: "₹",
        tooltip: "Amount of Remitted OEM"
      },
      {
        title: "Date of remittence",
        value: commercialDetails?.date_of_rem_to_oem,
        isDate: true,
        tooltip: "Date of Remittence to OEM"
      }
    ],
   
    "Insurance Details": [
      {
        title: "Insurance No",
        value: commercialDetails?.insurance_no,
        tooltip: "Insurance Number"
      },
      {
        title: "Insurance Renewal Date",
        value: commercialDetails?.insurance_renewal,
        isDate: true,
        tooltip: "Insurance Renewal Date"
      }
    ],
    "Disposed Asset": [
      {
        title: "Sale Asset Price",
        value: commercialDetails?.value_of_sold_asset,
        currency: "₹",
        tooltip: "Value of Sold Asset"
      },
      {
        title: "End Customer",
        value: commercialDetails?.sold_customer_name,
        tooltip: "Sold to customer name"
      },
      {
        title: "Date of Sales",
        value: commercialDetails?.sale_date,
        isDate: true,
        tooltip: "Date of Sales"
      }
    ]
  };

  const largeData = {
    "Customs & Exchange Overview": [
      {
        title: "Port of Clearance",
        value: commercialDetails?.port_of_clearance,
        tooltip: "Port of Clearance"
      },
      {
        title: "Date of Clearance",
        value: CustomDutyDateOfClearance,
        isDate: true,
        tooltip: "Custom Duty Date of Clearance"
      },
      {
        title: "BOE NO",
        value: commercialDetails?.boe_no,
        tooltip: "Bill of entry (BOE)"
      },
      {
        title: "Exchange Rate @ BOE",
        value: commercialDetails?.exrate_boe,
        format: val => `1 USD = ${parseFloat(val).toFixed(2)} INR`,
        tooltip: "Exchange rate at bill of entry"
      },
      {
        title: "Custom Duty",
        value: commercialDetails?.custom_duty_value,
        currency: "₹",
        tooltip: "Custom duty value"
      },
      {
        title: "Clearing Charges",
        value: commercialDetails?.clearing_charges,
        currency: "₹",
        tooltip: "Clearing Charges Amount"
      },
      {
        title: "CHA Charges",
        value: commercialDetails?.cha_charges,
        currency: "₹",
        tooltip: "Customs House Agent (CHA) charge"
      },
      {
        title: "GST Amount",
        value: commercialDetails?.gst_amount,
        currency: "₹",
        tooltip: "GST Amount"
      }
    ],
  }

  const formatValue = (item) => {
    if (item.value === null || item.value === "nill" || item.value === "N/A") {
      return "N/A";
    }
    
    if (item.format) {
      return item.format(item.value);
    }
    
    if (item.isDate) {
      return moment(item.value).format("DD-MM-YYYY");
    }
    
    if (item.currency) {
      return `${item.currency}${currency(item.value)}/-`;
    }
    
    return item.value;
  };

  return (
    <div className="bg-white ">
  <h2 className="text-base font-medium text-gray-800 mb-2">Asset Commercial Details</h2>
  
  <div className="flex flex-col md:flex-row gap-3">
    {/* Left Column - Compact Cards */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 flex-1">
      {Object.entries(sectionData).map(([sectionTitle, items]) => (
        <div key={sectionTitle} className="border rounded p-3 bg-gray-50">
          <h3 className="text-sm font-semibold text-green-2 mb-2">{sectionTitle}</h3>
          <div className="space-y-2">
            {items.map((item, index) => (
              <div key={index} className="flex justify-between items-center text-xs" title={item.tooltip}>
                <span className="text-gray-600">{item.title}</span>
                <span className="font-medium text-gray-800">
                  {formatValue(item)}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>

    {/* Right Column - Compact Card */}
    <div className="flex-1">
  {Object.entries(largeData).map(([sectionTitle, items]) => (
    <div key={sectionTitle} className="border border-gray-200 rounded-lg shadow-sm overflow-hidden bg-white">
      {/* Section Header */}
      <div className="bg-gray-50 px-4 py-3 border-b border-gray-200">
        <h3 className="text-md font-semibold text-green-2 flex items-center">
          <svg className="w-4 h-4 mr-2 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </svg>
          {sectionTitle}
        </h3>
      </div>
      
      {/* Data Items */}
      <div className="divide-y divide-gray-200">
        {items.map((item, index) => (
          <div 
            key={index} 
            className="px-4 py-3 hover:bg-gray-50 transition-colors duration-150 group relative"
          >
            <div className="flex justify-between items-center" title={item.tooltip}>
              <div className="flex items-center">
                <span className="text-xs font-medium text-gray-600">
                  {item.title}
                </span>
                {/* {item.tooltip && (
                  <span className="ml-1 text-gray-400 hover:text-gray-500 cursor-help">
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="absolute invisible group-hover:visible z-10 w-48 bg-gray-900 text-white text-xs rounded p-2 -mt-8 -ml-4 opacity-90">
                      {item.tooltip}
                    </span>
                  </span>
                )} */}
              </div>
              
              <span className="text-xs font-medium text-gray-900" >
                {/* {item.currency && (
                  <span className="text-gray-500 mr-1">{item.currency}</span>
                )} */}
                {formatValue(item)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>
  </div>
</div>
  );
};

export default AssetCommercial;