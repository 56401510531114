import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSearchData } from './useSearchData';
import { useParams } from 'react-router-dom';
import { asset_by_type, asset_in_maintenance, get_all_assets, inactive_assets } from '../apis/AssetsApis/getAllAssets';

export const useSearchAssets = ({ setShowCurrentTab }) => {
  const [assetDetails, setAssetDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [filter, setFilter] = useState("assetnumber");
  const [searchType, setSearchType] = useState("assetnumber");
  const [searchValue, setSearchValue] = useState("");
  const [searchTypeArray, setSearchTypeArray] = useState([]);
  const [showSearchDiv, setShowSearchDiv] = useState([]);
  const [getSearchList, setGetSearchList] = useState(false);
  const { query } = useParams();

  const { isPending: searchLoading, data: searchData } = useSearchData();

  // Derive search type array based on filter
  const updateSearchTypeArray = useCallback(() => {
    if (!searchData) return;

    switch (filter) {
      case "assetnumber":
        setSearchTypeArray(searchData?.asset_data?.map((item) => item.asset_no) || []);
        break;
      case "category":
        setSearchTypeArray(searchData?.category || []);
        break;
      case "yom":
        setSearchTypeArray(searchData?.YOM || []);
        break;
      default:
        setSearchTypeArray([]);
    }
  }, [filter, searchData]);

  const fetchAssets = useCallback(
    async (searchParam = "") => {
      setLoader(true);
      try {
        let assetData;

        if (searchParam) {
          const { data } = await asset_by_type(itemOffset, searchType, searchParam);
          assetData = data;
        } else if (query === "inactive") {
          const { data } = await inactive_assets(itemOffset);
          assetData = data;
        } else if (query === "inMaintenance") {
          const { data } = await asset_in_maintenance(itemOffset);
          assetData = data;
        } else {
          const { data } = await get_all_assets(itemOffset);
          assetData = data;
        }

        if (assetData?.message !== "valid token is missing") {
          setAssetDetails(assetData);
        }
      } catch (error) {
        console.error("Error fetching assets:", error);
      } finally {
        setLoader(false);
      }
    },
    [itemOffset, query, searchType]
  );

  // Perform search in the local search type array
  const search = useCallback(
    (query) => {
      if (!query) {
        setShowSearchDiv([]);
        return;
      }

      const filteredData = searchTypeArray.filter((item) =>
        item.toLowerCase().includes(query.toLowerCase())
      );

      setShowSearchDiv(filteredData.length > 0 ? filteredData : ["no results"]);
    },
    [searchTypeArray]
  );

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchValue(query);
    search(query);
  };

  const handleSearchClick = (item) => {
    fetchAssets(item);
  };

  // Sync filter changes
  useEffect(() => {
    updateSearchTypeArray();
  }, [filter, updateSearchTypeArray]);

  // Fetch data on initial load and relevant dependency changes
  useEffect(() => {
    fetchAssets();
  }, [fetchAssets, setShowCurrentTab, deleted, itemOffset, getSearchList]);

  // React to `searchLoading` updates
  useEffect(() => {
    if (!searchLoading) {
      setGetSearchList(false);
      updateSearchTypeArray();
    }
  }, [searchLoading, updateSearchTypeArray]);

  return useMemo(
    () => ({
      setFilter,
      searchValue,
      handleSearch,
      handleSearchClick,
      showSearchDiv,
      loader,
      setLoader,
      deleted,
      setDeleted,
      assetDetails,
      fetchAssets,
      showAllData: true, // Set this dynamically if required
      getSearchList,
      itemOffset,
      setItemOffset,
    }),
    [
      setFilter,
      searchValue,
      handleSearch,
      handleSearchClick,
      showSearchDiv,
      loader,
      deleted,
      assetDetails,
      fetchAssets,
      getSearchList,
      itemOffset,
    ]
  );
};
