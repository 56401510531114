import React, { useState } from "react";
import Documents from "../../../InnerTabs/Documents";

const PhotoData = ({ imageUrl, NoImage}) => {
  const [openDoc, setOpenDoc] = useState(false);
  const [openPhoto, setOpenPhoto] = useState(false);
  
  return (
    <>
   <div className="border rounded-xl ">
   {imageUrl.length > 0 ? (
        <>
          <div className=" max-w-sm  m-2 items-center flex justify-center">
            <img
              src={imageUrl[imageUrl.length - 1]?.image_uri || NoImage[0]?.imgUrl}
              alt=""
              className="rounded-lg max-w-sm "
              width={200}
              height={200}
            />
            
          </div>
        </>
      ) : (
        <>
          <div
            className=" max-w-sm  m-2 items-center flex justify-center
                    "
          >
            <img
              src={NoImage[0].imgUrl}
              alt="Main Asset"
              width={200}
              height={200}
            
              className="rounded-lg max-w-sm"
            />
          </div>
        </>
      )}
   </div>
    </>
  );
};

export default PhotoData;
