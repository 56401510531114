import React, { useState } from "react";

import SalesNavbar from "../../components/Sales/SalesNavbar";
import SalesSidebar from "../../components/Sales/SalesSidebar";

const SalesLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
   
    <div className="">
      {/* Navbar */}
        <SalesNavbar  isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>

        <div className="flex">
          {/* Sidebar */}
          <SalesSidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
          {/* Main Content */}
          <div className="p-2 text-xs">{children}</div>
        </div>
    </div>
  );
};

export default SalesLayout;
