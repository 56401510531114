import axios from "axios";
import React, { useState } from "react";
import { setBaseUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import SalesParson from "../../tables/SettingsTable/SalesParson";
import Layout from "../../Layout";
import { useToast } from "../../../Context/ToastContext";

function NewEmployee() {
  const {addToast} = useToast( )

  const selectOptions = [
    {
      value: "ASSETS.ALL",
      label: "ASSETS.ALL",
    },
    { value: "ASSETS.VIEW", label: "ASSETS.VIEW" },
    { value: "ASSETS.CRU", label: "ASSETS.CRU" },
    { value: "MAINT.ALL", label: "MAINT.ALL" },
    { value: "MAINT.CRU", label: "MAINT.CRU" },
    { value: "MAINT.VIEW", label: "MAINT.VIEW" },

    { value: "LEASE.ALL", label: "LEASE.ALL" },
    { value: "LEASE.CRU", label: "LEASE.CRU" },
    { value: "LEASE.VIEW", label: "LEASE.VIEW" },
    { value: "OPERATOR.ALL", label: "OPERATOR.ALL" },
    { value: "OPERATOR.CRU", label: "OPERATOR.CRU" },
    { value: "OPERATOR.VIEW", label: "OPERATOR.VIEW" },
    { value: "ADMIN.ALL", label: "ADMIN.ALL" },
  ];
  const [selectOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [employeeDetail, setEmployeeDetail] = useState({ email: "", name: "" });

  const addEmployeePerms = async () => {
    let permissions = selectOption.map((per) => per.value);
    try {
      const { data } = await axios.post(
        `${setBaseUrl}/company/add-permissions`,
        {
          email: employeeDetail.email,
          permissions: permissions,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
      addToast("Employee permissions added successfully", "success");
    } catch (error) {
      console.error(error);
      addToast("Employee permissions added failed", "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${setBaseUrl}/company/add-employee`,
        employeeDetail,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );

      await addEmployeePerms();
    
    addToast("Employee added successfully", "success");
    navigate("/settings")
    } catch (error) {
      console.error(error);
      addToast("Employee added failed", "error");
    } 
  };


  const [salesPerson, setSalesPerson] = useState(false)

  return (
      <>
    <Layout>

  <div className="h-full w-full dark:text-white">
  {/* Header Section */}
  <div className="flex justify-between items-center px-8 py-4 border-b border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800">
    <button
      onClick={() => navigate("/settings")}
      className="bg-white hover:bg-gray-200 text-black px-4 py-2 rounded-md shadow-md transition"
    >
      Back
    </button>


    <div className="flex items-center gap-2">
      <button className={`buttons ${salesPerson? 'bg-white text-black':'bg-green-2 text-white'}` } onClick={() => setSalesPerson(false)}>
        Operations
      </button>
      <button className={`buttons ${!salesPerson? 'bg-white text-black':'bg-green-2 text-white'}` } onClick={() => setSalesPerson(true)}>
       Sales & Service
      </button>
    </div>
  </div>

  <div className="flex flex-col lg:flex-row gap-8 px-8 py-6">
    {/* Employee Details Section */}
    {!salesPerson ?   <div className="w-full lg:w-1/2 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">
        Employee Details
      </h2>
      
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
         {/* Name Input */}
         <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Name
          </label>
          <input
            type="text"
            name="name"
            required
            placeholder="Enter Name"
            className="w-full p-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-green-400 dark:bg-gray-700 dark:text-white"
            onChange={(e) =>
              setEmployeeDetail({ ...employeeDetail, name: e.target.value })
            }
          />

        </div>

        {/* Email Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Email
          </label>
          <input
            type="email"
            name="email"
            required
            placeholder="Enter Email"
            className="w-full p-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-green-400 dark:bg-gray-700 dark:text-white"
            onChange={(e) =>
              setEmployeeDetail({ ...employeeDetail, email: e.target.value })
            }
          />
        </div>

       

        {/* Permissions Dropdown */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Permissions
          </label>
          <Select
            isMulti
            onChange={setSelectedOption}
            options={selectOptions}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "100%",
                fontSize: "12px",
              }),
              control: (provided, state) => ({
                ...provided,
                border: "1px solid #D1D5DB",
                borderRadius: "4px",
                boxShadow: state.isFocused
                  ? "0px 0px 5px rgba(156, 163, 175, 0.5)"
                  : "none",
                "&:hover": {
                  borderColor: "#2563EB",
                },
              }),
            }}
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-green-2 hover:bg-green-600 text-white font-medium py-2 rounded-md transition-all duration-200 shadow-md"
        >
          Submit
        </button>
      </form>
    </div> :   <div className="w-full lg:w-1/2">
    {/* Sales Person Section */}
      <SalesParson />
    </div>}
  

  
  </div>
</div>
    </Layout>

    </>
  );
}

export default NewEmployee;
