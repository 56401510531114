import React, { useState } from "react";
import { setBaseUrl } from "../../../config";
import { useParams } from "react-router-dom";
import { useToast } from "../../../Context/ToastContext";
import { formatDate } from "../../../customFunctions/FormatDate";
import { FiSend } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";

const Comments = ({ commentsFromParent, reject, servicePeron }) => {
  const [addComment, setAddComment] = useState("");
  const { id } = useParams();
  const { addToast } = useToast();

  const handleSubmit = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/maintenance/add-comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ comment: addComment, maintenance_id: id }),
      });
      if (res.status === 200) {
        setAddComment("");
        addToast("Comment added successfully", "success");

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        addToast("Failed to add comment", "error");
      }
    } catch (error) {
      addToast("An error occurred", "error");
    }
  };

  return (
    <div className="space-y-6">
      {/* Main Comments Section */}
      <div className="border-b border-gray-200 pb-2">
        <h3 className="text-lg font-medium p-2 rounded w-full text-blue-2 bg-gray-200">
          Discussion
        </h3>
      </div>

      {/* <div className="comments-history space-y-4 max-h-80 overflow-y-auto pr-2 border ">
                {commentsFromParent?.length > 0 ? (
                    commentsFromParent.map((comment, index) => (
                        <div
                            key={comment.id || index}
                            className={`flex ${comment.by_me ? 'justify-end' : 'justify-start'}`}
                        >
                            <div className={`flex max-w-xs md:max-w-md lg:max-w-lg ${comment.by_me ? 'flex-row-reverse' : ''}`}>
                                <div className="flex-shrink-0 mr-3 ml-3">
                                    <FaUserCircle className="h-8 w-8 text-gray-400" />
                                </div>
                                <div>
                                    <div className={`p-3 rounded-lg ${comment.by_me ? 'bg-blue-50' : 'bg-gray-50'}`}>
                                        <p className="text-sm text-gray-800">{comment.comment}</p>
                                    </div>
                                    <div className={`mt-1 text-xs text-gray-500 ${comment.by_me ? 'text-right' : 'text-left'}`}>
                                        {comment?.visit_date && formatDate(comment?.visit_date)}
                                        <span className="mx-1">•</span>
                                        {comment.by_me ? 'You' : <>
                                        {servicePeron.map((person) => { if(person.id === comment.comment_by_service_person) {
                                            return (<>{person.name}</>)
                                        }})}
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center py-4 text-gray-500 text-sm">
                        No comments yet. Start the conversation.
                    </div>
                )}
            </div> */}
      <div className="comments-history bg-light-7 space-y-4 max-h-80 overflow-y-auto pr-2 border flex flex-col-reverse">
        {commentsFromParent?.length > 0 ? (
          [...commentsFromParent].reverse().map((comment, index) => (
            <div
              key={comment.id || index}
              className={`flex ${
                comment.by_me ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`flex max-w-xs md:max-w-md lg:max-w-lg ${
                  comment.by_me ? "flex-row-reverse" : ""
                }`}
              >
                <div className="flex-shrink-0 mr-3 ml-3">
                  <FaUserCircle className="h-8 w-8 text-gray-400" />
                </div>
                <div>
                  <div
                    className={`p-3 rounded-lg ${
                      comment.by_me ? "bg-blue-50" : "bg-gray-50"
                    }`}
                  >
                    <p className="text-sm text-gray-800">{comment.comment}</p>
                  </div>
                  <div
                    className={`mt-1 text-xs text-gray-500 ${
                      comment.by_me ? "text-right" : "text-left"
                    }`}
                  >
                    {comment?.visit_date && formatDate(comment?.visit_date)}
                    <span className="mx-1">•</span>
                    {comment.by_me ? (
                      "You"
                    ) : (
                      <>
                        {servicePeron.map((person) => {
                          if (person.id === comment.comment_by_service_person) {
                            return <>{person.name}</>;
                          }
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4 text-gray-500 text-sm">
            No comments yet. Start the conversation.
          </div>
        )}
      </div>

      {/* Service Person Status Section */}
      {reject?.length > 0 && (
        <div className="mt-6">
          <div className="border-b border-gray-200 pb-2">
            <h3 className="text-lg font-medium text-gray-700">
              Service Requests Status
            </h3>
          </div>
          <div className="mt-3 space-y-3">
            {reject.map((rej, i) => (
              <div
                key={i}
                className="bg-white p-4 rounded-lg border border-gray-200 shadow-xs"
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center mr-3">
                      <span className="text-gray-600 text-sm font-medium">
                        {rej?.name
                          ?.split(" ")
                          .map((n) => n[0])
                          .join("")
                          .toUpperCase()}
                      </span>
                    </div>
                    <span className="font-medium text-gray-700">
                      {rej.name}
                    </span>
                  </div>
                  <span
                    className={`text-xs px-2.5 py-1 rounded-full font-medium ${
                      rej.is_accepeted === true
                        ? "bg-green-100 text-green-700 border border-green-100"
                        : rej.is_accepeted === false
                        ? "bg-red-50 text-red-700 border border-red-100"
                        : "bg-yellow-50 text-yellow-700 border border-yellow-100"
                    }`}
                  >
                    {rej.is_accepeted === true
                      ? "Accepted"
                      : rej.is_accepeted === false
                      ? "Rejected"
                      : "Pending"}
                  </span>
                </div>
                {(rej.comment_for_reject || rej.comment) && (
                  <div className="mt-3 pl-13">
                    <div className="text-sm text-gray-600 bg-gray-50 p-3 rounded-lg">
                      <p className="font-medium text-xs text-gray-500 mb-1">
                        NOTE
                      </p>
                      <p>{rej.comment_for_reject || rej.comment}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Add Comment Section */}
      <div className="mt-6">
        <div className="relative">
          <textarea
            className="w-full p-3 border border-green-800 rounded-lg text-sm focus:border-green-500 focus:ring-1 focus:ring-green-500 transition"
            placeholder="Type your message here..."
            value={addComment}
            onChange={(e) => setAddComment(e.target.value)}
            rows={3}
          ></textarea>
          <button
            className="absolute right-2 bottom-2 bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 transition flex items-center justify-center"
            onClick={handleSubmit}
            disabled={!addComment.trim()}
          >
            <FiSend className="h-4 w-4" />
          </button>
        </div>
        <p className="text-xs text-gray-500 mt-1">
          Type your message and click the send button
        </p>
      </div>
    </div>
  );
};

export default Comments;
