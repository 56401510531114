import React, { Fragment, useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { TableLoader } from "../../TableLoader";
import ReactPaginate from "react-paginate";
import { CurrentItems } from "./CurrentItems";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import HistoryBtn from "../../Buttons/HistoryBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import MISReportBtn from "../../Buttons/MISReportBtn";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CiFilter } from "react-icons/ci";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { IoMdAdd } from "react-icons/io";
import Loader from "../../Loader";
import { IoIosClose } from "react-icons/io";
import { useSearchAssets } from "../../../hooks/useSearchAssets";
import AssetSearch from "../../Search/AssetSearch";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { CiViewTable } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSearchParams } from "react-router-dom";
import AssetTableHeader from "./AssetTableHeader";
import NewTable from "./NewTable";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
// Pagination
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function AssetTable({ showCurrentTab, setShowCurrentTab }) {
  const {
    setFilter,
    searchValue,
    handleSearch,
    handleSearchClick,
    showSearchDiv,
    loader,
    setLoader,
    deleted,
    setDeleted,
    assetDetails,
    getAllAssets,

    showAllData,
    getSearchList,
    itemOffset,
    setItemOffset,
  } = useSearchAssets(setShowCurrentTab);

  // "all Assets",
  // "active",
  // "inactive",
  // "never assigned",
  // "expiring",
  // "expired",
  let selected = [
    {
      title: "All Assets",
      label: "all Assets",
    },
    {
      title: "Active",
      label: ["active", "expiring"],
    },
    {
      title: "Free",
      label: ["inactive", "never assigned"],
    },
    {
      title: "expired",
      label: ["expired"],
    },
  ];

  const [selectedItem, setSelectedItem] = useState("all Assets");
  const [openFilter, setOpenFilter] = useState(false);
  const [openTable, setopenTable] = useState(false);
  const [openFilterStatus, setFilterStatus] = useState(false);
  const [openYom, setOpenYom] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSold, setOpenSold] = useState(null);
  const uniqueYears = [...new Set(assetDetails.map((details) => details.yom))];
  const uniqueCategory = [
    ...new Set(assetDetails.map((details) => details.category)),
  ];
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // const [ascending, setAscending] = useState(null);
  // const [descending, setDescending] = useState(null);
  const [device, setDevice] = useState("all device");
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  // const a = useParams()
  let [searchParams, setSearchParams] = useSearchParams();

  if (searchParams.get("asset") === "free") {
    setTimeout(() => {
      setSelectedItem(["Free", ["inactive", "never assigned"]]);
    }, 500);
  } else if (searchParams.get("asset") === "sold") {
    setTimeout(() => {
      setOpenSold(true);
    }, 500);
  } else {
  }

  // header of the table
  const sortHeader = [
    "Asset No",
    "Model",
    "Make",
    "YOM",
    "Category",
    "Lease Status",
  ];

  const [openSort, setOpenSort] = useState(false);
  // sort names are storing
  const [sort, setSort] = useState(null);
  // for input in sorting
  const [inputSearch, setInputSearch] = useState("");
  // sorting the data in ascending order using the whole filter
  const [sortOrder, setSortOrder] = useState("asc");
  // Filtered header based on search input
  const filteredHeaders = sortHeader.filter((header) =>
    header.toLowerCase().includes(inputSearch.toLowerCase())
  );
  // Functions to implement Search in the filter
  const [inputFilter, setInputFilter] = useState("");

  const buttons = [
    {
      label: "Status",
      onClick: () => {
        setFilterStatus(!openFilterStatus);
        setOpenCategory(false);
        setOpenYom(false);
      },
    },
    {
      label: "YOM",
      onClick: () => {
        setOpenYom(!openYom);
        setOpenCategory(false);
        setFilterStatus(false);
      },
    },
    {
      label: "Category",
      onClick: () => {
        setOpenCategory(!openCategory);
        setOpenYom(false);
        setFilterStatus(false);
      },
    },
    {
      label: "Tracker Installed",
      onClick: () => {
        setDevice("tracker installed");
        setOpenCategory(false);
        setOpenYom(false);
        setFilterStatus(false);
        setOpenFilter(false);
      },
    },
    {
      label: "Sold Machines",
      onClick: () => {
        setOpenSold(true);
        setOpenFilter(false);
      },
    },
    // {
    //   label: "filter",
    //   onClick: null, // Disabled button, no action
    //   disabled: true,
    //   icon: <IoMdAdd />,
    // },
  ];

  // Filter the buttons based on the input value
  const filteredButtons = buttons.filter((button) =>
    button.label.toLowerCase().includes(inputFilter.toLowerCase())
  );

  // to stack filterations
  const [filteredDataStack, setFilteredDataStack] = useState([]);

  const [sortedAssetDetails, setSortedAssetDetails] = useState(assetDetails);

  const activeCount = assetDetails.filter(
    (item) =>
      item?.lease_status?.startsWith("expiring") ||
      item?.lease_status === "active"
  ).length;

  const FreeAssetCount = assetDetails.filter(
    (item) =>
      item?.lease_status === "never assigned" ||
      item?.lease_status === "inactive"
  ).length;
  const inactiveCount = assetDetails.filter(
    (item) => item?.lease_status === "inactive"
  ).length;
  const expiredCount = assetDetails.filter(
    (item) => item?.lease_status === "expired"
  ).length;
  const soldAssets = assetDetails.filter((item) => item?.is_sold).length;

  const trackerInstalledAssets = assetDetails.filter(
    (item) => item?.device_no
  ).length;

  const handleClearStatusFilter = () => {
    if (searchParams.get("asset") === "free") {
      setSearchParams("");
    }
    setSelectedItem("all Assets");
  };

  const handleCloseSold = () => {
    if (searchParams.get("asset") === "sold") {
      setSearchParams("");
    }
    setOpenSold(null);
    setFilteredDataStack([]);
  };

  let tableArray = [10, 50, 100, 150, assetDetails.length];
  const [selectTableData, setSelectTableData] = useState(20);

  const [filteredData, setFilteredData] = useState(assetDetails);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleExport = () => {
    const dataToExport =
      selectedRows.length > 0
        ? selectedRows
        : filteredData.map((item, index) => ({ ...item, id: index + 1 }));
    const csvContent = [
      Object.keys(dataToExport[0]).join(","), // CSV headers
      ...dataToExport.map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`) // Escape values with quotes
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "exported_table.csv";
    link.click();

    URL.revokeObjectURL(url); // Clean up URL
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = selectTableData; //10
  // Check if filteredDataStack is empty

  // Pagination logic continues
  const indexOfLastRow = Math.min(
    currentPage * rowsPerPage,
    assetDetails.length
  ); // Clamp to total length
  const indexOfFirstRow = Math.min(
    indexOfLastRow - rowsPerPage + 1,
    filteredDataStack.length
  ); // Adjust for the last page

  const currentRows = filteredDataStack.slice(
    indexOfFirstRow - 1,
    indexOfLastRow
  ); // Adjust for zero-based index

  const totalPages = Math.ceil(assetDetails.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="">
        <AssetTableHeader
          selectTableData={selectTableData}
          filteredDataStack={filteredDataStack}
          assetDetails={assetDetails}
          activeCount={activeCount}
          expiredCount={expiredCount}
          FreeAssetCount={FreeAssetCount}
          soldAssets={soldAssets}
          trackerInstalledAssets={trackerInstalledAssets}
        />
      </div>

      <div className="px-2 flex mt-2 flex-col md:flex-row justify-between  items-center md:space-y-0 pb-2">
        {/* Header */}
        <div className="flex justify-start gap-3">
          {/* Sorting Suggestions */}

          {/* <button
            className=" bg-white shadow-md flex items-center text-[10px] px-2 py-0.5 rounded-[6px] text-light-2 cursor-pointer"
            onClick={() => setOpenSort(!openSort)}
          >
            <BiSortAlt2 className="h-4 w-4 rounded" />
            Sort
          </button> */}
          {/* <div
            className={` bg-white shadow-md flex items-center text-[10px] px-2 py-0.5 rounded-[6px] text-light-2 cursor-pointer
            
            `}
            onClick={() => setOpenFilter(true)}
          >
            <CiFilter className="w-4 h-4" />
            Filter
          </div> */}
        </div>

        <div className="flex  flex-row gap-2 justify-between items-center ">
          {/* Table */}
          <div className="flex gap-4 text-xs justify-between z-20">
            {selectedItem !== "all Assets" && (
              <>
                <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1 ">
                  {selectedItem[0]}
                  <IoIosClose
                    className="h-4 w-4"
                    onClick={handleClearStatusFilter}
                  />
                </button>
              </>
            )}

            {selectedYear && (
              <>
                <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                  {selectedYear}
                  <IoIosClose
                    className="h-4 w-4"
                    onClick={() => {
                      setSelectedYear(null);
                      setFilteredDataStack([]);
                    }}
                  />
                </button>
              </>
            )}

            {selectedCategory && (
              <>
                <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                  {selectedCategory}
                  <IoIosClose
                    className="h-4 w-4"
                    onClick={() => {
                      setSelectedCategory(null);
                      setFilteredDataStack([]);
                    }}
                  />
                </button>
              </>
            )}

            {openSold && (
              <>
                <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                  Sold
                  <IoIosClose className="h-4 w-4" onClick={handleCloseSold} />
                </button>
              </>
            )}

            {device !== "all device" && (
              <>
                <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                  {device}
                  <IoIosClose
                    className="h-4 w-4"
                    onClick={() => setDevice("all device")}
                  />
                </button>
              </>
            )}

            {/* Sorted Data */}
            {sort && (
              <>
                <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                  {sort}
                  <IoIosClose
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => setSort(null)}
                  />
                </button>
              </>
            )}
            {/* Open table */}
            {openTable && (
              <>
                {/* Dropdown Menu */}
                <div
                  className={`absolute mt-5 border transition-all ease-in-out duration-300 z-[9999]   text-xs
                  flex flex-col justify-center 
                 bg-white w-fit shadow-2xl h-fit items-start transform opacity-0 scale-95 ${
                   selectTableData === null ? "right-[10.2%]" : "right-[21%]"
                 }`}
                  style={{
                    opacity: openTable ? 1 : 0,
                    transform: openTable ? "scale(1)" : "scale(0.95)",
                  }}
                >
                  {tableArray.map((table) => (
                    <button
                      key={table}
                      className="hover:bg-[#EEE] w-full py-1 border-b px-2"
                      onClick={() => setSelectTableData(table)}
                    >
                      {table}
                    </button>
                  ))}
                  <span
                    className="text-xs p-1 cursor-pointer hover:bg-[#EEE]"
                    onClick={() => {
                      setSelectTableData(null);
                      setopenTable(false);
                    }}
                  >
                    all data
                  </span>
                </div>

                {/* Backdrop */}
                <div
                  className="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-5 z-50 transition-opacity duration-300"
                  style={{ opacity: openTable ? 1 : 0 }}
                  onClick={() => setopenTable(false)}
                />
              </>
            )}

            {/* filter */}
            {openFilter && (
              <>
                <div
                  className="absolute mt-5 z-[9999] left-[8%] flex flex-col justify-center pt-2 pb-2 shadow-2xl
                  bg-white dark:bg-[#070e18] w-[150px] rounded-lg h-fit items-center"
                >
                  <input
                    type="text"
                    className="bg-transparent w-[130px] h-7 rounded-md text-xs"
                    placeholder="Search..."
                    value={inputFilter}
                    onChange={(e) => setInputFilter(e.target.value)}
                  />

                  {filteredButtons.map((button, index) => (
                    <button
                      key={index}
                      className={`hover:bg-[#EEE] mt-2  w-[130px]  py-1 hover:rounded-md border-b`}
                      onClick={button.disabled ? null : button.onClick}
                    >
                      {button.icon && (
                        <span className="mr-2">{button.icon}</span>
                      )}
                      {button.label}
                    </button>
                  ))}

                  <button className="flex items-center justify-center rounded-md py-2 cursor-not-allowed hover:bg-[#EEE] w-[130px] ">
                    <IoMdAdd />
                    filter
                  </button>
                </div>

                {openFilterStatus && (
                  <>
                    <div
                      onMouseLeave={() => setFilterStatus(false)}
                      className="absolute mt-5 z-[9999] left-[20.3%]
                    flex flex-col justify-center  pt-6 shadow-2xl
                  bg-white   w-[150px] border rounded-lg h-fit items-center"
                    >
                      {selected.map((select) => {
                        return (
                          <>
                            <button
                              className={`hover:bg-[#EEE] w-[125px] border-b hover:rounded-md px-2  py-2 ${
                                selectedItem === select ? "bg-[#EEE]" : ""
                              }  `}
                              onClick={() => {
                                setSelectedItem([select.title, select.label]);
                                setFilterStatus(false);
                                setOpenFilter(false);
                              }}
                              key={select}
                            >
                              {capitalizeFirstLetter(select.title)}
                            </button>
                          </>
                        );
                      })}

                      <button className="flex items-center justify-start py-2">
                        <IoMdAdd />
                        Status
                      </button>
                    </div>
                  </>
                )}

                {openYom && (
                  <>
                    <>
                      <div
                        onMouseLeave={() => setOpenYom(false)}
                        className="absolute z-[9999] left-[20.5%]
                    flex flex-col justify-center hide-scrollbar  h-[300px] overflow-x-auto shadow-2xl  overflow-y-auto
                  bg-white w-[150px] pt-[20rem] top-[21.5%]  rounded-lg  items-center  "
                      >
                        {/* <input
                          type="text"
                          disabled
                          className="bg-transparent w-[130px] h-7 absolute top-2 rounded-md text-xs"
                          placeholder="Search..."
                        /> */}
                        <div className="mt-20 ml-2">
                          {uniqueYears.sort().map((year) => (
                            <>
                              <button
                                key={year}
                                className="hover:bg-[#EEE]  border-b hover:rounded-md w-[130px] py-2"
                                onClick={() => setSelectedYear(year)}
                              >
                                {year}
                              </button>
                            </>
                          ))}
                        </div>
                      </div>
                    </>
                  </>
                )}

                {openCategory && (
                  <>
                    <>
                      <div
                        onMouseLeave={() => setOpenCategory(false)}
                        className="absolute mt-5 z-[9999] left-[20.3%]
                      flex flex-col justify-center  pt-6 shadow-2xl
                      bg-white w-[150px]  rounded-lg h-fit pb-6 items-center"
                      >
                        {uniqueCategory.map((category) => (
                          <button
                            key={category}
                            className="hover:bg-[#EBEBEB] border-b w-full py-2"
                            onClick={() => setSelectedCategory(category)}
                          >
                            {category}
                          </button>
                        ))}
                      </div>
                    </>
                  </>
                )}

                <div
                  className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                  onClick={() => setOpenFilter(false)}
                />
              </>
            )}
            {/* open Sort */}
            {openSort && (
              <>
                <div className="absolute text-xs w-[200px] h-fit z-30 top-[22%] left-[1%] rounded-lg p-2 border bg-white">
                  <input
                    type="text"
                    className="bg-transparent w-[180px] h-7 rounded-md text-xs"
                    placeholder="Search..."
                    onChange={(e) => setInputSearch(e.target.value)}
                    value={inputSearch}
                  />
                  <ul className="mt-2">
                    {filteredHeaders.length > 0 ? (
                      filteredHeaders.map((header) => (
                        <li
                          className="px-2 py-2 hover:bg-[#EEE] rounded-md cursor-pointer"
                          key={header}
                          onClick={() => {
                            setSort(header);
                            setOpenSort(false);
                          }}
                        >
                          {header}
                        </li>
                      ))
                    ) : (
                      <li className="px-2 py-2 text-gray-500">
                        No results found
                      </li>
                    )}
                  </ul>
                </div>
                <div
                  className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
                    !openSort && "hidden"
                  } `}
                  onClick={() => {
                    setOpenSort(false);
                  }}
                />
              </>
            )}
            {/* This will need to commented in new Form */}
            {/* <div className="">filtered data</div> */}
          </div>

          {/* NewForm Upto Here needs to comment out */}
          {/* table button */}
          {/* <button
            className=" filter-items rounded-[8px] bg-light-8 text-xs border text-light-2  justify-center  items-center flex gap-1 cursor-pointer"
            onClick={() => setopenTable(true)}
          >
            <IoIosArrowDown className="w-4 h-4" />
          </button> */}
          {/* {selectTableData && (
            <>
              <button className=" p-1.5 flex items-center rounded cursor-pointer text-xs">
                {"1 - "} {selectTableData} {"of"} {assetDetails.length}
               
              </button>
              <IoIosArrowBack className="h-4 w-4 bg-light-8 rounded-full text-light-2 " />
              <IoIosArrowForward className="h-4 w-4 bg-light-8 rounded-full text-light-2 " />
            </>
          )} */}

          {/* Search Tab */}
          <div className="items-center z-[5000]">
            {/* <AssetSearch
              setFilter={setFilter}
              searchValue={searchValue}
              handleSearch={handleSearch}
              handleSearchClick={handleSearchClick}
              open={open}
              setOpen={setOpen}
              setOpenSearch={setOpenSearch}
            /> */}

            {/* {openSearch && showSearchDiv.length > 0 && (
              <div
                className="absolute bg-white
               dark:bg-gray-800 p-2
               border w-[200px] h-[50vh] 
               dark:border-gray-600 
               rounded-2xl hide-scrollbar z-10  
               overflow-y-scroll right-3 mt-5
               shadow transition-all duration-300 ease-in-out text-xs"
              >
                {showSearchDiv.map((item) => (
                  <p
                    onClick={() => handleSearchClick(item)}
                    className="cursor-pointer hover:bg-gray-200 dark:hover:bg-[#EEE] border-b rounded-md p-2 transition duration-300 ease-in-out"
                  >
                    {item}
                  </p>
                ))}
              </div>
            )} */}
            {openSearch && (
              <div className="absolute bg-white dark:bg-gray-800 p-2 border w-[200px] h-[50vh] dark:border-gray-600 rounded-2xl hide-scrollbar z-10 overflow-y-scroll right-3 mt-5 shadow transition-all duration-300 ease-in-out text-xs">
                {showSearchDiv.length > 0 ? (
                  showSearchDiv.map((item, index) => (
                    <p
                      key={index}
                      onClick={() => handleSearchClick(item)}
                      className="cursor-pointer hover:bg-gray-200 dark:hover:bg-[#EEE] border-b rounded-md p-2 transition duration-300 ease-in-out"
                    >
                      {item}
                    </p>
                  ))
                ) : (
                  <p className="text-gray-500 p-2">No results found</p>
                )}
              </div>
            )}
          </div>
          <div
            className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
              !open && "hidden"
            } `}
            onClick={() => {
              setOpen(false);
              setOpenSearch(false);
            }}
          />

          {/* <NewAssetBtn tabName="asset" /> */}
          {/* <ExcelReportBtn tabName="assets" /> */}
          {/* <button
            variant="contained"
            onClick={handleExport}
            className="border text-black buttons"
          >
            Export
          </button> */}
        </div>
      </div>

      {loader ? (
        <Loader />
      ) : (
       <NewTable
          handleExport={handleExport}
          filteredDataStack={filteredDataStack}
          setFilteredDataStack={setFilteredDataStack}
          assetDetails={assetDetails}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}

      {/* Bottom Button */}
    </>
  );
}

export default AssetTable;
