
import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
import { setBaseUrl } from '../../../config';


const DashboardMap = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(80.9629);
  const [lat, setLat] = useState(23.0937);
  const [zoom, setZoom] = useState(3.4);
  const [geojson, setGeoJSON] = useState({

    type: "FeatureCollection",
    features: [],
  });
  
  let Latitude;
  let Longitude;
  

  const fetchDeviceData = async () => {
    try {
      const response = await fetch(
        `${setBaseUrl}/dashboard/get-map-for-active-devices`,
        {
          method: "GET",
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
      const data = await response.json();
  
      const data_geo = {
        type: "FeatureCollection",
        features: data.data.map((device) => {
          const telData = device.device_id.tel_data;
          const latMatch = telData.match(/"lat":(\d+)/);
          const lngMatch = telData.match(/"lng":(\d+)/);
  
          // Initialize coordinates as null and set them if valid values are found
          let Latitude = null;
          let Longitude = null;
  
          if (latMatch) {
            Latitude = parseFloat(latMatch[1]) / 1000000;
          } else {
            console.warn("Latitude not found in data:", telData);
          }
  
          if (lngMatch) {
            Longitude = parseFloat(lngMatch[1]) / 1000000;
          } else {
            console.warn("Longitude not found in data:", telData);
          }
  
          return {
            type: "Feature",
            properties: {
              description: `Asset No: ${device.asset_no}, Model: ${device.model}`,
            },
            geometry: {
              type: "Point",
              coordinates: [Longitude, Latitude],
            },
          };
        }).filter(feature => feature.geometry.coordinates[0] !== null && feature.geometry.coordinates[1] !== null), // Filter out any entries without valid coordinates
      };
  
      setGeoJSON(data_geo);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };
  

  mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

  useEffect(() => {
    if (map.current) return;

    fetchDeviceData();

    map.current = new mapboxgl.Map({
      container: mapContainer.current,

      style: 'mapbox://styles/mapbox/streets-v11',

      center: [lng, lat],
      zoom: zoom,
    });

    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.scrollZoom.disable();

    map.current.on("load", () => {
      map.current.loadImage(
        "https://cdn-icons-png.flaticon.com/512/2776/2776067.png",
        (error, image) => {
          if (error) {
            console.error("Error loading marker image:", error);
            return;
          }
          map.current.addImage("custom-marker", image);

          if (geojson) {
            map.current.addSource("devices", {
              type: "geojson",
              data: geojson,
              cluster: true,
              clusterMaxZoom: 14,
              clusterRadius: 50,
            });

            map.current.addLayer({
              id: "clusters",
              type: "circle",
              source: "devices",
              filter: ["has", "point_count"],
              paint: {
                "circle-color": "#51bbd6",
                "circle-radius": 15,
              },
            });

            map.current.addLayer({
              id: "cluster-count",
              type: "symbol",
              source: "devices",
              filter: ["has", "point_count"],
              layout: {
                "text-field": "{point_count_abbreviated}",
                "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                "text-size": 12,
              },
            });

            map.current.addLayer({
              id: "unclustered-point",
              type: "symbol",
              source: "devices",
              filter: ["!", ["has", "point_count"]],
              layout: {
                "icon-image": "custom-marker",
                "icon-size": 0.07,
              },
            });
          }
        }
      );

    });
  }, []);

  useEffect(() => {
    if (map.current && geojson) {

      const source = map.current.getSource("devices");

      if (source) {
        source.setData(geojson);
      }
    }
  }, [geojson]);

  return (

    <div
      style={{ height: "76vh", width: "50vw" }}
      className="items-start rounded-2xl"
    >
      <div
        ref={mapContainer}
        className="map-container"
        style={{ width: "100%", height: "100%" }}
      />

    </div>
  );
};

export default DashboardMap;
