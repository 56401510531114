import React, { useEffect, useState } from "react";
import SalesLayout from "../SalesLayout.jsx";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setBaseUrl } from "../../../config.js";
import BookAssetForm from "../../../components/Sales/BookAssetForm.jsx";
import SeeBookings from "../../../components/Sales/SeeBookings.jsx";

const SalesDashboard = () => {
  const [filteredData, setFilteredData] = useState([]); // Search filter data
  const [searchQuery, setSearchQuery] = useState(""); // Global search input
  const [page, setPage] = useState(0); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [statusFilter, setStatusFilter] = useState("all"); // Booking status filter
  const [selectedAsset, setSelectedAsset] = useState(null); // Selected asset for modal
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("sales_person_logged_in") !== "true") {
      navigate("/sales-services/login");
    }
  }, [navigate]);

  const token = sessionStorage.getItem("sales_token");

  const getAssetDetails = async () => {
    if (!token) {
      console.error("Token is missing! Redirecting to login...");
      return;
    }

    try {
      const res = await fetch(`${setBaseUrl}/sale-service/get-asset-list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      });

      if (!res.ok) {
        throw new Error(`API Error: ${res.status}`);
      }

      const data = await res.json();
      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching asset details:", error);
    }
  };

  useEffect(() => {
    getAssetDetails();
  }, [token]);


  // console.log(filteredData);

  // Handle Global Search
  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query) {
      getAssetDetails();
      return;
    }

    const lowerCaseQuery = query.toLowerCase();
    setFilteredData((prevData) =>
      prevData.filter((row) =>
        Object.values(row).some(
          (value) =>
            value && value.toString().toLowerCase().includes(lowerCaseQuery)
        )
      )
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let value = event.target.value;
    setPageSize(value === "All data" ? filteredData.length : value);
    setPage(0); // Reset to first page
  };

  // Handle Status Filter
  const handleStatusFilter = (status) => {
    setStatusFilter(status);
    if (status === "all") {
      getAssetDetails();
    } else {
      const isAvailable = status === "available";
      setFilteredData((prevData) =>
        prevData.filter((row) =>
          isAvailable ? row.booking === null : row.booking !== null
        )
      );
    }
  };

  // Open Modal for Booking
  const openBookingModal = (asset) => {
    const bookingCount = asset?.booking?.length || 0; // Count existing bookings

    if (bookingCount >= 3) {
      alert("You have already booked this asset 3 times. Further booking is not allowed.");
      return;
    }


    setSelectedAsset(asset);
    setIsBookingModalOpen(true);
  };

  // Open Modal for Viewing Details
  const openDetailsModal = (asset) => {
    setSelectedAsset(asset);
    setIsDetailsModalOpen(true);
  };

  // Close Modals
  const closeBookingModal = () => setIsBookingModalOpen(false);
  const closeDetailsModal = () => setIsDetailsModalOpen(false);
  const id = sessionStorage.getItem("sales_id");
  const name = sessionStorage.getItem("sales_name");


  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 30,
      renderCell: (params) => <>{params.row.counter}</>,
    },
    { field: "asset_no", headerName: "Asset No",  },
    { field: "make", headerName: "Brand", },
    { field: "category", headerName: "Category", },
    {
      field: "monthly_rental_amount",
      headerName: "Monthly Rental",
      
    },
    { field: "yom", headerName: "Production Year", width: 120},
    { field: "capacity", headerName: "Capacity(KG)", width: 120 },
    { field: "hieght_machine", headerName: "Machine Height(Ft)", width: 120 },
    {
      field: "rental_end_date",
      headerName: "Rental End Date",
      width: 120,
      renderCell: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    // {
    //   headerName: "Status",
    //   field: "booking",
    //   width: 150,
    //   renderCell: (params) => {
    //     const hasBookings = params.value && params.value.length > 0; // Check if booking array exists and has items
    //     return (
    //       <Button
    //         variant="outlined"
    //         color={!hasBookings ? "success" : "error"}
    //         size="small"
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           gap: 1,
    //           padding: "6px 12px",
    //           fontSize: "12px", // Equivalent to text-xs
    //           justifyContent: "center",
    //           border: "none",
    //           marginTop: "8px",
    //           textTransform: "capitalize",
    //         }}
    //       >
    //         {!hasBookings ? "Available for Booking" : "Booked"}
    //       </Button>
    //     );
    //   },
    // }
    {
      headerName: "Status",
      field: "booking",
      width: 150,
      renderCell: (params) => {
        const bookings = params.value || [];
        let statusText = "Available for Booking";
        let buttonColor = "success";

        const isApproved = bookings.some((b) => b.booking_is_approved === true);

        if (isApproved) {
          statusText = "Alloted";
          buttonColor = "primary";
        } else if (bookings.length === 3) {
          statusText = "Fully Booked";
          buttonColor = "error";
        } else if (bookings.length === 2) {
          statusText = "Only 1 Booking Left";
          buttonColor = "warning";
        } else if (bookings.length === 1) {
          statusText = "2 Bookings Available";
          buttonColor = "info";
        }

        return (
          <Button
            variant="outlined"
            color={buttonColor}
            size="small"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              padding: "6px 12px",
              fontSize: "12px",
              justifyContent: "center",
              border: "none",
              marginTop: "8px",
              textTransform: "capitalize",
            }}
          >
            {statusText}
          </Button>
        );
      },
    }

    //     ,   
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 220,
    //   renderCell: (params) => {
    //     const salesId = sessionStorage.getItem("sales_id");
    //     const bookings = params.row.booking || [];
    //     const isAllotted =  bookings.some((b) => b.booking_is_approved);
    //     const isAvailable = bookings.length === 0;
    //     const isCurrentUserBooked = bookings.some(
    //       (b) => b.sale_person_id?.toString() === salesId
    //     );
    //     const isBookingLimitReached = bookings.length > 3;

    //     return (
    //       <div className="flex gap-2">


    //         {/* Show "Book Now" if available and conditions are met */}
    //         {(!isAllotted || isAvailable || (!isCurrentUserBooked && !isBookingLimitReached)) && (
    //           <Button
    //             sx={{
    //               fontSize: "12px",
    //               marginTop: "6px",
    //               textTransform: "capitalize",
    //             }}
    //             variant="contained"
    //             color="primary"
    //             onClick={() => openBookingModal(params.row)}
    //           >
    //             Book Now
    //           </Button>
    //         )}


    //          {/* Show "See Details" if there are bookings */}
    //          {bookings.length > 0 && (
    //           <Button
    //             sx={{
    //               fontSize: "12px",
    //               marginTop: "6px",
    //               textTransform: "capitalize",
    //             }}
    //             variant="outlined"
    //             color="secondary"
    //             onClick={() => openDetailsModal(params.row)}
    //           >
    //             See Details
    //           </Button>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    , {
      field: "actions",
      headerName: "Actions",
      width: 220,
      renderCell: (params) => {
        const salesId = sessionStorage.getItem("sales_id");
        const bookings = params.row.booking || [];

        // Check if any booking is approved
        const isApproved = bookings.some((b) => b.booking_is_approved === true);
        console.log(isApproved)
        // Check if no bookings exist
        const isAvailable = bookings.length === 0;

        // Check if current user has booked
        const isCurrentUserBooked = bookings.some(
          (b) => b.sale_person_id?.toString() === salesId
        );

        // Check if booking limit is reached
        const isBookingLimitReached = bookings.length > 3;

        return (
          <div className="flex gap-2">
            {/* Show "Book Now" only if:
            - No existing bookings (isAvailable) OR
            - User has not booked yet and booking limit is not reached
            - No booking is approved */}
            {!isApproved && ((isAvailable || (!isCurrentUserBooked && !isBookingLimitReached))) && (
              <Button
                sx={{
                  fontSize: "12px",
                  marginTop: "6px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                color="primary"
                onClick={() => openBookingModal(params.row)}
              >
                Book Now
              </Button>
            )}

            {/* Show "See Details" if there are bookings */}
            {bookings.length > 0 && (
              <Button
                sx={{
                  fontSize: "12px",
                  marginTop: "6px",
                  textTransform: "capitalize",
                }}
                variant="outlined"
                color="secondary"
                onClick={() => openDetailsModal(params.row)}
              >
                See Details
              </Button>
            )}
          </div>
        );
      },
    }


    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 200,
    //   renderCell: (params) => {
    //     const isAvailable = params.row.booking === null;
    //     return (
    //       <div className="flex gap-2">
    //         {isAvailable ? (
    //           <Button sx={{
    //             fontSize:"12px", marginTop: "6px" , textTransform: "capitalize", 
    //           }}
    //             variant="contained"
    //             color="primary"
    //             onClick={() => openBookingModal(params.row)}
    //           >
    //             Book Now
    //           </Button>
    //         ) : (
    //           <Button sx={{
    //             fontSize:"12px", marginTop: "6px" , textTransform: "capitalize", 
    //             }}
    //             variant="outlined"
    //             color="secondary"
    //             onClick={() => openDetailsModal(params.row)}
    //           >
    //             View Details
    //           </Button>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <>
      {/* <h1 className="text-base font-bold mb-4">Sales Dashboard</h1> */}

      <div className="">
        {/* Filters and Search */}
        <div className="flex justify-between p-2">
          <input
            className="border border-gray-300 bg-gray-50 h-[30px] w-[250px] p-2 rounded text-sm"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search..."
          />

<div className="flex items-center gap-4">

          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 30, 50, "All data"]}
            labelRowsPerPage="Sales Data:"
            SelectProps={{
              renderValue: (value) =>
                value === "All data" ? "All data" : value,
            }}
          />
          <select
            className="border border-gray-300 bg-gray-50 h-[30px] rounded text-xs px-2"
            onChange={(e) => handleStatusFilter(e.target.value)}
          >
            <option value="all">All</option>
            <option value="available">Available for Booking</option>
            <option value="booked">Booked</option>
          </select>
</div>
        </div>
        {filteredData.length > 0 ? <DataGrid
          rows={
            pageSize === filteredData.length
              ? filteredData.map((row, index) => ({
                ...row,
                counter: index + 1,
              }))
              : filteredData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((row, index) => ({
                  ...row,
                  counter: page * pageSize + index + 1,
                }))
          }
          columns={columns}
          pageSize={pageSize}
          paginationMode="server"
          pagination={false}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReordering
          sx={{
            "& .MuiDataGrid-footerContainer": {
              display: "none", // Hides the footer container
            },

            "& .MuiDataGrid-root": { fontSize: "12px", height: 12 },
            "& .MuiDataGrid-columnHeaders": {
              fontSize: "12px",

              fontWeight: "bold",
              color: "#000",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              height: 20,
            },
            "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
            "& .MuiDataGrid-virtualScroller": {
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
        /> : <>
          <span className="text-xs">No data available in your search area</span>
        </>}


        {/* Booking Modal */}
        <Modal open={isBookingModalOpen} onClose={closeBookingModal} >
          <Paper className="p-6 w-[500px] mx-auto mt-20">

            <BookAssetForm assetNo={selectedAsset?.asset_no} id={selectedAsset?.id} />
            {/* Booking Form Here */}
            <Button sx={{ fontSize: '12px' }} onClick={closeBookingModal}>Close</Button>
          </Paper>
        </Modal>

        {/* Details Modal */}
        <Modal open={isDetailsModalOpen} onClose={closeDetailsModal}>
          <Paper className="p-6 w-96 mx-auto mt-20">

            <SeeBookings assetNo={selectedAsset?.asset_no} bookingDetails={selectedAsset?.booking} />
            <Button sx={{ fontSize: '12px' }} onClick={closeDetailsModal}>Close</Button>
          </Paper>
        </Modal>
      </div>
    </>
  );
};

export default SalesDashboard;
