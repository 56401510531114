import React, { useContext, useEffect, useState } from 'react';
import { LoaderContext } from '../../../Context/LoaderContext';
import { setBaseUrl } from '../../../config';
import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { currency, formatDate } from '../../../customFunctions/FormatDate';

function LeaseTab({ asset_id }) {
  console.log(asset_id);

  const setLoader = useContext(LoaderContext);
  const [leaseDetails, setLeaseDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllLease = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/get_all`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": asset_id,
        },
      });
      setLeaseDetails(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllLease();
  }, [asset_id]);

  const sortList = (a, b) => {
    const statusOrder = {
      'active': 1,
      'inactive': 2,
      'never assigned': 3,
    };

    return (statusOrder[a.lease_status] || 4) - (statusOrder[b.lease_status] || 4);
  };

  const navigate = useNavigate();

    // Lease Status
    let numb = 0;
    if (leaseDetails?.lease_status?.startsWith("expiring")) {
      const match = leaseDetails?.lease_status.match(/\d+/);
      if (match) {
        numb = parseInt(match[0], 10);
      }
    }

  if (loading) {
    return (
      <div className="h-[50vh] flex flex-col justify-center items-center dark:text-white text-gray-500">
      <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <p className="text-lg">No lease found</p>
      <p className="text-sm mt-1">Create a new lease or check back later</p>
    </div>
    );
  }

  return (
    
    <div className="overflow-x-auto rounded-lg mt-1 border border-gray-200">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Odoo Order ID
            </th>
            <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Lease Status
            </th>
            <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Start Date
            </th>
            <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              End Date
            </th>
            <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Claimable Amount
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {leaseDetails.sort(sortList).map((lease) => (
            <tr 
              key={lease.id}
              onClick={() => navigate(`/lease/lease-details/${lease.id}`)}
              className="hover:bg-gray-50 cursor-pointer transition-colors"
            >
              <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-700">
                {lease?.odoo_order_id || '-'}
              </td>
              <td className="px-4 py-3 whitespace-nowrap">
                <span className={`
                  text-xs font-medium px-3 py-1 rounded-full
                  ${
                    lease?.lease_status === "inactive" || lease?.lease_status === "never assigned" 
                      ? "bg-gray-200 text-gray-800" 
                      : lease?.lease_status === "active" 
                      ? "bg-green-200 text-green-2"
                      : lease?.lease_status === "expired" 
                      ? "bg-red-100 text-red-1"
                      : lease?.lease_status?.startsWith("expiring")
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-blue-100 text-blue-2"
                  }
                `}>
                    {lease?.lease_status === "inactive" || lease?.lease_status === "never assigned" ? "Free" : lease?.lease_status === "expired" ? "Exceeded" : lease?.lease_status?.startsWith(
                        "expiring"
                      ) ? `Active ${numb} days` : "Active" }
                </span>
              </td>
              <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-700">
                {formatDate(lease?.rental_start_date) || '-'}
              </td>
              <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-700">
                {lease?.rental_extended_date 
                  ? formatDate(lease.rental_extended_date) 
                  : formatDate(lease?.rental_end_date) || '-'}
              </td>
              <td className="px-4 py-3 whitespace-nowrap text-xs font-medium text-gray-900">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md bg-blue-100 text-blue-800">
                  ₹{currency(parseInt(lease?.total_claimable_amount || 0))}/-
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LeaseTab;
