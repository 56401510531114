import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { useContext } from "react";
import { LoaderContext } from "../../../Context/LoaderContext";
import moment from "moment";
import { formatDate, formatMaintenanceType } from "../../../customFunctions/FormatDate";


function CurrentItems({ deleted, setDeleted, allMaintenance }) {
  const [tableAnimation, setTableAnimation] = useState(false)
  const [perms] = useContext(PermissionContext);
  const [loader, setLoader] = useContext(LoaderContext);
  const navigate = useNavigate()
  const deleteMaintenance = async (id) => {
    try {
      setLoader(true);
      const { data } = await axios.delete(
        `${setBaseUrl}/maintenance/delete`,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
          data: {
            id: id
          }
        }
      );
      setDeleted((prev) => !prev);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoader(false);
      return;
    }
  };
  const openDetailsOnClick = (id) => {

    navigate("/maintenance/update-maintenance/" + id)
  };

  const handleUpdate = (id) => {
    navigate("/maintenance/update-maintenance/" + id)
  };
  useEffect(() => {
    setTimeout(() => {
      setTableAnimation(true);
    }, 400)
  }, [])
  return (
    <div className="intro-y mt-4">
    {allMaintenance === undefined || allMaintenance.length === 0 ? (
      <div className="h-[50vh] flex flex-col justify-center items-center dark:text-white text-gray-500">
        <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p className="text-lg">No maintenance tickets found</p>
        <p className="text-sm mt-1">Create a new ticket or check back later</p>
      </div>
    ) : (
      <div className="overflow-x-auto rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
        <table className={`min-w-full divide-y divide-gray-200 dark:divide-gray-700 ${tableAnimation ? "show-rows" : ""}`}>
          <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Complaint Date
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Ticket ID
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Asset
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Location
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Title
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Issue Date
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Type
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Assigned To
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 dark:text-gray-300 uppercase tracking-wider">
                Priority
              </th>
            </tr>
          </thead>
  
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {allMaintenance.map((item) => (
              <tr 
                key={item.id} 
                className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-150 cursor-pointer"
                onClick={() => openDetailsOnClick(item.id)}
              >
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-700 dark:text-gray-300">
                  <div className="font-medium dark:text-white">
                    {formatDate(item?.compaint_date) || 'N/A'}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400 text-xs">
                    {moment(item?.compaint_date).format('hh:mm A')}
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                  {item?.ticket_no}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-700 dark:text-gray-300">
                  {item?.asset_no}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-700 dark:text-gray-300">
                  {item?.location || '—'}
                </td>
                <td className="px-4 py-3 text-sm text-gray-700 dark:text-gray-300 max-w-xs truncate">
                  {item?.title || '—'}
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-700 dark:text-gray-300">
                  <div className="font-medium dark:text-white">
                    {formatDate(item?.issue_date) || 'N/A'}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400 text-xs">
                    {moment(item?.issue_date).format('hh:mm A')}
                  </div>
                </td>
                {/* <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-700 dark:text-gray-300">
                  {item?.issue_date ? formatDate(item?.issue_date) : '—'}
                </td> */}
                <td className="px-4 py-3 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs rounded-full ${item?.types ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200' : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}`}>
                
                     {item?.types !== "types" && formatMaintenanceType(item?.types)}
                  </span>
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-700 dark:text-gray-300">
                  {item?.serviceSalePerson?.length ? (
                    <div className="flex flex-wrap gap-1">
                      {item.serviceSalePerson.map((person, i) => (
                        <span key={i} className="bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200 text-xs px-2 py-0.5 rounded">
                          {person}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <span className="text-red-600 dark:text-red-400">Not Assigned</span>
                  )}
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                    item?.status === 'Completed' ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' :
                    item?.status === 'In Progress' ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200' :
                    item?.status === 'Pending' ? 'bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200' :
                    'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
                  }`}>
                    {item?.status || '—'}
                  </span>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                    item?.priority === 'High' ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200' :
                    item?.priority === 'Medium' ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200' :
                    item?.priority === 'Low' ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' :
                    'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
                  }`}>
                    {item?.priority || '—'}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
  );
}

export default CurrentItems;
