import React, { useState } from "react";
import Document from "../viewer/Document";
import deleteDocument from "../../apis/files/deleteDocument";

function Documents({ documentUrl, section }) {
  const [allDocuments, setAllDocuments] = useState(documentUrl);

  const handleDelete = async (id) => {
    const data = await deleteDocument(id, section);
    if (data !== false) {
      const newDocList = allDocuments.filter((doc) => doc.id !== id);
      setAllDocuments(newDocList);
    } else {
      alert("Document not deleted");
    }
  };

  return (
    <div className="assetD-tabs bg-white rounded-md shadow-md text-dark-6 mb-20">
      <div className="grid grid-cols-12 gap-6">
        <div className="intro-y box col-span-12  rounded-[4px]">
          {/* Header Section */}
          <div className="flex items-center px-6 py-4 border-b">
            <h2 className="font-semibold text-xs text-gray-800 dark:text-white">
              Documents
            </h2>
          </div>

          {/* Content Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
            {allDocuments.length > 0 ? (
              allDocuments.map((document) => (
                <Document
                  key={document.id}
                  pdfdocument={document}
                  handleDelete={handleDelete}
                />
              ))
            ) : (
              <div className="text-gray-500 text-center text-xs col-span-full">
                No documents found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Documents;
