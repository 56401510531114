import React, { useRef } from "react";

const AssetSearch = ({
  setFilter,
  searchValue,
  handleSearch,
  handleSearchClick,
  open,
  setOpen,
  setOpenSearch,
}) => {
  const inputRef = useRef(null); // Create a ref for the input field

  const handleSearchIconClick = () => {
    setOpen(true); // Open the search input
    setTimeout(() => {
      inputRef.current?.focus(); // Focus on the input field
    }, 0); // Delay to ensure the input is visible before focusing
  };

  return (
    <div>
      <div className="flex">
        <button
          className="p-2 rounded-[5.6px] items-center text-xs font-normal text-center hover:bg-[#eee]"
          onClick={handleSearchIconClick}
        >
          <svg
            className="w-3 h-3 text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </button>

        <input
          ref={inputRef}
          type="search"
          placeholder="Search"
          value={searchValue} // Use controlled input
          className={`h-7 border-none focus:outline-none bg-transparent focus:ring-0 text-xs transition-all duration-500 ease-in-out ${
            open ? "w-[150px] opacity-100" : "w-0 opacity-0"
          }`}
          onChange={handleSearch}
          onFocus={() => setOpenSearch(true)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleSearchClick(searchValue);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AssetSearch;
