// import React, { useContext } from "react";
// import { Tooltip } from "@mui/material";

// import Loading from "../../../Loading";
// import { capitalizeFirstLetter, dateValue } from "../../../../customFunctions/FormatDate";
// import moment from "moment";
// import PhotoData from "./PhotoData";
// import { PermissionContext } from "../../../../Context/PermissionsContext";
// import { useNavigate } from "react-router-dom";

// const AssetHeader = ({ assetDetails, loading, imageUrl, NoImage , handleBottomTab, setPhotoDataTab, id, perms }) => {
//   const navigate = useNavigate()


//   // Lease Status
//   let numb = 0;
//   if (assetDetails?.lease_status?.startsWith("expiring")) {
//     // Match a number within the string "expiring in X days"
//     const match = assetDetails?.lease_status.match(/\d+/);

//     if (match) {
//       numb = parseInt(match[0], 10);
//     }
//   }



//   return (
//     <>
//       <div className="h-[200px] w-full flex border rounded-[22px] bg-white shadow-md">
//         {/* Image Section */}
//         <PhotoData imageUrl={imageUrl} NoImage={NoImage} handleBottomTab={handleBottomTab} setPhotoDataTab={setPhotoDataTab} title={assetDetails?.asset_no} />
//         {/* Details Section */}
//         <div className="flex justify-between p-4 w-full rounded-r-[22px] bg-white">
//           {/* Left Column */}
//           <div className="w-[500px] h-[170px] ">
//             {/* Asset Number & Brand */}
//             <div className="flex justify-between">
//               <span className="text-gray-500 text-[22px] font-extrabold" title="Asset Number">
//                 {assetDetails?.asset_no}
//               </span>
//               <span className="text-blue-600 font-extrabold text-base" title="Brand">
//                 {assetDetails?.make}
//               </span>
//             </div>

//             {/* Category & Serial Number */}
//             <div className="flex text-gray-500 justify-between text-sm  mt-2">
//               <span className="" title="Category">{assetDetails?.category}</span>
//               <span className="flex gap-2 items-center">
//               <span title="Serial Number">{assetDetails?.serial_no}</span>
//               <div className="bg-dark-5 h-[7px] w-[7px] rounded-full" />
//               <span className="" title="Model Number">{assetDetails?.model}</span>
//               </span>
//             </div>

//             {/* Year of Manufacture & Condition */}
//             <div className="flex justify-between text-sm  mt-2">
//               <span className="text-gray-500" title="Production Year">{assetDetails?.yom}</span>
              
//               <span title="Used Machine or New Machine">{assetDetails?.config_data?.used_or_new?.toUpperCase() || "New"}</span>

//             </div>

//             {/* Drive Type & Capacity */}
//             <div className="flex justify-between text-sm  mt-2">
//               <span className="text-gray-500" title="Drive Type">
//                 {assetDetails?.config_data?.battery_type}
//               </span>
//               <span title="Capacity">
//                 {`${assetDetails?.hieght_machine || "0"}ft - `}
//                 {`${assetDetails?.capacity || "0"}kg`}
//               </span>
//             </div>

//             {/* Wheel Drive, Tyres, ANSI */}
//             <div className="flex justify-between text-sm  mt-2">
//               <span className="text-gray-500" title="Wheels">
//                 {assetDetails?.config_data?.two_or_four_wd}
//               </span>
//               <div className="flex items-center gap-3" title="Tyres">
//                 <span>{assetDetails?.config_data?.tyres}</span>{" "}
//                 <div className="h-[7px] w-[7px] rounded-full bg-black" title="ANSI or CD" />
//                 <span>{(assetDetails?.config_data?.ansi_or_new)?.toUpperCase()}</span>
//               </div>
//             </div>

//             {/* Accessories & Engine Serial Number */}
//             <div className="flex justify-between text-sm  mt-2">
//               <span className="text-gray-500" title="Accessories">
//                 {assetDetails?.config_data?.accessories}
//               </span>
//               <span title="Engine Serail Number">{assetDetails?.config_data?.engine_serial_no}</span>
//             </div>
//           </div>

//           {/* Right Column */}
//           <div className="w-full max-w-md h-full justify-between flex flex-col pt-6  ">
//             {/* Lease Status */}
//             <div className="flex justify-between text-sm ">
//               <span className="text-gray-500">Lease Status</span>
              // <span title="Lease Status"
              //   className={`
              //      ${
              //   assetDetails?.lease_status === "inactive"
              //     ? "bg-light-3"
              //     : assetDetails?.lease_status === "active"
              //     ? "bg-green-2"
              //     : assetDetails?.lease_status === "expired"
              //     ? "bg-red-1"
              //     : "bg-blue-2"
              // }
                  
              //  text-white p-1 px-4 rounded-full   `}
              // >
                //  {assetDetails?.lease_status === "inactive" || assetDetails?.lease_status === "never assigned" ? "Free" : assetDetails?.lease_status === "expired" ? "Exceeded" : assetDetails?.lease_status?.startsWith(
                //         "expiring"
                //       ) ? `Active ${numb} days` : "Active" }
              
//               </span>
//             </div>

//             {/* Tracker Status */}
//             <div className="flex justify-between text-sm  mt-0.5">
//               <span className="text-gray-500">Tracker</span>
//               <span title="Tracker installation status"
//                 className={`px-3 py-0.5 rounded-full ${
//                   assetDetails?.device_no
//                     ? "text-white bg-blue-1 font-normal"
//                     : "text-white bg-light-5"
//                 }
                
//                 `}
//               >
//                 {assetDetails?.device_no ? "Installed" : "Not Installed"}
//               </span>
//             </div>

//             {/* Ratings */}
//             <div className="flex justify-between text-sm  mt-2">
//               <span className="text-gray-500" >Ratings</span>
//               <span className="text-yellow-500" title="Maintenance rating of the machine">
//                 {assetDetails?.rating || "5"}/5 
//               </span>
//             </div>
//              {/* Sold */}
//              {assetDetails.is_sold  && <div className="flex justify-between text-sm  mt-2">
//               <span className="text-gray-500" >Sold Date</span>
//               <span className="text-red-1" title="Machine sold status">
//                 {assetDetails?.asset_sold_date || ""}
//               </span>
//             </div>}
             

//             {/* Last Service Date & Update Button */}
//             <div className="flex justify-between items-center mt-2">
//               <span className=" font-regular text-base" title="Creation Date">
//                  {assetDetails?.created_at ? dateValue(assetDetails?.created_at) : 'N/A'}
//               </span>
           

//               {(perms.indexOf("ADMIN.ALL") > -1 ||
//                               perms.indexOf("ASSETS.ALL") > -1 ||
//                               perms.indexOf("ASSETS.CRU") > -1) && (
//                               <>
//                                 <button
//                                   className="buttons  bg-green-2 text-white"
//                                   title="Update Asset Details"
//                                   variant="contained"
                                 
//                                   onClick={() => navigate("/assets/update-asset/" + id)}
//                                 >
//                                   Edit
//                                 </button>
//                               </>
//                             )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AssetHeader;
import React from "react";
import { Tooltip } from "@mui/material";
import Loading from "../../../Loading";
import { capitalizeFirstLetter, dateValue } from "../../../../customFunctions/FormatDate";
import moment from "moment";
import PhotoData from "./PhotoData";
import { PermissionContext } from "../../../../Context/PermissionsContext";
import { useNavigate } from "react-router-dom";

const AssetHeader = ({ assetDetails, loading, imageUrl, NoImage, handleBottomTab, setPhotoDataTab, id, perms }) => {
  const navigate = useNavigate();

  // Lease Status
  let numb = 0;
  if (assetDetails?.lease_status?.startsWith("expiring")) {
    const match = assetDetails?.lease_status.match(/\d+/);
    if (match) {
      numb = parseInt(match[0], 10);
    }
  }

  // if (loading) return <Loading />;

  return (
    <div className="w-full flex flex-col md:flex-row border rounded-xl bg-white shadow-sm">
      {/* Image Section */}
      <PhotoData 
        imageUrl={imageUrl} 
        NoImage={NoImage} 
        handleBottomTab={handleBottomTab} 
        setPhotoDataTab={setPhotoDataTab} 
        title={assetDetails?.asset_no} 
      />
      
      {/* Details Section */}
      <div className=" font-semibold text-dark-6 flex md:flex-1 md:flex-row flex-col justify-between p-4 rounded-r-xl bg-white ">
        {/* Left Column - Asset Specifications */}
        <div className="md:w-[50%] space-y-3  w-full ">
          {/* Asset Number & Brand */}
          <div className="flex justify-between items-center">
            <h1 className={`text-2xl font-bold  truncate max-w-[70%] 
              ${assetDetails?.device_no ? "text-green-1" : "text-gray-800"}
              `} title="Asset Number">
              {assetDetails?.asset_no}
            </h1>
            <span className="text-blue-600 font-semibold text-base px-2 py-1 bg-blue-50 rounded" title="Brand">
              {assetDetails?.make}
            </span>
          </div>

          {/* Category & Serial Info */}
          <div className="flex justify-between text-sm text-gray-600">
            <span title="Category">{assetDetails?.category}</span>
            <div className="flex items-center gap-2">
              <span title="Serial Number">{assetDetails?.serial_no}</span>
              <div className="w-1 h-1 rounded-full bg-gray-400" />
              <span title="Model Number">{assetDetails?.model}</span>
            </div>
          </div>

          {/* Production Year & Condition */}
          <div className="flex justify-between text-sm">
            <span className="text-gray-600" title="Production Year">
              {assetDetails?.yom || 'N/A'}
            </span>
            <span className="font-medium" title="Used Machine or New Machine">
              {assetDetails?.config_data?.used_or_new?.toUpperCase() || "NEW"}
            </span>
          </div>

          {/* Drive Type & Capacity */}
          <div className="flex justify-between text-sm">
            <span className="text-gray-600" title="Drive Type">
              {assetDetails?.config_data?.battery_type || 'N/A'}
            </span>
            <span title="Capacity">
              {`${assetDetails?.hieght_machine || "0"}ft - ${assetDetails?.capacity || "0"}kg`}
            </span>
          </div>

          {/* Wheel Drive & ANSI */}
          <div className="flex justify-between text-sm">
            <span className="text-gray-600" title="Wheels">
              {assetDetails?.config_data?.two_or_four_wd || 'N/A'}
            </span>
            <div className="flex items-center gap-2">
              <span title="Tyres">{assetDetails?.config_data?.tyres || 'N/A'}</span>
              <div className="w-1 h-1 rounded-full bg-gray-400" />
              <span title="ANSI or CD">{(assetDetails?.config_data?.ansi_or_new)?.toUpperCase() || 'N/A'}</span>
            </div>
          </div>

          {/* Accessories & Engine */}
          <div className="flex justify-between text-sm">
            <span className="text-gray-600" title="Accessories">
              {assetDetails?.config_data?.accessories || 'None'}
            </span>
            <span title="Engine Serial Number">
              {assetDetails?.config_data?.engine_serial_no || 'N/A'}
            </span>
          </div>
        </div>

        {/* Right Column - Status Information */}
        <div className="md:w-[40%] w-full flex flex-col justify-between  max-md:mt-2">
          <div className="space-y-3">
            {/* Lease Status */}
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Lease Status</span>
              {/* <span title="Lease Status"
                className={`
                   ${
                assetDetails?.lease_status === "inactive"
                  ? "bg-light-3"
                  : assetDetails?.lease_status === "active"
                  ? "bg-green-2"
                  : assetDetails?.lease_status === "expired"
                  ? "bg-red-1"
                  : "bg-blue-2"
              }
                  
               text-white p-1 px-4 rounded-full   `}
              > */}
              <span 
                className={`
                  text-xs font-medium px-3 py-1 rounded-full
                  ${
                    assetDetails?.lease_status === "inactive" || assetDetails?.lease_status === "never assigned" 
                      ? "bg-gray-200 text-gray-800" 
                      : assetDetails?.lease_status === "active" 
                      ? "bg-green-200 text-green-2"
                      : assetDetails?.lease_status === "expired" 
                      ? "bg-red-100 text-red-1"
                      : assetDetails?.lease_status?.startsWith("expiring")
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-blue-100 text-blue-2"
                  }
                `}
                title="Lease Status"
              >
                {/* {assetDetails?.lease_status === "inactive" || assetDetails?.lease_status === "never assigned" 
                  ? "Available" 
                  : assetDetails?.lease_status === "expired" 
                  ? "Exceeded" 
                  : assetDetails?.lease_status?.startsWith("expiring") 
                  ? `Active (${numb}d)` 
                  : "Active"} */}
                   {assetDetails?.lease_status === "inactive" || assetDetails?.lease_status === "never assigned" ? "Free" : assetDetails?.lease_status === "expired" ? "Exceeded" : assetDetails?.lease_status?.startsWith(
                        "expiring"
                      ) ? `Active ${numb} days` : "Active" }
              
              </span>
            </div>

            {/* Tracker Status */}
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Tracker</span>
              <span 
                className={`text-xs font-medium px-3 py-1 rounded-full ${
                  assetDetails?.device_no
                    ? "bg-blue-100 text-blue-800"
                    : "bg-gray-200 text-gray-800"
                }`}
                title="Tracker installation status"
              >
                {assetDetails?.device_no ? "Installed" : "Not Installed"}
              </span>
            </div>

            {/* Ratings */}
            <div className="flex justify-between items-center">
              <span className="text-sm text-gray-600">Maintenance Rating</span>
              <div className="flex items-center">
                <span className="text-amber-500 font-medium mr-1" title="Maintenance rating of the machine">
                  {assetDetails?.rating || "5"}
                </span>
                <span className="text-gray-400">/5</span>
              </div>
            </div>

            {/* Sold Status */}
            {assetDetails.is_sold && (
              <div className="flex justify-between items-center">
                <span className="text-sm text-gray-600">Sold Date</span>
                <span className="text-sm font-medium text-red-600" title="Machine sold date">
                  {assetDetails?.asset_sold_date || "N/A"}
                </span>
              </div>
            )}
          </div>

          {/* Creation Date & Edit Button */}
          <div className="flex justify-between items-center pt-4 mt-4 ">
            <div>
              <span className="text-xs text-gray-500">Created</span>
              <p className="text-sm font-medium" title="Creation Date">
                {assetDetails?.created_at ? dateValue(assetDetails?.created_at) : 'N/A'}
              </p>
            </div>

            {(perms.indexOf("ADMIN.ALL") > -1 ||
             perms.indexOf("ASSETS.ALL") > -1 ||
             perms.indexOf("ASSETS.CRU") > -1) && (
              <button
                className="px-4 py-2 text-sm font-medium text-white bg-green-2 rounded-md hover:bg-green-3 transition-colors"
                title="Update Asset Details"
                onClick={() => navigate("/assets/update-asset/" + id)}
              >
                Edit Asset
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetHeader;