import React from "react";

import { formatDateInput } from "../../../customFunctions/FormatDate";


function NewCommercialDetails({
  commToggle,
  setCommToggle,
  commercialDetails,
  setCommercialDetails,
  assetBasicDetails,
  setAssetBasicDetails,
}) {
  // const handleToggle = () => {
  //   setCommToggle((prev) => !prev);
  // };
  return (
    <>
      <div className="flex animSlideup relative">
        <div className="">
          <span className="text-xs font-semibold text-dark-6 underline">
            Commercial Details
          </span>
        </div>
      </div>

      <>
        <div className="border text-xs p-10 mt-2 gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4">
          {/* Item 1 */}
          <div title="Purchase order number" className="flex-1 w-[200px] ">
            {/* <TooltipFun  placement="top"> */}
            <label className="font-bold">PO Number</label>
            {/* </TooltipFun> */}
            <input
              type="text"
              maxLength={25}
              value={commercialDetails?.purchase_order_no}
              placeholder="PO Number."
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  purchase_order_no: e.target.value,
                });
              }}
            />
          </div>
          <div title="Purchase order date" className="flex-1 w-[200px] ">
            <label className="font-bold">PO Date</label>
            <input
              type="date"
              min="2000-01-01"
              value={formatDateInput(commercialDetails?.purchase_order_date)}
              placeholder="PO Number"
              className="text-black focus:outline-none placeholder:text-dark-6 focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  purchase_order_date: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 3 */}
          <div title="Invoice Number" className="flex-1 w-[200px] ">
            <label className="font-bold">Invoice Number.</label>
            <input
              type="text"
              maxLength={25}
              value={commercialDetails?.invoice_no}
              placeholder="Invoice Number."
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  invoice_no: e.target.value,
                });
              }}
            />
          </div>

          {/* Item 4 */}
          <div title="Invoice Date" className="flex-1 w-[200px] ">
            <label className="font-bold">Invoice Date</label>
            <input
              type="date"
              min="2000-01-01"
              value={formatDateInput(commercialDetails?.invoice_date)}
              placeholder="Invoice Number"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  invoice_date: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 5 */}
          <div title="Payment Terms" className="flex-1 w-[200px] ">
            {/* <TooltipFun placement="top-end" > */}
            <label className="font-bold">Payment Terms</label>
            {/* </TooltipFun> */}
            <input
              type="text"
              maxLength={100}
              value={commercialDetails?.payment_terms}
              placeholder="Payment Terms"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
                if (!emojiRegex.test(e.target.value)) {
                  setCommercialDetails({
                    ...commercialDetails,
                    payment_terms: e.target.value,
                  });

                } else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
              }}
            />
          </div>
          {/* Item 6 */}
          <div title="Payment Remitted to OEM" className="flex-1 w-[200px] ">
            {/* <TooltipFunplacement="top-end" > */}
            <label className="font-bold">Payment Remitted to OEM</label>
            {/* </TooltipFunplacement=> */}
            <input
              type="number"
              maxLength={25}
              value={commercialDetails?.amount_rem_to_oem}
              placeholder="Payment Remitted to OEM"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  amount_rem_to_oem: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 7 */}
          <div
            title="Date of Payment Remitted to OEM"
            className="flex-1 w-[200px] "
          >
            {/* <TooltipFun  placement="top-end" > */}
            <label className="font-bold">Date of Payment Remitted to OEM</label>
            {/* </TooltipFun> */}
            <input
              type="date"
              min="2000-01-01"
              value={commercialDetails?.date_of_rem_to_oem}
              placeholder="e.g. ₹1,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  date_of_rem_to_oem: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 8 */}
          <div
            title="Exchange Rate of Remittence"
            className="flex-1 w-[200px] "
          >
            <label className="font-bold">Exchange Rate of Remittence</label>

            <input
              type="number"
              min={0}
              maxLength={25}
              value={commercialDetails?.exchange_rate_rem}
              placeholder="Exchange Rate of Remittence"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
                if (!emojiRegex.test(e.target.value)){

                  setCommercialDetails({
                    ...commercialDetails,
                    exchange_rate_rem: e.target.value,
                  });
                } else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
              }}
            />
          </div>
          {/* Item 9 */}
          <div
            title="Date of Custom Duty Payment"
            className="flex-1 w-[200px] "
          >
            <label className="font-bold">Date of Custom Duty Payment</label>

            <input
              type="date"
              min="2000-01-01"
              value={commercialDetails?.custom_duty_payment}
              placeholder="Exchange Rate of Remittence"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  custom_duty_payment: e.target.value,
                });
              }}
            />
          </div>
          <div
            title="Enter Custom Duty Date of Clearance"
            className="flex-1 w-[200px] "
          >
            <label className="font-bold">
              Custom Duty Date of Clearance
              {/* <span className="text-red-1 font-bold">*</span> */}
            </label>
            {/* {console.log(assetBasicDetails?.custom_duty_date_of_clearance)} */}

            <input
              type="date"
              id="timezone"
              value={formatDateInput(
                assetBasicDetails?.custom_duty_date_of_clearance
              )}
              placeholder=""
              maxLength={25}
              min="2000-01-01"
              onChange={(e) => {
                const emojiRegex =
                  /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
                if (!emojiRegex.test(e.target.value)) {
                  setAssetBasicDetails({
                    ...assetBasicDetails,
                    custom_duty_date_of_clearance: e.target.value,
                  });
                } else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
              }}
              className="text-black   focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
            />
          </div>
          {/* Item 10 */}
          <div title="Ex Work Price" className="flex-1 w-[200px] ">
            <label className="font-bold">Ex Work Price ($) </label>

            <input
              type="number"
              min={0}
              maxLength={14}
              value={commercialDetails?.exworks_price}
              placeholder="e.g. ₹1,00,00/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  exworks_price: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 11 */}
          <div title="CIF Charges" className="flex-1 w-[200px] ">
            <label className="font-bold">CIF Charges ($) </label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.cif_charges}
              placeholder="e.g. ₹1,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  cif_charges: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 12 */}
          <div title="Total Cost" className="flex-1 w-[200px] ">
            <label className="font-bold">Total Cost ($) </label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.total_cost}
              placeholder="e.g. $ 1,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  total_cost: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 13 */}
          <div title="BOE Number" className="flex-1 w-[200px] ">
            <label className="font-bold">BOE Number.</label>

            <input
              type="text"
              maxLength={30}
              min={0}
              value={commercialDetails?.boe_no}
              placeholder="e.g. 12345"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  boe_no: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 14 */}
          <div title="Custom Duty Value" className="flex-1 w-[200px] ">
            <label className="font-bold"> Custom Duty Value (₹) </label>

            <input
              type="number"
              maxLength={30}
              min={0}
              value={commercialDetails?.custom_duty_value}
              placeholder="e.g. ₹42,83,472/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  custom_duty_value: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 15 */}
          <div title="GST Amount" className="flex-1 w-[200px] ">
            {/* <TooltipFun placement="top-end"> */}
            <label className="font-bold">GST Amount (₹) </label>
            {/* </TooltipFun> */}
            <input
              type="number"
              min={0}
              value={commercialDetails?.gst_amount}
              placeholder="e.g. ₹2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  gst_amount: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 16 */}
          <div title="Ex Rate as per BOE" className="flex-1 w-[200px] ">
            <label className="font-bold">Ex Rate as per BOE</label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.exrate_boe}
              placeholder="Ex Rate as per BOE"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  exrate_boe: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 17 */}
          <div title="Clearing Charges" className="flex-1 w-[200px] ">
            <label className="font-bold">Clearing Charges (₹) </label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.clearing_charges}
              placeholder="e.g. ₹ 70,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  clearing_charges: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 18 */}
          <div title="Custom House Agent Charges" className="flex-1 w-[200px] ">
            <label className="font-bold">CHA Charges (₹) </label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.cha_charges}
              placeholder="e.g. ₹ 2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  cha_charges: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 19 */}
          <div
            title="Transportation charges upto yard"
            className="flex-1 w-[200px] "
          >
            <label className="font-bold">
              Transportation charges upto yard (₹)
            </label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.transportation_charges}
              placeholder="e.g. ₹ 20,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  transportation_charges: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 20 */}
          <div title="Country / Port of dispatch" className="flex-1 w-[200px] ">
            <label className="font-bold">Country / Port of dispatch</label>

            <input
              type="text"
              value={commercialDetails?.port_of_dispatch}
              placeholder="Country / Port of dispatch"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  port_of_dispatch: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 21 */}
          <div title="Port of Clearance" className="flex-1 w-[200px] ">
            <label className="font-bold"> Port of clearance </label>

            <input
              type="text"
              value={commercialDetails?.port_of_clearance}
              placeholder=" e.g. Chennai"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  port_of_clearance: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 22 */}
          {/* <div className="flex-1 w-[200px] ">
              <label className="font-bold">  Under which GST machine cleared  </label>
              <input
                 type="text"
                 value={commercialDetails?.port_of_clearance}
                placeholder="Under which GST machine cleared"
               className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"

                onChange={(e) => {setCommercialDetails({...commercialDetails, port_of_clearance: e.target.value})
                setUpdateAssetCommercial({
                  ...updateAssetCommercial, "port_of_clearance": e.target.value
                })
              }}
              />
            </div> */}
          {/* Item 23 */}
          <div title="Period of Insurance" className="flex-1 w-[200px] ">
            <label className="font-bold"> Period of Insurance </label>

            <input
              type="text"
              value={commercialDetails?.period_of_insurance}
              placeholder=" Period of Insurance"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  period_of_insurance: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 24 */}
          <div title="Renewal Date" className="flex-1 w-[200px] ">
            <label className="font-bold">Renewal's Date </label>

            <input
              type="date"
              min="2000-01-01"
              value={formatDateInput(commercialDetails?.insurance_renewal)}
              placeholder="Renewal Date"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  insurance_renewal: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 25 */}
          <div title="Total Landed Cost" className="flex-1 w-[200px] ">
            <label className="font-bold">Total Landed Cost (₹) </label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.total_landed_cost}
              placeholder="e.g. ₹ 2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  total_landed_cost: e.target.value,
                });
              }}
            />
          </div>
          {/* Item 25 */}
          {/* {console.log(commercialDetails?.total_landed_cost_with_gst)} */}
          <div
            title="Total Landed Cost (including GST)"
            className="flex-1 w-[200px] "
          >
            <label className="font-bold">
              {" "}
              Total Landed Cost (including GST) (₹){" "}
            </label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.total_landed_cost_with_gst}
              placeholder="e.g. ₹ 2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  total_landed_cost_with_gst: e.target.value,
                });
              }}
            />
          </div>
          {/* item 26 */}
          <div title="Valu of Sold Asset" className="flex-1 w-[200px] ">
            <label className="font-bold">Value of sold asset (₹) </label>

            <input
              type="number"
              min={0}
              value={commercialDetails?.value_of_sold_asset}
              placeholder="e.g. ₹ 2,00,000/-"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  value_of_sold_asset: e.target.value,
                });
              }}
            />
          </div>
          <div title="Sold to customer name" className="flex-1 w-[200px] ">
            <label className="font-bold">Sold to customer name </label>

            <input
              type="text"
              maxLength={25}
              value={commercialDetails?.sold_customer_name}
              placeholder="e.g. TATA"
              className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setCommercialDetails({
                  ...commercialDetails,
                  sold_customer_name: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </>
    </>
  );
}

export default NewCommercialDetails;
