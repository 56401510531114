import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { formatTime, formatTimeDuration } from "./Modals/AssetDetails/Tabs/FormatDate";

const Timeline = ({ devId, setShowMachine, handleBottomTab, timeLine }) => {
  const [hover, setHover] = useState(false);

  let dates = [];

  for (let i = 0; i < Object.keys(timeLine.working_time).length; i++) {
    dates.push(Object.keys(timeLine.working_time)[i]);
  }

  const header = ["Date", "Start Time", "End Time", "Duration", "Total Instance"];

  return (
    <Box p={3} bgcolor="white" borderRadius={2} boxShadow={2}>
      {/* Page Title */}
    <h1 className="text-sm text-dark-6 font-semibold">Working Time</h1>

      {/* Table Container */}
      <Box
        position="relative"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {/* Hover Overlay */}
        {hover && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor="rgba(0, 0, 0, 0.4)"
            zIndex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <button
             className="bg-blue-2 text-white buttons"
              onClick={() => {
                setShowMachine(true);
                handleBottomTab(4);
              }}
            >
              Show More
            </button>
          </Box>
        )}

        {/* Table */}
        <TableContainer component={Paper} style={{ fontSize: 12}}>
          <Table size="small" aria-label="working time table">
            {/* Table Header */}
            <TableHead>
              <TableRow>
                {header.map((col) => (
                  <TableCell key={col} align="left" style={{ fontWeight: "semibold" }} sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* Table Body */}
            <TableBody>
              {dates?.reverse()?.slice(0, 6)?.map((date) => (
                <TableRow key={date} hover>
                  <TableCell sx={{ fontSize: '12px' }}>{date}</TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {timeLine.working_time[date].dispute_time
                      ? `${formatTime(timeLine.working_time[date].dispute_time.start)} (dispute time)`
                      : formatTime(timeLine.working_time[date].start)}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {timeLine.working_time[date].dispute_time
                      ? `${formatTime(timeLine.working_time[date].dispute_time.end)} (dispute time)`
                      : formatTime(timeLine.working_time[date].end)}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {formatTimeDuration(timeLine.working_time[date].duration)}
                  </TableCell>
                  <TableCell sx={{ fontSize: '12px' }}>
                    {timeLine.instance_time[date]?.length
                      ? timeLine.instance_time[date]?.length
                      : 0}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Timeline;
