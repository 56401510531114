import React, { useEffect, useState } from "react";
import "./button.css";

import io from "socket.io-client";
import { lastEventData } from "../../../apis/Telematics/last_event_data";
import LocationMarker from "./LocationMarker";
import DeviceMap from "./DeviceMap";
function DeviceEvents({ devId }) {
  const [internalBattery, setInternalBattery] = useState(null);
  const [vehicleBatteryVol, setVehicleBatteryVol] = useState(null);
  const [mapPosition, setMapPosition] = useState([22.539999, 88.354111]);
  const [gpsGroundSpeed, setGpsGroundSpeed] = useState(0);
  const [gpsAltitude, setGpsAltitude] = useState("OFF");
  const [turnSwitch, setTurnSwitch] = useState(false);
  const [DeviceOnOff, setDeviceOnOff] = useState("OFF");

  // const [openInternal, setOpenInternal] = useState(false)
  // const [openVoltage, setOpenVoltage] = useState(false)
  // const [openOnOf, setOpenOnOf] = useState(false)
  // console.log(process.env.REACT_APP_SOCKET_DATA_TOKEN)
  useEffect(() => {
    // console.log(devId);
    const socket = io("https://websocket.durbinservices.com/", {
      extraHeaders: {
        "x-access-token": process.env.REACT_APP_SOCKET_DATA_TOKEN,
      },
    });

    socket.on(`genie/maco-storm/remote-start-stop/${devId}`, (data) => {
      // console.log(data);
    });
    return () => {
      socket.disconnect();
    };
  }, [
    mapPosition,
    vehicleBatteryVol,
    // internalBattery,
    DeviceOnOff,
  ]);

  const deviceOnOffEvent = (triggeredEvent) => {
    const socket = io("https://websocket.durbinservices.com/");
    if (turnSwitch == false) {
      socket.emit(
        "client-message",
        `genie/maco-storm/remote-start-stop/${devId}#TURN_OFF`
      );
    } else {
      socket.emit(
        "client-message",
        `genie/maco-storm/remote-start-stop/${devId}#TURN_ON`
      );
    }
    setTurnSwitch((prev) => !prev);
  };

  const getAllEventData = async () => {
    try {
      let getData = await lastEventData(devId);
      // console.log("Raw Power Data Push:", getData);

      // Parsing Power Data
      try {
        // let powerData = getData?.pwr_data_push?.split(",");
        // console.log("Second Element of Power Data:", powerData[1]);
      } catch (err) {
        console.error("Error parsing power data:", err);
      }

      // Device Internal Battery
      if (getData?.pwr_data_push) {
        const rawData = JSON.parse(getData?.pwr_data_push?.data)
        
        try {
          let batteryData = rawData["SOC"]
          setInternalBattery(batteryData > 100 ? 100 : batteryData <= 1? "1" : Math.floor(batteryData))
          // console.log(batteryData)
          } catch (error) {
            console.error("Error parsing internal battery data:", error);
          }

        // try {
        //   let batteryData = getData?.pwr_data_push?.split("\n")[1];
        //   let parsedBattery = JSON.parse(batteryData);
        //   let soc = parseFloat(parsedBattery["SOC"]);
        //   // console.log("SOC Value:", soc);
        //   setInternalBattery(soc > 100 ? 100 : Math.floor(soc));
        // } catch (err) {
        //   console.error("Error parsing internal battery data:", err);
        // }
      }

      // console.log(internalBattery)

      if(getData?.feedback_push) {
        // console.log(getData?.feedback_push?.data.split("-")[1])
        setDeviceOnOff(getData?.feedback_push?.data.split("-")[1])
      }
      // Vehicle Battery Voltage
      if (getData?.vehicle_engine_status) {
        try {
          let splitVoltage = getData?.vehicle_engine_status.data.split(" - ")[1];
         
          // let voltage = parseFloat(splitVoltage[1]?.trim());
          // console.log("Vehicle Battery Voltage:", voltage);
          // setDeviceOnOff(voltage > 13.5 ? "ON" : "OFF");
          setVehicleBatteryVol(splitVoltage);
        } catch (err) {
          console.error("Error parsing vehicle battery voltage:", err);
        }
      }

      // GPS Data
      if (getData?.gps_push) {
        let rawData
        try {

          rawData = getData?.gps_push?.data;
          const parsedData = JSON.parse(rawData);
  
          // Access lat and lng
          const lat = parsedData.lat / 1000000;
          const lng = parsedData.lng / 1000000;
  

          setMapPosition([lat, lng]);
          setGpsGroundSpeed(parsedData?.speed);
          setGpsAltitude(parsedData?.altitude);
          // console.log("Map Position", mapPosition);
          
          
        } catch (error) {
          console.error("Error parsing GPS data:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  useEffect(() => {
    // console.log(devId);
    getAllEventData();
  }, []);

  return (
    // d8ee6ad2-6639-4783-bd28-a5db20caafff
    // d8ee6ad2-6639-4783-bd28-a5db20caafff

    <>
      <div className=" flex max-md:flex-col  w-full  h-fit pb-4  justify-between gap-1   items-center mx-auto ">
        <div className="flex flex-col bg-white shadow-md rounded-[6px]   gap-2 py-6 max-md:w-full w-[200px] px-2 border text-left">
          <h1 className="text-sm font-extrabold text-dark-6">
            Telematics Data
          </h1>
          <>
            <div className="  items-start bg text-dark-6 max-md:w-full w-[180px] text-xs py-0.5  my-1 flex flex-col justify-start  transition-all ease-in-out duration-300">
              <span className="text-xs flex ">
                Asset Tracker internal battery
              </span>
              <span className="text-xs h-8 max-md:w-full w-[180px] px-2 items-center flex justify-start bg-light-4 text-dark-6 font-bold">
                {internalBattery == null
                  ? "N/A"
                  : internalBattery <= 0
                  ? "0"
                  : internalBattery + "%"}
              </span>
            </div>
          </>
          <>
            <div className=" items-start bg text-dark-6 max-md:w-full w-[200px] text-xs py-0.5  my-1 flex flex-col justify-start  transition-all ease-in-out duration-300">
              <span className="flex text-xs">Vehicle Battery Voltage</span>
              <span className="text-xs h-8 max-md:w-full w-[180px] px-2 items-center flex justify-start bg-light-4 text-dark-6 font-bold">
                {vehicleBatteryVol == null ? "N/A" : vehicleBatteryVol}
              </span>
            </div>
          </>

          <>
            <div className=" items-start bg text-dark-6 max-md:w-full w-[180px] text-xs py-0.5  my-1 flex flex-col justify-start  transition-all ease-in-out duration-300">
              <span className="flex">Engine ON-OFF status</span>
              <span className="text-xs h-8 max-md:w-full w-[180px] px-2 items-center flex justify-start bg-light-4 text-dark-6 font-bold">
                {" "}
                {DeviceOnOff}
              </span>
            </div>
          </>
        </div>

        <div className=" items-start flex justify-start rounded-[6px] shadow-md border w-fit p-3 bg-white">
          <DeviceMap
            mapPosition={mapPosition}
            setMapPosition={setMapPosition}
          />
        </div>
      </div>
    </>
  );
}

export default DeviceEvents;

// TOPIC
// client-message

// MESSAGE DATA
// genie/maco-storm/remote-start-stop/ca3b612b-dc7e-4508-933d-a41aceab6e7b#TURN_ON
// genie/maco-storm/remote-start-stop/ca3b612b-dc7e-4508-933d-a41aceab6e7b#TURN_OFF
