// import { Button, Input } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { setBaseUrl } from "../../../config";

// const EditableLogTable = ({ monthData, setEditable, asset_no }) => {
//   // console.log("Asset No", asset_no);
//   const [formData, setFormData] = useState([]);
//   const [submittedData, setSubmittedData] = useState([

//   ]);

//   useEffect(() => {
//     setFormData(monthData);
//   }, [monthData]);

//   const handleInputChange = (index, field, value) => {

//     // let  updatedFormData =[]
//     if(formData[index].x_studio_time_in && formData[index].x_studio_time_out){
//       if(formData[index].x_studio_time_in > formData[index].x_studio_time_out){
//         toast.error("Time In should be less than Time Out");
//         // return;
//       }  else if(formData[index].x_studio_time_in === formData[index].x_studio_time_out){
//         toast.error("Time In should not be equal to Time Out");
//         // return;
//       }
//     }
//     // Update the formData for the specific field
//     const updatedFormData = [...formData];
//     updatedFormData[index][field] = value;

//     // Update formData state
//     // setFormData(updatedFormData);

//     // Update or add the row to submittedData
//     const updatedRow = updatedFormData[index];
//     setSubmittedData((prevData) => {
//       const existingIndex = prevData.findIndex(
//         (data) => data.x_studio_date === updatedRow.x_studio_date
//       );

//       if (existingIndex >= 0) {
//         // Update existing row
//         const updatedData = [...prevData];
//         updatedData[existingIndex] = {
//           ...updatedData[existingIndex],
//           ...updatedRow,
//         };
//         return updatedData;
//       } else {
//         // Add new row
//         return [...prevData, { ...updatedRow }];
//       }
//     });
//   };

// const handleSave = async (e) => {
//     e.preventDefault();
//   // Define or pass the asset_no here
//     const list = [
//         "x_studio_date",
//         "x_studio_day_type",
//         "x_studio_time_in",
//         "x_studio_time_out",
//         "x_studio_breakdown_time",
//         "x_studio_overtime",
//         "x_studio_reimbursements",
//         "x_studio_rental_register_no",
//         "x_name",
//         "odoo_id",
//     ];
//     let SubmissionData = [];

//     for (let i = 0; i < submittedData.length; i++) {
//         const objData = submittedData[i];
//         let obj = {};
//         for (let j = 0; j < Object.keys(objData).length; j++) {
//             let objectKey = Object.keys(objData)[j];
//             if (objectKey === "x_studio_operator") {
//                 obj["x_studio_operator1"] = submittedData[i][objectKey];
//             }

//             if (list.includes(objectKey)) {
//                 obj[objectKey] = submittedData[i][objectKey];
//             } else if(list.includes("x_name")){
//                 obj["x_name"] = asset_no;
//             }
//         }
//         SubmissionData.push(obj);
//     }

//     // console.log("SubmissionData", SubmissionData);

//     // Uncomment and complete the fetch request if necessary
// try {
//   const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
//     method: "POST",
//     body: JSON.stringify({
//       input_arr: SubmissionData,
//       action: "replace",
//     }),
//     headers: {
//       "Content-Type": "application/json",
//       "x-access-tokens": sessionStorage.getItem("token"),
//     },
//   });

//   if (response.ok) {
//     toast.success("Data updated successfully!");
//   } else {
//     throw new Error("Failed to update data");
//   }
// } catch (error) {
//   console.error(error);
//   toast.error("Data not updated successfully. Please check odoo logs and try again!!");
// }
// };

//   return (
//     <div>
//       <table className="w-full table-auto text-center bg-white">
//         <thead className="border-b">
//           <tr>
//             <th className="p-2">Date</th>
//             <th className="p-2">Day Type</th>
//             <th className="p-2">Time In</th>
//             <th className="p-2">Time Out</th>
//             <th className="p-2">Total Time</th>
//             <th className="p-2">Overtime</th>
//             <th className="p-2">Time Adjust</th>
//             <th className="p-2">Odoo Bill Amount</th>
//             <th className="p-2">Overtime Amount</th>
//             <th className="p-2">Operator</th>
//           </tr>
//         </thead>
//         <tbody>
//           {formData.map((item, i) => (
//             <tr key={i} className="border-b">
//               <td className="p-2">
//                 <input
//                   type="date"
//                   value={item.x_studio_date || ""}
//                   onChange={(e) =>
//                     handleInputChange(i, "x_studio_date", e.target.value)
//                   }
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <select
//                   value={item.x_studio_day_type || ""}
//                   onChange={(e) => {
//                     handleInputChange(i, "x_studio_day_type", e.target.value);
//                     // setSubmittedData({...submittedData, x_studio_day_type:e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 >
//                   <option value="" disabled>
//                     Select Day Type
//                   </option>
//                   <option value="dry">dry</option>
//                   <option value="wet">wet</option>
//                 </select>
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_time_in || ""}
//                   onChange={(e) => {
//                     handleInputChange(i, "x_studio_time_in", e.target.value);
//                     // setSubmittedData({...submittedData, x_studio_time_in:e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_time_out || ""}
//                   onChange={(e) => {
//                     handleInputChange(i, "x_studio_time_out", e.target.value);
//                     // setSubmittedData({...submittedData, x_studio_time_out:e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_total_time || ""}
//                   onChange={(e) => {
//                     handleInputChange(i, "x_studio_total_time", e.target.value);
//                     // setSubmittedData({...submittedData, x_studio_total_time:e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_overtime || ""}
//                   onChange={(e) => {
//                     handleInputChange(i, "x_studio_overtime", e.target.value);
//                     // setSubmittedData({...submittedData, x_studio_overtime: e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_time_adjust || ""}
//                   onChange={(e) => {
//                     handleInputChange(
//                       i,
//                       "x_studio_time_adjust",
//                       e.target.value
//                     );
//                     // setSubmittedData({...submittedData, x_studio_time_adjust: e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_normal_bill_amount || ""}
//                   onChange={(e) => {
//                     handleInputChange(
//                       i,
//                       "x_studio_normal_bill_amount",
//                       e.target.value
//                     );
//                     // setSubmittedData({...submittedData, x_studio_normal_bill_amount: e.target.value})
//                   }}
//                   className="w-full text-right focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <input
//                   type="number"
//                   value={item.x_studio_overtime_amount || ""}
//                   onChange={(e) => {
//                     handleInputChange(
//                       i,
//                       "x_studio_overtime_amount",
//                       e.target.value
//                     );
//                     // setSubmittedData({...submittedData, x_studio_overtime_amount: e.target.value})
//                   }}
//                   className="w-full text-right focus:outline-none text-[10px]"
//                 />
//               </td>
//               <td className="p-2">
//                 <Input
//                   sx={{
//                     fontSize: "10px",
//                   }}
//                   value={item.x_studio_operator || ""}
//                   onChange={(e) => {
//                     handleInputChange(i, "x_studio_operator", e.target.value);
//                     // setSubmittedData({...submittedData, x_studio_operator: e.target.value})
//                   }}
//                   className="w-full text-center focus:outline-none text-[10px]"
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="flex justify-end gap-4 mt-2">
//         <Button variant="contained" color="primary" onClick={handleSave}>
//           Save
//         </Button>
//         <Button
//           variant="outlined"
//           color="secondary"
//           onClick={() => setEditable(false)}
//         >
//           Cancel
//         </Button>
//       </div>
//     </div>
//   );
// };

// export default EditableLogTable;

// import React, { useEffect, useState } from "react";

// const EditableLogTable = ({ rso_id, asset_no, leaseId, tableData, monthData }) => {
//   console.log(monthData[0].x_studio_date.split('-')[1].split('-'))
//   const [selectedMonth, setSelectedMonth] = useState("");
//   const [formData, setFormData] = useState([]);
//   const [openConflict, setOpenConflict] = useState(false);
//   // existing data
//   // const [existing, setExisting] = useState(null);
//   let actionType = null;

//   // Helper function to convert HH:MM to decimal format
//   const timeToDecimal = (time) => {
//     const [hours, minutes] = time.split(":").map(Number);
//     return hours + minutes / 60;
//   };

//   // Define initial field structure
//   const initialFields = {
//     x_studio_date: "",
//     x_studio_day_type: "",
//     x_studio_time_in: "",
//     // x_studio_rental_register_no: rso_id,
//     x_studio_time_out: "",
//     x_studio_breakdown_time: "",
//     x_studio_overtime: "",
//     x_studio_operator1: "",
//     x_studio_reimbursements: "",
//   };

//   // Calculate the number of days in the selected month and year
//   const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

//   // Handle month change
//   const handleMonthChange = (e) => {
//     const [year, month] = e.target.value.split("-");
//     setSelectedMonth(e.target.value);

//     const daysInMonth = getDaysInMonth(month, year);

//     // If month data doesn't exist in tableData, initialize formData with empty fields for each day
//     if (!tableData[e.target.value]) {
//       setFormData(
//         Array.from({ length: daysInMonth }, (_, index) => ({
//           ...initialFields,
//           x_studio_date: `${year}-${month}-${String(index + 1).padStart(
//             2,
//             "0"
//           )}`,
//         }))
//       );
//     } else {
//       // setFormData([...tableData[e.target.value], ]);
//       // If data exists, check for missing days and complete the form for the entire month
//       const updatedFormData = Array.from(
//         { length: daysInMonth },
//         (_, index) => {
//           const day = `${year}-${month}-${String(index + 1).padStart(2, "0")}`;

//           // Check if the day already exists in the tableData for the selected month
//           const existingData = tableData[e.target.value].find(
//             (entry) => entry.x_studio_date === day
//           );

//           // If existing data is found, use it; otherwise, use initialFields

//           // return { ...initialFields, x_studio_date: day}
//           return existingData
//             ? existingData
//             : {
//                 ...initialFields,
//                 x_studio_date: day,
//               };
//         }
//       );

//       setFormData(updatedFormData);
//     }
//   };

//   // Use formData directly in transformedData, whether or not tableData has data for the selected month
//   // const transformedData = formData;

//   // Filtered fields to display in the table
//   const displayedFields = [
//     "x_studio_date",
//     // "x_studio_rental_register_no",
//     "x_studio_day_type",
//     "x_studio_time_in",
//     "x_studio_time_out",
//     "x_studio_breakdown_time",
//     "x_studio_overtime",
//     "x_studio_operator1",
//     "x_studio_reimbursements",
//   ];

//   const handleChange = (dayIndex, field, value) => {

//     // Check if the field is for time and ensure the value has up to 2 decimal places
//     if (field === "x_studio_time_in" || field === "x_studio_time_out") {
//       if (/^\d{0,2}(\.\d{0,2})?$/.test(value)) {
//         setFormData((prevData) => {
//           const updatedFormData = [...prevData];
//           updatedFormData[dayIndex][field] = value;
//           return updatedFormData;
//         });
//       }
//     } else {
//       // For other fields, check if value is an array and extract the string if needed
//       const formattedValue = Array.isArray(value) ? value[1] : value;

//       setFormData((prevData) => {
//         const updatedFormData = [...prevData];
//         updatedFormData[dayIndex][field] = formattedValue;
//         return updatedFormData;
//       });
//     }
//   };

//   const [operatorData, setOperatorData] = useState(null);

//   const convertedFormData = formData
//     .filter((entry) => entry.x_studio_time_in && entry.x_studio_time_out)
//     .map((entry) => {
//       const dayType = entry.x_studio_day_type || "WD";
//       const Operator =
//         entry?.x_studio_operator1[0] || operatorData[0]?.operator_name;

//         // console.log(entry.x_studio_operator1[1])
//       //   if (Array.isArray(Operator)) {
//       //     // Convert array to string
//       //     let operatorString = Operator.join(','); // Join with comma or another separator if needed
//       //     value = Operator[1]; // Take the second element
//       //     console.log("Operator as a string:", operatorString);
//       //     console.log("Value at index 1:", value);
//       // } else {
//       //     console.log("Operator is not an array.");
//       // }

//       return {
//         ...entry,
//         x_studio_day_type: dayType,
//         x_studio_time_in: entry.x_studio_time_in,
//         x_studio_time_out: entry.x_studio_time_out,
//         x_studio_rental_register_no: rso_id,
//         x_name: asset_no,
//         x_studio_operator1: Operator,
//       };
//     });

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // Uncomment to submit data
//     SubmissionFunction();
//   };

//   const SubmissionFunction = async () => {
//     try {
//       const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
//         method: "POST",
//         body: JSON.stringify({
//           input_arr: convertedFormData,
//           action: actionType,
//         }),
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//       });
//       const data = await response.json();

//       // console.log(data.type);
//       if (data.type === "conflict") {
//         setOpenConflict(true);
//       }

//       if (response.status === 200) {
//         alert("Submission Successfull");
//         window.location.reload();

//       } else {
//         alert("Submission Failure!! Please Re-Submit");
//         console.log(response);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//     // console.log(convertedFormData)
//   };

//   const [loading, setLoading] = useState(false);
//   const getOperatorByLeaseID = async () => {
//     setLoading(true);
//     try {
//       const res = await fetch(`${setBaseUrl}/lease/get-operator-by-lease-id`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//           "lease-id": leaseId,
//         },
//       });

//       const data = await res.json();
//       setOperatorData(data);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getOperatorByLeaseID();
//   }, []);

//   // console.log(actionType);

//   // console.log(displayedFields);
//   return (
//     <>
//       <h2 className="text-[12px] font-semibold mb-4">Monthly Log Form</h2>
//       <input
//         type="month"
//         className="mb-4 text-[10px] focus:outline-none focus:ring-0 focus:border-none border-none shadow-md rounded-[2px]"
//         value={selectedMonth}
//         onChange={handleMonthChange}
//       />
//       <div className="container mx-auto p-6 text-[10px] bg-white shadow-md ">
//         <div className="overflow-x-auto">
//           <table className="w-full text-[10px] text-center">
//             <thead className="w-full">
//               <tr>
//                 {displayedFields.map((field, index) => (
//                   <th
//                     key={index}
//                     className="px-4 py-2 border text-center text-[10px] capitalize w-[250px]"
//                   >
//                     {field.replace("x_studio_", "").replace(/_/g, " ")}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {formData.map((dayData, dayIndex) => (
//                 <tr key={dayIndex} className="border-b text-center">
//                   {displayedFields.map((field, index) => (
//                     <td key={index} className="border">
//                       {field === "x_studio_day_type" ? (
//                         <select
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           value={dayData[field] || "WD"}
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         >
//                           <option value="WD">WD</option>
//                           <option value="HD">HD</option>
//                         </select>
//                       ) : field === "x_studio_operator1" ? (
//                         <>
//                           {/* <select
//                             onChange={(e) =>
//                               handleChange(dayIndex, field, e.target.value)
//                             }
//                             value={
//                               dayData[field][1] ||
//                               operatorData[0]?.operator_name
//                             }
//                             className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                           >
//                             {dayData[field] && (
//                               <option value={dayData[field]}>
//                                 {dayData[field]}
//                               </option>
//                             )}
//                             {operatorData !== null &&
//                               operatorData?.map((operator) => (
//                                 <>
//                                   <option
//                                     value={operator.operator_name}
//                                     key={operator.id}
//                                   >
//                                     {operator.operator_name}
//                                   </option>
//                                 </>
//                               ))}
//                           </select> */}
//                           <select
//                             onChange={(e) =>
//                               handleChange(dayIndex, field, e.target.value)
//                             }
//                             value={
//                               typeof dayData[field] === "object"
//                                 ? dayData[field][1]
//                                 : dayData[field]
//                             }
//                             className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                           >
//                             {dayData[field] && (
//                               <option
//                                 value={dayData[field][1] || dayData[field]}
//                               >
//                                 {dayData[field][1] || dayData[field]}
//                               </option>
//                             )}
//                             {operatorData?.map((operator) => (
//                               <option
//                                 value={operator.operator_name}
//                                 key={operator.id}
//                               >
//                                 {operator.operator_name}
//                               </option>
//                             ))}
//                           </select>
//                         </>
//                       ) : field === "x_studio_time_in" ||
//                         field === "x_studio_time_out" ? (
//                         <input
//                           type="number"
//                           value={dayData[field]}
//                           step="0.01"
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       ) : field === "x_studio_date" ? (
//                         <input
//                           type="text"
//                           value={dayData[field]}
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       ) : (
//                         <input
//                           type="number"
//                           value={dayData[field]}
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       )}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//             {openConflict && (
//               <>
//                 <div className="flex flex-col fixed top-[20%] left-[40%] h-[200px] w-[400px] bg-white  items-center justify-center z-[9999] shadow-md">
//                   <span className="text-sm text-light-3">
//                     Information is already field!!! <br /> Do You Want to
//                     Replace it or Your Want to keep both?{" "}
//                   </span>
//                   <div className="flex p-5 gap-2">
//                     <button
//                       className="border border-light-1 text-light-1 text-xs p-1"
//                       onClick={() => {
//                         setOpenConflict(false);
//                       }}
//                     >
//                       Cancel
//                     </button>
//                     <button
//                       className="bg-light-1 text-white text-xs p-1"
//                       onClick={() => {
//                         actionType = "replace";
//                         SubmissionFunction();
//                       }}
//                     >
//                       Replace
//                     </button>
//                     <button
//                       className="bg-red-600 text-white text-xs p-1"
//                       onClick={() => {
//                         actionType = "keep";
//                         SubmissionFunction();
//                       }}
//                     >
//                       Keep Both
//                     </button>
//                   </div>
//                 </div>

//                 <div
//                   className="z-0 fixed top-0 bottom-0 left-0 right-0 bg-[#00000028]"
//                   onClick={() => setOpenConflict(false)}
//                 />
//               </>
//             )}
//           </table>
//         </div>
//       </div>
//       <div className="flex gap-2">

//         <button className="mt-4  p-1 transition text-[10px] shadow-md bg-white text-text-1 rounded-[4px] font-semibold" onClick={() => setFormData([])}>Cancel</button>
//       <button
//         onClick={handleSubmit}
//         className="mt-4 bg-blue-2 text-white p-1 transition text-[10px] w-[39px] h-[24px] font-semibold rounded-[4px] shadow-md hover:bg-blue-1"
//         >
//         Save
//       </button>
//         </div>
//     </>
//   );
// };

// const EditableLogTable = ({ rso_id, asset_no, leaseId, tableData, monthData }) => {
//   console.log(monthData[0]?.x_studio_date.split('-')[1].split('-')); // Debugging the month data.

//   const [selectedMonth, setSelectedMonth] = useState("");
//   const [formData, setFormData] = useState([]);
//   const [openConflict, setOpenConflict] = useState(false);
//   let actionType = null;

//   // Helper function to convert HH:MM to decimal format
//   const timeToDecimal = (time) => {
//     const [hours, minutes] = time.split(":").map(Number);
//     return hours + minutes / 60;
//   };

//   // Define initial field structure
//   const initialFields = {
//     x_studio_date: "",
//     x_studio_day_type: "",
//     x_studio_time_in: "",
//     x_studio_time_out: "",
//     x_studio_breakdown_time: "",
//     x_studio_overtime: "",
//     x_studio_operator1: "",
//     x_studio_reimbursements: "",
//   };

//   // Calculate the number of days in the selected month and year
//   const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

//   const displayedFields = [
//     "x_studio_date",
//     "x_studio_day_type",
//     "x_studio_time_in",
//     "x_studio_time_out",
//     "x_studio_breakdown_time",
//     "x_studio_overtime",
//     "x_studio_operator1",
//     "x_studio_reimbursements",
//   ];

//   const handleChange = (dayIndex, field, value) => {
//     if (field === "x_studio_time_in" || field === "x_studio_time_out") {
//       if (/^\d{0,2}(\.\d{0,2})?$/.test(value)) {
//         setFormData((prevData) => {
//           const updatedFormData = [...prevData];
//           updatedFormData[dayIndex][field] = value;
//           return updatedFormData;
//         });
//       }
//     } else {
//       const formattedValue = Array.isArray(value) ? value[1] : value;

//       setFormData((prevData) => {
//         const updatedFormData = [...prevData];
//         updatedFormData[dayIndex][field] = formattedValue;
//         return updatedFormData;
//       });
//     }
//   };

//   const [operatorData, setOperatorData] = useState(null);

// const convertedFormData = formData
//   .filter((entry) => entry.x_studio_time_in && entry.x_studio_time_out)
//   .map((entry) => {
//     const dayType = entry.x_studio_day_type || "WD";
//     // const Operator = entry?.x_studio_operator1[0] || operatorData[0]?.operator_name;
//     return {
//       ...entry,
//       x_studio_day_type: dayType,
//       x_studio_time_in: entry.x_studio_time_in,
//       x_studio_time_out: entry.x_studio_time_out,
//       x_studio_rental_register_no: rso_id,
//       x_name: asset_no,
//       x_studio_operator1: '',
//     };
//   });

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     SubmissionFunction();
//   };

//   const SubmissionFunction = async () => {
//     try {
//       const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
//         method: "POST",
//         body: JSON.stringify({
//           input_arr: convertedFormData,
//           action: actionType,
//         }),
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//       });
//       const data = await response.json();

//       if (data.type === "conflict") {
//         setOpenConflict(true);
//       }

//       if (response.status === 200) {
//         alert("Submission Successful");
//         window.location.reload();
//       } else {
//         alert("Submission Failure!! Please Re-Submit");
//         console.log(response);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const [loading, setLoading] = useState(false);
//   const getOperatorByLeaseID = async () => {
//     setLoading(true);
//     try {
//       const res = await fetch(`${setBaseUrl}/lease/get-operator-by-lease-id`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//           "lease-id": leaseId,
//         },
//       });

//       const data = await res.json();
//       setOperatorData(data);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getOperatorByLeaseID();
//   }, []);

//   return (
//     <>
//       <h2 className="text-[12px] font-semibold mb-4">Monthly Log Form</h2>

//       <div className="container mx-auto p-6 text-[10px] bg-white shadow-md ">
//         <div className="overflow-x-auto">
//           <table className="w-full text-[10px] text-center">
//             <thead className="w-full">
//               <tr>
//                 {displayedFields.map((field, index) => (
//                   <th
//                     key={index}
//                     className="px-4 py-2 border text-center text-[10px] capitalize w-[250px]"
//                   >
//                     {field.replace("x_studio_", "").replace(/_/g, " ")}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {formData.map((dayData, dayIndex) => (
//                 <tr key={dayIndex} className="border-b text-center">
//                   {displayedFields.map((field, index) => (
//                     <td key={index} className="border">
//                       {field === "x_studio_day_type" ? (
//                         <select
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           value={dayData[field] || "WD"}
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         >
//                           <option value="WD">WD</option>
//                           <option value="HD">HD</option>
//                         </select>
//                       ) : field === "x_studio_operator1" ? (
//                         <>
//                         {/* <select
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           value={typeof dayData[field] === "object" ? dayData[field][1] : dayData[field]}
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         >
//                           {dayData[field] && (
//                             <option value={dayData[field][1] || dayData[field]}>
//                               {dayData[field][1] || dayData[field]}
//                             </option>
//                           )}
//                           {operatorData?.map((operator) => (
//                             <option value={operator.operator_name} key={operator.id}>
//                               {operator.operator_name}
//                             </option>
//                           ))}
//                         </select> */}
//                         </>
//                       ) : field === "x_studio_time_in" || field === "x_studio_time_out" ? (
//                         <input
//                           type="number"
//                           value={dayData[field]}
//                           step="0.01"
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       ) : field === "x_studio_date" ? (
//                         <input
//                           type="text"
//                           value={dayData[field]}
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       ) : (
//                         <input
//                           type="number"
//                           value={dayData[field]}
//                           onChange={(e) =>
//                             handleChange(dayIndex, field, e.target.value)
//                           }
//                           className="w-full border-none text-[10px] focus:outline-none focus:ring-0 focus:border-none"
//                         />
//                       )}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//       <div className="flex gap-2">
//         <button
//           className="mt-4 p-1 transition text-[10px] shadow-md bg-white text-text-1 rounded-[4px] font-semibold"
//           onClick={() => setFormData([])}
//         >
//           Cancel
//         </button>
//         <button
//           onClick={handleSubmit}
//           className="mt-4 bg-blue-2 text-white p-1 transition text-[10px] w-[39px] h-[24px] font-semibold rounded-[4px] shadow-md hover:bg-blue-1"
//         >
//           Save
//         </button>
//       </div>
// </>
// );
// };

import { toast } from "react-toastify";
import { setBaseUrl } from "../../../config";

import { useState, useEffect } from "react";
import moment from "moment";

const EditableLogTable = ({
  rso_id,
  asset_no,
  leaseId,
  tableData,
  monthData,
  setEditable,
  rentalStartDate,
  rentalEndDate,
}) => {
  // const [selectedMonth, setSelectedMonth] = useState("");
  const [formData, setFormData] = useState([]);
  const [operatorData, setOperatorData] = useState(null);
  const [loader, setLoader] = useState(false);

  const getOperatorByLeaseID = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-operator-by-lease-id`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "lease-id": leaseId,
        },
      });
      const data = await res.json();
      setOperatorData(data);
    } catch (error) {
      console.error("Error fetching operator data:", error);
    }
  };

  useEffect(() => {
    getOperatorByLeaseID();
  }, [leaseId]);

  // Helper function to get number of days in a month
  const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

  // Initial field structure
  const initialFields = (date) => ({
    x_studio_date: date,
    x_studio_day_type: "WD",
    x_studio_time_in: "",
    x_studio_time_out: "",
    x_studio_breakdown_time: "",
    x_studio_overtime: "",
    x_studio_operator1: "",
    x_studio_reimbursements: "",
  });

  // Auto-populate the form for the selected month
  useEffect(() => {
    if (monthData && monthData.length > 0) {
      const [year, month] = monthData[0]?.x_studio_date?.split("-").map(Number);
      const days = getDaysInMonth(month, year);

      // Create the formData with initial structure
      const newFormData = Array.from({ length: days }, (_, i) => {
        const formattedDate = `${year}-${String(month).padStart(
          2,
          "0"
        )}-${String(i + 1).padStart(2, "0")}`;

        // Find the matching entry in monthData for the current day
        const matchingData = monthData.find(
          (entry) => entry.x_studio_date === formattedDate
        );

        // If matching data exists, use it, otherwise use the default initialFields
        return matchingData
          ? {
              ...initialFields(formattedDate),
              ...matchingData, // Override with data from monthData if available
            }
          : initialFields(formattedDate);
      });

      setFormData(newFormData);
    }
  }, [monthData]);

  const [updatedFormData, setUpdatedFormData] = useState({});

  const handleFieldChange = (date, field, value, dayIndex) => {
    setUpdatedFormData((prevData) => {
      const updatedData = { ...prevData };

      // If there's a value, update the field
      if (value !== undefined && value !== null && value !== "") {
        // Check if data for the specific date already exists in updatedFormData
        if (updatedData[dayIndex]) {
          updatedData[dayIndex][field] = value; // Update the field for the existing date
        } else {
          updatedData[dayIndex] = { [field]: value }; // If no data for that date, create a new entry
        }
      } else {
        // If value is undefined, null, or empty, do not add or remove the entry
        if (updatedData[dayIndex]) {
          delete updatedData[dayIndex]; // Remove the entry for that date if no valid data exists
        }
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert updatedFormData object to an array
    const convertedFormData = Object.entries(updatedFormData).map(
      ([dayIndex, entry]) => ({
        ...entry,
        x_studio_date: formData[dayIndex]?.x_studio_date || "",
        x_studio_rental_register_no: rso_id, // Ensure asset and RSO ID are included
        x_name: asset_no,
        x_studio_breakdown_time:
          entry.x_studio_breakdown_time ||
          formData[dayIndex]?.x_studio_breakdown_time,
        x_studio_overtime:
          entry.x_studio_overtime || formData[dayIndex].x_studio_overtime,
        x_studio_reimbursements:
          entry.x_studio_reimbursements ||
          formData[dayIndex].x_studio_reimbursements,
        x_studio_time_in:
          entry.x_studio_time_in || formData[dayIndex].x_studio_time_in,
        x_studio_time_out:
          entry.x_studio_time_out || formData[dayIndex].x_studio_time_out,
        x_studio_day_type: entry.x_studio_day_type || "WD",
        x_studio_operator1: entry.x_studio_operator || "N/A",
      })
    );

    // console.log("Submitting Data:", convertedFormData);

    if (convertedFormData.length === 0) {
      toast.error("No valid data to submit!");
      return;
    }

    // Now `updatedFormData` contains only the modified entries
    try {
      setLoader(true)
      const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
        method: "POST",
        body: JSON.stringify({
          input_arr: convertedFormData,
          action: "replace",
        }),
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      if (response.ok) {
        toast.success("Data updated successfully!");
        setLoader(false)
        window.location.reload();
      } else {
        throw new Error("Failed to update data");
        setLoader(false)
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Data not updated successfully. Please check odoo logs and try again!!"
      );
      setLoader(false)
    }
  };


  const copyPreviousRow = (dayIndex) => {
    setFormData((prevData) => {
      if (dayIndex === 0) return prevData; // Prevent copying if it's the first row
  
      const updatedData = [...prevData];
      updatedData[dayIndex] = { ...prevData[dayIndex - 1] }; // Copy previous row
  
      // Ensure the date remains unique
      updatedData[dayIndex].x_studio_date = prevData[dayIndex].x_studio_date;
  
      return updatedData;
    });
  };


  return (
    <div>
      {/* Month Selector */}
      
      <div action="" className="bg-white p-2 rounded">
        <table className="w-full text-[10px] text-center ">
          <thead className="">
            <tr className="p-4">
              <th className="p-4">Date</th>
              <th className="p-4">Day</th>
              <th className="p-4">Day Type</th>
              <th className="p-4">Time In</th>
              <th className="p-4">Time Out</th>
              <th className="p-4">Breakdown</th>
              <th className="p-4">Overtime</th>
              <th className="p-4">Operator</th>
              <th className="p-4">Reimbursements</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((dayData, index) => {
              const currentDate = dayData["x_studio_date"];
              const isDisabled =
                (rentalStartDate && currentDate < rentalStartDate) ||
                (rentalEndDate && currentDate > rentalEndDate);

                const rowClass = dayData.x_studio_day_type === "HD" ? "bg-red-100" : "bg-white";

              return (
                <tr key={index} className={`border ${rowClass}`}>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border bg-transparent"
                    }
                    title="Date"
                  >
                    {dayData.x_studio_date}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border bg-transparent"
                    }
                    title="Date"
                  >
                    {moment(dayData.x_studio_date).format("dddd")}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    <select
                      title="Day Type"
                      className="text-[10px] w-full border-none bg-transparent"
                      // value={moment(dayData.x_studio_day_type).format(
                      //   "DD-MM-YYYY"
                      // )}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_day_type = e.target.value;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_day_type",
                          e.target.value,
                          index
                        );
                      }}
                      disabled={isDisabled}
                    >
                      <option value="WD">WD</option>
                      <option value="HD">HD</option>
                    </select>
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                        step="0.01"
                      value={dayData.x_studio_time_in}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_in = parseFloat(e.target.value);
                        setFormData(updatedData);
                        handleFieldChange(dayData.x_studio_date, "x_studio_time_in", parseFloat(e.target.value), index)
                      }}
                    /> */}
                    <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] bg-transparent w-full border-none"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_time_in}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove any non-numeric characters except the decimal point
                        inputValue = inputValue.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is allowed
                        let parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                        }

                        // Limit to 2 digits before and 2 digits after the decimal
                        if (parts[0].length > 2) {
                          parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                        }
                        if (parts[1]?.length > 2) {
                          parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                        }

                        // Combine the parts back into a valid format
                        inputValue =
                          parts.length > 1
                            ? parts[0] + "." + (parts[1] || "")
                            : parts[0];

                        // Update state
                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_in = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_time_in",
                          inputValue,
                          index
                        );
                      }}
                    />
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      disabled={isDisabled}
                      title="Time Out"
                      step="0.01"
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      value={dayData.x_studio_time_out}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_out = parseFloat(
                          e.target.value
                        );
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_time_out",
                          parseFloat(e.target.value),
                          index
                        );
                      }}
                    /> */}
                      <input
                      title="Time out"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none bg-transparent"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_time_out}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove any non-numeric characters except the decimal point
                        inputValue = inputValue.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is allowed
                        let parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                        }

                        // Limit to 2 digits before and 2 digits after the decimal
                        if (parts[0].length > 2) {
                          parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                        }
                        if (parts[1]?.length > 2) {
                          parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                        }

                        // Combine the parts back into a valid format
                        inputValue =
                          parts.length > 1
                            ? parts[0] + "." + (parts[1] || "")
                            : parts[0];

                        // Update state
                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_out = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_time_out",
                          inputValue,
                          index
                        );
                      }}
                    />

                    {/* <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_time_out}
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (!inputValue) {
                          inputValue = "0";
                        }

                        if (inputValue.length > 2) {
                          // Convert last two digits into decimals
                          let intPart = inputValue.slice(0, -2); // Take all but last 2 digits
                          let decimalPart = inputValue.slice(-2); // Take last 2 digits
                          inputValue = `${intPart}.${decimalPart}`; // Combine into float format
                        } else {
                          inputValue = parseInt(inputValue, 10).toString(); // Keep as an integer
                        }

                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_out = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_time_out",
                          inputValue,
                          index
                        );
                      }}
                    /> */}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      title="Breakdown time"
                      disabled={isDisabled}
                      min={0}
                      className="text-[10px] w-full border-none"
                      type="number"
                      value={dayData.x_studio_breakdown_time}
                      step="0.01"
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_breakdown_time = parseFloat(
                          e.target.value
                        );
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_breakdown_time",
                          parseFloat(e.target.value),
                          index
                        );
                      }}
                    /> */}
                      <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none bg-transparent"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_breakdown_time}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove any non-numeric characters except the decimal point
                        inputValue = inputValue.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is allowed
                        let parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                        }

                        // Limit to 2 digits before and 2 digits after the decimal
                        if (parts[0].length > 2) {
                          parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                        }
                        if (parts[1]?.length > 2) {
                          parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                        }

                        // Combine the parts back into a valid format
                        inputValue =
                          parts.length > 1
                            ? parts[0] + "." + (parts[1] || "")
                            : parts[0];

                        // Update state
                        const updatedData = [...formData];
                        updatedData[index].x_studio_breakdown_time = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_breakdown_time",
                          inputValue,
                          index
                        );
                      }}
                    />
                    {/* <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_breakdown_time}
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (!inputValue) {
                          inputValue = "0";
                        }

                        if (inputValue.length > 2) {
                          // Convert last two digits into decimals
                          let intPart = inputValue.slice(0, -2); // Take all but last 2 digits
                          let decimalPart = inputValue.slice(-2); // Take last 2 digits
                          inputValue = `${intPart}.${decimalPart}`; // Combine into float format
                        } else {
                          inputValue = parseInt(inputValue, 10).toString(); // Keep as an integer
                        }

                        const updatedData = [...formData];
                        updatedData[index].x_studio_breakdown_time = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_breakdown_time",
                          inputValue,
                          index
                        );
                      }}
                    /> */}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      title="Overtime "
                      disabled={isDisabled}
                      step="0.01"
                      value={dayData.x_studio_overtime}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_overtime = parseFloat(
                          e.target.value
                        );
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_overtime",
                          parseFloat(e.target.value),
                          index
                        );
                      }}
                    /> */}
                       <input
                      title="Overtime"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none bg-transparent"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_overtime}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove any non-numeric characters except the decimal point
                        inputValue = inputValue.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is allowed
                        let parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                        }

                        // Limit to 2 digits before and 2 digits after the decimal
                        if (parts[0].length > 2) {
                          parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                        }
                        if (parts[1]?.length > 2) {
                          parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                        }

                        // Combine the parts back into a valid format
                        inputValue =
                          parts.length > 1
                            ? parts[0] + "." + (parts[1] || "")
                            : parts[0];

                        // Update state
                        const updatedData = [...formData];
                        updatedData[index].x_studio_overtime = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_overtime",
                          inputValue,
                          index
                        );
                      }}
                    />
                    {/* <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.01"
                      value={dayData.x_studio_overtime}
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (!inputValue) {
                          inputValue = "0";
                        }

                        if (inputValue.length > 2) {
                          // Convert last two digits into decimals
                          let intPart = inputValue.slice(0, -2); // Take all but last 2 digits
                          let decimalPart = inputValue.slice(-2); // Take last 2 digits
                          inputValue = `${intPart}.${decimalPart}`; // Combine into float format
                        } else {
                          inputValue = parseInt(inputValue, 10).toString(); // Keep as an integer
                        }

                        const updatedData = [...formData];
                        updatedData[index].x_studio_overtime = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_overtime",
                          inputValue,
                          index
                        );
                      }}
                    /> */}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      disabled={isDisabled}
                      title="Operator Name"
                      className="text-[10px] w-full border-none"
                      type="text"
                      value={dayData.x_studio_operator}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_operator = e.target.value;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_operator",
                          e.target.value,
                          index
                        );
                      }}
                    /> */}

                    <select
                      disabled={isDisabled}
                      title="Operator Name"
                      className="text-[10px] w-full border-none bg-transparent"
                      type="text"
                      value={dayData.x_studio_operator}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_operator = e.target.value;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_operator",
                          e.target.value,
                          index
                        );
                      }}
                    >
                      <option value="">Select Operator</option>
                      {operatorData?.map((operator) => (
                        <option
                          key={operator.id}
                          value={operator.operator_name}
                        >
                          {operator.operator_name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    <input
                      disabled={isDisabled}
                      min={0}
                      title="Reimbursements"
                      className="text-[10px] w-full border-none bg-transparent"
                      type="number"
                      value={dayData.x_studio_reimbursements}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_reimbursements = parseFloat(
                          e.target.value
                        );
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_reimbursements",
                          parseFloat(e.target.value),
                          index
                        );
                      }}
                    />



                  </td>

                  <td className="border p-2">
                      {index > 0 && (
                        <button
                        title="Copy the previous day entered data"
                          onClick={() => copyPreviousRow(index)}
                          className="bg-blue-500 text-white px-2 py-1 rounded-md text-xs hover:bg-blue-600 transition"
                        >
                          Copy
                        </button>
                      )}
                    </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Save Button */}
        <div className="flex items-center gap-2 mt-2">
          <button
            title="back to timesheet"
            onClick={() => {
              setEditable(false);
            }}
            className=" text-black bg-white buttons"
          >
            Cancel
          </button>
          <button
            
            onClick={handleSubmit}
            title="Save changes to timesheet"
            className="buttons bg-blue-500 text-white"
          >
          {loader? 'Saving...': 'Save'} 
          </button>
        </div>
      </div>

      {/* Log Table */}
    </div>
  );
};

export default EditableLogTable;

// export default EditableLogTable;
