
import React from 'react'

const OperatorHeaderTable = ({allOperators}) => {
  return (
    <div className=''>
      <div className="flex gap-3 text-xs mt-2 justify-start">
        
            <span placement="bottom-end" arrow title="Total number of operators" >
        <div className=" p-3 w-[180px] flex flex-col gap-4  rounded-[16px] border hover:shadow-md bg-white" >

            <h1 className="text-[12px]">Total Operators</h1>
            <span className="text-[22px] font-extrabold text-light-2" >{allOperators.length}</span>
        </div>
            </span>
        
    </div>
    </div>
  )
}

export default OperatorHeaderTable
