import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import Loader from "../Loader";
import { LoaderContext } from "../../Context/LoaderContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PermissionContext } from "../../Context/PermissionsContext";
import downloader from "../../assets/images/icons8-download-48.png";
import galleryIcon from "../../assets/images/galleryIcon.png";
import pdfIcon from "../../assets/images/pdfIcon.png";
import Image from "../viewer/Image";
import Document from "../viewer/Document";
import Documents from "../InnerTabs/Documents";
import { formatDate } from "./AssetDetails/Tabs/FormatDate";
import MaintenanceLogData from "../InnerTabs/MaintenanceLogData";

function MaintenanceDetails() {
  const [perms] = useContext(PermissionContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [allMent, setAllMent] = useState({});
  const [parts, setParts] = useState([]);
  const [innerTab, setInnerTab] = useState(1);
  const [dataReload, setDataReload] = useState(true);
  const setLoader = useContext(LoaderContext)[1];
  const loader = useContext(LoaderContext)[0];
  const [documentUrl, setDocumentUrl] = useState([]);

  const getSpecificMen = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/maintenance/get_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      console.log(data);
      setAllMent(data);
      setParts(data.parts);
      setDocumentUrl(data.attachments);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getSpecificMen();
    // console.log(setLoader);
  }, [dataReload]);

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div id="operator-details " className="dark:text-white">
        <div class="flex ">
          <button onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="w-6 h-6 mr-6 cursor-pointer feather feather-arrow-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </button>
          <p class="inline py-5 pl-5 text-xl font-medium text-slate-700 dark:text-slate-200">
            Maintenance Details
          </p>
          <div class="sm:flex items-center ml-auto mt-0 text-xs">
            {(perms.indexOf("MAINT.ALL") !== -1 ||
              perms.indexOf("MAINT.CRU") !== -1 ||
              perms.indexOf("ADMIN.ALL") !== -1) && (
              <span
                onClick={() =>
                  navigate(`/maintenance/update-maintenance/${id}`)
                }
                class=" ml-auto flex items-center justify-center cursor-pointer mr-6 bg-light-1 text-white w-[67px] h-7 rounded i"
              >
                Update
              </span>
            )}
            <button
              onClick={() => setDataReload(!dataReload)}
              class="ml-auto flex items-center justify-center border border-light-1 text-light-1 cursor-pointer w-[67px] h-7 rounded i"
            >
              Reload
            </button>
          </div>
        </div>
        <div class="intro-y box px-5 pt-5 mt-5 bg-white dark:bg-slate-700 rounded-lg ">
          <div class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5">
            <div class="flex justify-between w-full px-5 items-center  lg:justify-between">
              <div class="ml-5">
                <div class="w-full sm:w-40 truncate sm:whitespace-normal font-medium text-xs sm:text-2xl"></div>
                <div class="font-medium ">
                  Status:{" "}
                  <span className="text-gray-500 dark:text-gray-300">
                    {allMent.status}
                  </span>
                </div>
              </div>
              <div class="ml-5">
                <div class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-xs sm:text-2xl"></div>
                <div class="text-gray-600 dark:text-gray-200">
                  Types: {allMent.types}
                </div>
              </div>
            </div>
          </div>
          <div class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start text-xs">
            <span
              onClick={() => setInnerTab(1)}
              class={`lease-details-tab py-4 sm:mr-8 cursor-pointer ${
                innerTab === 1 ? "assetDetailsActive" : ""
              }`}
            >
              Dashboard
            </span>
            <span
              onClick={() => setInnerTab(2)}
              class={`lease-details-tab py-4 sm:mr-8 cursor-pointer ${
                innerTab === 2 ? "assetDetailsActive" : ""
              }`}
            >
              Photos
            </span>
            <span
              onClick={() => setInnerTab(3)}
              class={`lease-details-tab py-4 sm:mr-8 cursor-pointer ${
                innerTab === 3 ? "assetDetailsActive" : ""
              }`}
            >
              Documents
            </span>
            <span
              onClick={() => setInnerTab(4)}
              class={`lease-details-tab py-4 sm:mr-8 cursor-pointer ${
                innerTab === 4 ? "assetDetailsActive" : ""
              }`}
            >
              Log Data
            </span>
          </div>
        </div>

        {innerTab === 1 && (
          <div id="opD-dashboard" class="leaseD-tabs block mt-4">
            <div class="grid grid-cols-12 gap-6">
              <div class="intro-y box col-span-12 lg:col-span-4 zoom-in bg-white dark:bg-slate-700 rounded-lg">
                <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                  <h2 class=" text-xs mr-auto ">Breakdown Details</h2>
                </div>
                <div class="p-5 text-xs">
                  <div class="flex flex-col sm:flex-row">
                    <div class="mr-auto">
                      <div class="font-medium text-gray-500">Asset Name</div>
                    </div>
                    <div class="flex">
                      <div class="text-center">
                        <div class=" text-white text-xs rounded px-2 mt-1">
                          {allMent?.asset_no}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col sm:flex-row mt-4">
                    <div class="mr-auto">
                      <div class="font-medium text-gray-500">Description</div>
                    </div>
                    <div class="flex">
                      <div class="text-center">
                        <div class="font-medium">{allMent?.description}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col sm:flex-row mt-4">
                    <div class="mr-auto">
                      <div class="font-medium text-gray-500">Start Date</div>
                    </div>

                    <div class="flex">
                      <div class="text-center">
                        <div class="font-medium">
                          {formatDate(allMent?.scheduled_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col sm:flex-row mt-4">
                    <div class="mr-auto">
                      <div class="font-medium text-gray-500">End Date</div>
                    </div>

                    <div class="flex">
                      <div class="text-center">
                        <div class="font-medium">
                          {formatDate(allMent?.ready_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row mt-4">
                    <div className="mr-auto">
                      <div className="font-medium  mx-2"> Status </div>
                    </div>
                    <div className="flex">
                      <div className="text-center">
                        <div className=" text-white text-xs rounded px-2 mt-1">
                          {allMent?.status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {parts.map((part) => (
                <div class="intro-y box col-span-12 lg:col-span-4 zoom-in bg-white dark:bg-slate-700 rounded-lg">
                  <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-xs mr-auto">
                      {part.installation ? "Parts Installed" : "Parts Removed"}
                    </h2>
                  </div>
                  <div class="p-5 text-xs">
                    <div class="flex flex-col sm:flex-row">
                      <div class="mr-auto"></div>
                      <div class="flex"></div>
                    </div>
                    <div class="flex flex-col sm:flex-row mt-4">
                      <div class="mr-auto">
                        <div class="font-medium text-gray-500">
                          Part Number:
                        </div>
                      </div>
                      <div class="flex">
                        <div class="text-center">
                          <div class="font-medium">{part.part_no}</div>
                        </div>
                      </div>
                    </div>
                    {part.installation && (
                      <div class="flex flex-col sm:flex-row mt-4">
                        <div class="mr-auto">
                          <div class="font-medium text-gray-500">Price</div>
                        </div>
                        <div class="flex">
                          <div class="text-center">
                            <div class="font-medium">₹{part.price}</div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div class="flex flex-col sm:flex-row mt-4">
                      <div class="mr-auto">
                        <div class="font-medium text-gray-500"> Quantity </div>
                      </div>
                      <div class="flex">
                        <div class="text-center">
                          <div class=" text-white text-xs rounded px-2 mt-1">
                            {part.quantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {innerTab === 2 && (
          <div id="opD-assets" class="leaseD-tabs mt-4 animSlideUp relative">
            <div class="grid grid-cols-12 gap-6">
              <div class="intro-y box col-span-12 bg-white dark:bg-slate-700 rounded-lg">
                <div class="flex items-center px-5 py-5 sm:py-3   dark:border-dark-5">
                  <h2 class="font-medium text-xs mr-auto">Photos</h2>
                </div>
                <div class="flex p-5">
                  {allMent.photos.length > 0 ? (
                    allMent.photos.map((image) => (
                      <Image image_uri={image.image_uri} />
                    ))
                  ) : (
                    <div>No photos found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {innerTab === 3 && (
          <Documents documentUrl={documentUrl} section="maintenance" />
        )}
        {innerTab === 4 && <MaintenanceLogData id={id} />}
      </div>
    );
  }
}

export default MaintenanceDetails;
