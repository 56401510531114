import React, { useState } from 'react'
import { setBaseUrl } from '../../config';
import { useToast } from '../../Context/ToastContext';

const LocalPart = ({ maintenance_id, setLocatPart }) => {
    const token = sessionStorage.getItem("sales_token");
    const [formData, setFormData] = useState({
        part_name: '',
        part_no: '',
        quantity: '',
        price: ''
    })
    const { addToast } = useToast()
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch(`${setBaseUrl}/sale-service/entry-part-to-approve`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "auth-token": token,
                },
                body: JSON.stringify(formData)
            })

            if (res.status === 200) {
                addToast("Successfully local parts request submitted", "success")
                setTimeout(() => {
                    window.location.reload();
                  }, 2000);
            } else {
                addToast("Failed to add local parts", "error")
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            {/* Popup container */}
            <div className="fixed z-[9999] bg-white flex flex-col  p-6 left-[30%]  items-center gap-4 top-[20%] rounded-lg shadow-lg transition-all duration-300 transform">
                <h2 className="text-lg font-semibold text-gray-800">Local Part</h2>
                {/* Part Name */}
                <span className="flex flex-col">
                    <label htmlFor="" className="">Part Number</label>
                    <input type="text" className="w-full" onChange={(e) => setFormData({
                        ...formData, part_no: e.target.value
                    })} />
                </span>

                <span className="flex flex-col">
                    <label htmlFor="" className="">Part Name</label>
                    <input type="text" className="w-full" onChange={(e) => { setFormData({ ...formData, part_name: e.target.value }) }} />
                </span>


                <span className="flex flex-col">
                    <label htmlFor="" className="">Quantity</label>
                    <input type="number" min={0} className="w-full" onChange={(e) => { setFormData({ ...formData, quantity: e.target.value }) }} />
                </span>


                <span className="flex flex-col">
                    <label htmlFor="" className="">Price</label>
                    <input type="number" min={0} className="w-full" onChange={(e) => { setFormData({ ...formData, price: e.target.value }) }} />
                </span>


                <div className="flex justify-between w-full mt-4 gap-2">
                    <button
                        className="buttons bg-gray-100  border w-full"
                        onClick={() => setLocatPart(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="buttons bg-white text-red-500 border border-red-500  w-full"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>


                </div>
            </div>

            {/* Overlay Background */}
            <div
                className="fixed z-0 top-0 bottom-0 left-0 right-0 bg-[#00000060]"
                onClick={() => setLocatPart(false)}
            />
        </>
    )
}

export default LocalPart