import React, { useState } from 'react'

const RejectionForm = ({setRejectComment, handleServiceAction}) => {
  




   

    return (
        <div className="flex flex-col gap-4 border w-full  p-5 text-xs mt-4 rounded-[16px] bg-white" >
            <h3 className="text-xs p-2 my-2 w-full bg-gray-200">Rejection Form</h3>

            <div className="">
                <label htmlFor="" className="">Comments</label>
                <textarea name=""  className="w-full text-xs " id="" onChange={(e) => { setRejectComment(e.target.value) }}>

                </textarea>
            </div>



            <div className="flex justify-end gap-4">

                <button className="w-full text-xs px-4 py-2 items-center border  border-red-1 text-red-1 rounded-[4px]" onClick={() => handleServiceAction(false)}>Submit</button>
            </div>
        </div>
    )
}

export default RejectionForm