import axios from "axios";
import { setBaseUrl } from "../../config";

export const log_sheet_entry_without_action = (logFile) =>
  axios.post(
    `${setBaseUrl}/lease/entry-excel-data`,
    {
      excel: logFile,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-tokens": sessionStorage.getItem("token"),
      },
    }
  );

export const log_sheet_entry_with_action = (logFile, action) =>
  axios.post(
    `${setBaseUrl}/lease/entry-excel-data`,
    {
      excel: logFile,
      action: action,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-tokens": sessionStorage.getItem("token"),
      },
    }
  );

export const get_log_table_data = (odoo_id) =>
  axios.get(`${setBaseUrl}/lease/get-time-sheet`, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-tokens": sessionStorage.getItem("token"),
      "rent-odoo-id": odoo_id,
    },
  });
