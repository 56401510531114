import React from "react";

const PhotoDataTab = ({ imageArray, assetName }) => {
  return (
    <div className="pb-12">

    <div className="pb-12 text-xs">
      {imageArray && imageArray.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-screen  gap-6">
          {imageArray.map((image, index) => (
            <div
              key={index}
              className=" rounded-[6px]  bg-white shadow-md overflow-hidden  transition-shadow h-fit flex flex-col items-center"
            >
              {/* Image Container */}
              <div className="w-full h-fit overflow-hidden ">
                <img
                  src={image.image_uri}
                  alt={`Asset ${image.id}`}
                  className="w-full h-full object-cover hover:scale-105 transition-transform"
                  onError={(e) => {
                    e.target.src =
                      "https://via.placeholder.com/150?text=Image+Not+Available"; // Fallback image
                  }}
                />
              </div>

              {/* Caption */}
              <div className="w-full flex justify-between items-center bg-white p-2 text-center">
                <p className="text-xs text-dark-6 truncate">
                  {`Asset ID`}
                </p>
                <span className="text-xs font-bold text-dark-6">{assetName}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-center">No images available</p>
      )}
    </div>
    </div>
  );
};

export default PhotoDataTab;
