import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import totalAssets from "../assets/images/dashboard/totalAssets.svg";
import freeAssestsIcon from "../assets/images/dashboard/freeAssets.svg";
import leasedAssets from "../assets/images/dashboard/leasedAssets.svg";

import assetsInBreakdown from "../assets/images/dashboard/assetsInBreakdown.svg";
import usageHours from "../assets/images/dashboard/usageHours.svg";
import actualhours from "../assets/images/dashboard/actualhours.svg";
import totalOperators from "../assets/images/dashboard/totalOperators.svg";
import { allDashboardData } from "../apis/dashboard/all_data_for_dashboard";
import { useQuery } from "@tanstack/react-query";
import CategoryTable from "../components/tables/DashboardTable/CategoryTable";
import Loader from "../components/Loader";
import { NavbarContext } from "../Context/NavbarContext";
import DashboardMap from "../components/Modals/Dashboard/DashboardMap";

function Dashboard() {
  const [, setNavState] = useContext(NavbarContext);
  const navigate = useNavigate();
  const handleReload = () => {
    document.location.reload();
  };

  const {
    isLoading,
    isError,
    data: allData,
    error,
  } = useQuery({ queryKey: ["dashboard_data"], queryFn: allDashboardData });

  useEffect(() => {
    setNavState(1);
  }, []);

  if (isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (isError) {
    return (
      <span className="text-center items-center  flex flex-col justify-center text-white font-bold text-6xl mt-[20%] ">
        Something Wrong !!!!!!
      </span>
    );
  }

  const Data = [
    {
      title: "Total Assets",
      
      route: "/assets",
      data: allData?.total_assets,
    },
    {
      title: "Sold Assets",
      
      route: "/assets?asset=sold",
      data: allData?.sale_asset,
    },
    {
      title: "Free Assets",
      
      route: "/assets?asset=free",
      data: allData?.total_free_assets,
    },
    {
      title: "Asset Leased",
      
      route: "/lease",
      data: allData?.total_assets_active_in_lease,
    },

    {
      title: "Assets (Breakdown)",
      
      route: "#",
      data: allData?.number_asset_break_down_in_maintenance,
    },
    {
      title: "Total Operators",
      
      route: "#",
      data: allData?.total_number_operator,
    },
  ];

  return (
//     <Layout>
//       <>
//         <div className="flex flex-col text-xs  mt-2" id="dashboard">
//           {/* Header */}

//           <div className="grid grid-cols-6 gap-3 mx-4">
//             {Data.map((data, index) => (
              // <>
              //   <div
              //     className="rounded-[8px] shadow-md bg-white px-4 py-3"
              //     key={index}
              //   >
              //     <div className="flex justify-between border-l-2 border-light-6 pl-2">
              //     <h1 className="flex flex-col">
              //     {/* <span className="font-bold text-sm ">{index >= 0 && index <= 4 ? "Assets":"Operators"}</span> */}
              //       {data.title}
              //     </h1>
              //     {/* <img src={data.imgUrl} alt="" className="my-2 h-4 w-4" /> */}
                  
              //     </div>
              //     {index === 5 || index === 4 ? (
              //       <>
              //         <p className="text-base font-extrabold text-light-2 my-2   ml-3">
              //           {data.data}
              //         </p>
              //       </>
              //     ) : index === 0? (
              //     <>
              //      <p className="text-base font-extrabold text-light-2 my-2  ml-3">{data.data}</p>
              //     </>
              //     ) : (
              //       <>
                    
              //         <p className="text-base font-extrabold text-light-2 my-2  ml-3">{data.data}</p>
              //       </>
              //     )}

               
              //     {/* {index >= 0 && (
              //       <Link to={data.route} className="text-[10px] text-dark-6 underline ml-3">
              //         See All Assets
              //       </Link>
              //     )} */}

              //     {index === 0 ? <>
              //       <Link to={data.route} className="text-[10px] text-blue-2 underline ml-3">
              //         See All Assets
              //       </Link>
              //     </>: index === 1 ? <>
                  
              //     <Link to={data.route} className="text-[10px] text-blue-2 underline ml-3">
              //         Sold Assets
              //       </Link>
              //     </> : index === 2 ? <>
              //     <Link to={data.route} className="text-[10px] text-blue-2 underline ml-3">
              //         Free Assets 
              //       </Link>
              //     </> : ""}
              //   </div>
              // </>
//             ))}
//           </div>
//         </div>
     
//       </>
      
// <div className="flex items-start  justify-between">

//       <CategoryTable />
//       <DashboardMap/>
// </div>
     
//     </Layout>
<>
<Layout>
<div className="">
      {/* <header className="bg-white shadow p-2">
        <h1 className="text-xs font-semibold  text-gray-800">Asset Management Dashboard</h1>
      </header> */}

      <main className="p-0">
        {/* Statistic Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-2 text-[11px]">
          {Data?.map((data, index) => (
            <>
            <div
              className="rounded-[8px] shadow-md bg-white px-4 py-3"
              key={index}
            >
              <div className="flex justify-between border-l-2 border-light-6 pl-2">
              <h1 className="flex flex-col">
              {/* <span className="font-bold text-sm ">{index >= 0 && index <= 4 ? "Assets":"Operators"}</span> */}
                {data.title}
              </h1>
              {/* <img src={data.imgUrl} alt="" className="my-2 h-4 w-4" /> */}
              
              </div>
              {index === 5 || index === 4 ? (
                <>
                  <p className="text-base font-extrabold text-light-2 my-2   ml-3">
                    {data.data}
                  </p>
                </>
              ) : index === 0? (
              <>
               <p className="text-base font-extrabold text-light-2 my-2  ml-3">{data.data}</p>
              </>
              ) : (
                <>
                
                  <p className="text-base font-extrabold text-light-2 my-2  ml-3">{data.data}</p>
                </>
              )}

           
              {/* {index >= 0 && (
                <Link to={data.route} className="text-[10px] text-dark-6 underline ml-3">
                  See All Assets
                </Link>
              )} */}

              {index === 0 ? <>
                <Link to={data.route} className="text-[10px] text-blue-2 underline ml-3">
                  See All Assets
                </Link>
              </>: index === 1 ? <>
              
              <Link to={data.route} className="text-[10px] text-blue-2 underline ml-3">
                  Sold Assets
                </Link>
              </> : index === 2 ? <>
              <Link to={data.route} className="text-[10px] text-blue-2 underline ml-3">
                  Free Assets 
                </Link>
              </> : ""}
            </div>
          </>
          ))}
        </div>

        {/* Main Content */}
        <div className="flex flex-col justify-center items-center  gap-0 ">
          <div className="flex-1">
            <DashboardMap />
          </div>
          <div className="flex-1">
            <CategoryTable />
          </div>
        </div>
      </main>
    </div>

</Layout>
</>
  );
}

export default Dashboard;
