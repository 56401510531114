import React, { useEffect, useState } from 'react'
import { setBaseUrl } from '../config'
import axios from 'axios'
import { formatDate, FormPercentage } from './Modals/AssetDetails/Tabs/FormatDate'

const Timeline = ({ devId, setShowMachine,handleBottomTab }) => {
    const [timeData, setTimeData] = useState(null)
    // console.log(devId)  

    const fetchTimeLineData = async () => {
        try {
            const response = await axios.get(`${setBaseUrl}/asset/get-device-timeline`, 
                 {
                    headers: {
                        "x-access-tokens": sessionStorage.getItem("token"),
                        'device-id': devId,
                    }
                }
            )

            if(!response.status === 200) {
                throw new Error('Network response was not ok')
            }
            const items = response.data
            setTimeData(items)
            // console.log(timeData)
            // console.log(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {    
        fetchTimeLineData()
    }, [])

    const [hover, setHover] = useState(false)

    const header = [
        "Date", "Start Time", "End Time", "Total Duration", "Engine Runtime(hrs)", "Total Instance"
    ]


    return (
        <>
       

        <div className=" w-[100vh]  mt-1">
            <h1 className="text-xs font-bold py-2 mx-2">Device Timeline Details</h1>
            <div className="border rounded-2xl h-[260px] hide-scrollbar overflow-y-auto" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {hover && 
            <>
            <div className="bg-black absolute  h-[260px] w-[100vh] rounded-2xl z-50 bg-opacity-40 flex justify-center items-center">
                <button className="bg-black text-white rounded-full w-[120px] h-7 text-xs" onClick={() => {setShowMachine(true); handleBottomTab(4); }}>Show More</button>
            </div>
            </>}
            {timeData ?     
                <>
               <table className='text-center text-xs border-separate border-spacing-y-0   w-full relative'>
                <thead className='h-10 sticky  bg-[#efefef] dark:bg-gray-700  '>
                    <tr className="" >
                    {header.map((item) => (<>
                    
                          <td key={item}>{item}</td>      
                    </>))}
                    </tr>
                </thead>
                <tbody className="">
                    
                {timeData && Object.keys(timeData).reverse().map((date) => {
               
               let earliestStartTime = null;
               let latestEndTime = null;
               let totalDuration = [0, 0, 0]; // [hours, minutes, seconds]
               let numberOfEntries = 0; // Number of times we are getting data in a day
           
               timeData[date].forEach((item) => {
                   numberOfEntries += 1; // Increment number of entries for each record
           
                   const start = item[1].start_time.split(" ")[1];
                   const end = item[1].end_time.split(" ")[1];
           
                   // Check and set earliest start time
                   if (!earliestStartTime || start < earliestStartTime) {
                       earliestStartTime = start;
                   }
           
                   // Check and set latest end time
                   if (!latestEndTime || end > latestEndTime) {
                       latestEndTime = end;
                   }
           
                   // Calculate the total duration
                   const durationTime = item[0].split(":");
                   totalDuration[0] += parseInt(durationTime[0]); // hours
                   totalDuration[1] += parseInt(durationTime[1]); // minutes
                   totalDuration[2] += parseFloat(durationTime[2]); // seconds
               });
           
               // Normalize the duration (convert seconds to minutes, minutes to hours)
               totalDuration[1] += Math.floor(totalDuration[2] / 60);
               totalDuration[2] = totalDuration[2] % 60;
               totalDuration[0] += Math.floor(totalDuration[1] / 60);
               totalDuration[1] = totalDuration[1] % 60;
           
               const formattedStart = earliestStartTime.split(":").slice(0, 2).join(":");
               const formattedEnd = latestEndTime.split(":").slice(0, 2).join(":");
               const formattedDuration = `${totalDuration[0]}:${totalDuration[1].toString().padStart(2, '0')}:${Math.floor(totalDuration[2]).toString().padStart(2, '0')}`;
           
               // Calculate total working hours (earliest start time to latest end time)
               const totalWorkingHours = (() => {
                   const start = new Date(`1970-01-01T${earliestStartTime}Z`);
                   const end = new Date(`1970-01-01T${latestEndTime}Z`);
                   const diffInMs = end - start;
                   const diffInHours = Math.floor(diffInMs / 1000 / 60 / 60);
                   const diffInMinutes = Math.floor((diffInMs / 1000 / 60) % 60);
                   console.log(diffInMinutes)
                   if(diffInHours > 0) {

                       return `${diffInHours}:${diffInMinutes.toString().padStart(2, '0')}hrs`;
                   } else {
                    return `${diffInMinutes.toString().padStart(2, '0')}:00 min`;
                   }
               })();
    
                return (
                    <tr key={date} className="h-8 items-center">
                        <td className="border-b">{formatDate(date)}</td>
                        <td className="border-b">{formattedStart}</td>
                        <td className="border-b">{formattedEnd}</td>
                        
                        <td className="border-b">{totalWorkingHours}</td>
                        <td className="border-b">{formattedDuration}</td>
                        <td className="border-b">{numberOfEntries}</td>
                    </tr>
                );
            })}

                </tbody>
               </table>
                </> 
                :
                 <>
                 <div className="text-center mt-10 text-xs">Data is not generated!!</div>
                 </>
            }


            </div>
        </div>
    </>
    )
}

export default Timeline
