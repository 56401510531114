import React from 'react'
import SalesLayout from './SalesLayout'
import ServiceWallet from '../../components/ServicePerson/ServiceWallet'

const ServiceWalletPage = () => {
  return (
    <SalesLayout>
      <ServiceWallet/>
      </SalesLayout>
  )
}

export default ServiceWalletPage