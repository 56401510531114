import React, { useState } from 'react';
import { capitalizeFirstLetter, formatDate, formatMaintenanceType } from '../../../customFunctions/FormatDate';

const MaintenanceHeader = ({ maintenanceData }) => {
    const [openWalletId, setOpenWalletId] = useState(null); // Track which wallet is open

    const handleOpenWallet = (id) => {
        setOpenWalletId(id); // Set the ID of the service salesperson whose wallet is open
    };

    const handleCloseWallet = () => {
        setOpenWalletId(null); // Close the wallet modal
    };
    const today = new Date();

    // Remove the time portion from today's date to ensure accurate comparison
    const todayDateOnly = new Date(today.setHours(0, 0, 0, 0));

    // Check if complaintDate exists, remove time portion for comparison
    const complaintDate = maintenanceData?.issue_date ? new Date(maintenanceData?.issue_date) : null;
    const complaintDateOnly = complaintDate ? new Date(complaintDate.setHours(0, 0, 0, 0)) : null;

    // Ensure complaintDate is valid before calculating breakdown
    const breakdown = complaintDateOnly && !isNaN(complaintDateOnly)
        ? Math.floor((todayDateOnly - complaintDateOnly) / (1000 * 60 * 60 * 24))
        : 0;

    // Now handle the breakdown label logic
    const breakdownLabel = breakdown > 0 ? `${breakdown} days` : breakdown === 0 ? "Today" : "Not Filled";


    const HeaderData = [
        {
            title: '#Ticket ID',
            label: <p className='text-blue-2'>  {maintenanceData.ticket_no}</p>,
        },
        {
            title: 'Breakdown Title ',
            label: maintenanceData.complaint,
        },
        {
            title: 'Date of Complain',
            label: maintenanceData.compaint_date ? formatDate(maintenanceData.compaint_date) : "Not Filled",
        },
        {
            title: 'Breakdown Date',
            label: <span className='flex justify-between'>
                <span>{maintenanceData?.issue_date ? formatDate(maintenanceData?.issue_date) : "Not Filled"}</span>
                <span>{breakdownLabel}</span>
            </span>,
        },
        {
            title: 'Breakdown Type',
            label: capitalizeFirstLetter(maintenanceData?.complaint_type) || "Not Filled",
        },
        {
            title: 'Service Category',
            label: maintenanceData?.types !== "types" && formatMaintenanceType(maintenanceData?.types),
        },
        {
            title: 'Deadline',
            label: maintenanceData?.ready_date ? formatDate(maintenanceData?.ready_date) : "Not Filled",
        },
        {
            title: 'Priority',
            label: capitalizeFirstLetter(maintenanceData?.priority) || "Not Filled",
        },
        {
            title: 'Assigned Engineer',
            label: (
               
                <div className="space-y-2">
                    {maintenanceData?.serviceSalePersons?.map((service) => {
                        // Find the corresponding reject comment for this service person
                        const personComment = maintenanceData?.reject_comments?.find(
                            (person) => person.name === service.name
                        );

                        return (
                            <div key={service.id} className="flex justify-between items-center w-full py-2 px-3 bg-gray-50 rounded-lg">
                            {personComment && (personComment.is_accepeted === null || personComment.is_accepeted === true) && (
                                <>
                                    <div className="flex items-center">
                                        <span className={`text-sm font-medium ${personComment.is_accepeted === null
                                                ? 'text-red-500'
                                                : 'text-gray-800'
                                            }`}>
                                            {service.name}
                                            {personComment.is_accepeted === null && (
                                                <span className="ml-1 text-xs text-red-500" title="Request pending">(Pending)</span>
                                            )}
                                        </span>
                                    </div>
        
                                    <button
                                        onClick={() => handleOpenWallet(service.id)}
                                        className="text-xs font-medium text-blue-600 hover:text-blue-800 px-3 py-1 rounded-md bg-blue-50 hover:bg-blue-100 transition-colors"
                                    >
                                        View Wallet
                                    </button>
        
                                    {/* Wallet Modal */}
                                    {openWalletId === service.id && (
                                        <div className="fixed inset-0 z-[9999] flex items-center justify-center p-4">
                                            {/* Overlay */}
                                            <div
                                                className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
                                                onClick={handleCloseWallet}
                                            />
        
                                            {/* Modal Content */}
                                            <div className="relative w-full max-w-fit max-h-[90vh] bg-white rounded-xl shadow-2xl overflow-hidden flex flex-col">
                                                {/* Header */}
                                                <div className="sticky top-0 z-10 bg-white border-b border-gray-200 p-4 flex justify-between items-center">
                                                    <h2 className="text-lg font-semibold text-gray-800">
                                                        <span className="text-blue-600">{service.name}</span>'s Wallet
                                                    </h2>
                                                    <button
                                                        onClick={handleCloseWallet}
                                                        className="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>
        
                                                {/* Body */}
                                                <div className="overflow-y-auto flex-1 p-4">
                                                    {service.wallet && service.wallet.length > 0 ? (
                                                        <div className="space-y-4">
                                                            <div className="overflow-x-auto">
                                                                <table className="min-w-full divide-y divide-gray-200">
                                                                    <thead className="bg-gray-50">
                                                                        <tr>
                                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                                Date
                                                                            </th>
                                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                                Part Number
                                                                            </th>
                                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                                Quantity
                                                                            </th>
                                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                                Status
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                                        {service.wallet.map((item, index) => (
                                                                            <tr key={index} className="hover:bg-gray-50">
                                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                                    {formatDate(item.requested_date)}
                                                                                </td>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                                    {item.part_no}
                                                                                </td>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                                    {item.request_quantity}
                                                                                </td>
                                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${item.is_approved
                                                                                            ? 'bg-green-100 text-green-800'
                                                                                            : 'bg-yellow-100 text-yellow-800'
                                                                                        }`}>
                                                                                        {item.is_approved ? 'Approved' : 'Pending'}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
        
                                                            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                                                                <div className="flex justify-between items-center">
                                                                    <span className="text-sm font-medium text-gray-700">Total Price</span>
                                                                    <span className="text-lg font-semibold text-blue-600">
                                                                        ₹{maintenanceData?.total_part_price?.toLocaleString() || '0'}/-
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex flex-col items-center justify-center py-12">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            <h3 className="mt-2 text-sm font-medium text-gray-900">No items in wallet</h3>
                                                            <p className="mt-1 text-sm text-gray-500">This service provider hasn't added any items yet.</p>
                                                        </div>
                                                    )}
                                                </div>
        
                                                {/* Footer */}
                                                <div className="sticky bottom-0 bg-white border-t border-gray-200 p-4 flex justify-end">
                                                    <button
                                                        onClick={handleCloseWallet}
                                                        className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        );
                    })}
                </div>
            
            ),
        },
     
    ];



    return (
        <div>
            {/* Maintenance Header */}
            <div className="grid grid-cols-5 gap-4">
                {HeaderData.reduce((acc, _, index, array) => {
                    if (index % 2 === 0) {
                        acc.push(array.slice(index, index + 2));
                    }
                    return acc;
                }, []).map((pair, index) => (
                    <div key={index} className="bg-white rounded-lg p-2 text-xs border">
                        {pair.map((item, idx) => (
                            <div key={idx} className="mb-2">
                                <p className="text-xs text-gray-500">{item.title}</p>
                                <p className={`font-semibold ${item.highlight || "text-gray-800"}`}>
                                    {item.label}
                                </p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MaintenanceHeader;