import React from "react";

import { Blocks } from "react-loader-spinner";

function Loader() {
  return (
    // fixed inset-0 z-[9999]
    <div  className='border h-64 p-5 rounded mt-4 shadow-2xl w-[100%] max-w-full mb-5 animate-pulse '>
           <div className="animate-pulse">
            <div className=" h-10 bg-gray-400  mb-4"></div>
            <div className="h-10 bg-gray-300  mb-4"></div>
            <div className="h-10 bg-gray-300  mb-4"></div>
            {/* <div className="h-10 bg-gray-300 rounded-lg mb-4"></div> */}
            <div className="h-10 bg-gray-300  mb-4"></div>
          </div>
    </div>
  );
}

export default Loader;
