import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import MaintenanceTable from "../../tables/MaintenanceTable/MaintenanceTable";
import MaintenanceTab from "./MaintenanceTab";
import { useNavigate, useParams } from "react-router-dom";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import { LoaderContext } from "../../../Context/LoaderContext";
import Loader from "../../Loader";
import LeaseTab from "./LeaseTab";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { PermissionContext } from "../../../Context/PermissionsContext";
import Documents from "../../InnerTabs/Documents";
import DeviceEvents from "../Telematics/DeviceEvents";
import Timeline from "../../Timeline";
import { NoImage } from "./Tabs/NoImage";
import AssetDashboards from "./Tabs/AssetDashboards";
import CommercialDetails from "./Tabs/CommercialDetails";
import PhotoData from "./Tabs/PhotoData";
import AssetCommercial from "./Tabs/AssetCommercial";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssetHeader from "./Tabs/AssetHeader";
import TimeLineDuration from "./TimeLineDuration.jsx";
import PhotoDataTab from "./bottomTabs/PhotoDataTab.jsx";
import RemittenceTable from "./Tabs/RemittenceTable.jsx";
import UserLogData from "./bottomTabs/UserLogData.jsx";
import { useGetAssetWorkingTimeline } from "../../../apis/AssetsApis/api.js";
import { Button, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Loading from "../../Loading.jsx";
import { useToast } from "../../../Context/ToastContext.js";

import Breadcrumb from "../../../customFunctions/Breadcrumb.jsx";

function AssetDetails({ setShowCurrentTab }) {
  const [perms] = useContext(PermissionContext);
  const [loader, setLoader] = useContext(LoaderContext);
  const [assetDetails, setAssetBasicDetails] = useState({});
  const [commercialDetails, setcommercialDetails] = useState({});
  const [innerTab, setInnerTab] = useState(1);
  // Timeline Duration Reference

  const [imageUrl, setImageUrl] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [commercialDetailsExist, setcommercialDetailsExist] = useState(false);
  const [epocToHumanDate, setEpocToHumanDate] = useState("");
  // const [reload, setReload] = useState(false);
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [revenueView, setRevenueView] = useState([]);
  const [odooCost, setOdooCost] = useState(null);
  const [error, setError] = useState(null);
  const { addToast } = useToast();

  let section = null;
  const [timeLine, setTimeLine] = useState({
    instance_time: {},
    working_time: [],
  });
  // console.log(sessionStorage.getItem("currentTab"))
  // let currentTab = sessionStorage.getItem("currentTab");
  const hasFetchedRevenue = useRef(false);
  const hasFetchedDashboard = useRef(false);
  // console.log(currentTab);
  const getRevenueData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${setBaseUrl}/asset/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
          section: "revenue",
        },
      });

      const data = await response.json();
      if (data.revnew_data) {
        setRevenueView(data.revnew_data);
      }

      if (data.commercial_detail[0].odoo_cost) {
        setOdooCost(data.commercial_detail[0].odoo_cost);
      }

      if (response.status !== 200) {
        addToast(
          "Please check Asset Commercial Details or Custom Duty Date of Clearance and Date of remmited to oem",
          "error"
        );
      }
    } catch (error) {
      setLoading(false);
      // alert(error)
      addToast(
        "Please check Asset Commercial Details or Custom Duty Date of Clearance and Date of remmited to oem",
        "error"
      );
    }
  };

  useEffect(() => {
    const currentTab = sessionStorage.getItem("currentTab");

    if (currentTab === "5" && !hasFetchedRevenue.current) {
      getRevenueData();
      hasFetchedRevenue.current = true;
    }
  }, []);

  // console.log(revenueView)
  const getAssetDetails = async () => {
    // console.log(id);

    try {
      setLoader(true);
      setLoading(true);
      const response = await fetch(`${setBaseUrl}/asset/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
          section: "asset_dashboard",
        },
      });

      const data = await response.json();
      // console.log(data);
      setDeviceId(data?.device_id);

      setTimeLine({
        instance_time: data?.tele_data?.instance_data,
        working_time: data?.tele_data?.working_time,
      });

      setEpocToHumanDate((prev) => {
        let humanDateFormat = new Date(0);
        humanDateFormat.setUTCSeconds(data?.created_at);
        return humanDateFormat;
      });
      if (data.commercial_detail !== undefined) {
        let lenOfCommDetail = Object.keys(data.commercial_detail).length;
        // if commercial details exist in data then we set commercial details state and set commercialDetailsExist to true
        if (lenOfCommDetail > 0) {
          setcommercialDetails(data.commercial_detail);
          setcommercialDetailsExist(true);
        }
      }
      // console.log(data?.photo_data[0]?.image_uri);
      setAssetBasicDetails(data);
      setImageUrl(data?.photo_data);
      setDocumentUrl(data?.attachment_data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  // console.log(commercialDetails[0].total_landed_cost);

  const handleCurrentTab = (tab) => {
  
    sessionStorage.setItem("currentTab", tab);
    setInnerTab(tab);
  };

  // useEffect(() => {
  //   const getCurrentTabName = sessionStorage.getItem("currentTab");

  //   // Only set `innerTab` when necessary
  //   if (tab === "true") {
  //     setInnerTab(1);
  //   }  else {
  //     const tabMapping = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  //     const currentTabIndex = tabMapping.includes(Number(getCurrentTabName))
  //       ? Number(getCurrentTabName)
  //       : 1;
  //     setInnerTab(currentTabIndex);
  //   }

  //   if (!hasFetchedDashboard.current) {
  //     getAssetDetails();
  //     hasFetchedDashboard.current = true; // Ensures it runs only once
  //   }


  // }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    const getCurrentTabName = sessionStorage.getItem("currentTab");
  
    // Only set `innerTab` when necessary
    if (tab === "true") {
      setInnerTab(1);
    } else {
      const tabMapping = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      let currentTabIndex = tabMapping.includes(Number(getCurrentTabName))
        ? Number(getCurrentTabName)
        : 1;
      
      // Check if current tab is 8 and device_no doesn't exist
      if (currentTabIndex === 8 && !assetDetails?.device_no) {
        currentTabIndex = 1;
        sessionStorage.setItem("currentTab", "1"); // Update session storage as well
      }
      
      setInnerTab(currentTabIndex);
    }
  
    if (!hasFetchedDashboard.current) {
      getAssetDetails();
      hasFetchedDashboard.current = true; // Ensures it runs only once
    }
  
  }, [assetDetails?.device_no]); // Add dependency to re-run when device_no changes

  useEffect(() => {
    if (!hasFetchedDashboard.current) {
      getAssetDetails();
      hasFetchedDashboard.current = true;
    }
  }, []);

  const [showMachine, setShowMachine] = useState(false);
  const [bottomTab, setBottomTab] = useState(1); // Default tab
  const { bottom } = useParams();

  const handleBottomTab = (tab) => {
    setBottomTab(tab);
    sessionStorage.setItem("bottomTab", tab);
  };

  // useEffect to initialize the tab state based on session storage or URL params
  useEffect(() => {
    let storedTab = sessionStorage.getItem("bottomTab");

    if (bottom === "true") {
      setBottomTab(1); // Override with tab 1 if bottom is true
    } else if (storedTab) {
      setBottomTab(Number(storedTab)); // Set tab based on session storage
    }
  }, [bottom]);

  // console.log(imageUrl)

  let imageArray = [];
  if (imageUrl) {
    for (let i = 0; i < imageUrl.length; i++) {
      imageArray.push(imageUrl[i]);
    }
  }

  const [photoDataTab, setPhotoDataTab] = useState(false);

  useEffect(() => {
    if (photoDataTab) {
      // Smooth scroll to the bottom tab with an ID of 'photos'
      const photosElement = document.getElementById("photos");
      if (photosElement) {
        photosElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (showMachine) {
      const timeElement = document.getElementById("timeline");
      if (timeElement) {
        timeElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [photoDataTab, showMachine]);

  const [logData, setLogData] = useState([]);
  const [loadingRev, setLoadingRev] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const getUserLogData = async () => {
    setLoadingRev(true);
    try {
      const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": id,
        },
      });
      const data = await res.json();
      if (res.ok) {
        setLoadingRev(false);
        setLogData(data);
      }
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bottomTab === 3) {
      getUserLogData();
    }
  }, [bottomTab]);

  // BreadCrumb Functions
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const menuItems = [
    {
      id: 1,
      title: "General Information",
      icon: "📊",
      visible: true,
    },
    {
      id: 2,
      title: "Commercial Details",
      icon: "💰",
      visible: perms.indexOf("ADMIN.ALL") > -1,
    },
    {
      id: 3,
      title: "Maintenance",
      icon: "🔧",
      visible: true,
    },
    {
      id: 4,
      title: "Lease",
      icon: "📑",
      visible: true,
    },
    {
      id: 5,
      title: "Revenue",
      icon: "💵",
      visible: perms.indexOf("ADMIN.ALL") > -1 && commercialDetails?.length > 0,
    },
    {
      id: 6,
      title: "Photos & Documents",
      icon: "📷",
      visible: true,
    },
    {
      id: 7,
      title: "Logs",
      icon: "📝",
      visible: true,
    },
    {
      id: 8,
      title: "Timeline",
      icon: "⏳",
      visible: deviceId !== null ,
    },
  ];

  return (
    <>
      {/* Header Breadcrumb */}
      <Breadcrumb
        title1="All Asset"
        label1="assets"
        title2="Asset"
        number={assetDetails.asset_no}
      />

      {/* Asset Header */}
      <AssetHeader
        assetDetails={assetDetails}
        loading={loading}
        imageUrl={imageUrl}
        NoImage={NoImage}
        handleBottomTab={handleBottomTab}
        setPhotoDataTab={setPhotoDataTab}
        id={id}
        perms={perms}
      />

      <div className="flex justify-between gap-4  mt-4">
        {/* Sidebar */}
        <div
          className={`flex flex-col h-full bg-white dark:bg-gray-800 rounded-[8px] shadow-md border transition-all duration-300 ${
            collapsed ? "w-14" : "w-64"
          }`}
        >
          {/* Sidebar Header */}
          {/* <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
            {!collapsed && (
              <h2 className="text-base font-semibold text-gray-800 dark:text-white">
                Asset Details
              </h2>
            )}
            <button
              onClick={() => setCollapsed(!collapsed)}
              className="p-1 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              {collapsed ? "➡️" : "⬅️"}
            </button>
          </div> */}

          {/* Menu Items */}
          <nav className="flex-1 overflow-y-auto p-2">
            <ul className="space-y-2">
              {menuItems
                .filter((item) => item.visible)
                .map((item) => (
                  <li key={item.id}>
                    <button
                      onClick={() => {
                        handleCurrentTab(item.id);
                        if (item.id === 5) getRevenueData();
                      }}
                      className={`flex items-center w-full p-3 rounded-lg transition-colors duration-200
                  ${
                    innerTab === item.id
                      ? "bg-green-50 text-green-600 dark:bg-green-900/30 dark:text-green-400"
                      : "hover:bg-gray-100 text-gray-700 dark:hover:bg-gray-700 dark:text-gray-300"
                  }`}
                      title={item.title}
                    >
                      <span className="text-sm mr-2">{item.icon}</span>
                      {!collapsed && (
                        <span className="text-xs font-medium">
                          {item.title}
                        </span>
                      )}
                    </button>
                  </li>
                ))}
            </ul>
          </nav>

          {/* Collapsed indicator */}
          {collapsed && (
            <div className="p-2 text-center text-xs text-gray-500 dark:text-gray-400">
              ▼
            </div>
          )}
        </div>

        {/* details */}
        {loader ? (
          <Loader />
        ) : (
          <div className=" w-full">
            {/* tab 1 */}
            {innerTab === 1 && (
              <>
                <>
                  {/* Asset Details Data */}

                  {/* Photos Data */}
                  <div className="flex flex-col md:flex-row justify-start  gap-4 ">
                    
                      <DeviceEvents devId={deviceId} />

                      {deviceId && (
                        <>
                          <Link
                            to="timeline"
                            smooth
                            duration={500}
                            className=""
                          >
                            <Timeline
                              devId={deviceId}
                              setShowMachine={setShowMachine}
                              handleBottomTab={handleCurrentTab}
                              timeLine={timeLine}
                            />
                          </Link>
                        </>
                      )}

                  </div>
                </>
              </>
            )}

            {/* tab 2 */}
            {perms.indexOf("ADMIN.ALL") > -1 ? (
              <>
                {innerTab === 2 && (
                  <>
                    <div
                      id="assetD-dashboard"
                      class="assetD-tabs flex flex-col justify-between space-y-2 mt-4 mb-16 "
                    >
                      {/*Left column  */}

                      <AssetCommercial
                        commercialDetails={commercialDetails[0]}
                        CustomDutyDateOfClearance={
                          assetDetails.custom_duty_date_of_clearance
                        }
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            {/*Maintenance */}
            {innerTab === 3 && (
              <div id="assetD-maintenance" className="assetD-tabs mt-4">
                <div className="text-right dark:text-white">
                  <NewAssetBtn tabName="maintenance" />
                </div>
                <MaintenanceTab asset_id={id} />
              </div>
            )}

            {/* Lease */}
            {innerTab === 4 && (
              <div id="assetD-maintenance" className="text-black assetD-tabs">
                <div className="text-right">
                  <NewAssetBtn tabName="lease" />
                </div>
                <LeaseTab asset_id={id} />
              </div>
            )}

            {/* revenew */}
            {commercialDetails !== null &&
              perms.includes("ADMIN.ALL") &&
              innerTab === 5 && (
                <>
                  {!odooCost ? (
                    <div className="text-center text-red-500 text-xs flex flex-col">
                      Revenue Details not Available
                      <button
                        className="text-xs p-1 text-white bg-green-2 buttons"
                        onClick={() => handleCurrentTab(1)}
                      >
                        Dashboard
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-6">
                      <AssetDashboards commercialDetails={odooCost} />
                    </div>
                  )}

                  {loadingRev ? (
                    <Loading />
                  ) : revenueView ? (
                    <RemittenceTable
                      revenueView={revenueView}
                      asset_sold_date={assetDetails.asset_sold_date}
                    />
                  ) : (
                    <div className="text-center flex flex-col text-red-500 text-xs">
                      Interest Depreciation Details Not Available
                      {!odooCost && (
                        <button
                          className="text-xs p-1 text-white bg-green-2 buttons"
                          onClick={() => handleCurrentTab(1)}
                        >
                          Dashboard
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}

            {/* photos */}

            {innerTab === 6 && photoDataTab && (
              <>
                <div id="photos">
                  <PhotoDataTab
                    imageArray={imageArray}
                    assetName={assetDetails?.asset_no}
                  />
                  <Documents documentUrl={documentUrl} />
                </div>
              </>
            )}

            {innerTab === 7 && (
              <>
                <UserLogData id={assetDetails.id} logData={logData} />
              </>
            )}

            {innerTab === 8 && deviceId  && (
              <>
                <div id="timeline">
                  <TimeLineDuration
                    devId={deviceId}
                    setShowMachine={setShowMachine}
                    timeLine={timeLine}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AssetDetails;
// <>
//   <div className=" px-2">
//     <div className="">
// <AssetHeader
//   assetDetails={assetDetails}
//   loading={loading}
//   imageUrl={imageUrl}
//   NoImage={NoImage}
//   handleBottomTab={handleBottomTab}
//   setPhotoDataTab={setPhotoDataTab}
//   id={id}
//   perms={perms}
// />

//       {/* Button Header */}
//   <div className="flex justify-between gap-6 mt-2  ">
//     <div className="text-[10px] items-center flex justify-start gap-3  w-full">
//       <span
//         onClick={() => {
//           handleCurrentTab(1);
//         }}
//         title="Dashboard"
//         className={`asset-details-tab py-2 sm:mr-8 cursor-pointer   hover:scale-105  transition-transform duration-300  dark:text-white
//           ${innerTab === 1 ? "assetDetailsActive " : ""}`}
//       >
//         Dashboard
//       </span>

//       {perms.indexOf("ADMIN.ALL") > -1 ? (
//         <>
//           <span
//             title="Commercial Details"
//             onClick={() => handleCurrentTab(2)}
//             className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white
//               ${innerTab === 2 ? "assetDetailsActive " : ""}`}
//           >
//             Commercial Details
//           </span>
//         </>
//       ) : (
//         ""
//       )}

//       <span
//         title="Maintenance Details of the asset"
//         onClick={() => handleCurrentTab(3)}
//         className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
//           innerTab === 3 ? "assetDetailsActive " : ""
//         }`}
//       >
//         Maintenance
//       </span>

//       <span
//         title="Leases of the Asset"
//         onClick={() => handleCurrentTab(4)}
//         className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
//           innerTab === 4 ? "assetDetailsActive " : ""
//         }`}
//       >
//         Lease
//       </span>

//       {perms.indexOf("ADMIN.ALL") > -1 ? (
//         <>
//           {commercialDetails.length > 0 &&
//           commercialDetails !== null ? (
//             <>
//               <span
//                 title="Revenue"
//                 onClick={() => {
//                   handleCurrentTab(5);
//                   // setRevenueView("revenue");
//                   getRevenueData();
//                 }}
//                 className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
//                   innerTab === 5 ? "assetDetailsActive " : ""
//                 }`}
//               >
//                 Revenue
//               </span>
//             </>
//           ) : (
//             ""
//           )}
//         </>
//       ) : (
//         ""
//       )}
//     </div>

//   </div>
// </div>
//     {/* details page  */}

//   {loader ? (
//     <Loader />
//   ) : (
//     <div className="mt-5">
//       {/* tab 1 */}
//       {innerTab === 1 && (
//         <>
//           <>
//             {/* Asset Details Data */}

//             {/* Photos Data */}
//             <div className="flex justify-between gap-1 flex-row max-sm:flex-col">
//               <CommercialDetails
//                 assetDetails={assetDetails}
//                 imageUrl={imageUrl}
//                 NoImage={NoImage}
//                 handleBottomTab={handleBottomTab}
//                 setPhotoDataTab={setPhotoDataTab}
//               />

//               <div className="flex flex-col">
//                 <DeviceEvents devId={deviceId} />

//                 {deviceId && (
//                   <>
//                     <Link
//                       to="timeline"
//                       smooth
//                       duration={500}
//                       className=""
//                     >
//                       <Timeline
//                         devId={deviceId}
//                         setShowMachine={setShowMachine}
//                         handleBottomTab={handleBottomTab}
//                         timeLine={timeLine}
//                       />
//                     </Link>
//                   </>
//                 )}
//               </div>
//             </div>
//             <hr className="w-full h-1 text-dark-6" />
//             {/* Bottom Tabs */}
//             <div className="flex items-center gap-4 text-xs pl-2 mt-[15px] pb-20 text-dark-6 ">
//               <Link to="photos">
//                 <button
//                   className={` px-0 py-1 ${
//                     bottomTab === 1 ? "border-b border-dark-6 " : ""
//                   }`}
//                   onClick={() => {
//                     handleBottomTab(1);
//                     setPhotoDataTab(true);
//                   }}
//                 >
//                   Photos & Videos
//                 </button>
//               </Link>
//               <button
//                 className={` px-0 py-1 ${
//                   bottomTab === 2 ? "border-b border-dark-6 " : ""
//                 }`}
//                 onClick={() => handleBottomTab(2)}
//               >
//                 Documents
//               </button>
//               <button
//                 className={` px-0 py-1 ${
//                   bottomTab === 4 ? "border-b border-dark-6 " : ""
//                 }`}
//                 onClick={() => {
//                   handleBottomTab(4);
//                   setShowMachine(true);
//                 }}
//               >
//                 Working Time
//               </button>

//               <button
//                 className={` px-0 py-1 ${
//                   bottomTab === 3 ? "border-b border-dark-6 " : ""
//                 }`}
//                 onClick={() => {
//                   handleBottomTab(3);
//                   getUserLogData();
//                 }}
//               >
//                 Log Data
//               </button>
//             </div>

//             {bottomTab === 1 && photoDataTab && (
//               <>
//                 <div id="photos">
//                   <PhotoDataTab
//                     imageArray={imageArray}
//                     assetName={assetDetails?.asset_no}
//                   />
//                 </div>
//               </>
//             )}

//             {bottomTab === 2 && (
//               <>
//                 <Documents documentUrl={documentUrl} />
//               </>
//             )}

//             {bottomTab === 3 && (
//               <>
//                 <UserLogData id={assetDetails.id} logData={logData} />
//               </>
//             )}

//             {bottomTab === 4 && deviceId && showMachine && (
//               <>
//                 <div id="timeline">
//                   <TimeLineDuration
//                     devId={deviceId}
//                     setShowMachine={setShowMachine}
//                     timeLine={timeLine}
//                   />
//                 </div>
//               </>
//             )}
//           </>
//         </>
//       )}

//       {/* tab 2 */}
//       {perms.indexOf("ADMIN.ALL") > -1 ? (
//         <>
//           {innerTab === 2 && (
//             <>
//               <div
//                 id="assetD-dashboard"
//                 class="assetD-tabs flex flex-col justify-between space-y-2 mt-4 mb-16 "
//               >
//                 {/*Left column  */}
//                 {/* {console.log(commercialDetails[0])} */}
//                 <AssetCommercial
//                   commercialDetails={commercialDetails[0]}
//                   // epocToHumanDate={epocToHumanDate}
//                   CustomDutyDateOfClearance={
//                     assetDetails.custom_duty_date_of_clearance
//                   }
//                 />
//               </div>
//             </>
//           )}
//         </>
//       ) : (
//         ""
//       )}

//       {/* tab 3 */}
//       {innerTab === 3 && (
//         <div id="assetD-maintenance" className="assetD-tabs mt-4">
//           <div className="text-right dark:text-white">
//             <NewAssetBtn tabName="maintenance" />
//           </div>
//           <MaintenanceTab asset_id={id} />
//         </div>
//       )}

//       {/* tab 4 */}
//       {innerTab === 4 && (
//         <div id="assetD-maintenance" className="text-black assetD-tabs">
//           <div className="text-right">
//             <NewAssetBtn tabName="lease" />
//           </div>
//           <LeaseTab asset_id={id} />
//         </div>
//       )}

//       {commercialDetails !== null &&
//         perms.includes("ADMIN.ALL") &&
//         innerTab === 5 && (
//           <>
//             {!odooCost ? (
//               <div className="text-center text-red-500 text-xs flex flex-col">
//                 Revenue Details not Available
//                 <button
//                   className="text-xs p-1 text-white bg-green-2 buttons"
//                   onClick={() => handleCurrentTab(1)}
//                 >
//                   Dashboard
//                 </button>
//               </div>
//             ) : (
//               <div className="flex flex-col gap-6">
//                 <AssetDashboards commercialDetails={odooCost} />
//               </div>
//             )}

//             {loadingRev ? (
//               <Loading />
//             ) : revenueView ? (
//               <RemittenceTable
//                 revenueView={revenueView}
//                 asset_sold_date={assetDetails.asset_sold_date}
//               />
//             ) : (
//               <div className="text-center flex flex-col text-red-500 text-xs">
//                 Interest Depreciation Details Not Available
//                 {!odooCost && (
//                   <button
//                     className="text-xs p-1 text-white bg-green-2 buttons"
//                     onClick={() => handleCurrentTab(1)}
//                   >
//                     Dashboard
//                   </button>
//                 )}
//               </div>
//             )}
//           </>
//         )}
//     </div>
//   )}
// </div>
// </>
