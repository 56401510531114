import React, { useEffect, useState } from "react";
import Select from "react-select";

import { setBaseUrl } from "../../../../config";
import { currency } from "../../../../customFunctions/FormatDate";

const MachineForm = ({
  name,
  type,
  label,
  assetArray,
  machineData,
  setMachineData,
  machineIndex,
  operatorArray,
  keyNameType,
  error,

  setError,
  validateData,
  OvertimeHourlyRent,
  normalRent,
  overtimeRate,
  // formErrors,
  formErrorMachine,
  setFormErrorMachine,

  // setFormErrors,
}) => {
  const handleChange = (e) => {
    e.preventDefault();

    const value = e.target.value;


    if (keyNameType === "rental_start_date") {
      machineData[machineIndex]["rental_start_date"] = value;
    } else if (keyNameType === "rental_end_date") {
      const startDate = new Date(
        machineData[machineIndex]["rental_start_date"]
      );
      const endDate = new Date(value);

      if (startDate && endDate <= startDate) {
        setError([
          "Rental end date must be greater than the rental start date!",
          machineIndex,
        ]);
        machineData[machineIndex]["rental_end_date"] = ""; // Clear rental end date after alert
        setMachineData([...machineData]); // Ensure state is updated after clearing
        return;
      } else {
        setError([false, machineIndex]);
      }
      machineData[machineIndex]["rental_end_date"] = value;
    } else {
      machineData[machineIndex][`${keyNameType}`] = value;
    }

    if (keyNameType === "overtime_rate") {
      const rate = value;
      let overtimeData = OvertimeHourlyRent(normalRent, rate, machineIndex);
      machineData[machineIndex][`${keyNameType}`] = overtimeData;
    }
    if (keyNameType !== "normal_rate") {
      const normal = normalRent;
      machineData[machineIndex][`normal_rate`] = normal;
    }

    setMachineData([...machineData]); // Update state with modified data
  };

  const [hover, setHover] = useState(false);
  const [focused, setFocused] = useState(false); // New state for focus

  // const [values, setValues] = useState(0);

  // Time Duration
  const [duration, setDuration] = useState("");
  const handleTimeChange = (e) => {
    const time = e.target.value;
    const [hours, minutes] = time.split(":");
    const totalMinutes = parseInt(hours);
    setDuration(totalMinutes);
  };



  return (
    <div className="flex justify-between items-center gap-5 space-y-2 ">
      <span title={name} className="text-[10px] flex-1">
        {name}
        {name === "Select Number Of Operators" ||
          name === "Asset No" ||
          name === "Rental Start Date" ||
          name === "Rental End Date" ? (
          <span className="text-red-1 font-bold">*</span>
        ) : (
          ""
        )}
      </span>

      {keyNameType === "monthly_rental_amount" ?
        <div className="flex flex-col gap-2">

          <input
            title={name}
            type={type}
            maxLength={25}
            placeholder={
              name?.includes("Amount") ? "₹ 0.00/-" : name || "Enter value"
            }
            className={` ${formErrorMachine[`${keyNameType}`] ? 'focus:ring-red-1 border-red-1' : 'focus:ring-green-2 border-green-2'} w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-1  bg-light-4 rounded border h-8 shadow-none
    `}

            min={type === "number" ? 0 : ""}
            onChange={(e) => {
              setFormErrorMachine({
                ...formErrorMachine,
                monthly_rental_amount: false,
              })
              handleChange(e)
            }} // Call the fixed handleInputChange function
            onFocus={() => {
              setHover(true); // Set hover to true on focus
              setFocused(true); // Set focused to true on focus
            }}
            onBlur={() => {
              setHover(false); // Set hover to false on blur
              setFocused(false); // Set focused to false on blur
            }}
          />
          {formErrorMachine && formErrorMachine[`${keyNameType}`] === true && <span className="text-red-1 text-xs">Please enter {name}</span>}
        </div>

        : keyNameType === "rental_start_date" ?
          // <div>
          //   <input
          //     title={name}
          //     type="date"
          //     // min={machineData[machineIndex]?.rental_start_date || ""}
          //     placeholder={name}
          //     className={` ${formErrorMachine[`${keyNameType}`] === true ? 'focus:ring-red-1 border-red-1' : 'focus:ring-green-2 border-green-2'} w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-1  bg-light-4 rounded border h-8 shadow-none
          //     `}

          //     onChange={(e) => {
          //       setFormErrorMachine({
          //         ...formErrorMachine, 
          //         rental_start_date: false
          //       })
          //       handleChange(e)
          //     }} // Call the fixed handleInputChange function
          //     onFocus={() => {
          //       setHover(true); // Set hover to true on focus
          //       setFocused(true); // Set focused to true on focus
          //     }}
          //     onBlur={() => {
          //       setHover(false); // Set hover to false on blur
          //       setFocused(false); // Set focused to false on blur
          //     }}
          //   />

          //   {formErrorMachine[`${keyNameType}`] === true && <span className="text-red-1 text-xs">Please enter {name}</span>}
          // </div> 
          <div>
            <input
              title={name}
              type="date"
              placeholder={name}
              className={`w-[350px] text-[10px] focus:border-0  bg-light-4 rounded border h-8 shadow-none
      ${formErrorMachine?.[keyNameType] ? 'focus:ring-red-1 border-red-1' : 'focus:ring-green-2 border-green-2'}
    `}

              onChange={(e) => {
                setFormErrorMachine((prev) => ({
                  ...prev,
                  [keyNameType]: false, // Reset error for specific key
                }));
                handleChange(e);
              }}

              onFocus={() => {
                setHover(true);
                setFocused(true);
              }}

              onBlur={() => {
                setHover(false);
                setFocused(false);
              }}
            />

            {formErrorMachine?.[keyNameType] && (
              <span className="text-red-1 text-xs">Please enter {name}</span>
            )}
          </div>

          : name === "Asset No" ? (
            <div title={name} className="flex flex-col gap-2">
              <Select
                placeholder="Select Asset" // Placeholder for the select field
                options={
                  Array.isArray(assetArray)
                    ? assetArray.map((assets) => ({
                      value: assets.id,
                      label: assets.asset_no,
                    }))
                    : []
                }
                onChange={(selectedOption) => {
                  // Update machineData for the selected machine based on its index
                  machineData[machineIndex].asset = selectedOption.value;
                  setFormErrorMachine({ ...formErrorMachine, [keyNameType]: true })
                  setMachineData([...machineData]); // Ensure state update with a new array reference
                }}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "350px",
                    fontSize: 10,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    width: "350px",
                    // height: "20px",
                    border:
                      formErrorMachine && formErrorMachine[`${keyNameType}`] ? "1px solid #FF0606" : state.isFocused || state.isHovered
                        ? "none"
                        : "1px solid transparent  #DB4444", // Keep border transparent when not focused or hovered
                    borderBottom: "1px solid", // Apply bottom border
                    boxShadow: state.isFocused
                      ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                      : null, // Optional: Add shadow on focus
                    outline: "none", // Remove default outline
                    borderRadius: 0,
                    backgroundColor: "#EEE",
                  }),
                  // Optionally style the menu and other components as needed
                }}
              />


              {formErrorMachine && formErrorMachine[`${keyNameType}`] === true && <span className="text-red-1 text-xs">Please enter {name}</span>}
            </div>
          ) : name === "Operator Name" ? (
            <>
              <span title={name} className="">
                <Select
                  isMulti
                  // onChange={(value) => {

                  //   const formattedData = value.map((operator) => ({id: operator.id}))

                  //   const updatedMachineData = [...machineData]
                  //   updatedMachineData[machineIndex] ={
                  //     ...updatedMachineData[machineIndex],
                  //     select_operators: formattedData
                  //   }

                  //   // machineData[machineIndex].select_operators = value;
                  //   setMachineData(machineData);
                  // }}
                  onChange={(value) => {
                    // console.log(value);

                    // Transform the selected values into the required format
                    const formattedOperators = value.map((operator) => ({
                      id: operator.value,
                    }));

                    // Create a new array with updated machine data
                    const updatedMachineData = [...machineData];
                    updatedMachineData[machineIndex] = {
                      ...updatedMachineData[machineIndex],
                      select_operators: formattedOperators,
                    };

                    setMachineData(updatedMachineData);
                  }}
                  name="colors"
                  options={operatorArray}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isOptionDisabled={() => {
                    return (
                      machineData[machineIndex].select_operators.length >=
                      machineData[machineIndex][`select_number_of_operators`]
                    );
                  }}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "350px",
                      fontSize: 10,
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      width: "350px",
                      height: "20px",
                      border:
                        state.isFocused || state.isHovered
                          ? "none"
                          : "1px solid transparent", // Keep border transparent when not focused or hovered
                      borderBottom: "1px solid #D1D5DB", // Apply bottom border
                      boxShadow: state.isFocused
                        ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                        : null, // Optional: Add shadow on focus
                      outline: "none", // Remove default outline
                      borderRadius: 0,
                      backgroundColor: "#EEE",
                    }),
                    // Optionally style the menu and other components as needed
                  }}
                />
              </span>
            </>
          ) : //  keyNameType === "normal_rate" ||
            // keyNameType === "overtime_rate" ||
            keyNameType === "normal_amount" ||
              keyNameType === "overtime_amount" ||
              keyNameType === "total_claimable_amount" ? (
              <>
                {" "}
                <input
                  title={name}
                  type={type}
                  maxLength={25}
                  placeholder={name}
                  className={` w-[350px] text-[10px] text-light-5 font-extrabold focus:border-0 focus:outline-none focus:ring-1 focus:ring-red-1 bg-light-4 rounded border-none h-8 shadow-none
            `}
                  value={validateData ? currency(validateData) : 0}
                  disabled={true}
                />
              </>
            ) : keyNameType === "rental_end_date" ? (
              <div>
                <input
                  title={name}
                  type="date"
                  min={machineData[machineIndex]?.rental_start_date || ""}
                  placeholder={name}
                  className={`${error ? "border border-red-1" : ""
                    }  w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-1  bg-light-4 rounded border-none h-8  shadow-none `}
                  onChange={(e) => {

                    handleChange(e)
                  }} // Call the fixed handleInputChange function
                  onFocus={() => {
                    setHover(true); // Set hover to true on focus
                    setFocused(true); // Set focused to true on focus
                  }}
                  onBlur={() => {
                    setHover(false); // Set hover to false on blur
                    setFocused(false); // Set focused to false on blur
                  }}
                />


              </div>
            ) : keyNameType === "normal_rate" ? (
              <input
                maxLength={25}
                title={name}
                type={type}
                placeholder={name}
                className={`w-[350px] text-[10px] text-light-5 font-extrabold focus:border-0 focus:outline-none focus:ring-1 focus:ring-red-1 bg-light-4 rounded border-none h-8 shadow-none
      `}
                value={validateData ? currency(validateData) : 0}
                onChange={handleChange}
                disabled={true}
              />
            ) : keyNameType === "overtime_rate" ? (
              <>
                <div className="flex flex-col items-start gap-2 text-xs p-4 w-[350px] object-cover overflow-hidden bg-light-4 rounded-md ">
                  {/* Header Section */}
                  <div className="text-xs font-medium flex justify-center w-fit">
                    <span>Normal Hourly Rent: </span>
                    <span className="">
                      {"  "} ₹{" "}
                      {normalRent ? currency(parseFloat(normalRent).toFixed(2)) : 0}
                    </span>
                  </div>

                  {/* Input and Calculation Section */}
                  <div
                    title="Overtime rate"
                    className="flex text-xs items-center justify-between w-fit gap-2"
                  >
                    {/* Normal Rent Display */}
                    <span className="text-xs font-semibold text-gray-800">
                      {" "}
                      ₹{normalRent ? currency(parseFloat(normalRent).toFixed(2)) : 0}
                    </span>
                    {/* Multiplication Symbol */}
                    <span className="text-xs font-bold text-gray-600">×</span>
                    {/* Overtime Rate Input */}
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength={3}
                      placeholder="Enter Rate"
                      className="decoration-0 w-[65px] text-gray-700 font-bold focus:ring-2 focus:ring-red-400 focus:outline-none rounded-lg border border-gray-300 h-8 px-2 shadow-sm text-xs"
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (value.length > 3) {
                          value = value.slice(0, 3); // Limit input to two characters
                        }

                        if (value !== "" && parseInt(value, 10) > 300) {
                          value = "300"; // Restrict input to max 31
                        }

                        e.target.value = value; // Ensure only valid values go to handleChange
                        handleChange(e);
                      }}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Please enter a value greater than or equal to 0"
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      onKeyDown={(e) => {
                        if (e.key === "." || e.key === "e") {
                          e.preventDefault(); // Prevent typing "." or "e"
                        }
                      }}
                    />{" "}
                    {"%"}
                    {/* Equals Symbol */}
                    <span className="text-xs font-bold text-gray-600">=</span>
                    {/* Overtime Rent Display */}
                    <span className="text-xs font-bold flex items-center ">
                      ₹{" "}
                      <span className="text-green-800  font-bold ">
                        {currency(machineData[machineIndex].overtime_rate) || 0}/hr{" "}
                      </span>
                    </span>
                  </div>

                  <div className="text-xs w-full text-gray-500 mt-2">
                    <p>
                      Enter the overtime rate to calculate the overtime hourly rent.
                      <span className="font-semibold text-gray-700">
                        {" "}
                        Normal Hourly Rent
                      </span>{" "}
                      is used as the base value.
                    </p>
                  </div>
                </div>
              </>
            ) : keyNameType === "total_working_days" ? (
              <input
                title={name}
                // type={type}
                placeholder={name}
                // min={0}
                // max={31}
                // maxLength={2}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={2}
                className={`w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-1 focus:ring-red-1 bg-light-4 rounded border-none h-8  shadow-none `}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                  if (value.length > 2) {
                    value = value.slice(0, 2); // Limit input to two characters
                  }

                  if (value !== "" && parseInt(value, 10) > 31) {
                    value = "31"; // Restrict input to max 31
                  }

                  e.target.value = value; // Ensure only valid values go to handleChange
                  handleChange(e); // Call your existing handleChange function
                }} // Call the fixed handleInputChange function
                onFocus={() => {
                  setHover(true); // Set hover to true on focus
                  setFocused(true); // Set focused to true on focus
                }}
                onBlur={() => {
                  setHover(false); // Set hover to false on blur
                  setFocused(false); // Set focused to false on blur
                }}
              />
            ) : keyNameType === "nominal_hours_per_day" ? (
              <input
                type={type}
                title={name}
                inputMode="numeric"
                pattern="[0-9:]*" // Allow only numbers and colon
                maxLength={2}
                placeholder={name}
                className={`w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-1 focus:ring-red-1 bg-light-4 rounded border-none h-8  shadow-none `}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                  if (value.length > 2) {
                    value = value.slice(0, 2); // Limit input to two characters
                  }

                  if (value !== "" && parseInt(value, 10) > 24) {
                    value = "24"; // Restrict input to max 31
                  }

                  e.target.value = value; // Ensure only valid values go to handleChange
                  handleChange(e); // Call your existing handleChange function
                }}
                onFocus={() => {
                  setHover(true); // Set hover to true on focus
                  setFocused(true); // Set focused to true on focus
                }}
                onBlur={() => {
                  setHover(false); // Set hover to false on blur
                  setFocused(false); // Set focused to false on blur
                }}
              />
            ) : (
              <div className="flex flex-col gap-2">

                <input
                  title={name}
                  type={type}
                  maxLength={25}
                  placeholder={
                    name?.includes("Amount") ? "₹ 0.00/-" : name || "Enter value"
                  }
                  className={` w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-1 focus:ring-red-1 bg-light-4 rounded border-none h-8 shadow-none
            `}
                  max={name === "Nominal Hrs/day" ? 24 : ""}
                  min={type === "number" ? 0 : ""}
                  onChange={handleChange} // Call the fixed handleInputChange function
                  onFocus={() => {
                    setHover(true); // Set hover to true on focus
                    setFocused(true); // Set focused to true on focus
                  }}
                  onBlur={() => {
                    setHover(false); // Set hover to false on blur
                    setFocused(false); // Set focused to false on blur
                  }}
                />

              </div>
            )}


    </div>
  );
};

export default MachineForm;
