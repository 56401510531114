import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import Loader from "../Loader";
import { LoaderContext } from "../../Context/LoaderContext";
import { PermissionContext } from "../../Context/PermissionsContext";
import { useNavigate, useParams } from "react-router-dom";
import ifscIco from "../../components/OperatorDetails/assets/ifscIco.svg";
import odooIco from "../../components/OperatorDetails/assets/adhaarIcon.svg";
import adhaarIco from "../../components/OperatorDetails/assets/adhaarIcon2.svg";
import joiningIco from "../../components/OperatorDetails/assets/joiningIcon.svg";
import moneyIco from "../../components/OperatorDetails/assets/moneyIco.svg";
import phoneIco from "../../components/OperatorDetails/assets/phoneIcon.svg";
import profileIco from "../../components/OperatorDetails/assets/profileIco.svg";
import bankAcIco from "../../components/OperatorDetails/assets/bankAcIco.svg";
import pfAcIco from "../../components/OperatorDetails/assets/pfIco.svg";
import CardForDetails from "./CardForDetails";
// import {
//   capitalizeFirstLetter,
//   currency,
//   formatAadhaar,
//   formatDate,
//   formatDateTime,
// } from "../Modals/AssetDetails/Tabs/FormatDate";
import * as XLSX from "xlsx";
import moment from "moment";
import MonthlySalary from "./salaries/MonthlySalary";
import Attendance from "./salaries/Attendance";
import SalaryDetails from "./salaries/SalaryDetails";
import { formatDate,capitalizeFirstLetter,currency,formatDateTime,formatAadhaar } from "../../customFunctions/FormatDate";

function OperatorDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [perms, setPerms] = useContext(PermissionContext);
  const [operatorDetail, setOperatorDetail] = useState({});
  const loader = useContext(LoaderContext)[0];
  const setLoader = useContext(LoaderContext)[1];
  const [reload, setReload] = useState(false);
  const [history, setHistory] = useState(false);
  const getOperatorDetail = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/operator/get_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      // console.log(data);
      setOperatorDetail(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getOperatorDetail();
  }, []);

  // console.log(formatDate(operatorDetail?.date_list[0]?.joining_date));

  const [openLogData, setOpenLogData] = useState(false);
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldNameMapping = {
    description: "Description",
    lease_id: "Lease ID",
    maintenance_id: "Maintenance ID",
    operator_id: "Operator ID",
    action_time: "Action Time",
    act_by: "Performed By",
    action_type: "Action Type",
    asset_id: "Asset Identifier",
    site_location: "Site Location",
    purchased_from: "Purchased From",
    make: "Manufacturer Name",
    model: "Model Number",
    category: "Category",
    device_no: "Device No",
    installation_date: "Installation Date",
    date_list: "Dates",
    bank_details: "Bank Details",
    name: "Name",
    net_inhand_salary: "Net Inhand Salary",
    odoo_employee_no: "Odoo Employee No",
    pf_account_no: "PF Account No",
    phone: "Phone",
    aadhar_no: "Aadhaar No",
    asset_no: "Asset Number",
    lease_RSO_no: "RSO Number",
    advance_paid_amount:"Advance paid amount",
    operator_role:"Role",
    operator_sale_person_id: 'Operator Sale Person Id',
    odoo_employee_no:"Employee No",
    
    // Add more mappings as needed
  };

  const getUserLogData = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "operator-id": id,
        },
      });
      const data = await res.json();
      setLogData(data);
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      setLoading(false);
    }
  };

  // const parseFields = (fields) => {
  //   try {
  //     const parsedFields = JSON.parse(fields);
  //     return Object.entries(parsedFields)
  //       .filter(([key]) => key !== "id") // Exclude the `id` field
  //       .map(([key, value]) => ({
  //         fieldName: fieldNameMapping[key] || key,
  //         fieldValue: typeof value === "object" ? JSON.stringify(value) : value, // Safely handle objects and arrays
  //       }));
  //   } catch (error) {
  //     console.error("Error parsing fields:", error);
  //     return [];
  //   }
  // };

  const parseFields = (fields) => {
    try {
      const parsedFields = JSON.parse(fields);

      return Object.entries(parsedFields)
        .filter(([key]) => key !== "id") // Exclude the `id` field
        .map(([key, value]) => {
          let formattedValue;

          // Handle special cases for nested structures
          if (typeof value === "object" && value !== null) {
            if (key.toLowerCase().includes("phone")) {
              formattedValue = `Phone No: ${value.phone_no}, Country Code: ${value.phone_code}`;
            } else if (key.toLowerCase().includes("date")) {
              formattedValue = Object.entries(value)
                .map(
                  ([dateKey, dateValue]) =>
                    `${capitalizeFirstLetter(dateKey)}: ${dateValue}`
                )
                .join(", ");
            } else if (key.toLowerCase().includes("bank")) {
              formattedValue = Object.entries(value)
                .filter(([fieldKey]) => fieldKey !== "id") // Exclude IDs in bank details
                .map(
                  ([bankKey, bankValue]) =>
                    `${capitalizeFirstLetter(bankKey)}: ${bankValue}`
                )
                .join(", ");
            } else {
              formattedValue = JSON.stringify(value); // Default for other objects
            }
          } else {
            formattedValue = value; // Handle primitive values
          }

          return {
            fieldName: fieldNameMapping[key] || capitalizeFirstLetter(key),
            fieldValue: formattedValue,
          };
        });
    } catch (error) {
      console.error("Error parsing fields:", error);
      return [];
    }
  };

  // console.log(operatorDetail.log_in_out);

  const handleLog = () => {
    setOpenLogData(true);
    getUserLogData();
  };

  const handleExportExcel = () => {
    const formattedData = logData.map((data) => {
      const fields = parseFields(data.fields_are || "{}");
      const fieldDetails = fields.map(
        (field) => `${field.fieldName}: ${JSON.stringify(field.fieldValue)}`
      );

      return {
        Time: data.action_time,
        "Act By": capitalizeFirstLetter(data.act_by),
        "Action Type": capitalizeFirstLetter(data.action_type),
        "Fields Changed": fields.map((field) => field.fieldName).join(", "),
        "Updated Data": fieldDetails.join("\n"),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Log Data");
    XLSX.writeFile(workbook, "Operator Logs.xlsx");
  };

  const { bottom } = useParams();
  const [bottomTab, setBottomTab] = useState(() => {
    // Initialize state with the stored value if available, otherwise default to 1
    const storedTab = sessionStorage.getItem("operatorBottomTab");
    return storedTab ? Number(storedTab) : 1;
  });

  const handleBottomTab = (tab) => {
    setBottomTab(tab);
    sessionStorage.setItem("operatorBottomTab", tab);
  };

  useEffect(() => {
    if (bottom === "true") {
      setBottomTab(1); // Override with tab 1 if bottom is true
      sessionStorage.setItem("operatorBottomTab", 1); // Also update storage
    }
  }, [bottom]);

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <div id="operator-details" className=" dark:bg-gray-800 h-full p-6">
          {/* Bottom Tab */}
          <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-900 border-t shadow-lg flex gap-2 justify-end items-center px-6 py-2">
            <button
              onClick={handleLog}
              className="shadow-md text-white bg-green-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition"
            >
              Log Data
            </button>
            <button
              className="shadow-md text-dark-6 bg-white border px-3 text-xs h-7 w-[96px] rounded-[2px]"
              onClick={() => navigate("/operators")}
            >
              Back
            </button>
            {["OPERATOR.ALL", "OPERATOR.CRU", "ADMIN.ALL"].some((perm) =>
              perms.includes(perm)
            ) && (
              <button
                className="shadow-md text-white bg-green-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition"
                onClick={() => navigate(`/operators/update-operator/${id}`)}
              >
                Edit
              </button>
            )}
          </div>

          {/* Updated Operator UI Design */}

          <div className="grid grid-cols-4 gap-2 mt-2">
            {/* Card 1 Personal Details */}
            {operatorDetail?.file_Links?.length > 0 && (
              <>
            <div className="">
                      <img
                        src={`https://${operatorDetail.file_Links[0].file_link}`}
                        alt="Profile"
                        className="h-[200px] w-[200px] border rounded-md mt-2 object-cover"
                      />
                      
            </div>
                    </>
                  )}
            <div className="bg-white p-4 flex flex-col gap-1 border">
              <span className="text-xs font-bold mb-2">Personal Details</span>
              <table className="w-full text-xs">
                <tbody>
                  {[
                    { label: "Name", value: operatorDetail?.name },
                    {
                      label: "Operator Role",
                      value: operatorDetail?.operator_role || "N/A",
                    },
                    {
                      label: "Mobile No",
                      value: operatorDetail?.phone
                        ? `${operatorDetail?.phone?.phone_code}-${operatorDetail?.phone?.phone_no}`
                        : "N/A",
                    },
                    { label: "Aadhaar No", value: operatorDetail?.aadhar_no },
                    {
                      label: "Date of Birth", value: operatorDetail?.dob || 'Not Filled'
                    },
                    {
                      label: "Drive License",
                      value: operatorDetail?.driving_license || "N/A",
                    },
                    {
                      label: "Employee No",
                      value: operatorDetail?.odoo_employee_no,
                    },
                    {
                      label: "Operator Sales Person",
                      value: operatorDetail?.sale_person || "N/A",
                    },
                   
                  ].map(({ label, value }, idx) => (
                    <tr key={idx} className="border-b">
                      <td
                        title={label}
                        className="py-2 font-medium text-gray-500"
                      >
                        {label}:
                      </td>
                      <td title={label} className="py-2">
                        {value || "--"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Card 2 Financial Details */}
            <div className="bg-white p-4 flex flex-col gap-1 border">
              <span className="text-xs font-bold mb-2">Financial Details</span>
              <table className="w-full text-xs">
                <tbody>
                  {[
                    {
                      label: "PF Account No",
                      value: operatorDetail?.pf_account_no || "--",
                    },
                    {
                      label: "Bank Account No",
                      value: operatorDetail?.bank_details?.account_no || "--",
                    },

                    {
                      label: "IFSC Code",
                      value: operatorDetail?.bank_details?.ifsc_code || "--",
                    },
                    {
                      label: "Net Inhand Salary",
                      value: operatorDetail?.net_inhand_salary
                        ? `₹${currency(operatorDetail?.net_inhand_salary)}/-`
                        : "--",
                    },
                    {
                      label: "Advanced Paid Amount",
                      value:
                      operatorDetail?.advance_paid_amount ?  `₹ ${currency(operatorDetail?.advance_paid_amount)}/-` : '₹ 0.00/-'
                        ,
                    },
                  ].map(({ label, value }, idx) => (
                    <tr key={idx} className="border-b">
                      <td
                        title={label}
                        className="py-2 font-medium text-gray-500"
                      >
                        {label}:
                      </td>
                      <td title={label} className="py-2">
                        {value || "--"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* card 3 Work History */}
            <div className="bg-white p-4 h-[300px] overflow-y-auto flex flex-col gap-2 border dark:bg-gray-900">
              <span className="text-xs font-bold mb-2 text-gray-700 dark:text-gray-300">
                Working History
              </span>
              <table className="w-full text-xs ">
                <thead className="border-b">
                  <tr className="">
                    <th className="py-2 font-medium text-gray-500">Joined</th>
                    <th className="py-2 font-medium text-gray-500">Left</th>
                  </tr>
                </thead>
                <tbody>
                  {operatorDetail?.date_list?.length > 0 ? (
                    operatorDetail?.date_list?.map((date, index) => {
                      return (
                        <tr key={index} className=" text-center">
                          <td className="p-2 border-b ">
                            {date?.joining_date
                              ? formatDate(date.joining_date)
                              : "--"}
                          </td>
                          <td className="p-2 border-b ">
                            {date?.leaving_date
                              ? formatDate(date.leaving_date)
                              : "--"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="items-center flex justify-center">
                      no available data
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Card 4 Assignment History */}

            <div className="bg-white p-4 flex flex-col gap-1 border">
              <span className="text-xs font-bold mb-2">Assignment History</span>
              <table className="w-full text-xs">
                <tbody>
                  {[
                    {
                      label: "Asset No",
                      value: operatorDetail?.asset_no?.join(", ") || "--",
                    },
                    {
                      label: "RSO No",
                      value: operatorDetail?.lease_RSO_no?.join(", ") || "--",
                    },
                    {
                      label: "Operator Availability",
                      value: operatorDetail?.date_list?.[operatorDetail?.date_list.length - 1]?.leaving_date === null ? (
                        <div className="text-xs text-green-1 font-bold">
                          Active
                        </div>
                      ) : (
                        <div className="text-dark-6 font-bold text-xs">
                          Inactive
                        </div>
                      ),
                    }
                    
                  ].map(({ label, value }, idx) => (
                    <tr key={idx} className="border-b">
                      <td
                        title={label}
                        className="py-2 font-medium text-gray-500"
                      >
                        {label}:
                      </td>
                      <td title={label} className="py-2">
                        {value || "--"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Card 5 Wallet Details */}
         
            {operatorDetail?.wallet_arr &&
                operatorDetail.wallet_arr.length > 0 && (
                  <div className="bg-white p-4 flex flex-col gap-1 border">
                    <h2 className="text-xs text-dark-6 font-bold mb-4">
                      Wallet Information
                    </h2>
                    <table className="w-full text-xs text-center">
                      <thead className="borber-b">
                        {/* <tr > */}
                          <th className="py-2 border-b">Item Name</th>
                          <th className="py-2 border-b">Issue Date</th>
                          <th className="py-2 border-b">Quantity</th>
                        {/* </tr> */}
                      </thead>
                      <tbody>
                        {operatorDetail.wallet_arr.map((wallet, index) => (
                          <tr key={index} className="border-b">
                            <td title="item name" className="py-2 font-medium text-gray-500">
                              {wallet.items_name || "--"}
                            </td>
                            <td title="item issued date" className="py-2 font-medium text-gray-500">
                              {moment(wallet.issue_date).format("DD-MM-YYYY") || "--"}
                            </td>
                            <td
                              title="quantity of items added"
                              className="py-2 font-medium text-gray-500"
                            >
                              {wallet.quantity || "--"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}


           
         
          </div>
            {/* Card 6 Salary Details */}
          <div className="bg-white p-4 flex flex-col gap-1 borde ">
              {operatorDetail?.salary_by_month_arr ? (
                <div className="">
                  <h2 className="text-xs font-medium mb-4">
                    {" "}
                    Operator Salary Details
                  </h2>
                  <table className="w-full text-xs border">
                    <thead>
                      <tr className="bg-gray-100 dark:bg-gray-800">
                        <th className="p-2 border">Month-Year</th>
                        <th className="p-2 border">Basic Pay</th>
                        <th className="p-2 border">Gross Salary</th>
                        <th className="p-2 border">Net Payment</th>
                        <th className="p-2 border">Take Home</th>
                        <th className="p-2 border">TDS</th>
                        <th className="p-2 border">Allowances</th>
                        <th className="p-2 border">Overtime</th>
                      </tr>
                    </thead>
                    <tbody>
                      {operatorDetail?.salary_by_month_arr?.map(
                        (monthData, index) => (
                          <tr key={index} className="border-b text-center">
                            <td  className="p-2 border">
                              {monthData.month_year}
                            </td>
                            <td title="basic payment" className="p-2 border">
                              ₹{monthData.basic_pay}
                            </td>
                            <td title="gross salary" className="p-2 border">
                              ₹{monthData.gross}
                            </td>
                            <td
                              title="net payment details"
                              className="p-2 border"
                            >
                              ₹{monthData.net_payment}
                            </td>
                            <td title="Taken to home" className="p-2 border">
                              ₹{monthData.takehome}
                            </td>
                            <td title="monthly tds" className="p-2 border">
                              ₹{monthData.tds}
                            </td>
                            <td title="allowances" className="p-2 border">
                              ₹{monthData.allowances}
                            </td>
                            <td title="overtime amount" className="p-2 border">
                              ₹{monthData.overtime_amount}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <span className="text-red-1 text-xs">
                  No salary information given{" "}
                </span>
              )}

              {/* Wallet Information */}

             
            </div>

    {/* Log Data */}
            <div className="">
            {openLogData && (
              <>
                <div className=" bg-white p-2">
                  <button
                    className="bg-green-2 text-white px-4 py-2 mb-2 rounded hover:bg-green-3 text-xs"
                    onClick={handleExportExcel}
                  >
                    Export to Excel
                  </button>
                  {loading ? (
                    <div className="flex justify-center items-center py-10">
                      <div className="animate-spin rounded-full h-8 w-8 "></div>
                    </div>
                  ) : (
                    <div className="overflow-x-auto text-[10px] h-[300px] overflow-y-auto w-[125vh]">
                      <table className="min-w-full border-collapse divide-y table-auto">
                        <thead className="divide-y sticky top-0 bg-light-4">
                          <tr>
                            <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                              Time
                            </th>
                            <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                              Act By
                            </th>
                            <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                              Action Type
                            </th>
                            <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                              Fields Changed
                            </th>
                            <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                              Updated Data
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {logData.length > 0 ? (
                            logData.map((data, index) => {
                              const fields = parseFields(
                                data.fields_are || "{}"
                              );
                              return (
                                <tr
                                  key={index}
                                  className="hover:bg-gray-100 transition duration-200 ease-in-out"
                                >
                                  <td className="py-3 px-4 border text-dark-6">
                                    {moment(data?.action_time).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td className="py-3 px-4 border text-dark-6">
                                    {capitalizeFirstLetter(data.act_by)}
                                  </td>
                                  <td className="py-3 px-4 border text-dark-6">
                                    {capitalizeFirstLetter(data.action_type)}
                                  </td>
                                  <td className="py-3 px-4 border text-dark-6">
                                    <ul className="list-disc ml-4">
                                      {fields.map((field, i) => (
                                        <li key={i} className="text-gray-700">
                                          {field.fieldName}
                                        </li>
                                      ))}
                                    </ul>
                                  </td>
                                  <td className="py-3 px-4 border text-dark-6">
                                    <ul className="list-disc ml-4">
                                      {fields.map((field, i) => (
                                        <li key={i} className="text-gray-700">
                                          <strong>{field.fieldName}:</strong>{" "}
                                          {field.fieldValue}
                                        </li>
                                      ))}
                                    </ul>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan="5"
                                className="py-4 px-4 text-center text-gray-500"
                              >
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </>
            )}
            </div>


        
          </div>
      

        <div>
          {/* <MonthlySalary operatorId={id} operatorDetail={operatorDetail} /> */}
        </div>
      </>
    );
  }
}

export default OperatorDetails;



//   {/* Old Operator UI Deisgn */}
//   <div className="flex flex-col gap-6 mt-2">
//   {/* Operator's Information */}
//   <div className="bg-white dark:bg-gray-900 p-6 text-dark-6 rounded-[2px] shadow-md lg:col-span-2">
//     <h2 className="text-xs font-bold mb-2">Operator's Information</h2>
//     <table className="w-full text-xs">
//       <tbody>
//         {[
//           { label: "Name", value: operatorDetail?.name },
//           {
//             label: "Mobile No",
//             value: operatorDetail?.phone
//               ? `${operatorDetail?.phone?.phone_code}-${operatorDetail?.phone?.phone_no}`
//               : "N/A",
//           },
//           { label: "Aadhaar No", value: operatorDetail?.aadhar_no },
//           {
//             label: "Odoo Employee No",
//             value: operatorDetail?.odoo_employee_no,
//           },
//           {
//             label: "Operator Sales Person",
//             value: operatorDetail?.operator_sale_person || "N/A",
//           },
//           {
//             label: "Operator Role",
//             value: operatorDetail?.operator_role || "N/A",
//           },
//           {
//             label: "Asset No",
//             value: operatorDetail?.asset_no?.join(", ") || "--",
//           },
//           {
//             label: "RSO No",
//             value: operatorDetail?.lease_RSO_no?.join(", ") || "--",
//           },
//           // {
//           //   label: "Operator Availability",
//           //   value: !operatorDetail?.date_list?.[0]?.leaving_date ||
//           //          new Date(operatorDetail.date_list[0].leaving_date) < new Date() ? (
//           //     <div className="text-xs text-green-1 font-bold">Available</div>
//           //   ) : (
//           //     <div className="text-red-1 font-bold text-xs">Assigned</div>
//           //   )
//           // },
//           {
//             label: "Operator Availability",
//             value: !operatorDetail?.date_list?.[0]?.leaving_date ? (
//               <div className="text-xs text-green-1 font-bold">
//                 Active
//               </div>
//             ) : (
//               <div className="text-dark-6 font-bold text-xs">
//                 Inactive
//               </div>
//             ),
//           },
//           {
//             label: "Joining Date",
//             value: operatorDetail?.date_list?.[0]?.joining_date
//               ? moment(
//                   operatorDetail.date_list[0].joining_date
//                 ).format("DD-MM-YYYY")
//               : "--",
//           },
//           {
//             label: "Leaving Date",
//             value: operatorDetail?.date_list?.[0]?.leaving_date
//               ? moment(
//                   operatorDetail.date_list[0].leaving_date
//                 ).format("DD-MM-YYYY")
//               : "--",
//           },
//         ].map(({ label, value }, idx) => (
//           <tr key={idx} className="border-b">
//             <td
//               title={label}
//               className="py-2 font-medium text-gray-500"
//             >
//               {label}:
//             </td>
//             <td title={label} className="py-2">
//               {value || "--"}
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>

//   {/* Financial Information */}
//   <div className="bg-white text-dark-6 dark:bg-gray-900 p-6 rounded-[2px] shadow-md">
//     <h2 className="text-xx font-bold mb-4">Financial Information</h2>
//     <table className="w-full text-xs">
//       <tbody>
//         {[
//           {
//             label: "Advanced Paid Amount",
//             value:
//               `₹${currency(operatorDetail?.advance_paid_amount)}/-` ||
//               "--",
//           },
//           {
//             label: "PF Account No",
//             value: operatorDetail?.pf_account_no || "--",
//           },
//           {
//             label: "Bank Account No",
//             value: operatorDetail?.bank_details?.account_no || "--",
//           },
//           {
//             label: "IFSC Code",
//             value: operatorDetail?.bank_details?.ifsc_code || "--",
//           },
//           {
//             label: "Net Inhand Salary",
//             value: operatorDetail?.net_inhand_salary
//               ? `₹${currency(operatorDetail?.net_inhand_salary)}/-`
//               : "--",
//           },
//         ].map(({ label, value }, idx) => (
//           <tr key={idx} className="border-b">
//             <td
//               title={label}
//               className="py-2 font-medium text-gray-500"
//             >
//               {label}:
//             </td>
//             <td title={label} className="py-2">
//               {value}
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>

//   {/* Operator Salary Details */}
 

//   {/* <div className="bg-white text-dark-6 dark:bg-gray-900 p-4 rounded-[2px] shadow-md">
//     <h2 className="text-xx font-bold mb-4">Attendance</h2>
//     <table className="w-full text-xs ">
//       <thead className=" text-dark-6  text-left">
//         <tr>
//           <th className="px-4 py-2 border-b border-gray-300 w-[105px]">
//             Date
//           </th>
//           <th className="px-4 py-2 border-b border-gray-300">
//             Time In
//           </th>
//           <th className="px-4 py-2 border-b border-gray-300">
//             Time Out
//           </th>
         
//         </tr>
//       </thead>
//       <tbody className="text-dark-600 h-[100px]  overflow-y-auto">
//         {operatorDetail?.log_in_out?.map((log, idx) => (
//           <tr
//             key={idx}
//             className={`hover:bg-gray-50
//             `}
//           >
//             <td className="px-4 py-2 border-b border-gray-300 w-[105px]">
//               {moment(log.in).format("DD-MM-YYYY")}
//             </td>
//             <td className="px-4 py-2 border-b border-gray-300">
//               {log.in.split(" ")[4].split(":").slice(0, 2).join(":")}
//             </td>
//             <td className="px-4 py-2 border-b border-gray-300">
//               {log.out.split(" ")[4].split(":").slice(0, 2).join(":")}
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div> */}

//   {/* Employment Dates */}
//   {/* <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md lg:col-span-3">
// <h2 className="text-xl font-bold mb-4">Employment Dates</h2>
// {operatorDetail?.date_list?.length > 0 ? (
// <div className="space-y-4">
// {operatorDetail.date_list.map((date, idx) => (
//   <div key={idx} className="border-b pb-2">
//     <p>
//       <strong>Date of Joining:</strong>{" "}
//       {date?.joining_date || "--"}
//     </p>
//     <p>
//       <strong>Date of Leaving:</strong>{" "}
//       {date?.leaving_date || "--"}
//     </p>
//   </div>
// ))}
// </div>
// ) : (
// <p>No employment dates available.</p>
// )}
// </div> */}
//   <div className="text-xs flex gap-3 items-center ">
//     <div className="">
//       {operatorDetail?.date_list?.length > 0 && (
//         <>
//           {/* First Date Display */}
//           <div
//             className="flex flex-col space-y-3 "
//             key={operatorDetail.date_list[0].joining_date}
//           >
//             {/* <div className="flex items-center space-x-2">
//             <span className="dark:text-white text-gray-500">
//               Date of Joining:
//             </span>
//             <span className="font-bold">
//               {operatorDetail.date_list[0]?.joining_date
//                 ? formatDate(operatorDetail.date_list[0].joining_date)
//                 : "--"}
//             </span>
//           </div>
//           <div className="flex items-center space-x-2">
//             <span className="dark:text-white text-gray-500">
//               Date of Leaving:
//             </span>
//             <span>
//               {operatorDetail.date_list[0]?.leaving_date
//                 ? formatDate(operatorDetail.date_list[0].leaving_date)
//                 : "--"}
//             </span>
//           </div> */}

//             {/* Show More Button */}
//             <button
//               title="Show all contract history of the operator"
//               className="shadow-md text-white bg-green-2 px-3 text-xs h-7 w-[96px] rounded-[2px]"
//               onClick={() => setHistory(!history)}
//             >
//               Show More
//             </button>
//           </div>

//           {/* Show More Tab */}
//           {history && (
//             <>
//               <>
//                 {/* Modal Container */}
//                 <div className="absolute left-[30%] text-dark-6 top-[53%] transform -translate-y-1/2 w-[350px] h-[380px] overflow-y-auto bg-white dark:bg-gray-900 border rounded-[2px] shadow-lg p-5 z-50">
//                   {/* Close Button */}
//                   <button
//                     title="close tab"
//                     className="absolute top-4 right-5 text-gray-600 dark:text-gray-300 border border-gray-300 hover:border-gray-500 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 px-3 py-1 w-[96px] rounded-[2px] text-xs shadow transition"
//                     onClick={() => setHistory(false)}
//                   >
//                     Close
//                   </button>

//                   {/* Title */}
//                   <h3
//                     title={`past employement history of ${operatorDetail?.name}`}
//                     className="text-xs font-semibold text-gray-800 dark:text-white mb-4"
//                   >
//                     Employment History
//                   </h3>

//                   {/* Employment History List */}
//                   <div className="space-y-3">
//                     {operatorDetail?.date_list?.slice(1).length >
//                     0 ? (
//                       operatorDetail?.date_list
//                         ?.slice(1)
//                         .map((date, index) => (
//                           <div
//                             key={index}
//                             className="p-3 border rounded-[2px] bg-light-7 dark:bg-gray-800 shadow-sm"
//                           >
//                             <div
//                               title="Date of joining"
//                               className="flex justify-between text-xs"
//                             >
//                               <span className="text-gray-500 dark:text-gray-400">
//                                 Date of Joining:
//                               </span>
//                               <span className="font-medium text-gray-800 dark:text-white">
//                                 {date?.joining_date
//                                   ? formatDate(date.joining_date)
//                                   : "--"}
//                               </span>
//                             </div>
//                             <div
//                               title="Date of leaving"
//                               className="flex justify-between text-xs mt-2"
//                             >
//                               <span className="text-gray-500 dark:text-gray-400">
//                                 Date of Leaving:
//                               </span>
//                               <span className="font-medium text-gray-800 dark:text-white">
//                                 {date?.leaving_date
//                                   ? formatDate(date.leaving_date)
//                                   : "--"}
//                               </span>
//                             </div>
//                           </div>
//                         ))
//                     ) : (
//                       <p className="text-center text-gray-500 dark:text-gray-400">
//                         No additional history available.
//                       </p>
//                     )}
//                   </div>
//                 </div>

//                 {/* Backdrop */}
//                 <div
//                   className="fixed inset-0 bg-[#0000] bg-opacity-30 z-40"
//                   onClick={() => setHistory(false)}
//                 />
//               </>
//             </>
//           )}
//         </>
//       )}{" "}
//       {/* End of First Date Display */}
//     </div>
//   </div>

