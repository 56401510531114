import React, { useEffect, useRef, useState } from "react";
import { Button, Paper, TablePagination } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { formatDate } from "../../../customFunctions/FormatDate";
import { FaFileExcel } from 'react-icons/fa';

const ButtonData = ({ handleExport, selectedRows, filteredData }) => {
  return (
    <>

      <span title="Export Data in Excel format" placement="top">
        {filteredData.length > 0 ? (
          <button
            className="flex items-center buttons border border-green-2 gap-1  text-green-2 text-center justify-center"
            onClick={handleExport}
            title='Click to export in excel'
          >
            <FaFileExcel />    Export Excel
          </button>
        ) : (
          <button
            disabled
            className="flex items-center buttons bg-green-2 text-white  gap-1 text-center justify-center"
            title='Click to export in excel'
          >
            <FaFileExcel />     Export Excel
          </button>
        )}
      </span>
    </>
  );
};

const NewOperatorTable = ({
  allOperators,
  setFilteredData,
  selectedRows,
  setSelectedRows,
  handleExport,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setLocalFilteredData] = useState(allOperators);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const inputRef = useRef(null);
  const navigate = useNavigate();

  const [statusFilter, setStatusFilter] = useState("all");

  const filterOperatorsByStatus = (operators, status) => {
    return operators.filter((operator) => {
      const leavingDate = operator.leaving_date
        ? new Date(operator.leaving_date)
        : null;
      const today = new Date();

      if (status === "Active") {
        return !leavingDate || leavingDate < today; // Active: No leaving_date or it's in the past
      } else if (status === "Inactive") {
        return leavingDate && leavingDate >= today; // Inactive: Leaving date is in the future
      }
      return true; // Default: Show all
    });
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // Apply filtering before displaying data
  const filteredOperators = filterOperatorsByStatus(filteredData, statusFilter);

  useEffect(() => {
    setFilteredData(filteredData);
  }, [filteredData, setFilteredData]);

  useEffect(() => {
    setLocalFilteredData(allOperators);
  }, [allOperators]);

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = allOperators.filter((operator) =>
      Object.values(operator).some((value) =>
        value?.toString().toLowerCase().includes(lowerCaseQuery)
      )
    );
    setLocalFilteredData(filtered);
    setSuggestions([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchQuery);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setPageSize(value === "All data" ? filteredData.length : value);
    setPage(0);
  };

  const handleInputChange = (query) => {
    setSearchQuery(query);
    if (query.trim() === "") {
      setSuggestions([]);
    } else {
      const lowerCaseQuery = query.toLowerCase();
      const matches = allOperators
        .filter(
          (operator) =>
            operator.name?.toLowerCase().includes(lowerCaseQuery) ||
            operator.aadhar_no?.toString().includes(lowerCaseQuery) ||
            operator.pf_account_no?.toString().includes(lowerCaseQuery)
        )
        .map((operator) => ({
          name: operator.name,
          aadhar_no: operator.aadhar_no,
          pf_account_no: operator.pf_account_no,
        }));
      setSuggestions(matches.slice(0, 5)); // Limit suggestions to 5
    }
  };

  const preprocessData = (data) =>
    data.map((item) => ({
      ...item,
      pf_account_no: Number.isInteger(item.pf_account_no)
        ? item.pf_account_no
        : parseInt(item.pf_account_no, 10) || "N/A",
      aadhar_no: item.aadhar_no
        ? item.aadhar_no
          .toString()
          .replace(/\D/g, "") // Remove non-numeric characters
          .replace(/(\d{4})(\d{4})(\d{4})/, "$1 $2 $3")
        : "N/A", // Fallback to "n/a" if Aadhaar number is invalid or missing
      joining_date: item.joining_date ? formatDate(item.joining_date) : "N/A",
      leaving_date: item.leaving_date ? formatDate(item.leaving_date) : "N/A",
    }));

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <span title="Row Number" arrow placement="top">
          <span>#</span>
        </span>
      ),
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },

    {
      field: "name",
      headerName: "Name",
      width: 180,
      renderHeader: () => (
        <span title="Name of the operator" arrow placement="top">
          <span>Name</span>
        </span>
      ),
    },
    {
      field: "aadhar_no",
      headerName: "Aadhaar Number",
      width: 180,
      renderHeader: () => (
        <span title="Aadhaar number of the operator" arrow placement="top">
          <span>Aadhaar Number</span>
        </span>
      ),
    },
    {
      field: "operator_role",
      headerName: "Operator Role",
      width: 180,
      renderHeader: () => (
        <span title="Role of the operator" arrow placement="top">
          <span>Operator Role</span>
        </span>
      ),
    },
    {
      field: "phone",
      headerName: "Contact Number",
      width: 180,
      renderHeader: () => (
        <span title="contact number of the operator" arrow placement="top">
          <span>Contact Number</span>
        </span>
      ),
    },

    {
      field: "sale_person",
      headerName: "Operator Sales Person",
      width: 180,
      renderHeader: () => (
        <span title="operator sales person" arrow placement="top">
          <span>Operator Sales Person</span>
        </span>
      ),
    },
    {
      field: "arr_asset_nos",
      headerName: "arr_asset_nos",
      width: 180,
      renderHeader: () => (
        <span title="Name of the operator" arrow placement="top">
          <span>Assets</span>
        </span>
      ),
    },

    // {
    //   field: "joining_date",
    //   headerName: "Joining Date",
    //   width: 180,
    //   renderHeader: () => (
    //     <span title="Date the operator joined" arrow placement="top">
    //       <span>Joining Date</span>
    //     </span>
    //   ),
    // },
    {
      field: "leaving_date",
      headerName: "Availability",
      width: 180,
      renderHeader: () => (
        <span title="Operator Availability" placement="top" arrow>
          <span>Check Availability</span>
        </span>
      ),
      renderCell: (params) => {
        const leavingDate = params.row.leaving_date
          ? new Date(params.row.leaving_date)
          : null;

        if (!leavingDate) {
          return <span className="text-green-500 font-bold">Active</span>;
        } else {
          return <span className="text-dark-6 font-bold">Inactive</span>;
        }
      },
    },

    // {
    //   field: "leaving_date",
    //   headerName: "Leaving Date",
    //   width: 180,
    //   renderHeader: () => (
    //     <span title="Date the operator left" placement="top" arrow>
    //       <span>Check Availability</span>
    //     </span>
    //   ),

    //   renderCell: (params) => {
    //     const leavingDate = params.row.leaving_date;
    //     if (!leavingDate) {
    //       return <span className="text-green-1">Active</span>;
    //     } {
    //       return <span>Inactive</span>;
    //     }
    //   }
    // },
    // {
    //   field: "leaving_date",
    //   headerName: "Status",
    //   width: 180,
    //   renderHeader: () => (
    //     <span title="Operator Status" arrow placement="top">
    //       <span>Status</span>
    //     </span>
    //   ),
    //   renderCell: (params) => {
    //     const leavingDate = params.row.leaving_date
    //       ? new Date(params.row.leaving_date)
    //       : null;
    //     const today = new Date();

    //     if (!leavingDate || leavingDate < today) {
    //       return <span className="text-green-500 font-bold">Available</span>;
    //     } else if (leavingDate >= today) {
    //       return <span className="text-red-500 font-bold">Assigned</span>;
    //     } else {
    //       return <span className="text-gray-500 font-bold">Not Specified</span>;
    //     }
    //   },
    // },
  ];

  return (
    <div className="mt-2">
      <Paper
        style={{
          height: "70vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        <div className="flex justify-between items-center ">
          <div className="relative flex flex-col md:flex-row gap-4 items-center">
            {/* Search Input with Suggestions */}
            <div className="relative w-full md:w-auto">
              <input
                title="Search by operator name, Aadhaar number, etc."
                ref={inputRef}
                type="text"
                placeholder="Search by Name, Aadhaar No, etc."
                value={searchQuery}
                onChange={(e) => handleInputChange(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full md:w-[250px] h-10 px-4 text-xs border border-gray-300 rounded-[4px] focus:outline-none focus:ring-1 focus:ring-blue-500 transition-all"
              />

              {suggestions.length > 0 && (
                <ul className="absolute left-0 mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg max-h-48 overflow-y-auto z-20">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="p-3 hover:bg-blue-50 cursor-pointer text-sm text-gray-700"
                      onClick={() => {
                        setSearchQuery(
                          suggestion.name ||
                          suggestion.aadhar_no ||
                          suggestion.pf_account_no
                        );
                        handleSearch(
                          suggestion.name ||
                          suggestion.aadhar_no ||
                          suggestion.pf_account_no
                        );
                      }}
                    >
                      {suggestion.name ||
                        suggestion.aadhar_no ||
                        suggestion.pf_account_no}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Availability Filter */}
            <div className="flex items-center gap-3">
              <span className="text-xs font-medium text-gray-600">
                Check Availability:
              </span>
              <select
                className="h-10 px-3 text-xs text-gray-700 border border-gray-300 rounded-[4px] focus:ring-1 focus:ring-blue-2 transition-all"
                value={statusFilter}
                onChange={handleStatusChange}
              >
                <option value="all">All Operators</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>


          </div>

          <div className="flex items-center gap-2 ">
            <TablePagination
              title="Pagination"
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 30, 50, "All data"]}
              labelRowsPerPage="Operators:"
              SelectProps={{
                renderValue: (value) =>
                  value === "All data" ? "All data" : value, // Display "All data" if selected
              }}
              className="bg-white rounded-lg"
            />
            <NewAssetBtn tabName="operator" />
            <ButtonData
              handleExport={handleExport}
              selectedRows={selectedRows}
              filteredData={filteredData}
            />
          </div>
        </div>

        {filteredData.length > 0 ? (
          <DataGrid
            rows={
              pageSize === filteredOperators.length // Use filteredOperators instead of filteredData
                ? filteredOperators.map((row, index) => ({
                  ...row,
                  counter: index + 1, // Add row number for all rows
                }))
                : filteredOperators
                  .slice(page * pageSize, page * pageSize + pageSize) // Paginate filtered data
                  .map((row, index) => ({
                    ...row,
                    counter: page * pageSize + index + 1, // Adjust counter for paginated rows
                  }))
            }
            onRowClick={(params) => {
              const rowId = params.row.id; // Use the row ID or another property to navigate
              navigate(`/operators/details/${rowId}`); // Adjust the route as needed
            }}
            columns={columns}
            paginationMode="server"
            pagination={false}
            disableSelectionOnClick
            sx={{
              "& .MuiDataGrid-footerContainer": {
                display: "none", // Hides the footer container
              },

              "& .MuiDataGrid-root": { fontSize: "12px", height: 25 },
              "& .MuiDataGrid-columnHeaders": {
                fontSize: "12px",
                backgroundColor: "#000",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
                height: 20,
              },
              "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          />
        ) : (
          <>
            <div className="text-xs items-center flex justify-center p-10 border">
              No operators found in your search area
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default NewOperatorTable;
