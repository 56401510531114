import React from 'react'

const OperatorHeaderTable = ({allOperators}) => {
  return (
    <div>
      <div className="flex gap-3 text-xs mt-2 justify-start">
        {/* {HeaderData.map((data) => <> */}
        <div className=" p-3 w-[180px]  rounded-[6px] shadow-md bg-white" >
            <h1 className="text-[10px]">Total Operators</h1>
            <span className="text-base font-extrabold text-light-2" >{allOperators.length}</span>
        </div>
        {/* </>)} */}
    </div>
    </div>
  )
}

export default OperatorHeaderTable
