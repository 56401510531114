import React, { useEffect, useState } from "react";
import RejectionForm from "./RejectionForm";
import RemovedPart from "./RemovedPart";
import InstalledPart from "./InstalledPart";
import RequestPart from "./RequestPart";
import { setBaseUrl } from "../../config";
import LocalPart from "./LocalPart";
import { formatDate } from "../../customFunctions/FormatDate";
import { useToast } from "../../Context/ToastContext";
import ReceivedPartTable from "./RecievedPart";

const TableParts = ({ inventory, maintenance, id }) => {
  const [form, setForm] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const token = sessionStorage.getItem("sales_token");
  const [openRemove, setOpenRemove] = useState(false); // Remove Part
  const [openRequest, setOpenRequest] = useState(false); // Add Request
  const [openInstalledParts, setOpenInstalledParts] = useState(false); // Add Installed
  const [localPart, setLocatPart] = useState(false)
  const [getWallet, setGetWallet] = useState(null);
  const [seeAllWallet, setSeeAllWallet] = useState(false)
  const [loading, setLoading] = useState(false);
  // /sale-service/get-own-wallet
  // get setvice person wallet informations
  const { addToast } = useToast()
  const getWalletInfo = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/sale-service/get-own-wallet`, {
        headers: {
          "auth-token": token,
        },
      });
      const data = await res.json();
      setGetWallet(data);
    } catch (error) {
      console.error("Failed to fetch wallet details:", error);
    }
  };

  useEffect(() => {
    getWalletInfo();
  }, [setBaseUrl, token]);


  // console.log(getWallet);

  const handleSubmit = async () => {
    const submittedData = Object.keys(form)
      .filter((ID) => form[ID]?.selected) // Only include selected rows
      .map((ID) => {
        const inventoryItem = getWallet.find((item) => {

          return Number(item.id) === Number(ID); // Ensure type consistency
        });



        return {

          part_id: form[ID]?.part_inventory_id || inventoryItem?.part_inventory_id || "",
          maintenance_id: id,
          quantity: form[ID]?.quantity || inventoryItem?.request_quantity || 0,
        };
      });

    // console.log("Submitted Data:", submittedData);

    try {
      const response = await fetch(`${setBaseUrl}/sale-service/install-part`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(submittedData),
      });

      setLoading(false);
      if (response.status === 200) {
        addToast("Part installed successfully", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        addToast("Error in installing part", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      addToast("Error in installing part! Please try again", "error");
    }
  };


  const filteredInventory = getWallet?.filter(
    (item) =>
      item.part_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.part_no?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const groupedInventory = filteredInventory?.reduce((acc, item) => {
    const id = item.id; // Assuming id is a unique identifier (string/number)
    if (!acc[id]) acc[id] = [];
    acc[id].push(item);
    return acc;
  }, {});

  return (
    <div className="flex flex-col p-6 gap-2 mt-1 border w-[800px]">
      <span className="text-xs p-2 w-full bg-gray-200">
        Parts Utilization
      </span>

    

     

<div className="relative max-w-md mb-6">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Search by Part No..."
            className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            aria-label="Search parts by part number"
          />
        
        </div>



      <table>
        <thead className="bg-gray-800 text-white border">
          <tr>
            <th className="px-4 py-2 text-xs">#</th>
            <th className="px-4 py-2 text-xs">Requested Date</th>
            <th className="px-4 py-2 text-xs">Part No</th>

            <th className="px-4 py-2 text-xs">Quantity</th>
            <th className="px-4 py-2 text-xs">Status</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedInventory || {}).length === 0 ? (
            <tr>
              <td colSpan="5" className="text-center text-xs py-4">
                No parts available.
              </td>
            </tr>
          ) : (
            <>
              {
                Object.keys(groupedInventory).map((ID, index) =>
                  groupedInventory[ID].map((item, subIndex) => {

                    if (item.maintenance_id === id && item.request_quantity !== 0) {
                    

                      return (
                        <tr className="border" key={`${ID}-${subIndex}`}>

                          <td className="px-4 py-2 text-xs text-gray-600">
                            <input
                              type="checkbox"
                              checked={form[item.id]?.selected || false}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  [item.id]: {
                                    ...form[item.id],
                                    selected: e.target.checked,
                                    part_no: item.part_no,
                                    part_name: item.part_name,
                                  },
                                });
                              }}
                            />
                          </td>
                          <td className="px-4 py-2 text-xs text-gray-600">
                            {formatDate(item.requested_date)}
                          </td>
                          <td className="px-4 py-2 text-xs text-gray-600">
                            {item.part_no}
                          </td>

                          <td className="px-4 py-2 text-xs text-gray-600">
                            <input
                              type="number"
                              min={0}
                              className="border-gray-300 w-[120px] rounded-md p-2 text-xs focus:ring-2 focus:ring-blue-400 focus:border-blue-400 outline-none "
                              value={form?.[item.id]?.quantity || item.request_quantity}
                          
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  [item.id]: {
                                    ...form[item.id],
                                    quantity: e.target.value,
                                    part_no: item.part_no,
                                    part_name: item.part_name,
                                  },
                                });
                              }}
                            />
                          </td>
                          <td className="px-4 py-2 text-xs ">
                            <span
                              className={`p-1 px-2 text-xs rounded-full ${item.is_approved === true
                                ? "bg-green-2 text-white"
                                : "bg-red-1 text-white"
                                }`}
                            >
                              {item.is_approved ? "Approved" : "Pending"}
                            </span>
                          </td>
                        </tr>
                      )
                    }
                  }
                  )
                )

              }
            </>
          )

          }


        </tbody>
      </table>
      {/* <ReceivedPartTable getWallet={getWallet}/> */}
      <div className="flex items-center  gap-2">
      <button
        className={`flex-1 py-2 px-4 rounded-lg font-medium  transition-colors  bg-green-2 text-white`}
        onClick={handleSubmit}
     
      >
        Install
      </button>
       
        {/* <button className="px-4 py-2 text-xs border-red-1 text-red-1 hover:bg-red-1 hover:text-white border rounded"
          onClick={() => setLocatPart(true)}
        >
          Purchase Locally
        </button> */}

      </div>

      <button
          className="flex-1 py-2 px-4 rounded-lg font-medium  transition-colors border border-green-2 text-green-2 hover:text-white   hover:bg-green-2 "
          onClick={() => setOpenRequest(true)}
        >
          Request Part
        </button>
        {/* <button
            className="buttons border-green-2 text-green-2 border w-[150px]"
            onClick={() => setOpenInstalledParts(true)}
          >
            Installed Part
          </button> */}
        <button
          className="flex-1 py-2 px-4 rounded-lg font-medium transition-colors border-green-2 text-green-2 hover:bg-green-2 hover:text-white border  "
          onClick={() => setOpenRemove(true)}
        >
          Remove Part{" "}
        </button>
      {openRemove && (
        <>
          <div className="z-[9999] bg-white fixed md:left-[35%] top-[25%] p-6 rounded-[4px]">
            <RemovedPart inventory={inventory} id={id} />
          </div>
          <div
            className="z-0 top-0 bottom-0 left-0 right-0 fixed bg-[#00000027]"
            onClick={() => setOpenRemove(false)}
          />
        </>
      )}

      {openInstalledParts && (
        <>
          <div className="z-[9999]  bg-transparent fixed md:left-[35%] top-[25%] p-6 rounded-[4px]">
            <InstalledPart inventory={getWallet} id={id} />
          </div>
          <div
            className="z-0 top-0 bottom-0 left-0 right-0 fixed bg-[#00000027]"
            onClick={() => setOpenInstalledParts(false)}
          />
        </>
      )}

      {openRequest && (
        <>
          <div className="z-[9999] bg-transparent fixed md:left-[35%] top-[25%] p-6 rounded-[4px]">
            <RequestPart inventory={inventory} id={id} />
          </div>
          <div
            className="z-0 top-0 bottom-0 left-0 right-0 fixed bg-[#00000027]"
            onClick={() => setOpenRequest(false)}
          />
        </>
      )}

      {/* {
        localPart && <LocalPart maintenance_id={id} setLocatPart={setLocatPart} />
      } */}
    </div>
  );
};

export default TableParts;
