import React, { useEffect, useRef, useState } from "react";
import ImageUploading from "react-images-uploading";
import BasicDetails from "./BasicDetails";
import ConfigDetails from "./ConfigDetails";
import CommercialDetails from "./CommercialDetails";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";
import NewBasicDetails from "./NewBasicDetails";
import NewAssetConfigForm from "./NewAssetConfigForm";
import NewCommercialDetails from "./NewCommercialDetails";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";

function NewAssetTab({ setShowCurrentTab }) {
  // set loader state
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  //setting state for allowing option for commercial details
  const [commToggle, setCommToggle] = useState(false);

  //target the image input when clicked on the button
  //the button will have an handleInputImage function which will invoke the image input field by triggering the ref of that input
  const imageInputRef = useRef(null);
  //set image
  const [uploadedImage, setUploadedImage] = useState(null);
  //target the document input element by using useRef hook
  const documentInputRef = React.useRef(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [showDoc, setShowDoc] = useState(null);
  const [assetBasicDetails, setAssetBasicDetails] = useState({
    asset_no: "",
    make: "",
    model: "",
    yom: "",
    category: "",
    capacity: "",
    description: "",
    serial_no: "",
    purchased_from: "",
    rfid: "",
    hieght_machine: "",
    device_no: null,
    site_location: "",
    rating: "",
    //company_id:
    is_sold: "",
    // sold_machine_data: "",
    doc_expiry_date: new Date(),
    doc_types: "doc",
    installation_date: "",
  });

  const [assetConfigDetails, setAssetConfigDetails] = useState({
    used_or_new: "new",
    ansi_or_new: "ansi",
    machine_ownership_type: "rental",
    battery_type: "Diesel",
    engine_serial_no: "",
    two_or_four_wd: "2WD",
    accessories: "",
    tyres: "Airfilled",
    asset_id: "",
  });

  const [commercialDetails, setCommercialDetails] = useState({
    purchase_order_no: "",
    purchase_order_date: "",
    invoice_no: "",
    invoice_date: "",
    payment_terms: "",
    amount_rem_to_oem: "",
    date_of_rem_to_oem: "",
    exchange_rate_rem: "",
    custom_duty_payment: "",
    exworks_price: "",
    cif_charges: "",
    total_cost: "",
    boe_no: "",
    custom_duty_value: "",
    gst_amount: "",
    exrate_boe: "",
    clearing_charges: "",
    cha_charges: "",
    transportation_charges: "",
    port_of_dispatch: "",
    port_of_clearance: "",
    period_of_insurance: "",
    insurance_renewal: "",
    total_landed_cost: "",
    total_landed_cost_with_gst: "",
    asset_id: "",
  });

  const handleImageChange = () => {
    imageInputRef.current.click();
  };

  const handleImageUploadChange = (e) => {
    setUploadedImage(e.target.files);
    let photoFiles = e.target.files;
    let photoArray = [];
    for (const key in photoFiles) {
      if (photoFiles.hasOwnProperty(key)) {
        photoArray.push(photoFiles[key]);
      }
    }
    setShowImage(photoArray);
  };
  //set the document to send to the server
  const handleDocumentChange = (e) => {
    console.log(e.target.files);
    setUploadedDocument(e.target.files);
    let docFiles = e.target.files;
    let docArray = [];
    for (const key in docFiles) {
      if (docFiles.hasOwnProperty(key)) {
        docArray.push(docFiles[key]);
      }
    }
    setShowDoc(docArray);
  };

  //invoke the document upload input on button click
  const handleDocumentUploadInput = (e) => {
    documentInputRef.current.click();
  };
  //upload the image to the database
  async function handleMaro(asset_id) {
    // console.log(asset_id);
    console.log(uploadedImage);
    if (uploadedImage) {
      const formData = new FormData();
      for (const key in uploadedImage) {
        if (uploadedImage.hasOwnProperty(key))
          formData.append("photo", uploadedImage[key]);
      }
      formData.append("types", "asset");
      formData.append("asset_id", asset_id);
      //   for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }
      await axios
        .post(`${setBaseUrl}/asset/upload_photo`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  // upload the document to the database
  async function handleDocument(asset_id) {
  

    if (uploadedDocument) {
      const formData = new FormData();
      for (const key in uploadedDocument) {
        if (uploadedDocument.hasOwnProperty(key)) {
          formData.append("attachment", uploadedDocument[key]);
        }
      }
      formData.append("types_section", "asset");
      formData.append("asset_id", asset_id);
      formData.append("doc_types", "document");
      formData.append("doc_expiry_date", new Date());
      formData.append("serial_no", assetBasicDetails?.serial_no);
      //       types_section:asset
      // serial_no:assetBasicDetails.serial_no
      // doc_types:document
      // doc_expiry_date:13/06/2024
      // asset_id:7d9ec0a8-1aab-4ef0-afa6-c1392413f571
      //   for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }
      await axios
        .post(`${setBaseUrl}/asset/upload_attachment`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  // const handleSubmit = async () => {
  //   if (assetBasicDetails.category === "") {
  //     alert("Please select a category");
  //     return;
  //   }
  //   try {
  //     setLoader(true);
  //     //first an asset will be created with basic details
  //     const data1 = await axios.post(
  //       `${setBaseUrl}/asset/create`,
  //       assetBasicDetails,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     console.log(data1);
  //     //get the id of newly created asset
  //     let getAssetId = data1.data.asset_id;

  //     await handleMaro(getAssetId);
  //     await handleDocument(getAssetId);
  //     let updatedConfigDetails = {
  //       ...assetConfigDetails,
  //       asset_id: getAssetId,
  //     };
  //     console.log(updatedConfigDetails);
  //     //send request to the create config details with the above id
  //     const data2 = await axios.post(
  //       `${setBaseUrl}/asset/create_config`,
  //       updatedConfigDetails,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     console.log(data2);
  //     //send request to the create commercial details with the above id only if commToggle is true
  //     let data3;
  //     if (commToggle) {
  //       let updatedCommercialDetails = {
  //         ...commercialDetails,
  //         asset_id: getAssetId,
  //       };
  //       data3 = await axios.post(
  //         `${setBaseUrl}/asset/create_details`,
  //         updatedCommercialDetails,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-access-tokens": sessionStorage.getItem("token"),
  //           },
  //         }
  //       );
  //     }
  //     console.log(data3);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoader(false);
  //     navigate("/assets");
  //   }
  // };
  
  const handleSubmit = async () => {
    // Validate required fields
    const { asset_no, make, model } = assetBasicDetails;
  
    if (!asset_no || !make || !model) {
      if (!asset_no) toast.error("Asset number is required");
      if (!make) toast.error("Make is required");
      if (!model) toast.error("Model Number is required");
      return; // Stop execution if validation fails
    }
  
    try {
      setLoader(true);
  
      // Create the asset with basic details
      const data1 = await axios.post(
        `${setBaseUrl}/asset/create`,
        assetBasicDetails,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
      // console.log(data1);
  
      // Get the ID of the newly created asset
      let getAssetId = data1.data.asset_id;
  
      // Handle related data creation
      await handleMaro(getAssetId);
      await handleDocument(getAssetId);
  
      // Update configuration details and send the request
      let updatedConfigDetails = {
        ...assetConfigDetails,
        asset_id: getAssetId,
      };
      console.log(updatedConfigDetails);
  
      const data2 = await axios.post(
        `${setBaseUrl}/asset/create_config`,
        updatedConfigDetails,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
      // console.log(data2);
  
      // Optionally handle commercial details
      let data3;
      if (commToggle) {
        let updatedCommercialDetails = {
          ...commercialDetails,
          asset_id: getAssetId,
        };
        data3 = await axios.post(
          `${setBaseUrl}/asset/create_details`,
          updatedCommercialDetails,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": sessionStorage.getItem("token"),
            },
          }
        );
      }
      // console.log(data3);
  
      // Show success toast
      toast.success("Asset successfully created");
      navigate("/assets");
    } catch (error) {
      console.error(error);
  
      // Show error toast
      toast.error("Asset creation failed");
    } finally {
      setLoader(false);
    }
  };
  


  if (loader) {
    return <Loader />;
  } else {
    return (
      <div id="new-asset" className="animfadein">
        <div class="flex  text-dark-6 py-2">
         
          <p class="inline pb-2 pl-5  font-bold text-dark-6">
            {/* if showCurrentTab equals to 5 it means we have opened new Asset tab or it is Update asset tab */}
          Create New Asset
          </p>
        </div>
        {/* <BasicDetails
          assetBasicDetails={assetBasicDetails}
          setAssetBasicDetails={setAssetBasicDetails}
        /> */}

        <NewBasicDetails
          assetBasicDetails={assetBasicDetails}
          setAssetBasicDetails={setAssetBasicDetails}
        />
        {/* <ConfigDetails
          assetConfigDetails={assetConfigDetails}
          setAssetConfigDetails={setAssetConfigDetails}
        /> */}
        <NewAssetConfigForm
          assetConfigDetails={assetConfigDetails}
          setAssetConfigDetails={setAssetConfigDetails}
        />

        {/* <CommercialDetails
          commToggle={commToggle}
          setCommToggle={setCommToggle}
          commercialDetails={commercialDetails}
          setCommercialDetails={setCommercialDetails}
        /> */}
        <NewCommercialDetails
          commToggle={commToggle}
          setCommToggle={setCommToggle}
          commercialDetails={commercialDetails}
          setCommercialDetails={setCommercialDetails}
        />
        {/* Old ui */}

        {/* <div class="flex animSlideup relative mb-24" >
          <div class="basis-full bg-white rounded-[8px] text-xs mx-2 mt-5 border  justify-between">
            <div class="flex p-5 border-b border-gray-200 ">
              <h2 class="font-medium  mr-auto ">Asset Photos</h2>
              <button class="bg-blue-3 text-white rounded-[6px] h-6 px-2 text-xs"
              onClick={() => handleImageChange()}
              >
                <input
                  ref={imageInputRef}
                  type="file"
                  accept="image/jpeg, image/png"
                  multiple
                  onChange={handleImageUploadChange}
                  wfd-id="id57"
                  className="hidden"
                />
                Browse Photos
              </button>
            </div>
            {uploadedImage && <div class="p-5 text-sm">
              <div class="flex flex-col text-base gap-2">
                
                {showImage.length > 0 && showImage.map(img => <p className="text-xs">{img.name}</p>)}
              </div>
            </div>}
          </div>
          <div className="basis-full bg-white rounded-[8px] mx-2 mt-5  border justify-between">
            <div class="flex p-5 border-b border-gray-200 ">
              <h2 class="font-medium text-xs mr-auto ">
                Maintenance Documents
              </h2>
              <button
                onClick={handleDocumentUploadInput}
                class="bg-blue-3 text-white rounded-[6px] h-6 px-2 text-xs"
              >
                <input
                  ref={documentInputRef}
                  type="file"
                  multiple
                  accept=".doc,.pdf,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  class="hidden"
                  wfd-id="id57"
                  onChange={handleDocumentChange}
                />
                Browse Documents
              </button>
            </div>
            <div class="p-5 text-sm">
              {uploadedDocument && (
                <div class="flex flex-col">{showDoc.length>0 && showDoc.map(doc=><p>{doc.name}</p>)}</div>
              )}
            </div>
          </div>
        </div> */}

        {/* New UI */}
        <div className="flex gap-3 mt-2 pb-16 text-xs justify-end">
          <div className="">
            <div class="border-2 border-dashed border-gray-300  rounded-lg p-6 mb-2">
              <div class="flex p-5  border-gray-200">
                <button
                  className="flex flex-col items-center justify-center text-gray-500  hover:text-gray-700  w-[300px]"
                  onClick={() => handleImageChange()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-12 h-12 mb-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5V19a2 2 0 002 2h14a2 2 0 002-2v-2.5M16 10l-4-4m0 0L8 10m4-4v12"
                    />
                  </svg>
                  <input
                    ref={imageInputRef}
                    type="file"
                    accept="image/jpeg, image/png"
                    multiple
                    onChange={handleImageUploadChange}
                    wfd-id="id57"
                    className="hidden"
                  />
                  Click to upload photos
                </button>
              </div>
              {uploadedImage && (
                <div class="p-5 text-xs">
                  <div class="flex flex-col text-base gap-2">
                    {showImage.length > 0 &&
                      showImage.map((img) => (
                        <p className="text-xs">{img.name}</p>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg p-6 mb-2 w-[400px]">
            <label
              htmlFor="fileUpload"
              className="cursor-pointer flex flex-col items-center justify-center text-gray-500  hover:text-gray-700  transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-12 h-12 mb-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5V19a2 2 0 002 2h14a2 2 0 002-2v-2.5M16 10l-4-4m0 0L8 10m4-4v12"
                />
              </svg>
              <span className="text-xs font-medium">
                Click to upload Documents
              </span>
              <input
                ref={documentInputRef}
                id="fileUpload"
                wfd-id="id57"
                type="file"
                className="hidden"
                multiple
                accept=".pdf"
                onChange={handleDocumentChange}
              />
            </label>
            <div class="p-5 text-xs">
              {uploadedDocument && (
                <div class="flex flex-col">
                  {showDoc.length > 0 &&
                    showDoc.map((doc) => <p>{doc.name}</p>)}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Button */}
        <div className="h-12 bottom-0 border-t items-center flex justify-end  left-10 right-0 fixed z-50  bg-white shadow-2xl ">
          <div className="flex mr-6 justify-between items-center gap-3 text-xs">
            <Button
             
              onClick={() => navigate("/assets")}
              variant="outlined"
              sx={{
                fontSize: "12px",
                color: "#7C7C7C",
                borderColor: '#7C7C7C',
                textTransform: "none", // To disable uppercase text transformation
              }}
            >
              Back
            </Button>
            <Button
             variant="contained"
             sx={{
               fontSize: "12px",
               color: "white",
               textTransform: "none", // To disable uppercase text transformation
             }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          
          </div>
        </div>
      </div>
    );
  }
}

export default NewAssetTab;
