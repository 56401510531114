import React, { useEffect, useState } from 'react';
import Data from './Dummy'; // Ensure this import is correct
import { setBaseUrl } from "../../../config"; // Ensure this import is correct
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useToast } from '../../../Context/ToastContext';
import { formatDateForInput, formatDateForInputMaintenance, parseInputToDate } from '../../../customFunctions/FormatDate';

const NewMaintenance = ({ ticketID, setTicketID }) => {
  // State Variables
  const [assetDetails, setAssetDetails] = useState([]); // Store asset details
  const [searchTerm, setSearchTerm] = useState(""); // Search input state
  const [showDropdown, setShowDropdown] = useState(false); // Dropdown visibility
  const [selectedAssets, setSelectedAssets] = useState([]); // Selected assets
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [form, setForm] = useState({
    complaint: '',
    complaint_type: '',
    description: '',
    issue_date: ''
  }); //store the complain message



  // Navigate Function
  const navigate = useNavigate()

  const { addToast } = useToast()
  // Fetch Asset  Numbers
  const getAssetNumbers = async () => {
    try {
      const response = await fetch(`${setBaseUrl}/asset/get_all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      const data = await response.json();

      if (Array.isArray(data)) {
        const formattedAssets = data.map(asset => ({
          asset_no: asset.asset_no, // User-friendly label
          id: asset.id, // Unique value (ID)
        }));

        setAssetDetails(formattedAssets);
      } else {
        console.error("Data is not an array:", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Fetch asset numbers on component mount
  useEffect(() => {
    getAssetNumbers();
  }, []);

  // Handle asset selection
  const handleSelect = (asset) => {
    if (!selectedAssets.some(selected => selected.asset_no === asset.asset_no)) {
      setSelectedAssets([...selectedAssets, asset]);
    }
    setShowDropdown(false); // Close dropdown after selection
  };

  // Remove selected asset
  const removeAsset = (assetToRemove) => {
    setSelectedAssets(selectedAssets.filter(asset => asset.asset_no !== assetToRemove.asset_no));
  };

  // console.log(selectedAssets)

  const handleSubmit = async () => {
    if (selectedAssets.length === 0) {
      toast.error("No asset selected");
      return;
    }

    const asset = selectedAssets[0]; // Take the first selected asset

    const SubmitData = {
      asset_no: asset.asset_no || "",
      asset_id: asset.id || "",
      complaint_type: form.complaint_type || "",
      description: form.description || "",
      complaint_date: new Date(),
      complaint: form.complaint || "",
      issue_date: form.issue_date || ""

    };

    try {
      const res = await fetch(`${setBaseUrl}/maintenance/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(SubmitData),
      });

      const data = await res.json()


      if (res.status === 200) {
        addToast(`Complain registered successfully!! Ticket ID ${data.id}`, "success");
        setTicketID(data.id)
        navigate('/maintenance')
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      } else {
        const errorObj = typeof data.error === "string" ? JSON.parse(data.error.replaceAll("'", '"')) : data.error;
        addToast(`A complain is already registered for this asset with ticket id: ${errorObj.tiket_no}`, "error");

      }
    } catch (e) {
      addToast("An error occurred", "error");
    }
  };





  return (
    // <div className="items-center gap-6 justify-center flex">
    //   {/* Complain Register Form */}
    //   <div className='items-center justify-center flex flex-col gap-6 p-10 w-[100vh]'>
    //     <span className="text-base font-extrabold text-dark-6 ">Register Complain</span>

    //     {/* Choose Asset Number */}
    //     <div className="relative">
    //       <label className="block text-gray-700 text-sm w-[300px]">Choose Asset Number</label>
    //       {/* Search Input */}
    //       <div
    //         className="w-full bg-gray-100 border-b border-gray-300 px-3 py-2 rounded-md cursor-pointer"
    //         onClick={() => setShowDropdown(!showDropdown)}
    //       >
    //         {selectedAssets.length > 0 ? (
    //           <div className="flex flex-wrap gap-2  w-[300px]">
    //             {selectedAssets.map((asset) => (
    //               <span key={asset.id} className="bg-blue-500 text-white px-2 py-1 text-xs rounded flex items-center gap-1">
    //                 {asset.asset_no}
    //                 <button
    //                   onClick={(e) => {
    //                     e.stopPropagation();
    //                     removeAsset(asset);
    //                   }}
    //                   className="ml-1 hover:text-red-300"
    //                 >
    //                   ✕
    //                 </button>
    //               </span>
    //             ))}
    //           </div>
    //         ) : (
    //           <span className="text-gray-400">Select Asset Number</span>
    //         )}
    //       </div>

    //       {/* Dropdown */}
    //       {showDropdown && (
    //         <>
    //           <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-[10] mt-1">
    //             {/* Search Input */}
    //             <input
    //               type="text"
    //               className="w-full p-2 border-b border-gray-300 focus:outline-none"
    //               placeholder="Search..."
    //               value={searchTerm}
    //               onChange={(e) => setSearchTerm(e.target.value)}
    //             />
    //             {/* Options List */}
    //             <ul className="max-h-40 overflow-y-auto">
    //               {assetDetails.length > 0 ? (
    //                 assetDetails
    //                   .filter(asset => asset.asset_no.toLowerCase().includes(searchTerm.toLowerCase()))
    //                   .map(asset => (
    //                     <li
    //                       key={asset.id}
    //                       className="px-3 py-2 hover:bg-blue-100 cursor-pointer"
    //                       onClick={() => handleSelect(asset)}
    //                     >
    //                       {asset.asset_no}
    //                     </li>
    //                   ))
    //               ) : (
    //                 <li className="px-3 py-2 text-gray-500">No results found</li>
    //               )}
    //             </ul>
    //           </div>
    //           {/* Overlay to close dropdown when clicking outside */}
    //           <div
    //             className="fixed top-0 left-0 w-full h-full z-[5]"
    //             onClick={() => setShowDropdown(false)}
    //           />
    //         </>
    //       )}
    //     </div>

    //     {/* Complain Title */}
    //     <div className="">
    //       <label className="block text-gray-700 text-sm">Breakdown Title</label>
    //       <input
    //         type="text"
    //         maxLength={35}
    //         value={form.complaint || ''}
    //         className="border border-gray-300 text-gray-700 text-sm p-2 rounded-md w-[300px]"
    //         placeholder="Enter Breakdown Title"
    //         onChange={(e) => {
    //           setForm({
    //             ...form, complaint: e.target.value,
    //           })
    //         }}
    //       />
    //     </div>
    //     <div className="">
    //       <label className="block text-gray-700 text-sm">Breakdown Date</label>
    //       <input
    //         type="date"
    //         value={form.issue_date || ''}
          
    //         className="border border-gray-300 text-gray-700 text-sm p-2 rounded-md w-[300px]"
    //         placeholder="Enter Date"
    //         onChange={(e) => {
    //           setForm({
    //             ...form, issue_date: e.target.value,
    //           })
    //         }}
    //       />
  
    //     </div>
    //     {/* Complain Type */}
    //     <div className="">
    //       <label className="block text-gray-700 text-sm">Breakdown Type</label>
    //       <select
    //         value={form.complaint_type || ''}
    //         className="border border-gray-300 text-gray-700 text-sm p-2 rounded-md w-[300px]"
    //         onChange={(e) => {
    //           setForm({
    //             ...form, complaint_type: e.target.value,
    //           })
    //         }}
    //       >
    //         <option value="">Select</option>
    //         <option value="major">Major Maintenance</option>
    //         <option value="minor">Minor Maintenance</option>
    //         <option value="others">Others</option>
    //       </select>
    //     </div>

    //     {/* Description */}
    //     <div className="">
    //       <label className="block text-gray-700 text-sm">Breakdown Description</label>
    //       <textarea
    //         rows="4"
    //         maxLength={250}
    //         value={form.description || ''}
    //         className="border border-gray-300 text-gray-700 text-sm p-2 rounded-md w-[300px]"
    //         placeholder="Enter breakdown description"
    //         onChange={(e) => {
    //           setForm({
    //             ...form, description: e.target.value,
    //           })
    //         }}
    //       />
    //     </div>

    //     {/* Buttons */}
    //     <div className="flex justify-between  items-center">
    //       <button className="flex-1 py-2 px-4 rounded-lg font-medium text-green-2 transition-colors border border-green-2" onClick={() => navigate(-1)}>Cancel</button>
    //       <button className="flex-1 py-2 px-4 rounded-lg font-medium text-white transition-colors bg-green-2" onClick={handleSubmit}>Submit</button>
    //     </div>
    //   </div>


    // </div>
    <div className="flex items-center justify-center  p-4">
  {/* Complain Register Form */}
  <div className="bg-white rounded-xl border p-8 w-full max-w-md">
    <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">Register Complaint</h2>

    {/* Choose Asset Number (kept as is) */}
    <div className="relative mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-1">Choose Asset Number</label>
      <div
        className="w-full bg-gray-100 border-b border-gray-300 px-3 py-2 rounded-md cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedAssets.length > 0 ? (
          <div className="flex flex-wrap gap-2 w-[300px]">
            {selectedAssets.map((asset) => (
              <span key={asset.id} className="bg-blue-500 text-white px-2 py-1 text-xs rounded flex items-center gap-1">
                {asset.asset_no}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeAsset(asset);
                  }}
                  className="ml-1 hover:text-red-300"
                >
                  ✕
                </button>
              </span>
            ))}
          </div>
        ) : (
          <span className="text-gray-400">Select Asset Number</span>
        )}
      </div>

      {showDropdown && (
        <>
          <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-[10] mt-1">
            <input
              type="text"
              className="w-full p-2 border-b border-gray-300 focus:outline-none"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ul className="max-h-40 overflow-y-auto">
              {assetDetails.length > 0 ? (
                assetDetails
                  .filter(asset => asset.asset_no.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map(asset => (
                    <li
                      key={asset.id}
                      className="px-3 py-2 hover:bg-blue-100 cursor-pointer"
                      onClick={() => handleSelect(asset)}
                    >
                      {asset.asset_no}
                    </li>
                  ))
              ) : (
                <li className="px-3 py-2 text-gray-500">No results found</li>
              )}
            </ul>
          </div>
          <div
            className="fixed top-0 left-0 w-full h-full z-[5]"
            onClick={() => setShowDropdown(false)}
          />
        </>
      )}
    </div>

    {/* Form Fields */}
    <div className="space-y-4">
      {/* Complaint Title */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Breakdown Title</label>
        <input
          type="text"
          maxLength={35}
          value={form.complaint || ''}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Enter breakdown title"
          onChange={(e) => setForm({...form, complaint: e.target.value})}
        />
      </div>

      {/* Breakdown Date */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Breakdown Date</label>
        <input
          type="date"
          value={form.issue_date || ''}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          onChange={(e) => setForm({...form, issue_date: e.target.value})}
        />
      </div>

      {/* Complaint Type */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Breakdown Type</label>
        <select
          value={form.complaint_type || ''}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          onChange={(e) => setForm({...form, complaint_type: e.target.value})}
        >
          <option value="">Select breakdown type</option>
          <option value="major">Major Maintenance</option>
          <option value="minor">Minor Maintenance</option>
          <option value="others">Others</option>
        </select>
      </div>

      {/* Description */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Breakdown Description</label>
        <textarea
          rows="4"
          maxLength={250}
          value={form.description || ''}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Describe the breakdown in detail"
          onChange={(e) => setForm({...form, description: e.target.value})}
        />
      </div>
    </div>

    {/* Buttons */}
    <div className="flex justify-between gap-4 mt-8">
      <button 
        className="flex-1 py-2 px-4 rounded-lg font-medium transition-colors border border-green-2 text-green-2"
        onClick={() => navigate(-1)}
      >
        Cancel
      </button>
      <button 
        className="flex-1 py-2 px-4 rounded-lg font-medium text-white transition-colors bg-green-2"
        onClick={handleSubmit}
      >
        Submit Complaint
      </button>
    </div>
  </div>
</div>
  );
};

export default NewMaintenance;