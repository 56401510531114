import React, { useState } from "react";
import { setBaseUrl } from "../../../config";
import { toast } from "react-toastify";
import { useToast } from "../../../Context/ToastContext";
import { IoMdClose } from "react-icons/io";
import AddNewPart from "./AddNewPart";
import { formatDate } from "../../../customFunctions/FormatDate";

const GetApprovalParts = ({ person, id }) => {
  const [openAddNew, setOpenAddNew] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [selectedParts, setSelectedParts] = useState([]);
  const { addToast } = useToast();

  // Toggle part selection
  const handleSelectPart = (part) => {
    const isSelected = selectedParts.some((p) => p.id === part.id);
    if (isSelected) {
      setSelectedParts(selectedParts.filter((p) => p.id !== part.id));
    } else {
      setSelectedParts([...selectedParts, { ...part, request_quantity: part.request_quantity, is_approved: true }]);
    }
  };

  // Update quantity for a selected part
  const handleQuantityChange = (partId, newQuantity) => {
    const updatedParts = selectedParts.map((p) =>
      p.id === partId ? { ...p, request_quantity: newQuantity, quantity: parseInt(newQuantity) } : p
    );
    setSelectedParts(updatedParts);
  };

  // Submit selected parts
  const handleHit = async () => {
    if (selectedParts.length === 0) {
      toast.error("Please select at least one part to submit.");
      return;
    }

    try {
      const res = await fetch(`${setBaseUrl}/maintenance/edit-aproval-list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(selectedParts),
      });

      if (res.status === 200) {
        addToast("Parts Approved Successfully", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error:", error);
      addToast("Parts Approval Failed", "error");
    }
  };

  // Filter parts based on search term
  const filteredParts = person?.[0]?.wallet?.filter((part) =>
    part.part_no.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="">
      <div className="flex flex-col gap-4 shadow-md p-4 rounded-md bg-white w-full">
        <h3 className="flex justify-between items-center">
          <span className="w-full p-2 mr-2 rounded bg-gray-200">
            Parts Requested by Service Engineer
          </span>
        </h3>

        {/* Search Bar */}
        <div className="relative max-w-md mb-6">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            placeholder="Search by Part No..."
            className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500  transition duration-150"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-label="Search parts by part number"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
              aria-label="Clear search"
            >
              <svg
                className="h-5 w-5 text-gray-400 hover:text-gray-600"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>

        {/* Table Part */}
        <table className="min-w-full bg-white border border-gray-300 rounded-lg">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="p-3 text-left">#</th>
              <th className="p-3 text-left">Date</th>
              <th className="p-3 text-left">Part No</th>
              <th className="p-3 text-left">Quantity</th>
              <th className="p-3 text-left">Warehouse</th>
              <th className="p-3 text-left">Requested by</th>
              <th className="p-3 text-left">In Stock</th>
              <th className="p-3 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredParts?.length > 0 ? (
              filteredParts.map((parts, index) => {
                if (parts.maintenance_id === id) {
                  return (
                    <tr key={parts.id} className="border-b hover:bg-gray-100">
                      <td className="p-3 text-left">
                        <input
                          type="checkbox"
                          onChange={() => handleSelectPart(parts)}
                          checked={selectedParts.some((p) => p.id === parts.id)}
                        />
                      </td>
                      <td className="p-3 text-left">{parts.requested_date && formatDate(parts.requested_date)}</td>
                      <td className="p-3 text-left">{parts.part_no}</td>
                      <td className="p-3 text-left">
                        <input
                          type="number"
                          value={
                            selectedParts.find((p) => p.id === parts.id)?.request_quantity || parts.request_quantity
                          }
                          onChange={(e) => handleQuantityChange(parts.id, e.target.value)}
                          className="w-20 p-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          disabled={parts.is_approved ? true : false}
                        />
                      </td>
                      <td className="p-3 text-left">{parts.location || "N/A"}</td>
                      <td className="p-3 text-left">{person[0].name || "N/A"}</td>
                      <td className="p-3 text-left">{parts.in_stock_quantity || "N/A"}</td>
                      <td className="p-3 text-left">
                        <span
                          className={`px-2 py-1 text-xs font-semibold rounded-full ${parts.is_approved ? "bg-green-100 text-green-2" : "bg-red-200 text-red-1"
                            }`}
                        >
                          {parts.is_approved ? "Approved" : "Pending"}
                        </span>
                      </td>
                    </tr>
                  );
                }
                return null;
              })
            ) : (
              <tr>
                <td colSpan="8" className="text-center p-4">
                  No parts found.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Buttons */}
        <div className="flex justify-between">
          <button className="buttons bg-green-2 text-white p-2 rounded-md" onClick={() => setOpenAddNew(true)}>
            Add New
          </button>

          <button className="buttons bg-green-2 text-white p-2 rounded-md" onClick={handleHit}>
            Save
          </button>
        </div>
      </div>

      {/* Pop-up Form */}
      {openAddNew && (
        <>
          <AddNewPart person={person} id={id} setOpenAddNew={setOpenAddNew} />

          <div className="fixed top-0 bottom-0 left-0 right-0 bg-[#00000016] z-[0]" onClick={() => setOpenAddNew(false)} />
        </>
      )}
    </div>
  );
};

export default GetApprovalParts;
