import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import { useToast } from '../../../Context/ToastContext';
import { setBaseUrl } from '../../../config';
import debounce from 'lodash.debounce';

const AddNewPart = ({ person, id, setOpenAddNew }) => {
  const { addToast } = useToast();

  const [formData, setFormData] = useState({
    service_sale_person_id: person?.[0]?.id || '',
    part_no: '',
    quantity: '',
    maintenance_id: id
  });

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch inventory data based on input
  const fetchInventory = async (searchTerm) => {
    if (searchTerm.length < 3) return; // Fetch only if 3+ chars

    setIsLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/maintenance/get-all-inventory-part`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': sessionStorage.getItem('token'),
        },
      });
      const data = await res.json();

      const formattedOptions = data.map((item) => ({
        value: item.part_no,
        label: `${item.part_no} - ${item.part_name}`,
      }));
      setOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching inventory:', error);
      addToast('Failed to fetch inventory information.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // Debounced search for better performance
  const debouncedFetchInventory = useCallback(debounce(fetchInventory, 500), []);

  useEffect(() => {
    return () => debouncedFetchInventory.cancel(); // Cleanup on unmount
  }, [debouncedFetchInventory]);

  const handleInputChange = (inputValue) => {
    debouncedFetchInventory(inputValue);
  };

  const handlePartSelect = (selectedOption) => {
    setFormData({ ...formData, part_no: selectedOption?.value || '' });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${setBaseUrl}/maintenance/assign-part`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': sessionStorage.getItem('token'),
        },
        body: JSON.stringify([
          {
            quantity: Number(formData.quantity),
            part_no: formData.part_no,
            service_sale_person_id: Number(formData.service_sale_person_id),
            maintenance_id: id,
          },
        ]),
      });
  
      if (res.ok) {
        addToast('Part successfully added!', 'success');
        setOpenAddNew(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const errorData = await res.json();
        addToast(`Error: ${errorData.message || 'Something went wrong'}`, 'error');
      }
    } catch (error) {
      console.error('Request failed:', error);
      addToast('Adding part failed.', 'error');
    }
  };

  return (
    <div className="w-[450px] bg-white fixed z-[9999] rounded-[16px] top-[26%] left-[35%] p-6 shadow-lg">
      <h2 className="text-xl font-semibold text-center mb-4">Add New Part</h2>
      <form onSubmit={handleSubmit} className="space-y-3 py-2">

        <label className="block text-gray-700 font-medium">Select Service Person</label>
        <select
          value={formData.service_sale_person_id}
          onChange={(e) => setFormData({ ...formData, service_sale_person_id: e.target.value })}
          className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {person?.map((man) => (
            <option key={man.id} value={man.id}>{man.name}</option>
          ))}
        </select>

        <label className="block text-gray-700 font-medium">Part Number</label>
        <Select
          placeholder="Please Type 3 Characters to Search"
          onInputChange={handleInputChange}
          onChange={handlePartSelect}
          options={options}
          value={options.find((option) => option.value === formData.part_no)}
          isLoading={isLoading}
          noOptionsMessage={() => 'No parts found'}
          isClearable
          styles={{
            container: (provided) => ({ ...provided, width: '100%' }),
            control: (provided, state) => ({
              ...provided,
              backgroundColor: '#EEE',
              border: state.isFocused ? '1px solid #60A5FA' : '1px solid #D1D5DB',
              boxShadow: 'none',
            }),
          }}
        />

        <label className="block text-gray-700 font-medium">Quantity</label>
        <input
          type="number"
          name="quantity"
          min={0}
          value={formData.quantity}
          onChange={handleChange}
          required
          className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          type="submit"
          className="w-full bg-green-500 text-white px-2 py-3 rounded-md hover:bg-green-600 transition"
        >
          Add Part
        </button>
      </form>
    </div>
  );
};

export default AddNewPart;
