// import React, { useState } from 'react'
// import { setBaseUrl } from '../../../config'
// import { useToast } from '../../../Context/ToastContext'

// const RequestForClosure = ({ maintenance_id, setOpenClosure }) => {
//     const [form, setForm] = useState({
//         maintenance_id: maintenance_id,
//         accept: true
//     })

//     const { addToast } = useToast()

//     const handleSubmit = async () => {
//         try {

//             const res = await fetch(`${setBaseUrl}/maintenance/accept-closer-request`, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                     "x-access-tokens": sessionStorage.getItem("token"),
//                 },
//                 body: JSON.stringify(form),
//             },
//             )

//             if (res.status === 200) {
//                 addToast("Maintenance Successfully Closed!", "success")
//                 setTimeout(() => {
//                     window.location.reload();
//                   }, 2000);
//             }
//         } catch (error) {
//             console.error(error)
//             addToast("Maintenance failed to close", "error")
//         }
//     }

//     return (
//         <>
//             {/* Popup container */}
//             <div className="fixed z-[9999] bg-white flex flex-col  p-6 left-[30%] items-center gap-4 top-[20%] rounded-lg shadow-lg transition-all duration-300 transform">
//                 <h2 className="text-lg font-semibold text-gray-800">Request for Closure</h2>
//                 <span className="text-sm text-gray-600">
//                     Are you sure you want to request closure for maintenance?
//                 </span>

//                 <div className="flex justify-between w-full mt-4 gap-2">
//                     <button
//                         className="buttons bg-gray-100  border w-full"
//                         onClick={() => setOpenClosure(false)}
//                     >
//                         Cancel
//                     </button>
//                     <button
//                         className="buttons bg-white text-red-500 border border-red-500  w-full"
//                         onClick={handleSubmit}
//                     >
//                         Confirm
//                     </button>


//                 </div>
//             </div>

//             {/* Overlay Background */}
//             <div
//                 className="fixed z-0 top-0 bottom-0 left-0 right-0 bg-[#00000060]"
//                 onClick={() => setOpenClosure(false)}
//             />
//         </>
//     )
// }

// export default RequestForClosure
import React, { useState } from 'react';
import { setBaseUrl } from '../../../config';
import { useToast } from '../../../Context/ToastContext';

const RequestForClosure = ({ maintenance_id, setOpenClosure }) => {
    const [form, setForm] = useState({
        maintenance_id: maintenance_id,
        accept: true
    });
    const [confirmationStep, setConfirmationStep] = useState(1); // 1: First confirmation, 2: Final confirmation
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { addToast } = useToast();

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const res = await fetch(`${setBaseUrl}/maintenance/accept-closer-request`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-tokens": sessionStorage.getItem("token"),
                },
                body: JSON.stringify(form),
            });

            if (res.status === 200) {
                addToast("Maintenance successfully closed!", "success");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                const errorData = await res.json();
                addToast(errorData.message || "Failed to close maintenance", "error");
                setOpenClosure(false);
            }
        } catch (error) {
            console.error(error);
            addToast("An error occurred while closing maintenance", "error");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleConfirmation = () => {
        if (confirmationStep === 1) {
            setConfirmationStep(2);
        } else {
            handleSubmit();
        }
    };

    return (
        <>
            {/* Overlay Background */}
            <div 
                className="fixed inset-0 bg-black bg-opacity-50 z-[9998] backdrop-blur-sm transition-opacity"
                onClick={() => setOpenClosure(false)}
            />

            {/* Popup container */}
            <div className="fixed z-[9999] bg-white flex flex-col p-6 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-md rounded-xl shadow-xl transition-all duration-300 transform">
                <div className="flex flex-col items-center mb-4">
                    <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mb-3">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="h-6 w-6 text-red-500" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke="currentColor"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
                            />
                        </svg>
                    </div>
                    
                    <h2 className="text-xl font-semibold text-gray-800 text-center">
                        {confirmationStep === 1 
                            ? "Request Maintenance Closure" 
                            : "Final Confirmation"}
                    </h2>
                    
                    <p className="text-sm text-gray-600 mt-2 text-center">
                        {confirmationStep === 1 
                            ? "Are you sure you want to request closure for this maintenance?"
                            : "This action cannot be undone. Are you absolutely sure you want to close this maintenance?"}
                    </p>
                </div>

                <div className="flex justify-between w-full mt-6 gap-3">
                    <button
                        className="flex-1 py-2 px-4 border border-gray-300 rounded-lg text-gray-700 font-medium hover:bg-gray-50 transition-colors"
                        onClick={() => confirmationStep === 1 ? setOpenClosure(false) : setConfirmationStep(1)}
                        disabled={isSubmitting}
                    >
                        {confirmationStep === 1 ? "Cancel" : "Go Back"}
                    </button>
                    
                    <button
                        className={`flex-1 py-2 px-4 rounded-lg font-medium transition-colors ${
                            confirmationStep === 1 
                                ? "bg-blue-600 text-white hover:bg-blue-700"
                                : "bg-red-600 text-white hover:bg-red-700"
                        }`}
                        onClick={handleConfirmation}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <span className="flex items-center justify-center">
                                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </span>
                        ) : (
                            confirmationStep === 1 ? "Close" : "Confirm Closure"
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default RequestForClosure;