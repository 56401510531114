import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setBaseUrl } from "../config";
import OuterLoader from "./OuterLoder";
import Layout from "../components/Layout";

export const ChangePassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [creds, setCreds] = useState({ email: "", new_password: "" });

  const handleSubmit = async () => {
    try {
      setLoader(true);
      const { data } = await axios.post(`${setBaseUrl}/change-password`, {
        email: creds.email,
        password: creds.new_password,
      });
      sessionStorage.clear();

      navigate("/successful");
    } catch (error) {
      console.error(error);
      setLoader(false);
      alert(error.response.data.message);
    }
  };

  if (loader) {
    return <OuterLoader />;
  } else {
    return (
      <>
        <Layout>
          <div className="h-full w-full flex flex-col justify-center gap-3 items-center mt-[10vh]">
            <h1 className="text-lg items-center text-center font-extrabold text-dark-6">
              Change Password
            </h1>
            <div class="intro-y mx-10 overflow-auto lg:overflow-visible mt-8 sm:mt-0">
              <div className="flex flex-col gap-4 justify-center items-center">
                <label for="email" className="text-white">
                  Email:
                </label>
                <input
                  title="enter you email address"
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  className="p-2 mx-4 rounded-md text-center"
                  onChange={(e) =>
                    setCreds({ ...creds, email: e.target.value })
                  }
                />
                <label for="name" className="text-white">
                  Enter Old Password:
                </label>
                <input
                  title="please add your old password"
                  type="password"
                  name="name"
                  required
                  placeholder="Old Password"
                  className="p-2 mx-4 rounded-md text-center"
                />
                <label for="name" className="text-white">
                  Enter New Password:
                </label>
                <input
                  title="please add new password"
                  type="password"
                  name="name"
                  required
                  placeholder="Enter New Password"
                  className="p-2 mx-4 rounded-md text-center"
                  onChange={(e) =>
                    setCreds({ ...creds, new_password: e.target.value })
                  }
                />

                <div className="flex items-center gap-5">
                  <button
                    className="buttons bg-white text-black border"
                    title="return to the last page"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    title="submit to update your password"
                    className="buttons bg-blue-2 text-white"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
};
