import React, { useState } from 'react'
import { IoCloseCircle } from "react-icons/io5";

const GlobalSearch = () => {
    const [open, setOpen] = useState(false)
    // const [input, setInput] = useState(null)
    const [selectCategory, setSelectCategory] = useState(null)

    const AssetCategory = ['All Categories', 'Asset Number', 'Category', 'YOM']
  return (
    <div className=' flex justify-center  h-fit items-center w-fit   bg-white dark:bg-opacity-60  rounded-2xl '>
      {selectCategory && (
        <>
        <span className='flex items-center justify-center text-xs bg-gray-500 mx-1 px-2 gap-1 rounded  text-white'>
            {selectCategory}
            <IoCloseCircle onClick={() => setSelectCategory(null)}/>
        </span>
        </>
      )}
      <button className="w-4 h-7  items-center ml-2 text-center dark:placeholder:text-white">
            <svg className="w-4 h-4  text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
      </button>
      <input 
        type="search" 
        className="
        text-xs
        placeholder:text-xs
        focus:outline-none 
        focus:border-none
        focus:ring-0 
        bg-transparent 
        border-none h-7" 
        placeholder='Search' 
        onChange={(e) => {setSelectCategory(e.target.value);}}
        onMouseEnter={() => setOpen(true)}
       
        />
        {open && 
        
        <div className={`rounded  shadow-xl border-black flex justify-between px-5 pt-2 bg-white dark:bg-slate-800 mt-1 h-[200px] w-[200px] z-50 top-8 absolute`} onMouseOver={() => setOpen(true)} onMouseLeave={()=> setOpen(false)}>
                <div className="flex flex-col text-sm ">
                    <h1 className="font-bold">Select Category</h1>
                    {AssetCategory.map((assets) => 
                    <>
                    <span className="text-xs text-left mt-1 hover:underline" key={assets} onClick={()=>setSelectCategory(assets)}>{assets}</span>
                    </>)}
                </div>
                {/* <div className="">Item 2</div> */}
        </div>
        }
    </div>
  )
}

export default GlobalSearch

