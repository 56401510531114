import React, { useState } from "react";

const PhotoData = ({ imageUrl, NoImage, handleBottomTab, setPhotoDataTab }) => {
  const [openPhoto, setOpenPhoto] = useState(false);

  
const handlePhotoTabClick = () => {
  handleBottomTab(1);
  setPhotoDataTab(true);
};

  return (
    <>
      {/* Container */}
      <div className="flex flex-col items-center justify-center w-full ">
        {imageUrl && imageUrl.length > 0 ? (
          <>
            {/* Image Display */}
            <div className="flex items-center justify-center w-52 h-[190px] mt-4 border-gray-200 rounded-[2px] overflow-hidden">
              <img
                src={imageUrl[imageUrl.length - 1]?.image_uri || NoImage[0]?.imgUrl}
                alt="Device Images"
                className="object-contain w-full h-full cursor-pointer"
                onClick={handlePhotoTabClick}

              />
            </div>

          
          </>
        ) : (
          <>
            {/* Placeholder for No Image */}
            <div className="flex items-center justify-center w-52 h-52 border border-gray-200 rounded-lg bg-gray-50">
              <img
                src={NoImage[0]?.imgUrl}
                alt="No Image"
                className="object-contain w-full h-full"
              />
            </div>
          </>
        )}
      </div>

      {/* Modal for Viewing Image */}
      {openPhoto && (
        <>
          {/* Modal Overlay */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setOpenPhoto(false)}
          ></div>

          {/* Modal Content */}
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-6 z-50 max-w-md w-full">
            <div className="flex flex-col items-center space-y-4">
              {/* Full-size Image */}
              <div className="w-full h-96 overflow-hidden">
                <img
                  src={imageUrl[imageUrl.length - 1]?.image_uri || NoImage[0]?.imgUrl}
                  alt="Device Images"
                  className="object-contain w-full h-full"
                />
              </div>

              {/* Close Button */}
              <button
                onClick={() => setOpenPhoto(false)}
                className="bg-red-500 text-white font-semibold text-sm px-4 py-2 rounded hover:bg-red-600 transition"
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PhotoData;
