import moment from 'moment'
import React from 'react'

const SeeBookingDetails = ({assetNo, bookingDetails}) => {
  return (
    <div>
      {bookingDetails?.length > 0 ? (
  <div className="overflow-x-auto">
    <table className="w-full border text-[12px]">
      <thead className="bg-gray-200">
        <tr className="border-b">
        <th className="p-2">Sales Person</th>
          <th className="p-2">Customer</th>
          <th className="p-2">Location</th>
          <th className="p-2">Creation Date</th>
          <th className="p-2">Duration</th>
          <th className="p-2">Rate per Day</th>
          <th className="p-2">Approval Status</th>
        </tr>
      </thead>
      <tbody>
        {bookingDetails?.map((booking, index) => (
          <tr key={index} className="border-b">
            <td className="p-2">{booking.sale_person_name || "N/A"}</td>
            <td className="p-2">{booking.booking_customer_name}</td>
            <td className="p-2">{booking.booking_location}</td>
            <td className="p-2">{moment(booking.create_at).format("DD-MM-YYYY")}</td>
            <td className="p-2">{booking.duration_of_lease || "N/A"}</td>
            <td className="p-2">₹{booking.booking_estimated_rate_per_day}</td>
            <td className={`p-2 font-bold ${booking.is_approved ? "text-green-600" : "text-red-600"}`}>
              {booking.is_approved ? "Approved ✅" : "Pending ❌"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
) : (
  <p className="text-red-600 text-sm p-4">No booking details available.</p>
)}

    </div>
  )
}

export default SeeBookingDetails