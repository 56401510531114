import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
// import { LoaderContext } from "../../Context/LoaderContext";
import Loader from "../Loader";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, formatDateForInput } from "./AssetDetails/Tabs/FormatDate";

function UpdateOperator() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(null);
  const [isAadharValid, setIsAadharValid] = useState(null);
  const [operatorDetail, setOperatorDetail] = useState({
    aadhar_no: "",
    
    dob: "", //added Avijit
    blood_group: "", // avijit
    family: "", // avijit
    photo: "", //avijit
    emergency_mobile: "", //avijit
    bank_details: {
      account_no: "",
      id: "",
      ifsc_code: "",
    },
    id: "",
    date_list: {
      joining_date: "",
      leaving_date: "",
    },
    name: "",
    net_inhand_salary: "",
    odoo_employee_no: "",
    pf_account_no: "",
    phone: {
      id: "",
      phone_no: "",
      types: "+91",
    },
    lease_RSO_no: "",
    asset_no:""
  });
  const getOperatorDetail = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/operator/get_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      // console.log(data);
      setOperatorDetail(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handlePhone = (e) => {
    setOperatorDetail({
      ...operatorDetail,
      phone: {
        ...operatorDetail.phone,
        phone_no: e.target.value,
      },
    });
    let phoneDigitCount = e.target.value.length;
    if (phoneDigitCount === 10) {
      setIsPhoneValid(null);
    } else {
      setIsPhoneValid(
        "Phone number must contain 10 digits. you gave: >> " + phoneDigitCount
      );
    }
  };

  const handleAdhaar = (e) => {
    setOperatorDetail({
      ...operatorDetail,
      aadhar_no: e.target.value,
    });
    let aadhar_no_value = e.target.value;
    let aadhar_no_value_length = aadhar_no_value.length;
    var expr =
      /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
    if (expr.test(aadhar_no_value)) {
      setIsAadharValid(null);
    } else {
      setIsAadharValid(
        "Adhaar number must contain 12 digits. You gave>> " +
          aadhar_no_value_length
      );
    }
  };

  useEffect(() => {
    getOperatorDetail();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (isPhoneValid !== null) {
      alert("Please enter a valid phone number.");
      return;
    }
    if (isAadharValid !== null) {
      alert("Please enter a valid adhar number.");
      return;
    }
    try {
      const { data } = await axios.put(
        `${setBaseUrl}/operator/update`,
        operatorDetail,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(data);
      const response = data.data;
      navigate(`/operators/details/${response.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const  handleDOB = () => {

  } 

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
      
      <div id="update-operator" className="mb-20">
        <div class="flex text-xs">
          <button onClick={() => navigate(`/operators/details/${id}`)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="w-6 h-6 mr-6 cursor-pointer feather feather-arrow-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </button>
          <p class="inline py-5 pl-5 text-xs font-medium text-slate-700 dark:text-slate-200">
            Update Operator
          </p>
        </div>

        <div class="flex flex-col gap-6 md:flex-row ">
          <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
           
            <div class="p-2 text-xs">
              <div class="mt-4">
                <label class="">Name</label>
                <input
                  type="text"
                  value={operatorDetail?.name}
                  onChange={(e) =>
                    setOperatorDetail({
                      ...operatorDetail,
                      name: e.target.value,
                    })
                  }
                  placeholder="Op Name(eg: Me X)"
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>

              <div class="mt-4">
                <label class="">Mobile No.</label>
                <input
                  value={operatorDetail?.phone.phone_no}
                  onChange={handlePhone}
                  type="number"
                  placeholder="Mobile No."
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>
              {isPhoneValid !== null && (
                <p className="text-[#24a062] font-bold mt-4">{isPhoneValid}</p>
              )}
              <div class="mt-0">
                <label>Aadhaar no.</label>
                <input
                  value={operatorDetail?.aadhar_no}
                  onChange={handleAdhaar}
                  type="text"
                  placeholder="Aadhaar no."
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>
              {isAadharValid !== null && (
                <p className="text-[#24a062] font-bold mt-4">{isAadharValid}</p>
              )}
{/* DOB */}
              <div class="mt-4">
                <label>Date of Birth</label>
                <input
                  value={operatorDetail?.dob}
                  onChange={handleDOB}
                  type="date"
                 
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />

                
              </div>

              {/* Blood Group */}
              <div class="mt-4">
                  <label class="">Blood Group</label>
                  <select
                    onChange={(e) =>
                      setOperatorDetail({
                        ...operatorDetail,
                        blood_group: e.target.value,
                      })
                    }
                    type="text"
                    placeholder=""
                    class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                  >
                    <option>A+</option>
                    <option>A-</option>
                    <option>B+</option>
                    <option>B-</option>
                    <option>O+</option>
                    <option>O-</option>
                    <option>AB+</option>
                  </select>
                </div>

              {/* Family */}
              <div class="mt-4">
                <label>Emergency Member Name</label>
                <input
                  value={operatorDetail?.family}
                  onChange={(e) => setOperatorDetail({ ...operatorDetail, family:e.target.value})}
                  type="text"
                 placeholder="name"
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>
              {/* Emergency Mobile No */}
              <div class="mt-4">
                <label>Emergency Mobile No</label>
                <input
                  value={operatorDetail?.emergency_mobile}
                  onChange={(e) => setOperatorDetail({ ...operatorDetail, emergency_mobile:e.target.value})}
                  type="number"
                 placeholder="Mobile No"
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>
              {/* photo */}
              <div class="mt-4">
                  <label class="">Add Profile Photo</label>
                  <input
                    onChange={(e) =>
                      setOperatorDetail({
                        ...operatorDetail,
                        photo: e.target.value,
                      })
                    }
                    value={operatorDetail?.photo}
                    type="file"
                    placeholder="image"
                    class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                  />
                </div>
              {/* Odoo Employee No */}
              <div class="mt-4">
                <label class="">Odoo Employee No.</label>
                <input
                  value={operatorDetail?.odoo_employee_no}
                  onChange={(e) =>
                    setOperatorDetail({
                      ...operatorDetail,
                      odoo_employee_no: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Odoo employee number"
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>
            </div>
          </div>
          <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
            
            <div class="p-5 text-xs">
              <div class="mt-4">
                <label class="">Bank Account No.</label>
                <input
                  value={operatorDetail?.bank_details.account_no}
                  onChange={(e) =>
                    setOperatorDetail({
                      ...operatorDetail,
                      bank_details: {
                        ...operatorDetail.bank_details,
                        account_no: e.target.value,
                      },
                    })
                  }
                  type="text"
                  placeholder="Bank account no."
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>

              <div class="mt-4 ">
                <label class="">Bank IFSC Code</label>
                <input
                  value={operatorDetail?.bank_details.ifsc_code}
                  onChange={(e) =>
                    setOperatorDetail({
                      ...operatorDetail,
                      bank_details: {
                        ...operatorDetail.bank_details,
                        ifsc_code: e.target.value,
                      },
                    })
                  }
                  type="text"
                  placeholder="Bank IFSC Code"
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>
              <div class="mt-4">
                <label class="">Pf Account No.</label>
                <input
                  value={operatorDetail?.pf_account_no}
                  onChange={(e) =>
                    setOperatorDetail({
                      ...operatorDetail,
                      pf_account_no: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="PF Account No."
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>
            </div>
          </div>
          <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
           
            <div class="p-5 text-sm">
              <div class="mt-0">
                <label>Date of Joining</label>
                <input
                  type="date"
                  id="dateInput"
                  value={formatDateForInput(
                    operatorDetail?.date_list[0]?.joining_date
                  )}
                  onChange={(e) =>
                    setOperatorDetail({
                      ...operatorDetail,
                      date_list: { joining_date: e.target.value },
                    })
                  }
                  placeholder="07-03-2021"
                  class="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>

              <div class="mt-4">
                <label class="">Leaving Date</label>
                <input
                  value={operatorDetail?.date_list[0]?.leaving_date}
                  onChange={(e) =>
                    setOperatorDetail({
                      ...operatorDetail,
                      date_list: { leaving_date: e.target.value },
                    })
                  }
                  type="date"
                  placeholder="PF Account No."
                  className="input w-full border mt-2 p-2 text-gray-500 border-slate-300 dark:bg-slate-900 font-medium rounded-md text-xs"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
        <div class="flex mt-5 border bg-white fixed bottom-0 left-0 right-0 h-12 items-center justify-end text-xs gap-3">
          <button
            onClick={() => navigate(`/operators/details/${id}`)}
            className="border w-[67px] h-7 border-light-1 text-light-1 rounded ml-16"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            class="bg-light-1 text-white w-[67px] h-7 rounded mr-6"
          >
            Save
          </button>
        </div>
      </>
    );
  }
}

export default UpdateOperator;
