import React, { useState, useEffect } from 'react'
import { setBaseUrl } from '../../config';
import { formatDate } from '../../customFunctions/FormatDate';
// import { capitalizeFirstLetter } from '../Modals/AssetDetails/Tabs/FormatDate';

const ServiceAsset = ({ assetID, maintenanceData }) => {
  const [loading, setLoading] = useState(false)
  let assetDetails = maintenanceData.asset


  // console.log(maintenanceData)






  let numb = 0
  if (assetDetails?.lease_status?.startsWith("expiring")) {
    // Match a number within the string "expiring in X days"
    const match = assetDetails?.lease_status.match(/\d+/);

    if (match) {
      numb = parseInt(match[0], 10);
    }
  }



  return (
    <div>
      {/* 
           Asset No
           Make
           */}
      {/* Mode No, Serial NO */}
      {/* Category, YOM */}
      {/* Lease Status, Height-Capacity */}
      {/* Customer, Rental End Date */}
      {/* Operator Sales Person */}


      {/* {loading ? "Loading..." : <> */}
      <div className="items-center overflow-hidden text-justify text-xs w-[65vw] gap-4 mt-2 rounded pb-1 flex justify-between max-md:flex-col">
        {/* Frame 1 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full   text-[10px] h-[100px] rounded-[6px]">
          <div title={`Machine No: ${assetDetails?.asset_no || "N/A"}`} className="py-2">
            <span className="text-gray-500">Asset No.</span>

            <span
              className={`block font-semibold text-xs overflow-hidden ${assetDetails.device_no ? "text-green-1 font-extrabold" : ""
                }`}
            >
              {assetDetails?.asset_no || "Not Filled"}
            </span>

          </div>
          <div title={`Make: ${assetDetails?.make || "N/A"}`} className="py-2 ">
            <span className="text-gray-500">Make</span>

            <span className="block font-semibold text-xs">
              {assetDetails?.make || "N/A"}
            </span>

          </div>
        </div>

        {/* Frame 2 */}
        <div className="bg-white overflow-hidden border py-0 px-3 w-full   text-[10px] h-[100px] rounded-[6px]">
          <div title={`Model No: ${assetDetails?.model || "N/A"}`} className="py-2">
            <span className="text-gray-500">Model No.</span>

            <span className="block font-semibold text-xs">
              {assetDetails?.model || "N/A"}
            </span>

          </div>
          {/* Height-Weight */}
          <div title={`Height-Capacity: ${assetDetails?.capacity || "N/A"}kg - ${assetDetails?.hieght_machine || "N/A"
            }ft`} className="py-2">
            <span className="t">Height-Capacity</span>

            <span className="block font-semibold text-xs">
              {assetDetails?.capacity || 'N/A'}kg - {assetDetails?.hieght_machine || 'N/A'}
              ft
            </span>

          </div>

        </div>

        {/* Frame 3 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full   text-[10px] h-[100px] rounded-[6px]">
          <div title={`Category`} className="py-2">
            <span className="text-gray-500">Category</span>

            <span className="block font-semibold text-xs">
              {assetDetails?.category ? (
                <>
                  {assetDetails?.category === "nill"
                    ? "N/A"
                    : assetDetails?.category || "N/A"}
                </>
              ) : (
                "N/A"
              )
              }
            </span>

          </div>
          <div title={`Year of Manufacturing: ${assetDetails?.yom || "N/A"}`} className="py-2">
            <span className="text-gray-500">Year of Manufacturing</span>

            <span className="block font-semibold text-xs">
              {assetDetails?.yom || "N/A"}
            </span>

          </div>
        </div>

        {/* Frame 4 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full   text-[10px] h-[100px] rounded-[6px]">
          <div title={`Lease Status: ${(
            assetDetails?.lease_status || "N/A"
          )}`} className="py-2">
            <span className="text-gray-500">Lease Status</span>

            <span


              className={`block font-semibold text-xs ${assetDetails?.lease_status === "inactive"
                ? "text-slate-600"
                : assetDetails?.lease_status === "active"
                  ? "text-green-500"
                  : assetDetails?.lease_status === "expired"
                    ? "text-red-600"
                    : "text-blue-600"
                }`}
            >
              {assetDetails?.lease_status === "inactive" || assetDetails?.lease_status === "never assigned" ? "Free" : assetDetails?.lease_status === "expired" ? "Exceeded" : assetDetails?.lease_status?.startsWith(
                "expiring"
              ) ? `Active ${numb} days` : "Active"}
            </span>

          </div>
          {/* Location */}
          <div title={`Serial No: ${maintenanceData?.lease_location || "N/A"}`} className="py-2">
            <span className="text-gray-500">Location</span>

            <span className="block font-semibold  text-xs">
              {maintenanceData?.lease_location || "N/A"}
            </span>

          </div>


        </div>
        {/* Frame 5 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full   text-[10px] h-[100px] rounded-[6px]">
          <div title={`Customer`} className="pt-2">
            <span className="text-gray-500">Customer</span>

            <span className="block font-semibold text-[10px] overflow-hidden">
              {maintenanceData?.lease_customer ? (
                <>
                  {maintenanceData?.lease_customer === "nill"
                    ? "N/A"
                    : maintenanceData?.lease_customer?.split("-")[0]}
                </>
              ) : (
                "N/A"
              )
              }
            </span>

          </div>
          {/* Sales Person */}
          <div title={`Rental End Date: ${formatDate(maintenanceData?.lease_end_date) || "N/A"}`} className="py-1">
            <span className="text-gray-500">Rental End Date</span>

            <span className="block font-semibold text-xs">
              {formatDate(maintenanceData?.lease_end_date) || 'Not Filled'}
            </span>

          </div>
        </div>

        {/* Frame 6 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full   text-[10px] h-[100px] rounded-[6px]">
          <div title={`Operator`} className="py-2">
            <span className="text-gray-500">Operator</span>

            <span className="block font-semibold text-xs">
              {assetDetails?.operator ? (
                <>
                  {assetDetails?.operator === "nill"
                    ? "N/A"
                    : assetDetails?.operator}
                </>
              ) : (
                "N/A"
              )
              }
            </span>

          </div>
          {/* Sales Person */}
          <div title={`Year of Manufacturing: ${maintenanceData?.lease_sale_person || "N/A"}`} className="py-2">
            <span className="text-gray-500">Sales Person</span>

            <span className="block font-semibold text-xs">
              {maintenanceData?.lease_sale_person && !maintenanceData?.lease_sale_person === null  && maintenanceData?.lease_sale_person  || 'Not Filled'}
            </span>

          </div>
        </div>


      </div>
      {/* </>} */}
    </div>
  )
}

export default ServiceAsset