import axios from "axios";
import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { BACKEND_LOCATION } from "../config";
import Layout from "./Layout";
// import Alert from "react-bootstrap/Alert";
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import './signup.css'
import DurbinLogo from "../assets/images/DurbinLogo.jpg"

const Signup = () => {

  
  const formData = new FormData();
  const navigation = useNavigate();  
  const [alert, setAlert] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  const [phone, setPhone] = useState();
  const [data, setdata] = React.useState({
    name: "",
    password1: "",
    password2: "",
    email: "",
    company_name: "",
  });
  const submitForSignup = () => {}
  // const submitForSignup = async () => {
  //   if (
  //     data.name !== "" &&
  //     data.password1 !== "" &&
  //     data.password2 !== "" &&
  //     data.email !== "" &&
  //     data.company_name !== ""
  //   ) {
  //     if (data.password1 === data.password2) {
  //       formData.append("name", data.name);
  //       formData.append("password", data.password1);
  //       formData.append("email", data.email);
  //       formData.append("phone", phone);
  //       formData.append("company_name", data.company_name);

        
  //       try {
  //         const resp = await axios.post(
  //           `${BACKEND_LOCATION}admin/signup`,
  //           formData
  //         );
  //         if (resp.status === 200) {
  //           console.log(resp);
  //           navigation("/login");
  //           localStorage.setItem("user_id", resp.data.msg);
  //         }
  //       } catch (error) {
          
  //         setErrmsg(error.message)
  //         setAlert(true)
  //       }
  //     }
  //     else {
  //       alert("Password does not match!")
  //     }
  //   }
  // };
  return (
    // <Layout>
    //   <div className="signupfrom">
    //     <div className="d-flex justify-content-around name-company">
    //       <input
    //         type="text"
    //         name="name"
    //         placeholder="Enter Name"
    //         onChange={(e) => {
    //           setdata({ ...data, name: e.target.value });
    //         }}
    //       />
    //       <input
    //         type="email"
    //         name="email"
    //         placeholder="Email"
    //         onChange={(e) => {
    //           setdata({ ...data, email: e.target.value });
    //         }}
    //       />
    //     </div>
    //     <div className="d-flex justify-content-around align-items-center name-company">
    //     {/* <div 
    //     id="phoneInput">
    //     <PhoneInput
    //      placeholder="Enter phone number"
    //      value={phone}
    //      onChange={setPhone}/>
    //      </div> */}
    //       <input
    //         id="company_name"
    //         type="text"
    //         name="company_name"
    //         placeholder="Company Name"
    //         onChange={(e) => {
    //           setdata({ ...data, company_name: e.target.value });
    //         }}
    //       />
    //     </div>
    //     <div className="d-flex justify-content-around name-company">
    //       <input
    //         type="password"
    //         name="password"
    //         placeholder="Password"
    //         onChange={(e) => {
    //           setdata({ ...data, password1: e.target.value });
    //         }}
    //       />
    //       <input
    //         type="password"
    //         name="password"
    //         placeholder="Confirm Password"
    //         onChange={(e) => {
    //           setdata({ ...data, password2: e.target.value });
    //         }}
    //       />
    //     </div>
    //     <button type="submit" className="login-btn" onClick={submitForSignup}>
    //       Sign Up
    //     </button>
    //   </div>
    //   {/* {alert && (
    //     <Alert variant="danger" onClose={() => setAlert(false)} dismissible>
    //       {errmsg}
    //     </Alert>
    //   )} */}
    //   <Link to="/login">
      
    //     Already have an account?  <span  className="text-blue-500 font-sans  ">  Login here...</span>
        
    //   </Link>
    // </Layout>

    <>
    <Layout>
    <section class="bg-gray-50 dark:bg-gray-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img class="w-8 h-8 mr-2" src={DurbinLogo} alt="logo"/>
          Durbin Live
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Create and account
              </h1>
              <form class="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required=""/>
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
                  </div>
                  <div>
                      <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                      <input type="confirm-password" name="confirm-password" id="confirm-password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""/>
                  </div>
                  <div class="flex items-start">
                      <div class="flex items-center h-5">
                        <input id="terms" aria-describedby="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required=""/>
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="terms" class="font-light text-gray-500 dark:text-gray-300">I accept the <a class="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>
                      </div>
                  </div>
                  <button type="submit" class="w-full bg-blue-700 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create an account</button>
                  <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      Already have an account? <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</a>
                  </p>
              </form>
          </div>
      </div>
  </div>
</section>
    </Layout>
    </>
  );
};

export default Signup;
