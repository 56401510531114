import React, { useState } from "react";
import { currency, currencyInLakhs } from "./FormatDate";

const AssetDashboards = ({ commercialDetails }) => {
  const { time_by_month, total_cost, total_cost_by_month, total_time } =
    commercialDetails?.odoo_cost || {};
  let months = Object.keys(time_by_month || '');

  const monthMap = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };


  // Extract unique years and months
  const uniqueYears = [...new Set(months?.map((date) => date?.split("-")[0]))];
  const [selectedYear, setSelectedYear] = useState(uniqueYears[0]);

  const handleYearSelection = (year) => {
    setSelectedYear(year);
  };

  const calculateYearTotals = (year) => {
    let totalNormal = 0;
    let totalOvertime = 0;
    let totalAmount = 0;
    let totalNormalHours = 0;
    let totalOvertimeHours = 0;
    let totalTime = 0;


    months
      .filter((month) => month?.startsWith(year))
      .forEach((month) => {
        totalNormal += total_cost_by_month[month][0];
        totalOvertime += total_cost_by_month[month][1];
        totalAmount += total_cost_by_month[month][0] + total_cost_by_month[month][1];
        totalNormalHours += time_by_month[month][0]
        totalOvertimeHours += time_by_month[month][1]
        totalTime += time_by_month[month][0] + time_by_month[month][1]
      });

    return {
      totalNormal,
      totalOvertime,
      totalAmount,
      totalNormalHours,
      totalOvertimeHours,
      totalTime
    };
  };

  return (
    <div className="md:w-full   text-xs pl-4 shadow-md pb-4 border rounded-[6px] bg-white">
      <h1 className="text-[14px]  font-bold text-[#7e7e7e] my-4 pl-2">
       Revenue Generated
      </h1>

      {/* Year Buttons */}
      <div className="flex p-2 gap-4">
        {uniqueYears?.map((year) => (
          <button
            key={year}
            className={`h-7 w-[67px] rounded-[2px] ${
              selectedYear === year
                ? "bg-light-3 text-white"
                : "bg-light-4 text-light-5"
            }`}
            onClick={() => handleYearSelection(year)}
          >
            {year}
          </button>
        ))}
      </div>

      <div className="mt-2" />
      {/* Display Tables for Selected Year */}
      {uniqueYears?.map((year) => {
        const yearTotals = calculateYearTotals(year);
        return (
          <>
         
          <div key={year} className="overflow-x-auto  w-[89vw] hide-scrollbar text-[10px]
                          [&::-webkit-scrollbar]:w-2
                [&::-webkit-scrollbar-track]:rounded-[0]
                [&::-webkit-scrollbar-track]:bg-white
                [&::-webkit-scrollbar-thumb]:rounded-[5px]
                [&::-webkit-scrollbar-thumb]:bg-[#EEE]
                [&::-webkit-scrollbar-thumb]:mt-6
                dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500
          ">
            {selectedYear === year && (
              <table className="min-w-max w-full table-auto 
              
              ">
                {/* Table Header (Months) */}
                <thead>
                  <tr>
                    <th className="p-2 border-b w-[140px] bg-[#c9c6c6] sticky left-0 z-10 ">
                      Details
                    </th>
                    {months?.filter((month) => month?.startsWith(year))
                      .map((month) => {
                        const monthKey = month?.split("-")[1];
                        const monthName = monthMap[monthKey];


                        return (
                          <th
                            key={month}
                            className="p-2 border-b bg-[#c9c6c6] text-left"
                          >
                            {monthName}
                          </th>
                        );
                      })}
                    <th className="p-2 border-b bg-[#c9c6c6] sticky right-0 z-10 ">
                      Total
                    </th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {/* Normal Cost */}
                  <tr>
                    <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10 ">
                      Normal Amount (₹)
                    </td>
                    {months?.filter((month) => month.startsWith(year))?.map((month) => (
                        <td key={month} className="p-2 border-b">
                          {parseFloat(currencyInLakhs(total_cost_by_month[month][0])).toFixed(2)} 
                          {/* {total_cost_by_month[month][0] >= 100000 ? "Lakhs" : total_cost_by_month[month][0] >= 1000 ? 'k' : ''} */}
                          k
                        </td>
                      ))}
                    <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">
                      {parseFloat(currencyInLakhs(yearTotals.totalNormal)).toFixed(2)}
                      {/* {yearTotals.totalNormal >= 100000 ? "Lakhs" : yearTotals.totalNormal >= 1000 ? 'k' : ''} */}
                      k
                    </td>
                  </tr>

                  {/* Overtime Cost */}
                  <tr>
                    <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10 bg-">
                      Overtime Amount(₹)
                    </td> 
                    {months?.filter((month) => month.startsWith(year))?.map((month) => (
                        <td key={month} className="p-2 border-b">
                          {parseFloat(currencyInLakhs(total_cost_by_month[month][1])).toFixed(2)}
                          {/* {total_cost_by_month[month][1] >= 100000 ? "Lakhs" : total_cost_by_month[month][1] >= 1000 ? 'k' : ''} */} k
                        </td>
                      ))}
                    <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">
                      {parseFloat(currencyInLakhs(yearTotals.totalOvertime)).toFixed(2)}
                      {/* {yearTotals.totalOvertime >= 100000 ? "Lakhs" : yearTotals.totalOvertime >= 1000 ? 'k' : ''} */}
                      k
                    </td>
                  </tr>

                  {/* Total Cost */}
                  <tr>
                    <td className="p-2 border-b font-semibold bg-[#EEE] text-[#3671d9] sticky left-0 z-10 bg-">
                      Total Amount(₹)
                    </td>
                    {months
                      .filter((month) => month.startsWith(year))
                      .map((month) => (
                        <td key={month} className="p-2 border-b">
                          {parseFloat(currencyInLakhs(
                            (
                              total_cost_by_month[month][0] + total_cost_by_month[month][1]
                            )
                          )).toFixed(2)}
                           {/* {   total_cost_by_month[month][0] + total_cost_by_month[month][1] >= 100000 ? "Lakhs" :     total_cost_by_month[month][0] + total_cost_by_month[month][1] >= 1000 ? 'k' : ''}  */} k
                        </td>
                      ))}
                    <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">
                      {parseFloat(currencyInLakhs(yearTotals.totalAmount)).toFixed(2)}
                      {/* {yearTotals.totalAmount >= 100000 ? "Lakhs" : yearTotals.totalAmount >= 1000 ? 'k' : ''} */} k
                    </td>
                  </tr>

                    {/* Normal Time */}
                    <tr>
                              <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10">Normal Time(hrs)</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">
                                    {time_by_month[month][0].toFixed(2)} 
                                  </td>
                                ))}
                              <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">{yearTotals.totalNormalHours}{" "}</td>
                            </tr>

                            {/* Overtime */}
                            <tr>
                              <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10">Overtime(hrs)</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">
                                    {time_by_month[month][1].toFixed(2)} 
                                  </td>
                                ))}
                              <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">{yearTotals.totalOvertimeHours}{" "}</td>
                            </tr>

                            {/* Total Time */}
                            <tr>
                              <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10 text-[#3671d9]">Total Time(hrs)</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">
                                    {(time_by_month[month][0] + time_by_month[month][1]).toFixed(2)} 
                                  </td>
                                ))}
                              <td className="p-2 border-b sticky right-0 z-10 bg-[#ebe9e9]">{yearTotals.totalTime.toFixed(2)}{" "}</td>
                            </tr>

                            {/* Spares */}
                            <tr>
                              <td className="p-2 border-b font-semibold bg-[#EEE] sticky left-0 z-10">Spares</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">--</td>
                                ))}
                              <td className="p-2 border-b  sticky right-0 z-10 bg-[#ebe9e9]">--</td>
                            </tr>

                            {/* Transportation Cost */}
                            <tr>
                              <td className="p-2  font-semibold bg-[#EEE] sticky left-0 z-10 rounded-[0]-bl-2xl">Transportation Cost</td>
                              {months
                                .filter((month) => month.startsWith(year))
                                .map((month) => (
                                  <td key={month} className="p-2 border-b">--</td>
                                ))}
                              <td className="p-2  sticky right-0 z-10 bg-[#ebe9e9] rounded-[0]-br-2xl ">--</td>
                            </tr>
                </tbody>
              </table>
            )}
          </div>

          </>
        );
      })}


    </div>




  );
};

export default AssetDashboards;
