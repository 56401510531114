import React, { useEffect, useState } from 'react';
import { setBaseUrl } from '../../config';
import { formatDate } from '../../customFunctions/FormatDate';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../Context/ToastContext';
import ServiceErrorPage from '../../pages/SalesService/SeviceErrorPage';
import { FiArrowLeft, FiRefreshCw } from 'react-icons/fi';
import { TailSpin } from 'react-loader-spinner';

const ServiceWallet = ({ id }) => {
  const [wallet, setWallet] = useState(null);
  const [loading, setLoading] = useState(true); // Start with true since we load immediately
  const [error, setError] = useState(null);
  const { addToast } = useToast();
  const token = sessionStorage.getItem("sales_token");
  const navigate = useNavigate();

  if (!token) {
    navigate("/sale-service/login");
  }

  // Get Wallet Details
  const getDetails = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/sale-service/get-own-wallet`, {
        headers: {
          "auth-token": token,
        },
      });

      const data = await res.json();

      if (res.status === 200) {
        setWallet(data);
        addToast("Wallet successfully fetched!", "success");
      } else {
        throw new Error(data.message || "Failed to fetch wallet details");
      }
    } catch (error) {
      console.error(error);
      addToast(error.message || "Failed to fetch the wallet details!", "error");
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Only call if not already loaded and no error
    if (wallet === null && !error) {
      getDetails();
    }
  }, []); // Empty dependency array ensures it only runs once

  if (error) {
    return <ServiceErrorPage />;
  }

  return (
    <div className="fixed inset-0  flex items-center justify-center  p-4">
      <div className="bg-white rounded-xl border w-full max-w-4xl max-h-[80vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold text-gray-800">Service Wallet</h2>
          <div className="flex space-x-2">
            <button 
              onClick={getDetails}
              disabled={loading}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              title="Refresh"
            >
              <FiRefreshCw className={`w-5 h-5 text-gray-600 ${loading ? 'animate-spin' : ''}`} />
            </button>
            <button 
              onClick={() => navigate(-1)}
              className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              title="Close"
            >
              <FiArrowLeft className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="overflow-auto flex-1">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <TailSpin color="#3B82F6" height={50} width={50} />
            </div>
          ) : wallet === null || wallet.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-64 text-gray-500">
              <svg className="w-16 h-16 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p className="text-lg">No wallet data found</p>
              <button 
                onClick={getDetails}
                className="mt-4 px-4 py-2 bg-blue-100 text-blue-600 rounded-md hover:bg-blue-200 transition-colors"
              >
                Try Again
              </button>
            </div>
          ) : (
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">Part No</th>
                    <th scope="col" className="px-6 py-3">Quantity</th>
                    <th scope="col" className="px-6 py-3">Requested Date</th>
                  </tr>
                </thead>
                <tbody>
                  {wallet.map((item, index) => (
                    <tr key={index} className="bg-white border-b hover:bg-gray-50">
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        {item.part_no || "N/A"}
                      </td>
                      <td className="px-6 py-4">
                        {item.request_quantity || "N/A"}
                      </td>
                      <td className="px-6 py-4">
                        {formatDate(item.requested_date)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="p-4 border-t text-sm text-gray-500">
          Showing {wallet?.length || 0} items
        </div>
      </div>
    </div>
  );
};

export default ServiceWallet;