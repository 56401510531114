import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import Loader from "../Loader";
import { LoaderContext } from "../../Context/LoaderContext";
import { PermissionContext } from "../../Context/PermissionsContext";
import { useNavigate, useParams } from "react-router-dom";
import ifscIco from "../../components/OperatorDetails/assets/ifscIco.svg";
import odooIco from "../../components/OperatorDetails/assets/adhaarIcon.svg";
import adhaarIco from "../../components/OperatorDetails/assets/adhaarIcon2.svg";
import joiningIco from "../../components/OperatorDetails/assets/joiningIcon.svg";
import moneyIco from "../../components/OperatorDetails/assets/moneyIco.svg";
import phoneIco from "../../components/OperatorDetails/assets/phoneIcon.svg";
import profileIco from "../../components/OperatorDetails/assets/profileIco.svg";
import bankAcIco from "../../components/OperatorDetails/assets/bankAcIco.svg";
import pfAcIco from "../../components/OperatorDetails/assets/pfIco.svg";
import CardForDetails from "./CardForDetails";
import {
  currency,
  formatAadhaar,
  formatDate,
} from "../Modals/AssetDetails/Tabs/FormatDate";

function OperatorDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [perms, setPerms] = useContext(PermissionContext);
  const [operatorDetail, setOperatorDetail] = useState({});
  const loader = useContext(LoaderContext)[0];
  const setLoader = useContext(LoaderContext)[1];
  const [reload, setReload] = useState(false);
  const [history, setHistory] = useState(false);
  const getOperatorDetail = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/operator/get_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      // console.log(data);
      setOperatorDetail(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getOperatorDetail();
  }, []);

  // console.log(formatDate(operatorDetail?.date_list[0]?.joining_date));

const [openLogData, setOpenLogData] = useState(false)
const [logData, setLogData] = useState([]);
const [loading, setLoading] = useState(false);

const getUserLogData = async () => {
  setLoading(true);
  try {
    const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
        "operator_id": id,
      },
    });
    const data = await res.json();
    setLogData(data);
  } catch (error) {
    console.error("Error fetching log data:", error);
  } finally {
    setLoading(false);
  }
};

const handleLog = () => {
  setOpenLogData(true)
  getUserLogData()
}



  if (loader) {
    return <Loader />;
  } else {
    return (
      // <div id="operator-details" className="">
      //   <div className="flex border  fixed bottom-0 left-0 right-0 items-center h-12">

      //     <div class="sm:flex items-center ml-auto mt-0 gap-2 hidden text-xs mr-10">
      //     <button
      //       className="text-dark-6 border-dark-6 px-3 text-xs border h-6 w-16 rounded-[2px]"
      //       onClick={() => navigate("/operators")}
      //     >
      //       back
      //     </button>
      //       {(perms.indexOf("OPERATOR.ALL") !== -1 ||
      //         perms.indexOf("OPERATOR.CRU") !== -1 ||
      //         perms.indexOf("ADMIN.ALL") !== -1) && (
      //         <button
      //           onClick={() => navigate(`/operators/updateOperator/${id}`)}
      //           class="shadow-md text-white bg-blue-2 px-3 text-xs h-6 w-16 rounded-[2px]"
      //         >
      //           Edit
      //         </button>
      //       )}

      //     </div>
      //   </div>

      //   <div className="flex justify-evenly space-y-4 p-6 mt-3 bg-white dark:bg-slate-700 border rounded-[8px] text-xs">
      //     {/* User Details Card */}
      //     {/* <div className="border w-[160px] h-[160px] mt-5 rounded-full">

      //     </div> */}

      //     {/* <div className="flex flex-col space-y-3 border rounded-[8px]">
      //       <h1 className="font-bold ">Operator's Informations</h1>
      //       <div className="flex items-center space-x-3">
      //         <div className="flex items-center space-x-2">

      //           <span className="dark:text-white text-gray-500">Name:</span>

      //           <span className="font-bold text-base">
      //             {operatorDetail?.name}
      //           </span>
      //         </div>
      //       </div>
      //       <div className="flex flex-col space-y-2">
      //         <div className="flex items-center space-x-2">

      //           <span className="dark:text-white text-gray-500">
      //             Mobile No:
      //           </span>
      //           <span className="">
      //             {operatorDetail?.phone?.types}-
      //             {operatorDetail?.phone?.phone_no}
      //           </span>
      //         </div>
      //       </div>
      //       <div className="flex items-center space-x-2">

      //         <span className="dark:text-white text-gray-500">Aadhaar No:</span>
      //         <span className="">{operatorDetail?.aadhar_no}</span>
      //       </div>
      //       <div className="flex items-center space-x-2">

      //         <span className="dark:text-white text-gray-500">
      //           Odoo Employee No:
      //         </span>
      //         <span className="">{operatorDetail?.odoo_employee_no}</span>
      //       </div>
      //       <div className="flex items-center space-x-2">

      //         <span className="dark:text-white text-gray-500">RSO No:</span>
      //         <span className="flex gap-2">
      //           {operatorDetail?.lease_RSO_no?.map((data) => (
      //             <>
      //               <div className="">{data},</div>
      //             </>
      //           ))}
      //         </span>
      //       </div>
      //       <div className="flex items-center space-x-2">

      //         <span className="dark:text-white text-gray-500">Asset No:</span>
      //         <span className="flex gap-2">
      //           {operatorDetail?.asset_no?.map((data) => (
      //             <>
      //               <div className="">{data},</div>
      //             </>
      //           ))}
      //         </span>
      //       </div>
      //     </div> */}
      //     <div className="border rounded-[8px]">
      //       <h1 className="font-bold mb-4 pt-4 pl-4">Operator's Information</h1>
      //       <table className="table-auto w-full text-left border-collapse">
      //         <tbody>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 ">
      //               Name:
      //             </td>
      //             <td className="py-2 px-4 font-medium text-xs">
      //               {operatorDetail?.name}
      //             </td>
      //           </tr>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 ">
      //               Mobile No:
      //             </td>
      //             <td className="py-2 px-4">
      //               {operatorDetail?.phone?.types}-
      //               {operatorDetail?.phone?.phone_no}
      //             </td>
      //           </tr>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 ">
      //               Aadhaar No:
      //             </td>
      //             <td className="py-2 px-4">{operatorDetail?.aadhar_no}</td>
      //           </tr>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 ">
      //               Odoo Employee No:
      //             </td>
      //             <td className="py-2 px-4">
      //               {operatorDetail?.odoo_employee_no}
      //             </td>
      //           </tr>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 ">
      //               RSO No:
      //             </td>
      //             <td className="py-2 px-4">
      //               {operatorDetail?.lease_RSO_no?.map((data, index) => (
      //                 <span key={index} className="mr-2">
      //                   {data},
      //                 </span>
      //               ))}
      //             </td>
      //           </tr>
      //           <tr className="border-t">
      //             <td className="py-2 px-4 font-medium text-gray-500 ">
      //               Asset No:
      //             </td>
      //             <td className="py-2 px-4">
      //               {operatorDetail?.asset_no?.map((data, index) => (
      //                 <span key={index} className="mr-2">
      //                   {data},
      //                 </span>
      //               ))}
      //             </td>
      //           </tr>
      //         </tbody>
      //       </table>
      //     </div>

      //     {/* <hr className="h-[100px] border " /> */}

      //     {/* Employment Details Card */}

      //     <div className="border rounded-[8px] ">
      //       <h1 className="font-bold mb-4 pt-4 pl-4">Financial Information</h1>
      //       <table className="table-auto w-full text-left border-collapse">
      //         <tbody>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 dark:text-white">
      //               PF Account No:
      //             </td>
      //             <td className="py-2 px-4 font-bold">
      //               {parseInt(operatorDetail?.pf_account_no) || "--"}
      //             </td>
      //           </tr>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 dark:text-white">
      //               Bank Account No:
      //             </td>
      //             <td className="py-2 px-4">
      //               {operatorDetail?.bank_details?.account_no || "--"}
      //             </td>
      //           </tr>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 dark:text-white">
      //               IFSC Code:
      //             </td>
      //             <td className="py-2 px-4">
      //               {operatorDetail?.bank_details?.ifsc_code || "--"}
      //             </td>
      //           </tr>
      //           <tr className="border-t border-b">
      //             <td className="py-2 px-4 font-medium text-gray-500 dark:text-white">
      //               Net Inhand Salary:
      //             </td>
      //             <td className="py-2 px-4">
      //               {operatorDetail?.net_inhand_salary == null ||
      //               isNaN(operatorDetail?.net_inhand_salary)
      //                 ? "--"
      //                 : `₹${currency(
      //                     operatorDetail?.net_inhand_salary || "--"
      //                   )}`}
      //             </td>
      //           </tr>
      //         </tbody>
      //       </table>
      //     </div>

      //     {/* <hr className="h-[100px] border " /> */}
      //     {/* Date of Joining */}

      //     {/* History Data */}
      //   </div>
      // <div className="w-[300px] text-xs  p-10">
      //   {operatorDetail?.date_list?.length > 0 && (
      //     <>
      //       {/* First Date Display */}
      //       <div
      //         className="flex flex-col space-y-3 "
      //         key={operatorDetail.date_list[0].joining_date}
      //       >
      //         <div className="flex items-center space-x-2">
      //           <span className="dark:text-white text-gray-500">
      //             Date of Joining:
      //           </span>
      //           <span className="font-bold">
      //             {operatorDetail.date_list[0]?.joining_date
      //               ? formatDate(operatorDetail.date_list[0].joining_date)
      //               : "--"}
      //           </span>
      //         </div>
      //         <div className="flex items-center space-x-2">
      //           <span className="dark:text-white text-gray-500">
      //             Date of Leaving:
      //           </span>
      //           <span>
      //             {operatorDetail.date_list[0]?.leaving_date
      //               ? formatDate(operatorDetail.date_list[0].leaving_date)
      //               : "--"}
      //           </span>
      //         </div>

      //         {/* Show More Button */}
      //         <button
      //           className="shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[96px] rounded-[2px]"
      //           onClick={() => setHistory(!history)}
      //         >
      //           Show More
      //         </button>
      //       </div>

      //       {/* Show More Tab */}
      //       {history && (
      //         <>
      //           <div className="absolute right-24 text-xs top-[65%] h-[300px] w-[300px] left-[25%] overflow-y-auto bg-white dark:bg-slate-900 border rounded-[4px] shadow-xl overflow-hidden p-4">
      //             <button
      //               className="absolute right-4 text-dark-6 border-dark-6 px-3 text-xs border h-[25px] rounded-[4px] cursor-pointer w-[60px]"
      //               onClick={() => setHistory(false)}
      //             >
      //               Close
      //             </button>

      //             <div className="space-y-2 p-2 mt-6">
      //               {operatorDetail?.date_list
      //                 ?.slice(1)
      //                 .map((date, index) => (
      //                   <div key={index} className="flex flex-col space-y-2">
      //                     <div className="flex items-center space-x-2">
      //                       <span className="dark:text-white text-gray-500">
      //                         Date of Joining:
      //                       </span>
      //                       <span>
      //                         {date?.joining_date
      //                           ? formatDate(date.joining_date)
      //                           : "--"}
      //                       </span>
      //                     </div>
      //                     <div className="flex items-center space-x-2">
      //                       <span className="dark:text-white text-gray-500">
      //                         Date of Leaving:
      //                       </span>
      //                       <span>
      //                         {date?.leaving_date
      //                           ? formatDate(date.leaving_date)
      //                           : "--"}
      //                       </span>
      //                     </div>
      //                   </div>
      //                 ))}
      //             </div>
      //           </div>
      //           <div
      //             className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000000] z-50 "
      //             onClick={() => setHistory(false)}
      //           />
      //         </>
      //       )}
      //     </>
      //   )}
      // </div>
      // </div>
      <>
        <div
          id="operator-details"
          className=" dark:bg-gray-800 h-full p-6"
        >
          <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-900 border-t shadow-lg flex gap-2 justify-end items-center px-6 py-2">
          <button
            onClick={handleLog}
            className="shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition">
              Log Data
            </button>
            <button
              className="shadow-md text-dark-6 bg-white border px-3 text-xs h-7 w-[96px] rounded-[2px]"
              onClick={() => navigate("/operators")}
            >
              Back
            </button>
            {["OPERATOR.ALL", "OPERATOR.CRU", "ADMIN.ALL"].some((perm) =>
              perms.includes(perm)
            ) && (
              <button
                className="shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition"
                onClick={() => navigate(`/operators/updateOperator/${id}`)}
              >
                Edit
              </button>
            )}
          </div>

          <div className="grid lg:grid-cols-3 gap-6 mt-2">
            {/* Operator's Information */}
            <div className="bg-white dark:bg-gray-900 p-6 text-dark-6 rounded-[2px] shadow-md lg:col-span-2">
              <h2 className="text-xs font-bold mb-2">Operator's Information</h2>
              <table className="w-full text-xs">
                <tbody>
                  {[
                    { label: "Name", value: operatorDetail?.name },
                    {
                      label: "Mobile No",
                      value: `${operatorDetail?.phone?.types}-${operatorDetail?.phone?.phone_no}`,
                    },
                    { label: "Aadhaar No", value: operatorDetail?.aadhar_no },
                    {
                      label: "Odoo Employee No",
                      value: operatorDetail?.odoo_employee_no,
                    },
                    {
                      label: "RSO No",
                      value: operatorDetail?.lease_RSO_no?.join(", ") || "--",
                    },
                    {
                      label: "Asset No",
                      value: operatorDetail?.asset_no?.join(", ") || "--",
                    },
                  ].map(({ label, value }, idx) => (
                    <tr key={idx} className="border-b">
                      <td className="py-2 font-medium text-gray-500">
                        {label}:
                      </td>
                      <td className="py-2">{value || "--"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Financial Information */}
            <div className="bg-white text-dark-6 dark:bg-gray-900 p-6 rounded-[2px] shadow-md">
              <h2 className="text-xx font-bold mb-4">Financial Information</h2>
              <table className="w-full text-xs">
                <tbody>
                  {[
                    {
                      label: "PF Account No",
                      value: operatorDetail?.pf_account_no || "--",
                    },
                    {
                      label: "Bank Account No",
                      value: operatorDetail?.bank_details?.account_no || "--",
                    },
                    {
                      label: "IFSC Code",
                      value: operatorDetail?.bank_details?.ifsc_code || "--",
                    },
                    {
                      label: "Net Inhand Salary",
                      value: operatorDetail?.net_inhand_salary
                        ? `₹${operatorDetail?.net_inhand_salary}`
                        : "--",
                    },
                  ].map(({ label, value }, idx) => (
                    <tr key={idx} className="border-b">
                      <td className="py-2 font-medium text-gray-500">
                        {label}:
                      </td>
                      <td className="py-2">{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Employment Dates */}
            {/* <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md lg:col-span-3">
      <h2 className="text-xl font-bold mb-4">Employment Dates</h2>
      {operatorDetail?.date_list?.length > 0 ? (
        <div className="space-y-4">
          {operatorDetail.date_list.map((date, idx) => (
            <div key={idx} className="border-b pb-2">
              <p>
                <strong>Date of Joining:</strong>{" "}
                {date?.joining_date || "--"}
              </p>
              <p>
                <strong>Date of Leaving:</strong>{" "}
                {date?.leaving_date || "--"}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p>No employment dates available.</p>
      )}
    </div> */}
            <div className="w-full shadow-md text-xs  p-10 bg-white ">
              {operatorDetail?.date_list?.length > 0 && (
                <>
                  {/* First Date Display */}
                  <div
                    className="flex flex-col space-y-3 "
                    key={operatorDetail.date_list[0].joining_date}
                  >
                    <div className="flex items-center space-x-2">
                      <span className="dark:text-white text-gray-500">
                        Date of Joining:
                      </span>
                      <span className="font-bold">
                        {operatorDetail.date_list[0]?.joining_date
                          ? formatDate(operatorDetail.date_list[0].joining_date)
                          : "--"}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="dark:text-white text-gray-500">
                        Date of Leaving:
                      </span>
                      <span>
                        {operatorDetail.date_list[0]?.leaving_date
                          ? formatDate(operatorDetail.date_list[0].leaving_date)
                          : "--"}
                      </span>
                    </div>

                    {/* Show More Button */}
                    <button
                      className="shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[96px] rounded-[2px]"
                      onClick={() => setHistory(!history)}
                    >
                      Show More
                    </button>
                  </div>

                  {/* Show More Tab */}
                  {history && (
                    // <>
                    //   <div className="absolute right-24 text-xs top-[45%] h-[300px] w-[300px] left-[25%] overflow-y-auto bg-white dark:bg-slate-900 border rounded-[4px] shadow-xl overflow-hidden p-4">
                    //     <button
                    //       className="absolute right-4 text-dark-6 border-dark-6 px-3 text-xs border h-[25px] rounded-[2px] shadow-md cursor-pointer w-[60px]"
                    //       onClick={() => setHistory(false)}
                    //     >
                    //       Close
                    //     </button>

                    //     <div className="space-y-2 p-2 mt-6">
                    //       {operatorDetail?.date_list
                    //         ?.slice(1)
                    //         .map((date, index) => (
                    //           <div key={index} className="flex flex-col space-y-2">
                    //             <div className="flex items-center space-x-2">
                    //               <span className="dark:text-white text-gray-500">
                    //                 Date of Joining:
                    //               </span>
                    //               <span>
                    //                 {date?.joining_date
                    //                   ? formatDate(date.joining_date)
                    //                   : "--"}
                    //               </span>
                    //             </div>
                    //             <div className="flex items-center space-x-2">
                    //               <span className="dark:text-white text-gray-500">
                    //                 Date of Leaving:
                    //               </span>
                    //               <span>
                    //                 {date?.leaving_date
                    //                   ? formatDate(date.leaving_date)
                    //                   : "--"}
                    //               </span>
                    //             </div>
                    //           </div>
                    //         ))}
                    //     </div>
                    //   </div>
                    //   <div
                    //     className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000000] z-50 "
                    //     onClick={() => setHistory(false)}
                    //   />
                    // </>

                    <>
                      <>
                        {/* Modal Container */}
                        <div className="absolute left-[30%] text-dark-6 top-[53%] transform -translate-y-1/2 w-[350px] h-[380px] overflow-y-auto bg-white dark:bg-gray-900 border rounded-[2px] shadow-lg p-5 z-50">
                          {/* Close Button */}
                          <button
                            className="absolute top-4 right-5 text-gray-600 dark:text-gray-300 border border-gray-300 hover:border-gray-500 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 px-3 py-1 w-[96px] rounded-[2px] text-xs shadow transition"
                            onClick={() => setHistory(false)}
                          >
                            Close
                          </button>

                          {/* Title */}
                          <h3 className="text-xs font-semibold text-gray-800 dark:text-white mb-4">
                            Employment History
                          </h3>

                          {/* Employment History List */}
                          <div className="space-y-3">
                            {operatorDetail?.date_list?.slice(1).length > 0 ? (
                              operatorDetail?.date_list
                                ?.slice(1)
                                .map((date, index) => (
                                  <div
                                    key={index}
                                    className="p-3 border rounded-[2px] bg-light-7 dark:bg-gray-800 shadow-sm"
                                  >
                                    <div className="flex justify-between text-xs">
                                      <span className="text-gray-500 dark:text-gray-400">
                                        Date of Joining:
                                      </span>
                                      <span className="font-medium text-gray-800 dark:text-white">
                                        {date?.joining_date
                                          ? formatDate(date.joining_date)
                                          : "--"}
                                      </span>
                                    </div>
                                    <div className="flex justify-between text-xs mt-2">
                                      <span className="text-gray-500 dark:text-gray-400">
                                        Date of Leaving:
                                      </span>
                                      <span className="font-medium text-gray-800 dark:text-white">
                                        {date?.leaving_date
                                          ? formatDate(date.leaving_date)
                                          : "--"}
                                      </span>
                                    </div>
                                  </div>
                                ))
                            ) : (
                              <p className="text-center text-gray-500 dark:text-gray-400">
                                No additional history available.
                              </p>
                            )}
                          </div>
                        </div>

                        {/* Backdrop */}
                        <div
                          className="fixed inset-0 bg-[#0000] bg-opacity-30 z-40"
                          onClick={() => setHistory(false)}
                        />
                      </>
                    </>
                  )}
                </>
              )}
            </div>

          
            {openLogData && <>
              <div className=" bg-white shadow-md  p-5">
          {loading ? (
            <div className="flex justify-center items-center py-10">
              <div className="animate-spin rounded-full h-8 w-8 "></div>
            </div>
          ) : (
            <div className="overflow-x-auto overflow-y-auto h-[364px] w-full text-[10px]">
              <table className="w-full border-collapse divide-y">
                {/* Table Header */}
                <thead className="divide-y sticky top-0 bg-white shadow-md">
                  <tr>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Act By
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Action Type
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Fields Are
                    </th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {logData.length > 0 ? (
                    logData.map((data, index) => (
                      <tr
                        key={index}
                        className={` hover:bg-light-8 transition`}
                      >
                        <td className="py-3 px-4 text-dark-6 border-b">
                          {data.act_by}
                        </td>
                        <td className="py-3 px-4 text-dark-6 border-b">
                          {data.action_type}
                        </td>
                        <td className="py-3 px-4 text-dark-6 border-b">
                          {data.fields_are?.split(",id,").join(", ")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="3"
                        className="py-4 px-4 text-center text-dark-6"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
            </>} 
          </div>
        </div>
      </>
    );
  }
}

export default OperatorDetails;

// {operatorDetail?.date_list?.map((date) => {
//   <>
//   <div key={date} className="flex flex-col gap-6 space-y-3 ">

//     {console.log(formatDate(date.joining_date))}
//   {date.leaving_date ? <>{console.log(formatDate(date.leaving_date))}</> : "--"}

//   </div>
//   </>
// })}
