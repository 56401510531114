import axios from "axios";
import React, { useState } from "react";
import { setBaseUrl } from "../../../config";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../../Context/LoaderContext";
import { useEffect } from "react";

function CurrentItems({
  allOperators,
  deleted,
  setDeleted,
  sortHeader,
  openFilter,
  setOpenFilter,
  openTable,
  setopenTable,
  tableArray,
  selectTableData,
  setSelectTableData,
  openSort,
  setOpenSort,
  sort,
  setSort,
  sortOrder,
  setSortOrder,
  inputSearch,
  setInputSearch,
  filteredHeaders,
  sortedOperatorDetails,
  filteredDataStack,
  setFilteredDataStack,
}) {
  const [tableAnimation, setTableAnimation] = useState(false);
  const [loader, setLoader] = useContext(LoaderContext);
  const [perms] = useContext(PermissionContext);
  const navigate = useNavigate();

  const openDetailsOnClick = (id) => {
    navigate(`details/${id}`);
  };

  const openUpdateOperator = (id) => {
    navigate(`updateOperator/${id}`);
  };
  const deleteOperator = async (id) => {
    try {
      setLoader(true);
      const { data } = await axios.delete(`${setBaseUrl}/operator/delete`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        data: { id: id },
      });
      setDeleted((prev) => !prev);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoader(false);
      return;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTableAnimation(true);
    }, 100);
  }, [loader]);

  // sortedOperatorDetails = [...allOperators].sort((a, b) => {
  //   if (!sort) return 0; // No sorting if sort is null

  //   const aValue =
  //     a[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || ""; // Convert the sort string to match the object key
  //   const bValue =
  //     b[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";

  //   if (aValue < bValue) {
  //     return sortOrder === "asc" ? -1 : 1;
  //   }
  //   if (aValue > bValue) {
  //     return sortOrder === "asc" ? 1 : -1;
  //   }
  //   return 0;
  // });

  sortedOperatorDetails = [...allOperators].sort((a, b) => {
    if (!sort) return 0; // No sorting if no header is selected

    const aValue =
      a[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";
    const bValue =
      b[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";

    return sortOrder === "asc"
      ? aValue.localeCompare(bValue) // Ascending order
      : bValue.localeCompare(aValue); // Descending order
  });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  // input select

  // const [items, setItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const handleSelectAllChange = (event) => {
    const newAllSelected = event.target.checked;
    setAllSelected(newAllSelected);

    const updatedItems = allOperators.map((item) => ({
      ...item,
      selected: newAllSelected,
    }));
    // setFilteredDataStack(updatedItems);
  };

  const handleItemChange = (itemId) => {
    // const updatedItems = filteredDataStack.map((item) =>
    //   item.id === itemId ? { ...item, selected: !item.selected } : item
    // );

    // allOperators(updatedItems);

    // Update `allSelected` if all items are selected after the change
    // setAllSelected(updatedItems.every((item) => item.selected));
  };

  return (
    <>
      <div className="  w-full h-[74vh] mt-3 overflow-hidden border hide-scrollbar  overflow-y-auto">
        {allOperators === undefined || allOperators.length === 0 ? (
          <div className="h-[50vh] flex justify-center items-center">
            No Data available
          </div>
        ) : (
          <>
            <div className="">
              <table
                className={`table ${
                  tableAnimation ? "show-rows" : ""
                }  border-separate border-spacing-y-0  text-xs  w-full relative `}
              >
                <thead className="h-10 sticky bg-[#efefef] dark:bg-gray-700 ">
                  <tr className="">
                    <th>
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={handleSelectAllChange}
                        className="focus:outline-none focus:ring-0 rounded-[3px] text-light-1 bg-transparent"
                      />
                    </th>
                    <th>Serial No</th>
                    {sortHeader?.map((header) => (
                      <th
                        key={header}
                        className="cursor-pointer"
                        onClick={() => {
                          if (sort === header) {
                            toggleSortOrder();
                          } else {
                            setSort(header);
                            setSortOrder("asc");
                          }
                        }}
                      >
                        {header}{" "}
                        {sort === header
                          ? sortOrder === "asc"
                            ? "▲"
                            : "▼"
                          : ""}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-900">
                  {allOperators.map((item, index) => {
                    if (selectTableData - 1 >= index || !selectTableData) {
                      return (
                        <>
                          <tr
                            className="cursor-pointer text-center items-center hover:bg-[#2222] justify-center h-10"
                            key={index}
                          >
                            <td className="border-b w-[50px] ">
                              <input
                                type="checkbox"
                                checked={item.selected}
                                onChange={() => handleItemChange(item.id)}
                                className="focus:outline-none focus:ring-0 rounded-[3px]  text-light-1 bg-transparent "
                              />
                            </td>
                            <td className="border-b w-[60px]">{index + 1}</td>
                            
                            <td className="border-b">
                              {" "}
                              <Link to={`details/${item?.id}`}>
                              {(item?.name).toUpperCase()}
                              </Link>
                            </td>
                            <td className="border-b">
                              {" "}
                              <Link to={`details/${item?.id}`}>
                              {item.aadhar_no === undefined ||
                              item.aadhar_no === ""
                                ? "no data available"
                                : item.aadhar_no}
                              </Link>
                            </td>
                            <td className="border-b">
                            <Link to={`details/${item?.id}`}>
                              {item.pf_account_no === undefined ||
                              item.pf_account_no === ""
                                ? "no data available"
                                : parseInt(item.pf_account_no)}
                            </Link>
                            </td>
                            <td className="border-b">
                            <Link to={`details/${item?.id}`}>
                              {item.joining_date === undefined ||
                              item.joining_date === ""
                                ? "no data available"
                                : item.joining_date}
                            </Link>
                            </td>
                            <td className="border-b">
                            <Link to={`details/${item?.id}`}>
                              {item.leaving_date === undefined ||
                              item.leaving_date === "" || item.leaving_date === null 
                                ? "no data available"
                                : item.leaving_date}
                            </Link>
                            </td>
                            {/* <td className="border-b">{item?.rso_no}</td>
                            <td className="border-b">{item?.asset_no}</td> */}
                          </tr>
                        </>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CurrentItems;
