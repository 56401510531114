import React from 'react';
import { formatDate } from '../../customFunctions/FormatDate';
import { FaUserCircle } from 'react-icons/fa';

const ServiceComments = ({ comments }) => {
    return (
        <div className="space-y-4 border border-gray-200 rounded-lg overflow-hidden">
            
            {/* Comments History */}
            <div className="p-4 space-y-4 max-h-96 overflow-y-auto">
                {comments?.length > 0 ? (
                    comments.map((comment, index) => (
                        <div 
                            key={comment.id || index} 
                            className={`flex ${comment.by_me ? 'justify-end' : 'justify-start'}`}
                        >
                            <div className={`flex max-w-xs md:max-w-md ${comment.by_me ? 'flex-row-reverse' : ''}`}>
                                <div className="flex-shrink-0 mr-3 ml-3">
                                    <FaUserCircle className={`h-8 w-8 ${comment.by_me ? 'text-blue-500' : 'text-gray-400'}`} />
                                </div>
                                <div>
                                    <div className={`p-3 rounded-lg ${comment.by_me ? 'bg-blue-50' : 'bg-gray-50'}`}>
                                        <p className="text-sm text-gray-800">{comment.comment}</p>
                                    </div>
                                    <div className={`mt-1 text-xs text-gray-500 ${comment.by_me ? 'text-right' : 'text-left'}`}>
                                        {comment?.visit_date && formatDate(comment?.visit_date)}
                                        <span className="mx-1">•</span>
                                        {comment.by_me ? 'You' : 'Service Header'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center py-6 text-gray-500 text-sm">
                        No comments available yet
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServiceComments;