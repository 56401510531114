import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import Loader from "../../Loader";
import { TableLoader } from "../../TableLoader";
import { LoaderContext } from "../../../Context/LoaderContext";
import CurrentItems from "./CurrentItems";
import { Pagination } from "../Pagination";
import UpperTab from "./UpperTab";
import HistoryBtn from "../../Buttons/HistoryBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import NewOperatorTable from "./NewOperatorTable";

function OperatorTable() {
  // set the item quantity to load page numbers
  // const [items, setItems] = useState(1);
  // set items per page

  const [allOperators, setAllOperators] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [loader, setLoader] = useContext(LoaderContext);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [fetchSearchData, setFetchSearchData] = useState(false);
  const [filteredDataStack, setFilteredDataStack] = useState([]);

  const getAllOperators = async (qry, value = "") => {
    // console.log(qry, value)
    let headers = {};
    if (qry !== null) {
      headers = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
        offset: itemOffset,

        [qry]: value,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
        offset: itemOffset,
      };
    }
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/operator/get_all`, {
        headers: headers,
      });
      // console.log(data);
      setAllOperators(data);
      // if (data.length) setItems(data[0].total_data);
    } catch (error) {
      // console.error(error);
    } finally {
      setLoader(false);
    }
  }; //eta thakbe

  useEffect(() => {
    getAllOperators();
  }, [deleted, itemOffset]); //eta thakbe

  const sortHeader = [
    "Name",
    "Aadhar No",
    "Pf Account No",
    "Joining Date",
    "Leaving Date",
    // "RSO No.",
    // "Asset No",
  
  ];

  const [openFilter, setOpenFilter] = useState(false);


  let tableArray = [10, 20, 30, 50, `${allOperators.length}`];
  const [selectTableData, setSelectTableData] = useState(10);


  // Sort Table
  const [openSort, setOpenSort] = useState(false);
  // sort names are storing
  const [sort, setSort] = useState(null);
  // sorting the data in ascending order using the whole filter
  const [sortOrder, setSortOrder] = useState("asc");
    // for input in sorting
    const [inputSearch, setInputSearch] = useState("");
  // Filtered header based on search input
  const filteredHeaders = sortHeader.filter((header) =>
    header.toLowerCase().includes(inputSearch.toLowerCase())
  );

  let sortedOperatorDetails = []


  const [filteredData, setFilteredData] = useState(allOperators);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleExport = () => {
    const dataToExport =
      selectedRows.length > 0
        ? selectedRows
        : filteredData.map((item, index) => ({ ...item, id: index + 1 }));
    const csvContent = [
      Object.keys(dataToExport[0]).join(","), // CSV headers
      ...dataToExport.map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`) // Escape values with quotes
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "exported_table.csv";
    link.click();

    URL.revokeObjectURL(url); // Clean up URL
  };


  return (
    <div id="main-operator" className="bloc">
      
      <UpperTab
        allOperators={allOperators}
        getAllOperators={getAllOperators}
        setFetchSearchData={setFetchSearchData}
        sortHeader={sortHeader}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
 
        openSort={openSort} setOpenSort={setOpenSort}
        sort={sort}
        setSort={setSort}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        inputSearch={inputSearch}
        setInputSearch={setInputSearch}
        filteredHeaders={filteredHeaders}
  
      />

      {loader ? (
        <Loader />
      ) : (
        // <CurrentItems
        //   allOperators={allOperators}
        //   deleted={deleted}
        //   setDeleted={setDeleted}
        //   sortHeader={sortHeader}
        //   openFilter={openFilter}
        // setOpenFilter={setOpenFilter}
     
        // tableArray={tableArray}
        // selectTableData={selectTableData}
        // setSelectTableData={setSelectTableData}
        // openSort={openSort} setOpenSort={setOpenSort}
        // sort={sort}
        // setSort={setSort}
        // sortOrder={sortOrder}
        // setSortOrder={setSortOrder}
        // inputSearch={inputSearch}
        // setInputSearch={setInputSearch}
        // filteredHeaders={filteredHeaders}
        // sortedOperatorDetails={sortedOperatorDetails}
        // />
        <NewOperatorTable
        allOperators={allOperators}
        handleExport={handleExport}
        setAllOperators={setAllOperators}
        setFilteredData={setFilteredData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        />
      )}
      
    </div>
  );
}

export default OperatorTable;
