import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import Loader from "../../Loader";
import CurrentItems from "./CurrentItems";
import Search from "./Search";
import { LoaderContext } from "../../../Context/LoaderContext";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import NewMaintenanceTable from "./NewMaintenanceTable";

function MaintenanceTable({ ticketID, setTicketID }) {
  // set the item quantity to load page numbers
  const [deleted, setDeleted] = useState(false);

  const [loader, setLoader] = useContext(LoaderContext)
  const [allMaintenance, setAllMaintenance] = useState([]);

  const [searchData, setSearchData] = useState(null);

  const [searchType, setSearchType] = useState("No filter");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [openTicket, setOpenTicket] = useState(false)

  const [setItems] = useState(0);
  // set items per page
  const itemsPerPage = 10;
  const [itemOffset] = useState(0);

  const getAllMaintenance = async () => {
    try {
      setLoader(true);
      const headersobj = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),

      };
      if ((searchType !== "") | undefined && (searchData !== "") | null) {
        // console.log(searchData);
        // console.log(searchType);
        if (searchType == "schedule_date") {
          headersobj["scheduled-date-from"] = startDate;
          headersobj["scheduled-date-to"] = endDate;
        } else headersobj[`${searchType}`] = searchData;
      }
      const { data } = await axios.get(`${setBaseUrl}/maintenance/get_all`, {
        headers: headersobj,
      });

      setAllMaintenance(data);
    } catch (error) {
      alert(error.response.data.error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllMaintenance();
  }, [deleted, itemOffset, searchData]);

  useEffect(() => {
    setOpenTicket(true);
    
    const timer = setTimeout(() => {
      setOpenTicket(false);
    }, 10000); // Timeout duration in milliseconds (e.g., 10000ms = 10 seconds)
  
    return () => clearTimeout(timer); // Cleanup the timeout on unmount or dependency change
  }, [ticketID]);
  

  return (
    <>
      {/* <HistoryBtn/>
     <ExcelReportBtn tabName='maintenance' /> */}
      {/* <Search startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} getAllMaintenance={getAllMaintenance} setItems={setItems} searchType={searchType}  setSearchType={setSearchType} setSearchData={setSearchData}/> */}

      {loader ? (
        <Loader />
      ) : (
        <>
          {(ticketID && openTicket) && (
            <>
              <div className="z-[9999] fixed bg-white rounded-[16px] p-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-md shadow-xl animate-fadeIn">
                <div className="text-center">
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                    <svg
                      className="h-6 w-6 text-green-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                    Complaint Registered Successfully!
                  </h3>
                  <div className="mt-2 px-4 py-3 bg-blue-50 rounded-md">
                    <p className="text-sm text-gray-600">
                      Your complaint has been successfully registered with
                    </p>
                    <p className="text-lg font-bold text-blue-600 mt-1">
                      Ticket ID: {ticketID}
                    </p>
                  </div>
                  <div className="mt-6">
                    <button
                      onClick={() => setOpenTicket(false)}
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
                    >
                      Close
                    </button>
                  </div>
                  <p className="text-xs text-gray-500 mt-4">
                    We'll contact you soon regarding this issue.
                  </p>
                </div>
              </div>
              <div
                className="z-[9998] fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm transition-opacity"
                onClick={() => setOpenTicket(false)}
              />
            </>
          )}
          <NewMaintenanceTable maintenance={allMaintenance} />


        </>
      )}

    </>
  );
}

export default MaintenanceTable;

