// import React, { useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Box,
//   Button,
// } from "@mui/material";
// import { formatDate, formatTime, formatTimeDuration } from "../customFunctions/FormatDate";
// // import {
// //   formatDate,
  
// //   formatTime,
  
// //   formatTimeDuration,
// // } from "./Modals/AssetDetails/Tabs/FormatDate";

// const Timeline = ({ devId, setShowMachine, handleBottomTab, timeLine }) => {
//   const [hover, setHover] = useState(false);
  
//   // Format Time

  
  

//   let dates = [];

//   for (let i = 0; i < Object.keys(timeLine.working_time).length; i++) {
//     dates.push(Object.keys(timeLine.working_time)[i]);
//   }

//   const header = [
//     "Date",
//     "Start Time",
//     "End Time",
//     "Duration",
//     "Total Instance",
//   ];

//   return (
//     <Box p={3} bgcolor="white" borderRadius={2} boxShadow={2}>
//       {/* Page Title */}
//       <h1 className="text-sm text-dark-6 font-semibold">Working Time</h1>

//       {/* Table Container */}
//       <Box
//         position="relative"
//         onMouseOver={() => setHover(true)}
//         onMouseLeave={() => setHover(false)}
//       >
//         {/* Hover Overlay */}
//         {hover && (
//           <Box
//             position="absolute"
//             top={0}
//             left={0}
//             right={0}
//             bottom={0}
//             bgcolor="rgba(0, 0, 0, 0.4)"
//             zIndex={1}
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//           >
//             <button
//               className="bg-green-2 text-white buttons"
//               onClick={() => {
//                 setShowMachine(true);
//                 handleBottomTab(4);
//               }}
//             >
//               Show More
//             </button>
//           </Box>
//         )}

      
//         <TableContainer component={Paper} style={{ fontSize: 12 }}>
//           <Table size="small" aria-label="working time table">
          
//             <TableHead>
//               <TableRow>
//                 {header.map((col) => (
//                   <TableCell
//                     key={col}
//                     align="left"
//                     style={{ fontWeight: "semibold" }}
//                     sx={{ fontSize: "12px", fontWeight: "bold" }}
//                   >
//                     {col}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
            
//             <TableBody>
//               {dates
//                 ?.reverse()
//                 ?.slice(0, 6)
//                 ?.map((date) => (
//                   <TableRow key={date} hover>
//                     <TableCell sx={{ fontSize: "12px" }}>{formatDate(date)}</TableCell>
//                     <TableCell sx={{ fontSize: "12px" }}>
//                       {timeLine?.working_time[date]?.dispute_time ? (
//                         <>
//                           {formatTime(timeLine?.working_time[date]?.start)}
//                           <br />
//                           {`${formatTime(
//                             timeLine?.working_time[date]?.dispute_time?.start
//                           )} (dispute time)`}
//                         </>
//                       ) :
//                       <>
//                         {timeLine?.working_time[date]?.start? formatTime(timeLine?.working_time[date]?.start) : "0:00"}
//                       </> 
//                       }
//                     </TableCell>
//                     <TableCell sx={{ fontSize: "12px" }}>
//                       {timeLine?.working_time[date]?.dispute_time ? (
//                         <>
//                           {formatTime(timeLine?.working_time[date].end)}
//                           <br />
//                           {`${formatTime(
//                             timeLine?.working_time[date]?.dispute_time?.end
//                           )} (dispute time)`}
//                         </>
//                       ) :
//                       <>
//                         {timeLine?.working_time[date]?.end? formatTime(timeLine?.working_time[date]?.end): "0:00"}
//                       </> 
//                       }
//                     </TableCell>
//                     <TableCell sx={{ fontSize: "12px" }}>
//                       {timeLine?.working_time[date]?.dispute_time ? (
//                         <>
//                           {formatTimeDuration(
//                             timeLine?.working_time[date]?.duration
//                           )}
//                           <br />
//                           {`${formatTimeDuration(
//                             timeLine?.working_time[date]?.dispute_time?.duration
//                           )} (dispute duration)`}
//                         </>
//                       ) : <>
//                         {timeLine?.working_time[date]?.duration? formatTimeDuration(timeLine?.working_time[date]?.duration): "0:00"}
//                       </>
//                       }
//                     </TableCell>
//                     <TableCell sx={{ fontSize: "12px" }}>
//                       {timeLine?.instance_time[date]?.length
//                         ? timeLine?.instance_time[date]?.length
//                         : 0}
//                     </TableCell>
//                   </TableRow>
//                 ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Box>
//   );
// };

// export default Timeline;


import React, { useState } from "react";
import { formatDate, formatFullDate, formatTime, formatTimeDuration } from "../customFunctions/FormatDate";
import TelematicsData from "./Modals/Telematics/TelematicsData";

const Timeline = ({ devId, setShowMachine, handleBottomTab, timeLine }) => {
  const [expanded, setExpanded] = useState(false);
  
  let dates = [];
  for (let i = 0; i < Object.keys(timeLine.working_time).length; i++) {
    dates.push(Object.keys(timeLine.working_time)[i]);
  }

  // Sort dates in reverse chronological order
  const sortedDates = dates.reverse();

  return (
    <>
   
      {/* Working Time History Panel */}
    <div className="bg-white  rounded-[8px] shadow-md p-4 w-[27vw] ">

      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-sm font-semibold text-gray-800">Working Time History</h2>
        <button 
          onClick={() => {
            setShowMachine(true);
            handleBottomTab(8);
          }}
          className="text-blue-600 hover:text-blue-800 text-sm font-medium"
        >
          View All
        </button>
      </div>

      {/* Timeline Items */}
      <div className="space-y-4">
        {(expanded ? sortedDates : sortedDates.slice(0, 4)).map((date) => (
          <div key={date} className="border-b border-gray-100 p-4 rounded-[10.77px] bg-light-8 ">
            {/* Date Header */}
            <div className="text-sm font-medium text-gray-500 mb-2">
              {formatFullDate(date)}
            </div>
            
            {/* Time Range */}
            <div className="flex justify-between items-center mb-1">
              <div className="text-sm text-gray-700">
                {timeLine?.working_time[date]?.start ? 
                  `from ${formatTime(timeLine.working_time[date].start)}` : 
                  "No start time"}
                {" to "}
                {timeLine?.working_time[date]?.end ? 
                  formatTime(timeLine.working_time[date].end) : 
                  "No end time"}
              </div>
              <div className="text-sm font-medium text-gray-900">
                {timeLine?.working_time[date]?.duration ? 
                  formatTimeDuration(timeLine.working_time[date].duration) : 
                  "0:00"} Hrs
              </div>
            </div>

            {/* Dispute Time (if exists) */}
            {timeLine?.working_time[date]?.dispute_time && (
              <div className=" p-2 rounded text-xs text-gray-600 mt-2">
                <div className="font-medium text-yellow-700 mb-1">Dispute Time</div>
                <div className="flex justify-between">
                  <span>
                    {formatTime(timeLine.working_time[date].dispute_time.start)} - 
                    {formatTime(timeLine.working_time[date].dispute_time.end)}
                  </span>
                  <span className="font-medium">
                    {formatTimeDuration(timeLine.working_time[date].dispute_time.duration)}
                  </span>
                </div>
              </div>
            )}

            {/* Instance Count */}
            <div className="text-xs text-gray-500 mt-2">
              {timeLine?.instance_time[date]?.length || 0} instances
            </div>
          </div>
        ))}
      </div>

    
    </div>
    </>
  );
};

export default Timeline;