import React from "react";

const LeasePhotoData = ({ photos }) => {
  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xs font-semibold text-gray-800 mb-4">Lease Photos</h2>

      {photos?.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {photos.map((photo) => (
            <div
              key={photo.id}
              className="bg-white rounded-lg shadow-sm overflow-hidden"
            >
              <img
                src={photo.image_uri}
                alt="Lease Photo"
                className="h-[200px] w-[200px] object-cover rounded-lg transition-transform duration-200 hover:scale-105"
              />
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-sm">No photos available.</p>
      )}
    </div>
  );
};

export default LeasePhotoData;
