import React, { useEffect, useState } from "react";
import { get_data_by_categories } from "../../../apis";

function CategoryTable() {
  const [unlinked_assets, setUnlinked_assets] = useState([]);
  const [Leased_assets, setLeased_assets] = useState([]);
  const [maintenance_assets, setMaintenance_assets] = useState([]);
  const [categories, setCategories] = useState([]);

  const getUnlinked_assets = async () => {
    try {
      const { data } = await get_data_by_categories();
      // console.log(data);

      const categoryNames = Object.keys(data);
      const assets = Object.values(data);

      // Set categories
      setCategories(categoryNames);

      // Arrays for asset data
      const temp_unlinked_assets = [];
      const temp_leased_assets = [];
      const temp_maintenance_assets = [];

      // Extract the asset data
      assets.forEach(assetArray => {
        temp_unlinked_assets.push(assetArray[0]); // Assuming index 0 is unlinked assets
        temp_leased_assets.push(assetArray[1]); // Assuming index 1 is leased
        temp_maintenance_assets.push(assetArray[2]); // Assuming index 2 is maintenance
      });

      setUnlinked_assets(temp_unlinked_assets);
      setLeased_assets(temp_leased_assets);
      setMaintenance_assets(temp_maintenance_assets);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUnlinked_assets();
  }, []);

  return (
    <div className="p-5  mt-0">
      <div className="text-center  w-[90vw]  px-10 py-2 overflow-y-auto hide-scrollbar rounded-[6px] bg-white shadow-md">
        <table className="text-center  text-xs  divide-y border-separate border-spacing-y-0   w-full relative ">
          <thead className=" h-10 sticky  dark:bg-black">
            <tr className="text-dark-6 ">
              <th className="border-b">Category</th>
              <th className="border-b">Total</th>
              <th className="border-b">Leased</th>
              <th className="border-b">Free Assets</th>
              <th className="border-b">Under Breakdown</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category, index) => 
           {
            let total_value = unlinked_assets[index] + Leased_assets[index] + maintenance_assets[index]
            return (
              <tr key={index} className="h-10 items-center ">
                <td className="border-b ">{category}</td>
                <td className="border-b ">{total_value}</td>
                <td className="border-b ">{unlinked_assets[index]}</td>
                <td className="border-b ">{Leased_assets[index]}</td>
                <td className="border-b ">{maintenance_assets[index]}</td>
              </tr>
            )}
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CategoryTable;
