import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Assets from "./pages/Assets";

import Lease from "./pages/Lease";
import Telematics from "./pages/Telematics";
import Operator from "./pages/Operator";
import Settings from "./pages/Settings";
import Login from "./Authentication/Login";
import Signup from "./Authentication/Signup";
import { LoaderContextProvider } from "./Context/LoaderContext";
import { NavbarContextProvider } from "./Context/NavbarContext";
import { PermissionContext } from "./Context/PermissionsContext";
import { ChangePassword } from "./pages/ChangePassword";
import { Successful } from "./pages/Successful";
import { UpdateEmployee } from "./components/Modals/UpdateEmployee";
import SuperadminRoutes from "./pages/Superadmin/SuperadminRoutes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Logistics from "./pages/Logistics/Logistics";
import NewLogistics from "./pages/Logistics/NewLogistics";
import UpdateLogistics from "./pages/Logistics/UpdateLogistics";
import ErrorModule from "./errorModule/ErrorModule";
import BookingData from "./pages/BookingData";
import SalesLogin from "./Authentication/sales/SalesLogin";
import SalesRegister from "./Authentication/sales/SalesRegister";
import SalesForgetPassword from "./Authentication/sales/SalesForgetPassword";

import { ChangePasswordNewUser } from "./pages/ChangePasswordNewUser";
import LogisticsDetails from "./pages/Logistics/LogisticsDetails";
import Maintenance from "./pages/Maintenance/Maintenance";
import Inventory from "./pages/Inventory/Inventory";
import SaleServiceDashboard from "./pages/SalesService/Dashboard/SaleServiceDashboard";
import ServiceDetails from "./pages/SalesService/ServiceDetails";
import ForgotPassword from "./Authentication/ForgotPassword";
import ServiceWalletPage from "./pages/SalesService/ServiceWalletPage";
import { ToastProvider } from "./Context/ToastContext";
import NewEmployee from "./components/Modals/NewEmployee/NewEmployee";
import ServiceErrorPage from "./pages/SalesService/SeviceErrorPage";





const queryClient = new QueryClient()


function App() {
  const [pageNum, setPageNum] = useState(1);
  const [perms, setPerms] = useContext(PermissionContext);



  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    setPageNum(1);
    setActiveTab(1);
  }, []);

  useEffect(() => {
    let getPermissionsFromSession = JSON.parse(
      sessionStorage.getItem("permissions")
    );
    setPerms(getPermissionsFromSession);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
      <LoaderContextProvider>
        <NavbarContextProvider>
          <Router>
            <Routes>
              {/* login */}
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/signup" element={<Signup />} />

              {/* Dashboard */}
              <Route path="/dashboard" element={<Dashboard />} />

              {/* Asset */}
              <Route path="/assets/*" element={<Assets />} />
              {/* <Route path="/assets/sales/*" element={<SalesAssets />} /> */}
              <Route path="/maintenance/*" element={<Maintenance />} />
              <Route path="/inventory/*" element={<Inventory />} />
              {/* Add Parts to Inventory */}
              {/* /inventory/add-parts */}
 
              <Route path="/lease/*" element={<Lease />} />


              {/* Telematics */}
              <Route path="/telematics/*" element={<Telematics />} />



              {/* Operator Table */}
              <Route path="/operators/*" element={<Operator />} />

              {/* Settings */}
              <Route path="/settings/*" element={<Settings />} />
              <Route path="/settings/add-employee" element={<NewEmployee />} />
              <Route path="/settings/update-employee/:email" element={<UpdateEmployee />} />


              {/* Logisticss */}
              <Route path="/logistics/*" element={<Logistics />} />
              <Route path="/new-logistics/*" element={<NewLogistics />} />
              <Route path="/logistics-details/:id" element={<LogisticsDetails />} />
              <Route path="/update-logistics/:id" element={<UpdateLogistics />} />

              {/* Error Module */}
              <Route path="/error-module/*" element={<ErrorModule />} />

              {/* Change Password */}
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/change-password-new-user" element={<ChangePasswordNewUser />} />
              <Route path="/successful" element={<Successful />} />
              <Route path="/superadmin/*" element={<SuperadminRoutes />} />

              {/* Booking Module */}
              <Route path="/booking/*" element={<BookingData />} />

              {/* Sales and Services */}
              <Route path="/sales-services/login" element={<SalesLogin />} />
              <Route path="/sales-services/register" element={<SalesRegister />} />
              <Route path="/sales-services/forgot-password" element={<SalesForgetPassword />} />
              <Route path="/sales-services/dashboard" element={<SaleServiceDashboard />} />
                <Route path="/sales-services/service-maintenance/:id" element={<ServiceDetails />} />
                <Route path="/sales-services/service-wallet" element={<ServiceWalletPage />} />
                {/* <Route path="/sales-services/error" element={<ServiceErrorPage />} /> */}
            </Routes>
          </Router>
        </NavbarContextProvider>
      </LoaderContextProvider>

      </ToastProvider>
    </QueryClientProvider>
  );
}

export default App;
