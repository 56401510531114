import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { Link } from "react-router-dom";

const ServiceHistory = ({ assetID }) => {
  const [history, setHistory] = useState([]);

  const getHistory = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/maintenance/get_all`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": assetID,
        },
      });

      if (res.status === 200) {
        const data = await res.json();
        setHistory(data);
      } else {
        console.error("Failed to fetch service history");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getHistory();
  }, [assetID]);

  // Helper function to format date
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return new Date(dateString).toLocaleDateString();
    } catch {
      return dateString;
    }
  };

  // Helper function to display boolean values
  const displayBoolean = (value) => {
    if (value === null || value === undefined) return "N/A";
    return value ? "Yes" : "No";
  };

  return (
    <div className="mt-6 space-y-6">
      <h1 className="p-2 w-full text-base border-b">Servicing History</h1>
      {history.map((record, index) => (
        <div
          key={index}
          className="border rounded-[16px] p-4 transition-shadow duration-300 bg-white"
        >
          <div className="flex justify-between items-center bg-gray-100 p-3 rounded-t-lg">
            <p className="text-sm font-semibold">
              Asset ID: <span className="font-normal">{record.asset_no}</span>
            </p>
            <p className="text-sm font-semibold">
              Ticket No:{" "}
              <Link
                to={`/maintenance/update-maintenance/${record.id}`}
                className="font-normal underline text-blue-2"
              >
                {record.ticket_no}
              </Link>
            </p>
          </div>

          <div className="mt-4 grid grid-cols-2 gap-4 text-gray-700">
            <p>
              <strong>Title:</strong> {record.title || "N/A"}
            </p>

            <p>
              <strong>Complaint Date:</strong>{" "}
              {formatDate(record.compaint_date)}
            </p>
            <p>
              <strong>Issue Date:</strong> {formatDate(record.issue_date)}
            </p>
            <p>
              <strong>Priority:</strong>{" "}
              <span
                className={
                  record.priority === "High"
                    ? "text-red-500"
                    : record.priority === "Medium"
                    ? "text-yellow-500"
                    : "text-green-500"
                }
              >
                {record.priority || "N/A"}
              </span>
            </p>
            <p>
              <strong>Service Engineers:</strong>{" "}
              {record.serviceSalePerson?.join(", ") || "N/A"}
            </p>
            <p>
              <strong>Status:</strong>{" "}
              <span
                className={
                  record.status === "Pending"
                    ? "text-orange-500"
                    : record.status === "open"
                    ? "text-blue-500"
                    : "text-green-500"
                }
              >
                {record.status || "N/A"}
              </span>
            </p>
            <p>
              <strong>Type:</strong>{" "}
              {record.types === "type"
                ? ""
                : record.types === "non_warranty"
                ? "Non Warranty"
                : record.types === "safety_notice"
                ? "Safety Notice"
                : record.types === "preventive_maintenance"
                ? "Preventive Maintenance"
                : ""}
            </p>
            <p>
              <strong>Location:</strong> {record.location || "N/A"}
            </p>
            <p>
              <strong>Part Approval Needed:</strong>{" "}
              {displayBoolean(record.is_part_approval_needed)}
            </p>
            <p>
              <strong>Ready for Closer:</strong>{" "}
              {displayBoolean(record.is_ready_for_closer)}
            </p>
            <div className="parts-section">
              <h3 className="text-md font-semibold text-gray-800 mb-3">
                Parts List
              </h3>

              {record.parts?.length > 0 ? (
                <div className="space-y-3 ">
                  {record.parts.map((part, i) => (
                    <div
                      key={i}
                      className="bg-light-8 p-3 rounded-lg shadow-xs border border-gray-100"
                    >
                      <div className="flex justify-between items-start">
                        <div className="flex-1">
                          <p className="text-sm font-medium text-gray-800">
                            {part.part_no}
                            <span className="text-xs text-gray-500 ml-2">
                              (Qty: {part.quantity} pcs)
                            </span>
                          </p>
                          <p className="text-xs text-gray-600 mt-1">
                            Price: {part.price} {part.currency}
                          </p>
                        </div>

                        <div className="flex items-center">
                          <span
                            className={`text-xs font-medium px-2 py-1 rounded-full 
                ${
                  part.installation === true
                    ? "bg-green-50 text-green-600"
                    : "bg-red-50 text-red-600"
                }`}
                          >
                            {part.status || "Pending"}
                          </span>
                        </div>
                      </div>

                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-gray-50 p-4 rounded-lg text-center">
                  <p className="text-sm text-gray-500">No parts listed</p>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceHistory;
