
import React from "react";


const AssetTableHeader = ({
  assetDetails,
  activeCount,
  expiredCount,
  FreeAssetCount,
  soldAssets,
  trackerInstalledAssets,
}) => {
  const HeaderData = [
    {
      id: 1,
      title: "Total Assets Bought",
      label: "The total number of assets bought by customers",
      data: assetDetails.length,
      placement: "bottom"
    }, {
      id: 2,
      title: "Total Rental Assets",
      label: "The number of assets in rental",
      data: assetDetails.length - soldAssets,
      placement: "bottom"
    },
    {
      id: 3,
      title: "Assets With Active Lease",
      label: "The number of assets currently under active lease agreements",
      data: activeCount,
      placement: "bottom"
    },

    {
      id: 4,
      title: "Assets With Exceeded Lease",
      label: "The number of assets with leases that have expired",
      data: expiredCount,
      placement: "bottom"
    },
    {
      id: 5,
      title: "Free Assets",
      label: "The number of assets available for lease or not in use",
      data: (assetDetails.length - soldAssets) - activeCount - expiredCount, // rental asset - active asset - breakdown asset  - expired asset
      placement: "bottom"
    },
    {
      id: 6,
      title: "Sold Assets",
      label: "The number of assets that have been sold",
      data: soldAssets,
      placement: "bottom"
    },
    {
      id: 7,
      title: "Tracker Installed Assets",
      label: "The number of assets with installed tracking devices",
      data: trackerInstalledAssets,
      placement: "bottom"
    },

  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-4 ">
      {HeaderData.map((data) => (
          <div title={data.title} className="p-4 gap-2 flex flex-col rounded-[16px] border bg-gradient-to-r bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out cursor-pointer">
            <h2 className=" text-[12px] font-normal text-gray-700">{data.title}</h2>
            <p className="text-[22px]  font-extrabold text-dark-6">{data.data}</p>
          </div>
      ))}
    </div>
  );
};

export default AssetTableHeader;
