import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setBaseUrl } from "../../config";
import { NavbarContext } from "../../Context/NavbarContext";
import { PermissionContext } from "../../Context/PermissionsContext";
import OuterLoader from "../../pages/OuterLoder";
import backgVideo from "../../assets/videos/Access-banner.mp4";
import DurbinLogo from "../../assets/images/DurbinLogo.jpg";
import useLocalstorage from "../../utils/useLocalstorage";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../Layout";
import { useToast } from "../../Context/ToastContext";

const SalesLogin = () => {
  const [navState, setNavState] = useContext(NavbarContext);
  const [perms, setPerms] = useContext(PermissionContext);
  const { setValues, getValues } = useLocalstorage();
  const navigate = useNavigate();
  const emailInputRef = useRef(null);
  const {addToast} = useToast()

  const [data, setData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  //   useEffect(() => {
  //     setNavState(1);
  //     checkIfLoggedIn();
  //     focusEmailInput();
  //   }, []);

  const focusEmailInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  const checkIfLoggedIn = () => {
    const isLoggedIn = sessionStorage.getItem("token");
    if (isLoggedIn) {
      navigate("/sales-services/dashboard");
    }
  };


  const LoginFunction = async () => {
    try {
      setLoader(true);
      const res = await fetch(`${setBaseUrl}/sale-service/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });





      setLoader(false);

      const response = await res.json();
    
      //   navigateToPage(permissions);
      if (res.status === 200) {

        const token_id = response.data.user_token;
        const role = response.data.role;

        const username = response.data.name;
        const email = response.data.email;
        const user_id = response.data.id;

        sessionStorage.setItem("sales_token", token_id);
        sessionStorage.setItem("sales_role", role);
        sessionStorage.setItem("sales_email", data.email)
        sessionStorage.setItem("sales_name", username)
        sessionStorage.setItem("sales_id", user_id)
        sessionStorage.setItem("sales_person_logged_in", "true")

        navigate("/sales-services/dashboard")
       addToast("Sales person successfully logged in", "success");
      }
    } catch (error) {
      setLoader(false);
     addToast("Please Enter Valid Email and Password!", "error");
      console.error(error)
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };



  if (loader) return <OuterLoader />;

  return (
    <Layout>
      <div className="flex justify-center items-center h-screen text-xs">
        <div className="relative w-full h-full">

          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-30">
            <form
              className="bg-white text-black  w-[389.42px] h-[430.66px] rounded-lg shadow-lg"
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  data.email &&
                  data.password
                  // &&
                  // isValidEmail(data.email) &&
                  // isValidPassword(data.password)
                ) {
                  LoginFunction();
                }
              }}
            >

              <div className="flex flex-col  text-left gap-3 items-start border-b-[1.5px] p-6 border-light">
                {/* Header */}
                <header className="text-[32px] font-bold"> Login</header>
                <div className="flex flex-col ">

                  <span className="font-extrabold text-[14px]">Sales & Service</span>
                  <span className="font-normal text-[12px]">Enter your email below to login to your account</span>
                </div>
              </div>


              {/* Input Form */}
              <div className="flex flex-col p-6">

                {/* Email Input */}
                <div className="my-2.5 ">
                  <label htmlFor="" className="text-[13.09px] font-extrabold">Email</label>
                  <input
                    title="Enter email address"
                    ref={emailInputRef}
                    type="email"
                    id="email"
                    placeholder="example@company.com"
                    value={data.email}
                    onChange={(e) => setData({ ...data, email: e.target.value })}
                    className={`w-[314.81px] h-[40px] px-4 py-2 text-sm border rounded-lg focus:outline-none ${data.email && !isValidEmail(data.email)
                      ? "border-red-500"
                      : "border-gray-300"
                      }`}
                  />
                  {data.email && !isValidEmail(data.email) && (
                    <p className="text-red-500 text-xs mt-1">
                      Invalid email format
                    </p>
                  )}
                </div>

                {/* Password */}
                <div className="mb-4 relative text-xs">
                  <span className="flex justify-between mb-0.5">
                    <label htmlFor="" className="text-[13.09px] font-extrabold">Password</label>
                    <span className="underline" onClick={() => navigate('/sales-services/forgot-password')}>Forgot your password? </span>
                  </span>
                  <input
                    title="Enter password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="password"
                    value={data.password}
                    onChange={(e) => setData({ ...data, password: e.target.value })}
                    className={`w-[314.81px] h-[40px] px-4 py-2 text-sm border rounded-lg focus:outline-none ${data.password
                      //  && !isValidPassword(data.password)
                      ? "border-red-500"
                      : "border-gray-300"
                      }`}
                  />
                  {data.password && (
                    <div
                      className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer items-center mt-3 text-dark-6"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <RemoveRedEyeOutlinedIcon />
                      )}
                    </div>
                  )}
                </div>


                {/* Submit Button */}
                <button
                  type="submit"
                  className={` py-3 rounded-lg mb-3 font-normal text-white text-sm transition-all duration-300 ${data.email &&
                    data.password &&
                    isValidEmail(data.email)
                    // &&            isValidPassword(data.password)
                    ? "bg-green-2 hover:bg-green-3"
                    : "bg-dark-6 cursor-not-allowed"
                    }`}
                  disabled={
                    !(
                      data.email &&
                      data.password &&
                      isValidEmail(data.email)
                      // &&                  isValidPassword(data.password)
                    )
                  }
                >
                  {loader ? <span className="animate-spin">⏳</span> : "Sign In"}
                </button>
               
               {/* Create New Account */}
                <button onClick={() => navigate("/sales-services/register/")} className="py-3 border border-green-2 rounded-lg mb-3 font-normal text-green-2 text-sm hover:bg-green-2 hover:text-white  transition-all duration-300">
                  Create New Account
                </button>
               
               

              </div>


            </form>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default SalesLogin;



// john@yopmail.com
// QAUv8D+o
// Service Person

// arkhoprovo@durbin.live
// V-2Ln2W"
// Sales Person