import React from "react";
import DurbinLogo from "../assets/images/DurbinLogo.jpg";
import { useNavigate } from "react-router-dom";
import backgVideo from "../assets/videos/Access-banner.mp4";
import { motion } from "framer-motion";
const Layout = ({ children }) => {
  const navigate = useNavigate()

  return (
    <div className="h-screen flex flex-col">
      {/* Header */}
      <header className="bg-white  flex items-center justify-between p-2 shadow-md border-b-2 border-gray-200">
        {/* Logo & Title */}
        <div className="flex items-center space-x-2">
          <img
            src={DurbinLogo}
            alt="Durbin Logo"
            className="w-[40px] h-[40px] rounded-full"
          />
          <h1 className="text-xl font-bold text-gray-800">Asset Tracker</h1>
        </div>
      
         <div className="flex gap-4">
      {/* Company Login Button */}
      <motion.button
        whileHover={{ scale: 1, backgroundColor: "#066253", color: "white" }}
        whileTap={{ scale: 0.60 }}
        className="border border-green-2 text-green-2 font-normal p-3 text-xs rounded-[0px] transition-all"
        onClick={() => navigate("/login")}
      >
  Operations
      </motion.button>

      {/* Sales & Service Button */}
      <motion.button
        whileHover={{ scale: 1, backgroundColor: "white", color: "black", borderColor: "#097C69" }}
        whileTap={{ scale: 0.95 }}
        className="bg-green-2 text-white border border-green-2 font-normal p-3 text-xs rounded-[0px] transition-all"
        onClick={() => navigate("/sales-services/login")}
      >
        Sales & Service
      </motion.button>
    </div>
      </header>

      {/* Centered Children */}
      <section className="flex flex-grow items-center justify-center">
        <div className="relative w-full h-full">
          {/* Background Video */}
          {/* <video
            className="w-full h-full object-cover"
            src={backgVideo}
            autoPlay
            loop
            muted
          /> */}
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center ">
            {children}

          </div>
        </div>
      </section>
    </div>
  );
};

export default Layout;
// 