import React, { useContext, useEffect, useState } from "react";
import Navbar from "./navbar/Navbar";
import logo from "../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { NavbarContext } from "../Context/NavbarContext";
import { PermissionContext } from "../Context/PermissionsContext";
import webSiteTheme from "../utils/Theme";
import useAuthorities from "../utils/useAuthorities";
import Sidebar from "./navbar/Sidebar";
// import EngineeringIcon from "@mui/icons-material/Engineering";
// import MobileNav from "./navbar/MobileNav";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
// import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";

// import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import { FaInstalod } from "react-icons/fa6";
// import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import { GiHamburgerMenu } from "react-icons/gi";
// import { FaRegBookmark } from "react-icons/fa";
// import { MdErrorOutline } from "react-icons/md";
// import { MdOutlineInventory2 } from "react-icons/md";

function Layout({ pageNum, children, showCurrentTab, setShowCurrentTab }) {
  // const navigate = useNavigate();
  const [theme, setTheme] = useState("");
  const [navState, setNavState] = useContext(NavbarContext);
  // const [perms, setPerms] = useContext(PermissionContext);
  const [perms] = useContext(PermissionContext);
  const { assetsTabPerm, maintenanceTabPerm, leasesTabPerm, operatorsTabPerm } =
    useAuthorities();

  useEffect(() => {
    webSiteTheme();
    setTheme(sessionStorage.getItem("theme"));
  }, [theme]);

  const onClickTabChange = (number) => {
    
    setNavState(number);
  };

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const [open, setOpen] = useState(false);

  // const NavData = ['Dashboard', 'Assets',  'Maintenance', 'Lease', 'Operators', 'Settings']
  // const sidebarData = [
  //   {
  //     title: "Dashboard",
  //     route: "dashboard",
  //     imgUrl: HomeOutlinedIcon,
  //   },
  //   {
  //     title: "Assets",
  //     route: "assets",
  //     imgUrl: BusinessCenterOutlinedIcon,
  //   },
  //   {
  //     title: "Maintenance",
  //     route: "maintenance",
  //     imgUrl: ManageHistoryIcon,
  //   },
  //   {
  //     title: "Lease",
  //     route: "lease",
  //     imgUrl: FeedOutlinedIcon,
  //   },
  //   {
  //     title: "Operators",
  //     route: "operators",
  //     imgUrl: EngineeringIcon,
  //   },
  //   {
  //     title: "Settings",
  //     route: "settings",
  //     imgUrl: SettingsOutlinedIcon,
  //   },
  //   {
  //     title: "Logistics",
  //     route: "logistics",
  //     imgUrl: FaInstalod,
  //   },
  //   {
  //     title: 'Error',
  //     route: 'error-module',
  //     imgUrl: MdErrorOutline
  //   },
  //   {
  //     title: 'Book',
  //     route: 'booking',
  //     imgUrl: FaRegBookmark
  //   }, {
  //     title: 'Inventory',
  //     route: 'inventory',
  //     imgUrl: MdOutlineInventory2
  //   }
  // ];
  // const path = useLocation().pathname;
  // const [hover, setHover] = useState(false);
  const [openMobileTab, setOpenMobileTab] = useState(false);

  return (
    <>
      {/* Header */}
         <Navbar
        theme={theme}
        setTheme={setTheme}
        setOpen={setOpen}
        open={open}
        // hover={hover}
        // openMobileTab={openMobileTab}
        // setOpenMobileTab={setOpenMobileTab}
        />

      <GiHamburgerMenu className="h-12 md:hidden" onClick={() => setOpenMobileTab(true)}/>
      <div className="flex">
        {/* Laptop Sidebar */}
        {/* <div
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={`mt-11  h-full max-md:hidden fixed ${hover ? `w-[200px]` : `w-[60px]`
            } z-[9999] transition-all ease-in-out duration-300 bg-white border-r dark:border-none dark:bg-gray-950 text-black shadow-2xl`}
        >

          <div className=" flex flex-col gap-4 mt-8  ">
            {sidebarData.map((nav) => {
              const isActive = path.split("/")[1] === nav.route;

              return (
                <>
                  <Link
                    to={`/${nav.route}`}
                    key={nav.route}
                    className={` flex flex-rowb p-2   mx-4  items-center 
                      ${hover
                        ? `  rounded-[4px] `
                        : ` items-center justify-center `
                      }   
                      ${isActive
                        ? "bg-green-2  shadow-xl text-white rounded-[6px]"
                        : "bg-transparent text-black dark:text-white "
                      }`}
                  >
                    <nav.imgUrl className="  fixed mx-1 h-6 w-6  p-0.5" />
                    <h1
                      className={`text-sm font-regular  ${hover
                        ? "opacity-100  ml-10 transition-all duration-500  ease-in-out"
                        : "opacity-0"
                        }`}
                    >
                      {nav.title}
                    </h1>
                  </Link>
                </>
              );
            })}
          </div>
        </div> */}

        <Sidebar/>

        <div className=" relative flex flex-col md:pl-[60px] mt-[64px] h-full w-full">
        {/* Main section */}
          <div className="pt-2  xl:pt-3 px-3 flex-auto">
            <div className="tab-content tab-space text-xs">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
