import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { lastEventData } from "../../../apis/Telematics/last_event_data";
import moment from "moment";

const TelematicsData = ({ devId }) => {
  const [internalBattery, setInternalBattery] = useState(null);
  const [vehicleBatteryVol, setVehicleBatteryVol] = useState(null);
  const [turnSwitch, setTurnSwitch] = useState(false);
  const [DeviceOnOff, setDeviceOnOff] = useState("OFF");
  const [lastUpdated, setLastUpdated] = useState(new Date());

  useEffect(() => {
    const socket = io("https://websocket.durbinservices.com/", {
      extraHeaders: {
        "x-access-token": process.env.REACT_APP_SOCKET_DATA_TOKEN,
      },
    });

    socket.on(`genie/maco-storm/remote-start-stop/${devId}`, (data) => {
      // Handle socket data if needed
    });

    return () => {
      socket.disconnect();
    };
  }, [devId, DeviceOnOff]);

  const deviceOnOffEvent = (triggeredEvent) => {
    const socket = io("https://websocket.durbinservices.com/");
    const command = turnSwitch ? "TURN_OFF" : "TURN_ON";
    socket.emit(
      "client-message",
      `genie/maco-storm/remote-start-stop/${devId}#${command}`
    );
    setTurnSwitch((prev) => !prev);
  };

  const isDataRecent = (timestamp) => {
    const fifteenMinutesInMs = 1 * 60 * 1000;
    const now = new Date().getTime();
    return now - timestamp <= fifteenMinutesInMs;
  };

  const getAllEventData = async () => {
    try {
      const getData = await lastEventData(devId);
      setLastUpdated(new Date());

      // Power Data Push Data
      if (getData?.pwr_data_push) {
        const rawData = JSON.parse(getData?.pwr_data_push?.data);
        let SOC = Math.min(Math.max(parseFloat(rawData["SOC"]), 1), 100);
        const currentTime = new Date().getTime();
        const storedSOC = JSON.parse(localStorage.getItem("SOCData"));

        if (storedSOC && isDataRecent(storedSOC.timestamp)) {
          setInternalBattery(parseInt(storedSOC.value));
        } else {
          setInternalBattery(Math.floor(SOC));
          localStorage.setItem(
            "SOCData",
            JSON.stringify({ value: SOC, timestamp: currentTime })
          );
        }
      }

      // Compare Battery Voltage
      const pwrDataPushTime = getData?.pwr_data_push?.created_at
        ? new Date(getData?.pwr_data_push?.created_at)
        : null;

      const vehicleStatusTime = getData?.vehicle_engine_status?.created_at
        ? new Date(getData?.vehicle_engine_status?.created_at)
        : null;

      const now = Date.now();
      let vehicleBatteryVol = null;

      if (pwrDataPushTime && vehicleStatusTime) {
        const pwrDiff = Math.abs(now - pwrDataPushTime.getTime());
        const vehicleStatusDiff = Math.abs(now - vehicleStatusTime.getTime());

        if (pwrDiff > vehicleStatusDiff) {
          vehicleBatteryVol = Number(
            JSON.parse(getData?.pwr_data_push?.data)?.ExVOLT
          ).toFixed(2);
        } else {
          vehicleBatteryVol = getData?.vehicle_engine_status?.data
            .split(" - ")[1]
            ?.replace("v", "")
            .trim();
        }
      } else if (pwrDataPushTime) {
        vehicleBatteryVol = JSON.parse(getData?.pwr_data_push?.data)?.ExVOLT;
      } else if (vehicleStatusTime) {
        vehicleBatteryVol =
          getData?.vehicle_engine_status?.data.split(" - ")[1];
      }

      setVehicleBatteryVol(vehicleBatteryVol);
      setDeviceOnOff(
        vehicleBatteryVol >= 13.45
          ? "ON"
          : vehicleBatteryVol <= 13.3
          ? "OFF"
          : DeviceOnOff
      );
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  useEffect(() => {
    getAllEventData();
    const interval = setInterval(getAllEventData, 60000); // Update every minute
    return () => clearInterval(interval);
  }, [devId]);

  const getBatteryStatus = (voltage) => {
    if (!voltage) return { text: "N/A", color: "gray" };
    if (voltage < 10) return { text: "Low", color: "red" };
    if (voltage < 11.5) return { text: "Normal", color: "yellow" };
    return { text: "Optimal", color: "green" };
  };

  const batteryStatus = getBatteryStatus(vehicleBatteryVol);

  return (
    <div className=" w-[20vw] bg-white rounded-lg shadow-md border border-gray-200 dark:border-gray-700 dark:bg-gray-800 p-4">
      <div className="flex  justify-between items-center mb-1 pb-1">
        <h2 className="text-sm font-semibold text-gray-800 dark:text-white">
          Telematics Data
        </h2>
        {/* <button 
            onClick={deviceOnOffEvent}
            className={`px-3 py-1 text-sm rounded-md ${
              turnSwitch ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
          >
            {turnSwitch ? 'ON' : 'OFF'}
          </button> */}
      </div>

      <div className="grid grid-cols-1  gap-4">
        {/* Device Statuws */}
        <div className="bg-light-8 dark:bg-gray-700/50 rounded-lg p-4 h-[80px]">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
              Device Status
            </h3>
            {/* Active / Deep Sleep */}
            <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
              <span className="items-center flex justify-end">
                <div
                  className={`w-2 h-2 rounded-full mr-2 bg-${batteryStatus.color}-500`}
                ></div>
                Active{" "}
              </span>
            </span>
          </div>
          {/* Device Status Details */}

          <div className="flex justify-between items-center">
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
              Last Data
            </h3>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
              {/* This needds to Verify || Only Showing Current time change it later */}
              {lastUpdated ? moment(lastUpdated).format("HH:mm") : "N/A"} 
            </span>
          </div>
        </div>
        {/* Asset Tracker Battery */}
        <div className="bg-light-8 dark:bg-gray-700/50 rounded-lg p-4 h-[80px]">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
              Asset Battery
            </h3>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
              {internalBattery == null ? "N/A" : `${internalBattery}%`}
            </span>
          </div>
          <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2.5">
            <div
              className={`h-2.5 rounded-full ${
                !internalBattery || internalBattery <= 20
                  ? "bg-red-500"
                  : internalBattery <= 60
                  ? "bg-yellow-500"
                  : "bg-green-500"
              }`}
              style={{ width: `${internalBattery || 0}%` }}
            ></div>
          </div>
        </div>

        {/* Vehicle Battery Voltage */}
        <div className="bg-light-8 dark:bg-gray-700/50 rounded-lg p-4 h-[80px]">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
              Vehicle Battery
            </h3>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
              {vehicleBatteryVol == null ? "N/A" : `${vehicleBatteryVol}V`}
            </span>
          </div>
          <div className="flex items-center">
            <div
              className={`w-2 h-2 rounded-full mr-2 bg-${batteryStatus.color}-500`}
            ></div>
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {batteryStatus.text}
            </span>
          </div>
        </div>

        {/* Engine Status */}
        <div className="bg-light-8 dark:bg-gray-700/50 rounded-lg p-4 h-[80px]">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
              Engine Status
            </h3>
            <div className="flex items-center">
              <div
                className={`w-2 h-2 rounded-full mr-2 ${
                  DeviceOnOff === "ON" ? "bg-green-500" : "bg-gray-400"
                }`}
              ></div>
              <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
                {DeviceOnOff || "N/A"}
              </span>
            </div>
          </div>
          <div className="text-xs text-gray-500 dark:text-gray-400">
            Updated: {moment(lastUpdated).format("HH:mm")}
          </div>
        </div>

        {/* 
        If( Last Data > 10 or 15 min => Sleep Mode else Active )
        */}
      </div>
    </div>
  );
};

export default TelematicsData;
