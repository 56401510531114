// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { setBaseUrl } from "../../config";
// import { Link, useNavigate } from "react-router-dom";

// const CheckOldOperator = ({
//   adhaarChecked,
//   setAdhaarChecked,
//   // newAdhaar,
//   // setNewAdhaar,
//   newDob,
//   setNewDob,
//   operatorData,
//   setOperatorData,
// }) => {
//   const [adhaarNumberInput, setAdhaarNumberInput] = useState("");
//   const [oldOperator, setOldOperator] = useState(false);
//   const [searchSuggestions, setSearchSuggestions] = useState([]);
//   const [searchData, setSearchData] = useState([]);
//   const [error, setError] = useState("");
//   const [id, setId] = useState({});
//   const navigate = useNavigate();

//   // Fetch Aadhaar data for search suggestions
//   const getSearchData = async () => {
//     try {
//       const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//       });
//       let dataArryUserId = [];
//       let aadharArr = Object.keys(data["aadhar"]);
//       for (let i = 0; i < aadharArr.length; i++) {
//         dataArryUserId.push({
//           [aadharArr[i]]: data["aadhar"][aadharArr[i]],
//         });
//       }
//       setSearchData(dataArryUserId);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     getSearchData();
//   }, []);

//   // Enter Adhaar number
//   const handleCheckAdhaar = (e) => {
//     const input = e.target.value;

//     // Check if the input is a valid number and has exactly 12 digits
//     if (!/^\d+$/.test(input) && input !== "") {
//       setError("Aadhaar number must contain only digits");
//       return;
//     }

//     if (input.length > 12) {
//       setError("Aadhaar number must not exceed 12 digits");
//       return;
//     }

//     operatorData.aadhar_no = input;
//     setError("");
//     setAdhaarNumberInput(input);
//     setOperatorData(operatorData);

//     let filteredData = [];
//     for (let i = 0; i < searchData.length; i++) {
//       if (Object.keys(searchData[i])[0].indexOf(input) !== -1) {
//         filteredData.push(searchData[i]);
//       }
//     }
//     setSearchSuggestions(filteredData);
//     if (input.length === 12) {
//       setOldOperator(filteredData.length > 0);
//     } else {
//       setOldOperator(false);
//     }
//   };

//   const [dobinput, setDobinput] = useState("");
//   const [isEligible, setIsEligible] = useState(false);

//   const handleCheckDOB = (e) => {
//     setDobinput(e.target.value);

//     const today = new Date();
//     const birthDate = new Date(e.target.value);

//     // Calculate the age difference
//     const age = today.getFullYear() - birthDate.getFullYear();
//     const monthDiff = today.getMonth() - birthDate.getMonth();
//     const dayDiff = today.getDate() - birthDate.getDate();

//     // Check if age is 18 or above
//     if (
//       age > 18 ||
//       (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))
//     ) {
//       setIsEligible(true);
//       console.log("Eligible");
//       setNewDob(e.target.value);
//     } else {
//       setIsEligible(false);
//       console.log("Not Eligible");
//     }
//   };

//   const handleCreate = (e) => {
//     e.preventDefault();
//     if (adhaarNumberInput.length !== 12 || null) {
//       setError("Aadhaar number must be exactly 12 digits");
//       return;
//     }

//     if (oldOperator) {
//       navigate(`/operators/updateOperator/${id}`);
//     } else {
//       if(isEligible){
//         setOldOperator(false);
//         setAdhaarChecked(true);
//       }
//     }
//   };

//   return (
//     <>
//       <div className="items-center text-xs flex justify-center">
//         <form
//           className=" w-[536px] h-[484px] items-center mt-3 flex flex-col justify-center space-y-3  "
//           // onSubmit={handleCreate}
//         >
//           <h1 className="text-center absolute top-10 font-bold text-slate-700 dark:text-white ">
//             Operators
//           </h1>
//           <span>Please Enter Aadhaar No</span>
//           <input
//             type="text"
//             placeholder="Enter Valid Aadhaar No."
//             className="p-2 mx-4 text-xs w-[200px] bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
//             value={adhaarNumberInput}
//             onChange={handleCheckAdhaar}
//             maxLength={12}
//           />

//           {error && <p className="text-red-500">{error}</p>}

//           {adhaarNumberInput !== "" && (
//             <>
//               {" "}
//               {!oldOperator && (
//                 <>
//                   <input
//                     type="date"
//                     className="p-2 mx-4 text-xs w-[200px] bg-light-4 border-none focus:border-none  focus:outline-none focus:ring-0 rounded"
//                     value={dobinput}
//                     onChange={handleCheckDOB}
//                     maxLength={12}
//                   />
//                   {dobinput !== "" && (
//                     <>
//                       {" "}
//                       {isEligible ? (
//                         <p></p>
//                       ) : (
//                         <p className="text-red-600">Not Eligible Must Be 18+</p>
//                       )}
//                     </>
//                   )}
//                 </>
//               )}
//             </>
//           )}

//           <div className="flex justify-between gap-10 bg-white">
//             <Link to={"/operators"}>
//               <button
//                 type="button"
//                 className="w-[84px]  text-xs h-7 transition-all duration-200 ease-in shadow-md px-2 self-stretch rounded-[6px] "
//               >
//                 Back
//               </button>
//             </Link>
//             {/*
//             The Logic for the Button
//             case 1: Empty Adhaar - Disabled // not allowed
//             case 2: New Adhaar - Check Age - Blue // create new
//             case 3: Old Adhaar - Red // Rejoin
//             */}
//             <button
//               onClick={handleCreate}
//               className={` transition-all duration-200 ease-in px-2 self-stretch rounded-[6px] shadow-md ${
//                 // oldOperator || isEligible
//                 //   ? "cursor-pointer bg-blue-3 text-white"
//                 //   : "cursor-not-allowed bg-light-8 text-light-2"

//                 adhaarNumberInput === ""
//                   ? "cursor-not-allowed bg-light-8 text-light-2"
//                   : oldOperator
//                   ? "cursor-pointer bg-red-1 text-white"
//                   : !oldOperator && isEligible
//                   ? "cursor-pointer bg-blue-3 text-white"
//                   : "cursor-not-allowed bg-light-8 text-light-2"
//               }`}
//             >
//               {oldOperator ? "Rejoin" : "Create New"}
//             </button>
//           </div>
//         </form>
//         <div className="flex flex-col fixed z-[9999] top-[60%]   w-[300px] text-sm items-center jsutify-center h-[120px] overflow-y-auto">
//           {/* Search Suggestions */}
//           {searchSuggestions?.map((item, i) => {
//             const aadhaar = Object.keys(item)[0];

//             // Function to highlight digits found anywhere in the input
//             const highlightAadhaar = (aadhaarNumber) => {
//               return aadhaarNumber.split("").map((digit, index) => {
//                 // Check if the digit is found anywhere in the input
//                 const isMatched =
//                   adhaarNumberInput && adhaarNumberInput.includes(digit);
//                 return (
//                   <span
//                     key={index}
//                     className={isMatched ? "text-green-500 font-bold" : ""}
//                   >
//                     {digit}
//                   </span>
//                 );
//               });
//             };

//             return (
//               <div
//                 key={i}
//                 className="p-2 flex items-center gap-2 cursor-pointer w-full hover:bg-gray-100 transition duration-200"
//                 onClick={() => {
//                   setAdhaarNumberInput(aadhaar); // Set selected Aadhaar in the input
//                   setId(item[aadhaar]); // Set the corresponding ID
//                   setOldOperator(true);
//                 }}
//               >
//                 <div className="flex-1 text-gray-700 text-xs font-medium">
//                   {highlightAadhaar(aadhaar)}
//                 </div>
//                 {/* Call the function to render the highlighted Aadhaar */}
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </>
//   );
// };

// export default CheckOldOperator;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Alert,
} from "@mui/material";

const CheckOldOperator = ({
  adhaarChecked,
  setAdhaarChecked,
  newDob,
  setNewDob,
  operatorData,
  setOperatorData,
}) => {
  const [adhaarNumberInput, setAdhaarNumberInput] = useState("");
  const [oldOperator, setOldOperator] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [error, setError] = useState("");
  const [id, setId] = useState({});
  const [dobinput, setDobinput] = useState("");
  const [isEligible, setIsEligible] = useState(false);

  const navigate = useNavigate();

  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      const dataArray = Object.entries(data["aadhar"]).map(([key, value]) => ({
        [key]: value,
      }));
      setSearchData(dataArray);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearchData();
  }, []);

  const handleCheckAdhaar = (e) => {
    const input = e.target.value;

    if (!/^\d+$/.test(input) && input !== "") {
      setError("Aadhaar number must contain only digits");
      return;
    }

    if (input.length > 12) {
      setError("Aadhaar number must not exceed 12 digits");
      return;
    }

    setError("");
    setAdhaarNumberInput(input);
    setOperatorData({ ...operatorData, aadhar_no: input });

    const filteredData = searchData.filter((item) =>
      Object.keys(item)[0].startsWith(input)
    );
    setSearchSuggestions(filteredData);

    setOldOperator(input.length === 12 && filteredData.length > 0);
  };

  const handleCheckDOB = (e) => {
    const dob = e.target.value;
    setDobinput(dob);

    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    const isOfAge =
      age > 18 ||
      (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));

    setIsEligible(isOfAge);
    if (isOfAge) setNewDob(dob);
  };

  const handleCreate = (e) => {
    e.preventDefault();

    if (adhaarNumberInput.length !== 12) {
      setError("Aadhaar number must be exactly 12 digits");
      return;
    }

    if (oldOperator) {
      navigate(`/operators/updateOperator/${id}`);
    } else if (isEligible) {
      setOldOperator(false);
      setAdhaarChecked(true);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="grey.100"
    >
      <Card elevation={3} sx={{ width: 400, p: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom textAlign="center">
            Operator Verification
          </Typography>

       
<h1 className="text-dark-6 font-semibold text-base my-2">Aadhaar Number</h1>
          <input type="text" className="border focus:ring-0 focus:outline-none border-light-2 w-full rounded-[4px] " value={adhaarNumberInput}   onChange={handleCheckAdhaar}
          placeholder="Enter Aadhaar No"
          
          />

          {adhaarNumberInput && !oldOperator && (
            <>

            <TextField
              fullWidth
              label="Enter Date of Birth"
              type="date"
              value={dobinput}
              onChange={handleCheckDOB}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              error={dobinput && !isEligible}
              helperText={
                dobinput && !isEligible ? "You must be at least 18 years old to continue." : ""
              }
              />
              </>
          )}

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              component={Link}
              to="/operators"
              variant="outlined"
              color="secondary"
            >
              Back
            </Button>

            <Button
              onClick={handleCreate}
              variant="contained"
              color={
                adhaarNumberInput === ""
                  ? "inherit"
                  : oldOperator
                  ? "error"
                  : isEligible
                  ? "primary"
                  : "inherit"
              }
              disabled={!adhaarNumberInput || (!oldOperator && !isEligible)}
            >
              {oldOperator ? "Rejoin" : "Create New"}
            </Button>
          </Box>

          {searchSuggestions.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Search Suggestions</Typography>
              <List style={{ height: '200px', overflow:"auto", backgroundColor: '#efefefd5'}}>
                {searchSuggestions.map((item, index) => {
                  const aadhaar = Object.keys(item)[0];
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          setAdhaarNumberInput(aadhaar);
                          setId(item[aadhaar]);
                          setOldOperator(true);
                        }}
                      >
                        <ListItemText primary={aadhaar} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CheckOldOperator;
