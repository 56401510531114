import React from 'react'
import logo from "../../assets/images/logo.png";
import { capitalize } from '@mui/material';
import { FiMenu } from "react-icons/fi";


const SalesNavbar = ({ isSidebarOpen, setIsSidebarOpen }) => {

  const user = sessionStorage.getItem('sales_name')

  return (
    // <div>
         <header className="bg-white shadow-md p-2 flex justify-between items-center w-[100%]">
         <div className={`flex justify-start items-center transition-all translate-x-1 duration-300 ease-in-out  w-[300px] `}>
          <button
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    className="p-2 text-gray-600 hover:bg-gray-200 rounded transition"
                  >
                    <FiMenu size={20} />
                  </button>
              <img className="w-8  mb-1 ml-2" src={logo} alt="logo" />
              <p className=" font-semibold  text-dark-6 text-base">
                Asset <span className="font-semibold">Tracker</span>
              </p>
            </div>
          <div className="flex items-center space-x-4 text-xs">
            <span className="text-gray-700">Welcome, {capitalize(user)}</span>
          </div>
        </header>
 
    // </div>
  )
}

export default SalesNavbar