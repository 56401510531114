import axios from "axios";
import React, { useState } from "react";
import { setBaseUrl } from "../../../config";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../../Context/LoaderContext";
import { useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { formatDate } from "../../Modals/AssetDetails/Tabs/FormatDate";

function CurrentItems({
  allOperators,
  deleted,
  setDeleted,
  sortHeader,
  openFilter,
  setOpenFilter,

  tableArray,
  selectTableData,
  setSelectTableData,
  openSort,
  setOpenSort,
  sort,
  setSort,
  sortOrder,
  setSortOrder,
  inputSearch,
  setInputSearch,
  filteredHeaders,
  sortedOperatorDetails,
  filteredDataStack,
  setFilteredDataStack,
}) {
  const [openTable, setopenTable] = useState(false);
  const [tableAnimation, setTableAnimation] = useState(false);
  const [loader, setLoader] = useContext(LoaderContext);
  const [perms] = useContext(PermissionContext);
  const navigate = useNavigate();

  const openDetailsOnClick = (id) => {
    navigate(`details/${id}`);
  };

  const openUpdateOperator = (id) => {
    navigate(`updateOperator/${id}`);
  };
  const deleteOperator = async (id) => {
    try {
      setLoader(true);
      const { data } = await axios.delete(`${setBaseUrl}/operator/delete`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        data: { id: id },
      });
      setDeleted((prev) => !prev);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoader(false);
      return;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTableAnimation(true);
    }, 100);
  }, [loader]);

  // sortedOperatorDetails = [...allOperators].sort((a, b) => {
  //   if (!sort) return 0; // No sorting if sort is null

  //   const aValue =
  //     a[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || ""; // Convert the sort string to match the object key
  //   const bValue =
  //     b[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";

  //   if (aValue < bValue) {
  //     return sortOrder === "asc" ? -1 : 1;
  //   }
  //   if (aValue > bValue) {
  //     return sortOrder === "asc" ? 1 : -1;
  //   }
  //   return 0;
  // });

  sortedOperatorDetails = [...allOperators].sort((a, b) => {
    if (!sort) return 0; // No sorting if no header is selected

    const aValue =
      a[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";
    const bValue =
      b[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";

    return sortOrder === "asc"
      ? aValue.localeCompare(bValue) // Ascending order
      : bValue.localeCompare(aValue); // Descending order
  });

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  // input select

  // const [items, setItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const handleSelectAllChange = (event) => {
    const newAllSelected = event.target.checked;
    setAllSelected(newAllSelected);

    const updatedItems = allOperators.map((item) => ({
      ...item,
      selected: newAllSelected,
    }));
    // setFilteredDataStack(updatedItems);
  };

  // const navigate = useNavigate()
  const handleItemChange = (itemId) => {
    // const updatedItems = filteredDataStack.map((item) =>
    //   item.id === itemId ? { ...item, selected: !item.selected } : item
    // );

    // allOperators(updatedItems);

    // Update `allSelected` if all items are selected after the change
    // setAllSelected(updatedItems.every((item) => item.selected));

    navigate(`/operators/details/${itemId}`);
  };


    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = selectTableData;
  
    // Calculate indices for the current rows
    const indexOfLastRow = Math.min(
      currentPage * rowsPerPage,
      allOperators.length
    ); // Clamp to total length
    const indexOfFirstRow = Math.min(
      indexOfLastRow - rowsPerPage + 1,
      allOperators.length
    ); // Adjust for the last page
  
    // Adjust to ensure only valid rows are displayed
    const currentRows = allOperators.slice(
      indexOfFirstRow - 1,
      indexOfLastRow
    ); // Adjust for zero-based index
  
    const totalPages = Math.ceil(allOperators.length / rowsPerPage);
  
    const handleNextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage((prev) => prev + 1);
      }
    };
  
    const handlePreviousPage = () => {
      if (currentPage > 1) {
        setCurrentPage((prev) => prev - 1);
      }
    };
  
    const handlePageClick = (page) => {
      setCurrentPage(page);
    };
  return (
    <>
      <div className="flex items-center justify-end">
        {/* Open Table Data */}
        {openTable && (
          <>
            <div
              className={` absolute mt-10 z-[9999]  p-1.5 text-xs ${
                selectTableData === null ? "right-[10.2%]" : "right-[21%]"
              } 
                flex flex-col justify-center 
               bg-white dark:bg-[#070e18] w-[80px] items-center shadow-2xl rounded-lg h-fit`}
            >
              {tableArray.map((table) => (
                <>
                  {" "}
                  <button
                    key={table}
                    className="hover:bg-[#EEE] w-full  py-1  dark:hover:text-black  border-b"
                    onClick={() => setSelectTableData(table)}
                  >
                    {table}
                  </button>{" "}
                </>
              ))}
            </div>
            <div
              className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
              onClick={() => setopenTable(false)}
            />
          </>
        )}

<button
          className=" filter-items rounded-[8px] bg-light-8 text-xs border text-light-2  justify-center  items-center flex gap-1 cursor-pointer"
          onClick={() => setopenTable(true)}
        >
          <IoIosArrowDown className="w-4 h-4" />
        </button>

        <button className="p-1.5 flex items-center rounded cursor-pointer text-xs">
          {/* {`${indexOfFirstRow + 1} - ${indexOfLastRow} of ${assetDetails.length}`} */}

          {`${indexOfFirstRow}-${indexOfLastRow} of ${allOperators.length}`}
        </button>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="p-1.5 flex items-center rounded cursor-pointer text-xs disabled:opacity-50"
        >
          <IoIosArrowBack className="h-4 w-4 bg-light-8 rounded-full text-light-2 -1" />
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="p-1.5 flex items-center rounded cursor-pointer text-xs disabled:opacity-50"
        >
          <IoIosArrowForward className="h-4 w-4 bg-light-8 rounded-full text-light-2 -1" />
        </button>
      </div>
      <div className="  w-full h-[66vh] mt-3 overflow-hidden border hide-scrollbar bg-white px-4 rounded-[6px] overflow-y-auto">
        {allOperators === undefined || allOperators.length === 0 ? (
          <div className="h-[50vh] flex justify-center items-center">
            not specified
          </div>
        ) : (
          <>
            <div className="">
              <table
                className={`table ${
                  tableAnimation ? "show-rows" : ""
                }  border-separate border-spacing-y-0 divide-y text-xs  w-full relative `}
              >
                <thead className="h-10 sticky dark:bg-gray-700 divide-y border-b">
                  <tr className="text-dark-6">
                    <th className="border-b">
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={handleSelectAllChange}
                        className="focus:outline-none focus:ring-0 rounded-[3px] text-blue-2 bg-transparent"
                      />
                    </th>
                    <th className="border-b">Serial No</th>
                    {sortHeader?.map((header) => (
                      <th
                        key={header}
                        className="cursor-pointer border-b"
                        onClick={() => {
                          if (sort === header) {
                            toggleSortOrder();
                          } else {
                            setSort(header);
                            setSortOrder("asc");
                          }
                        }}
                      >
                        {header}{" "}
                        {sort === header
                          ? sortOrder === "asc"
                            ? "▲"
                            : "▼"
                          : ""}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className=" dark:bg-gray-900 divide-y ">
                  {currentRows.map((item, index) => {
                    if (selectTableData - 1 >= index || !selectTableData) {
                      return (
                        <>
                          <tr
                            className="cursor-pointer text-center items-center border-b  hover:bg-[#2222] justify-center h-10"
                            key={index}
                            onClick={() => handleItemChange(item.id)}
                          >
                            <td className=" w-[50px] border-b">
                              <input
                                type="checkbox"
                                checked={item.selected}
                                onChange={() => handleItemChange(item.id)}
                                className="focus:outline-none focus:ring-0 rounded-[3px]  text-blue-2 bg-transparent "
                              />
                            </td>
                            <td className=" w-[60px] border-b">{index + 1}</td>

                            <td className="border-b">
                              {" "}
                              <Link to={`details/${item?.id}`}>
                                {(item?.name).toUpperCase()}
                              </Link>
                            </td>
                            <td className="border-b">
                              {" "}
                              <Link to={`details/${item?.id}`}>
                                {item.aadhar_no === undefined ||
                                item.aadhar_no === ""
                                  ? "N/A"
                                  : item.aadhar_no.replace(/(\d{4})(\d{4})(\d{4})/, `$1${" "}$2${" "}$3`)}
                              </Link>
                            </td>
                            <td className="border-b">
                              <Link to={`details/${item?.id}`}>
                                {item.pf_account_no === undefined || item.pf_account_no === "nan" ||
                                item.pf_account_no === ""
                                  ? "N/A"
                                  : parseInt(item.pf_account_no)}
                              </Link>
                            </td>
                            <td className="border-b">
                              <Link to={`details/${item?.id}`}>
                                {item.joining_date === undefined ||
                                item.joining_date === ""
                                  ? "N/A"
                                  : formatDate(item.joining_date)}
                              </Link>
                            </td>
                            <td className="border-b">
                              <Link to={`details/${item?.id}`}>
                                {item.leaving_date === undefined ||
                                item.leaving_date === "" ||
                                item.leaving_date === null
                                  ? "N/A"
                                  : formatDate(item.leaving_date)}
                              </Link>
                            </td>
                            {/* <td className="border-b">{item?.rso_no}</td>
                            <td className="border-b">{item?.asset_no}</td> */}
                          </tr>
                        </>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CurrentItems;
