import { span } from '@mui/material'
import React from 'react'

const LeaseTableHeader = (
    {
       activeCount,expiredCount, 
        leaseDetails
    }
) => {

    const HeaderData = [{
        title: "Total Items",
        label: "Total number of lease generated",
        data: leaseDetails.length
    },{
        title: "Active Lease",
        label: "Total number of active lease",
        data: activeCount,
    },{
        title: "Expired Lease",
        label: "Total number of expired lease",
        data: expiredCount
    }]
  return (
    <>

    <div className="flex gap-3 text-xs justify-start">
        {/* {HeaderData.map((data) => <>
    <span title={data.label} arrow placement="bottom-end">
        <div className="bg-white  p-3 w-[160px]  rounded-[6px] shadow-md" key={data.id}>
            <h1 className="">{data.title}</h1>
            <span className="text-base font-extrabold text-light-2">{data.data}</span>
        </div>
        </span>
        </>)} */}
          {HeaderData.map((data) => (
          <div title={data.title} className="p-4 gap-2 flex flex-col w-[160px] rounded-[16px] border bg-gradient-to-r bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out cursor-pointer">
            <h2 className=" text-[12px] font-normal text-gray-700">{data.title}</h2>
            <p className="text-[22px]  font-extrabold text-dark-6">{data.data}</p>
          </div>
      ))}
    </div>
    </>
  )
}

export default LeaseTableHeader
