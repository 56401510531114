// import React from 'react'
// import HistoryIcon from '@mui/icons-material/History';

// function HistoryBtn() {
//   return (
//     <button
//               onclick="showDiv('asset-historyBtn', 'main-assets');"
//               // className="button text-white bg-orange-700 hover:bg-orange-400 dark:bg-orange-500 hover:dark:bg-orange-400 py-1 px-2 h-8 mt-2 md:h-14 md:mt-2 w-28 rounded-md shadow-md mr-2 text-xs sm:text-sm cursor-pointer"
//             className=' m-2 flex items-center justify-center bg-orange-700 hover:bg-orange-400 dark:bg-orange-500 hover:dark:bg-orange-400 text-white py-2 px-3 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 md:py-1 text-sm cursor-pointer'
//             >
              
//               <HistoryIcon className='p-1 m-1'/>
//               {"  "}
//               History{" "}
//             </button>
//   )
// }

// export default HistoryBtn

import React from 'react';
import HistoryIcon from '@mui/icons-material/History';

function HistoryBtn() {
  return (
    <button
      className='text-xs flex justify-center rounded-md  items-center bg-[#006DB6] fixed right-[12%] bottom-3  w-[80px] h-7  text-white '
    >
      <HistoryIcon className='p-1  '/>
      {"  "}
      History{" "}
    </button>
  )
}

export default HistoryBtn;
