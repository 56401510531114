import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setBaseUrl } from "../../config";


import GetApprovalParts from "./Maintenance/GetApprovalParts";
import Comments from "./Maintenance/Comments";
import AssetMaintenanceDetails from "./Maintenance/AssetMaintenanceDetails";

import ServiceHistory from "./Maintenance/ServiceHistory";
import MaintenanceHeader from "./Maintenance/MaintenanceHeader";
import { useToast } from "../../Context/ToastContext";
import MaintenanceLog from "./Maintenance/MaintenanceLog";
import RequestForClosure from "./Maintenance/RequestForClosure";
import Rejection from "./Maintenance/Rejection";
import InstalledParts from "./Maintenance/InstalledParts";
import MaintenancePhoto from "./Maintenance/MaintenancePhoto";

const UpdateManitenance = () => {
  const { addToast } = useToast();
  const [addComment, setAddComment] = useState('');
  const { id } = useParams();
  const [updateForm, setUpdateForm] = useState({
    complaint: '',
    complaint_type: '',
    types: '',
    status: '',
    description: '',
    // priority: '',
    ready_date: '',

  });
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [servicePerson, setServicePerson] = useState([]);
  const [assignServicePerson, setAssignServicePerson] = useState({
    service_person: [],
    maintenance_id: id,
  });

  const [uploadImage, setUploadImage] = useState(null);
  const [openClosure, setOpenClosure] = useState(false)
  const [updateFormChecker, setUpdateFormChecker] = useState(false)
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitComment = async () => {


    try {
      const res = await fetch(`${setBaseUrl}/maintenance/add-comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': sessionStorage.getItem('token')
        },
        body: JSON.stringify({ comment: addComment, maintenance_id: id })
      });
      if (res.status === 200) {
        setAddComment('');
        addToast('Comment added successfully', 'success');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.error('Failed to add comment');
        addToast('Failed to add comment', 'error');
      }
    } catch (error) {
      console.error(error);
      addToast('An error occurred', 'error');
    }
  };

  const handleUploadImage = (e) => {
    const files = e.target.files;
    setUploadImage(files);

    const formData = new FormData();
    formData.append('types', 'maintenance');
    formData.append('maintenance_id', id);

    if (files) {
      Array.from(files).forEach((file) => {
        formData.append('photo', file);
      });
    }

    // Debug: Log all FormData entries
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  };


  const getMaintenanceData = async () => {
    // fetch data from API using the id
    // then update the state with the fetched data
    try {
      const res = await fetch(`${setBaseUrl}/maintenance/get_by_id`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();
      setMaintenanceData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMaintenanceData();
  }, []);

  // console.log(maintenanceData)

  const getServicePerson = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-service-persons`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();
      setServicePerson(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getServicePerson();
  }, []);


  const updateMachine = async (updatedData) => {
    try {
      const submittedData = { id, ...updatedData };
      const res = await fetch(`${setBaseUrl}/maintenance/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(submittedData),
      });

      if (res.ok) {
        addToast("Maintenance Updated Successfully", "success");
      } else {
        addToast("Update Failed", "error");
      }
    } catch (error) {
      console.error("Error updating machine:", error);
      addToast("Something went wrong while updating.", "error");
    }
  };

  // Helper function to upload image
  const uploadImageFile = async (maintenanceId, image) => {
    try {
      const formData = new FormData();
      formData.append("types", "maintenance");
      formData.append("maintenance_id", maintenanceId);
      formData.append("photo", image[0]);

      const res = await fetch(`${setBaseUrl}/maintenance/upload_photo`, {
        method: "POST",
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: formData,
      });

      if (res.ok) {
        addToast("Image uploaded successfully!", "success");
      } else {
        addToast("Failed to upload image.", "error");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      addToast("Image upload failed.", "error");
    }
  };


  const assignServicePersonApi = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/maintenance/assign-service-person`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(assignServicePerson),
      });

      if (res.ok) {
        addToast("Service Person Assigned successfully!!", "success");

        // Immediately reflect status update locally
        const updatedForm = { ...updateForm, status: "in_progress" };
        setUpdateForm(updatedForm);

        // Include updated status in the machine update
        await updateMachine(updatedForm);
      } else {
        addToast("Failed to assign service person. Please try again.", "error");
        return;
      }
    } catch (error) {
      console.error("Error assigning service person:", error);
      addToast("Something went wrong while assigning.", "error");
      return;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple clicks

    setIsSubmitting(true); // Disable the button immediately


    // Ensure service person is selected
    if (assignServicePerson.service_person.length) {

      await assignServicePersonApi()
      await updateMachine(updateForm);
    } else if (updateFormChecker) {
      await updateMachine(updateForm);

    }



    // Upload Image if available
    if (uploadImage?.length) {
      console.log("upload file")
      await uploadImageFile(id, uploadImage);
    }

    // Submit comment
    if (addComment.length > 0) {
      handleSubmitComment();
    }

    setTimeout(() => {
      window.location.reload();
    }, 2000);


  };




  return (
    <>
      {maintenanceData.is_ready_for_closer && <>
        <div className="w-full bg-red-100 p-4 flex justify-between items-center">
          <span className="">Service Engineer Requested for Closure</span>
          <button className="buttons border border-red-1 text-red-1" onClick={() => setOpenClosure(true)}> Close</button>
        </div>
      </>}
      <div className="h-screen w-full  text-[12px] font-medium">
        {/* Header */}

        {maintenanceData?.asset_id && <AssetMaintenanceDetails assetID={maintenanceData.asset_id} maintenanceData={maintenanceData} />}

        <div className="p-4 border bg-light-8 mt-4">
          <MaintenanceHeader maintenanceData={maintenanceData} />
          {/* Status */}
          <div className="flex items-end justify-end mt-2 gap-2">
            {/* Open Status Button */}
            <button
              title="Mark as Open"
              className={`buttons border w-[130px]  ${maintenanceData.status === 'open' ? 'bg-green-2 text-white' : maintenanceData.status === 'temporary_closed' ? 'bg-green-2 text-white' : 'bg-text-1 text-white'}`}
            >
              Open
            </button>

            {/* In Progress Status Button */}
            <button
              title="Mark as In Progress"
              disabled={maintenanceData.status !== 'in_progress'}
              className={`buttons border w-[130px] ${maintenanceData.status === 'in_progress' ? 'bg-green-2 text-white' : 'bg-text-1 text-white'} ${maintenanceData.status !== 'in_progress' && 'cursor-not-allowed'}`}
            >
              In Progress
            </button>

            {/* Close Status Button */}
            <button
              title="Complete and Request Closure"
              onClick={() => setOpenClosure(true)}
              disabled={maintenanceData.status === "closed" ? true : false}
              className={`buttons border w-[130px] ${maintenanceData.status === "closed" ? 'bg-red-1 text-white' : 'bg-text-1 text-white hover:bg-white hover:text-red-1 hover:border-red-1 border'}`}
            >
          {maintenanceData.status === "closed" ? "Closed" : "Close"}
            </button>
          </div>

          {/* Form */}
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-4 mt-4">
            {/* Form for Assign Operator */}

            <div className="bg-white p-4  rounded-md border w-full shadow-md">


              {/* Table 1 */}
              <div className="flex gap-2 justify-between max-md:flex-col">
                {/* Asset Service Person */}
                <div className="mb-2">
                  <label
                    htmlFor="service_person"
                    className="block text-[12px] font-medium text-gray-700 mb-1"
                  >
                    Assign Service Engineer
                  </label>
                  <select
                    id="service_person"
                    className="w-[300px] p-2 border rounded-md text-xs"
                    // value={maintenanceData?.serviceSalePersons[0].name || assignServicePerson.service_person || ''}
                    onChange={(e) => {
                      setAssignServicePerson({
                        ...assignServicePerson,
                        service_person: [{ id: Number(e.target.value) }],
                      });
                      setUpdateFormChecker(true)
                    }}
                  >
                    <option value="">Select Service Engineer</option>
                    {servicePerson.map((person) => (
                      <option key={person.id} value={person.id}>
                        {person.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Complain Title */}
                <div className="mb-2">
                  <label htmlFor="" className="block text-[12px] font-medium text-gray-700 mb-1">Breakdown Title</label>
                  <input type="text"
                    value={updateForm.complaint || maintenanceData.complaint}
                    onChange={(e) => {
                      setUpdateForm({
                        ...updateForm,
                        complaint: e.target.value,
                      })

                      setUpdateFormChecker(true)
                    }}
                    placeholder="Edit complaint title" className="w-[300px] border p-2 rounded-md text-xs" />
                </div>



              </div>

              <div className="flex gap-2 justify-between max-md:flex-col">
                {/* Service Category */}
                <div className="mb-2">
                  <label
                    htmlFor="Service Category"
                    className="block text-[12px] font-medium text-gray-700 mb-1"
                  >
                    Service Category
                  </label>
                  <select
                    id="types"
                    value={updateForm?.types || maintenanceData.types || ''}
                    className="w-[300px] p-2 border rounded-md text-xs"
                    onChange={(e) => {
                      setUpdateForm({
                        ...updateForm,
                        types: e.target.value,
                      });
                      setUpdateFormChecker(true)
                    }}
                  >
                    <option value="">Select</option>
                    <option value="warranty">Warranty</option>
                    <option value="non_warranty">Non-Warranty</option>
                    <option value="safety_notice">Safety Notice</option>
                    <option value="preventive_maintenance">Preventive Maintenance</option>
                  </select>
                </div>

                {/* Complain Type */}
                <div className="mb-2">
                  <label
                    htmlFor="deadline"
                    className="block text-[12px] font-medium text-gray-700 mb-1"
                  >
                    Breakdown Type
                  </label>
                  <select
                    id="priority"
                    value={updateForm?.complaint_type || maintenanceData.complaint_type || ''}
                    className="w-[300px] p-2 border rounded-md text-xs"
                    onChange={(e) => {
                      setUpdateForm({
                        ...updateForm,
                        complaint_type: e.target.value,
                      });
                      setUpdateFormChecker(true)
                    }}
                  >
                    <option value="">Select</option>
                    <option value="major">Major </option>
                    <option value="minor">Minor </option>
                    <option value="others">Others</option>
                  </select>
                </div>

              </div>


              <div className="flex gap-2 justify-between max-md:flex-col">
                {/* Priority */}
                <div className="mb-2">
                  <label
                    htmlFor="deadline"
                    className="block text-[12px] font-medium text-gray-700 mb-1"
                  >
                    Priority
                  </label>
                  <select
                    id="priority"
                    value={updateForm?.priority || maintenanceData.priority || ''}
                    className="w-[300px] p-2 border rounded-md text-xs"
                    onChange={(e) => {
                      setUpdateForm({
                        ...updateForm,
                        priority: e.target.value,
                      });
                      setUpdateFormChecker(true)
                    }}
                  >
                    <option value="">Select</option>
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </select>
                </div>


                {/* Deadline Input */}
                <div className="mb-2">
                  <label
                    htmlFor="deadline"
                    className="block text-[12px] font-medium text-gray-700 mb-1"
                  >
                    Deadline
                  </label>
                  <input
                    type="date"
                    id="deadline"
                    className="w-[300px] p-2 border rounded-md text-xs"
                    onChange={(e) => {
                      setUpdateForm({
                        ...updateForm,
                        ready_date: e.target.value,
                      });
                      setUpdateFormChecker(true)
                    }}
                  />
                </div>


              </div>


              {/* Comments */}
              <div className="mb-2">
                <label
                  htmlFor="comments"
                  className="block text-[12px] font-medium text-gray-700 mb-1"
                >
                  Update Breakdown Description
                </label>
                <textarea
                  id="comments"
                  rows="3"

                  className="w-full p-2 border rounded-md text-xs"
                  placeholder="Update breakdown description"
                  value={maintenanceData.description || updateForm.description || ""}
                  onChange={(e) => {
                    setUpdateForm({
                      ...updateForm,
                      description: e.target.value,
                    });

                    setMaintenanceData({
                      ...maintenanceData, description: e.target.value
                    })
                    setUpdateFormChecker(true)
                  }}
                ></textarea>


                <label htmlFor="" className="">Add Comment</label>
                <textarea
                  className="w-full p-2 border rounded-lg text-xs"
                  placeholder="Add message"
                  value={addComment}
                  onChange={(e) => setAddComment(e.target.value)}
                ></textarea>

                <button
                  className="text-xs px-4 py-2 border rounded-md border-green-2 text-green-2 hover:bg-green-2 hover:text-white relative"
                >
                  Upload File
                  <input
                    type="file"
                    className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                    onChange={handleUploadImage}
                  />
                </button>
            
                {uploadImage && <img src={uploadImage.photo} className="w-[200px] h-[200px] mt-4" />}

              </div>


              <div className="flex justify-end items-end gap-2 mt-4">

                <button
                  className={`buttons bg-green-2 text-white ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Updating..." : "Update"}
                </button>

              </div>

            </div>



            {/* Get Part Lists for Approval */}
            {maintenanceData?.serviceSalePersons && <GetApprovalParts person={maintenanceData?.serviceSalePersons} id={id} />}

          </div>
          {/* Installed Parts */}
          {maintenanceData.parts && <InstalledParts maintenanceData={maintenanceData.parts} />}
          {/* Rejection Table */}
          {/* <Rejection maintenanceData={maintenanceData} /> */}

          {/* Operator Details */}
          <div className="bg-white p-4 mt-4 rounded-md border w-full shadow-sm">


            {/* Description */}
            <div className="flex flex-col gap-2 p-4 border rounded-[4px] mt-4">
              <span className="text-base text-blue-2 bg-gray-200 rounded border p-2 ">Breakdown Description</span>
              <p className="text-xs">{maintenanceData?.description}</p>
              {/* Comments */}

              <Comments commentsFromParent={maintenanceData?.comments} reject={maintenanceData?.reject_comments} servicePeron={maintenanceData.serviceSalePersons} />
              {/* Photos */}
              {maintenanceData?.photos && <MaintenancePhoto photos={maintenanceData?.photos} />}
            </div>
            <MaintenanceLog id={id} />


            <ServiceHistory assetID={maintenanceData.asset_id} />
            {/* Action Buttons */}

          </div>

        </div>

        {/* Request for Closure */}
        {openClosure &&
          <RequestForClosure maintenance_id={id} setOpenClosure={setOpenClosure} />
        }


      </div>
    </>
  );
};

export default UpdateManitenance;
