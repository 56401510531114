import React from "react";
import { FiMenu, FiHome, FiBarChart2, FiSettings, FiLogOut } from "react-icons/fi";
import { motion } from "framer-motion";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const sidebarVariants = {
  open: { width: "10rem", transition: { duration: 0.3 } },
  closed: { width: "4rem", transition: { duration: 0.3 } },
};

const SalesSidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {

  const navigate = useNavigate()
  const handleLogout = () => {
    sessionStorage.clear()
    navigate("/sales-services/login")
  }
  return (
    <motion.div
      variants={sidebarVariants}
      animate={isSidebarOpen ? "open" : "closed"}
      className={`bg-white shadow-lg h-screen flex flex-col border z-50`}
    >
     

      {/* Sidebar Links */}
      <nav className="mt-4 px-3 text-xs items-start flex flex-col space-y-2">
        {[
          { icon: <FiHome size={20} />, label: "Home", route: 'dashboard' },
          { icon: <FiBarChart2 size={20} />, label: "Wallet", route: 'service-wallet' },
          // { icon: <FiSettings size={20} />, label: "Settings" },
        ].map((item, index) => (
          <div
            key={index}
            onClick={() =>  navigate(`/sales-services/${item.route}`)}
            className="flex  items-start space-x-2 p-2 hover:bg-gray-100 cursor-pointer transition-all duration-200"
          >
            {item.icon}
            {isSidebarOpen && <span className="text-xs text-gray-700">{item.label}</span>}
          </div>
        ))}

        {/* Logout Button */}
        <div
         onClick={handleLogout}
          className="flex items-center space-x-2 p-2 text-red-600 hover:bg-red-100 cursor-pointer transition-all duration-200 mt-auto"
        >
          <FiLogOut size={20} />
          {isSidebarOpen && <span className="text-xs" >Logout</span>}
        </div>
      </nav>
    </motion.div>
  );
};

export default SalesSidebar;
