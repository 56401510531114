import React, { useState } from "react";
import Select from "react-select";

const MachineForm = ({
  name,
  type,
  label,
  assetArray,
  machineData,
  setMachineData,
  machineIndex,
  operatorArray,
  keyNameType,
  error,
  setError,
}) => {
  const handleChange = (e) => {
    e.preventDefault();

    const value = e.target.value;

    if (keyNameType === "rental_start_date") {
      machineData[machineIndex]["rental_start_date"] = value;
    } else if (keyNameType === "rental_end_date") {
      const startDate = new Date(
        machineData[machineIndex]["rental_start_date"]
      );
      const endDate = new Date(value);

      if (startDate && endDate <= startDate) {
        setError("Rental end date must be greater than the rental start date!");
        machineData[machineIndex]["rental_end_date"] = ""; // Clear rental end date after alert
        setMachineData([...machineData]); // Ensure state is updated after clearing
        return;
      }
      machineData[machineIndex]["rental_end_date"] = value;
    } else {
      machineData[machineIndex][`${keyNameType}`] = value;
    }

    setMachineData([...machineData]); // Update state with modified data
  };

  const [hover, setHover] = useState(false);
  const [focused, setFocused] = useState(false); // New state for focus

  const [values, setValues] = useState(0);

  // Time Duration
  const [duration, setDuration] = useState("");
  const handleTimeChange = (e) => {
    const time = e.target.value;
    const [hours, minutes] = time.split(":");
    const totalMinutes = parseInt(hours);
    setDuration(totalMinutes);
  };

  return (
    <div className="flex justify-between items-center gap-5 space-y-2 ">
      <span className="text-[10px]">{name}</span>

      {name === "Asset No" ? (
        <Select
          placeholder="Select Asset" // Placeholder for the select field
          options={
            Array.isArray(assetArray)
              ? assetArray.map((assets) => ({
                  value: assets.id,
                  label: assets.asset_no,
                }))
              : []
          }
          onChange={(selectedOption) => {
            // Update machineData for the selected machine based on its index
            machineData[machineIndex].asset = selectedOption.value;
            setMachineData([...machineData]); // Ensure state update with a new array reference
          }}
          styles={{
            container: (provided) => ({
              ...provided,
              width: "350px",
              fontSize: 10,
            }),
            control: (provided, state) => ({
              ...provided,
              width: "350px",
              height: "20px",
              border:
                state.isFocused || state.isHovered
                  ? "none"
                  : "1px solid transparent", // Keep border transparent when not focused or hovered
              borderBottom: "1px solid #D1D5DB", // Apply bottom border
              boxShadow: state.isFocused
                ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                : null, // Optional: Add shadow on focus
              outline: "none", // Remove default outline
              borderRadius: 0,
            }),
            // Optionally style the menu and other components as needed
          }}
        />
      ) : name === "Operator Name" ? (
        <>
          <Select
            isMulti
            onChange={(value) => {
              machineData[machineIndex].select_operators = value;
              setMachineData(machineData);
            }}
            name="colors"
            options={operatorArray}
            className="basic-multi-select"
            classNamePrefix="select"
            isOptionDisabled={() => {
              return (
                machineData[machineIndex].select_operators.length >=
                machineData[machineIndex][`select_number_of_operators`]
              );
            }}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "350px",
                fontSize: 10,
              }),
              control: (provided, state) => ({
                ...provided,
                width: "350px",
                height: "20px",
                border:
                  state.isFocused || state.isHovered
                    ? "none"
                    : "1px solid transparent", // Keep border transparent when not focused or hovered
                borderBottom: "1px solid #D1D5DB", // Apply bottom border
                boxShadow: state.isFocused
                  ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                  : null, // Optional: Add shadow on focus
                outline: "none", // Remove default outline
                borderRadius: 0,
              }),
              // Optionally style the menu and other components as needed
            }}
          />
        </>
      ) : keyNameType === "normal_rate" ||
        keyNameType === "overtime_rate" ||
        keyNameType === "normal_amount" ||
        keyNameType === "overtime_amount" ||
        keyNameType === "total_claimable_amount" ? (
        <>
          {" "}
          <input
            type={type}
            placeholder={name}
            className={`w-[350px] h-6 text-[10px] 
              border-b border-r-0 border-t-0 border-l-0 border-gray-300 
              focus:border-b focus:outline-none focus:ring-0 focus:border-gray-300
            `}
            value={name}
          />
        </>
      ) : keyNameType === "rental_end_date" ? (
        <>
          <input
            type='date'
            placeholder={name}
            className={`w-[350px] h-6 text-[10px] 
        "border-b border-r-0 border-t-0 border-l-0 border-gray-300 focus:border-b focus:outline-none focus:ring-0 focus:border-gray-300"
          
      `}
      
            onChange={handleChange} // Call the fixed handleInputChange function
            onFocus={() => {
              setHover(true); // Set hover to true on focus
              setFocused(true); // Set focused to true on focus
            }}
            onBlur={() => {
              setHover(false); // Set hover to false on blur
              setFocused(false); // Set focused to false on blur
            }}
          />
        </>
      ) : (
        <input
          type={type}
          placeholder={name}
          className={`w-[350px] h-6 text-[10px] 
            "border-b border-r-0 border-t-0 border-l-0 border-gray-300 focus:border-b focus:outline-none focus:ring-0 focus:border-gray-300"
              
          `}
          onChange={handleChange} // Call the fixed handleInputChange function
          onFocus={() => {
            setHover(true); // Set hover to true on focus
            setFocused(true); // Set focused to true on focus
          }}
          onBlur={() => {
            setHover(false); // Set hover to false on blur
            setFocused(false); // Set focused to false on blur
          }}
        />
      )}
    </div>
  );
};

export default MachineForm;
