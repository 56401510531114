import React from 'react';

const MaintenancePhoto = ({ photos }) => {
    // Function to ensure the URL has proper protocol
    const getImageUrl = (uri) => {
        if (!uri) return '';
        return uri.startsWith('http') ? uri : `https://${uri}`;
    };

    return (
        <div className="space-y-4">
            <div className="border-b border-gray-200 pb-2">
                <h3 className="text-lg font-medium text-gray-700">Maintenance Photos</h3>
            </div>
            
            {photos?.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-7 gap-4">
                    {photos.map((photo) => (
                        <div 
                            key={photo.id} 
                            className="bg-white w-fit rounded-lg overflow-hidden shadow-sm p-2 border"
                        >
                            <div className="w-fit bg-gray-100">
                                <img 
                                    src={getImageUrl(photo.image_uri)} 
                                    alt="Maintenance documentation" 
                                    className="object-cover w-[200px] h-[200px]"
                                    onError={(e) => {
                                        e.target.onerror = null; 
                                        e.target.src = 'https://via.placeholder.com/300x200?text=Image+Not+Available';
                                    }}
                                />
                            </div>
                          {/* WE Need Side Scroller here */}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center py-6 text-gray-500">
                    No photos available for this maintenance
                </div>
            )}
        </div>
    );
};

export default MaintenancePhoto;