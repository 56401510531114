import React, { useState } from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
// import MobileNav from "./navbar/MobileNav";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";

import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { FaInstalod } from "react-icons/fa6";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import { GiHamburgerMenu } from "react-icons/gi";
import { FaRegBookmark } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { MdOutlineInventory2 } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const sidebarData = [
  {
    title: "Dashboard",
    route: "dashboard",
    imgUrl: HomeOutlinedIcon,
  },
  {
    title: "Assets",
    route: "assets",
    imgUrl: BusinessCenterOutlinedIcon,
  },
  {
    title: "Maintenance",
    route: "maintenance",
    imgUrl: ManageHistoryIcon,
  },
  {
    title: "Lease",
    route: "lease",
    imgUrl: FeedOutlinedIcon,
  },
  {
    title: "Operators",
    route: "operators",
    imgUrl: EngineeringIcon,
  },
  {
    title: "Settings",
    route: "settings",
    imgUrl: SettingsOutlinedIcon,
  },
  {
    title: "Logistics",
    route: "logistics",
    imgUrl: FaInstalod,
  },
  {
    title: "Error",
    route: "error-module",
    imgUrl: MdErrorOutline,
  },
  {
    title: "Book",
    route: "booking",
    imgUrl: FaRegBookmark,
  },
  {
    title: "Inventory",
    route: "inventory",
    imgUrl: MdOutlineInventory2,
  },
];
const Sidebar = () => {
  const [hover, setHover] = useState(false);
  const path = useLocation().pathname;

  
  return (
    <div>
      <div
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={`mt-[64px]   h-full max-md:hidden fixed ${
          hover ? `w-[200px]` : `w-[60px]`
        } z-[9999] transition-all ease-in-out duration-300 bg-white border-r dark:border-none dark:bg-gray-950 text-black shadow-2xl`}
      >
        <div className=" flex flex-col gap-4 mt-8  ">
          {sidebarData.map((nav) => {
            const isActive = path.split("/")[1] === nav.route;

            return (
              <>
                <Link
                  to={`/${nav.route}`}
                  key={nav.route}
                  className={` flex flex-rowb p-2   mx-4  items-center 
                              ${
                                hover
                                  ? `  rounded-[4px] `
                                  : ` items-center justify-center `
                              }   
                              ${
                                isActive
                                  ? "bg-green-2  shadow-xl text-white rounded-[6px]"
                                  : "bg-transparent text-black dark:text-white "
                              }`}
                >
                  <nav.imgUrl className="  fixed mx-1 h-6 w-6  p-0.5" />
                  <h1
                    className={`text-sm font-regular  ${
                      hover
                        ? "opacity-100  ml-10 transition-all duration-500  ease-in-out"
                        : "opacity-0"
                    }`}
                  >
                    {nav.title}
                  </h1>
                </Link>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
