import React from "react";

import { Blocks } from "react-loader-spinner";

function OuterLoader() {
  return (
    // fixed inset-0 z-[9999]
    <div className="flex justify-center items-center fixed inset-0 z-[9999] bg-white">
      {/* <Blocks
        height="100"
        width="100"
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        outerCircleColor=""
        innerCircleColor=""
        barColor=""
        ariaLabel="blocks-wrapper"
      /> */}
    

      <div  className='border p-4 rounded-2xl shadow-2xl w-[90%] max-w-full ml-2 mr-5 mt-36 md:ml-10  '>
       <div className="rounded-xl relative h-28 mb-4 flex p-4 items-center bg-gray-300 animate-pulse">
           <div className="m-5">

                <div class=" items-center justify-center md:w-28 h-16 bg-gray-100 rounded w-20 dark:bg-gray-700">
                    
                </div> 
           </div>
            <div className="m-5">
                <div class=" items-center justify-center md:w-28 h-16 bg-gray-100 rounded w-20 dark:bg-gray-700">
                  
                </div> 
            </div>

            <div className="m-5">
                <div class=" items-center justify-center md:w-28 h-16 bg-gray-100 rounded w-20 dark:bg-gray-700">
                    
                </div> 
            </div>

            <div className="m-5">
                <div class=" items-center justify-center md:w-28 h-16 bg-gray-100 rounded w-20 dark:bg-gray-700">
                    
                </div> 
            </div>

            <div className="m-5">
                <div class=" items-center justify-center md:w-28 h-16 bg-gray-100 rounded w-20 dark:bg-gray-700">
                    
                </div> 
            </div>
           
       </div>
      
 
       <div className="relative h-44 mb-4 rounded-xl flex justify-center items-center bg-gray-300 animate-pulse">
            
       </div>
    
    
    <div className="animate-pulse">
       <div className=" h-12 bg-gray-400 rounded-lg mb-4"></div>
       <div className="h-10 bg-gray-300 rounded-lg mb-4"></div>
       <div className="h-10 bg-gray-300 rounded-lg mb-4"></div>
       <div className="h-10 bg-gray-300 rounded-lg mb-4"></div>
       <div className="h-10 bg-gray-300 rounded-lg mb-4"></div>
    </div>
    </div>
    </div>
  );
}

export default OuterLoader;
