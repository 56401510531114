import React, { useEffect, useState } from "react";
import MISReportBtn from "../../Buttons/MISReportBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import { useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import OperatorSearch from "../../Search/OperatorSearch";
import { Tooltip } from "react-tooltip";
import { CiFilter, CiViewTable } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";

function UpperTab({
  getAllOperators,
  allOperators,
  sortHeader,
  openFilter,
  setOpenFilter,
  openTable,
  setopenTable,
  tableArray,
  selectTableData,
  setSelectTableData,
  openSort, setOpenSort,
  sort,
  setSort,
  sortOrder,
  setSortOrder,
  inputSearch,
  setInputSearch,
  filteredHeaders,
}) {
  const [searchSuggetions, setSearchSuggetions] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchTypeArray, setSearchTypeArray] = useState([]);
  const [showSearchDiv, setShowSearchDiv] = useState([]);
  const [searchType, setSearchType] = useState("aadhar-no");

  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      // console.log(data);
      setSearchSuggetions(data);
      // setItems(data.asset_data.length());
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    getSearchData();
  }, []);


  const [open, setOpen] = useState(false);
  const handleSearchCategory = (value) => {
    // console.log(value);
    switch (value) {
      case "adhaarNo":
        setSearchType("aadhar-no");
        let adNos = Object.keys(searchSuggetions.aadhar);
        setSearchTypeArray(adNos);
        break;
      case "pf":
        setSearchType("pf-account");
        let pfAcNo = Object.keys(searchSuggetions.pf_account_no);
        setSearchTypeArray(pfAcNo);
        break;
      case "name":
        setSearchType("name");
        let opName = Object.keys(searchSuggetions.name);
        setSearchTypeArray(opName);
      default:
        break;
    }
  };



  function search(query) {
    query = query.trim();
    if (query === "") {
      setShowSearchDiv([]);
      return;
    }
    const filteredData = searchTypeArray.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    if (filteredData.length > 0) {
      setShowSearchDiv(filteredData);
    } else {
      setShowSearchDiv(["no results"]);
    }

    // console.log(filteredData);
  }
  const handleSearch = (e) => {
    search(e.target.value);
    setSearchValue(e.target.value);
  };
  const handleSearchClicik = (item) => {
    // console.log(searchType, item);
    getAllOperators(searchType, item);
    setShowSearchDiv([]);
  };

  const [openSearch, setOpenSearch] = useState(false);

  return (
    <>
      <div class="flex justify-end flex-col md:flex-row items-center border-b pb-2 py-0">
     
        <div className="flex flex-col items-center md:flex-row">
          {/* filter */}
          <div className="flex flex-row gap-2 justify-between items-center ">
            {/* Table */}
            <button
              className="bg-light-1  filter-items rounded-md p-1.5 text-xs borde text-[#ffffff] font-extrabold justify-center  items-center flex gap-1 cursor-pointer"
              onClick={() => setopenTable(true)}
            >
              <CiViewTable className="w-4 h-4" />
            </button>
            <Tooltip
              anchorSelect=".table-items"
              place="right"
              className="text-xs font-normal"
            >
              Table
            </Tooltip>

            {/* filter */}
            {/* <div
              className={`bg-light-1  filter-items rounded-md p-1.5 text-xs borde text-[#ffffff] font-extrabold justify-center  items-center flex gap-1 cursor-pointer
         
            `}
              // onClick={() => setOpenFilter(true)}
            >
              <CiFilter className="w-4 h-4" />
              <Tooltip
                anchorSelect=".filter-items"
                place="left"
                className="text-xs font-normal"
              >
                Filter
              </Tooltip>
            </div> */}

            {/* Sorting Suggestions */}

            <button
              className="p-1.5 rounded-md bg-[#006DB6] borde text-xs  sort-items"
              onClick={() => setOpenSort(!openSort)}
            >
              <BiSortAlt2 className="h-4 w-4 rounded  text-[#ffffff]" />
              <Tooltip
                anchorSelect=".sort-items"
                place="left"
                className="text-xs font-normal"
              >
                Sort
              </Tooltip>
            </button>
            {openSort && (
            <>
              <div className="absolute text-xs w-[200px] h-fit z-30 top-12 right-[10%] rounded-lg p-2 border bg-white">
                <input
                  type="text"
                  className="bg-transparent w-[180px] h-7 rounded-md text-xs"
                  placeholder="Search..."
                  onChange={(e) => setInputSearch(e.target.value)}
                  value={inputSearch}
                />
                <ul className="mt-2">
                  {filteredHeaders.length > 0 ? (
                    filteredHeaders.map((header) => (
                      <li
                        className="px-2 py-2 hover:bg-[#EEE] rounded-md cursor-pointer"
                        key={header}
                        onClick={() => {
                          setSort(header);
                          setOpenSort(false);
                        }}
                      >
                        {header}
                      </li>
                    ))
                  ) : (
                    <li className="px-2 py-2 text-gray-500">
                      No results found
                    </li>
                  )}
                </ul>
              </div>
              <div
                className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
                  !openSort && "hidden"
                } `}
                onClick={() => {
                  setOpenSort(false);
                }}
              />
            </>
          )}
          </div>

          <div className="">
            {/* Open Table Data */}
            {openTable && (
              <>
                <div
                  className="absolute mt-10 z-[9999] right-[21%] p-1.5 text-xs
                flex flex-col justify-center 
               bg-white dark:bg-[#070e18] w-[80px] shadow-2xl rounded-lg h-fit items-start"
                >
                  {tableArray.map((table) => (
                    <>
                      {" "}
                      <button
                        key={table}
                        className="hover:bg-[#EEE] w-full  py-1  dark:hover:text-black "
                        onClick={() => setSelectTableData(table)}
                      >
                        {table}
                      </button>{" "}
                    </>
                  ))}
                </div>
                <div
                  className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                  onClick={() => setopenTable(false)}
                />
              </>
            )}
          </div>
          {/* Search */}
          <div className="flex flex-col md:flex-row" />
          <div className="z-[5000]">
            <OperatorSearch
              handleSearchCategory={handleSearchCategory}
              searchValue={searchValue}
              handleSearch={handleSearch}
              handleSearchClicik={handleSearchClicik}
              open={open}
              setOpen={setOpen}
              setOpenSearch={setOpenSearch}
            />

            {openSearch && showSearchDiv.length > 0 && (
              <div className="absolute z-[9999] bg-white p-2 border h-[50vh]  shadow-xl rounded-[9px] w-[200px] hide-scrollbar mt-6 overflow-y-scroll right-4">
                {showSearchDiv?.map((item) => (
                  <p
                    onClick={() => handleSearchClicik(item)}
                    className="cursor-pointer hover:bg-[#EEE] text-xs border-b rounded-md p-2"
                  >
                    {(item).toUpperCase()}
                  </p>
                ))}
              </div>
            )}




          </div>
          <div
            className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
              !open && "hidden"
            } `}
            onClick={() => {
              setOpen(false);
              setOpenSearch(false);
            }}
          />
          <NewAssetBtn tabName="operator" />
        </div>
      </div>
      <div className="flex text-xs mt-1 gap-4 justify-start items-center">
        <div className=" font-extrabold">
          <span className="font-normal ">Total Number of Operators: </span>{" "}
          {allOperators.length}
        </div>
        {/* Table and Filter Names */}
        <div className="flex gap-4 text-[10px] justify-between z-20">
        {selectTableData && (
            <>
              <button className="bg-[#EEE] p-1 flex items-center rounded cursor-pointer">
                {selectTableData}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectTableData(null)}
                />
              </button>
            </>
          )}
           {/* Sorted Data */}
           {sort && (
            <>
              <button className="text-xs bg-[#EEE] rounded-md px-1 py-1 flex items-center justify-center">
                {sort}
                <IoIosClose
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => setSort(null)}
                />
              </button>
            </>
          )}

        </div>
      </div>
    </>
  );
}

export default UpperTab;
