import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../../config";
import { Button } from "@mui/material";

const UserLogData = ({ id }) => {
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserLogData = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": id,
        },
      });
      const data = await res.json();
      setLogData(data);
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    getUserLogData();
  };

  return (
    <div className="min-h-[50vh] text-xs  py-2">
      {/* Page Title */}
      <div className="max-w-7xl mx-auto px-2">
        {/* Button */}

        <Button
          onClick={handleOpen}
          variant="contained"
          sx={{
            fontSize: "12px",
            color: "white",
           

            textTransform: "none", // To disable uppercase text transformation
          }}
        >
          Display Data
        </Button>

        {/* Data Container */}
        <div className="mt-3 bg-white shadow-md  p-5">
          {loading ? (
            <div className="flex justify-center items-center py-10">
              <div className="animate-spin rounded-full h-8 w-8 "></div>
            </div>
          ) : (
            <div className="overflow-x-auto  text-[10px]">
              <table className="min-w-full border-collapse divide-y">
                {/* Table Header */}
                <thead className="divide-y sticky top-0">
                  <tr>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Act By
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Action Type
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Fields Are
                    </th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody>
                  {logData.length > 0 ? (
                    logData.map((data, index) => (
                      <tr
                        key={index}
                        className={` hover:bg-light-8 transition`}
                      >
                        <td className="py-3 px-4 text-dark-6 border-b">
                          {data.act_by}
                        </td>
                        <td className="py-3 px-4 text-dark-6 border-b">
                          {data.action_type}
                        </td>
                        <td className="py-3 px-4 text-dark-6 border-b">
                          {data.fields_are?.split(",id,").join(", ")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="3"
                        className="py-4 px-4 text-center text-dark-6"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserLogData;
