import React from "react";

const AssetTableHeader = ({
  assetDetails,
  activeCount,
  expiredCount,
  FreeAssetCount,
  soldAssets,
  trackerInstalledAssets,
}) => {

    const HeaderData = [
        {
            id: 1,
            title: "Total Assets",
            data:
            //  selectTableData && selectTableData <= filteredDataStack.length
            // ? selectTableData
            // : filteredDataStack.length + `/` +
              assetDetails.length
        },
        {
            id:2,
            title: "Asset With Active Lease:",
            data: activeCount 
        },
        {
            id: 3,
            title: "Asset With Expired Lease:",
            data: expiredCount
        },
        {
            id: 4,
            title: "Free Assets",
            data: FreeAssetCount
        },
        {
            id: 5,
            title: "Sold Assets:",
            data: soldAssets
        },
        {
            id: 6,
           title: 'Tracker Installed Assets:',
           data: trackerInstalledAssets
        }
    ]
// console.log(HeaderData)
  return (
    // <div className="flex justify-between gap-0">
    //   <div className="border p-3 text-xs font-extrabold mt-1 w-[180px]">
    //     <span className="font-normal ">Total Assets: </span>
    //      {"("} 1 -{" "}
    //      <div className="text-xl">

    //     {selectTableData && selectTableData <= filteredDataStack.length
    //       ? selectTableData
    //       : filteredDataStack.length}{" "}
    //     / {assetDetails.length} 
    //       </div>
    //     {")"}
    //   </div>

    //   <div className="text-xs flex flex-col p-3 border font-extrabold mt-1 w-[180px]">
    //     <span className="font-normal ">Asset With Active Lease: </span>{" "}
    //     <div className="text-xl">
    //     {activeCount}
    //     </div>
    //   </div>

    //   <div className="text-xs flex flex-col border p-3 font-extrabold mt-1 w-[180px]">
    //     <span className="font-normal ">Asset With Expired Lease: </span>{" "}
    //    <span className="text-xl">
    //     {expiredCount}
    //    </span>
    //   </div>
    //   <div className="text-xs flex flex-col border p-3 font-extrabold mt-1 w-[180px]">
    //     <span className="font-normal ">Free Assets: </span> 
    //     <span className="text-xl">
    //     {FreeAssetCount}
    //     </span>
    //   </div>
    //   <div className="text-xs flex flex-col border p-3 font-extrabold mt-1 w-[180px]">
    //     <span className="font-normal ">Sold Assets: </span>
    //     <span className="text-xl">
    //      {soldAssets}
    //     </span>
    //   </div>
    //   <div className="text-xs flex flex-col border p-3 font-extrabold mt-1 w-[180px]">
    //     <span className="font-normal ">Tracker Installed Assets: </span>{" "}
    //     <span className="text-xl">
    //     {trackerInstalledAssets}
    //     </span>
    //   </div>
    // </div>
    <>
    <div className="flex mb-3 gap-3 text-xs mt-2 justify-start ">
        {HeaderData.map((data) => <>
        <div className=" p-3 w-[180px]  rounded-[4px] shadow-md bg-white " key={data.id}>
            <h1 className="">{data.title}</h1>
            <span className="text-base font-extrabold text-light-2 ">{data.data}</span>
        </div>
        </>)}
    </div>
    </>
  );
};

export default AssetTableHeader;
