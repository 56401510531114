import React from "react";

import AssetDetailsTab from "../AssetDetailsTab";
import { capitalizeFirstLetter, formatDate } from "../../../../customFunctions/FormatDate";

const LeaseInfo = ({
  leaseSpecificDetails,
  asset_id,
  leaseId,
  runDuration,
}) => {
  let numb = 0;
  if (leaseSpecificDetails?.lease_status?.startsWith("expiring")) {
    // Match a number within the string "expiring in X days"
    const match = leaseSpecificDetails?.lease_status.match(/\d+/);

    if (match) {
      numb = parseInt(match[0], 10);
    }
  }

  return (
    <>
      <div
        className="flex flex-col md:flex-row 
            text-xs w-[93vw]  justify-between  mt-2 gap-4 dark:bg-slate-900  space-y-2 md:space-y-0"
      >
        {/* Frame 1 */}
        <div className="flex-1 border bg-white px-4 text-[10px] rounded-[4px] shadow-md ">
          <div title="RSO ID" className="py-2">
            <span className="text-gray-500">RSO Id</span>
            <span
              className={`block font-semibold text-xs
               `}
            >
              {capitalizeFirstLetter(leaseSpecificDetails?.odoo_order_id)}
            </span>
          </div>
          <div title="asset number added to the lease" className="py-2">
            <span className="text-gray-500">Asset No</span>
            <span
              className={`block font-semibold text-xs ${
                leaseSpecificDetails?.device_id ? "text-green-1" : "text-dark-6"
              }
               `}
            >
              {/* AvijitDa */}
              {leaseSpecificDetails?.asset_no}
            </span>
          </div>
        </div>

        <div className="flex-1 border bg-white px-4 text-[10px] rounded-[4px] shadow-md ">
        <div title="customer name" className="py-2">
            <span className="text-gray-500">Customer Name</span>
            <span
              className={`block font-semibold text-[10px] 
               `}
            >
              {/* AvijitDa */}
              {leaseSpecificDetails?.customer}
            </span>
          </div>
          <div title="lease type" className="py-2">
            <span className="text-gray-500">Lease Type</span>
            <span
              className={`block font-semibold text-[12px] 
               `}
            >
              {/* {leaseSpecificDetails?.lease_type?  leaseSpecificDetails?.lease_type: "N/A" } */}
              {leaseSpecificDetails?.lease_type
                ? leaseSpecificDetails?.lease_type === "wet_lease"
                  ? "Wet" : leaseSpecificDetails?.lease_type === "wet"
                  ? "Wet"
                  : leaseSpecificDetails?.lease_type === "customer_certified"
                  ? "Customer Certified"
                  : leaseSpecificDetails?.lease_type === "dry_lease"
                  ? "Dry Lease" :  leaseSpecificDetails?.lease_type === "dry"
                  ? "Dry Lease"
                  : leaseSpecificDetails?.lease_type === "nill"
                  ? "N/A"
                  : "N/A"
                : "N/A"}
              {/* {console.log(leaseSpecificDetails?.lease_type)} */}
            </span>
          </div>
        </div>

        {/* Frame 2 */}
        <div className="flex-1 border bg-white px-4 text-[10px] rounded-[4px] shadow-md ">
          <div title="rental start date" className="py-2">
            <span className="text-gray-500">Rental Start Date</span>
            <span className="block font-semibold text-xs">
              {formatDate(leaseSpecificDetails?.rental_start_date)}
            </span>
          </div>
          <div title="rental end date" className="py-2">
            <span className="text-gray-500"> Rental End Date:</span>
            <span className="block font-semibold text-[12px]">
              {leaseSpecificDetails?.closed_date
                ? leaseSpecificDetails?.closed_date
                : leaseSpecificDetails?.rental_extended_date
                ? formatDate(leaseSpecificDetails?.rental_extended_date)
                : formatDate(leaseSpecificDetails?.rental_end_date)}
            </span>
          </div>
        
        </div>

        {/* Frame 3 */}
        <div className="flex-1 border bg-white px-4 text-[10px] rounded-[4px] shadow-md ">
         
          <div title="lease status" className="py-2">
            <span className="text-gray-500">Status</span>
            <span
              className={`block font-semibold text-[12px] 
                ${ new Date(leaseSpecificDetails?.rental_start_date) > new Date() ? 'text-green-2' : 
                  leaseSpecificDetails?.lease_status === "inactive"
                    ? "text-gray-500"
                    : leaseSpecificDetails?.lease_status === "never assigned" || leaseSpecificDetails?.lease_status ===
                            "expired"
                    ? "text-red-600"
                    : "text-green-500"
                }`}
            >
              {new Date(leaseSpecificDetails?.rental_start_date) > new Date() ? 'Booked' : leaseSpecificDetails?.lease_status?.startsWith("expiring")
                ? `Active (${numb} days)`
                : leaseSpecificDetails?.lease_status === "inactive"
                ? "Closed"
                : leaseSpecificDetails?.lease_status === "expired" ? "Exceeded" : capitalizeFirstLetter(leaseSpecificDetails?.lease_status)}
              {/* {leaseSpecificDetails?.lease_status === "Inactive" || leaseSpecificDetails?.lease_status === "never assigned" ? "Closed" : leaseSpecificDetails?.lease_status?.toLowerCase()?.includes("expiring") ? `Active ` */}

              {/* : "Active"} */}
            </span>
          </div>
          <div title="runtime duration" className="py-2">
            <span className="text-gray-500">
              Runtime Duration (Till last update)
            </span>
            <span
              className={`block font-semibold text-[12px] 
               `}
            >
              {/* AvijitDa */}
              {runDuration === null || undefined ? (
                <div className="w-[67px] h-7 bg-[#EEE] border rounded-md animate-pulse" />
              ) : (
                runDuration
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaseInfo;
