import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { setBaseUrl } from "../../../config";

function BasicDetails({ assetBasicDetails, setAssetBasicDetails, id }) {
  
  const categories = [
    "Articulating Boom",
    "Telescopic Boom",
    "Truck Mounted Boom",
    "Diesel Scissors",
    "VTL",
    "Runabout",
    "Battery Scissors",
  ];

  const handleSelectChange = (e) => {
    setAssetBasicDetails({
      ...assetBasicDetails,
      category: e.target.value,
    });
  };
  useEffect(() => {
    setAssetBasicDetails({
      ...assetBasicDetails,
      category: categories[0],
    });
    // console.log(assetBasicDetails);
  }, []);

  const [checkAuthority, setCheckAuthority] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [otp, setOtp] = useState("");
 
console.log(assetBasicDetails.is_sold,"is_sold")

// const [confirmSold, setConfirmSold] = useState({is_sold : "false"})


  const getOtpFunction = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/asset/get-otp-sold-asset`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": id,
        },
      });
      if (res === 200) {
        alert("OTP Has been sent to your email");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const verifyOtp = async () => {
    try {
      
      const res = await fetch(`${setBaseUrl}/asset/update-otp-sold-asset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
         

        },
        credentials: "include",
        body: JSON.stringify({ id, otp,  is_sold: true})
      })

      if(res === 200) {
        setIsVerify(true);
        // setAssetBasicDetails({...assetBasicDetails, is_sold: true})
        alert("Verified")
      }
    } catch (error) {
      console.error(error)
      alert("Verification Failed")
    }
  }

  const handleSoldVerification = () => {
    verifyOtp()
    setCheckAuthority(false);
  };
  
  const handleIsSold = () => {
    getOtpFunction();
    // setAssetBasicDetails({ ...assetBasicDetails, is_sold: true })
  };
  return (
    <>
    
      <div className="p-10 text-xs gap-4 z-20  border bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
        {/*Asset No  */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Asset No</label>
          <input
            type="text"
            value={assetBasicDetails?.asset_no}
            placeholder="Asset No"
            class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                asset_no: e.target.value,
              })
            }
          />
        </div>
        {/* Make */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Make</label>
          <input
            type="text"
            value={assetBasicDetails?.make}
            placeholder="Make"
            class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                make: e.target.value,
              })
            }
          />
        </div>
        {/* Model */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Model</label>
          <input
            type="text"
            value={assetBasicDetails?.model}
            placeholder="Model"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                model: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Description */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Description</label>
          <textarea
            rows="2"
            value={assetBasicDetails?.description}
            placeholder="Say something that best describes the asset"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                description: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                focus:outline-none
                focus:border-gray-200 focus:ring-0
                text-xs"
          />
        </div>
        {/* Serial No */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Serial No</label>
          <input
            type="text"
            value={assetBasicDetails?.serial_no}
            placeholder="Serial No"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                serial_no: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Purchased from */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Purchased from</label>
          <input
            type="text"
            value={assetBasicDetails?.purchased_from}
            placeholder="purchased from"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                purchased_from: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* RFID */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">RFID</label>
          <input
            type="text"
            value={assetBasicDetails?.rfid}
            placeholder="RFID Not Required"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                rfid: e.target.value,
              })
            }
            disabled={true}
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>

        {/* Device No */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Device No</label>
          <input
            type="text"
            value={assetBasicDetails?.device_no}
            placeholder="Device Hash Not Required"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                device_no: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* installation_date */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Device Installation Date</label>
          <input
            type="date"
            value={assetBasicDetails?.installation_date}
            placeholder="Device Hash Not Required"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                installation_date: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* YOM */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Year of Manufacturing</label>
          <input
            type="text"
            value={assetBasicDetails?.yom}
            placeholder="Year of Manufacturing"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                yom: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Site Location */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Site Location</label>
          <input
            type="text"
            value={assetBasicDetails?.site_location}
            placeholder="Site Location"
            required
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                site_location: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Height of Machine */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Height of Machine</label>
          <input
            type="text"
            value={assetBasicDetails?.hieght_machine}
            placeholder="e.g. 10 ft"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                hieght_machine: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Capacity of machine */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Capacity of Machine</label>
          <input
            type="text"
            value={assetBasicDetails?.capacity}
            placeholder="e.g. 10 ft"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                capacity: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          />
        </div>
        {/* Rating */}
        <div className="flex-1 w-[200px] ">
          <label className="font-bold">Ratings(out of 5)</label>
          <select
            type="text"
            value={assetBasicDetails?.rating}
            placeholder="e.g. 10 ft"
            onChange={(e) =>
              setAssetBasicDetails({
                ...assetBasicDetails,
                rating: e.target.value,
              })
            }
            class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        {/* Cateogry */}
        <div class="flex-1 w-[200px]">
          <label class="">Category*</label>
          <select
            // className="border-2 rounded-md p-2 text-xs text-black"
            className="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
            onChange={handleSelectChange}
          >
            {categories.map((category) => (
              <option
                key={category}
                value={category}
                selected={assetBasicDetails?.category === category}
              >
                {category}
              </option>
            ))}
          </select>
        </div>
  
        {/* machine sold function */}
        {id ? (
          <>
            <div className="flex-1 w-[200px]">
              <label className="font-bold">Machine Sold</label>
              <div className="flex gap-4">
                {/* Radio button for 'Yes' */}
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="is_sold"
                    value={true}
                    checked={assetBasicDetails.is_sold === true}
                    onChange={handleIsSold}
                    className="mr-2 focus:ring-0"
                    onClick={() => setCheckAuthority(true)}
                  />
                  Yes
                </label>

                {/* Radio button for 'No' */}
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="is_sold"
                    value={false}
                    checked={assetBasicDetails.is_sold === false}
                    onChange={handleIsSold}
                    className="mr-2 focus:ring-0"
                    onClick={() => setCheckAuthority(false)}
                  />
                  No
                </label>
              </div>
              {/* Avijit */}
              {/* Machine Sold Date */}
              {isVerify && (
                <>
                  <div className="flex-1 w-[200px] mt-3">
                    <label className="font-bold">Machine Sold Date</label>
                    <input
                      type="date"
                      value={assetBasicDetails?.machine_sold_date} //avijit
                      onChange={(e) =>
                        setAssetBasicDetails({
                          ...assetBasicDetails,
                          machine_sold_date: e.target.value, //avijit
                        })
                      }
                      class="w-[200px] h-8 rounded border-gray-200
                    focus:outline-none
                    focus:border-gray-200 focus:ring-0
                    text-xs"
                    />
                  </div>
                </>
              )}
            </div>
            {checkAuthority && (
              <>
                <div className="z-[9999] text-xs p-4 h-[250px] w-[500px] fixed top-[20%] text-black left-[30%] rounded bg-white">
                  <button className="" onClick={() => setCheckAuthority(false)}>
                    Close
                  </button>
                  <div className="items-center justify-center flex flex-col gap-6">
                    <h1 className="font-extrabold text-[#8d8d8d]">Enter OTP</h1>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{ color: "black", width: "50px" }}
                        />
                      )}
                    />
                    <button
                      className="bg-light-1 text-white w-[67px] rounded h-7"
                      onClick={handleSoldVerification}
                    >
                      Verify
                    </button>
                  </div>
                </div>
                <div
                  className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000021] z-50 "
                  onClick={() => setCheckAuthority(false)}
                />
              </>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default BasicDetails;
