import { useEffect, useState } from "react";
import { setBaseUrl } from "../../../../config";

export const useGetCustomer = (url) => {
  const [customerArray, setCustomerArray] = useState([]);

  const fetchCustomer = async () => {
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "unassigned-asset": "true",
        },
      });

      const data = await res.json();

      // Flatten the array of arrays into a single array of objects
      const flattenedData = data.flat();
      const formattedCustomers = flattenedData.map((customer) => ({
        label: customer.label,
        value: customer.value,
      }));

      setCustomerArray(formattedCustomers); // Update state with flattened data
      // console.log(formattedCustomers);  // Check if the formatting is correct
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  return { customerArray, setCustomerArray };
};

export const useGetAsset = (url) => {
  const [assetArray, setAssetArray] = useState([]);

  const fetchAssets = async () => {
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "unassigned-asset": "true",
        },
      });

      const data = await res.json();
      const formattedAssets = data.map((asset) => ({
        asset_no: asset.asset_no,
        id: asset.id,
      }));
      setAssetArray(formattedAssets);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [url]);

  // Defining Object

  // console.log(assetArray)
  return { assetArray };
};

export const useGetOperator = (url) => {
  const [operatorArray, setOperatorArray] = useState([]);

  const fetchOperators = async () => {
    try {
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      const data = await res.json();

      const formattedOperator = data.map((operator) => ({
        value: operator.id,
        label: operator.name,
      }));

      setOperatorArray(formattedOperator);
    } catch (error) {}
  };

  useEffect(() => {
    fetchOperators();
  }, [url]);

  return { operatorArray };
};


export const useGetOperatorAccount = (url) => {
  const [operatorArray, setOperatorArray] = useState([]);

  const fetchOperators = async () => {
    try {
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      const data = await res.json();

      const formattedOperator = data.map((operator) => ({
        name: operator.name,
        account: operator.account_no,
      }));

      setOperatorArray(formattedOperator);
    } catch (error) {}
  };

  useEffect(() => {
    fetchOperators();
  }, [url]);

  return { operatorArray };
};




