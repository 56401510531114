import React, { useEffect, useState } from "react";
import { get_log_table_data } from "../../../apis/LeaseApis";
import {
  convertToHoursAndMinutes,
  currency,
  currencyInLakhs,
  formatDate,
  formatMonth,
} from "../AssetDetails/Tabs/FormatDate";
import CreateLogTable from "./CreateLogTable";
import { IoMdAdd } from "react-icons/io";

function LogTable({ rso_id, asset_no, setRunDuration , leaseId}) {


  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState(true);
  const [expandedSections, setExpandedSections] = useState([]);
// console.log(tableData)
  const fetchLogTableData = async () => {
    try {
      setLoading(true);
      const { data } = await get_log_table_data(rso_id);
      setTableData(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLogTableData();
  }, []);

  let table = tableData[0] || {};
  let keys = Object.keys(table);
  let totalAmount = tableData[1] ? Object.values(tableData[1]).reduce((sum, amt) => sum + amt, 0) : 0;

  
  setRunDuration(convertToHoursAndMinutes(totalAmount));

  let calculatedOdooAmount = 0;
  let overtimeAmount = 0;

  const toggleSection = (index) => {
    setExpandedSections((prev) => {
      const newExpandedSections = [...prev];
      newExpandedSections[index] = !newExpandedSections[index];
      return newExpandedSections;
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[50vh]">
        <div className="animate-pulse w-full max-w-lg p-5 text-center border rounded-xl shadow-lg bg-gray-300 text-xs">
          Loading data...
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-end justify-end gap-2 mt-5 ">
      <button
          className=" text-[12px] bg-white text-dark-6 buttons"
          onClick={() => setViewData(true)}
        >
          View
        </button>
        <button
          className=" shadow-md text-white bg-blue-2 buttons"
          onClick={() => setViewData(false)}
        >
         
         Add New
        </button>
       
      </div>

      {viewData ? (
        <div className="mt-4">
          <div className="pt-2 px-4 mb-2 h-[32px] items-center text-xs text-gray-800 w-[320px] bg-white shadow-md rounded-[4px]">
            Total Working Time: {convertToHoursAndMinutes(totalAmount)}
          </div>

          {keys.reverse().map((key, index) => {
            const { formattedMonth, year } = formatMonth(key);
            let monthData = table[key] || [];
            // console.log(tableData[1][key])
            return (
              <div key={index} className="mb-0 shadow-md text-text-1 border-b border-light-10  transition-all duration-200 ease-in ">
                <div
                  onClick={() => toggleSection(index)}
                  className="flex items-center justify-between px-4 py-2 shadow-xl bg-white hover:bg-[#8c8c8c] hover:text-white hover:shadow-md h-[32px] cursor-pointer"
                  >
            
                  <div className="text-[10px] font-semibold">
                    {formattedMonth}, {year}
                  </div>
                  <div className="flex gap-4 text-[10px] ">
                    <span>
                     <strong>Total Billing Amount:</strong> 
                      
                       {parseFloat(tableData[1][key]).toFixed(2)}
                       </span>
                   
                    <span>
                     <strong>Total Overtime Amount:</strong> 
                      
                       ₹{currencyInLakhs(overtimeAmount)}</span>
                  </div>
                </div>

                {expandedSections[index] && (
                  <div className="overflow-x-auto text-[10px] transition-all duration-300 ease-in p-4 border  bg-light-11">
                    <table className="w-full table-auto text-center bg-white">
                      <thead className="border-b">
                        <tr>
                          <th className="p-2 ">Date</th>
                          <th className="p-2 ">Day Type</th>
                          <th className="p-2 ">Time In</th>
                          <th className="p-2 ">Time Out</th>
                          <th className="p-2 ">Total Time</th>
                          <th className="p-2 ">Overtime</th>
                          <th className="p-2 ">Time Adjust</th>
                          <th className="p-2 ">Odoo Bill Amount</th>
                          <th className="p-2 ">Overtime Amount</th>
                          <th className="p-2 ">Operator 1</th>
                        </tr>
                      </thead>
                      <tbody>
                        {monthData.map((item, i) => {
                          calculatedOdooAmount += item.x_studio_normal_bill_amount;
                          overtimeAmount += item.x_studio_overtime_amount;

                          return (
                            <tr key={i} className="border-b">
                              <td className="p-2 ">
                                <input
                                  value={formatDate(item.x_studio_date)}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 ">
                                <input
                                  value={item.x_studio_day_type}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 ">
                                <input
                                  value={item.x_studio_time_in}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 ">
                                <input
                                  value={item.x_studio_time_out}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 ">
                                <input
                                  value={item.x_studio_total_time}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 ">
                                <input
                                  value={item.x_studio_overtime}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 ">
                                <input
                                  value={item.x_studio_time_adjust}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                              <td className="p-2 ">
                                <div className="flex items-center justify-center">
                                  <input
                                    value={parseFloat(item.x_studio_normal_bill_amount).toFixed(2)}
                                    className="w-full text-right focus:outline-none"
                                  />
                                  <span className="pl-1">₹</span>
                                </div>
                              </td>
                              <td className="p-2 ">
                                <div className="flex items-center justify-center">
                                  <input
                                    value={parseFloat(item.x_studio_overtime_amount).toFixed(2)}
                                    className="w-full text-right focus:outline-none"
                                  />
                                  <span className="pl-1">₹</span>
                                </div>
                              </td>
                              <td className="p-2 ">
                                <input
                                  value={item.x_studio_operator1}
                                  className="w-full text-center focus:outline-none"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <CreateLogTable rso_id={rso_id} asset_no={asset_no}  leaseId={leaseId} tableData={tableData[0]}/>
      )}
    </>
  );
}

export default LogTable;
