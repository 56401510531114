import React from 'react'
import SalesLayout from '../SalesLayout'
import SalesDashboard from './SalesDashboard'
import ServiceDashboard from './ServiceDashboard'

const SaleServiceDashboard = () => {
  const role = sessionStorage.getItem('sales_role')
  
  return (
    <SalesLayout>
      

      {role === 'sale\n' ? <>
      <SalesDashboard/>
      </> : role === 'service\n' ? <>
      <ServiceDashboard/>
      </> : <>
      <SalesDashboard/>
      <ServiceDashboard/>

      </>}


    </SalesLayout>
  )
}

export default SaleServiceDashboard