import React, { useRef } from "react";

const OperatorSearch = ({
  handleSearchCategory,
  searchValue,
  handleSearch,
  handleSearchClicik,
  open,
  setOpen,
  setOpenSearch,
}) => {
  const inputRef = useRef(null); // Create a ref for the input field

  const handleSearchIconClick = () => {
    setOpen(true); // Open the search input
    setTimeout(() => {
      inputRef.current?.focus(); // Set focus to the input field
    }, 100); // Ensure the input is visible before focusing
  };

  return (
    <div className="flex">
      <select
        className={`flex-shrink-0 z-10 inline-flex items-center h-7 bg-[#EEEE] rounded-full transition-all ease-in-out duration-500 focus:border-none text-xs border-none focus:outline-none focus:ring-0 
        ${open ? "opacity-100" : "opacity-0 delay-0 hidden"}`}
        onChange={(e) => handleSearchCategory(e.target.value)}
        defaultValue={searchValue}
      >
        <option value="adhaarNo">Adhaar No.</option>
        <option value="pf">PF A/c. No.</option>
        <option value="name">Name</option>
      </select>

      <button
        className="p-2 rounded-[5.6px]  items-center text-center hover:bg-[#eee]"
        onClick={handleSearchIconClick}
      >
        <svg
          className="w-3 h-3 text-gray-500"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </button>

      <input
        ref={inputRef} // Attach ref to the input field
        type="text"
        placeholder="Search"
        className={`h-7 border-none focus:outline-none bg-transparent focus:ring-0 focus:border-none text-xs transition-all duration-500 ease-in-out ${
          open ? "w-[150px] opacity-100" : "w-0 opacity-0"
        }`}
        value={searchValue}
        onChange={handleSearch}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            handleSearchClicik(event.target.value);
          }
        }}
        onFocus={() => setOpenSearch(true)}
      />
    </div>
  );
};

export default OperatorSearch;
