import React from "react";
import AssetUpdateDropdown from "../AssetUpdateDropdown";
import RsoUpdateDropdown from "../RsoUpdateDropdown";

const UpdateForm = ({
  title,
  keyValueType,
  type,
  value,
  formData,
  setFormData,
  assets_no, // added assets_no prop
  lease_RSO_nos, // added lease_RSO_nos prop for RSO numbers
  selectedAssets, setSelectedAssets,
  Asset_No,
  RSO_No,
  deleteRSO,
  setDeleteRSO,
  deleteAssets,
  setDeleteAssets,
  selectedRso,
  setSelectedRso
}) => {



  return (
    <>
      {title === "Asset No." ? (
        <div className="text-[12px] flex flex-col items-left justify-between">
          <h1>{title}</h1>
          {formData?.is_internal_tranfer && <AssetUpdateDropdown
            assets={Asset_No}
            value={value}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
          />}


          <ol>
            {formData?.assets_no
              ?.filter(asset => !deleteAssets.includes(asset)) // Exclude deleted assets
              .map((asset, index) => (
                <li key={index} className="flex justify-between my-1">
                  <span>{asset}</span>
                  <button
                    className="buttons border-red-1 text-red-1 border hover:text-white hover:bg-red-1"
                    onClick={() => setDeleteAssets(prev => [...prev, asset])}
                  >
                    Delete
                  </button>
                </li>
              ))
            }
          </ol>
        </div>
      ) : title === "RSO No" ? (
        <div className="text-[12px] flex flex-col items-left justify-between">
          <h1>{title}</h1>
          {/* <select
            onChange={(e) => setFormData({ ...formData, lease_RSO_nos: e.target.value })}
            className="w-[400px] text-[12px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8"
          >
            
            {formData?.lease_RSO_nos?.map((rso, index) => (
              <option key={index} value={rso}>
                {rso}
              </option>
            ))}
          </select> */}
          <RsoUpdateDropdown
            rsos={RSO_No}
            value={value}
            selectedRso={selectedRso}
            setSelectedRso={setSelectedRso}
          />


          {/* {console.log(deleteRSO)} */}
          <ol>
            {formData?.lease_RSO_nos
              ?.filter(rso => !deleteRSO.includes(rso)) // Exclude deleted RSOs
              .map((rso, index) => (
                <li key={index} className="flex justify-between my-1">
                  <span>{rso}</span>
                  <button
                    className="buttons border-red-1 text-red-1 border hover:text-white hover:bg-red-1"
                    onClick={() => setDeleteRSO(prev => [...prev, rso])}
                  >
                    Delete
                  </button>
                </li>
              ))
            }
          </ol>

        </div>
      ) : (
        <div className="flex flex-col justify-between items-left text-[12px]">
          <h1>{title}</h1>
          <input
            onChange={(e) => setFormData({ ...formData, lease_RSO_nos: e.target.value })}
            type={type || "text"}
            value={value}
            placeholder={title}
            className="w-[400px] text-[12px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8"
          />
        </div>
      )}
    </>
  );
};

export default UpdateForm;
