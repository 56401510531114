import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { setBaseUrl } from "../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../Context/PermissionsContext";
import { NavbarContext } from "../Context/NavbarContext";
import moment from "moment";
import ResolvedTab from "./ResolvedTab";

const ErrorModule = () => {
  // Authentication context
  const navigate = useNavigate();
  const [perms, setPerms] = useContext(PermissionContext);

  const [navState, setNavState] = useContext(NavbarContext);

  useEffect(() => {
    if (sessionStorage.getItem("asset_tracker_logged_in") !== "true") {
      navigate("/login");
    }
    // assets();
    setNavState(4);
  }, []);

  useEffect(() => {
    let getPermissionsFromSession = JSON.parse(
      sessionStorage.getItem("permissions")
    );
    setPerms(getPermissionsFromSession);
  }, []);

  // error module details
  const hasFetched = useRef(false);

  const [errorModule, setErrorModule] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedRSO, setSelectedRSO] = useState(null);
  const [selectedError, setSelectedError] = useState(null); // Track selected error for modal
  const [showModal, setShowModal] = useState(false); // Control modal visibility
  const [formdata, setFormData] = useState({}); // Initialize as an empty object
  const [isResolvedTab, setIsResolvedTab] = useState(false);
  const [nextDay, setNextDay] = useState(false);

  // console.log(formdata)
  // const handleUploadTime = (key, value, id, start, end, isNextDay,) => {
  //   const startDate = start?.split(" ")[0];
  //   let endDate = startDate;

  //   if (isNextDay && startDate) {
  //     console.log("startDate:", startDate);
  //     console.log("isNextDay:", isNextDay);

  //     // Validate startDate format
  //     if (!/^\d{4}-\d{2}-\d{2}$/.test(startDate)) {
  //       console.error("Invalid startDate format:", startDate);
  //       return;
  //     }

  //     const [year, month, day] = startDate.split("-").map(Number);
  //     const incrementedDate = new Date(year, month - 1, day);

  //     // Validate the Date object
  //     if (isNaN(incrementedDate.getTime())) {
  //       console.error("Invalid date object created:", year, month, day);
  //       return;
  //     }

  //     incrementedDate.setDate(incrementedDate.getDate() + 1);
  //     endDate = incrementedDate.toLocaleDateString("en-CA"); // Fallback to locale format

  //     console.log("Final endDate:", endDate);
  //   }

  //   const formattedValue =
  //     key === "corrected_start_time"
  //       ? `${startDate} ${value || start?.split(" ")[1]}`
  //       : key === "corrected_end_time"
  //       ? `${endDate} ${value || end?.split(" ")[1]}`
  //       :   value;

  //   setFormData((prev) => {
  //     const updatedFormData = {
  //       ...prev,
  //       [id]: {
  //         ...(prev[id] || {}),
  //         [key]: formattedValue,
  //         working_time_id: id,
  //       },
  //     };

  //     return updatedFormData;
  //   });
  // };

  // const handleUploadTime = (key, value, id, start, end, isNextDay) => {
  //   let nextDay = false;
  //   if(isNextDay) {nextDay = true}

  //   const startDate = start?.split(" ")[0];
  //   let endDate = end?.split(" ")[0];

  //   if(startDate != endDate) {
  //     nextDay = true;
  //   } else {
  //     endDate = startDate;
  //   }

  //   const formattedValue =
  //     key === "corrected_start_time"
  //       ? `${startDate} ${value || start?.split(" ")[1]}`
  //       : key === "corrected_end_time"
  //       ? `${endDate} ${value || end?.split(" ")[1]}`
  //       : value;

  //   console.log("Formatted Value:", formattedValue);

  //   setFormData((prev) => {
  //     const updatedFormData = {
  //       ...prev,
  //       [id]: {
  //         ...(prev[id] || {}),
  //         [key]: formattedValue,
  //         working_time_id: id,
  //       },
  //     };

  //     console.log("Updated formData:", updatedFormData);
  //     return updatedFormData;
  //   });
  // };

  const handleUploadTime = (key, value, id, start, end, isNextDay) => {
    let nextDay = isNextDay;

    const startDate = start?.split(" ")[0];
    let endDate = end?.split(" ")[0];
    // If start date is not equal to end date, and isNextDay is true, set the end date to the next day
    if (nextDay) {
      // This means the user is selecting the next day for the end time
      endDate = new Date(
        new Date(startDate).setDate(new Date(startDate).getDate() + 1)
      )
      .toISOString()
      .split("T")[0];
      console.log(endDate, "End Date")
    } else {
      endDate = startDate; // If unchecked, make the start and end date the same
    }

    const formattedValue =
      key === "corrected_start_time"
        ? `${startDate} ${value || start?.split(" ")[1]}`
        : key === "corrected_end_time"
        ? `${endDate} ${value || end?.split(" ")[1]}`
        : value;

    console.log("Formatted Value:", formattedValue);

    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        [id]: {
          ...(prev[id] || {}),
          [key]: formattedValue,
          working_time_id: id,
        },
      };

      console.log("Updated formData:", updatedFormData);
      return updatedFormData;
    });
  };

  const handleSubmit = async (e) => {
    const submittedData = {
      data_list: Object.values(formdata),
    };

    // console.log(submittedData);

    try {
      if (Object.keys(submittedData.data_list).length === 0) {
        toast.error("No data to submit!!");
        return;
      }
      const res = await fetch(`${setBaseUrl}/error/corrections-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(submittedData),
      });
      if (res.ok) {
        toast.success("Data submitted successfully!!");
      }
    } catch (error) {
      toast.error("Failed to submit data!!");
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return "--:--"; // Handle missing data
    const [hours, minutes] = timeString.split(":");
    return `${hours}:${minutes}`;
  };
  let total_time = 0;
  const calculateTotalTime = (inTime, outTime) => {
    if (!inTime || !outTime) return "--:--"; // Handle missing data

    // Parse the datetime strings into Date objects
    const inDate = new Date(inTime);
    const outDate = new Date(outTime);

    // Calculate the difference in milliseconds
    const diff = outDate - inDate;

    // Calculate hours, minutes, seconds, and milliseconds

    if (diff < 0) return "--:--"; // Handle invalid or past-midnight scenarios

    // Convert milliseconds into hours, minutes, and days
    const totalMinutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Format hours and minutes for "HH:mm" format
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    // console.log(formattedHours, formattedMinutes)
    const totalMilliseconds = diff % 1000;
    const totalSeconds = Math.floor(diff / 1000);
    const seconds = totalSeconds % 60;
    const difftotalMinutes = Math.floor(totalSeconds / 60);
    const diffminutes = totalMinutes % 60;
    const diffhours = Math.floor(totalMinutes / 60);

    // Format hours, minutes, seconds, and milliseconds
    const diffformattedHours = String(hours).padStart(2, "0");
    const diffformattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    const formattedMilliseconds = String(totalMilliseconds).padStart(6, "0");

    // Return the formatted time
    total_time = `${diffformattedHours}:${diffformattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
    return `${formattedHours}h ${formattedMinutes}m`; // Return the formatted time
  };

  const getError = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${setBaseUrl}/error/get-all-errors`, {
        method: "GET",
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      const data = await response.json();
      setErrorModule(data);

      // Set default selected RSO to the first key in the object
      const firstKey = Object.keys(data)[0];
      setSelectedRSO(firstKey);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      getError();
    }
  }, []);

  const handleViewDetails = (detail) => {
    setSelectedError(detail);
    setShowModal(true);
  };

  const convertTo12HrFormat = (timeString) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(":").map(Number);

    // Format the result into the "12 hr 43 mins" format
    return `${hours} hr ${minutes} mins`;
  };

  const renderTableRows = () => {
    return Object.entries(errorModule).map(([rsoNo, errors]) => (
      <tr
        key={rsoNo}
        className={`cursor-pointer ${
          selectedRSO === rsoNo ? "bg-dark-6 text-white" : ""
        }`}
        onClick={() => setSelectedRSO(rsoNo)}
      >
        <td className="p-3 border text-sm ">{errors[0].asset_no}</td>
        <td className="p-3 border text-sm  font-medium">{rsoNo}</td>
        <td className="p-3 border text-sm ">{errors.length}</td>
      </tr>
    ));
  };

  const renderDetails = () => {
    if (!selectedRSO || !errorModule[selectedRSO]) {
      return <p>No details available.</p>;
    }

    const details = errorModule[selectedRSO];

    const formatOperatorTime = (timeString) => {
      if (!timeString) return; // Handle missing data
      const time = timeString.split(" ")[4].split(":").slice(0, 2).join(":");

      return time
    }

    return (
      <div className="h-[80vh] border p-4 overflow-y-auto">
        <h2 className="text-lg font-bold mb-4">Details for {selectedRSO}</h2>
        <table className="w-full border-collapse border border-gray-300 text-[12px] font-sans table-auto shadow-md">
          <thead className="bg-gray-200">
            <tr className="border-t">
              {/* <th className="p-2 border">Asset No</th> */}
              <th className="border border-gray-300 px-4 py-2 w-[120px]">
                Date
              </th>
              <th className="border border-gray-300 px-4 py-2">Start Time</th>
              <th className="border border-gray-300 px-4 py-2">End Time</th>
              <th className="border border-gray-300 px-4 py-2">
                Total Machine Time
              </th>
              <th className="border border-gray-300 px-4 py-2">
                Operator Name
              </th>
              <th className="border border-gray-300 px-4 py-2">In Time</th>
              <th className="border border-gray-300 px-4 py-2">Out Time</th>
              {/* <th className="border border-gray-300 px-4 py-2">Device In Time</th>
              <th className="border border-gray-300 px-4 py-2">Device Out Time</th>
              <th className="border border-gray-300 px-4 py-2">Total Time</th> */}
              <th className="border border-gray-300 px-4 py-2">Errors</th>
            </tr>
          </thead>
          <tbody>
            {details.length > 0 &&
              details?.sort().map((detail, index) => (
                <tr key={index} className="odd:bg-white even:bg-gray-50">
                  {/* <td className="p-2 border">{detail.asset_no}</td> */}
                  <td className="p-2 border w-[120px]">
                    {moment(detail.date).format("DD-MM-YYYY")}
                  </td>
                  <td className="p-2 border">
                    {formatTime(detail.start_time.split(" ")[1])}
                    {/* {console.log(detail.start_time)} */}
                  </td>
                  <td className="p-2 border">
                    {formatTime(detail.end_time.split(" ")[1])}
                  </td>
                  <td className="p-2 border">
                    {calculateTotalTime(detail?.start_time, detail?.end_time)}
                  </td>
                  <td className="p-2 border">
                    {detail.operator_name || "N/A"}
                  </td>
                  <td className="p-2 border"> {detail.operator_time_in ? formatOperatorTime(detail.operator_time_in) : "N/A"}</td>
                  <td className="p-2 border"> {detail.operator_time_out ? formatOperatorTime(detail.operator_time_out) : "N/A"}</td>
                              {/* <td className="p-2 border"> {detail.operator_time_in ? moment(detail.operator_time_in).format("h:mm") : "N/A"}</td> */}
                  {/* <td className="p-2 border"> {detail.operator_time_out ? moment(detail.operator_time_out).format("h:mm") : "N/A"}</td> */}
                  {/* <td className="p-2 border">
                  <input
                    type="time"
                    // value={formdata.corrected_start_time || ""}
                    className="border p-1 w-full border-gray-300 rounded px-2 py-1 text-xs"
                    onChange={(e) =>
                      handleUploadTime(
                        "corrected_start_time",
                        e.target.value,
                        detail.working_time_id,
                        detail.start_time,
                        detail.end_time
                      )
                    }
                  />
                </td>
                <td className="px-4 py-2 border  flex items-center justify-center">
                  <input
                    type="time"
                    // value={formdata.corrected_end_time || ""}
                    className="border p-1 w-full border-gray-300 rounded px-2 py-1 text-xs"
                    onChange={(e) =>
                      handleUploadTime(
                        "corrected_end_time",
                        e.target.value,
                        detail.working_time_id,
                        detail.start_time,
                        detail.end_time
                      )
                    }
                  />

                  <input type="checkbox" className=""
                  
                  onChange={() => {

                  }}
                  />
                  
                </td>
                <td className="p-2 border">
                  
                  <input
                    type="time"
                    // value={calculateTotalTime(
                    //   detail?.start_time,
                    //   detail?.end_time
                    // )}
                    onChange={(e) => {
                      handleUploadTime(
                        "corrected_total_time",
                        e.target.value,
                        detail.working_time_id
                      )
                    }}
                    className="border p-1 w-full border-gray-300 rounded px-2 py-1 text-xs"
                  />
                </td> */}

                  <td className="p-2 border text-center">
                    {detail?.errors?.length}{" "}
                    <button
                      onClick={() => handleViewDetails(detail)}
                      className="ml-2 text-green-2 underline text-xs"
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderModal = () => {
    if (!selectedError.errors) return null;

    return (
      <div
        className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
        onClick={() => setShowModal(false)} // Close modal when clicking outside
      >
        <div
          className="bg-white p-6 rounded shadow-md w-2/3"
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
        >
          <h2 className="text-lg font-bold mb-4">Error Details</h2>
          <table className="w-full border-collapse border border-gray-300 text-sm">
            <thead>
              <th className="border border-gray-300 px-4 py-2">
                Device In Time
              </th>
              <th className="border border-gray-300 px-4 py-2">
                Device Out Time
              </th>
              <th className="border border-gray-300 px-4 py-2">
                Device Breakdown Time
              </th>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2 border">
                  <div className="flex flex-col gap-2">
                    {/* Display Start Time */}
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium">Start Time:</span>
                      <span className="text-sm text-gray-700">
                        {formdata[selectedError.working_time_id]
                          ?.corrected_start_time
                          ? moment(
                              formdata[selectedError.working_time_id]
                                .corrected_start_time
                            ).format("HH:mm")
                          : moment(selectedError.start_time).format("HH:mm")}
                      </span>
                    </div>

                    {/* Corrected Start Time Input */}
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="corrected_start_time"
                        className="text-xs font-medium text-gray-600"
                      >
                        Corrected Start Time:
                      </label>
                      <input
                        id="corrected_start_time"
                        type="time"
                        className="border border-gray-300 rounded px-2 py-1 text-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        onChange={(e) =>
                          handleUploadTime(
                            "corrected_start_time",
                            e.target.value,
                            selectedError.working_time_id,
                            selectedError.start_time,
                            selectedError.end_time
                          )
                        }
                      />
                    </div>
                  </div>
                </td>

                <td className="px-4 py-2 border">
                  <div className="flex flex-col gap-2">
                    {/* Display End Time */}
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-medium">End Time:</span>
                      <span className="text-sm text-gray-700">
                        {formdata[selectedError.working_time_id]
                          ?.corrected_end_time
                          ? moment(
                              formdata[selectedError.working_time_id]
                                .corrected_end_time
                            ).format("HH:mm")
                          : moment(selectedError.end_time).format("HH:mm")}
                        {/* {moment(selectedError.end_time).format("HH:mm")} */}
                      </span>
                    </div>

                    {/* Next Day Checkbox */}
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-medium text-gray-700">
                        Next Day?
                      </span>
                      {/* <input
                        type="checkbox"
                        className="w-4 h-4 accent-blue-500"
                        checked={selectedError.start_time.split(" ")[0] !== selectedError.end_time.split(" ")[0] || ''}
                        onChange={(e) => {
                          const isNextDay = e.target.checked;
                          
                          // Immediately update formData with the current corrected_end_time
                          handleUploadTime(
                            "corrected_end_time",
                            document.getElementById("corrected_end_time")
                              ?.value || "",
                            selectedError.working_time_id,
                            selectedError.start_time,
                            selectedError.end_time,
                            isNextDay
                          );
                        }}
                      /> */}
                      <input
                        type="checkbox"
                        className="w-4 h-4 accent-blue-500"
                        onChange={(e) => {
                          const isNextDay = e.target.checked;
                          setNextDay(() => isNextDay); 
                          // Immediately update formData with the current corrected_end_time
                          handleUploadTime(
                            "corrected_end_time",
                            document.getElementById("corrected_end_time")
                              ?.value || "",
                            selectedError.working_time_id,
                            selectedError.start_time,
                            selectedError.end_time,
                            isNextDay
                          );
                        }}
                      />
                    </div>

                    {/* Corrected End Time Input */}
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="corrected_end_time"
                        className="text-xs font-medium text-gray-600"
                      >
                        Corrected End Time:
                      </label>
                      <input
                        id="corrected_end_time"
                        type="time"
                        className="border border-gray-300 rounded px-2 py-1 text-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        onChange={(e) =>
                          handleUploadTime(
                            "corrected_end_time",
                            e.target.value,
                            selectedError.working_time_id,
                            selectedError.start_time,
                            selectedError.end_time,
                            nextDay
                          )
                        }
                      />
                    </div>
                  </div>
                </td>

                <td classN0ame="flex flex-col p-2 border">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium">Total Time:</span>
                    <span className="text-sm text-gray-700">
                      {moment(selectedError.total_time).format("HH:mm")}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium">
                      Total Breakdown Time:
                    </span>
                    <span className="text-sm text-gray-700">
                      {formdata[selectedError.working_time_id]?.breakdown_time
                        ? convertTo12HrFormat(
                            formdata[selectedError.working_time_id]
                              .breakdown_time
                          )
                        : selectedError.breakdown_time
                        ? moment(selectedError.breakdown_time).format("HH:mm")
                        : ""}
                    </span>
                  </div>

                  <input
                    type="time"
                    // value={}
                    onChange={(e) => {
                      handleUploadTime(
                        "breakdown_time",
                        e.target.value,
                        selectedError.working_time_id
                      );
                    }}
                    className="border p-1 w-full border-gray-300 rounded px-2 py-1 text-xs"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full border-collapse border border-gray-300 text-sm">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-2 border">Serial No</th>

                <th className="border border-gray-300 px-4 py-2">Total Time</th>
                {/* <th className="p-2 border">Error Message</th> */}
                <th className="p-2 border">Resolved</th>
              </tr>
            </thead>
            <tbody>
              {selectedError?.errors.map((error, index) => (
                <tr key={index} className="odd:bg-white even:bg-gray-50">
                  <td className="p-2 border">{index + 1}</td>

                  <td className="p-2 border">{error.error_message}</td>
                  <td className="p-2 border text-center">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        // setFormData({
                        //   ...formdata,
                        //   is_resloved: e.target.checked,
                        // })
                        handleUploadTime(
                          "is_resolved",
                          e.target.checked,
                          selectedError.working_time_id
                        )
                      }
                      className="form-checkbox"
                    />
                  </td>
                </tr>
              ))}

              <tr className="">
                <th className="p-2 border">Comments</th>
                <td className="p-2 border">
                  <textarea
                    type="text"
                    maxLength={500}
                    placeholder="Add comments"
                    className="border w-full  border-gray-300 rounded px-2 py-1 text-xs"
                    onChange={(e) => {
                      // setFormData({
                      //   ...formdata,
                      //   comment: e.target.value,
                      // });
                      handleUploadTime(
                        "comment",
                        e.target.value,
                        selectedError.working_time_id
                      );
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mt-4 flex justify-end text-xs gap-2">
            <button
              className="bg-red-1 text-white px-4 py-2 rounded"
              onClick={() => {
                setShowModal(false);
                setFormData({});
              }}
            >
              Cancel
            </button>
            <button
              className="bg-green-2 text-white px-4 py-2 rounded"
              onClick={() => {
                setShowModal(false);
                handleSubmit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <>
        <div className="flex gap-3">
          <button
            className={`
              flex py-2 px-4 rounded-lg font-medium  transition-colors border min-w-max
              ${
              isResolvedTab
                ? "border border-green-2 text-green-2"
                : "bg-green-2 text-white"
            }   px-3 py-1 `}
            onClick={() => setIsResolvedTab(false)}
          >
            Error
          </button>
          <button
            className={`
              flex py-2 px-4 rounded-lg font-medium  transition-colors border min-w-max
              ${
              isResolvedTab
                ? "bg-green-2 text-white "
                : "border border-green-2 text-green-2"
            }  px-3 py-1 `}
            onClick={() => setIsResolvedTab(true)}
          >
            Resolved
          </button>
        </div>
        {!isResolvedTab ? (
          <>
            <div className="flex flex-col md:flex-row text-xs">
              <div className="md:w-[500px] w-full bg-gray-50 p-4">
                <h1 className="font-bold text-lg border-b pb-2 mb-4">
                  Error Module
                </h1>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="w-full text-left border">
                    <thead>
                      <tr className="bg-light-9">
                        <th className="p-2 border">Asset No</th>
                        <th className="p-2 border">RSO No</th>
                        <th className="p-2 border">Errors</th>
                      </tr>
                    </thead>
                    <tbody>{renderTableRows()}</tbody>
                  </table>
                )}
              </div>
              <div className="flex-1 ">{renderDetails()}</div>
            </div>
            {/* <button
              className="bg-green-2 text-white px-3 py-1 rounded mr-2 mt-4 text-xs font-sans"
              onClick={() => {
                handleSubmit();
                setShowModal(false);
              }}
            >
              Submit
            </button> */}
            {showModal && renderModal()}
          </>
        ) : (
          <ResolvedTab />
        )}
      </>
    </Layout>
  );
};

export default ErrorModule;
