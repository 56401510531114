import React, { useState } from 'react'
import { setBaseUrl } from '../../config';
import { useToast } from '../../Context/ToastContext';

const ClosureForm = ({ maintenance, id }) => {


  const [form, setForm] = useState({

   
  })

  const { addToast } = useToast();
  const token = sessionStorage.getItem("sales_token");

  // upload Image
  const [uploadImage, setUploadImage] = useState(null);

  // Upload Image
  const handleUploadImage = (e) => {
    const files = e.target.files;
    setUploadImage(files);

    const formData = new FormData();
    formData.append('types', 'maintenance');
    formData.append('maintenance_id', id);

    if (files) {
      Array.from(files).forEach((file) => {
        formData.append('photo', file);
      });
    }

    // Debug: Log all FormData entries
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  };

  // Helper function to upload image
  const uploadImageFile = async (maintenanceId, image) => {
    try {
      const formData = new FormData();
      formData.append("types", "maintenance");
      formData.append("maintenance_id", maintenanceId);
      formData.append("photo", image[0]);

      const res = await fetch(`${setBaseUrl}/sale-service/upload_photo`, {
        method: "POST",
        headers: {
          "auth-token": token,
        },
        body: formData,
      });

      if (res.ok) {
        addToast("Image uploaded successfully!", "success");
      } else {
        addToast("Failed to upload image.", "error");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      addToast("Image upload failed.", "error");
    }
  };

  const addComment = async () => {
 
    const submittedData = {...form, maintenance_id: id, }
    try {
      const res = await fetch(`${setBaseUrl}/sale-service/add-comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(submittedData),

      })


      if (res.status === 200) {
        addToast("Comments added successfully", "success")
      
      }
    } catch (err) {
      console.error(err);
      addToast("Failed to add comments", "error")
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form);
    // // make API call here to submit form
    if (form.comment.length > 0) {
      await addComment()
    }
    // Upload Image if available
    if (uploadImage?.length) {
      console.log("upload file")
      await uploadImageFile(id, uploadImage);


    }

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }
  return (
    <div className="flex flex-col gap-4  w-full  p-5 text-xs  " >
      <h3 className="text-xs p-2 my-2 w-full bg-gray-200">Add Comment</h3>

      {/* Visit Date */}
      <div className="flex flex-col">
        <label htmlFor="" className="">Visit Date</label>
        <input type="date" value={form.visit_date || ""}  className="text-xs " onChange={(e) => { setForm({ ...form, visit_date: e.target.value }) }} />
      </div>

      <div className="">
        <label htmlFor="" className="">Comments</label>
        <textarea name="" value={form.comment || ''} className="w-full text-xs " id="" onChange={(e) => { setForm({ ...form, comment: e.target.value }) }}>

        </textarea>
      </div>

      <div className="">

        <div className="relative w-full">
          <button
            className="text-xs px-4 py-2 border rounded-md border-green-2 text-green-2 hover:bg-green-2 hover:text-white relative"
          >
            Upload File
            <input
              type="file"
              className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
              onChange={handleUploadImage}
            />
          </button>
          {console.log(uploadImage)}
          {uploadImage && <img src={uploadImage.photo} className="w-[200px] h-[200px] mt-4" />}

        </div>

      </div>

      <div className="flex justify-end gap-4">

        <button className="buttons bg-green-2 text-white rounded-[4px]" onClick={handleSubmit}>Save</button>
      </div>
    </div>
  )
}

export default ClosureForm