import React, { useState } from "react";
import Select from "react-select";
import { setBaseUrl } from "../../config";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useToast } from "../../Context/ToastContext";

const RemovedPart = ({ inventory, id }) => {
  const [removePart, setRemovePart] = useState({
    part_id: "",
    quantity: "",
    maintenance_id: id
  });

  const token = sessionStorage.getItem("sales_token");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const {addToast} = useToast()

  const filteredOptions = inventory.map((item) => ({
    value: item.id,
    label: `${item.part_no}, ${item.part_name}`
  }));

  const handlePartSelect = (selectedOption) => {
    setRemovePart({ ...removePart, part_id: Number(selectedOption.value) || "" });
  };

  const removePartFun = async () => {
  console.log(removePart)
    try {
      setLoading(true);
      const res = await fetch(`${setBaseUrl}/sale-service/remove-part`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify([removePart]),
      });
      setLoading(false);

      if (res.status === 200) {
      
        addToast("Part successfully removed from machine", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        addToast("Error in removing parts", "error");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      addToast("Error in removing part! Please check again", "error");
    }
  };

  // const removePartFun = async () => {
  //   const submitData = [removePart];
  //   console.log(submitData);
  //   try {
  //     setLoading(true);
  //     const res = await fetch(`${setBaseUrl}/sale-service/send-back`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "auth-token": token,
  //       },
  //       body: JSON.stringify(submitData),
  //     });
  //     setLoading(false);
  //     if (res.status === 200) {
  //       addToast("Part successfully removed", "success");
  //       // setTimeout(() => {
  //       //   window.location.reload();
  //       // }, 2000);
  //     } else {
  //       addToast("Error in removing parts", "error");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //     addToast("Error in removing part! Please check again", "error");
  //   }
  // };

  return (
    <div className="flex flex-col gap-4 bg-white w-full">
      <h3 className="text-lg font-semibold text-gray-800">Removed Part</h3>

      {/* Select Part Number */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700">Select Part Number</label>
        <Select
          title="Select Part"
          value={filteredOptions.find((option) => option.value === removePart.id)}
          options={filteredOptions}
          onInputChange={(inputValue) => setSearchTerm(inputValue)}
          onChange={handlePartSelect}
          placeholder="Please Type 3 Characters to Search"
          noOptionsMessage={() => "No parts found"}
          isClearable
          styles={{
            container: (provided) => ({
              ...provided,
              width: "350px",
              fontSize: "10px",
            }),
            control: (provided, state) => ({
              ...provided,
              width: "350px",
              border: state.isFocused ? "none" : "1px solid transparent",
              boxShadow: state.isFocused
                ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                : null,
              borderRadius: 0,
              backgroundColor: "#EEE",
            }),
          }}
        />
      </div>

      {/* Quantity Input */}
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700">Quantity</label>
        <input
          type="number"
          min={0}
          maxLength={25}
          onChange={(e) =>
            setRemovePart({ ...removePart, quantity: e.target.value })
          }
          placeholder="Enter Quantity"
          className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-green-2 focus:border-green-2 outline-none"
        />
      </div>


    

      {/* Submit Button */}
      <button
        onClick={removePartFun}
        className="border border-red-1 text-red-1 hover:bg-red-1 hover:text-white text-sm font-semibold py-2 px-4 rounded-md transition duration-200"
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={18} sx={{ color: "#fff" }} />
        ) : (
          "Remove Part"
        )}
      </button>
    </div>
  );
};

export default RemovedPart;
