import React, { useEffect, useState, useMemo, useRef, useContext } from "react";

import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import Layout from "../../components/Layout";
import { setBaseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import { PermissionContext } from "../../Context/PermissionsContext";
import { NavbarContext } from "../../Context/NavbarContext";
import { formatDate } from "../../customFunctions/FormatDate";
import { FaFileExcel } from 'react-icons/fa';
const Logistics = () => {
  const [logisticsData, setLogisticsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const [perms, setPerms] = useContext(PermissionContext);

  const [navState, setNavState] = useContext(NavbarContext);

  useEffect(() => {
    if (sessionStorage.getItem("asset_tracker_logged_in") !== "true") {
      navigate("/login");
    }
    // assets();
    setNavState(4);
  }, []);

  useEffect(() => {
    let getPermissionsFromSession = JSON.parse(
      sessionStorage.getItem("permissions")
    );
    setPerms(getPermissionsFromSession);
  }, []);

  const hasLogistics = useRef(false);

  const getLogistics = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-logistic`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      const data = await res.json();
      setLogisticsData(data);
      setFilteredData(data); // Initialize filteredData with full dataset
    } catch (error) {
      console.error("Error fetching logistics data:", error);
      alert("Something went wrong, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasLogistics.current) {
      hasLogistics.current = true;
      getLogistics();
    }
  }, []);



  useEffect(() => {
    setFilteredData(logisticsData);
  }, [logisticsData]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // console.log(filteredData)

  const rows = logisticsData?.map((logisticItem, index) => ({
    id: `${logisticItem.id}-${index}`,
    route: logisticItem.id,
    // rsoNo: logisticItem?.LogisticMap ? logisticItem?.LogisticMap[0]?.odoo_order_id : "N/A",
    // asset: logisticItem?.LogisticMap ? logisticItem?.LogisticMap[0]?.asset_no : "N/A",
    rsoNo: logisticItem?.LogisticMap?.[0]?.odoo_order_id || "N/A",
    asset: logisticItem?.LogisticMap?.[0]?.asset_no || "N/A",

    dispatchDate: logisticItem.date_of_dispatch ? formatDate(logisticItem.date_of_dispatch) : "N/A",
    mobDemob: logisticItem.mob_demob_state || "N/A",
    counter: index + 1,
  })) || []

  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Apply pagination here

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
    )
  );


  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <span title="Row Number" arrow placement="top">
          <span>#</span>
        </span>
      ),
      // renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },

    {
      field: "rsoNo",
      headerName: "RSO No",
      width: 300,
      renderHeader: () => (
        <span title="RSO Number" arrow placement="top">
          <span>RSO No</span>
        </span>
      ),
    },
    {
      field: "asset",
      headerName: "Asset",
      width: 300,
      renderHeader: () => (
        <span title="Asset Number" arrow placement="top">
          <span>Asset</span>
        </span>
      ),
    },
    {
      field: "dispatchDate",
      headerName: "Dispatch Date",
      width: 300,
      renderHeader: () => (
        <span title="Date of Dispatch" arrow placement="top">
          <span>Dispatch Date</span>
        </span>
      ),
      // renderCell: (params) => <>{params.row.dispatchDate ? formatDate(params.row.dispatchDate) : "N/A"}</>, // Use the dispatchDate field
    },
    {
      field: "mobDemob",
      headerName: "Mob/DeMob",
      width: 300,
      renderHeader: () => (
        <span title="Mobility or Demobilization State" arrow placement="top">
          <span>Mob or Demob</span>
        </span>
      ),
    },
  ];

  const handleRowClick = (params) => {
    navigate(`/logistics-details/${params.row.route}`);
    // console.log(params);
  };

  const handleExport = () => {
    try {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Flatten the data for export
      const exportData = filteredData.flatMap((item) =>
        (item.LogisticMap || []).map((logisticItem) => ({
          "RSO No": logisticItem.odoo_order_id || "N/A",
          "Asset No": logisticItem.asset_no || "N/A",
          "Dispatch Date": item.date_of_dispatch || "N/A",
          "Mob/Demob": item.mob_demob_state || "N/A",
          "Arrival Date": item.arrival_date || "N/A",
          "AWB Number": item.awb_number || "N/A",
          "Courier Company": item.courier_company || "N/A",
          "Destination": item.destination || "N/A",
          "Origin": item.origin || "N/A",
          "Total KM": item.total_km ? Number(item.total_km) : 0, // Convert to number
          "Transportation Cost": item.transportation_cost ? Number(item.transportation_cost) : 0, // Convert to number
        }))
      );

      // Convert data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Logistics");

      // Generate and download Excel file
      XLSX.writeFile(workbook, "logistics_data.xlsx");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      alert("Failed to export data. Please try again.");
    }
  };


  return (
    <Layout>
      <div className="">


        <Paper className="p-4">
          <h1 className="text-dark-6 text-xs font-bold">Logistics Table</h1>
          <div className=" flex items-center justify-between">
            <FormControl variant="outlined" fullWidth>
              <input
                title="search logistics"
                variant="outlined"
                placeholder="Search"
                value={search}
                onChange={handleSearchChange}
                size="small"
                className="border-light-5 border bg-gray-50 h-[30px] w-[300px] p-[5px] rounded-[4px] text-[12px] items-center"
              />
            </FormControl>
            <div className="flex gap-3 items-center  w-full px-2">

              <TablePagination
                count={logisticsData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 30, 50, filteredData.length]}
                labelRowsPerPage="Rows:"
                sx={{
                  width: '100%',
                }}
              />

              <span title="Add New Logistics" arrow placement="top">
                <button
                  variant="contained"
                  color="primary"
                  className="bg-green-2 hover:bg-green-3 text-white flex-1 py-2 px-4 rounded-lg font-medium  transition-colors border min-w-max"
                  onClick={() => navigate("/new-logistics")}
                >
                  Create New
                </button>
              </span>
              <span title="Export to Excel" arrow placement="top">

                <button
                  title="Export Data in Excel format"
                  onClick={handleExport}
                  className="border-green-2 text-green-2 flex gap-1 py-2 px-4 rounded-lg font-medium  transition-colors border min-w-max"

                >
                  <FaFileExcel />   Export Excel
                </button>
              </span>
            </div>
          </div>

          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              loading={loading}
              pageSize={rowsPerPage}
              paginationMode="server"
              pagination={false}

              onRowClick={handleRowClick} // Row click handler
              components={{
                NoRowsOverlay: () => (
                  <div style={{ padding: "1rem", textAlign: "center" }}>
                    {search
                      ? "No results match your search."
                      : "No data available."}
                  </div>
                ),
              }}
              sx={{
                "& .MuiDataGrid-footerContainer": {
                  display: "none", // Hides the footer container
                },
                "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
                "& .MuiDataGrid-columnHeaders": {
                  fontSize: "12px",
                  backgroundColor: "#000",
                  fontWeight: "bold",
                  color: "#006DB6",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                  height: 20,
                },
                "& .MuiDataGrid-cell": { fontSize: "12px" },
                "& .MuiDataGrid-virtualScroller": {
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none",
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              }}
            />
          </div>

        </Paper>
      </div>
    </Layout>

  );
};

export default Logistics;
