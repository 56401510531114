import React, { useEffect, useState } from "react";
import verified from "../../../assets/images/verified.svg";
import unverified from "../../../assets/images/unverified-16.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setBaseUrl } from "../../../config";
import { Pagination } from "../Pagination";
import Loader from "../../Loader";
import { IoAddOutline } from "react-icons/io5";
function SettingsTable() {
  const navigate = useNavigate();
  const [tableAnimation, setTableAnimation] = useState(false);
  const [allEmployees, setAllEmployees] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [items, setItems] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [loader, setLoader] = useState(false);

  const getAllEmployees = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/company/all_employee`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      console.log(data);
      setItems(data.length)
      setAllEmployees(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const updateEmployee = async (email) => {
    navigate(`/settings/update-employee/${email}`);
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setTableAnimation(true);
    }, 400);
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
      <>
      <div className="h-full w-full p-2 ">
  <div className="flex justify-between items-center mb-6">
    <p className="text-xs font-semibold text-dark-6 ">
      Employees
    </p>
    <button
      onClick={() => navigate("addEmployee")}
      className="bg-blue-2 text-white p-1 px-2 rounded text-xs flex items-center justify-center"
    >
       <IoAddOutline className="mr-2 h-3 w-3" />
     New
    </button>
  </div>
  <div className="overflow-auto p-5 bg-white shadow-md rounded-[4px]">
    <table className="w-full  divide-y text-left text-xs">
      <thead className="text-dark-6 text-xs">
        <tr>
        <th className="w-[130px] h-10 text-center">
        <input type="checkbox" className="border " />
        </th>
          <th className="w-[130px] h-10 text-center">Employee</th>
          <th className="w-[130px] h-10 text-center">Email</th>
          <th className="w-[130px] h-10 text-center">Verified</th>
          <th className="w-[130px] h-10 text-center">Action</th>
        </tr>
      </thead>
      <tbody className="divide-y">
        {allEmployees.map((data, index) => (
          <tr
            key={index}
            className="text-xs text-center"
          >
            <td>
              <input type="checkbox" className="border " />
            </td>
            <td className="h-10">
              {data.name}
            </td>
            <td className="h-10">
              {data.email}
            </td>
            <td className="h-10">
              <img
                src={data.verified ? verified : unverified}
                alt={data.verified ? "Verified" : "Unverified"}
                className="w-6 h-6 mx-auto"
              />
            </td>
            <td className="h-10">
              <button
                onClick={() => updateEmployee(data.email)}
                className="text-center bg-white border py-0.5 px-2 text-[10px] rounded-[6px]"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="mt-10">

  <Pagination
    itemsPerPage={itemsPerPage}
    itemsLength={items}
    itemOffset={itemOffset}
    setItemOffset={setItemOffset}
    />
    </div>
</div>

      </>
      )}
    </>
  );
}

export default SettingsTable;