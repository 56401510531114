import React, { useState } from 'react'
import Layout from "./Layout";
import { useNavigate } from 'react-router-dom';
const ForgotPassword = () => {
    const [data, setData] = useState({
        email:'',
        password:''
    })

    const ForgotPasswordFun = () => {

    }

    const navigate = useNavigate()

  return (
    <Layout>
         <form
          className="text-black bg-white w-[389.42px]  rounded-[16px] border-[1.5px] border-light-"
          onSubmit={(e) => {
            e.preventDefault();
            ForgotPasswordFun()
          }}
        >
          {/* Form Header */}
          <div className="flex flex-col  text-left items-start border-b-[1.5px] p-6 border-light">

            <header className="text-[32px] font-bold">Forgot Password</header>
            <span className="font-normal text-[12px]">Enter your email below</span>
          </div>

          {/* Input Form */}
          <div className="flex flex-col p-10">

            {/* Email Input */}
            <div className="my-4 ">
              <label htmlFor="" className="text-[13.09px] font-extrabold">Email</label>
              <input
              title="Enter email address"
                type="email"
                id="email"
                placeholder="example@company.com"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                className={`w-[314.81px] h-[40px] px-4 py-2 text-sm border rounded-lg focus:outline-none 
                border-red-1 `}
              />
            
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className={`w-full py-3 rounded-lg mb-3 font-normal text-white text-sm transition-all duration-300 
              bg-green-2 hover:bg-green-3
                
                `}
              
            >
              Reset Password
            </button>
            <button
              type="button"
              className={`w-full py-3 rounded-lg mb-3 font-normal  text-sm transition-all duration-300 
            border
                
                `}

                onClick={() => navigate('/login')}
              
            >
              Back to Login
            </button>
           
          </div>
        </form>
    </Layout>
  )
}

export default ForgotPassword