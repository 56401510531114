import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";

const UpdateLogistics = () => {
  const { id } = useParams();
  const [logisticsData, setLogisticsData] = useState(null);
  const [formData, setFormData] = useState({
    amount_paid_by_customer: "",
    arrival_date: "",
    assets_no: [],
    awb_number: "",
    bill_amount: "",
    courier_company: "",
    date_of_dispatch: "",
    dc_date: "",
    dc_no: "",
    demob_as_per_invoice: "",
    destination: "",
    lease_RSO_nos: [],
    mob_as_per_invoice: "",
    mob_demob_state: "",
    origin: "",
    rate_per_km: "",
    total_km: "",
    transportation_cost: "",
    transporter_bill_date: "",
    transporter_bill_no: "",
    truck_ft: "",
    truck_no: "",
  });

  const getLogisticsData = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-logistic-id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      const data = await res.json();
      setLogisticsData(data);
      setFormData(data); // Initialize formData with fetched data
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateLogisticsData = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/update-logistic/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(formData),
      });

      if (res.ok) {
        alert("Logistics data updated successfully!");
      } else {
        alert("Failed to update logistics data.");
      }
    } catch (error) {
      console.error("Error updating logistics data:", error);
    }
  };

  useEffect(() => {
    getLogisticsData();
  }, []);

  return (
    // <Layout>

    // <div>
    //   <h1>Update Logistics Data</h1>
    //   {logisticsData && (
    //     <form
    //       onSubmit={(e) => {
    //         e.preventDefault();
    //         updateLogisticsData();
    //       }}
    //     >
    //       {/* RSO No. Dropdown */}
    //       <label>
    //         RSO No.:
    //         <select
    //           name="lease_RSO_nos"
    //           value={formData.lease_RSO_nos}
    //           onChange={(e) =>
    //             handleSelectChange("lease_RSO_nos", [e.target.value])
    //           }
    //         >
    //           {logisticsData.lease_RSO_nos?.map((rso) => (
    //             <option key={rso} value={rso}>
    //               {rso}
    //             </option>
    //           ))}
    //         </select>
    //       </label>

    //       {/* Asset No. Dropdown */}
    //       <label>
    //         Asset No.:
    //         <select
    //           name="assets_no"
    //           value={formData.assets_no}
    //           onChange={(e) =>
    //             handleSelectChange("assets_no", [e.target.value])
    //           }
    //         >
    //           {logisticsData.assets_no?.map((asset) => (
    //             <option key={asset} value={asset}>
    //               {asset}
    //             </option>
    //           ))}
    //         </select>
    //       </label>

    //       {/* Date Inputs */}
    //       {["date_of_dispatch", "transporter_bill_date", "dc_date", "arrival_date"].map(
    //         (key) => (
    //           <label key={key}>
    //             {key.replace(/_/g, " ")}:
    //             <input
    //               type="date"
    //               name={key}
    //               value={formData[key] || ""}
    //               onChange={handleInputChange}
    //             />
    //           </label>
    //         )
    //       )}

    //       {/* Other Input Fields */}
    //       {Object.keys(formData).map(
    //         (key) =>
    //           ![
    //             "lease_RSO_nos",
    //             "assets_no",
    //             "date_of_dispatch",
    //             "transporter_bill_date",
    //             "dc_date",
    //             "arrival_date",
    //           ].includes(key) && (
    //             <label key={key}>
    //               {key.replace(/_/g, " ")}:
    //               <input
    //                 type="text"
    //                 name={key}
    //                 value={formData[key] || ""}
    //                 onChange={handleInputChange}
    //               />
    //             </label>
    //           )
    //       )}

    //       <button type="submit">Update Logistics</button>
    //     </form>
    //   )}
    // </div>
    // </Layout>
    <>
    <Layout>
    Update Page
    </Layout>
    </>
  );
};

export default UpdateLogistics;
