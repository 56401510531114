import React, { Fragment, useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { TableLoader } from "../../TableLoader";
import ReactPaginate from "react-paginate";
import { CurrentItems } from "./CurrentItems";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import HistoryBtn from "../../Buttons/HistoryBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import MISReportBtn from "../../Buttons/MISReportBtn";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CiFilter } from "react-icons/ci";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { IoMdAdd } from "react-icons/io";
import Loader from "../../Loader";
import { IoIosClose } from "react-icons/io";
import { useSearchAssets } from "../../../hooks/useSearchAssets";
import AssetSearch from "../../Search/AssetSearch";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { CiViewTable } from "react-icons/ci";
import { BiSortAlt2 } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSearchParams } from "react-router-dom";

// Pagination
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';

function AssetTable({ showCurrentTab, setShowCurrentTab }) {

  const {
    setFilter,
    searchValue,
    handleSearch,
    handleSearchClick,
    showSearchDiv,
    loader,
    setLoader,
    deleted,
    setDeleted,
    assetDetails,
    getAllAssets,

    showAllData,
    getSearchList,
    itemOffset,
    setItemOffset,
  } = useSearchAssets(setShowCurrentTab);

  // "all Assets",
  // "active",
  // "inactive",
  // "never assigned",
  // "expiring",
  // "expired",
  let selected = [
    {
      title: "All Assets",
      label: 'all Assets'
    },
    {
      title: "Active",
      label: ["active", "expiring"]
    },
    {
      title: 'Free',
      label: ['inactive',  "never assigned",]
    },   
    {
      title: "expired",
      label: ["expired"],
    }
  ];


  const [selectedItem, setSelectedItem] = useState('all Assets');
  const [openFilter, setOpenFilter] = useState(false);
  const [openTable, setopenTable] = useState(false);
  const [openFilterStatus, setFilterStatus] = useState(false);
  const [openYom, setOpenYom] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSold, setOpenSold] = useState(null);
  const uniqueYears = [...new Set(assetDetails.map((details) => details.yom))];
  const uniqueCategory = [
    ...new Set(assetDetails.map((details) => details.category)),
  ];
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  let tableArray = [10, 50, 100, 150];
  const [selectTableData, setSelectTableData] = useState(null);
  // const [ascending, setAscending] = useState(null);
  // const [descending, setDescending] = useState(null);
  const [device, setDevice] = useState("all device");
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  // const a = useParams()
  let [searchParams, setSearchParams] = useSearchParams();

  if(searchParams.get("asset") === 'free') {
    setTimeout(() => {
      setSelectedItem(["Free", ['inactive',  "never assigned"]])
      
    }, 500);
  } else if(searchParams.get("asset") === 'sold') {
    setTimeout(() => {
      setOpenSold(true)
      
    }, 500);
  }
  else {}


  // header of the table
  const sortHeader = [
    "Asset No",
    "Model",
    "Make",
    "YOM",
    "Category",
    "Lease Status",
  ];

  const [openSort, setOpenSort] = useState(false);
  // sort names are storing
  const [sort, setSort] = useState(null);
  // for input in sorting
  const [inputSearch, setInputSearch] = useState("");
  // sorting the data in ascending order using the whole filter
  const [sortOrder, setSortOrder] = useState("asc");
  // Filtered header based on search input
  const filteredHeaders = sortHeader.filter((header) =>
    header.toLowerCase().includes(inputSearch.toLowerCase())
  );
  // Functions to implement Search in the filter
  const [inputFilter, setInputFilter] = useState("");
  const buttons = [
    {
      label: "Status",
      onClick: () => {
        setFilterStatus(!openFilterStatus);
        setOpenCategory(false);
        setOpenYom(false);
      },
    },
    {
      label: "YOM",
      onClick: () => {
        setOpenYom(!openYom);
        setOpenCategory(false);
        setFilterStatus(false);
      },
    },
    {
      label: "Category",
      onClick: () => {
        setOpenCategory(!openCategory);
        setOpenYom(false);
        setFilterStatus(false);
      },
    },
    {
      label: "Tracker Installed",
      onClick: () => {
        setDevice("tracker installed");
        setOpenCategory(false);
        setOpenYom(false);
        setFilterStatus(false);
        setOpenFilter(false);
      },
    },
    {
      label: "Sold Machines",
      onClick: () => {
        setOpenSold(true);
        setOpenFilter(false);
      },
    },
    // {
    //   label: "filter",
    //   onClick: null, // Disabled button, no action
    //   disabled: true,
    //   icon: <IoMdAdd />,
    // },
  ];

  // Filter the buttons based on the input value
  const filteredButtons = buttons.filter((button) =>
    button.label.toLowerCase().includes(inputFilter.toLowerCase())
  );

  // to stack filterations
  const [filteredDataStack, setFilteredDataStack] = useState([]);

  const [sortedAssetDetails, setSortedAssetDetails] = useState(assetDetails);


  const activeCount = assetDetails.filter(item => 
    item?.lease_status?.startsWith('expiring') || item?.lease_status === 'active'
  ).length;
  
  const FreeAssetCount = assetDetails.filter(item => item?.lease_status === 'never assigned' || item?.lease_status === 'inactive').length;
  const inactiveCount = assetDetails.filter(item => item?.lease_status === 'inactive').length;
  const expiredCount  = assetDetails.filter(item => item?.lease_status === 'expired').length;
  const soldAssets = assetDetails.filter(item => item?.is_sold).length
  
  
  const trackerInstalledAssets = assetDetails.filter(item => item?.device_no).length

// console.log(selectedItem)

const handleClearStatusFilter = ()  => {
  if(searchParams.get("asset") === 'free') {
    setSearchParams("")
  } 
  setSelectedItem("all Assets");
}

const handleCloseSold = () => {
  if(searchParams.get("asset") === 'sold') {
    setSearchParams("")
  }
  setOpenSold(null);  setFilteredDataStack([])
}

  return (
    <>
      <div className="px-2 flex  flex-col md:flex-row border-b  pb-2 justify-end items-center md:space-y-0">
       {/* Header */}
       

        <div className="flex flex-row gap-2 justify-between items-center ">
          {/* Table */}
          <button
            className="bg-light-1  filter-items rounded-md p-1.5 text-xs borde text-[#ffffff] font-extrabold justify-center  items-center flex gap-1 cursor-pointer"
            onClick={() => setopenTable(true)}
          >
            <CiViewTable className="w-4 h-4" />
          </button>
          <Tooltip
            anchorSelect=".table-items"
            place="right"
            className="text-xs font-normal"
          >
            Table
          </Tooltip>

          {/* filter */}
          <div
            className={`bg-light-1  filter-items rounded-md p-1.5 text-xs borde text-[#ffffff] font-extrabold justify-center  items-center flex gap-1 cursor-pointer
            
            `}
            onClick={() => setOpenFilter(true)}
          >
            <CiFilter className="w-4 h-4" />
            <Tooltip
              anchorSelect=".filter-items"
              place="left"
              className="text-xs font-normal"
            >
              Filter
            </Tooltip>
          </div>
         
          {/* Sorting Suggestions */}

          <button
            className="p-1.5 rounded-md bg-[#006DB6] borde text-xs  sort-items"
            onClick={() => setOpenSort(!openSort)}
          >
            <BiSortAlt2 className="h-4 w-4 rounded  text-[#ffffff]" />
            <Tooltip
              anchorSelect=".sort-items"
              place="left"
              className="text-xs font-normal"
            >
              Sort
            </Tooltip>
          </button>
          {openSort && (
            <>
              <div className="absolute text-xs w-[200px] h-fit z-30 top-12 right-[10%] rounded-lg p-2 border bg-white">
                <input
                  type="text"
                  className="bg-transparent w-[180px] h-7 rounded-md text-xs"
                  placeholder="Search..."
                  onChange={(e) => setInputSearch(e.target.value)}
                  value={inputSearch}
                />
                <ul className="mt-2">
                  {filteredHeaders.length > 0 ? (
                    filteredHeaders.map((header) => (
                      <li
                        className="px-2 py-2 hover:bg-[#EEE] rounded-md cursor-pointer"
                        key={header}
                        onClick={() => {
                          setSort(header);
                          setOpenSort(false);
                        }}
                      >
                        {header}
                      </li>
                    ))
                  ) : (
                    <li className="px-2 py-2 text-gray-500">
                      No results found
                    </li>
                  )}
                </ul>
              </div>
              <div
                className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
                  !openSort && "hidden"
                } `}
                onClick={() => {
                  setOpenSort(false);
                }}
              />
            </>
          )}

          <div className="items-center z-[5000] ">
            {/* <div> */}

            {/* Search Tab */}

            <AssetSearch
              setFilter={setFilter}
              searchValue={searchValue}
              handleSearch={handleSearch}
              handleSearchClick={handleSearchClick}
              open={open}
              setOpen={setOpen}
              setOpenSearch={setOpenSearch}
            />

            {openSearch && showSearchDiv.length > 0 && (
              <div
                className="absolute bg-white
               dark:bg-gray-800 p-2
               border w-[200px] h-[50vh] 
               dark:border-gray-600 
               rounded-2xl hide-scrollbar z-10  
               overflow-y-scroll right-3 mt-5
               shadow transition-all duration-300 ease-in-out text-xs"
              >
                {/* Aconsole.log(showSearchDiv) */}
                {/* <button className="absolute right-0 text-xs font-bold mx-2  text-end">Close</button> */}
                {showSearchDiv.map((item) => (
                  <p
                    onClick={() => handleSearchClick(item)}
                    className="cursor-pointer hover:bg-gray-200 dark:hover:bg-[#EEE] border-b rounded-md p-2 transition duration-300 ease-in-out"
                  >
                    {item}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div
            className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
              !open && "hidden"
            } `}
            onClick={() => {
              setOpen(false);
              setOpenSearch(false);
            }}
          />
          <NewAssetBtn tabName="asset" />
        </div>

      </div>
      <div className="flex space-x-2">
      <div className="text-xs font-extrabold mt-1">
         <span className="font-normal ">Total Assets: </span> {"("} 1 - {selectTableData && selectTableData <= filteredDataStack.length ? selectTableData : filteredDataStack.length} / {assetDetails.length} {")"}
      </div>
      <div className="text-xs font-extrabold mt-1">
        <span className="font-normal ">Asset With Active Lease: </span>{" "}
       
          {activeCount}
      </div>
      

      <div className="text-xs font-extrabold mt-1">
        <span className="font-normal ">Asset With Expired Lease: </span>{" "}
        {expiredCount}
      </div>
      <div className="text-xs font-extrabold mt-1">
        <span className="font-normal ">Free Assets: </span>{" "}
        {FreeAssetCount}
      </div>
      <div className="text-xs font-extrabold mt-1">
        <span className="font-normal ">Sold Assets: </span>{" "}
        {soldAssets}
      </div>
      <div className="text-xs font-extrabold mt-1">
        <span className="font-normal ">Tracker Installed Assets: </span>{" "}
        {trackerInstalledAssets}
      </div>


      <div className="flex gap-4 text-xs justify-between z-20">
          {selectTableData && (
            <>
              <button className="bg-[#EEE] p-1.5 flex items-center rounded cursor-pointer">
                {selectTableData}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectTableData(null)}
                />
              </button>
            </>
          )}

          {selectedItem !== "all Assets" && (
            <>
              <button className="bg-[#EBEBEB] p-1.5 flex items-center rounded">
                {selectedItem[0]}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={handleClearStatusFilter}
                />
              </button>
            </>
          )}

          {selectedYear && (
            <>
              <button className="bg-[#EBEBEB] p-1.5 flex items-center rounded">
                {selectedYear}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => {setSelectedYear(null); setFilteredDataStack([])}}
                />
              </button>
            </>
          )}

          {selectedCategory && (
            <>
              <button className="bg-[#EBEBEB] p-1.5 flex items-center rounded">
                {selectedCategory}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => {setSelectedCategory(null); setFilteredDataStack([])}}
                />
              </button>
            </>
          )}

          {openSold && (
            <>
              <button className="bg-[#EBEBEB] p-1.5 flex items-center rounded">
                Sold
                <IoIosClose
                  className="h-4 w-4"
                  onClick={handleCloseSold}
                />
              </button>
            </>
          )}

          {device !== "all device" && (
            <>
              <button className="bg-[#EBEBEB] p-1.5 flex items-center rounded">
                {device}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setDevice("all device")}
                />
              </button>
            </>
          )}

          {/* Sorted Data */}
          {sort && (
            <>
              <button className="text-xs bg-[#EEE] rounded-md px-1 py-1 flex items-center justify-center">
                {sort}
                <IoIosClose
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => setSort(null)}
                />
              </button>
            </>
          )}

          {openTable && (
            <>
              <div
                className="absolute mt-10 z-[9999] right-[21%] p-1.5 text-xs
                flex flex-col justify-center 
               bg-white dark:bg-[#070e18] w-[80px] shadow-2xl rounded-lg h-fit items-start"
              >
                {tableArray.map((table) => (
                  <>
                    {" "}
                    <button
                      key={table}
                      className="hover:bg-[#EEE] w-full  py-1  dark:hover:text-black "
                      onClick={() => setSelectTableData(table)}
                    >
                      {table}
                    </button>{" "}
                  </>
                ))}
              </div>
              <div
                className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                onClick={() => setopenTable(false)}
              />
            </>
          )}
          {/* filter */}
          {openFilter && (
            <>
              <div
                className="absolute mt-8 z-[9999] right-[17%] flex flex-col justify-center pt-2 pb-2 shadow-2xl
                  bg-white dark:bg-[#070e18] w-[150px] rounded-lg h-fit items-center"
              >
                <input
                  type="text"
                  className="bg-transparent w-[130px] h-7 rounded-md text-xs"
                  placeholder="Search..."
                  value={inputFilter}
                  onChange={(e) => setInputFilter(e.target.value)}
                />

                {filteredButtons.map((button, index) => (
                  <button
                    key={index}
                    className={`hover:bg-[#EEE] mt-2  w-[130px] rounded-md py-2 dark:hover:text-black=`}
                    onClick={button.disabled ? null : button.onClick}
                  >
                    {button.icon && <span className="mr-2">{button.icon}</span>}
                    {button.label}
                  </button>
                ))}

                <button className="flex items-center justify-center rounded-md py-2 cursor-not-allowed hover:bg-[#EEE] w-[130px] ">
                  <IoMdAdd />
                  filter
                </button>
              </div>

              {openFilterStatus && (
                <>
                  <div
                    onMouseLeave={() => setFilterStatus(false)}
                    className="absolute mt-9 z-[9999] right-[24.5%]
                    flex flex-col justify-center  pt-6 shadow-2xl
                  bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   dark:shadow-[#767676] w-[150px] border rounded-lg h-fit items-center"
                  >
                    {selected.map((select) => {
                      return (
                        <>
                          <button
                            className={`hover:bg-[#EEE] dark:hover:text-black w-[125px] rounded-md px-2  py-2 ${
                              selectedItem === select
                                ? "bg-[#EEE] dark:text-black"
                                : ""
                            }  `}
                            onClick={() => {
                              setSelectedItem([select.title, select.label]);
                              setFilterStatus(false);
                              setOpenFilter(false);
                            }}
                            key={select}
                          >
                            {capitalizeFirstLetter(select.title)}
                          </button>
                        </>
                      );
                    })}

                    <button className="flex items-center justify-start py-2">
                      <IoMdAdd />
                      Status
                    </button>
                  </div>
                </>
              )}

              {openYom && (
                <>
                  <>
                    <div
                      onMouseLeave={() => setOpenYom(false)}
                      className="absolute z-[9999] right-[29%]
                    flex flex-col justify-center hide-scrollbar border border-black h-[300px] overflow-x-auto shadow-2xl  overflow-y-auto
                  bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   
                  dark:shadow-[#767676] w-[150px] pt-[20rem]  rounded-lg  items-center  "
                    >
                      <input type="text" disabled className="bg-transparent w-[130px] h-7 absolute top-2 rounded-md text-xs" placeholder="Search..."/>
                      {uniqueYears.sort().map((year) => (
                        <>
                        
                        <button
                          key={year}
                          className="hover:bg-[#EEE] rounded-md  dark:hover:text-black w-[130px] py-2"
                          onClick={() => setSelectedYear(year)}
                          >
                          {year}
                        </button>
                          </>
                      ))}
                    </div>
                  </>
                </>
              )}

              {openCategory && (
                <>
                  <>
                    <div
                      onMouseLeave={() => setOpenCategory(false)}
                      className="absolute mt-9 z-[9999] right-[25.5%]
                      flex flex-col justify-center  pt-6 shadow-2xl
                      bg-white dark:bg-[#070e18] dark:border-none dark:shadow-md   
                      dark:shadow-[#767676] w-[150px] border rounded-lg h-fit pb-6 items-center"
                    >
                      {uniqueCategory.map((category) => (
                        <button
                          key={category}
                          className="hover:bg-[#EBEBEB] dark:hover:text-black w-full py-2"
                          onClick={() => setSelectedCategory(category)}
                        >
                          {category}
                        </button>
                      ))}
                    </div>
                  </>
                </>
              )}

              <div
                className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                onClick={() => setOpenFilter(false)}
              />
            </>
          )}

          {/* <div className="">filtered data</div> */}
        </div>

      </div>

      {loader ? (
        <Loader />
      ) : (
        <CurrentItems
          deleted={deleted}
          setDeleted={setDeleted}
          loader={loader}
          setLoader={setLoader}
          assetDetails={assetDetails}
          selected={selected}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          selectTableData={selectTableData}
          selectedCategory={selectedCategory}
          selectedYear={selectedYear}
          device={device}
          openSold={openSold}
          sortHeader={sortHeader} 
          sort={sort}
          setSort={setSort}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          filteredDataStack={filteredDataStack}
          setFilteredDataStack={setFilteredDataStack}
          sortedAssetDetails={sortedAssetDetails}
          setSortedAssetDetails={setSortedAssetDetails}
        />
      )}

      {/* Bottom Button */}
      <div className="fixed z-[9999] h-12 w-screen right-0 left-0 items-end bottom-0 bg-white border-t flex justify-end">
        <HistoryBtn/>
        <ExcelReportBtn tabName='assets' />
      </div>
    </>
  );
}

export default AssetTable;
