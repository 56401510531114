// import React, { useState } from "react";
// import { TextField, Button, Paper } from "@mui/material";
// import { setBaseUrl } from "../../config";
// import { toast } from "react-toastify";

// const BookAssetForm = ({ assetNo, id }) => {
//   const [formData, setFormData] = useState({
//     customer_name: "",
//     estimated_rate_per_day: "",
//     need_from: "",
//     needed_up_to: "",
//     location: "",
//     transpot_paid_by_customer_by_percentage_or_amount: "",
//     duration_of_lease: ''
//   });

//   // Handle input changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;


//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault(); // ✅ Prevent page reload

//     // ✅ Basic validation
//     if (
//       !formData.customer_name ||
//       !formData.estimated_rate_per_day ||
//       !formData.need_from ||
//       !formData.needed_up_to
//     ) {
//       alert("Please fill all required fields!");
//       return;
//     }

//     const submittedData = { ...formData, asset_id: id };
//     // console.log("Submitted Data:", submittedData);


//     try {
//         const res = await fetch(`${setBaseUrl}/sale-service/book-asset`, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               "auth-token": sessionStorage.getItem("sales_token"),
//             },
//             body: JSON.stringify(submittedData),
//           });
//         if (!res.ok) {
//             throw new Error("Failed to book asset");
//         }

//         if(res.status === 200) {
//             toast.success("Booked Successfully!")
//             window.location.reload();
//         }
//     } catch (error) {
//         console.error(error);
//         toast.error("Failed to book asset");
//     }
//   };

// // const handleSubmit = async (e) => {
// //     e.preventDefault(); // Prevent page reload

// //     // ✅ Basic validation
// //     if (
// //       !formData.customer_name ||
// //       !formData.estimated_rate_per_day ||
// //       !formData.need_from ||
// //       !formData.needed_up_to
// //     ) {
// //       toast.error("Please fill all required fields!");
// //       return;
// //     }

// //     const token = sessionStorage.getItem("sales_token");

// //     if (!token) {
// //       toast.error("Session expired! Please login again.");
// //       return;
// //     }

// //     if (!id) {
// //       toast.error("Asset ID is missing!");
// //       return;
// //     }

// //     const submittedData = { ...formData, asset_id: id };

// //     try {
// //         const res = await fetch(`${setBaseUrl}/sale-service/book-asset`, {
// //             method: "POST",
// //             headers: {
// //               "Content-Type": "application/json",
// //               "auth-token": token,
// //             },
// //             body: JSON.stringify(submittedData),
// //         });

// //         const responseData = await res.json(); // ✅ Parse response
// //         console.log("API Response:", responseData);

// //         if (!res.ok) {
// //             throw new Error(responseData.msg || "Failed to book asset");
// //         }

// //         toast.success("Booked Successfully!");
// //     } catch (error) {
// //         console.error("Error:", error);
// //         toast.error(error.message || "Failed to book asset");
// //     }
// // };


//   return (
//     <Paper className="p-6 w-[400px] mx-auto">
//       <h2 className="text-lg font-bold mb-4">Book Asset: {assetNo}</h2>
//       <form onSubmit={handleSubmit} className="flex flex-col gap-3">
//         <TextField
//           label="Customer Name"
//           name="customer_name"
//           value={formData.customer_name}
//           onChange={handleChange}
//           required
//           fullWidth
//         />
//         <TextField
//           label="Estimated Rate Per Day (₹)"
//           name="estimated_rate_per_day"
//           value={formData.estimated_rate_per_day}
//           onChange={handleChange}
//           required
//           fullWidth
//         />
//         <TextField
//           label="Need From"
//           name="need_from"
//           type="date"
//           value={formData.need_from}
//           onChange={handleChange}
//           required
//           fullWidth
//           InputLabelProps={{ shrink: true }}
//         />
//         <TextField
//           label="Needed Up To"
//           name="needed_up_to"
//           type="date"
//           value={formData.needed_up_to}
//           onChange={handleChange}
//           required
//           fullWidth
//           InputLabelProps={{ shrink: true }}
//           inputProps={{
//             min: formData.need_from || "", // Set min date dynamically
//           }}
//         //   disabled={formData.need_from}
//         />
//         <TextField
//           label="Location"
//           name="location"
//           value={formData.location}
//           onChange={handleChange}
//           required
//           fullWidth
//         />
//         <TextField
//           label="Transport Paid (₹ or %)"
//           name="transpot_paid_by_customer_by_percentage_or_amount"
//           value={formData.transpot_paid_by_customer_by_percentage_or_amount}
//           onChange={handleChange}
//           required
//           fullWidth
//         />

//         {/* ✅ Submit Button */}
//         <div className="flex justify-between mt-4">
//           <Button sx={{
//             fontSize: "12px"
//         }} type="submit" variant="contained" color="primary">
//             Submit
//           </Button>
//         </div>
//       </form>
//     </Paper>
//   );
// };

// export default BookAssetForm;


import React, { useState } from "react";
import { TextField, Button, Paper } from "@mui/material";
import { setBaseUrl } from "../../config";
import { toast } from "react-toastify";
import moment from "moment"; // Ensure you have moment.js installed (`npm install moment`)

const BookAssetForm = ({ assetNo, id }) => {
  const [formData, setFormData] = useState({
    customer_name: "",
    estimated_rate_per_day: "",
    need_from: "",
    needed_up_to: "",
    location: "",
    transpot_paid_by_customer_by_percentage_or_amount: "",
    duration_of_lease: "", // ✅ Added duration field
  });

  // ✅ Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedForm = { ...formData, [name]: value };

    // ✅ Auto-calculate duration when dates are selected
    if (name === "need_from" || name === "needed_up_to") {
      if (updatedForm.need_from && updatedForm.needed_up_to) {
        const start = moment(updatedForm.need_from);
        const end = moment(updatedForm.needed_up_to);
        const duration = end.diff(start, "days") + 1; // Include the start date
        updatedForm.duration_of_lease = duration > 0 ? duration : 0;
      }
    }

    setFormData(updatedForm);
  };

  // ✅ Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.customer_name || !formData.estimated_rate_per_day || !formData.need_from || !formData.needed_up_to) {
      alert("Please fill all required fields!");
      return;
    }

    const submittedData = { ...formData, asset_id: id };

    try {
      const res = await fetch(`${setBaseUrl}/sale-service/book-asset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": sessionStorage.getItem("sales_token"),
        },
        body: JSON.stringify(submittedData),
      });

      if (!res.ok) throw new Error("Failed to book asset");

      toast.success("Booked Successfully!");
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Failed to book asset");
    }
  };

  return (
    <Paper className="p-6 w-[400px] mx-auto">
      <h2 className="text-lg font-bold mb-4">Book Asset: {assetNo}</h2>
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <TextField label="Customer Name" name="customer_name" value={formData.customer_name} onChange={handleChange} required fullWidth />
        <TextField label="Estimated Rate Per Day (₹)" name="estimated_rate_per_day" value={formData.estimated_rate_per_day} onChange={handleChange} required fullWidth />

        {/* Date Fields */}
        <TextField label="Need From" name="need_from" type="date" value={formData.need_from} onChange={handleChange} required fullWidth InputLabelProps={{ shrink: true }} />
        <TextField
          label="Needed Up To"
          name="needed_up_to"
          type="date"
          value={formData.needed_up_to}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: formData.need_from || "" }} // Prevents selecting a past date
        />

        {/* Duration Input Field */}
        <TextField
          label="Duration of Lease (Days)"
          name="duration_of_lease"
          type="number"
          value={formData.duration_of_lease}
          onChange={handleChange}
          fullWidth
          inputProps={{ min: 1 }}
        />

        <TextField label="Location" name="location" value={formData.location} onChange={handleChange} required fullWidth />
        <TextField label="Transport Paid (₹ or %)" name="transpot_paid_by_customer_by_percentage_or_amount" value={formData.transpot_paid_by_customer_by_percentage_or_amount} onChange={handleChange} required fullWidth />

        <div className="flex justify-between mt-4">
          <Button sx={{ fontSize: "12px" }} type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default BookAssetForm;
