import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaWifi, FaTag } from "react-icons/fa";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { FaFileExcel } from "react-icons/fa";

import { IoIosClose } from "react-icons/io";
import { capitalizeFirstLetter } from "../../../customFunctions/FormatDate";

const NewTable = ({
  handleExport,
  assetDetails,
  setFilteredData,
  filteredData,
  selectedRows,
  setSelectedRows,
}) => {
  const [filter, setFilter] = useState("All Assets"); // Default filter
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [filterStatus, setFilterStatus] = useState("select");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [selectedCategories, setSelectedCategories] = useState([]); // Category filter
  const [selectedYOM, setSelectedYOM] = useState([]); // YOM filter

  const [selectedBrand, setSelectedBrand] = useState([]);
  // const [selectedType, setSelectedType] = useState([]);

  const [selectedModel, setSelectedModel] = useState([]);

  const categories = [
    "Telescopic Boom",
    "Truck Mounted",
    "Battery Scissor",
    "Articulating Boom",
    "Articulating Battery Boom",
    "AWP",
    "Diesel Scissors",
  ];

  const uniqueYOMs = [
    ...new Set(
      filteredData
        .map((item) => String(item.yom)) // Ensure YOM values are strings
        .filter((year) => year && year !== "None") // Remove empty/null values & "None"
    ),
  ].sort((a, b) => Number(a) - Number(b)); // Sort numerically

  // console.log(uniqueYOMs);

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setPageSize(value === "All data" ? filteredData.length : value);
    setPage(0); // Reset to the first page
  };

  const handleClearFilter = () => {
    setFilter("All Assets"); // Reset filter to default
    setFilteredData(assetDetails); // Show all data
    setSearchQuery(""); // Clear search
  };

  const selected = [
    { title: "All Assets", label: "all" },
    { title: "Active", label: "active" },
    { title: "Free", label: "free" },
    { title: "Exceeded", label: "expired" },
  ];

  const status = [
    { title: "Select", label: "select" },
    { title: "Tracker Installed", label: "tracker_installed" },
    { title: "Sold Items", label: "is_sold" },
  ];

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    applyFilters();
  }, [
    assetDetails,
    filter,
    searchQuery,
    filterStatus,
    selectedYOM,
    selectedCategories,
    selectedBrand,
    selectedModel,
  ]);

  // Handle URL-based filtering

  const applyFilters = () => {
    let data = [...assetDetails];

    // Tracker Installed & Sold Items filtering
    if (filterStatus) {
      if (filterStatus === "Tracker Installed") {
        data = data.filter(
          (item) => item.device_no && item.device_no.trim() !== ""
        );
      } else if (filterStatus === "Sold Items") {
        data = data.filter((item) => item.is_sold === true);
      }
    }

    // Filter by Lease Status
    if (filter !== "All Assets") {
      if (filter === "Active") {
        data = data.filter(
          (item) =>
            item.lease_status === "active" ||
            item.lease_status?.toLowerCase().includes("expiring")
        );
      } else if (filter === "Free") {
        data = data.filter((item) =>
          ["inactive", "never assigned"].includes(item.lease_status)
        );
        setSearchParams("free");
      } else if (filter === "Exceeded") {
        data = data.filter((item) => item.lease_status === "expired");
      }
    }

    // Filter by Category
    if (selectedCategories.length > 0) {
      data = data.filter((item) => selectedCategories.includes(item.category));
    }

    // Filter by YOM (Ensure correct type)
    if (selectedYOM.length > 0) {
      data = data.filter((item) => selectedYOM.includes(String(item.yom)));
    }

    if (selectedBrand.length > 0) {
      data = data.filter((item) =>
        selectedBrand.includes(String(item.make).toLowerCase())
      );
    }

    if (selectedModel.length > 0) {
      console.log("Filtering by models:", selectedModel); // Debugging log
      data = data.filter(
        (item) =>
          selectedModel.includes(String(item.model).trim().toLowerCase()) // Ensure case & space matching
      );
    }

    // Search Query Filter
    if (searchQuery.trim()) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      data = data.filter((item) =>
        Object.values(item).some(
          (value) =>
            value && value.toString().toLowerCase().includes(lowerCaseQuery)
        )
      );
    }

    // Set Filtered Data
    setFilteredData(data);
    setPage(0); // Reset Pagination
  };

  useEffect(() => {
    const assetParam = searchParams.get("asset");

    if (assetParam === "free") {
      setTimeout(() => {
        applyFilters(["inactive", "never assigned"]); // Apply filter for "Free" status
        setFilter("Free"); // Update filter state
      }, 500);
    } else if (assetParam === "sold") {
      setTimeout(() => {
        applyFilters("sold"); // Apply "Sold Items" filter
        setFilter("Sold Items"); // Update filter state
      }, 500);
    }
  }, [searchParams]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const navigate = useNavigate();

  const getLeaseStatus = (value) => {
    if (value === "inactive" || value === "never assigned") {
      return { status: "Free", color: "#6c757d" }; // Gray
    } else if (value === "expired") {
      return { status: "Exceeded", color: "#dc3545" }; // Dark Red
    } else if (value === "active") {
      return { status: "Active", color: "#28a745" }; // Green
    } else if (value.toLowerCase().includes("expiring")) {
      const daysMatch = value.match(/(\d+)\s+days?/) || []; // Matches "1 day" or "X days"

      if (daysMatch[1]) {
        const daysLeft = parseInt(daysMatch[1], 10); // Convert to number

        return {
          status: `Active (${daysLeft} ${
            daysLeft === 1 ? "day" : "days"
          } left)`,
          color: "#28a745",
        };
      }
    }
    return { status: "Unknown", color: "#000" }; // Default case
  };

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => <span title="Row Number">#</span>,
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },
    {
      field: "asset_no",
      headerName: "Asset No",
      width: 180,
      renderHeader: () => (
        <span title="Unique identifier for the asset">Asset No</span>
      ),
      renderCell: (params) => {
        const { row } = params;
        let color = "#000"; // Default color
        let icon = null; // Default icon

        if (row.device_no) {
          color = "#28a745"; // Tracker Installed - Green
          icon = <FaWifi style={{ marginLeft: 5, color: "#28a745" }} />;
        }
        if (row.is_sold === true) {
          color = "red"; // Sold - Red
          icon = <FaTag style={{ marginLeft: 5, color: "red" }} />;
        }

        return (
          <span
            style={{ display: "flex", alignItems: "center" }}
            title={row.asset_no}
          >
            <span style={{ color, fontWeight: "bold" }}>{row.asset_no}</span>
            {icon}
          </span>
        );
      },
    },

    {
      field: "make",
      headerName: "Make",
      width: 180,
      renderHeader: () => (
        <span title="Brand or manufacturer of the asset">Make</span>
      ),
    },

    {
      field: "model",
      headerName: "Model",
      width: 180,
      renderHeader: () => (
        <span title="The specific model name or number of the asset">
          Model
        </span>
      ),
    },

    {
      field: "yom",
      headerName: "YOM",
      width: 180,
      renderHeader: () => <span title="Year of manufacture">YOM</span>,
    },

    {
      field: "category",
      headerName: "Category",
      width: 180,
      renderHeader: () => <span title="Category of the asset">Category</span>,
      renderCell: (params) => {
        const { value } = params;
        const categoryValue =
          value && value.toLowerCase() !== "nill" ? value : "N/A";
        return <span>{categoryValue}</span>;
      },
    },

    {
      field: "serial_no",
      headerName: "Serial No",
      width: 180,
      renderHeader: () => (
        <span title="Serial number of the asset">Serial No</span>
      ),
    },

    {
      field: "lease_status",
      headerName: "Lease Status",
      width: 150,
      renderHeader: () => (
        <span title="Current lease status of the asset">Lease Status</span>
      ),
      renderCell: (params) => {
        const { value } = params;
        const { status, color } = getLeaseStatus(value);

        return (
          <span
            style={{
              backgroundColor: color,
              // fontWeight: "bold",
              // padding: "4px 8px",
            }}
            className={`p-1.5  px-2 rounded-full  text-white`}
          >
            {capitalizeFirstLetter(status)}
          </span>
        );
      },
    },
  ];

  const brands = [
    ...new Set(
      filteredData
        .map((item) => String(item.make).toLowerCase()) // Ensure all are lowercase strings
        .filter((make) => make && make !== "none") // Remove empty/null/"None"
    ),
  ].sort(); // Sort alphabetically

  const models = [
    ...new Set(
      filteredData
        .map((item) => String(item.model).trim().toLowerCase()) // Normalize model names
        .filter((model) => model && model !== "none") // Remove empty values
    ),
  ].sort();

  const handleClearFilterChange = () => {
    setSelectedYOM([]);
    setSelectedModel([]);
    setSelectedCategories([]);
    setSelectedBrand([]);
    setSearchQuery("");
    applyFilters(); // Reset filters
    setFilter("All Assets"); // Reset filter to default
    setFilteredData(assetDetails); // Show all data
    setFilterStatus("select");
  };

  return (
    <div className=" bg-white">
      <Paper
        style={{
          // padding: 10,
          height: "70vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        {/* Demo */}
        {/* <div className="flex flex-wrap  justify-between bg-white ">
          <div className="grid grid-cols-7 items-center gap-2 p-2 bg-white dark:bg-gray-900 shadow-md rounded-lg">
            <FormControl sx={{ minWidth: 150 }} size="small">
              <label className="text-xs text-gray-600 dark:text-gray-300 mb-1">
                Lease Status
              </label>
              <Select
                title="Filter Lease Status"
                value={filter}
                onChange={handleFilterChange}
                sx={{
                  fontSize: "12px",
                  bgcolor: "white",
                  "& .MuiSelect-select": { padding: "6px" },
                }}
              >
                {selected.map((option) => (
                  <MenuItem
                    key={option.title}
                    value={option.title}
                    sx={{ fontSize: "12px" }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 150 }} size="small">
              <label className="text-xs text-gray-600 dark:text-gray-300 mb-1">
                Tracker Installed or Sold Status
              </label>
              <Select
                title="Filter Tracker Installed or Sold Assets"
                value={filterStatus}
                onChange={handleFilterStatusChange}
                sx={{
                  fontSize: "12px",
                  bgcolor: "white",
                  "& .MuiSelect-select": { padding: "6px" },
                }}
              >
                {status.map((option) => (
                  <MenuItem
                    key={option.title}
                    value={option.title}
                    sx={{ fontSize: "12px" }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 150 }} size="small">
              <label className="text-xs text-gray-600 dark:text-gray-300 mb-1">
                Category
              </label>
              <Select
                multiple
                value={selectedCategories}
                onChange={(event) => setSelectedCategories(event.target.value)}
                renderValue={(selected) => selected.join(", ")}
                sx={{
                  fontSize: "12px",
                  bgcolor: "white",
                  "& .MuiSelect-select": { padding: "6px" },
                }}
              >
                {categories.map((category) => (
                  <MenuItem
                    key={category}
                    value={category}
                    sx={{ fontSize: "12px" }}
                  >
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 150 }} size="small">
              <label className="text-xs text-gray-600 dark:text-gray-300 mb-1">
                Brand
              </label>
              <Select
                multiple
                value={selectedBrand}
                onChange={(e) =>
                  setSelectedBrand(e.target.value.map((b) => b.toLowerCase()))
                }
                input={<OutlinedInput label="Brand" />}
                renderValue={(selected) => (
                  <div className="flex flex-wrap gap-1">
                    {selected.map((value) => (
                      <Chip key={value} label={value} size="small" />
                    ))}
                  </div>
                )}
                sx={{
                  fontSize: "12px",
                  bgcolor: "white",
                  "& .MuiSelect-select": { padding: "6px" },
                }}
              >
                {brands.map((brand) => (
                  <MenuItem key={brand} value={brand} sx={{ fontSize: "12px" }}>
                    {brand}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 150 }} size="small">
              <label className="text-xs text-gray-600 dark:text-gray-300 mb-1">
                Model
              </label>
              <Select
                multiple
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
                input={<OutlinedInput label="Model" />}
                renderValue={(selected) => (
                  <div className="flex flex-wrap gap-1">
                    {selected.map((value) => (
                      <Chip key={value} label={value} size="small" />
                    ))}
                  </div>
                )}
                sx={{
                  fontSize: "12px",
                  bgcolor: "white",
                  "& .MuiSelect-select": { padding: "6px" },
                }}
              >
                {models.map((model) => (
                  <MenuItem key={model} value={model} sx={{ fontSize: "12px" }}>
                    {model}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 150 }} size="small">
              <label className="text-xs text-gray-600 dark:text-gray-300 mb-1">
                Production Year
              </label>
              <Select
                multiple
                value={selectedYOM}
                onChange={(e) => setSelectedYOM(e.target.value)}
                input={<OutlinedInput label="Year of Manufacture" />}
                renderValue={(selected) => (
                  <div className="flex flex-wrap gap-1">
                    {selected.map((value) => (
                      <Chip key={value} label={value} size="small" />
                    ))}
                  </div>
                )}
                sx={{
                  fontSize: "12px",
                  bgcolor: "white",
                  "& .MuiSelect-select": { padding: "6px" },
                }}
              >
                {uniqueYOMs.map((year) => (
                  <MenuItem key={year} value={year} sx={{ fontSize: "12px" }}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="flex flex-col">
              <span>Reset all fields</span>
              <button
                className="bg-red-500 text-white buttons items-end  hover:bg-red-600"
                onClick={handleClearFilterChange}
              >
                Reset Filters
              </button>
            </div>
          </div>
        </div> */}
        {/* FInal VErsion */}
        <div className="flex max-md:flex-col justify-between items-center mt-1 ">
          <div className="flex max-md:flex-col  gap-2 justify-center items-center">
            <div className="text-[12px] w-full">
              <div className="flex gap-2 items-center ">
                <span className="text-xs flex flex-col">
                  {/* Search */}
                  <input
                    title="Search Assets"
                    type="text"
                    placeholder="Search Assets..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="border-light-5 border  max-md:w-[300px] w-[200px] p-[4px] rounded-[4px] text-[12px] items-center"
                  />
                </span>

                {/* Lease Status */}
                <div className="min-w-[150px]">
                  <select
                    title="Filter Lease Status"
                    value={filter}
                    onChange={handleFilterChange}
                    className="text-[12px] bg-white border rounded-[4px] p-[4px] w-[150px]"
                    sx={{
                      fontSize: "12px",
                      bgcolor: "white",
                      "& .MuiSelect-select": { padding: "6px" },
                    }}
                  >
                    {selected.map((option) => (
                      <option
                        key={option.title}
                        value={option.title}
                        sx={{ fontSize: "12px" }}
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Tracker Installation, Sold Machines */}
                <div className="min-w-[150px]">
                  <select
                    title="Filter Tracker Installed or Sold Assets"
                    value={filterStatus}
                    onChange={handleFilterStatusChange}
                    className="text-[12px] bg-white border rounded-[4px] p-[4px] w-[150px]"
                  >
                    {status.map((option) => (
                      <option
                        key={option.title}
                        value={option.title}
                        sx={{ fontSize: "12px" }}
                      >
                        {option.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex max-md:flex-col justify-between gap-2 items-center pb-3">
            <button
              className={`shadow-md bg-white border rounded-md p-1.5  ${
                filter === "All Assets" && "hidden"
              } flex items-center text-[12px] text-black`}
            >
              {/* State indicator and label */}
              <span className={`flex items-center mr-2`}>
                <span
                  className={`h-2 w-2 rounded-full mr-1
                  ${
                    filter === "Free"
                      ? "bg-light-2"
                      : filter === "Exceeded"
                      ? "bg-red-1"
                      : filter === "Sold Items"
                      ? "bg-red-1"
                      : "bg-green-500"
                  }
                   `}
                ></span>{" "}
                {/* Green dot */}
                {filter}
              </span>

              {/* Close icon */}
              <IoIosClose
                className="h-4 w-4 cursor-pointer"
                onClick={() => {
                  setFilter("All Assets");
                }}
              />
            </button>

            <button
              className={`shadow-md bg-white border rounded-md p-1.5  ${
                filterStatus === "Sold Items" ||
                filterStatus === "Tracker Installed"
                  ? ""
                  : "hidden"
              } flex items-center text-[12px] text-black`}
            >
              {/* State indicator and label */}
              <span className={`flex items-center mr-2`}>
                <span
                  className={`h-2 w-2 rounded-full mr-1
                  ${
                    filterStatus === "Sold Items"
                      ? "bg-red-1"
                      : filterStatus === "Tracker Installed"
                      ? "bg-green-500"
                      : "hidden"
                  }
                   `}
                ></span>{" "}
                {/* Green dot */}
                {filterStatus}
              </span>

              {/* Close icon */}
              <IoIosClose
                className="h-4 w-4 cursor-pointer"
                onClick={() => {
                  setFilterStatus("select");
                }}
              />
            </button>

            {/* Header Pagination */}

            {/* <Tooltip title="Add pagination" placement="top" arrow> */}
            <TablePagination
              title="Add pagination"
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 30, 50, "All data"]}
              labelRowsPerPage="Assets:"
              SelectProps={{
                renderValue: (value) =>
                  value === "All data" ? "All data" : value, // Display "All data" if selected
              }}
            />
            {/* </Tooltip> */}
            <div className="flex items-center gap-2">
              <NewAssetBtn tabName="asset" />
              <span title="Export Data in Excel format" placement="top" arrow>
                <button
                  className="flex  items-center buttons border border-green-2 gap-1  text-green-2 text-center justify-center"
                  onClick={handleExport}
                  title="Click to export in excel"
                >
                  <FaFileExcel /> Export Excel
                </button>
              </span>
            </div>
          </div>
        </div>

        {filteredData.length > 0 ? (
          <DataGrid
            rows={
              pageSize === filteredData.length
                ? filteredData.map((row, index) => ({
                    ...row,
                    counter: index + 1, // Add row number for all rows
                  }))
                : filteredData
                    .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
                    .map((row, index) => ({
                      ...row,
                      counter: page * pageSize + index + 1, // Adjust counter for paginated rows
                    }))
            }
            onRowClick={(params) => {
              const rowId = params.row.id; // Use the row ID or another property to navigate
              navigate(`/assets/asset-details/${rowId}`); // Adjust the route as needed
            }}
            columns={columns}
            // checkboxSelection
            paginationMode="server"
            pagination={false} // Disable built-in pagination
            sx={{
              "& .MuiDataGrid-footerContainer": {
                display: "none", // Hides the footer container
              },

              "& .MuiDataGrid-root": { fontSize: "12px", height: 25 },
              "& .MuiDataGrid-columnHeaders": {
                fontSize: "12px",
                backgroundColor: "#000",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
                height: 20,
              },
              "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
              "& .MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
          />
        ) : (
          <>
            <div className="h-[50vh] flex flex-col justify-center items-center dark:text-white text-gray-500">
              <svg
                className="w-16 h-16 mb-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <p className="text-lg">No Assets found</p>
              <p className="text-sm mt-1">
                Create a new asset or check back later
              </p>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default NewTable;
