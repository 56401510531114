import React from "react";
import { capitalizeFirstLetter, dateValue, formatDate } from "./FormatDate";
import PhotoData from "./PhotoData";

const CommercialDetails = ({ assetDetails, imageUrl, NoImage , handleBottomTab, setPhotoDataTab}) => {
  return (
    <>
      <div className="bg-white rounded-[4px] shadow-md pt-3 mb-2 pb-4 px-2">
        <div className="flex flex-row-reverse justify-between px-2 gap-2 text-left max-md:flex-col">
          <PhotoData imageUrl={imageUrl} NoImage={NoImage} handleBottomTab={handleBottomTab} setPhotoDataTab={setPhotoDataTab} />
          <div className="flex flex-col text-left">
            <div className="  w-[160px] max-sm:w-full ">
              <span className="text-dark-6 text-[11px]">Asset No.</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.asset_no || 'N/A'}
              </span>
            </div>
            <div className="  w-[160px] max-sm:w-full ">
              <span className="text-dark-6 text-[11px]">Model</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.model || 'N/A'}
              </span>
            </div>
            <div className="  w-[160px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Serial No</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.serial_no || 'N/A'}
              </span>
            </div>
            <div className="  w-[160px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Category</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.category || 'N/A'}
              </span>
            </div>
          </div>
        </div>
        <div className="px-2  space-y- mt-4 gap-3">
          {assetDetails?.device_no && (
            <>
              <div className="flex justify-between max-sm:flex-col">
                {/* isntalled device number */}
                <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px] ">
                    Tracking Device ID
                  </span>
                  <span className={`border rounded-[2px] font-bold text-[11px] h-[27px]  items-center bg-white flex justify-start px-2 
                    ${assetDetails?.device_no ? 'text-blue-2':'text-dark-6'}
                    `}>
                    {assetDetails?.device_no ? assetDetails?.device_no : "No device installed"}
                  </span>
                </div>
                {/* installation_date */}
                <div className="flex flex-col text-left  ">
                  <span className="text-dark-6 text-[11px] ">
                    Tracking Device Installation Date
                  </span>
                  <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                    {assetDetails?.installation_date || "N/A"}
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="flex justify-between items-center max-sm:flex-col  py-1">
            <div className="flex flex-col text-left  w-[160px] max-md:w-full">
              <span className="text-dark-6 text-[11px]">Serial No.</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.serial_no || 'N/A'}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[180px] max-md:w-full">
              <span className="text-dark-6 text-[11px]">ANSI/CD</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.config_data?.ansi_or_new || 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-md:w-full">
              <span className="text-dark-6 text-[11px]">Drive Train</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2">
                {assetDetails?.config_data?.battery_type || "N/A"}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[180px] max-md:w-full">
              <span className="text-dark-6 text-[11px]">Place of Import</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.site_location || 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">
                Place of Clearance
              </span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.revnew_data?.port_of_clearance || "N/A"}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[180px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">
                Year of Manufacturing
              </span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.yom || 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col ">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Drive Type</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.config_data?.two_or_four_wd || "N/A"}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[180px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Make</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.make || 'N/A'}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Purchased From</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.purchased_from || 'N/A'}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[180px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Tracker</span>
              <span
                className={`border rounded-[2px] font-bold text-[11px] h-[27px]  items-center bg-white flex justify-start px-2 
              ${assetDetails?.device_id ? "text-blue-2" : "text-dark-6"}
              `}
              >
                {assetDetails?.device_id ? "Installed" : "Not Installed"}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Capacity</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.capacity}kg-{assetDetails?.hieght_mechine}ft
              </span>
            </div>
            <div className="flex flex-col text-left  w-[180px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Machine Sold</span>
              <span className="border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 ">
                {assetDetails?.is_sold === null ? "No" : "Yes"}
              </span>
            </div>
          </div>

          <div className="flex justify-between py-1 max-sm:flex-col">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Status</span>
              <span
                className={`border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 
              ${
                assetDetails?.lease_status === "inactive"
                  ? "text-slate-600"
                  : assetDetails?.lease_status === "active"
                  ? "text-green-500"
                  : assetDetails?.lease_status === "expired"
                  ? "text-red-600"
                  : "text-blue-600"
              }
              `}
              >
                {capitalizeFirstLetter(assetDetails?.lease_status) || 'N/A'}
              </span>
            </div>

            <div className="flex flex-col text-left  w-[180px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Used or New</span>
              <span
                className={`border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2 
             
              `}
              >
                {assetDetails?.config_data?.used_or_new || 'N/A'}
              </span>
            </div>
          </div>
          <div className="flex justify-between py-1 ">
            <div className="flex flex-col text-left  w-[160px] max-sm:w-full">
              <span className="text-dark-6 text-[11px]">Date of Creation</span>
              <span
                className={`border rounded-[2px] font-bold text-[11px] h-[27px] text-dark-6 items-center bg-white flex justify-start px-2
             
              `}
              >
                {dateValue(assetDetails?.created_at) || 'N/A'}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommercialDetails;
