import React from "react";

const RemittanceTable = ({ revnew_data, asset_sold_date }) => {
  let today;
  let date;
  let first_financial_date = new Date(
    revnew_data.custom_duty_date_of_clearance
  ).getMonth();

  if (first_financial_date > 4) {
    date = new Date(revnew_data.custom_duty_date_of_clearance).getFullYear();
  } else {
    date =
      new Date(revnew_data.custom_duty_date_of_clearance).getFullYear() - 1;
  }

  let yearArray = [];
  let present;

  let i = 0;

  if (asset_sold_date !== null) {
    present = new Date(asset_sold_date).getFullYear();
  } else {
    present = new Date().getFullYear();
  }
  while (present >= date) {
    yearArray[i] = present;
    present--;
    i++;
  }

  const yearRanges = yearArray
    .reverse()
    .map((year, index, arr) => {
      if (index < arr.length - 1) {
        return `${year} - ${arr[index + 1]}`;
      }
    })
    .filter(Boolean);

  // console.log(revnew_data)

  return (
    <div className="p-6 text-xs mb-20 bg-white rounded-[6px] shadow-md">
      <h2 className="text-[14px] font-bold mb-4 text-[#7e7e7e]">
        Interest Depreciation Table
      </h2>
      <div className=" text-left text-dark-6 ">
      <table className="min-w-full divide-y">
        <thead className="">
          <tr>
            <th className="px-4 py-2 ">Description</th>
            {yearRanges.map((year, index) => (
              <th key={index} className="px-4 py-2 ">
                {year}
              </th>
            ))}
            <th className="px-4 py-2 ">
              {asset_sold_date ? "Sold Date" : "Today"}
            </th>
          </tr>
        </thead>

        <tbody className="divide-y text-xs">
          <tr className="">
            <th className="px-4 py-2 ">
              Interest on Custom Duty{" "}
            </th>
            {yearRanges.map((year, index) => {
              {
                return (
                  <>
                    <td
                      key={index}
                      className="px-4 py-2 "
                    >
                      {
                        revnew_data.untill_rem_payment_custom_duty_interest[
                          `${year}`
                        ]
                      }
                    </td>
                  </>
                );
              }
            })}

            <td className="px-4 py-2 ">
              {revnew_data.untill_rem_payment_custom_duty_interest[`today`]}
            </td>
          </tr>
          <tr>
            <th className="px-4 py-2 ">
              Interest on Remittance or Clearing Balance
            </th>
            {yearRanges.map((year, index) => (
              <>
                <td key={index} className="px-4 py-2 ">
                  {revnew_data.first_year_rem_to_oem_interest[`${year}`]}

                  {revnew_data.interest_by_year[`${year}`]}
                </td>
              </>
            ))}
            <td className="px-4 py-2 ">
              {revnew_data.interest_by_year[`today`]}
            </td>
          </tr>
          <tr>
            <th className="px-4 py-2 ">Depreciation </th>
            {yearRanges.map((year, index) => (
              <td key={index} className="px-4 py-2 ">
                {/* {revnew_data.first_year_depreciation[`${year}`]} */}
                {revnew_data.depercation_by_year[`${year}`]}
              </td>
            ))}
            <td className="px-4 py-2 ">
              {revnew_data.depercation_by_year[`today`]}
            </td>
          </tr>
          <tr>
            <th className="px-4 py-2 ">
              Clearing Balance of Loan (Depreciated Value){" "}
            </th>
            {yearRanges.map((year, index) => (
              <td key={index} className="px-4 py-2 ">
                {revnew_data.first_year_pricipal_depreciation[`${year}`]}
                {revnew_data.total_pricipal_depreciation_by_date[`${year}`]}
              </td>
            ))}
            <td className="px-4 py-2 ">
              {revnew_data.total_pricipal_depreciation_by_date[`today`]}
            </td>
          </tr>
        </tbody>
      </table>

      </div>
    </div>
  );
};

export default RemittanceTable;
