// import axios from 'axios'
// import React, { useContext, useEffect, useState } from 'react'
// import { setBaseUrl } from '../../config'
// import { useNavigate } from 'react-router-dom'

// const CheckOldOperator = ({adhaarChecked, setAdhaarChecked}) => {

//     // Check Operator
//     // let  id = '0eae3514-f88c-4f69-a7cf-8a31a82f1d9b'
//     const [adhaarNumberInput, setAdhaarNumberInput] = useState(null)
//     const [oldOperator, setOldOperator] = useState(false)
//     const [searchSuggetions, setSearchSuggetions] = useState([]);
//     const [searchData, setSearchData] = useState([])
//     const [showAdhaar, setShowAdhaar] = useState(false)
//     const [id, setId] = useState({})
//     const navigate = useNavigate()
//   // Search Adhaar
//   const getSearchData = async () => {
//     try {
//       const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//       });
//       let dataArryUserId = []
//       let aadharArr = Object.keys(data["aadhar"])
//       for (let i = 0; i < aadharArr.length ; i++) {
//         dataArryUserId.push({
//           [aadharArr[i]] : data["aadhar"][aadharArr[i]]
//         })
//       }
//       setSearchData(() => dataArryUserId)
//       // setId(dataArryUserId)
//       setSearchSuggetions(() => dataArryUserId);

//     } catch (error) {

//     }
//   };

//   useEffect(() => {
//     getSearchData();
//   }, []);

// // console.log(searchSuggetions)

//     const handleCheckAdhaar = (e) => {
//       e.preventDefault();
//       const input = e.target.value.trim();
//         setShowAdhaar(true)

//       setAdhaarNumberInput(input)

//       let dataArray = []
//       for(let i = 0; i < searchData.length; i++) {
//           if(Object.keys(searchData[i])[0].indexOf(input) !== -1)  {
//               dataArray.push(searchData[i])
//             }
//           }

//           // console.log(dataArray)
//       if (e.target.value) {
//         setSearchSuggetions(dataArray)
//         setOldOperator(true)
//       } else {
//         setOldOperator(false)
//       }

//       if(e.target.value!== searchSuggetions) {
//         setOldOperator(false)
//       }
//       let aadhar_no_value = e.target.value;

//       var expr =
//         /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
//       if (expr.test(aadhar_no_value)) {
//         setAdhaarNumberInput(null);

//       } else {

//       }
//     }

// const handleCreate = () => {

//   if(adhaarNumberInput !== null) {
//     setOldOperator(true)
//     navigate(`/operators/updateOperator/${id}`)
//   } else {
//     setOldOperator(false)
//     setAdhaarChecked(true)
//   }
// }

//   return (
//     <>
//      <div className="items-center text-xs flex  justify-center ">
//       <form
//         className="border w-[400px] h-[500px]
//         items-center flex flex-col justify-center space-y-5 "
//         onChange={handleCheckAdhaar}>
//       <h1 className="text-center absolute top-10 font-bold
//       text-slate-700 dark:text-white">
//         Operators
//         </h1>
//       <span> Please Enter Adhaar No</span>
//         <input
//         type="text"
//         placeholder="Enter Valid Aadhaar no."
//         className="bg-transparent
//         rounded-full w-[280px]
//         transition-all duration-300
//         ease-in-out focus:border-light-1 border-light-1 border
//         focus:outline-none focus:ring-0"
//         value={adhaarNumberInput}
//         />
// {adhaarNumberInput !== null && (
//         <p className="text-[#24a062] font-bold mt-4 text-xs">{adhaarNumberInput}</p>
//       )}
//         <div className="flex justify-between gap-5">
//         <button className="w-[120px] border border-light-1 h-8 rounded">Back</button>
//         <button className="w-[120px] bg-light-1 text-white h-8 rounded" onClick={handleCreate}>{oldOperator? "Rejoin":"Create New"}</button>
//         </div>

//       </form>
//         <div className="flex flex-col absolute bottom-5 h-[100px] overflow-y-auto">

// {searchSuggetions?.map((item, i) => (
//     <>
//     <div className="" key={i}  onClick={() => {setAdhaarNumberInput(Object.keys(item)[0]); setId(item[Object.keys(item)[0]]);  setOldOperator(true)}}>
//         {Object.keys(item)}
//     </div>
//     </>
// ))}
//         </div>
//      </div>

//       </>
//   )
// }

// export default CheckOldOperator

import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { Link, useNavigate } from "react-router-dom";

const CheckOldOperator = ({
  adhaarChecked,
  setAdhaarChecked,
  // newAdhaar,
  // setNewAdhaar,
  newDob,
  setNewDob,
  operatorData,
  setOperatorData
}) => {
  const [adhaarNumberInput, setAdhaarNumberInput] = useState("");
  const [oldOperator, setOldOperator] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [error, setError] = useState("");
  const [id, setId] = useState({});
  const navigate = useNavigate();

  // Fetch Aadhaar data for search suggestions
  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      let dataArryUserId = [];
      let aadharArr = Object.keys(data["aadhar"]);
      for (let i = 0; i < aadharArr.length; i++) {
        dataArryUserId.push({
          [aadharArr[i]]: data["aadhar"][aadharArr[i]],
        });
      }
      setSearchData(dataArryUserId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearchData();
  }, []);

  // Enter Adhaar number
  const handleCheckAdhaar = (e) => {
    const input = e.target.value;

    // Check if the input is a valid number and has exactly 12 digits
    if (!/^\d+$/.test(input) && input !== "") {
      setError("Aadhaar number must contain only digits");
      return;
    }

    if (input.length > 12) {
      setError("Aadhaar number must not exceed 12 digits");
      return;
    }

    operatorData.aadhar_no = input
    setError("");
    setAdhaarNumberInput(input);
    setOperatorData(operatorData)
    

    let filteredData = [];
    for (let i = 0; i < searchData.length; i++) {
      if (Object.keys(searchData[i])[0].indexOf(input) !== -1) {
        filteredData.push(searchData[i]);
      }
    }
    setSearchSuggestions(filteredData);
    if (input.length === 12) {
      setOldOperator(filteredData.length > 0);
    } else {
      setOldOperator(false);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    if (adhaarNumberInput.length !== 12 || null) {
      setError("Aadhaar number must be exactly 12 digits");
      return;
    }

    if (oldOperator) {
      navigate(`/operators/updateOperator/${id}`);
    } else {
      setOldOperator(false);
      setAdhaarChecked(true);
    }
  };

  const [dobinput, setDobinput] = useState("");
  const [isEligible, setIsEligible] = useState(false);

  const handleCheckDOB = (e) => {
    setDobinput(e.target.value);

    const today = new Date();
    const birthDate = new Date(e.target.value);

    // Calculate the age difference
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Check if age is 18 or above
    if (
      age > 18 ||
      (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))
    ) {
      setIsEligible(true);
      console.log("Eligible");
      setNewDob(e.target.value)
    } else {
      setIsEligible(false);
      console.log("Not Eligible");
    }
  };

  return (
    <>
      <div className="items-center text-xs flex justify-center">
        <form
          className="border w-[400px] h-[500px] items-center flex flex-col justify-center space-y-3"
          onSubmit={handleCreate}
        >
          <h1 className="text-center absolute top-10 font-bold text-slate-700 dark:text-white">
            Operators
          </h1>
          <span>Please Enter Aadhaar No</span>
          <input
            type="text"
            placeholder="Enter Valid Aadhaar No."
            className="bg-transparent rounded-full w-[280px] transition-all duration-300 ease-in-out focus:border-light-1 border-light-1 border focus:outline-none focus:ring-0"
            value={adhaarNumberInput}
            onChange={handleCheckAdhaar}
            maxLength={12}
          />

          {error && <p className="text-red-500">{error}</p>}

          {adhaarNumberInput !== "" && (
            <>
              {" "}
              {!oldOperator && (
                <>
                  <input
                    type="date"
                    className="bg-transparent rounded-full w-[280px] transition-all duration-300 ease-in-out focus:border-light-1 border-light-1 border focus:outline-none focus:ring-0"
                    value={dobinput}
                    onChange={handleCheckDOB}
                    maxLength={12}
                  />
                  {dobinput !== "" && (
                    <> {isEligible ? <p></p> : <p className="text-red-600">Not Eligible Must Be 18+</p>}</>
                  )}
                </>
              )}
            </>
          )}

          <div className="flex justify-between gap-5">
            <Link to={"/operators"}>
              <button
                type="button"
                className="w-[120px] border border-light-1 h-8 rounded"
              >
                Back
              </button>
            </Link>
            <button
              type="submit"
              className={`w-[120px] bg-light-1 text-white h-8 rounded ${
                oldOperator || isEligible
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              }`}
            >
              {oldOperator ? "Rejoin" : "Create New"}
            </button>
          </div>
        </form>
        <div className="flex flex-col absolute bg-[#efefef47] bottom-0  h-[120px] overflow-y-auto">
          {/* Search Suggestions */}
          {searchSuggestions?.map((item, i) => {
            const aadhaar = Object.keys(item)[0];

            // Function to highlight digits found anywhere in the input
            const highlightAadhaar = (aadhaarNumber) => {
              return aadhaarNumber.split("").map((digit, index) => {
                // Check if the digit is found anywhere in the input
                const isMatched =
                  adhaarNumberInput && adhaarNumberInput.includes(digit);
                return (
                  <span
                    key={index}
                    className={isMatched ? "text-green-500 font-bold" : ""}
                  >
                    {digit}
                  </span>
                );
              });
            };

            return (
              <div
                key={i}
                className="cursor-pointer"
                onClick={() => {
                  setAdhaarNumberInput(aadhaar); // Set selected Aadhaar in the input
                  setId(item[aadhaar]); // Set the corresponding ID
                  setOldOperator(true);
                }}
              >
                {highlightAadhaar(aadhaar)}{" "}
                {/* Call the function to render the highlighted Aadhaar */}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CheckOldOperator;
