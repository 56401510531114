import { toast } from "react-toastify";
import { setBaseUrl } from "../../../config";

import { useState, useEffect } from "react";
import moment from "moment";
import { CircularProgress } from "@mui/material";

const EditableLogTable = ({
  rso_id,
  asset_no,
  leaseId,
  tableData,
  monthData,
  setEditable,
  rentalStartDate,
  rentalEndDate,
}) => {
  // const [selectedMonth, setSelectedMonth] = useState("");
  const [formData, setFormData] = useState([]);
  const [operatorData, setOperatorData] = useState(null);
  const [loader, setLoader] = useState(false);

  const getOperatorByLeaseID = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-operator-by-lease-id`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "lease-id": leaseId,
        },
      });
      const data = await res.json();
      setOperatorData(data);
    } catch (error) {
      console.error("Error fetching operator data:", error);
    }
  };

  useEffect(() => {
    getOperatorByLeaseID();
  }, [leaseId]);

  // Helper function to get number of days in a month
  const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

  // Initial field structure
  const initialFields = (date) => ({
    x_studio_date: date,
    x_studio_day_type: "WD",
    x_studio_time_in: "",
    x_studio_time_out: "",
    x_studio_breakdown_time: "",
    x_studio_overtime: "",
    x_studio_operator1: "",
    x_studio_reimbursements: "",
  });

  // Auto-populate the form for the selected month
  // useEffect(() => {
  //   if (monthData && monthData.length > 0) {
  //     const [year, month] = monthData[0]?.x_studio_date?.split("-").map(Number);
  //     const days = getDaysInMonth(month, year);

  //     // Create the formData with initial structure
  //     const newFormData = Array.from({ length: days }, (_, i) => {
  //       const formattedDate = `${year}-${String(month).padStart(
  //         2,
  //         "0"
  //       )}-${String(i + 1).padStart(2, "0")}`;

  //       // Find the matching entry in monthData for the current day
  //       const matchingData = monthData.find(
  //         (entry) => entry.x_studio_date === formattedDate
  //       );

  //       // If matching data exists, use it, otherwise use the default initialFields
  //       return matchingData
  //         ? {
  //             ...initialFields(formattedDate),
  //             ...matchingData, // Override with data from monthData if available
  //           }
  //         : initialFields(formattedDate);
  //     });

  //     setFormData(newFormData);
  //   }
  // }, [monthData]);

  useEffect(() => {
    if (monthData && monthData.length > 0) {
      const [year, month] = monthData[0]?.x_studio_date?.split("-").map(Number);
      const days = getDaysInMonth(month, year);

      // Create the formData with multiple rows for duplicate dates
      const newFormData = [];

      for (let i = 1; i <= days; i++) {
        const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(i).padStart(2, "0")}`;

        // Find all matching entries for the current date
        const matchingEntries = monthData.filter(entry => entry.x_studio_date === formattedDate);

        if (matchingEntries.length > 0) {
          // Add multiple rows if there are multiple shifts
          matchingEntries.forEach(entry => {
            newFormData.push({
              ...initialFields(formattedDate),
              ...entry, // Override with data from monthData
            });
          });
        } else {
          // Add a single row with default values if no data is available
          newFormData.push(initialFields(formattedDate));
        }
      }

      setFormData(newFormData);
    }
  }, [monthData]);

  const [updatedFormData, setUpdatedFormData] = useState({});

  const handleFieldChange = (date, field, value, dayIndex) => {
    setUpdatedFormData((prevData) => {
      const updatedData = { ...prevData };

      // If there's a value, update the field
      if (value !== undefined && value !== null && value !== "") {
        // Check if data for the specific date already exists in updatedFormData
        if (updatedData[dayIndex]) {
          updatedData[dayIndex][field] = value; // Update the field for the existing date
        } else {
          updatedData[dayIndex] = { [field]: value }; // If no data for that date, create a new entry
        }
      } else {
        // If value is undefined, null, or empty, do not add or remove the entry
        if (updatedData[dayIndex]) {
          delete updatedData[dayIndex]; // Remove the entry for that date if no valid data exists
        }
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Convert updatedFormData object to an array
    const convertedFormData = Object.entries(updatedFormData).map(
      ([dayIndex, entry]) => ({
        ...entry,
        x_studio_date: formData[dayIndex]?.x_studio_date || "",
        x_studio_rental_register_no: rso_id, // Ensure asset and RSO ID are included
        x_name: asset_no,
        x_studio_breakdown_time:
          entry.x_studio_breakdown_time ||
          formData[dayIndex]?.x_studio_breakdown_time,
        x_studio_overtime:
          entry.x_studio_overtime || formData[dayIndex].x_studio_overtime,
        x_studio_reimbursements:
          entry.x_studio_reimbursements ||
          formData[dayIndex].x_studio_reimbursements,
        x_studio_time_in:
          entry.x_studio_time_in || formData[dayIndex].x_studio_time_in,
        x_studio_time_out:
          entry.x_studio_time_out || formData[dayIndex].x_studio_time_out,
        x_studio_day_type: entry.x_studio_day_type || "WD",
        x_studio_operator1: entry.x_studio_operator || "N/A",
      })
    );

    // console.log("Submitting Data:", convertedFormData);

    if (convertedFormData.length === 0) {
      toast.error("No valid data to submit!");
      return;
    }

    // Now `updatedFormData` contains only the modified entries
    try {
      setLoader(true)
      const response = await fetch(`${setBaseUrl}/lease/entry-timesheet-data`, {
        method: "POST",
        body: JSON.stringify({
          input_arr: convertedFormData,
          action: "replace",
        }),
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      if (response.ok) {
        toast.success("Data updated successfully!");
        setLoader(false)
        window.location.reload();
      } else {
        throw new Error("Failed to update data");
        setLoader(false)
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Data not updated successfully. Please check odoo logs and try again!!"
      );
      setLoader(false)
    }
  };


  const copyPreviousRow = (dayIndex) => {
    setFormData((prevData) => {
      if (dayIndex === 0) return prevData; // Prevent copying if it's the first row

      const updatedData = [...prevData];
      updatedData[dayIndex] = { ...prevData[dayIndex - 1] }; // Copy previous row

      // Ensure the date remains unique
      updatedData[dayIndex].x_studio_date = prevData[dayIndex].x_studio_date;

      return updatedData;
    });
  };


  return (
    <div>
      {/* Month Selector */}

      <div action="" className="bg-white p-2 rounded">
        <table className="w-full text-[10px] text-center ">
          <thead className="">
            <tr className="p-4">
              <th className="p-4">Date</th>
              <th className="p-4">Day</th>
              <th className="p-4">Day Type</th>
              <th className="p-4">Time In</th>
              <th className="p-4">Time Out</th>
              <th className="p-4">Breakdown</th>
              <th className="p-4">Overtime</th>
              <th className="p-4">Operator</th>
              <th className="p-4">Reimbursements</th>
            </tr>
          </thead>
          <tbody>
            {console.log(formData)}
            {formData.map((dayData, index) => {
              const currentDate = dayData["x_studio_date"];
              const isDisabled =
                (rentalStartDate && currentDate < rentalStartDate) ||
                (rentalEndDate && currentDate > rentalEndDate);

              const rowClass = dayData.x_studio_day_type === "HD" ? "bg-red-100" : "bg-white";

              return (
                <tr key={index} className={`border ${rowClass}`}>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border bg-transparent"
                    }
                    title="Date"
                  >
                    {dayData.x_studio_date}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border bg-transparent"
                    }
                    title="Date"
                  >
                    {moment(dayData.x_studio_date).format("dddd")}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    <select
                      title="Day Type"
                      className="text-[10px] w-full border-none bg-transparent"
                      // value={moment(dayData.x_studio_day_type).format(
                      //   "DD-MM-YYYY"
                      // )}
                      value={dayData.x_studio_day_type || ''}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_day_type = e.target.value;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_day_type",
                          e.target.value,
                          index
                        );
                      }}
                      disabled={isDisabled}
                    >
                      <option value="WD">WD</option>
                      <option value="HD">HD</option>
                    </select>
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                        step="0.01"
                      value={dayData.x_studio_time_in}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_in = parseFloat(e.target.value);
                        setFormData(updatedData);
                        handleFieldChange(dayData.x_studio_date, "x_studio_time_in", parseFloat(e.target.value), index)
                      }}
                    /> */}
                    <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] bg-transparent w-full border-none"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_time_in}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove any non-numeric characters except the decimal point
                        inputValue = inputValue.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is allowed
                        let parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                        }

                        // Limit to 2 digits before and 2 digits after the decimal
                        if (parts[0].length > 2) {
                          parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                        }
                        if (parts[1]?.length > 2) {
                          parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                        }

                        // Combine the parts back into a valid format
                        inputValue =
                          parts.length > 1
                            ? parts[0] + "." + (parts[1] || "")
                            : parts[0];

                        // Update state
                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_in = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_time_in",
                          inputValue,
                          index
                        );
                      }}
                    />
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      disabled={isDisabled}
                      title="Time Out"
                      step="0.01"
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      value={dayData.x_studio_time_out}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_out = parseFloat(
                          e.target.value
                        );
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_time_out",
                          parseFloat(e.target.value),
                          index
                        );
                      }}
                    /> */}
                    <input
                      title="Time out"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none bg-transparent"
                      type="number"
                      min={dayData.x_studio_time_in || formData[index].x_studio_time_in || 0}
                      step="0.01"
                      value={dayData.x_studio_time_out}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove any non-numeric characters except the decimal point
                        inputValue = inputValue.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is allowed
                        let parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                        }

                        // Limit to 2 digits before and 2 digits after the decimal
                        if (parts[0].length > 2) {
                          parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                        }
                        if (parts[1]?.length > 2) {
                          parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                        }

                        // Combine the parts back into a valid format
                        inputValue =
                          parts.length > 1
                            ? parts[0] + "." + (parts[1] || "")
                            : parts[0];

                        // Update state
                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_out = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_time_out",
                          inputValue,
                          index
                        );
                      }}
                    />

                    {/* <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_time_out}
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (!inputValue) {
                          inputValue = "0";
                        }

                        if (inputValue.length > 2) {
                          // Convert last two digits into decimals
                          let intPart = inputValue.slice(0, -2); // Take all but last 2 digits
                          let decimalPart = inputValue.slice(-2); // Take last 2 digits
                          inputValue = `${intPart}.${decimalPart}`; // Combine into float format
                        } else {
                          inputValue = parseInt(inputValue, 10).toString(); // Keep as an integer
                        }

                        const updatedData = [...formData];
                        updatedData[index].x_studio_time_out = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_time_out",
                          inputValue,
                          index
                        );
                      }}
                    /> */}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      title="Breakdown time"
                      disabled={isDisabled}
                      min={0}
                      className="text-[10px] w-full border-none"
                      type="number"
                      value={dayData.x_studio_breakdown_time}
                      step="0.01"
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_breakdown_time = parseFloat(
                          e.target.value
                        );
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_breakdown_time",
                          parseFloat(e.target.value),
                          index
                        );
                      }}
                    /> */}
                    <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none bg-transparent"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_breakdown_time}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove any non-numeric characters except the decimal point
                        inputValue = inputValue.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is allowed
                        let parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                        }

                        // Limit to 2 digits before and 2 digits after the decimal
                        if (parts[0].length > 2) {
                          parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                        }
                        if (parts[1]?.length > 2) {
                          parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                        }

                        // Combine the parts back into a valid format
                        inputValue =
                          parts.length > 1
                            ? parts[0] + "." + (parts[1] || "")
                            : parts[0];

                        // Update state
                        const updatedData = [...formData];
                        updatedData[index].x_studio_breakdown_time = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_breakdown_time",
                          inputValue,
                          index
                        );
                      }}
                    />
                    {/* <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_breakdown_time}
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (!inputValue) {
                          inputValue = "0";
                        }

                        if (inputValue.length > 2) {
                          // Convert last two digits into decimals
                          let intPart = inputValue.slice(0, -2); // Take all but last 2 digits
                          let decimalPart = inputValue.slice(-2); // Take last 2 digits
                          inputValue = `${intPart}.${decimalPart}`; // Combine into float format
                        } else {
                          inputValue = parseInt(inputValue, 10).toString(); // Keep as an integer
                        }

                        const updatedData = [...formData];
                        updatedData[index].x_studio_breakdown_time = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_breakdown_time",
                          inputValue,
                          index
                        );
                      }}
                    /> */}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      title="Overtime "
                      disabled={isDisabled}
                      step="0.01"
                      value={dayData.x_studio_overtime}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_overtime = parseFloat(
                          e.target.value
                        );
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_overtime",
                          parseFloat(e.target.value),
                          index
                        );
                      }}
                    /> */}
                    <input
                      title="Overtime"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none bg-transparent"
                      type="number"
                      min={0}
                      step="0.01"
                      value={dayData.x_studio_overtime}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove any non-numeric characters except the decimal point
                        inputValue = inputValue.replace(/[^0-9.]/g, "");

                        // Ensure only one decimal point is allowed
                        let parts = inputValue.split(".");
                        if (parts.length > 2) {
                          inputValue = parts[0] + "." + parts[1]; // Keep only the first decimal point
                        }

                        // Limit to 2 digits before and 2 digits after the decimal
                        if (parts[0].length > 2) {
                          parts[0] = parts[0].slice(0, 2); // Keep only first 2 digits before decimal
                        }
                        if (parts[1]?.length > 2) {
                          parts[1] = parts[1].slice(0, 2); // Keep only first 2 digits after decimal
                        }

                        // Combine the parts back into a valid format
                        inputValue =
                          parts.length > 1
                            ? parts[0] + "." + (parts[1] || "")
                            : parts[0];

                        // Update state
                        const updatedData = [...formData];
                        updatedData[index].x_studio_overtime = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_overtime",
                          inputValue,
                          index
                        );
                      }}
                    />
                    {/* <input
                      title="Time in"
                      disabled={isDisabled}
                      className="text-[10px] w-full border-none"
                      type="number"
                      min={0}
                      maxLength={5}
                      step="0.01"
                      value={dayData.x_studio_overtime}
                      onChange={(e) => {
                        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (!inputValue) {
                          inputValue = "0";
                        }

                        if (inputValue.length > 2) {
                          // Convert last two digits into decimals
                          let intPart = inputValue.slice(0, -2); // Take all but last 2 digits
                          let decimalPart = inputValue.slice(-2); // Take last 2 digits
                          inputValue = `${intPart}.${decimalPart}`; // Combine into float format
                        } else {
                          inputValue = parseInt(inputValue, 10).toString(); // Keep as an integer
                        }

                        const updatedData = [...formData];
                        updatedData[index].x_studio_overtime = inputValue;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_overtime",
                          inputValue,
                          index
                        );
                      }}
                    /> */}
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    {/* <input
                      disabled={isDisabled}
                      title="Operator Name"
                      className="text-[10px] w-full border-none"
                      type="text"
                      value={dayData.x_studio_operator}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_operator = e.target.value;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_operator",
                          e.target.value,
                          index
                        );
                      }}
                    /> */}

                    <select
                      disabled={isDisabled}
                      title="Operator Name"
                      className="text-[10px] w-full border-none bg-transparent"
                      type="text"
                      value={dayData.x_studio_operator}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_operator = e.target.value;
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_operator",
                          e.target.value,
                          index
                        );
                      }}
                    >
                      <option value="">Select Operator</option>
                      {operatorData?.map((operator) => (
                        <option
                          key={operator.id}
                          value={operator.operator_name}
                        >
                          {operator.operator_name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td
                    className={
                      isDisabled ? "border border-red-1 text-red-1" : "border"
                    }
                  >
                    <input
                      disabled={isDisabled}
                      min={0}
                      title="Reimbursements"
                      className="text-[10px] w-full border-none bg-transparent"
                      type="number"
                      value={dayData.x_studio_reimbursements}
                      onChange={(e) => {
                        const updatedData = [...formData];
                        updatedData[index].x_studio_reimbursements = parseFloat(
                          e.target.value
                        );
                        setFormData(updatedData);
                        handleFieldChange(
                          dayData.x_studio_date,
                          "x_studio_reimbursements",
                          parseFloat(e.target.value),
                          index
                        );
                      }}
                    />



                  </td>

                  <td className="border p-2">
                    {index > 0 && (
                      <button
                        title="Copy the previous day entered data"
                        onClick={() => copyPreviousRow(index)}
                        className="bg-green-500 text-white px-2 py-1 rounded-md text-xs hover:bg-green-600 transition"
                      >
                        Copy
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Save Button */}
        <div className="flex items-center gap-2 mt-2">
          <button
            title="back to timesheet"
            onClick={() => {
              setEditable(false);
            }}
            className=" text-black bg-white buttons"
          >
            Cancel
          </button>
          <button

            onClick={handleSubmit}
            title="Save changes to timesheet"
            className="buttons bg-green-2 text-white"
            disabled={loader ? true : false}
          >
            {loader ? <CircularProgress size={18} sx={{ color: "#fff" }} /> : 'Save'}
          </button>
        </div>
      </div>

      {/* Log Table */}
    </div>
  );
};

export default EditableLogTable;

// export default EditableLogTable;
