import React, { useState } from "react";

function ConfigDetails({
  assetConfigDetails,
  setAssetConfigDetails,
  updateAssetConfig,
  setUpdateAssetConfig,
}) {

  return (
    <>
      <div className="my-5  ">
        <button className="text-[#5a5959] px-4 py-2 underline text-xs">
          Configuration Details
        </button>
        <div className="border  p-10 mt-2 text-xs gap-4 bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
          {/* Used or New */}
          <div className="flex-1 w-[200px] ">
            <label className="font-bold">Used/New</label>
            <select
              type="text"
              value={assetConfigDetails?.used_or_new}
              placeholder="used or new"
              className="text-dark-6 focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  used_or_new: e.target.value,
                });
                {
                  setUpdateAssetConfig({
                    ...updateAssetConfig,
                    "used_or_new": e.target.value,
                  });
                }
              }}
            >
              <option value="used">Used</option>
              <option value="new">New</option>
            </select>
          </div>
          {/* Ansi/ce */}
          <div className="flex-1 w-[200px] ">
            <label className="font-bold">ANSI/CE</label>
            <select
              type="text"
              value={assetConfigDetails?.ansi_or_new}
              placeholder="ansi or new"
              className="text-dark-6 focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) => {
                setAssetConfigDetails({
                  ...assetConfigDetails,
                  ansi_or_new: e.target.value,
                });
                
                setUpdateAssetConfig({
                    ...updateAssetConfig,
                    "ansi_or_new": e.target.value,
                  });
                
              }}
            >
              <option value="used">ANSI</option>
              <option value="new">CE</option>
            </select>
          </div>
          {/* Ship Owner */}
          <div className="flex-1 w-[200px] ">
            <label className="font-bold">Machine Ownership Ship Type</label>
            <select
              type="text"
              value={assetConfigDetails?.machine_ownership_type}
              className="text-dark-6 focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) =>
                {setAssetConfigDetails({
                  ...assetConfigDetails,
                  machine_ownership_type: e.target.value,
                })
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  "machine_ownership_type": e.target.value,
                });
              }
              }
            >
              <option value="rental">Rental</option>
              <option value="sands">S and S</option>
              <option value="retail">Retail</option>
              <option value="sold">Sold</option>
            </select>
          </div>
          {/* DIESEL/BATTERY */}
          <div className="flex-1 w-[200px] ">
            <label className="font-bold">Engine Type</label>
            <select
              type="text"
              value={assetConfigDetails?.battery_type}
              className="text-dark-6 focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) =>
                {setAssetConfigDetails({
                  ...assetConfigDetails,
                  battery_type: e.target.value,
                })
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  "battery_type": e.target.value,
                });
              }
              }
            >
              <option value="Diesel">Diesel</option>
              <option value="battery">Battery</option>
            </select>
          </div>
          {/* Engine Sr No */}
          <div className="flex-1 w-[200px] ">
          <label className="font-bold">Engine Serial No</label>
          <input
            type="text"
            value={assetConfigDetails?.engine_serial_no}
            placeholder="Make"
           className="text-dark-6 focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
            onChange={(e) =>
             { setAssetConfigDetails({
                ...assetConfigDetails,
                engine_serial_no: e.target.value,
              });
            {setUpdateAssetConfig({
              ...updateAssetConfig, 'engine_serial_no': e.target.value
            })}
            }
            }
          />
        </div>
          {/* 2WD / 4WD */}
          <div className="flex-1 w-[200px] ">
            <label className="font-bold">2WD / 4WD</label>
            <select
              type="text"
              value={assetConfigDetails.two_or_four_wd}
              className="text-dark-6 focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) =>
                {setAssetConfigDetails({
                  ...assetConfigDetails,
                  two_or_four_wd: e.target.value,
                })
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  "two_or_four_wd": e.target.value,
                });
              }
              }
            >
              <option value="2WD">2WD</option>
              <option value="4WD">4WD</option>
            </select>
          </div>
          {/* Others */}
          <div className="flex-1 w-[200px] ">
            <label className="font-bold">Accessories (if any)</label>
            <input
              type="text"
              value={assetConfigDetails?.accessories}
              placeholder="Accessories"
              className="text-dark-6 focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) =>
                {setAssetConfigDetails({
                  ...assetConfigDetails,
                  accessories: e.target.value,
                })
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  "accessories": e.target.value,
                });
              }
              }
            />
          </div>
          {/* Tires */}
          <div className="flex-1 w-[200px] ">
            <label className="font-bold">Tires</label>
            <select
              type="text"
              value={assetConfigDetails.tyres}
              className="text-dark-6 focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
              onChange={(e) =>
                {setAssetConfigDetails({
                  ...assetConfigDetails,
                  tyres: e.target.value,
                })
                setUpdateAssetConfig({
                  ...updateAssetConfig,
                  "tyres": e.target.value,
                });
              }
              }
            >
              <option> Airfilled </option>
              <option> Foam </option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfigDetails;
