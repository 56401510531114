import React, { Fragment, useEffect, useState } from 'react'
import { setBaseUrl } from '../../../config'
import axios from 'axios'
import { formatDate } from './Tabs/FormatDate'

const TimeLineDuration = ({devId, setShowMachine}) => {
  const [timeData, setTimeData] = useState(null)
  const [openTab, setOpenTab] = useState(null) // Track which row is open

  const fetchTimeLineData = async () => {
      try {
          const response = await axios.get(`${setBaseUrl}/asset/get-device-timeline`, 
               {
                  headers: {
                      "x-access-tokens": sessionStorage.getItem("token"),
                      'device-id': devId,
                  }
              }
          )

          if(!response.status === 200) {
              throw new Error('Network response was not ok')
          }
          const items = response.data
          setTimeData(items)
      } catch (error) {
          console.error(error)
      }
  }

  useEffect(() => {    
      fetchTimeLineData()
  }, [])

  const header = [
    "Date", "Start Time", "End Time", "Total Duration", "Engine Runtime(hrs)", "Total Instance"
  ]

  return (
    <div className='mb-16'>
         <div className=" w-full  mt-1 mb-12">
          
            <h1 className="text-xs font-bold py-2 mx-2 flex justify-between">Device Timeline Details
              <button className="bg-[#006DB6] text-white h-7 w-[80px] " onClick={() => {setShowMachine(false)}}>Close</button>
            </h1>

            <div className="border ">
            {timeData ? (
                <>
                 <table className='text-center text-xs border-separate border-spacing-y-0 w-full relative'>
                    <thead className='h-10 sticky bg-[#efefef] dark:bg-gray-700'>
                        <tr>
                          {header.map((item) => (
                              <td key={item}>{item}</td>      
                          ))}
                        </tr>
                    </thead>

                    <tbody>
                      {timeData && Object.keys(timeData).reverse().map((date) => {
                          let earliestStartTime = null;
                          let latestEndTime = null;
                          let totalDuration = [0, 0, 0]; // [hours, minutes, seconds]
                          let numberOfEntries = 0; // Number of times we are getting data in a day
               
                          timeData[date].forEach((item) => {
                              numberOfEntries += 1; // Increment number of entries for each record
               
                              const start = item[1].start_time.split(" ")[1];
                              const end = item[1].end_time.split(" ")[1];
               
                              // Check and set earliest start time
                              if (!earliestStartTime || start < earliestStartTime) {
                                  earliestStartTime = start;
                              }
               
                              // Check and set latest end time
                              if (!latestEndTime || end > latestEndTime) {
                                  latestEndTime = end;
                              }
               
                              // Calculate the total duration
                              const durationTime = item[0].split(":");
                              totalDuration[0] += parseInt(durationTime[0]); // hours
                              totalDuration[1] += parseInt(durationTime[1]); // minutes
                              totalDuration[2] += parseFloat(durationTime[2]); // seconds
                          });
               
                          // Normalize the duration (convert seconds to minutes, minutes to hours)
                          totalDuration[1] += Math.floor(totalDuration[2] / 60);
                          totalDuration[2] = totalDuration[2] % 60;
                          totalDuration[0] += Math.floor(totalDuration[1] / 60);
                          totalDuration[1] = totalDuration[1] % 60;
               
                          const formattedStart = earliestStartTime.split(":").slice(0, 2).join(":");
                          const formattedEnd = latestEndTime.split(":").slice(0, 2).join(":");
                          const formattedDuration = `${totalDuration[0]}:${totalDuration[1].toString().padStart(2, '0')}:${Math.floor(totalDuration[2]).toString().padStart(2, '0')}`;
               
                          // Calculate total working hours (earliest start time to latest end time)
                          const totalWorkingHours = (() => {
                              const start = new Date(`1970-01-01T${earliestStartTime}Z`);
                              const end = new Date(`1970-01-01T${latestEndTime}Z`);
                              const diffInMs = end - start;
                              const diffInHours = Math.floor(diffInMs / 1000 / 60 / 60);
                              const diffInMinutes = Math.floor((diffInMs / 1000 / 60) % 60);
                              if (diffInHours > 0) {
                                  return `${diffInHours}:${diffInMinutes.toString().padStart(2, '0')} hrs`;
                              } else {
                                  return `${diffInMinutes.toString().padStart(2, '0')} min`;
                              }
                          })();
    
                          return (
                            <Fragment key={date}>
                                <tr className="h-8 items-center transition-all duration-500 ease-in-out" onClick={() => setOpenTab(openTab === date ? null : date)}>
                                    <td className="border-b">{formatDate(date)}</td>
                                    <td className="border-b">{formattedStart}</td>
                                    <td className="border-b">{formattedEnd}</td>
                                    <td className="border-b">{totalWorkingHours}</td>
                                    <td className="border-b">{formattedDuration}</td>
                                    <td className="border-b">{numberOfEntries}</td>
                                </tr>

                                {openTab === date && (
                                    <>
                                    <button className="items-center rounded flex justify-center bg-light-1 text-white text-xs my-2 ml-2 h-6 w-[50px]" onClick={()=> setOpenTab(null)}>Close</button>
                                    <tr className={`transition-all duration-500 ease-in-out   `}>
                                        <td colSpan={6} className="p-4 bg-[#EEE]">
                                            <table className='text-center text-xs border-separate border-spacing-y-0 w-full border'>
                                                <thead className='h-10 sticky bg-[#cccaca] dark:bg-gray-700'>
                                                    <tr>
                                                        <td className=''>Start Date</td>
                                                        <td>Start Time</td>
                                                        <td>End Time</td>
                                                        <td className=''>Duration</td>
                                                    </tr>
                                                </thead>
                                                <tbody className='bg-white'>
                                                    {timeData[date]?.map((item, index) => {
                                                        let start = item[1].start_time.split(" ")[1];
                                                        let end = item[1].end_time.split(" ")[1];
                                                        const durationTime = item[0].split(":");

                                                        const formattedStart = start.split(":").slice(0, 2).join(":");
                                                        const formattedEnd = end.split(":").slice(0, 2).join(":");
                                                        const formattedDuration = `${durationTime[0]}:${durationTime[1]}:${durationTime[2].split('.')[0]}`;

                                                        return (
                                                            <tr key={`${date}-${index}`} className='h-8 items-center'>
                                                                <td className="border-b">{formatDate(date)}</td>
                                                                <td className="border-b">{formattedStart}</td>
                                                                <td className="border-b">{formattedEnd}</td>
                                                                <td className="border-b">{formattedDuration}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </>
                                )}
                            </Fragment>
                          );
                        })}
                    </tbody>
                 </table>
                </> 
            ) : (
                <div className="text-center mt-10 text-xs">Data is not generated!!</div>
            )}
            </div>
        </div>
    </div>
  )
}

export default TimeLineDuration
