const Data = {
    assetNumber: "Automated asset WHI@",
    complainTitle: "Technical Issue",
    description: "Sample Description of the complaint",
    complainDate: "01-01-2019",
    location: "Site A",
    serviceRecords: [
      {
        servicePerson: "John Abraham",
        lastDate: "01-01-2019",
        complaint: "Sample Description of the complaint",
        servicePersonComment: "Service person described what are the problems",
        installedParts: [
          { partName: "Screw", quantity: 2, price: "₹200/-" },
          { partName: "Bearings", quantity: 1, price: "₹500/-" },
          { partName: "Boults", quantity: 5, price: "₹700/-" },
        ],
        removedParts: [
          { partName: "Screw", quantity: 2, price: "₹200/-" },
          { partName: "Bearings", quantity: 1, price: "₹500/-" },
          { partName: "Boults", quantity: 5, price: "₹700/-" },
        ],
      },
   
    ],
  };
  
  export default Data;
  