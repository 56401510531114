
import React from "react";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../Context/PermissionsContext";
import { useContext, useEffect, useState } from "react";
import useAuthorities from "../../utils/useAuthorities";
import { IoMdAdd } from "react-icons/io";
import { Button, Tooltip } from "@mui/material";

function NewAssetBtn({ tabName }) {
  // const [perms, setPerms] = useContext(PermissionContext);
  const [, ] = useContext(PermissionContext);
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const {
    createAssetPerm,
    createLeasePerm,
    createMaintenancePerm,
    createOperatorPerm,
  } = useAuthorities();

  useEffect(() => {
    getButtonText();
  });

  const getButtonText = () => {
    switch (tabName) {
      case "asset":
        setButtonText("Asset");
        setButtonLink("/assets/new-asset");
        if (createAssetPerm) {
          setIsDisabled(false);
        }
        break;
      case "maintenance":
        setButtonText("Complain");
        setButtonLink("/maintenance/new-maintenance");
        if (createMaintenancePerm) {
          setIsDisabled(false);
        }
        break;
      case "lease":
        setButtonText("Lease");
        setButtonLink("/lease/new-lease");
        if (createLeasePerm) {
          setIsDisabled(false);
        }
        break;
      case "operator":
        setButtonText("Operator");
        setButtonLink("/operators/new-operator");
        if (createOperatorPerm) {
          setIsDisabled(false);
        }
        break;
      default:
        setButtonText("Asset");
        break;
    }
  };

  const navigate = useNavigate();
  return (
  

    <button
    onClick={() => navigate(buttonLink)}
 title={`Add New ${buttonText}`}
    className={` bg-green-2 hover:bg-green-3 text-white flex-1 py-2 px-4 rounded-lg font-medium  transition-colors border min-w-max
      ${isDisabled ? 'bg-gray-400' : ''}
    `}
    disabled={isDisabled}
    >
    Create {buttonText}
  </button>

  );
}

export default NewAssetBtn;


