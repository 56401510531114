// export default NewTable;
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate } from "react-router-dom";
import { FaWifi, FaTag } from "react-icons/fa";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { Button } from "@mui/material";

const NewTable = ({
  handleExport,
  assetDetails,
  setFilteredData,
  filteredData,
  selectedRows,
  setSelectedRows,
}) => {
  const [filter, setFilter] = useState("All Assets"); // Default filter
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Search query

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const handleClearFilter = () => {
    setFilter("All Assets"); // Reset filter to default
    setFilteredData(assetDetails); // Show all data
    setSearchQuery(""); // Clear search
  };

  const selected = [
    { title: "All Assets", label: "all" },
    { title: "Active", label: "active" },
    { title: "Free", label: "free" },
    { title: "Expired", label: "expired" },
    { title: "Tracker Installed", label: "tracker_installed" },
    { title: "Sold Items", label: "is_sold" },
  ];

  useEffect(() => {
    applyFilters(); // Apply initial filter and search
  }, [assetDetails, filter, searchQuery]);

  const applyFilters = () => {
    let data = [...assetDetails];

    // Apply filter
    if (filter !== "All Assets") {
      if (filter === "Tracker Installed") {
        data = data.filter(
          (item) => item.device_no && item.device_no.trim() !== ""
        );
      } else if (filter === "Sold Items") {
        data = data.filter((item) => item.is_sold === true);
      } else if (filter === "Active") {
        data = data.filter(
          (item) =>
            item.lease_status === "active" || 
            (item.lease_status && item.lease_status.toLowerCase().includes("expiring"))
        );
        console.log(data)
      } else if (filter === "Free") {
        data = data.filter(
          (item) =>
            item.lease_status === "inactive" ||
            item.lease_status === "never assigned"
        );
      } else if (filter === "Expired") {
        data = data.filter((item) => item.lease_status === "expired");
      }
    }
    
    

    // Apply search
    if (searchQuery.trim()) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      data = data.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(lowerCaseQuery)
        )
      );
    }

    setFilteredData(data);
    setPage(0); // Reset to the first page
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const navigate= useNavigate()


  
  const getLeaseStatus = (value) => {
    if (value === "inactive" || value === "never assigned") {
      return { status: "Free", color: "#9e9e9e" }; // Gray
    } else if (value === "expired") {
      return { status: "Expired", color: "#818181" }; // Dark Red
    } else if (value === "active" || value.toLowerCase().includes("expiring")) {
      return { status: "Active", color: "green" }; // Green
    }
    return { status: "Unknown", color: "#000" }; // Default case
  };
  
  const columns = [
    {
      field: "asset_no",
      headerName: "Asset No",
      width: 130,
      renderCell: (params) => {
        const { row } = params;
        let color = "#000"; // Default color
        let icon = null; // Default icon
  
        if (row.device_no) {
          color = "green"; // Tracker Installed - Green
          icon = <FaWifi style={{ marginLeft: 5, color: "green" }} />;
        }
        if (row.is_sold === true) {
          color = "red"; // Sold - Red
          icon = <FaTag style={{ marginLeft: 5, color: "red" }} />;
        }
  
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ color, fontWeight: "bold" }}>{row.asset_no}</span>
            {icon}
          </div>
        );
      },
    },
    { field: "make", headerName: "Make", width: 130 },
    { field: "model", headerName: "Model", width: 130 },
    { field: "yom", headerName: "YOM", width: 130 },
    { field: "category", headerName: "Category", width: 130 },
    { field: "serial_no", headerName: "Serial No", width: 130 },
    {
      field: "lease_status",
      headerName: "Lease Status",
      width: 140,
      renderCell: (params) => {
        const { value } = params;
        const { status, color } = getLeaseStatus(value);
  
        return (
          <button
            style={{
              color: color,
              fontWeight: "bold",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
            className={`buttons text-xs border`}
          >
            {capitalizeFirstLetter(status)}
          </button>
        );
      },
    },
    {
      field: "details",
      headerName: "See More",
      width: 130,
      renderCell: (params) => (
        <Button
          onClick={() => navigate(`/assets/assetDetails/${params.row.id}`)}
          variant="contained"
          sx={{
            height: "32px",
            width: "96px",
            fontSize: 12,
          }}
        >
          See Details
        </Button>
      ),
    },
  ];
  
  

  return (
    <div className="fixed right-[1%] left-[5%] bg-white">

    <Paper
      style={{
        padding: 10,
        height: '79vh',
        width: "100%",
        border: "hidden",

      }}
      elevation={0}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-2 justify-center items-center">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearch}
            placeholder="Search..."
            style={{
              height: 30,
              width: 200,
              padding: "5px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: 12,
            }}
          />
          <FormControl
            fullWidth
            sx={{
              "& .MuiInputLabel-root": { fontSize: "12px" },
              "& .MuiSelect-select": { fontSize: "12px" },
              "& .MuiMenuItem-root": { fontSize: "12px" },
            }}
          >
            <Select
              style={{
                height: 30,
                width: 300,
                fontSize: 10,
              }}
              value={filter}
              onChange={handleFilterChange}
            >
              {selected.map((option) => (
                <MenuItem key={option.title} value={option.title}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="flex gap-2 items-center">
        <TablePagination
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 30, 50, filteredData.length]}
          labelRowsPerPage="Rows per page:"
        />
          <NewAssetBtn tabName="asset" />
          <button
            variant="contained"
            onClick={handleExport}
            className="border text-black buttons bg-white"
          >
            Export CSV
          </button>
        </div>
      </div>
      <DataGrid
        rows={filteredData.slice(page * pageSize, page * pageSize + pageSize)}
        columns={columns}
        checkboxSelection
        pagination={false} // Disable built-in pagination
        sx={{
          "& .MuiDataGrid-root": { fontSize: "12px", height: 30 }, // Set font size for the entire grid
          "& .MuiDataGrid-columnHeaders": {
            fontSize: "14px", // Header font size
            backgroundColor: "#000", // Header background color
            fontWeight: "bold", // Bold text for headers
            color: "#006DB6", // Header text color
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold", // Ensures text in header is bold
            height: 20,
          },
          "& .MuiDataGrid-cell": { fontSize: "12px" }, // Set font size for cell text
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          },
        }}
        onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
      />
    </Paper>
    </div>
  );
};

export default NewTable;
