import React from 'react'
import { capitalizeFirstLetter } from './FormatDate'
import Loading from '../../../Loading'

const AssetHeader = ({assetDetails, loading}) => {

  return (
    <>
    <div className=" items-center text-justify text-xs w-full  gap-5 mt-2 rounded pb-1 flex justify-between max-md:flex-col  ">
      {/* Frame 1 */}
      <div className="bg-white border py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
        <div className="py-2">
          <span className="text-gray-500">Machine No. - Model No.</span>
          <span className="block font-semibold text-xs ">
         {/* {loading? <Loading title="header"/> :<></> } */}
         {assetDetails?.asset_no} - {assetDetails?.model} 
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Make - Serial No.</span>
          <span className="block font-semibold text-xs ">
            {/* {loading ? <Loading title="header"/> : <></> } */}
            {assetDetails?.make} - {assetDetails?.serial_no}
            
          </span>
        </div>
      </div>

      <div className="bg-white border py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
        <div className="py-2">
          <span className="text-gray-500"> Model No.</span>
          <span className="block font-semibold text-xs">
           {assetDetails?.model} 
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Serial No.</span>
          <span className="block font-semibold text-xs">
           {assetDetails?.serial_no}
          </span>
        </div>
      </div>

      {/* Frame 2 */}
      <div className="bg-white border py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
      
      
        <div className="py-2">
          <span className="text-gray-500">Category</span>
          <span className="block font-semibold text-xs">
            {assetDetails?.category}
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Year of Manufacturing</span>
          <span className="block font-semibold text-xs">
            {assetDetails?.yom}
          </span>
        </div>
      </div>



      {/* Frame 4 */}
      <div className="bg-white border py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
      
      {/* <div className="py-2">
          <span className="text-gray-500">Location of Device</span>
          <span className="block font-semibold text-xs">
            {assetDetails?.site_location}
          </span>
        </div> */}
         <div className="py-2">
          <span className="text-gray-500">Lease Status</span>
          <span
            className={`block font-semibold text-xs ${
              assetDetails?.lease_status === "inactive"
                ? "text-slate-600"
                : assetDetails?.lease_status === 'active' ?  "text-green-500" :
                assetDetails?.lease_status === 'expired' ? 'text-red-600': 'text-blue-600' 
            }`}
          >
            {capitalizeFirstLetter(assetDetails?.lease_status)}
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Capacity</span>
          <span className="block font-semibold text-xs">
            {assetDetails?.capacity}kg-{assetDetails?.hieght_mechine}ft
          </span>
        </div>
       
      </div>

     


      {/* Frame 7 */}
      <div className="bg-white border py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
      
      
         <div className="py-2">
          <span className="text-gray-500">Tracker</span>
            <span className={`block font-semibold text-xs ${
                assetDetails?.device_no &&  assetDetails?.device_no !== " " ? "text-blue-600" : "text-gray-500"
              }`}>
          { assetDetails?.device_no  &&  assetDetails?.device_no !== " " ? "Installed" : "Not Installed"}
          </span>
        </div>
        <div className="py-2">
          <span className="text-gray-500">Ratings</span>
          <span className="block font-bold text-xs text-red-600 ">
           {assetDetails?.rating? `${assetDetails?.rating}`: 5}/5
          </span>
        </div>
      </div>
       
    </div>
  </>
  )
}

export default AssetHeader
