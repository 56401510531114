// import React, { useEffect, useState } from 'react';
// import { setBaseUrl } from '../../../config';
// import { formatDate } from '../../../customFunctions/FormatDate';

// const MaintenanceLog = ({ id }) => {
//     const [logData, setLogData] = useState([]);

//     const getUserLogData = async () => {
//         try {
//             const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
//                 method: "GET",
//                 headers: {
//                     "Content-Type": "application/json",
//                     "x-access-tokens": sessionStorage.getItem("token"),
//                     "maintenance_id": id,
//                 },
//             });
//             const data = await res.json();
//             if (res.ok) {
//                 // Sort the data by action_time (latest to oldest)
//                 const sortedData = data.sort((a, b) => {
//                     const dateA = new Date(a.action_time);
//                     const dateB = new Date(b.action_time);
//                     return dateB - dateA; // Sort in descending order
//                 });
//                 setLogData(sortedData);
//             }
//         } catch (error) {
//             console.error("Error fetching log data:", error);
//         }
//     };

//     useEffect(() => {
//         getUserLogData();
//     }, [id]); // Re-fetch data when `id` changes

//     return (
//         <div className="p-4 text-xs">
//         <h2 className="text-base font-bold mb-4">Journey Details</h2>
//         <div className="overflow-x-auto">
//             <table className="w-full border-collapse border border-gray-300">
//                 <thead className="bg-gray-100">
//                     <tr>
//                         <th className="border border-gray-300 p-2 text-left">Date</th>
//                         <th className="border border-gray-300 p-2 text-left">Action Type</th>
//                         <th className="border border-gray-300 p-2 text-left">Acted By</th>
//                         <th className="border border-gray-300 p-2 text-left">Description</th>
//                         <th className="border border-gray-300 p-2 text-left">Complaint</th>
//                         <th className="border border-gray-300 p-2 text-left">Complaint Type</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {logData.map((log, index) => {
//                         const fields = JSON.parse(log.fields_are || '{}'); // Parse the fields_are JSON string
//                         return (
//                             <tr key={index} className="border border-gray-300">
//                                 <td className="border border-gray-300 p-2">
//                                     {formatDate(log.action_time)} {/* Format the date */}
//                                 </td>
//                                 <td className="border border-gray-300 p-2">
//                                     <span className="text-xs">
//                                         {log.action_type}
//                                     </span>
//                                 </td>
//                                 <td className="border border-gray-300 p-2">
//                                     {log.act_by}
//                                 </td>
//                                 <td className="border border-gray-300 p-2">
//                                     {fields.description || "No description"}
//                                 </td>
//                                 <td className="border border-gray-300 p-2">
//                                     {fields.complaint || "No complaint"}
//                                 </td>
//                                 <td className="border border-gray-300 p-2">
//                                     {fields.complaint_type || "No type"}
//                                 </td>
//                             </tr>
//                         );
//                     })}
//                 </tbody>
//             </table>
//         </div>
//     </div>
//     );
// };

// export default MaintenanceLog;


import React, { useEffect, useRef, useState } from "react";


import * as XLSX from "xlsx";
import moment from "moment";
import { setBaseUrl } from "../../../config";
import { capitalizeFirstLetter, formatTime } from "../../../customFunctions/FormatDate";


const MaintenanceLog = ({ id }) => {
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fieldNameMapping = {
    id: "ID",
    complaint: "Breakdown Title",
    complaint_type: 'Breakdown Type',
    types: "Service Category",
    status: "Status",
    description: "Breakdown Description",
    ready_date: "Deadline",
    priority: "Priority",
    is_accepeted: "Accept Request or Reject",
    maintenance_id: "Maintenance ID"
  };

  const hasLogFetch = useRef(false)

  const getUserLogData = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "maintenance_id": id,
        },
      });
      const data = await res.json();
      setLogData(data);
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!hasLogFetch.current){
      hasLogFetch.current = true;
      getUserLogData();
    }
  }, []);




  
  const parseFields = (fields) => {
    try {
      const parsedFields = JSON.parse(fields);
      return Object.entries(parsedFields)
        .filter(([key]) => key !== "id") // Exclude the `id` field
        .map(([key, value]) => ({
          fieldName: fieldNameMapping[key] || key,
          fieldValue: typeof value === "object" ? JSON.stringify(value) : value,
        }));
    } catch (error) {
      console.error("Error parsing fields:", error);
      return [];
    }
  };



    const handleExportExcel = () => {
      const formattedData = logData.map((data) => {
        const fields = parseFields(data.fields_are || "{}");
        const fieldDetails = fields.map(
          (field) => `${field.fieldName}: ${JSON.stringify(field.fieldValue)}`
        );
  
        return {
          Time: data.action_time,
          "Act By": capitalizeFirstLetter(data.act_by),
          "Action Type": capitalizeFirstLetter(data.action_type),
          "Fields Changed": fields.map((field) => field.fieldName).join(", "),
          "Updated Data": fieldDetails.join("\n"),
        };
      });
  
      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "User Log Data");
      XLSX.writeFile(workbook, "Lease Logs.xlsx");
    };
  

  return (
    <div className="min-h-[50vh] text-xs ">
      <div className="max-w-full mx-auto px-2">
        <div className="mt-3 bg-white shadow-md p-2">
        <span className="">Journey Details</span>
        <div className="flex justify-end mb-4">
            <button
              className="bg-green-2 text-white px-4 py-2 rounded hover:bg-green-3"
              onClick={handleExportExcel}
            >
              Export to Excel
            </button>
          </div>

          
          {loading ? (
            <div className="flex justify-center items-center py-10">
              <div className="animate-spin rounded-full h-8 w-8"></div>
            </div>
          ) : (
            <div className="overflow-x-auto text-[10px] border">
              <table className="min-w-full border-collapse divide-y">
                <thead className="divide-y sticky top-0 bg-light-4">
                  <tr>
                  <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Date
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Time
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Act By
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Action Type
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Fields Changed
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Updated Data
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {logData.length > 0 ? (
                    logData.map((data, index) => {
                      const fields = parseFields(data.fields_are || "{}");
                      return (
                        <tr
                          key={index}
                          className="hover:bg-gray-100 transition  border duration-200 ease-in-out"
                        >
                          <td className="py-3 px-4 text-dark-6 border-b">
                          {moment(data?.action_time).format("DD-MM-YYYY ")}

                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {moment(data?.action_time).format("HH:mm")}
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {capitalizeFirstLetter(data.act_by)}
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {capitalizeFirstLetter(data.action_type)}
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            <ul className="list-disc ml-4">
                              {fields.map((field, i) => (
                                <li key={i}>{field.fieldName}</li>
                              ))}
                            </ul>
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            <ul className="list-disc ml-4">
                              {fields.map((field, i) => (
                                <li key={i}>
                                  <strong>{field.fieldName}:</strong> {field.fieldValue}
                                </li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        className="py-4 px-4 text-center text-dark-6"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaintenanceLog;
