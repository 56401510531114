import React from 'react';

const PhotoDataTab = ({ imageArray }) => {
  return (
    <div className='pb-12 text-xs'>
     
      
      {imageArray && imageArray.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {imageArray.map((image, index) => (
            <div key={index} className="border rounded-lg p-2">
              <img src={image.image_uri} alt={`Asset ${image.id}`} className="w-full h-auto rounded" />
             
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">{ "No images available"}</p>
      )}
    </div>
  );
};

export default PhotoDataTab;
