import React from 'react'
import { MapContainer, TileLayer } from "react-leaflet";
import LocationMarker from './LocationMarker';

const DeviceMap = ({mapPosition, setMapPosition}) => {
  return (
    <div className=''>
        <MapContainer
        center={mapPosition}
        zoom={13}
        scrollWheelZoom={true}
        style={{height: '250px', width: '78vh', zIndex:0, objectFit:'cover' }}
        >

        <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
        <LocationMarker 
        position={mapPosition}
        setPosition={setMapPosition}
        />
        </MapContainer>
    </div>
  )
}

export default DeviceMap


