import React, { useEffect, useState } from 'react';
import { setBaseUrl } from '../../../config';
import { useNavigate } from 'react-router-dom';

const SalesPersonTable = ({title}) => {
  const [salesPerson, setSalesPerson] = useState([]);

  const salesPersonData = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-sale-persons`, {
        method: 'GET',
        headers: {
          'x-access-tokens': sessionStorage.getItem('token'),
        },
      });

      const data = await res.json();
      setSalesPerson(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    salesPersonData();
  }, []);


  const navigate = useNavigate()

  return (
    <div className="p-4">
      {/* <button className="buttons bg-green-3 text-white" title='Add new sales person' onClick={() => navigate(`/settings/add-employee`)} >Add New</button> */}
      <h2 className="text-sm font-semibold mb-4">Sales Persons</h2>
      <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 px-4 py-2">ID</th>
            <th className="border border-gray-300 px-4 py-2">Name</th>
            <th className="border border-gray-300 px-4 py-2">Email</th>
          </tr>
        </thead>
        <tbody>
          {salesPerson.length > 0 ? (
            salesPerson.sort().map((person) => (
              <tr key={person.id} className="text-center hover:bg-gray-100">
                <td className="border border-gray-300 px-4 py-2">{person.id}</td>
                <td className="border border-gray-300 px-4 py-2">{person.name}</td>
                <td className="border border-gray-300 px-4 py-2">{person.email}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="text-center py-4">No sales persons available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SalesPersonTable;
