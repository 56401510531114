import React from 'react'

const PaymentAnalysis = () => {
  return (
    <div className='text-xs'>
      Payment Analysis
    </div>
  )
}

export default PaymentAnalysis
