import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { FaSort } from "react-icons/fa";
import { useContext } from "react";
import {
  capitalizeFirstLetter,
  currency,
  customerGST,
  customerName,
  formatDate,
} from "../../Modals/AssetDetails/Tabs/FormatDate";
import { sortAssetDetails, toggleSortOrder } from "../../../utils/sortUtils";

export const CurrentItems = ({
  leaseDetails,
  setLoader,
  deleted,
  setDeleted,
  selected,
  selectedItem,
  setSelectedItem,
  selectTableData,
  sortHeader,
  sort,
  setSort,
  sortOrder,
  setSortOrder,
  filteredDataStack,
  setFilteredDataStack,
  // sortedLeaseDetails,
  // setSortedLeaseDetails,
}) => {
  const [perms] = useContext(PermissionContext);
  const navigate = useNavigate();
  const [tableAnimation, setTableAnimation] = useState(false);

  // Delete Lease
  const deleteLease = async (id) => {
    console.log(id);
    try {
      setLoader(true);
      const { data } = axios.delete(
        `${setBaseUrl}/lease/delete`,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
          data: {
            id: id,
          },
        }
      );
      setDeleted(!deleted);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoader(false);
      return;
    }
  };

  // console.log(filteredDataStack)

  const handleClick = (id) => {
    navigate("/lease/leaseDetails/" + id);
  };

  const handleUpdate = (id) => {
    navigate("/lease/leaseUpdate/" + id);
  };
  useEffect(() => {
    setTimeout(() => {
      setTableAnimation(true);
    }, 400);
  }, [deleted]);

  // Sort asset Details Function
  // sortedLeaseDetails = [...leaseDetails].sort((a, b) => {
  //   if (!sort) return 0; // No sorting if sort is null

  //   const aValue =
  //     a[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || ""; // Convert the sort string to match the object key
  //   const bValue =
  //     b[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";

  //   if (aValue < bValue) {
  //     return sortOrder === "asc" ? -1 : 1;
  //   }
  //   if (aValue > bValue) {
  //     return sortOrder === "asc" ? 1 : -1;
  //   }
  //   return 0;
  // });

  // // Set Orders
  // const toggleSortOrder = () => {
  //   setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  // };
  const sortedLeaseDetails = sortAssetDetails(leaseDetails, sort, sortOrder);

  // Creating empty Stack

  useEffect(() => {
    const filteredData = sortedLeaseDetails.filter((item) => {
      return (
        // selectedItem === "all Lease" ||
        // (`${item?.lease_status}`.indexOf(selectedItem) !== -1 &&
        //   selectedItem !== "active") ||
        //   ((item?.lease_status === "active" ||
        //     item?.lease_status.startsWith("expiring")) &&
        //     selectedItem === "active")
        selectedItem === "all Lease" ||
        (item?.lease_status.indexOf(selectedItem[1][0]) !== -1 &&
          selectedItem[1][0] !== "active") ||
        ((item?.lease_status === "active" ||
          item?.lease_status.startsWith("expiring")) &&
          selectedItem[1][0] === "active") ||
        item?.lease_status === selectedItem[1]
      );
    });

    // Storing the filtered data into our stack
    setFilteredDataStack(filteredData);
  }, [selectedItem]);
  // console.log("HEllo");
  // const filteredData = sortedLeaseDetails.filter((item) => {
  //   return (
  //     selectedItem === "all Lease" ||
  //     (`${item?.lease_status}`.indexOf(selectedItem) !== -1 && selectedItem !== "active") ||
  //     (item?.lease_status === "active" && selectedItem === "active")
  //   );
  // });

  // // Storing the filtered data into the stack
  // setFilteredDataStack(filteredData);

  // Selected items
  const [allSelected, setAllSelected] = useState(false);
  const handleSelectAllChange = (event) => {
    const newAllSelected = event.target.checked;
    setAllSelected(newAllSelected);

    const updatedItems = leaseDetails.map((item) => ({
      ...item,
      selected: newAllSelected,
    }));
    setFilteredDataStack(updatedItems);
  };

  const handleItemChange = (itemId) => {
    const updatedItems = filteredDataStack.map((item) =>
      item.id === itemId ? { ...item, selected: !item.selected } : item
    );

    setFilteredDataStack(updatedItems);

    // Update `allSelected` if all items are selected after the change
    setAllSelected(updatedItems.every((item) => item.selected));
  };
  return (
    <>
      <div className="w-full h-[74vh] my-4 overflow-hidden border hide-scrollbar max-lg:overflow-x-auto overflow-y-auto">
        {leaseDetails === undefined || leaseDetails.length === 0 ? (
          <div className="text-xs h-[50vh] flex rounded-lg justify-center items-center dark:text-white">
            No Data available...
          </div>
        ) : (
          <>
            <div>
              <table
                className={`table ${
                  tableAnimation ? "show-rows" : ""
                } border-separate border-spacing-y-0 text-center text-xs w-full relative`}
              >
                <thead className="h-10 sticky bg-[#efefef] dark:bg-gray-700">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={handleSelectAllChange}
                        className="focus:outline-none focus:ring-0 rounded-[3px] text-light-1 bg-transparent"
                      />
                    </th>
                    <th>Serial No</th>
                    {sortHeader.map((header) => (
                      <th
                        key={header}
                        className="cursor-pointer"
                        onClick={() => {
                          if (sort === header) {
                            setSortOrder(toggleSortOrder(sortOrder));
                          } else {
                            setSort(header); // Set the new sorting column
                            setSortOrder("asc"); // Default to ascending on new sort column
                          }
                        }}
                      >
                        <span className="text-center items-center flex justify-center">
                          {header}

                          {sort === header ? (
                            sortOrder === "asc" ? (
                              "▲"
                            ) : (
                              "▼"
                            )
                          ) : (
                            <FaSort />
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-900">
                  {filteredDataStack
                    .sort((a, b) => {
                      // Sorting logic for RSO No
                      if (sort === "RSO No") {
                        const extractYear = (value) => {
                          const match = value?.match(/RSO-(\d{4})-\d+/);
                          return match ? parseInt(match[1], 10) : 0; // Default to 0 if no match
                        };

                        const aValue = extractYear(a.odoo_order_id);
                        const bValue = extractYear(b.odoo_order_id);

                        return sortOrder === "asc"
                          ? aValue - bValue
                          : bValue - aValue;
                      } else if (sort === "Asset No") {
                        const aValue = a.asset_no || "";
                        const bValue = b.asset_no || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.asset_no)
                          : bValue.localeCompare(a.asset_no);
                      } else if (sort === "Customer Name") {
                        const aValue = a.customer || "";
                        const bValue = b.customer || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.customer)
                          : bValue.localeCompare(a.customer);
                      } else if (sort === "Start Date") {
                        const aValue = a.rental_start_date || "";
                        const bValue = b.rental_start_date || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.rental_start_date)
                          : bValue.localeCompare(a.rental_start_date);
                      } else if (sort === "End Date") {
                        const aValue = a.rental_end_date || "";
                        const bValue = b.rental_end_date || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.rental_end_date)
                          : bValue.localeCompare(a.rental_end_date);
                      } else if (sort === "Total Amount") {
                        const aValue = a.rental_end_date || "";
                        const bValue = b.rental_end_date || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.rental_end_date)
                          : bValue.localeCompare(a.rental_end_date);
                      } else if (sort === "Status") {
                        const aValue = a.lease_status || "";
                        const bValue = b.lease_status || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.lease_status)
                          : bValue.localeCompare(a.lease_status);
                      }
                      return 0; // No sorting if RSO No isn't selected
                    })
                    .map((item, index) => {
                      let numb = 0;
                      if (item?.lease_status?.startsWith("expiring")) {
                        // Match a number within the string "expiring in X days"
                        const match = item?.lease_status.match(/\d+/);

                        if (match) {
                          numb = parseInt(match[0], 10);
                        }
                      }
                      if (selectTableData - 1 >= index || !selectTableData) {
                        return (
                          <tr
                            className="cursor-pointer text-center items-center hover:bg-[#2222] h-10 px-6"
                            key={index}
                          >
                            <td className="border-b w-[50px]">
                              <input
                                type="checkbox"
                                checked={item.selected}
                                onChange={() => handleItemChange(item.id)}
                                className="focus:outline-none focus:ring-0 rounded-[3px] text-light-1 bg-transparent"
                              />
                            </td>
                            <td className="border-b w-[60px]">{index + 1}</td>
                            <td className="border-b">{item.asset_no} </td>
                            <td
                              className="border-b"
                              onClick={() => handleClick(item.id)}
                            >
                              {item.odoo_order_id || "--"}
                            </td>
                            <td
                              className="border-b w-[250px]"
                              onClick={() => handleClick(item.id)}
                            >
                              {item.customer.split(" - ")[0]}
                            </td>
                            <td
                              className="border-b"
                              onClick={() => handleClick(item.id)}
                            >
                              {item.customer.split(" - ")[1]}
                            </td>
                            <td
                              className="border-b"
                              onClick={() => handleClick(item.id)}
                            >
                              {currency(item.total_claimable_amount)}₹
                            </td>
                            <td
                              className="border-b"
                              onClick={() => handleClick(item.id)}
                            >
                              {formatDate(item.rental_start_date)}
                            </td>
                            <td
                              className="border-b"
                              onClick={() => handleClick(item.id)}
                            >
                              {item.closed_date
                                ? formatDate(item.closed_date)
                                : item.rental_extended_date
                                ? formatDate(
                                    item.rental_extended_date.split(" ")[0]
                                  )
                                : formatDate(item.rental_end_date)}
                            </td>
                            <td
                              className={`border-b font-bold ${
                                item.lease_status === "never assigned"
                                  ? "text-red-600"
                                  : item.lease_status === "inactive"
                                  ? "text-slate-500"
                                  : item.lease_status === "expired"
                                  ? "text-[#421515]"
                                  : "text-green-500"
                              }`}
                              onClick={() => handleClick(item.id)}
                            >
                              {item.lease_status.startsWith("expiring")
                                ? `Active (${numb})`
                                : item.lease_status === "inactive"
                                ? "Closed"
                                : capitalizeFirstLetter(item.lease_status)}
                            </td>

                            {/* <td className="border-b font-bold"><button onClick={deleteLease} className="border border-red-500 text-red-500 p-1">Delete</button></td> */}
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};
