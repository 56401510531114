import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Alert,
} from "@mui/material";

const CheckOldOperator = ({
  adhaarChecked,
  setAdhaarChecked,
  newDob,
  setNewDob,
  operatorData,
  setOperatorData,
}) => {
  const [adhaarNumberInput, setAdhaarNumberInput] = useState("");
  const [oldOperator, setOldOperator] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [error, setError] = useState("");
  const [id, setId] = useState({});
  const [dobinput, setDobinput] = useState("");
  const [isEligible, setIsEligible] = useState(false);

  const navigate = useNavigate();

  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      const dataArray = Object.entries(data["aadhar"]).map(([key, value]) => ({
        [key]: value,
      }));
      setSearchData(dataArray);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearchData();
  }, []);

  const handleCheckAdhaar = (e) => {
    const input = e.target.value;

    if (!/^\d+$/.test(input) && input !== "") {
      setError("Aadhaar number must contain only digits");
      return;
    }

    if (input.length > 12) {
      setError("Aadhaar number must not exceed 12 digits");
      return;
    }

    setError("");
    setAdhaarNumberInput(input);
    setOperatorData({ ...operatorData, aadhar_no: input });

    const filteredData = searchData.filter((item) =>
      Object.keys(item)[0].startsWith(input)
    );
    setSearchSuggestions(filteredData);

    // setOldOperator(input.length === 12 && filteredData.length > 0);
    setOldOperator(
      input.length === 12 && filteredData.length > 0 && typeof id != "object"
    );
  };

  const handleCheckDOB = (e) => {
    const dob = e.target.value;
    setDobinput(dob);

    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    const isOfAge =
      age > 18 ||
      (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)));

    setIsEligible(isOfAge);
    if (isOfAge) setNewDob(dob);
  };

  const handleCreate = (e) => {
    e.preventDefault();

    if (adhaarNumberInput.length !== 12) {
      setError("Aadhaar number must be exactly 12 digits");
      return;
    }

    if (oldOperator) {
      // navigate(`/operators/update-operator/${id}`,  { state: { rejoining: true } });
      navigate(`/operators/update-operator/${id}?q=rejoin`);
    } else if (isEligible) {
      setOldOperator(false);
      setAdhaarChecked(true);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="grey.100"
    >
      <Card elevation={3} sx={{ width: 400, p: 2 }}>
        <CardContent>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontSize: 14 }}
            textAlign="center"
          >
            Operator Verification
          </Typography>

          <div title="Please add adhaar number" className="">
            <h1 className="text-black font-semibold text-xs my-1">
              Aadhaar Number
            </h1>
            <input
              type="text"
              className="text-[12px] border focus:ring-0 focus:outline-none border-light-2 w-full rounded-[4px] "
              value={adhaarNumberInput}
              onChange={handleCheckAdhaar}
              placeholder="Enter Aadhaar No"
            />
          </div>
          {adhaarNumberInput && !oldOperator && (
            <div title="Please add date of birth">
              <span className="text-xs font-bold">Date of Birth</span>
              <input
                style={{
                  fontSize: 12,
                }}
                className="w-full rounded-[6px] "
                fullWidth
                label="Enter Date of Birth"
                type="date"
                value={dobinput}
                onChange={handleCheckDOB}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                error={dobinput && !isEligible}
                helperText={
                  dobinput && !isEligible
                    ? "You must be at least 18 years old to continue."
                    : ""
                }
              />
            </div>
          )}

          <div className="flex justify-between gap-2 mt-4">
            <button
              onClick={() => navigate("/operators")}
              title="Return to Operator table"
              className="px-4 py-3 rounded-[2px] w-full border"
            >
              Back
            </button>

            {/* <Button
              title={oldOperator ? "Rejoin" : "Create New"}
              onClick={handleCreate}
              variant="contained"
              color={
                adhaarNumberInput === ""
                  ? "inherit"
                  : oldOperator
                  ? "error"
                  : isEligible
                  ? "primary"
                  : "inherit"
              }
              disabled={!adhaarNumberInput || (!oldOperator && !isEligible)}
            >
              {oldOperator ? "Rejoin" : "Create New"}
            </Button> */}
            <button
              title={oldOperator ? "Rejoin" : "Create New"}
              onClick={handleCreate}
              // px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 
              className={`
                px-4 py-3 rounded-[2px] w-full
                  ${
                    adhaarNumberInput === ""
                      ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                      : oldOperator
                      ? "bg-red-500 hover:bg-red-600 text-white"
                      : isEligible
                      ? "bg-green-2 hover:bg-green-3 text-white"
                      : "bg-gray-300 text-gray-600 cursor-not-allowed"
                  }
                `}
              disabled={!adhaarNumberInput || (!oldOperator && !isEligible)}
            >
              {oldOperator ? "Rejoin" : "Create New"}
            </button>
          </div>

          {searchSuggestions.length > 0 && (
            <Box mt={2}>
              <span className="text-xs my-1" variant="subtitle1">Search Suggestions</span>
              <List
                style={{
                  height: "200px",
                  overflow: "auto",
                  backgroundColor: "#efefefd5",
                }}
              >
                {searchSuggestions.map((item, index) => {
                  const aadhaar = Object.keys(item)[0];
                  return (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          setAdhaarNumberInput(aadhaar);
                          setId(item[aadhaar]);
                          setOldOperator(true);
                        }}
                      >
                        <ListItemText primary={aadhaar} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CheckOldOperator;
