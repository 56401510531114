import React, { useEffect, useState } from "react";
import './button.css'

import io from "socket.io-client";
import { lastEventData } from "../../../apis/Telematics/last_event_data";
import LocationMarker from "./LocationMarker";
import DeviceMap from "./DeviceMap";
function DeviceEvents({ devId }) {
  const [internalBattery, setInternalBattery] = useState(null);
  const [vehicleBatteryVol, setVehicleBatteryVol] = useState(null);
  const [mapPosition, setMapPosition] = useState([22.539999, 88.354111]);
  const [gpsGroundSpeed, setGpsGroundSpeed] = useState(0);
  const [gpsAltitude, setGpsAltitude] = useState("OFF");
  const [turnSwitch, setTurnSwitch] = useState(false);
  const [DeviceOnOff, setDeviceOnOff] = useState("OFF");

  // const [openInternal, setOpenInternal] = useState(false)
  // const [openVoltage, setOpenVoltage] = useState(false)
  // const [openOnOf, setOpenOnOf] = useState(false)
  // console.log(process.env.REACT_APP_SOCKET_DATA_TOKEN)
  useEffect(() => {
    // console.log(devId);
    const socket = io("https://websocket.durbinservices.com/",{
      extraHeaders: {"x-access-token": process.env.REACT_APP_SOCKET_DATA_TOKEN }
    });

    // d94aeeab-286f-4034-aadf-9bfdd0b536a3
  //   socket.on(`genie/maco-storm/vehicle-engine-status/${devId}`, (data) => {
  //     console.log(JSON.stringify(data, null, 2)); // Pretty print the whole data object
  // });
  
    socket.on(`genie/maco-storm/device-internal-battery/${devId}`, (data) => {
      try {
        let splitByHyphen = data.split(" - ");
        if (splitByHyphen.length < 2) {
          console.error('Data format is incorrect:', data);
          return;
        }
        
        let trimmedString = splitByHyphen[1].trim();
        let stringWithoutPercent = trimmedString.replace("%", "");
        let floatValue = parseFloat(stringWithoutPercent);
    
        if (isNaN(floatValue)) {
          console.error('Parsed value is not a number:', stringWithoutPercent);
          return;
        }
    
        floatValue = Math.min(Math.max(floatValue, 0), 100); // Ensuring value is between 0 and 100
        console.log(floatValue);
        setInternalBattery(floatValue);
      } catch (error) {
        console.error('Error processing data:', error);
      }
    });
    

    socket.on(`genie/maco-storm/vehicle-battery-voltage/${devId}`, (data) => {
      try {
        let splitByHyphen = data.split("-");
        if (splitByHyphen.length < 2) {
          console.error('Data format is incorrect:', data);
          return;
        }
    
        let trimmedString = splitByHyphen[1].trim();
        let floatValue = parseFloat(trimmedString);
    
        if (isNaN(floatValue)) {
          console.error('Parsed value is not a number:', trimmedString);
          return;
        }
    
        if (floatValue > 13.5) {
          setDeviceOnOff("ON");
        } else {
          setDeviceOnOff("OFF");
        }
    
        setVehicleBatteryVol(trimmedString);
      } catch (error) {
        console.error('Error processing data:', error);
      }
    });
    


    socket.on(`genie/maco-storm/gps-push/${devId}`, (data) => {
      
      let parse_data = "";
      
      parse_data = data.split("\n")[1];
      let gpsData = JSON.parse(parse_data);
      // console.log(gpsData);
      let lat = gpsData.lat / 1000000;
      let lng = gpsData.lng / 1000000;
      // console.log(lat, lng);
      setMapPosition([lat, lng]);
      setGpsGroundSpeed(gpsData.speed);
    });
    socket.on(`genie/maco-storm/remote-start-stop/${devId}`, (data) => {
      // console.log(data);
    });
    return () => {
      socket.disconnect();
    };
  }, [mapPosition, vehicleBatteryVol, internalBattery, DeviceOnOff]);

  // const deviceOnOffEvent = (triggeredEvent) => {
  //   const socket = io("https://websocket.durbinservices.com/");
  //   if (turnSwitch == false) {
  //     socket.emit(
  //       "client-message",
  //       `genie/maco-storm/remote-start-stop/${devId}#TURN_OFF`
  //     );
  //   } else {
  //     socket.emit(
  //       "client-message",
  //       `genie/maco-storm/remote-start-stop/${devId}#TURN_ON`
  //     );
  //   }
  //   setTurnSwitch((prev) => !prev);
  // };

  const getAllEventData = async () => {
    // console.log(devId)
    let getData = await lastEventData(devId);
    // console.log(getData);
    // console.log(getData.device_internal_battery);
    try {
      if(getData?.device_internal_battery) {

      
        // console.log(getData?.device_internal_battery)
       
        let floatValue = parseFloat(JSON.parse(getData?.device_internal_battery.split("\n")[1])["SOC"]);
  
        if (floatValue > 100) setInternalBattery(100);
        else setInternalBattery(`${floatValue}`.split(".")[0]);
      }
    } catch (error) {
      console.error(error)
    }
  
    try {
      console.log(getData.vehicle_battery_voltage)
      if(getData?.vehicle_battery_voltage){


        let splitVolTageByHyphen = getData?.vehicle_battery_voltage.split(" - ");
        let trimmedStringForVoltage = splitVolTageByHyphen[1]?.trim();
        let floatValueForVoltage = parseFloat(trimmedStringForVoltage);
      
        // console.log(floatValueForVoltage);
        if (floatValueForVoltage > 13.5) {
          setDeviceOnOff("ON");
        } else {
          setDeviceOnOff("OFF");
        }
        setVehicleBatteryVol(splitVolTageByHyphen[1]);
      }
    } catch (error) {
      console.error(error)
    }

    try {
      let parse_data = "";

    // console.log(getData.gps_push)
    parse_data = getData.gps_push.split("\n")[1];
    // console.log(parse_data)
    parse_data = JSON.parse(parse_data);
    let lat = parse_data.lat / 1000000;
    let lng = parse_data.lng / 1000000;
    setMapPosition([lat, lng]);
    setGpsGroundSpeed(parse_data.speed);
    setGpsAltitude(parse_data.altitude);
    } catch (error) {
      console.error(error)
    }

  };

  useEffect(() => {
    // console.log(devId);
    getAllEventData();
  }, []);




  return (

    // d8ee6ad2-6639-4783-bd28-a5db20caafff
    // d8ee6ad2-6639-4783-bd28-a5db20caafff

<>
   
<div className=" flex flex-col   w-full rounded-3xl h-fit pb-4  bg-white dark:bg-slate-900  bordr items-center ">
    
      <div className=" items-center flex justify-center rounded-t-3xl w-fit ">

    

        <DeviceMap mapPosition={mapPosition} setMapPosition={setMapPosition}/>
      </div>

        {/* Battery Data */}
     
          <div className="flex justify-between px-2">
           {/* <h1 className="text-md font-bold ml-4">Device Events</h1> */}

      </div>
     

<div className="flex borde mt-4 rounded-full gap-5 px-2  bg-[#dadada">
      <>
    
      <div className="border rounded-md h-8 items-center bg-white w-[230px] text-xs  p-2 my-1 flex justify-between  transition-all ease-in-out duration-300">
              <span className="text-xs flex ">
              
                Asset Tracker internal battery</span>
              <span className="text-xs font-bold">
              {internalBattery == null ? (
                 "--"
                  ) : (
                    internalBattery + "%"
                  )}
              </span>
          </div>
      </>
  <>
  
  <div className="border rounded-md p-2 my-1 flex h-8 bg-white w-[200px] text-xs justify-between  transition-all ease-in-out duration-300 items-center">
                <span className="flex text-xs">
               
                Vehicle Battery Voltage
                </span>
                <span className="font-bold text-xs">
                {vehicleBatteryVol == null ? (
                    "--"
                  ) : (
                    vehicleBatteryVol
                  )}
                </span>
          </div>
  </>


<>
    <div className="border rounded-md  p-2 my-1 h-8 flex bg-white w-[200px] text-xs  justify-between  transition-all ease-in-out duration-300 items-center">
                  <span className="flex">
                  
                  Engine ON-OFF status
                  </span>
                  <span className="font-bold text-xs"> {DeviceOnOff}</span>
          </div>

</>

</div>

</div>

</>
  
  );
}

export default DeviceEvents;

// TOPIC
// client-message

// MESSAGE DATA
// genie/maco-storm/remote-start-stop/ca3b612b-dc7e-4508-933d-a41aceab6e7b#TURN_ON
// genie/maco-storm/remote-start-stop/ca3b612b-dc7e-4508-933d-a41aceab6e7b#TURN_OFF
