import React from 'react'
import { currency } from '../../customFunctions/FormatDate'

const InstalledParts = ({ maintenanceData, openInstalledParts, setOpenInstalledParts }) => {
    return (
        <>
        
            <div className="  bg-white p-4 w-full mt-2 border rounded-[4px] ">

                <div className="flex flex-col gap-2  text-xs  ">
                    <span className="text-xs p-2 w-full bg-gray-200"> Parts Installed or Removed</span>

                    <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                        <thead className="bg-gray-800 text-white">
                            <tr >
                                <th className="p-3 text-left">Part No:</th>
                                <th className="p-3 text-left">Quantity</th>
                                
                                <th className="p-3 text-left">Status</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            {maintenanceData?.map((item, idx) => (
                                <tr key={idx} className="border-b hover:bg-gray-100">
                                    <td className="p-3">{item.part_no || "N/A"}</td>
                                    <td className="p-3">{item.quantity}</td>
                                    
                                    <td className="p-3">
                                        <span className={`px-2 py-1 text-xs font-semibold rounded-full ${item.installation === true ? "bg-green-200 text-green-700" : "bg-red-200 text-red-1"
                                            }`}>
                                            {item.installation === true ? "Installed" : "Removed"}
                                        </span>
                                    </td>
                                   
                                </tr>
                            ))

                            }
                        </tbody>
                    </table>

                    
                </div>
            </div>




        </>
    )
}

export default InstalledParts