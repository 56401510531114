import React from 'react';

const Rejection = ({ maintenanceData }) => {
  const { reject_comments } = maintenanceData;

  return (
    <div className="overflow-x-auto p-4 bg-white mt-4 shadow-md">
      <h2 className="text-base font-semibold mb-4">Approval/Rejection Table</h2>
      <table className="w-full border-collapse border border-gray-300 ">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2 text-left">Name</th>
            <th className="border border-gray-300 p-2 text-left">Status</th>
            <th className="border border-gray-300 p-2 text-left">Comment</th>
          </tr>
        </thead>
        <tbody>
          {reject_comments?.map((item, index) => (
            <tr key={index} className="hover:bg-gray-100">
              {console.log(item.is_accepeted)}
              <td className="border border-gray-300 p-2">{item.name}</td>
              <td className={`border border-gray-300 p-2 `}> 
                <span className={`py-1 px-3 rounded-full ${item.is_accepeted === false ? 'text-red-1 bg-red-200': 'text-green-2 bg-green-200'}`}>

                {item.is_accepeted === false
                  ? 'Rejected'
                  : item.is_accepeted === true
                  ? 'Accepted'
                  : 'Pending'}
                  </span>
              </td>
              <td className="border border-gray-300 p-2">
                {item.comment_for_reject || 'No Comment'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Rejection;