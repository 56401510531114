import React, { useState } from 'react';
import { useToast } from '../../Context/ToastContext';
import { setBaseUrl } from '../../config';

const RequestForClosure = ({ maintenance_id, setOpenClosure }) => {
    const [form, setForm] = useState({
        maintenance_id: maintenance_id,
        temporary: false
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { addToast } = useToast();
    const token = sessionStorage.getItem("sales_token");

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            const res = await fetch(`${setBaseUrl}/sale-service/set-as-complete`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify({
                    maintenance_id: maintenance_id,
                    temporary: form.temporary
                }),
            });

            if (res.ok) {
                addToast("Closure request submitted successfully!", "success");
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                const errorData = await res.json();
                addToast(errorData.message || "Failed to submit closure request", "error");
            }
        } catch (error) {
            console.error(error);
            addToast("An error occurred while processing your request", "error");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {/* Overlay */}
            <div 
                className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-[9998] transition-opacity"
                onClick={() => !isSubmitting && setOpenClosure(false)}
            />

            {/* Modal */}
            <div className="fixed z-[9999] bg-white rounded-xl shadow-2xl w-[90%] max-w-md left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300">
                {/* Header */}
                <div className="p-6 border-b border-gray-200">
                    <div className="flex items-center justify-center mb-4">
                        <div className="flex items-center justify-center w-12 h-12 rounded-full bg-red-50">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                className="h-6 w-6 text-red-500" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor"
                            >
                                <path 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round" 
                                    strokeWidth={2} 
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
                                />
                            </svg>
                        </div>
                    </div>
                    <h2 className="text-xl font-semibold text-gray-800 text-center">
                        Request Maintenance Closure
                    </h2>
                    <p className="text-sm text-gray-600 mt-2 text-center">
                        Are you sure you want to request closure for this maintenance?
                    </p>
                </div>

                {/* Body */}
                <div className="p-6">
                    <div className="flex items-center mb-6">
                        <label className="inline-flex items-center cursor-pointer">
                            <input 
                                type="checkbox" 
                                className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                checked={form.temporary}
                                onChange={(e) => setForm({...form, temporary: e.target.checked})}
                            />
                            <span className="ml-3 text-sm font-medium text-gray-700">
                                Close Temporarily
                            </span>
                        </label>
                    </div>

                    <div className="flex justify-between gap-4 mt-6">
                        <button
                            className={`flex-1 py-2 px-4 border border-gray-300 rounded-lg text-gray-700 font-medium hover:bg-gray-50 transition-colors ${
                                isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            onClick={() => setOpenClosure(false)}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            className={`flex-1 py-2 px-4 rounded-lg font-medium text-white transition-colors ${
                                form.temporary 
                                    ? 'bg-amber-500 hover:bg-amber-600' 
                                    : 'bg-red-500 hover:bg-red-600'
                            } ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <span className="flex items-center justify-center">
                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Processing...
                                </span>
                            ) : (
                                form.temporary ? 'Temporarily Close' : 'Confirm Closure'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RequestForClosure;