import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select'; // Assuming you are using react-select for custom select components
import { setBaseUrl } from '../../config';
import { toast } from 'react-toastify';
import LocalPart from './LocalPart';
import { useToast } from '../../Context/ToastContext';

const RequestPart = ({ inventory, id }) => {
  const token = sessionStorage.getItem("sales_token");
  const [loadingReq, setLoadingReq] = useState(false);
  const [localPart, setLocatPart] = useState(false)
  const [requestPart, setRequestPart] = useState({
    quantity: "",
    part_no: "",
  });
  const [searchTerm, setSearchTerm] = useState(""); // For search functionality
  const [filteredOptions, setFilteredOptions] = useState([]); // Filtered part options
  const [quantityError, setQuantityError] = useState(""); // For quantity validation error

  const {addToast} = useToast()
  // Memoize the filtered inventory based on the search term
  const filteredInventory = useMemo(() => {
    return inventory?.filter(item => {
      return (
        item.part_no?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        item.part_name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
    });
  }, [searchTerm, inventory]);

  // Use effect for debouncing search input
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm?.length >= 3) {
        // Filter inventory based on the search term
        setFilteredOptions(
          filteredInventory.map(item => ({
            value: item.part_no,
            label: `${item.part_no} - ${item?.part_name}`,
          }))
        );
      } else {
        setFilteredOptions([]); // Clear options if input is less than 3 characters
      }
    }, 500); // Debounce time of 500ms to avoid continuous search

    return () => clearTimeout(delayDebounceFn); // Clear the timeout when searchTerm changes
  }, [searchTerm, filteredInventory]);

  // Handle quantity input change and validate the quantity
  const handleQuantityChange = (e) => {
    const value = e.target.value;



    setRequestPart({ ...requestPart, quantity: value });
  };

  // Handle the part number selection
  const handlePartSelect = (selectedOption) => {
    if (selectedOption) {
      setRequestPart({ ...requestPart, part_no: selectedOption.value });
    }
  };

  // Request Part function
  // const requestedPart = async () => {


  //   if (quantityError) {
  //     toast.error(quantityError);
  //     return;
  //   }
  //   const SubmittedData = [
  //     {
  //       quantity: requestPart.quantity,
  //       part_no: requestPart.part_no,
  //       maintenance_id: id,
  //     },
  //   ];

  //   setLoadingReq(true);
  //   try {
  //     const res = await fetch(`${setBaseUrl}/sale-service/request-part`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "auth-token": token,
  //       },
  //       body: JSON.stringify(SubmittedData),
  //     });

  //     setLoadingReq(false);

  //     if (res.status === 200) {
  //       addToast("Requested Successfully!", "success");
  //       setRequestPart({ quantity: "", part_no: "" });
  //     } else {
  //       addToast("Request failed. Please try again.", "error");
  //     }
  //     window.location.reload();
  //   } catch (error) {
  //     console.error(error);
  //     addToast("Request failed", "error");
  //     setLoadingReq(false);
  //   }
  // };
  const requestedPart = async () => {
    if (quantityError) {
      toast.error(quantityError);
      return;
    }
  
    const SubmittedData = [
      {
        quantity: requestPart.quantity,
        part_no: requestPart.part_no,
        maintenance_id: id,
      },
    ];

    console.log(SubmittedData)
  
    setLoadingReq(true);
    try {
      const res = await fetch(`${setBaseUrl}/sale-service/request-part`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(SubmittedData),
      });
  
      setLoadingReq(false);
  
      if (res.status === 200) {
        addToast("Requested Successfully!", "success");
        setRequestPart({ quantity: "", part_no: "" });
        // Delay reload by 2 seconds to allow toast to be seen
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        addToast("Request failed. Please try again.", "error");
      }
    } catch (error) {
      console.error(error);
      addToast("Request failed", "error");
      setLoadingReq(false);
    }
  };

  return (
    <>
    <div className="w-full max-w-md mx-auto p-4 border bg-white border-gray-300 rounded-lg shadow-md">
      <div className="flex justify-between items-center">

      <h2 className="text-lg font-semibold mb-4">Request Part</h2>
      <button className=" border-red-1 text-red-1 hover:bg-red-1 hover:text-white border buttons mt-2 w-[130px]"
          onClick={() => setLocatPart(true)}
          >
          Purchase Locally
        </button>
          </div>


      <label className="block mb-2 text-sm font-medium text-gray-700">Search Part Number</label>
      <Select
        title="Select Part"
        value={filteredOptions.find(option => option.value === requestPart.part_no)}
        options={filteredOptions}
        onInputChange={(inputValue) => {
          setSearchTerm(inputValue);
        }}
        onChange={handlePartSelect}
        placeholder="Please Type 3 Characters to Search"
        noOptionsMessage={() => "No parts found"}
        isClearable
        styles={{
          container: (provided) => ({
            ...provided,
            width: "410px",
            fontSize: 10,
          }),
          control: (provided, state) => ({
            ...provided,
            width: "410px",
            border: state.isFocused || state.isHovered ? "none" : "1px solid transparent",
            boxShadow: state.isFocused
              ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
              : null,
            borderRadius: 0,
            backgroundColor: '#EEE'
          }),
        }}
      />

      <label className="block mt-4 mb-2 text-sm font-medium text-gray-700">Quantity</label>
      <input
        type="number"
        min={0}
        placeholder="Enter Quantity"
        value={requestPart.quantity}
        onChange={handleQuantityChange}
        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:border-blue-400 outline-none"
      />

      {quantityError && (
        <p className="text-red-500 text-sm mt-1">{quantityError}</p>
      )}

      <button
        onClick={requestedPart}
        disabled={loadingReq || quantityError}
        className={`mt-6 w-full p-2 text-white rounded-md ${loadingReq || quantityError ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'}`}
      >
        {loadingReq ? 'Requesting...' : 'Request Part'}
      </button>
  
    </div>
      {
        localPart && <LocalPart maintenance_id={id} setLocatPart={setLocatPart} />
      }
    </>
  );
};

export default RequestPart;
