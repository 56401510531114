import React, { useEffect, useState } from 'react';
import { setBaseUrl } from '../../../config';
import { Link } from 'react-router-dom';

const ServicePersonTable = ({title}) => {
    const [servicePersons, setServicePersons] = useState([]);

    // Get All Service Person Details
    const getServicePersons = async () => {
        try {
            const res = await fetch(`${setBaseUrl}/lease/get-service-persons`, {
                headers: {
                    'x-access-tokens': sessionStorage.getItem('token')
                }
            });

            const data = await res.json();
            setServicePersons(data);
        } catch (error) {
            console.error('Error fetching service persons:', error);
        }
    };

    // Fetch service persons on component mount
    useEffect(() => {
        getServicePersons();
    }, []);

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            {/* <span className="items-end flex justify-end">
            <Link to={`/settings/add-employee`} className="buttons bg-green-3 text-white flex justify-center items-center" title='Add New Service Person'>Add New</Link>
            </span> */}
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Service Person List</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead className="bg-light-3 text-white">
                        <tr>
                            <th className="py-3 px-6 text-left">ID</th>
                            <th className="py-3 px-6 text-left">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {servicePersons.length > 0 ? (
                            servicePersons.map(person => (
                                <tr key={person.id} className="border-b hover:bg-gray-100">
                                    <td className="py-3 px-6">{person.id}</td>
                                    <td className="py-3 px-6">{person.name}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className="py-4 px-6 text-center text-gray-500">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ServicePersonTable;
