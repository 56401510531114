import React, { useState } from "react";
import Select from "react-select";

const BasicFormData = ({
  name,
  type,
  keyNameType,
  label,
  leaseData,
  setLeaseData,
  customerArray,
}) => {
  const [hover, setHover] = useState(false);
  const [focused, setFocused] = useState(false); // New state for focus

  const handleInputChange = (e) => {
    setLeaseData({
      ...leaseData,
      [keyNameType]: e.target.value, // Dynamically update the field by its name
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(customerArray);

  return (
    <div className="flex justify-between gap-5 items-center space-y-2 text-[12px]">
      <span className="text-[10px]">{name}</span>
      {name === "Customer Name" ? (
        <>
          <Select
            name="customer"
            options={filteredOptions} // Display the filtered customer options
            openMenuOnClick={false} // Prevent automatic opening
            onInputChange={(inputValue) => {
              setSearchTerm(inputValue); // Update search term
              if (inputValue.length >= 3) {
                // Filter customerArray based on the input value
                const filtered = customerArray.filter((customer) =>
                  customer.label
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                );
                setFilteredOptions(filtered); // Update the filtered options
              } else {
                setFilteredOptions([]); // Clear options if input is less than 3 characters
              }
            }}
            onChange={(value) => {
              setLeaseData({ ...leaseData, customer: value.value });
            }}
            styles={{
              container: (provided) => ({
                ...provided,
                width: "350px",
                fontSize: 10,
              }),
              control: (provided, state) => ({
                ...provided,
                width: "350px",
                height: "20px",
                border:
                  state.isFocused || state.isHovered
                    ? "none"
                    : "1px solid transparent",
                borderBottom: "1px solid #D1D5DB",
                boxShadow: state.isFocused
                  ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                  : null,
                outline: "none",
                borderRadius: 0,
              }),
            }}
          />
        </>
      ) : name === "Lease Type" ? (
        <>
          <select
            name={name}
            id="value"
            className="w-[350px] text-[10px] border-b border-r-0 border-t-0 border-l-0 border-gray-300 focus:border-b focus:outline-none focus:ring-0 focus:border-gray-300"
          
            onChange={(e) =>
              setLeaseData({
                ...leaseData,
                [keyNameType]: e.target.value, 
              })
            }
          >
              <option value="">Select</option>
            <option value="dry">Dry</option>
            <option value="wet">Wet</option>
          </select>
        </>
      ) : name === "Currency" ? (
        <>
       <>
          <select
            name={name}
            id="value"
            className="w-[350px] text-[10px] border-b border-r-0 border-t-0 border-l-0 border-gray-300 focus:border-b focus:outline-none focus:ring-0 focus:border-gray-300"
            
            onChange={(e) =>
              setLeaseData({
                ...leaseData,
                [keyNameType]: e.target.value, 
              })
            }
          >
            
            <option value="inr">INR</option>
            
          </select>
        </>
        </>
      ):(
        <input
          type={type || "text"}
          className={`w-[350px] h-6 text-[10px] 
            border-b border-r-0 border-t-0 border-l-0 border-gray-300 focus:border-b focus:outline-none focus:ring-0 focus:border-gray-300
        
        `}
          onChange={handleInputChange} // Handle generic input change
          onFocus={() => {
            setHover(true); // Set hover to true on focus
            setFocused(true); // Set focused to true on focus
          }}
          placeholder={name}
          onBlur={() => {
            setHover(false); // Set hover to false on blur
            setFocused(false); // Set focused to false on blur
          }}
        />
      )}
    </div>
  );
};

export default BasicFormData;
