import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { setBaseUrl } from "../../../config";
import { Category } from "@mui/icons-material";




function NewBasicDetails({
  assetBasicDetails,
  setAssetBasicDetails,
  formErrors,
  deviceNameError,
  handleBlur,
  touched,
  setTouched,
}) {
  const categories = [
    "Telescopic Boom",
    "Truck Mounted",
    "Battery Scissor",
    "Articulating Boom",
    "Articulating Battery Boom",
    "AWP",
    "Diesel Scissors",
   
  ];

  const handleSelectChange = (e) => {
    setAssetBasicDetails({
      ...assetBasicDetails,
      category: e.target.value,
    });
  };
  // useEffect(() => {
  //   setAssetBasicDetails({
  //     ...assetBasicDetails,
  //     category: categories[0],
  //   });
  //   // console.log(assetBasicDetails);
  // }, []);

  const [checkAuthority, setCheckAuthority] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [otp, setOtp] = useState("");

  // console.log(assetBasicDetails.is_sold,"is_sold")

  // const [confirmSold, setConfirmSold] = useState({is_sold : "false"})

  //   const getOtpFunction = async () => {
  //     try {
  //       const res = await fetch(`${setBaseUrl}/asset/get-otp-sold-asset`, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //           "asset-id": id,
  //         },
  //       });
  //       if (res === 200) {
  //         alert("OTP Has been sent to your email");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   const verifyOtp = async () => {
  //     try {

  //       const res = await fetch(`${setBaseUrl}/asset/update-otp-sold-asset`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),

  //         },
  //         credentials: "include",
  //         body: JSON.stringify({ id, otp,  is_sold: true})
  //       })

  //       if(res === 200) {
  //         setIsVerify(true);
  //         // setAssetBasicDetails({...assetBasicDetails, is_sold: true})
  //         alert("Verified")
  //       }
  //     } catch (error) {
  //       console.error(error)
  //       alert("Verification Failed")
  //     }
  //   }

  //   const handleSoldVerification = () => {
  //     verifyOtp()
  //     setCheckAuthority(false);
  //   };

  //   const handleIsSold = () => {
  //     getOtpFunction();
  //     // setAssetBasicDetails({ ...assetBasicDetails, is_sold: true })
  //   };

  // Generate an array of years (e.g., 1900 to current year + 10)
  const startYear = 2000;
  const endYear = new Date().getFullYear() + 2;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );

  return (
    <>
      <span className="text-xs font-semibold text-black underline">
        Add General Details
      </span>
      <div className="p-10 text-xs gap-4 z-20  border bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
        {/*Asset No  */}

        <span title="Enter Valid Asset Number" className="flex-1 w-[200px] ">
          {/* <TooltipFun  placement="top"> */}
            <label className="font-bold">
              Asset Number
              <span className="text-red-1 font-bold">*</span>
            </label>
          {/* </TooltipFun> */}

          <input
            type="text"
            title="Asset Number"
            value={assetBasicDetails?.asset_no}
            maxLength={20}
            placeholder="e.g. MA0123"
            className={`text-black focus:outline-none border-none focus:border-none w-full mt-2 p-2 bg-light-4 font-medium rounded-md text-xs ${
              formErrors.asset_no
                ? "ring-1 ring-red-1"
                : assetBasicDetails?.asset_no
                ? "ring-1 ring-green-3"
                : ""
            } 
            
            `}

            onKeyDown={(e) => {
              // Prevent typing emojis
              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (emojiRegex.test(e.key)) {
                e.preventDefault();
              }
            }}

            onChange={(e) =>
              {
                const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
                if (!emojiRegex.test(e.target.value)) {

                  setAssetBasicDetails({
                    ...assetBasicDetails,
                    asset_no: e.target.value,
                  })}  else {
                    e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                  }
                }
            }
            onBlur={() => handleBlur("asset_no")}
            onFocus={() => setTouched({ ...touched, asset_no: true })}
            required={true}
          />

          {/* {(touched.asset_no && formErrors.asset_no) || !assetBasicDetails.asset_no ? (
            <div className="text-red-1 text-xs">Please enter asset number</div>
          ): ''} */}

          {touched.asset_no && formErrors.asset_no ? (
            <div className="text-red-1 text-xs">Please enter asset number</div>
          ) : formErrors.asset_no && !assetBasicDetails.asset_no ? (
            <div className="text-red-1 text-xs">Please enter asset number</div>
          ) : (
            ""
          )}
        </span>
        {/* Make */}
        <div  title="Enter Manufacturer Name" className="flex-1 w-[200px] ">
          {/* <TooltipFun placement="top"> */}
            <label className="font-bold">
              Make
              <span className="text-red-1 font-bold">*</span>
            </label>
          {/* </TooltipFun> */}
          <input
            type="text"
            value={assetBasicDetails?.make}
            maxLength={25}
            placeholder="e.g. Genie"
            className={`text-black focus:outline-none border-none focus:border-none w-full mt-2 p-2 bg-light-4 font-medium rounded-md text-xs ${
              formErrors.make
                ? "ring-1 ring-red-1"
                : assetBasicDetails?.make
                ? "ring-1 ring-green-3"
                : ""
            }`}
            onChange={(e) => {
              
                const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
                if (!emojiRegex.test(e.target.value)) {

                  setAssetBasicDetails({
                    ...assetBasicDetails,
                    make: e.target.value,
                  });}  else {
                    e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                  }
                
             
            }}
            onBlur={() => handleBlur("make")}
            onFocus={() => setTouched({ ...touched, make: true })}
            required={true}
          />
          {/* {touched.make && formErrors.make && !assetBasicDetails.make && (
            <div className="text-red-1 text-xs">
              Please enter manufacturer name
            </div>
          )} */}

          {touched.make && formErrors.make ? (
            <div className="text-red-1 text-xs">
              {" "}
              Please enter manufacturer name
            </div>
          ) : formErrors.make && !assetBasicDetails.make ? (
            <div className="text-red-1 text-xs">
              {" "}
              Please enter manufacturer name
            </div>
          ) : (
            ""
          )}
        </div>
        {/* Model */}
        <div className="flex-1 w-[200px] "  title="Enter Valid Model Number">
          {/* <TooltipFun placement="top"> */}
            <label className="font-bold">
              Model
              <span className="text-red-1 font-bold">*</span>
            </label>
          {/* </TooltipFun> */}
          <input
            type="text"
            value={assetBasicDetails?.model}
            maxLength={25}
            placeholder="e.g. S60J"
            onChange={(e) => {
              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {

                setAssetBasicDetails({
                  ...assetBasicDetails,
                  model: e.target.value,
                });}  else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
            
            }}
            onBlur={() => handleBlur("model")}
            onFocus={() => setTouched({ ...touched, model: true })}
            required={true}
            className={`text-black focus:outline-none border-none focus:border-none w-full mt-2 p-2 bg-light-4 font-medium rounded-md text-xs ${
              formErrors.model
                ? "ring-1 ring-red-1"
                : assetBasicDetails?.model
                ? "ring-1 ring-green-3"
                : ""
            }`}
          />
          {/* {touched.model && formErrors.model && !assetBasicDetails.model && (
            <div className="text-red-1 text-xs">Please enter model number</div>
          )} */}

          {touched.model && formErrors.model ? (
            <div className="text-red-1 text-xs"> Please enter model number</div>
          ) : formErrors.model && !assetBasicDetails.model ? (
            <div className="text-red-1 text-xs"> Please enter model number</div>
          ) : (
            ""
          )}
        </div>

        {/* Serial No */}
        <div className="flex-1 w-[200px] " title="Enter Valid Serial Number" >
          
            <label className="font-bold">
              Serial Number
              <span className="text-red-1 font-bold">*</span>
            </label>
          
          <input
            type="text"
            value={assetBasicDetails?.serial_no}
            maxLength={25}
            placeholder="e.g. GS46D-12345"
            onBlur={() => handleBlur("serial_no")}
            onFocus={() => setTouched({ ...touched, serial_no: true })}
            onChange={(e) => {
              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {

                setAssetBasicDetails({
                  ...assetBasicDetails,
                  serial_no: e.target.value,
                });
              }  else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
            }}
            required={true}
            className={`text-black focus:outline-none border-none focus:border-none w-full mt-2 p-2 bg-light-4 font-medium rounded-md text-xs ${
              formErrors.serial_no
                ? "ring-1 ring-red-1"
                : assetBasicDetails?.serial_no
                ? "ring-1 ring-green-3"
                : ""
            }`}
          />
          {/* {touched.serial_no &&
            !assetBasicDetails.serial_no &&
            formErrors.serial_no && (
              <div className="text-red-1 text-xs">
                Please enter serial number
              </div>
            )} */}

          {touched.serial_no && formErrors.serial_no ? (
            <div className="text-red-1 text-xs">
              {" "}
              Please enter serial number
            </div>
          ) : formErrors.serial_no && !assetBasicDetails.serial_no ? (
            <div className="text-red-1 text-xs">
              {" "}
              Please enter serial number
            </div>
          ) : (
            ""
          )}
        </div>

    
        {/* Purchased from */}
        <div  title="Enter From whom we are purchasing" className="flex-1 w-[200px] ">
        
            <label className="font-bold">
              Purchased from
              {/* <span className="text-red-1 font-bold">*</span> */}
            </label>
         
          <input
            type="text"
            maxLength={25}
            value={assetBasicDetails?.purchased_from}
            placeholder="e.g. TATA"
            onChange={(e) => {
              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {

               
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  purchased_from: e.target.value,
                });
              }  else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
            }}
            required={true}
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div>
        {/* RFID */}
        {/* <div className="flex-1 w-[200px] ">
          <label className="font-bold">RFID
          <span className="text-red-1 font-bold">*</span>
          </label>
          <input
            type="text"
            value={assetBasicDetails?.rfid}
            placeholder="RFID Not Required"
            onChange={(e) =>
              {setAssetBasicDetails({
                ...assetBasicDetails,
                rfid: e.target.value,
              })
             
            }
            }
            disabled={true}
          className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div> */}

        {/* Device No */}
        <div  title="Enter Registered Device Number" className="flex-1 w-[200px] ">
          {/* <TooltipFun placement="top"> */}
            <label className="font-bold">Device Number</label>
          {/* </TooltipFun> */}
          <input
            type="text"
            maxLength={25}
            value={assetBasicDetails?.device_no}
            placeholder="Device Hash Not Required"
            onChange={(e) => {
              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {

                
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  device_no: e.target.value,
                });
              }  else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
            }}
            required={true}
            className={`text-black focus:outline-none ${
              deviceNameError ? "focus:ring-red-1" : "focus:ring-green-2"
            } focus:ring-1  focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs 
              
              `}
          />
          {deviceNameError && (
            <div className="text-red-1 text-xs">
              Enter registered device number
            </div>
          )}
        </div>
        {assetBasicDetails?.device_no &&
         <>
        
        {/* installation_date */}
        <div className="flex-1 w-[200px] " title="Enter Device Installation Date" >
          {/* <TooltipFun placement="top"> */}
            <label className="font-bold">Device Installation Date</label>
          {/* </TooltipFun> */}
          <input
            type="date"
            maxLength={25}
            value={assetBasicDetails?.installation_date}
            placeholder="Device Hash Not Required"
            onChange={(e) => {
              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {

               
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  installation_date: e.target.value,
                });
              }  else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
            }}
            required={true}
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div>
        </>}
        {/* YOM */}
        <div className="flex-1 w-[200px] ">
          {/* <TooltipFun placement="top"> */}
            {/* <label className="font-bold">
            Year of Manufacturing
          
          </label>
          <input
            type="number"
            min="1900"
            step="1"
            value={assetBasicDetails?.yom}
            placeholder="Year of Manufacturing"
            max="2099"
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                yom: e.target.value,
              });
            }}
            required={true}
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
            /> */}

            <div title="Enter Year of Manufacture" >
              <label htmlFor="year-picker" className="font-bold">
                Year of Manufacturing
              </label>
              <select
                id="year-picker"
                className={`text-black focus:outline-none border-none focus:border-none w-full mt-2 p-2 bg-light-4 font-medium rounded-md text-xs ${
                  formErrors.yom
                    ? "ring-1 ring-red-1"
                    : assetBasicDetails?.yom
                    ? "ring-1 ring-green-3"
                    : ""
                }`}
                value={assetBasicDetails.yom}
                onChange={(e) => {
                  setAssetBasicDetails({
                    ...assetBasicDetails,
                    yom: e.target.value,
                  });
                }}
              >
                <option value="" disabled>
                  Select Year
                </option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              {/* {touched.yom && !assetBasicDetails.yom && formErrors.yom && (
                <div className="text-red-1 text-xs">
                  Please add year of manufacturing
                </div>
              )} */}

              {touched.yom && formErrors.yom ? (
                <div className="text-red-1 text-xs">
                  {" "}
                  Please enter serial number
                </div>
              ) : formErrors.yom && !assetBasicDetails.yom ? (
                <div className="text-red-1 text-xs">
                  {" "}
                  Please enter serial number
                </div>
              ) : (
                ""
              )}
            </div>
          {/* </TooltipFun> */}
        </div>
        {/* Site Location */}
        <div  title="Enter Site Location" className="flex-1 w-[200px] ">
          {/* <TooltipFun placement="top"> */}
            <label className="font-bold">
              Site Location
              {/* <span className="text-red-1 font-bold">*</span> */}
            </label>
          {/* </TooltipFun> */}
          <input
            type="text"
            maxLength={25}
            value={assetBasicDetails?.site_location}
            placeholder="e.g. Chennai"
            required={true}
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                site_location: e.target.value,
              });
            }}
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div>
        {/* Height of Machine */}
        <div    title="Enter height of the machine in ft." className="flex-1 w-[200px] ">
     
            <label className="font-bold ">Height of Machine(ft.)</label>
       
          <input
            type="number"
            min={0}
            maxLength={25}
            value={assetBasicDetails?.hieght_machine}
            placeholder="e.g. 10 ft"
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                hieght_machine: e.target.value,
              });
            }}
            // required={true}
            
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div>
        {/* Capacity of machine */}
        <div title="Enter Capacity of Machine in kg." className="flex-1 w-[200px] ">
          {/* <TooltipFun  placement="top"> */}
            <label className="font-bold text-black">Capacity of Machine(kg.)</label>
          {/* </TooltipFun> */}
          <input
            type="number"
            min={0}
            maxLength={25}
            value={assetBasicDetails?.capacity}
            placeholder="e.g. 80 kg."
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                capacity: e.target.value,
              });
            }}
            // required={true}
            
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div>
        {/* Rating */}
        <div title="Enter Ratings" className="flex-1 w-[200px] ">
          {/* <TooltipFun  placement="top"> */}
            <label className="font-bold">Ratings(out of 5) </label>
          {/* </TooltipFun> */}
          <select
            type="text"
            value={assetBasicDetails?.rating}
            placeholder="e.g. 10 ft"
            disabled
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                rating: e.target.value,
              });
            }}
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        {/* Cateogry */}
        <div class="flex-1 w-[200px]" title="Enter Category">
          {/* <TooltipFun  placement="top"> */}
            <label className="font-bold">Category</label>
          {/* </TooltipFun> */}
          <select
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
            value={
              assetBasicDetails?.category === "nill" ||
              !assetBasicDetails?.category
                ? ""
                : assetBasicDetails?.category
            }
            onChange={handleSelectChange}
            required={true}
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option
                key={category}
                value={category}
                selected={assetBasicDetails?.category === category}
              >
                {category}
              </option>
            ))}
          </select>
        </div>

        {/* Description */}
        <div className="flex-1 w-[200px] "  title="Enter Desciption">
          {/* <TooltipFun placement="top"> */}
            <label className="font-bold">Description</label>
          {/* </TooltipFun> */}
          <textarea
            rows="2"
            maxLength={250}
            value={assetBasicDetails?.description}
            placeholder="Description must not be greater than 250 words."
            onChange={(e) => {
              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {

                setAssetBasicDetails({
                  ...assetBasicDetails,
                  description: e.target.value,
                });
              }  else {
                  e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
                }
            }}
            required={true}
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div>
        {/* machine sold function */}
        {/* {id ? (
          <>
            <div className="flex-1 w-[200px]">
              <label className="font-bold">Machine Sold</label>
              <div className="flex gap-4">
              
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="is_sold"
                    value={true}
                    checked={assetBasicDetails.is_sold === true}
                    onChange={handleIsSold}
                    className="mr-2 focus:ring-1 focus:ring-green-2"
                    onClick={() => setCheckAuthority(true)}
                  />
                  Yes
                </label>

               <label className="flex items-center">
                  <input
                    type="radio"
                    name="is_sold"
                    value={false}
                    checked={assetBasicDetails.is_sold === false}
                    onChange={handleIsSold}
                    className="mr-2 focus:ring-1 focus:ring-green-2"
                    onClick={() => setCheckAuthority(false)}
                  />
                  No
                </label>
              </div>
              
              {isVerify && (
                <>
                  <div className="flex-1 w-[200px] mt-3">
                    <label className="font-bold">Machine Sold Date</label>
                    <input
                      type="date"
                      value={assetBasicDetails?.machine_sold_date} //avijit
                      onChange={(e) =>
                    {    setAssetBasicDetails({
                          ...assetBasicDetails,
                          machine_sold_date: e.target.value, //avijit
                        })
                        {setUpdateAssetDetails({
                          ...updateAssetDetails, 'machine_sold_date': e.target.value
                        })}
                      }
                      }
                     className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                    />
                  </div>
                </>
              )}
            </div>
            {checkAuthority && (
              <>
                <div className="z-[9999] text-xs p-4 h-[250px] w-[500px] fixed top-[20%] text-black left-[30%] rounded bg-white">
                  <button className="" onClick={() => setCheckAuthority(false)}>
                    Close
                  </button>
                  <div className="items-center justify-center flex flex-col gap-6">
                    <h1 className="font-extrabold text-[#8d8d8d]">Enter OTP</h1>
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{ color: "black", width: "50px" }}
                        />
                      )}
                    />
                    <button
                      className="bg-light-1 text-white w-[67px] rounded h-7"
                      onClick={handleSoldVerification}
                    >
                      Verify
                    </button>
                  </div>
                </div>
                <div
                  className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000021] z-50 "
                  onClick={() => setCheckAuthority(false)}
                />
              </>
            )}
          </>
        ) : (
          ""
        )} */}
      </div>
    </>
  );
}

export default NewBasicDetails;
