import React, { useContext } from 'react'
import { NavbarContext } from '../../Context/NavbarContext';
import dashboard from "../../assets/images/dasboard.svg"
import assets from '../../assets/images/assets.svg'
import maintanance from '../../assets/images/maitanance.svg'
import lease from '../../assets/images/lease.svg'
import wrench from '../../assets/images/wrench.svg'

import { Link } from 'react-router-dom';
import useAuthorities from '../../utils/useAuthorities';
import { Navbar } from "flowbite-react";

// const MobileNav = () => {


//   return (
//     <div className='z-40 absolute md:hidden dark:bg-gray-700 dark:text-white text-black left-10 w-10/12   top-38 rounded-3xl  bg-white p-5 shadow-2xl'>
//         <div className='w-full'>
      
//         </div>
//     </div>
//   )
// }

// export default MobileNav


// import React from 'react'

const MobileNav = ({ closeNav }) => {
  const [navState, setNavState] = useContext(NavbarContext);
  const { assetsTabPerm, maintenanceTabPerm, leasesTabPerm, operatorsTabPerm } = useAuthorities();

  const onClickTabChange = (number) => {
      setNavState(number);
    };


  return (
    <>
      <div className="
      rounded bg-violet-950 absolute z-50 
      w-[400px] items-center flex flex-col 
      justify-center dark:bg-sky-900 lg:hidden"
      >
      <div className="max-w-7xl mx-auto py-4 px-2">
        <div className="flex justify-end">
           
          <button onClick={closeNav} className="text-white">
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {/* Your navigation links go here */}
            <ul className="mt-4 pl-0 ">
                    <li className="mb-2  py-2"
                        onClick={() => onClickTabChange(1)}
                    >
                        <Link
                        to="/dashboard"
                        href="#tb-dashboard"
                        id="nav-tab1"
                        className={`navbar-tab inlne cursor-pointer text-sm  font-medium  
                           px-5 py-3 
                           block leading-normal rounded-xl  
                           dark:text-slate-100 hover:transition-all hover:delay-100  
                        ${
                          navState === 1
                            ? "bg-slate-100  text-black dark:bg-gray-800"
                            : "text-white"
                        } `}
                      >
                        <img
                          src={dashboard}
                          alt="dashboard"
                          className="inline mx-2"
                        />
                        <p className="inline hover:bg-slate-700">Dashboard</p>
                      </Link>
                    </li>

                    {assetsTabPerm && (

                        <li className="mb-2  py-2"
                        onClick={() => onClickTabChange(2)}
                        >
                        <Link
                            to="/assets"
                            href="#tab-assets"
                            id="nav-tab2"
                            className={`navbar-tab inlne cursor-pointer text-sm  
                              font-medium   px-5 py-3  block leading-normal rounded-xl 
                              dark:text-slate-100 hover:transition-all hover:delay-100  
                            ${
                              navState === 2
                                ? "bg-slate-100 text-black dark:bg-gray-800"
                                : "text-white"
                            } `}
                          >
                            <img
                              src={assets}
                              alt="assets"
                              className="inline mx-2 hover:bg-slate-700"
                            />
                            Assets
                          </Link>
                    </li>
                        )}

                    
                    {maintenanceTabPerm && (
                        <li className="mb-2 py-2 rounded-md"
                        onClick={() => onClickTabChange(3)}
                        >
                        <Link
                            to="/maintenance"
                            href="#tab-maintenance"
                            id="nav-tab3"
                            className={`flex p-2 rounded-lg navbar-tab inlne cursor-pointer text-sm  font-medium   px-5 py-3  leading-normal  dark:text-slate-100 text-black hover:transition-all hover:delay-100  ${
                              navState === 3
                                ? "bg-slate-100 text-black dark:bg-gray-800"
                                : "text-white"
                            } `}
                          >
                            <img
                              src={maintanance}
                              alt="maintanance"
                              className="inline mx-2 hover:bg-slate-700"
                            />{" "}
                            Maintenance
                          </Link>
                        </li>
                    )}
                  
                
                  {leasesTabPerm && (

                        <li className="mb-2py-2"
                            onClick={() => onClickTabChange(4)}
                        >
                              <Link
                            to="/lease"
                            href="#tab-lease"
                            id="nav-tab4"
                            className={`navbar-tab inlne cursor-pointer text-sm  font-medium   px-5 py-3 max-[1279px]:rounded-2xl rounded-t-2xl block leading-normal  dark:text-slate-100 hover:transition-all hover:delay-100  ${
                              navState === 4
                                ? "bg-slate-100 text-black dark:bg-gray-800"
                                : "text-white"
                            } `}
                          >
                            <img src={lease} alt="lease" className="inline mx-2 hover:bg-slate-700" />{" "}
                            Lease
                          </Link>
                            
                        </li>
                  )}

            {operatorsTabPerm && (
                        <li className="mb-2  py-2"
                        onClick={() => onClickTabChange(6)}
                        >
                            <Link
                            to="/operators"
                            id="nav-tab6"
                            className={`navbar-tab inlne cursor-pointer text-sm  font-medium   px-5 py-3 rounded-xl block leading-normal  dark:text-slate-100 hover:transition-all hover:delay-100  ${
                              navState === 6
                                ? "bg-slate-100 text-black dark:bg-gray-800"
                                : "text-white"
                            } `}
                          >
                            <img
                              src={wrench}
                              alt="operator"
                              className="inline mx-2 w-[24px] h-[24px] hover:bg-slate-700"
                            />{" "}
                            Operator
                          </Link>
                        </li>
            )}
          </ul>
              
      </div>
    </div>
    </>
  )
}

export default MobileNav