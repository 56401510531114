import React, { useState } from "react";
import { formatTime, formatTimeDuration } from "./Tabs/FormatDate";
import { BsBoxArrowInRight } from "react-icons/bs";
import { Button } from "@mui/material";

const TimeLineDuration = ({ devId, setShowMachine, handleBottomTab, timeLine }) => {
  let dates = [];

  for (let i = 0; i < Object.keys(timeLine.working_time).length; i++) {
    dates.push(Object.keys(timeLine.working_time)[i]);
  }

  const header = ["Date", "Start Time", "End Time", "Duration", "Total Instance"];
  const [openDetails, setOpenDetails] = useState(null); // Tracks selected date

  return (
    <>
      <div className="pb-20 bg-white rounded-[6px] shadow-md">
        <div className="">
          {/* Table Container */}
          <div className="overflow-hidden rounded-[4px] border items-left flex flex-col px-2 pt-6 ">
            <h1 className="text-xs font-bold mb-4 text-dark-6 px-4">Working Time</h1>
            <table className="table-auto w-full text-xs text-left text-dark-6 font-normal divide-y p-4">
              {/* Table Header */}
              <thead className="text-dark-8 border-b">
                <tr>
                  {header.map((col) => (
                    <td key={col} className="px-4 py-2 font-bold text-dark-6">
                      {col}
                    </td>
                  ))}
                </tr>
              </thead>
              {/* Table Body */}
              <tbody className="divide-y">
                {dates.reverse().map((date) => (
                  <React.Fragment key={date}>
                    {/* Table Row */}
                    <tr className="hover:bg-gray-50">
                      <td className="px-4 py-2">{date}</td>
                      <td className="px-4 py-2">
                        {timeLine.working_time[date].dispute_time
                          ? `${formatTime(timeLine.working_time[date].dispute_time.start)} (dispute time)`
                          : formatTime(timeLine.working_time[date].start)}
                      </td>
                      <td className="px-4 py-2">
                        {timeLine.working_time[date].dispute_time
                          ? `${formatTime(timeLine.working_time[date].dispute_time.end)} (dispute time)`
                          : formatTime(timeLine.working_time[date].end)}
                      </td>
                      <td className="px-4 py-2">
                        {formatTimeDuration(timeLine.working_time[date].duration)}
                      </td>
                      <td className="px-4 py-2 flex gap-3 items-center justify-between w-[140px]">
                        <span>{timeLine.instance_time[date]?.length || 0}</span>
                        {/* <button
                          className=" bg-blue-2 text-white px-1.5 py-1 rounded transition flex items-center gap-1 font-semibold"
                          onClick={() => setOpenDetails(date)} 
                        >
                        Open
                        </button> */}
                        <Button
                         onClick={() => setOpenDetails(date)} 
                         variant="contained"
                         sx={{
                           fontSize: "12px",
                           color: "white",
                           textTransform: "none", // To disable uppercase text transformation
                         }}
                        >
                        <BsBoxArrowInRight className="h-3 w-3 font-bold mr-2" />
                          Open</Button>
                      </td>
                    </tr>

                    {/* Modal */}
                    {openDetails === date && (
                      <>
                        <div className="fixed z-50 left-[10%] top-[10%] w-[80%] bg-white p-6">
                          {/* Modal Header */}
                          <Button
                          variant="outlined"
                          sx={{
                            color: '#7C7C7C',
                            borderColor:'#7C7C7C',
                            fontSize: 12
                          }}
                            onClick={() => setOpenDetails(null)} // Close modal
                          >
                            Close
                          </Button>

                          <div className="flex mt-6 items-center justify-between mb-4">
                            <h2 className="text-xs font-semibold text-dark-6">
                              Details for: {date}
                            </h2>
                          </div>
                          {/* Details Table */}
                          <div className="overflow-auto h-[400px] border rounded-[6px] hide-scrollbar px-6 pt-2">
                            <table className="w-full text-[10px] text-dark-6">
                              <thead className="border-b">
                                <tr>
                                  <th className="px-4 py-2">Instance No</th>
                                  <th className="px-4 py-2">Start Time</th>
                                  <th className="px-4 py-2">End Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {timeLine.instance_time[date]?.map((item, i) => (
                                  <tr key={i} className="border-b">
                                    <td className="px-4 py-2">{i + 1}</td>
                                    <td className="px-4 py-2">
                                      {formatTimeDuration(item.start)}
                                    </td>
                                    <td className="px-4 py-2">
                                      {formatTimeDuration(item.end)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* Overlay */}
                        <div
                          className="bg-[#00000009] bg-opacity-25 fixed inset-0 z-40"
                          onClick={() => setOpenDetails(null)} // Close modal on overlay click
                        />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeLineDuration;
