import React, { useRef } from "react";

const LeaseSearch = ({
  setFilter,
  handleSearch,
  handleSearchClick,
  searchValue,
  open,
  setOpen,
  setOpenSearch,
}) => {
  const inputRef = useRef(null); // Create a ref for the input field

  const handleSearchIconClick = () => {
    setOpen(true); // Open the search input
    setTimeout(() => {
      inputRef.current?.focus(); // Set focus to the input field
    }, 0); // Ensure the input is visible before focusing
  };

  return (
    <>
      <div className="flex">
        <select
          className={`flex-shrink-0 z-10 inline-flex items-center h-7  bg-white shadow-md text-purple-1 rounded-full transition-all ease-in-out duration-500 focus:border-none text-xs border-none focus:outline-none focus:ring-0 
          ${open ? "opacity-100" : "opacity-0 delay-0 hidden"}`}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="rso_ids">RSO No</option>
          <option value="assetnumber">Asset Number</option>
          <option value="category">Category</option>
          <option value="yom">YOM</option>
          <option value="customer_name">Customer</option>
        </select>

        <button
          className="p-2 rounded-[5.6px] items-center text-xs font-normal text-center hover:bg-[#eee]"
          onClick={handleSearchIconClick} // Focus input on icon click
        >
          <svg
            className="w-4 h-4 text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </button>

        <input
          ref={inputRef} // Attach ref to the input field
          type="text"
          placeholder={searchValue === "" ? "Search" : searchValue}
          className={`h-7 border-none focus:outline-none bg-transparent focus:ring-0 focus:border-none text-xs transition-all duration-500 ease-in-out ${
            open ? "w-[150px] opacity-100" : "w-0 opacity-0"
          }`}
          onChange={handleSearch}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleSearchClick(searchValue);
            }
          }}
          onClick={() => setOpenSearch(true)}
        />
      </div>
    </>
  );
};

export default LeaseSearch;
