import React from "react";
import { currency, formatDate } from "./FormatDate";


const AssetCommercial = ({ commercialDetails }) => {
  const TotalLandedCost = parseFloat(
    commercialDetails[0]?.total_landed_cost
  ).toFixed(2);

  const TotalLandedCostWithGST = parseFloat(
    commercialDetails[0]?.total_landed_cost_with_gst
  ).toFixed(2);

  const BOENum = commercialDetails[0]?.boe_no;

  const insurance = commercialDetails[0]?.insurance_renewal
    ? formatDate(parseInt(commercialDetails[0]?.insurance_renewal))
    : "--";

  const CommercialData = [
    { title: "Total Landed Cost", label: `₹${currency(TotalLandedCost)}` },
    { title: "Ex-Works Price", label: `$${currency(commercialDetails[0]?.exworks_price)}` },
    { title: "CIF Charges", label: `$${currency(commercialDetails[0]?.cif_charges)}` },
    { title: "Total Cost (Ex-Works + CIF)", label: `$${currency(commercialDetails[0]?.total_cost)}` },
    { title: "Port of Clearance", label: commercialDetails[0]?.port_of_clearance || "--" },
    { title: "Date of Clearance", label: '--' },  // Example with no data
    { title: "Purchase Order No", label: commercialDetails[0]?.purchase_order_no || "--" },
    { title: "Purchase Order Date", label: formatDate(commercialDetails[0]?.purchase_order_date) },
    { title: "Insurance Renewal", label: insurance },
    { title: "Exchange Rate (at Remittance)", label: `₹${commercialDetails[0]?.exchange_rate_rem}` },
    { title: "Amount of Remitted OEM", label: `₹${currency(commercialDetails[0]?.amount_rem_to_oem)}` },
    { title: "Date of Remittance to OEM", label: formatDate(commercialDetails[0]?.date_of_rem_to_oem) },
    { title: "BOE No", label: BOENum },
    { title: "Exchange Rate (at BOE)", label: `₹${commercialDetails[0]?.exrate_boe}` },
    { title: "Custom Duty Value", label: `₹${currency(commercialDetails[0]?.custom_duty_value)}` },
    { title: "Clearing Charges", label: `₹${currency(commercialDetails[0]?.clearing_charges)}` },
    { title: "CHA Charge", label: `₹${currency(commercialDetails[0]?.cha_charges)}` },
    { title: "GST Amount", label: `${commercialDetails[0]?.gst_amount === null || "nill"  ? '--' :  `₹${currency(commercialDetails[0]?.gst_amount)}`}` },
    { title: "Total Landed Cost with GST", label: `${TotalLandedCostWithGST=== null || "nill" ? '--' : `₹${currency(TotalLandedCostWithGST)}`}` },
    { title: "Transportation Charges (INR)", label: commercialDetails[0]?.transportation_charges ? `₹${currency(commercialDetails[0]?.transportation_charges)}` : "--" }
  ];

  return (
    <>
    
    {CommercialData !== null ? <>
    <div className="p-6 border text-xs rounded-2xl w-full">
      <h1 className="text-[#4b5563] font-extrabold mb-4">
        Asset Commercial Details
      </h1>
      <div className="grid grid-cols-4 gap-4">
        {/* First column with first 6 items */}
        <div className="space-y-0">
          {CommercialData?.slice(0, 6).map((item, index) => (
            <div className="border flex justify-between p-2 items-center" key={index}>
              <div className="font-normal">{item.title}</div>
              <div className={`block font-semibold text-xs ${item.cssLabel || ''}`}>
                {item.label}
              </div>
            </div>
          ))}
        </div>

        {/* Second column */}
        <div className="space-y-0">
          {CommercialData.slice(6, 11).map((item, index) => (
            <div className="border flex justify-between p-2 items-center" key={index}>
              <div className="font-normal">{item.title}</div>
              <div className={`block font-semibold text-xs ${item.cssLabel || ''}`}>
                {item.label}
              </div>
            </div>
          ))}
        </div>

        {/* Third column */}
        <div className="space-y-0">
          {CommercialData.slice(11, 16).map((item, index) => (
            <div className="border flex justify-between p-2 items-center" key={index}>
              <div className="font-normal">{item.title}</div>
              <div className={`block font-semibold text-xs ${item.cssLabel || ''}`}>
                {item.label}
              </div>
            </div>
          ))}
        </div>

        {/* Fourth column */}
        <div className="space-y-0">
          {CommercialData.slice(16).map((item, index) => (
            <div className="border flex justify-between p-2 items-center" key={index}>
              <div className="font-normal">{item.title}</div>
              <div className={`block font-semibold text-xs ${item.cssLabel || ''}`}>
                {item.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </> : <div className="text-xs px-10">No Data Available</div>}

   
    </>
  );
};

export default AssetCommercial;
