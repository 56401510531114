import { createContext, useContext, useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = useCallback((message, type = 'info', duration = 10000) => {
        const id = Date.now();
        setToasts((prev) => [...prev, { id, message, type }]);
    
        setTimeout(() => {
            setToasts((prev) => prev.filter((toast) => toast.id !== id));
        }, duration);
    }, []);

    const removeToast = useCallback((id) => {
        setToasts((prev) => prev.filter((toast) => toast.id !== id));
    }, []);

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {/* Toast Container */}
            <div className="fixed top-14 left-0 right-0 z-[9999] flex flex-col items-center space-y-3 pointer-events-none">
                <AnimatePresence>
                    {toasts.map((toast) => (
                        <motion.div
                            key={toast.id}
                            initial={{ opacity: 0, y: -20, scale: 0.9 }}
                            animate={{ opacity: 1, y: 0, scale: 1 }}
                            exit={{ opacity: 0, x: 50 }}
                            transition={{ type: "spring", damping: 25, stiffness: 300 }}
                            className={`relative flex items-start w-full max-w-md min-w-[300px] p-4 rounded-xl shadow-lg pointer-events-auto ${
                                toast.type === 'success' 
                                    ? 'bg-green-500 text-white' :
                                toast.type === 'error' 
                                    ? 'bg-red-500 text-white' : 
                                    'bg-blue-500 text-white'
                            }`}
                        >
                            {/* Icon based on toast type */}
                            <div className="flex-shrink-0 mr-3">
                                {toast.type === 'success' ? (
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                    </svg>
                                ) : toast.type === 'error' ? (
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                ) : (
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                )}
                            </div>
                            
                            {/* Toast content */}
                            <div className="flex-1">
                                <p className="text-sm font-medium">{toast.type.toUpperCase()}</p>
                                <p className="mt-1 text-sm">{toast.message}</p>
                            </div>
                            
                            {/* Close button */}
                            <button 
                                onClick={() => removeToast(toast.id)}
                                className="ml-4 -mr-1 -mt-1 flex-shrink-0 p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-colors"
                                aria-label="Close toast"
                            >
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
            
            {children}
        </ToastContext.Provider>
    );
};