// import React from "react";
// import exportFromJSON from "export-from-json";
// import axios from "axios";
// import { setBaseUrl } from "../../config";

// function ExcelReportBtn({ tabName }) {
//   const handleClick = async () => {
//     let setDownloadUrl = "";
//     if (tabName == "assets") {
//       setDownloadUrl = `${setBaseUrl}/asset/get_excel_json`;
//     } else if (tabName == "maintenance") {
//       setDownloadUrl = `${setBaseUrl}/maintenance/get-json-data`;
//     } else if (tabName == "lease") {
//       setDownloadUrl = `${setBaseUrl}/lease/get-json-data`;
//     } else {
//       setDownloadUrl = `${setBaseUrl}/operator/get-json-data`;
//     }
//     try {
//       const { data } = await axios.get(setDownloadUrl, {
//         headers: {
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//       });
//       console.log(data);
//       // const dataModified = data.map((item) => {
//       //   let tempItem = {
//       //     ...item,
//       //     ...item.commercial_detail,
//       //     ...item.config_data,
//       //   };
//       //   delete tempItem.commercial_detail;
//       //   delete tempItem.config_data;
//       //   return tempItem;
//       // });
//       const fileName = `All ${tabName} List`;
//       const exportType = exportFromJSON.types.xls;
//       exportFromJSON({ data: data, fileName, exportType });
//     } catch (error) {
//       console.error(error);
//       alert(error.message);
//     }
//   };
//   return (
//     <button
//       className="flex button text-white bg-lime-500 py-2 px-2 rounded-md shadow-md mr-2 text-xs sm:text-sm cursor-pointer"
//       onClick={handleClick}
//     >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="24px"
//         height="24px"
//         viewBox="0 0 24 24"
//         fill="none"
//         stroke="currentColor"
//         stroke-width="2"
//         stroke-linecap="round"
//         stroke-linejoin="round"
//         className="w-4 h-4 mr-2 feather feather-download"
//       >
//         <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
//         <polyline points="7 10 12 15 17 10"></polyline>
//         <line x1="12" y1="15" x2="12" y2="3"></line>
//       </svg>{" "}
//       Excel Report
//     </button>
//   );
// }

// export default ExcelReportBtn;





// import React from "react";
// import exportFromJSON from "export-from-json";
// import axios from "axios";
// import { setBaseUrl } from "../../config";

// import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

// function ExcelReportBtn({ tabName }) {
//   const handleClick = async () => {
//     let setDownloadUrl = "";
//     if (tabName === "assets") {
//       setDownloadUrl = `${setBaseUrl}/asset/get_excel_json`;
//     } else if (tabName === "maintenance") {
//       setDownloadUrl = `${setBaseUrl}/maintenance/get-json-data`;
//     } else if (tabName === "lease") {
//       setDownloadUrl = `${setBaseUrl}/lease/get-json-data`;
//     } else {
//       setDownloadUrl = `${setBaseUrl}/operator/get-json-data`;
//     }
//     try {
//       const { data } = await axios.get(setDownloadUrl, {
//         headers: {
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//       });
//       const fileName = `All ${tabName} List`;
//       const exportType = exportFromJSON.types.xls;
//       exportFromJSON({ data: data, fileName, exportType });
//     } catch (error) {
//       console.error(error);
//       alert(error.message);
//     }
//   };

//   return (
//     <button
//       // className="flex items-center m-2   justify-center button text-white hover:bg-emerald-600 dark:hover:bg-emerald-900 dark:bg-emerald-500 bg-emerald-800 py-2 px-2 sm:py-3 sm:px-4 rounded-md shadow-md mr-2 text-xs sm:text-sm cursor-pointer"
//       className="m-2 flex items-center justify-center hover:bg-emerald-600 dark:hover:bg-emerald-900 dark:bg-emerald-500 bg-emerald-800 text-white py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 text-sm cursor-pointer"
//       onClick={handleClick}
//     >
//      <DownloadOutlinedIcon
//       className="p-1"
//      />
//       Excel Report
//     </button>
//   );
// }

// export default ExcelReportBtn;



import React from "react";
import exportFromJSON from "export-from-json";
import axios from "axios";
import { setBaseUrl } from "../../config";

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

function ExcelReportBtn({ tabName }) {
  const handleClick = async () => {
    let setDownloadUrl = "";
    if (tabName === "assets") {
      setDownloadUrl = `${setBaseUrl}/asset/get_excel_json`;
    } else if (tabName === "maintenance") {
      setDownloadUrl = `${setBaseUrl}/maintenance/get-json-data`;
    } else if (tabName === "lease") {
      setDownloadUrl = `${setBaseUrl}/lease/get-json-data`;
    } else  {
      setDownloadUrl = `${setBaseUrl}/operator/get-json-data`;
    }
    try {
      const { data } = await axios.get(setDownloadUrl, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      const fileName = `All ${tabName} List`;
      const exportType = exportFromJSON.types.xls;
      exportFromJSON({ data: data, fileName, exportType });
      console.log(data)
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  return (
    <button
      className="text-xs flex justify-center rounded-md  items-center border border-[#006DB6] fixed right-[2%] bottom-3  w-[120px] h-7  text-[#006DB6] "
      onClick={handleClick}
    >
     <DownloadOutlinedIcon className=" p-1 "/>
      Excel Report
    </button>
  );
}

export default ExcelReportBtn;
