// // import React from 'react'

// // function MISReportBtn() {
// //   return (
// //     <button className="flex button text-white bg-sky-700 py-2 px-2 md:py-4 w-28 md:w-20  rounded-md shadow-md mr-2 md:mr-2 text-xs sm:text-sm cursor-pointer">
// //               <svg
// //                 xmlns="http://www.w3.org/2000/svg"
// //                 width="24px"
// //                 height="24px"
// //                 viewBox="0 0 24 24"
// //                 fill="none"
// //                 stroke="currentColor"
// //                 stroke-width="2"
// //                 stroke-linecap="round"
// //                 stroke-linejoin="round"
// //                 className="w-4 h-4 mr-2 feather feather-download"
// //               >
// //                 <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
// //                 <polyline points="7 10 12 15 17 10"></polyline>
// //                 <line x1="12" y1="15" x2="12" y2="3"></line>
// //               </svg>{" "}
// //               MIS Report
// //             </button>
// //   )
// // }

// // export default MISReportBtn


// import React from 'react';
// import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

// function MISReportBtn() {
//   return (
//     <button 
//     // className="flex items-center m-2 justify-center button text-white bg-sky-800 hover:bg-sky-600 hover:dark:bg-sky-900 dark:bg-sky-500 py-2 px-2 sm:py-4 sm:px-4 w-28 md:w-36 rounded-md shadow-md mr-2 sm:mr-2 text-xs sm:text-sm cursor-pointer"
//     className=' m-2 flex items-center justify-center bg-sky-800 hover:bg-sky-600 hover:dark:bg-sky-900 dark:bg-sky-500 text-white  py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 text-sm cursor-pointer'
//     >
//       <DownloadOutlinedIcon
//        className='text-white p-1'
//       />
//       MIS Report
//     </button>
//   );
// }

// export default MISReportBtn;


import React from 'react';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

function MISReportBtn() {
  return (
    <button 
      className=' m-2 text-sm w-[150px] h-10 rounded text-white bg-blue-500'
    >
      <DownloadOutlinedIcon className='text-white p-0 mr-2'/>
      MIS Report
    </button>
  );
}

export default MISReportBtn;
