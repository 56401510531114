import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setBaseUrl } from '../../config';
import Select from "react-select";
import { toast } from 'react-toastify';
import Layout from '../Layout';

export const UpdateEmployee = () => {

  const { email } = useParams();
  // console.log(email)

  const navigate = useNavigate();

  const selectOptions = [
    {
      value: "ASSETS.ALL",
      label: "ASSETS.ALL",
    },
    { value: "ASSETS.VIEW", label: "ASSETS.VIEW" },
    { value: "ASSETS.CRU", label: "ASSETS.CRU" },
    { value: "MAINT.ALL", label: "MAINT.ALL" },
    { value: "MAINT.CRU", label: "MAINT.CRU" },
    { value: "MAINT.VIEW", label: "MAINT.VIEW" },

    { value: "LEASE.ALL", label: "LEASE.ALL" },
    { value: "LEASE.CRU", label: "LEASE.CRU" },
    { value: "LEASE.VIEW", label: "LEASE.VIEW" },
    { value: "OPERATOR.ALL", label: "OPERATOR.ALL" },
    { value: "OPERATOR.CRU", label: "OPERATOR.CRU" },
    { value: "OPERATOR.VIEW", label: "OPERATOR.VIEW" },
    { value: "ADMIN.ALL", label: "ADMIN.ALL" },
  ];

  const [selectedOption, setSelectedOption] = useState([]);
  const [employeeDetail, setEmployeeDetail] = useState({ email: email, reset_password: 1 });
  const [removePermission, setRemovePermission] = useState([])



  const getAllPermissions = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/company/view-permissions-for-update`,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "email": email,

          }
        })
      // console.log(data.message);
      // Map response to correct format
      // const formattedPermissions = data.message.map((perm) => ({
      //   value: perm,
      //   label: perm,
      // }));
      const preselected = data.message.map((item) => ({
        value: item.id,
        label: item.perms,
      }));

      setSelectedOption(preselected);
    } catch (error) {
      console.error(error)
      alert(error.message)
    }

  }

  useEffect(() => {
    getAllPermissions();
  }, [])

  // const handleChange = (selected) => {
  //   const removed = selectedOption.filter(
  //     (prev) => !selected.some((current) => current.value === prev.value).map((item) => item.value)
  //   );

  //   setRemovePermission((prev) => [...prev, ...removed]); // Store removed permissions
  //   setSelectedOption(selected); // Update selected permissions
  // };

  const handleChange = (selected) => {
    // Find permissions that were previously selected but not in the new selection
    const removed = selectedOption
      .filter((prev) => !selected.some((current) => current.value === prev.value))
      .map((item) => ({ id: item.value })); // Convert to object format { id: value }

    setRemovePermission((prev) => [...prev, ...removed]); // Store removed permissions
    setSelectedOption(selected); // Update selected permissions
  };



  const handleUpdatePermissions = async (e) => {
    e.preventDefault();

    let updatePermissions = {}; // Use let instead of const

    if (removePermission.length > 0 && selectedOption.length > 0) {
      updatePermissions = {
        email: email,
        perms_for_remove: removePermission, // Already formatted as [{ id: "uuid" }]
        perms_for_add: selectedOption.map((item) => ({ title: item.label })), // Convert selected options to { title: "LABEL" }
      };
    } else if (removePermission.length > 0) {
      updatePermissions = {
        email: email,
        perms_for_remove: removePermission,
      };
    } else if (selectedOption.length > 0) {
      updatePermissions = {
        email: email,
        perms_for_add: selectedOption.map((item) => ({ title: item.label })),
      };
    } else {
      alert("Please select at least one permission");
      return;
    }

    // console.log(updatePermissions); // Verify the structure before making API call


    try {
      const response = await fetch(`${setBaseUrl}/company/edit-permissions`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", "x-access-tokens": sessionStorage.getItem("token"), },

        body: JSON.stringify(updatePermissions),
      });

      if (!response.ok) throw new Error("Failed to update permissions");

      const data = await response.json();
      toast.success("Permissions updated successfully!!")
    } catch (error) {
      console.error("Error updating permissions:", error);
      toast.error("Failed to update permissions. Please check odoo logs and try again!!")
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();



    try {
      const res = await fetch(`${setBaseUrl}/company/add-employee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(employeeDetail),

        // body: JSON.stringify({
        //   email: email,
        //   permissions: selectOption? selectOption.value : []
        // }),

      })

      if (!res.ok) throw new Error("Failed to add employee");
      toast.success("Employee password reset successfully!")
    } catch (error) {
      console.error(error)
      toast.error("Failed to reset employee password!")
    }

  }




  // /company/edit-permissions

  return (
  

    <>
    <Layout>

      <div className="h-full w-full mt-5 p-5  dark:bg-gray-800 text-gray-800 dark:text-white">
        <div className="flex justify-start items-center  pb-2">
          <button
            onClick={() => navigate('/settings')}
            className="buttons border bg-white text-black"
          >
            Back
          </button>
        </div>

        <div className="mt-10 bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 max-w-md mx-auto">
          <h2 className="text-md font-semibold text-center mb-4">Employee Details</h2>

          <div className="flex flex-col gap-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-1">
                Email:
              </label>
              <input
                type="email"
                name="email"
                value={email}
                required
                placeholder="Enter email"
                className="p-2  text-xs w-full bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
                onChange={(e) =>
                  setEmployeeDetail({ ...employeeDetail, email: e.target.value })
                }
              />
            </div>

            {/* <div>
              <label htmlFor="name" className="block text-sm font-medium mb-1">
                Name:
              </label>
              <input
                type="text"
                name="name"
                required
                placeholder="Enter employee name"
                className="p-2  text-xs w-full bg-light-4 border-none focus:border-none focus:outline-none focus:ring-0 rounded"
                onChange={(e) =>
                  setEmployeeDetail({ ...employeeDetail, name: e.target.value })
                }
              />
            </div> */}

            <div>
              <label htmlFor="permissions" className="block text-sm font-medium mb-1">
                Choose Permissions:
              </label>
              {/* <Select
                isMulti
                onChange={setSelectedOption}
                options={selectOptions}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "180px",
                    fontSize: 10,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    width: "59vh",
                    // height: "20px",
                    border:
                      state.isFocused || state.isHovered
                        ? "none"
                        : "1px solid transparent",
                    borderBottom: "1px solid #D1D5DB",
                    boxShadow: state.isFocused
                      ? "0.2px 0.2px 0.2px 0.2px rgba(156, 163, 175, 0.25)"
                      : null,
                    outline: "none",
                    borderRadius: 0,
                  }),
                }}
              /> */}
              {/* {selectOption.length > 0 && } */}
              {/* <Select
                isMulti
                value={selectOption ? selectOption : ''} // Bind selected values
                onChange={setSelectedOption}
                options={selectOptions}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "180px",
                    fontSize: 10,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    width: "59vh",
                    border: state.isFocused || state.isHovered ? "none" : "1px solid transparent",
                    borderBottom: "1px solid #D1D5DB",
                    boxShadow: state.isFocused
                      ? "0.2px 0.2px 0.2px 0.2px rgba(156, 163, 175, 0.25)"
                      : null,
                    outline: "none",
                    borderRadius: 0,
                  }),
                }}
              /> */}

              <Select
                isMulti
                options={selectOptions} // Show all permissions
                value={selectedOption} // Display preselected permissions
                onChange={handleChange} // Update on change
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "180px",
                    fontSize: 10,
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    width: "59vh",
                    border: state.isFocused || state.isHovered ? "none" : "1px solid transparent",
                    borderBottom: "1px solid #D1D5DB",
                    boxShadow: state.isFocused
                      ? "0.2px 0.2px 0.2px 0.2px rgba(156, 163, 175, 0.25)"
                      : null,
                    outline: "none",
                    borderRadius: 0,
                  }),
                }}
              />
            </div>




            <div className="flex gap-2 items-center">
              <button className="text-xs font-semibold w-full px-4 py-2 bg-green-2 text-white"
                onClick={(e) => handleUpdatePermissions(e)}
              >
                Update Permission
              </button>
              <button
                type="submit"
                className="text-xs w-full px-4 py-2 font-semibold text-green-2 bg-white border border-green-2"
                onClick={(e) => {
                  setEmployeeDetail({
                    ...employeeDetail,
                    reset_password: true
                  })
                  handleSubmit(e)
                }}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>

    </>
  )
}
