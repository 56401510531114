import React, { useState } from "react";
import NewMachine from "./components/NewMachine";
import BasicFormData from "./components/BasicFormData";
import { useGetAsset, useGetCustomer } from "./api/newLeaseApi";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css";

const CreateComponent = ({ leaseData, setLeaseData }) => {
  const [loading, setLoading] = useState(false); // Create loading state
  let [machineData, setMachineData] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  // Add machine logic
  const addMachine = (e) => {
    e.preventDefault();

    const newMachine = {
      asset: "", // Asset will be selected later
      rental_start_date: "",
      rental_end_date: "",
      select_number_of_operators: "",
      select_operators: [], // This will store the selected operators
      total_working_hours_in_month: "",
      // total_working_days:'',
      nominal_hours_per_day: "",
      normal_rate: "",
      contract_value: "",
      overtime_rate: "",
      expected_revenue_without_overtime: "",
      monthly_rental_amount: "",
      normal_amount:'',
      overtime_amount: '',
      total_claimable_amount: '',
      
    };

    // Add the new machine to the machineData array
    setMachineData([...machineData, newMachine]);
    setCount((prevCount) => prevCount + 1); // Increment the machine count
  };

  // Form submit logic
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true); // Set loading to true when API call starts

  //   const submittedData = {
  //     ...leaseData, // Include the lease data
  //     machines: machineData, // Include the machine data
  //   };

  //   try {
  //     // API call to submit the lease data
  //     const { data } = await axios.post(
  //       `${setBaseUrl}/lease/create`,
  //       submittedData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );

  //     // Handle successful response
  //     navigate(`/lease`);
  //   } catch (error) {
  //     if (error.response && error.response.data.error) {
  //       alert(error.response.data.error);
  //     } else {
  //       alert("An unexpected error occurred");
  //     }
  //     // Navigate back on error
  //     navigate(`/lease/newLease`);
  //   } finally {
  //     setLoading(false); // Set loading to false when API call finishes
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when API call starts
  
    const submittedData = {
      ...leaseData, // Include the lease data
      machines: machineData, // Include the machine data
    };
  
 
    try {
    //   // API call to submit the lease data
      const { data } = await axios.post(
        `${setBaseUrl}/lease/create`,
        submittedData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
  
      // Handle successful response
      toast.success("Lease created successfully!"); // Show success toast
      navigate(`/lease`);
    } catch (error) {
      if (submittedData.rental_end_date < submittedData.rental_start_date) {
        toast.error("Rental End Date must be Greater than Start Date")
      }  else if (!submittedData.asset_no ) {
        toast.error("Please Add Asset")
      }  else if (!submittedData.monthly_rental_amount ) {
        toast.error("Please Add Monthly Rental Amount")
      } else {
        toast.error("An unexpected error occurred"); // Show general error toast
      }
  

    //   navigate(`/lease/newLease`);
    } finally {
      setLoading(false); // Set loading to false when API call finishes
    }
  };

  const { customerArray } = useGetCustomer(`${setBaseUrl}/lease/get-customer`);


  return (
    <>
      <div className="mt-2">
        <form className="text-xs space-y-2 bg-white p-5 shadow-md" onSubmit={handleSubmit}>
          {/* <h1>Add Basic Details</h1> */}
          <div className="flex justify-start gap-10 border p-5">
            <div className="flex flex-col">
              <BasicFormData
                name="Customer Name"
                type=""
                keyNameType="customer"
                label={leaseData.customer}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
                customerArray={customerArray}
              />
              <BasicFormData
                name="Customer PO No"
                type="text"
                keyNameType="customer_po_number"
                label={leaseData.customer_po_number}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              <BasicFormData
                name="Internal Reference Number"
                type="text"
                keyNameType="internal_reference_number"
                label={leaseData.internal_reference_number}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />

              <BasicFormData
                name="Lease Type"
                keyNameType="lease_type"
                label={leaseData.lease_type}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              <BasicFormData
                name="Asset Location"
                type="text"
                keyNameType="asset_location"
                label={leaseData.asset_location}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
            </div>
            <div className="flex flex-col">
              <BasicFormData
                name="Total Working Days/Month"
                type="text"
                keyNameType="total_working_days"
                label={leaseData.total_working_days}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              <BasicFormData
                name="Currency"
                type="text"
                keyNameType="currency"
                label={leaseData.currency}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              <BasicFormData
                name="Transport Charges(MOB)"
                type="text"
                keyNameType="transport_charges_with_mob"
                label={leaseData.transport_charges_with_mob}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              <BasicFormData
                name="Transport Charges(De-Mob)"
                type="text"
                keyNameType="transport_charges_without_mob"
                label={leaseData.transport_charges_without_mob}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              {/* <BasicFormData
                name="Claimable Amount"
                type="text"
                keyNameType="total_claimable_amount"
                label={leaseData.total_claimable_amount}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
                
              /> */}
              <BasicFormData
                name="Reimbursements"
                type="text"
                keyNameType="asset_location"
                label={leaseData.reimbursements}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
                
              />
            </div>
          </div>

          {/* <h1>Add New Machine</h1> */}
          <button
            className="bg-blue-2 rounded-[2px] shadow-md  text-white px-2 py-1"
            onClick={addMachine}
          >
            Add Machine
          </button>

          {/* Pass machine data and other relevant props to NewMachine */}
          <NewMachine
            count={count}
            machineData={machineData}
            setMachineData={setMachineData}
            selectedOperator={selectedOperator}
            setSelectedOperator={setSelectedOperator}
           
          />

<button className="bg-blue-2 rounded-[2px] shadow-md  text-white px-2 py-1" type="submit" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
        </form>
      </div>
    </>
  );
};

export default CreateComponent;
