// import React, { useEffect, useState } from "react";
// import Layout from "../../components/Layout";
// import { Link, useNavigate } from "react-router-dom";
// import { setBaseUrl } from "../../config";

// const Logistics = () => {
// const [logisticsData, setLogisticsData] = useState([]);
// const [loading, setLoading] = useState(false);
// const navigate = useNavigate();

// const getLogistics = async () => {
//   setLoading(true);
//   try {
//     const res = await fetch(`${setBaseUrl}/lease/get-logistic`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "x-access-tokens": sessionStorage.getItem("token"),
//       },
//     });

//     const data = await res.json();
//     setLogisticsData(data);
//   } catch (error) {
//     console.error("Error fetching logistics data:", error);
//     alert("Something error please reach sometimes later or reload!!!!")
//   } finally {
//     setLoading(false);
//   }
// };

// useEffect(() => {
//   getLogistics();
// }, []);

//   // console.log(logisticsData)
//   return (
//     <Layout>
//       <h1 className="text-base font-bold text-gray-700 mb-4">Logistics Data</h1>
//       <Link to={"/new-logistics"}>
//         <button className=" rounded-[6px] text-xs p-1 ">New</button>
//       </Link>

//       <div className="overflow-x-auto mt-1 hide-scrollbar">
//         <table className="min-w-full divide-y border-collapse border bg-[#EBEBEB] text-[10px]">
//           <thead>
//             <tr className="font-normal ">
//               <td className="p-2 ">RSO Number</td>
//               <td className="p-2 ">Asset</td>
//               <td className="p-2 ">Date of Dispatch</td>
//               <td className="p-2 ">Mob/Demob</td>
//               <td className="p-2 ">From Location</td>
//               <td className="p-2 ">Transporter Name</td>
//               <td className="p-2 ">
//                 Transporter Bill No
//               </td>
//               <td className="p-2 ">
//                 Transporter Bill Date
//               </td>
//               <td className="p-2 ">E-Way Bill Number</td>
//               <td className="p-2 ">Total KM</td>
//               <td className="p-2 ">Bill Amount</td>
//               <td className="p-2 ">
//                 Transportation Cost
//               </td>
//               <td className="p-2 ">
//                 Amount Paid by Customer
//               </td>
//               <td className="p-2 ">Rate/km</td>
//               <td className="p-2 ">Arrival Date</td>
//               <td className="p-2 ">Destination</td>
//               <td className="p-2 ">DC No.</td>
//               <td className="p-2 ">DC Date</td>
//               <td className="p-2 ">Truck No</td>
//               <td className="p-2 ">Truck Ft</td>
//               <td className="p-2 ">MOB/Invoice</td>
//               <td className="p-2 ">De-MOB/Invoice</td>
//             </tr>
//           </thead>
//           <tbody className="divide-y">
//             {loading ? (
// <tr>
//   <td colSpan="11" className="text-center p-4">
//     Loading...
//   </td>
// </tr>
//             ) : (
//               logisticsData.length > 0 &&
//               logisticsData?.map((item, index) => (
//                 <React.Fragment key={index} className="">
//                   {item.LogisticMap.map((logisticItem, logisticIndex) => (
//                     <tr
//                     key={logisticIndex}
//                     className="bg-white"
// onClick={() =>
//   navigate(`/update-logistics/${item.id}`)
// }
//                     >

//                       <td className="p-2 ">
//                         {logisticItem.odoo_order_id}
//                       </td>
//                       <td className="p-2 ">
//                         {logisticItem.asset_no}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.date_of_dispatch}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.mob_demob_state}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.origin}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.transporter_bill_no}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.transporter_bill_date}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.courier_company}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.awb_number}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.total_km}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {`$${item.bill_amount}`}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         ${`$${item.transportation_cost}`}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {`$${item.amount_paid_by_customer}`}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.rate_per_km}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.arrival_date}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.destination}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.dc_no}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.dc_date}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.truck_no}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.truck_ft}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.mob_as_per_invoice}
//                       </td>
//                       <td
//                         className="p-2 "
//                         rowSpan={item.LogisticMap.length}
//                       >
//                         {item.demob_as_per_invoice}
//                       </td>
//                     </tr>
//                   ))}
//                 </React.Fragment>
//               ))
//             )}
//           </tbody>
//         </table>
//       </div>
//     </Layout>
//   );
// };

// export default Logistics;

import React, { useEffect, useState } from "react";
import { FiEdit, FiShare, FiSettings, FiTrash2 } from "react-icons/fi";
// import { BsThreeDotsVertical } from "react-icons/bs";
import Layout from "../../components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { setBaseUrl } from "../../config";
import { IoAddOutline } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";
const Logistics = () => {
  const [openMenu, setOpenMenu] = useState(null); // Dropdown state

  const [logisticsData, setLogisticsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getLogistics = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-logistic`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      const data = await res.json();
      setLogisticsData(data);
    } catch (error) {
      console.error("Error fetching logistics data:", error);
      alert("Something error please reach sometimes later or reload!!!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLogistics();
  }, []);

  const [openData, setOpenData] = useState(false);


  

  return (
    <Layout>
      <div className="p-6">
        {/* Action Buttons */}
        <div className="flex justify-between gap-4 items-center space-x-4 mb-4">
          <h1 className="text-dark-6 text-xs font-bold">Logistics Table</h1>
          <Link to={"/new-logistics"} className="flex items-center justify-center text-xs bg-blue-2 text-white rounded-[2px] px-2 py-1 shadow-md ">
            <IoAddOutline className="mr-2 h-5 w-5" /> New
          </Link>
        </div>

        {/* Table */}
        <div className="overflow-x-auto text-[11px] p-5 bg-white rounded-[4px] shadow-md">
          <table className="min-w-full text-left ">
            <thead className="border-b text-dark-6">
              <tr>
                <th className="px-4 py-2">
                  <input type="checkbox" className="focus:outline-none focus:ring-0" />
                </th>
                <th className="px-4 py-2">RSO No</th>
                <th className="px-4 py-2">Asset</th>
                <th className="px-4 py-2">Dispatch Date</th>
                <th className="px-4 py-2">Mob/DeMob</th>
                <th className="px-4 py-2"></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6" className="text-center p-4">
                    Loading...
                  </td>
                </tr>
              ) : (
                <>
                  {logisticsData.length > 0 &&
                    logisticsData?.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.LogisticMap.map((logisticItem, logisticIndex) => (
                          <tr key={logisticIndex} className="border-b text-dark-8 text-[11px] hover:bg-light-11 cursor-pointer" onClick={() =>navigate(`/update-logistics/${item.id}`)}>
                            <td className="px-4 py-2 ">
                              <input type="checkbox" className="focus:outline-none focus:ring-0" />
                            </td>
                            <td className="px-4 py-2">
                              {logisticItem.odoo_order_id}
                            </td>
                            <td className="px-4 py-2">
                              {logisticItem.asset_no}
                            </td>
                            <td className="px-4 py-2">
                              {item.date_of_dispatch}
                            </td>
                            <td className="px-4 py-2">
                              {item.mob_demob_state}
                            </td>
                            <td className="px-4 py-2 text-right relative">
                              <BsThreeDots
                                className="cursor-pointer text-dark-6 h-4 w-4 font-bold"
                                // onClick={() =>
                                //   setOpenMenu(
                                //     openMenu === item.id ? null : item.id
                                //   )
                                // }
                              />
                              {/* {openMenu === item.id && (
                                <>
                                  <div className="fixed z-[50] right-16 mt-0 w-[200.39px] border text-[11px]  bg-white shadow shadow-[#21212124]">
                                    <button
                                      className="block w-full px-4 py-2 text-left hover:bg-light-11"
                                      onClick={() =>
                                        navigate(`/update-logistics/${item.id}`)
                                      }
                                    >
                                      Edit
                                    </button>
                                    <button
                                      className="block w-full px-4 py-2 text-left hover:bg-light-11"
                                      onClick={() =>
                                        setOpenData({
                                          ...logisticItem,
                                          ...item,
                                        })
                                      }
                                    >
                                      Open
                                    </button>

                                
                                  </div>
                                  <div
                                    className="z-0 bg-[#00000000] fixed top-0 bottom-0 left-0 right-0"
                                    onClick={() => setOpenMenu(null)}
                                  />
                                </>
                              )} */}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>

        {/* Modal for displaying full details */}
        {openData && (
          <>
            <div className="bg-white top-[9%] text-xs left-[35%] w-[400px] text-left text-[11px]  h-fit py-4 px-6 overflow-y-auto  z-[9999] fixed shadow-lg">
              <h2 className=" font-semibold mb-4 text-dark-6">Logistics Details</h2>
              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">RSO No:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.odoo_order_id || "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Asset:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.asset_no || "N/A"}
                  </span>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Dispatch Date:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.date_of_dispatch || "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Mob/Demob:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.mob_demob_state || "N/A"}
                  </span>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Origin:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.origin || "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Transporter Bill No:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.transporter_bill_no || "N/A"}
                  </span>
                </div>
              </div>


              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Transporter Bill Date:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.transporter_bill_date || "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Courier Company:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.courier_company || "N/A"}
                  </span>
                </div>
              </div>


              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Awb Number:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.awb_number || "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Total Km</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.total_km || "N/A"}
                  </span>
                </div>
              </div>


              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Bill Amount:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.bill_amount ? `$${openData.bill_amount}` : "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Total Km</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.transportation_cost
                  ? `$${openData.transportation_cost}`
                  : "N/A"}
                  </span>
                </div>
              </div>

           
            
              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Paid by Customer:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.amount_paid_by_customer
                  ? `$${openData.amount_paid_by_customer}`
                  : "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Rate/KM:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.rate_per_km || "N/A"}
                  </span>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Arrival Date:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.arrival_date || "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Destination:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.destination || "N/A"}
                  </span>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">DC No:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.dc_no || "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">DC Date:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.dc_date || "N/A"}
                  </span>
                </div>
              </div>
           
          
              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Truck No:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.truck_no || "N/A"}
                  </span>
                </div>
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Truck Ft:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.truck_ft || "N/A"}
                  </span>
                </div>
              </div>
       

              <div className="flex justify-between">
                <div className="  w-[160px] max-sm:w-full">
                  <span className="text-dark-6 text-[11px]">Invoice as Mob/DeMob:</span> 
                  <span className="font-bold text-[11px] h-[27px] text-dark-6 items-center border rounded-[4px] bg-white flex justify-start px-2">
                  {openData.mob_as_per_invoice || openData.demob_as_per_invoice || "N/A"}
                  </span>
                </div>
               
              </div>
       
           
          
         
         
              <button
                className="bg-blue-2 font-semibold text-white px-3 rounded-[2px] py-1 text-[11px] mt-4 w-[96px] h-[32px] shadow-md"
                onClick={() => setOpenData(null)}
              >
                Close
              </button>
            </div>

            <div
              className="bg-[#0000] z-[500] fixed top-0 bottom-0 left-0 right-0"
              onClick={() => setOpenData(false)}
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default Logistics;
