import React, { useEffect, useState } from "react";
import { get_data_by_categories } from "../../../apis";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function CategoryTable() {
  const [unlinked_assets, setUnlinked_assets] = useState([]);
  const [Leased_assets, setLeased_assets] = useState([]);
  const [maintenance_assets, setMaintenance_assets] = useState([]);
  const [categories, setCategories] = useState([]);

  const getUnlinked_assets = async () => {
    try {
      const { data } = await get_data_by_categories();

      const categoryNames = Object.keys(data);
      const assets = Object.values(data);

      setCategories(categoryNames);

      const temp_unlinked_assets = [];
      const temp_leased_assets = [];
      const temp_maintenance_assets = [];

      assets.forEach(assetArray => {
        temp_unlinked_assets.push(assetArray[0]);
        temp_leased_assets.push(assetArray[1]);
        temp_maintenance_assets.push(assetArray[2]);
      });

      setUnlinked_assets(temp_unlinked_assets);
      setLeased_assets(temp_leased_assets);
      setMaintenance_assets(temp_maintenance_assets);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUnlinked_assets();
  }, []);

  // Prepare the data for the bar chart
  const chartData = {
    labels: categories,
    datasets: [
      {
        label: "Unlinked Assets",
        data: unlinked_assets,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Leased Assets",
        data: Leased_assets,
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
      {
        label: "Maintenance Assets",
        data: maintenance_assets,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Assets by Category",
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="p-5 flex gap-5">
      {/* Table Section */}
      <div className="w-1/2 text-center border h-[281px] overflow-y-auto hide-scrollbar rounded-2xl">
        <table className="text-center text-xs border-separate border-spacing-y-0 w-full relative">
          <thead className="h-10 sticky dark:bg-black">
            <tr className="bg-[#EEE]">
              <th className="rounded-tl-2xl">Category</th>
              <th>Total</th>
              <th>Leased</th>
              <th>Free Assets</th>
              <th className="rounded-tr-2xl">Under Breakdown</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category, index) => {
              let total_value =
                unlinked_assets[index] +
                Leased_assets[index] +
                maintenance_assets[index];
              return (
                <tr key={index} className="h-10 items-center">
                  <td className="border-b">{category}</td>
                  <td className="border-b">{total_value}</td>
                  <td className="border-b">{unlinked_assets[index]}</td>
                  <td className="border-b">{Leased_assets[index]}</td>
                  <td className="border-b">{maintenance_assets[index]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Chart Section */}
      <div className="w-1/2 h-[281px] ">
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
}

export default CategoryTable;
