import React from "react";

function Loader() {
  return (
    <div className="border h-64 p-5 rounded-xl mt-4 shadow-lg w-full max-w-4xl mx-auto mb-5 animate-pulse">
      <div className="space-y-4">
        <div className="h-12 bg-gray-300 rounded-md"></div>
        <div className="h-10 bg-gray-200 rounded-md"></div>
        <div className="h-8 bg-gray-200 rounded-md"></div>
        <div className="h-8 bg-gray-200 rounded-md hidden sm:block"></div>
      </div>

      {/* Mobile-Specific Loader Elements */}
      <div className="mt-6 space-y-3 sm:hidden">
        <div className="h-8 bg-gray-200 rounded-md"></div>
        <div className="h-8 bg-gray-200 rounded-md"></div>
      </div>

      {/* Tablet/Desktop Loader Elements */}
      <div className="mt-6 space-y-3 hidden sm:block md:hidden">
        <div className="h-10 bg-gray-300 rounded-md"></div>
      </div>
    </div>
  );
}

export default Loader;


