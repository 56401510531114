import React, { useContext, useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { setBaseUrl } from "../../../config";
import { toast } from "react-toastify";

import { PermissionContext } from "../../../Context/PermissionsContext";
import { formatDateForInput } from "../../../customFunctions/FormatDate";

function BasicDetails({
  assetBasicDetails,
  setAssetBasicDetails,
  id,
  updateAssetDetails,
  setUpdateAssetDetails,
}) {
  const categories = [
    "Telescopic Boom",
    "Truck Mounted",
    "Battery Scissor",
    "Articulating Boom",
    "Articulating Battery Boom",
    "AWP",
    "Diesel Scissors",
  ];

  const handleSelectChange = (e) => {
    setAssetBasicDetails({
      ...assetBasicDetails,
      category: e.target.value,
    });
    {
      setUpdateAssetDetails({
        ...updateAssetDetails,
        category: e.target.value,
      });
    }
  };
  useEffect(() => {
    setAssetBasicDetails({
      ...assetBasicDetails,
      category: categories[0],
    });
    // console.log(assetBasicDetails);
  }, []);

  const [perms] = useContext(PermissionContext);
  const [checkAuthority, setCheckAuthority] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [otp, setOtp] = useState("");

  // console.log(assetBasicDetails.is_sold,"is_sold")

  // const [confirmSold, setConfirmSold] = useState({is_sold : "false"})
  let is_sold = "true";

  const getOtpFunction = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/asset/get-otp-sold-asset`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": id,
        },
      });
      if (res === 200) {
        alert("OTP Has been sent to your email");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const verifyOtp = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/asset/update-otp-sold-asset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        // credentials: "include",
        body: JSON.stringify({ id, otp, is_sold }),
      });

      if (res === 200) {
        setIsVerify(true);
        // setAssetBasicDetails({...assetBasicDetails, is_sold: true})
        // alert("Verified")
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      alert("Verification Failed");
    }
  };

  const handleSoldVerification = () => {
    verifyOtp();
    setCheckAuthority(false);
  };

  const handleIsSold = () => {
    if (!assetBasicDetails?.device_no || assetBasicDetails?.device_no === " ") {
      getOtpFunction();
    } else {
      toast.error("Please remove the tracker!!");
    }
    // setAssetBasicDetails({ ...assetBasicDetails, is_sold: true })
  };

  const startYear = 2000;
  const endYear = new Date().getFullYear() + 2;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );

  return (
    <>
      <div className="p-10 text-xs gap-4 z-20  border bg-white dark:bg-slate-900 grid grid-cols-1 md:grid-cols-4 ">
        {/*Asset No  */}
        <div title="Enter Valid Asset Number" className="flex-1 w-[200px] ">
          <label className="font-bold">
            Asset No
            <span className="text-red-1 font-bold">*</span>
          </label>
          <input
            type="text"
            value={assetBasicDetails?.asset_no}
            placeholder="e.g MA1234"
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
            onChange={(e) => {
              const emojiRegex =
                /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  asset_no: e.target.value,
                });
                {
                  setUpdateAssetDetails({
                    ...updateAssetDetails,
                    asset_no: e.target.value,
                  });
                }
              } else {
                e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
              }
            }}
          />
        </div>
        {/* Make */}
        <div title="Enter Manufacturer Name" className="flex-1 w-[200px] ">
          <label className="font-bold">
            Make
            <span className="text-red-1 font-bold">*</span>
          </label>
          <input
            type="text"
            value={assetBasicDetails?.make}
            placeholder="e.g. Genie"
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
            onChange={(e) => {
              const emojiRegex =
                /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  make: e.target.value,
                });
                {
                  setUpdateAssetDetails({
                    ...updateAssetDetails,
                    make: e.target.value,
                  });
                }
              } else {
                e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
              }
            }}
          />
        </div>
        {/* Model */}
        <div title="Enter Valid Model Number" className="flex-1 w-[200px] ">
          <label className="font-bold">
            Model
            <span className="text-red-1 font-bold">*</span>
          </label>
          <input
            type="text"
            value={assetBasicDetails?.model}
            placeholder="e.g. sz1234"
            onChange={(e) => {
              const emojiRegex =
                /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  model: e.target.value,
                });
                {
                  setUpdateAssetDetails({
                    ...updateAssetDetails,
                    model: e.target.value,
                  });
                }
              } else {
                e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
              }
            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div>

        {/* Serial No */}
        <div title="Enter Valid Serial Number" className="flex-1 w-[200px] ">
          <label className="font-bold">
            Serial Number
            <span className="text-red-1 font-bold">*</span>
          </label>
          <input
            type="text"
            maxLength={25}
            value={assetBasicDetails?.serial_no}
            placeholder="e.g., GS46D-12345"
            onChange={(e) => {
              const emojiRegex =
                /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  serial_no: e.target.value,
                });
                {
                  setUpdateAssetDetails({
                    ...updateAssetDetails,
                    serial_no: e.target.value,
                  });
                }
              } else {
                e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
              }
            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div>
       
        {/* Purchased from */}
        <div
          title="Enter From whom we are purchasing"
          className="flex-1 w-[200px] "
        >
          <label className="font-bold">
            Purchased from
            <span className="text-red-1 font-bold">*</span>
          </label>
          <input
            type="text"
            maxLength={40}
            value={assetBasicDetails?.purchased_from}
            placeholder="purchased from"
            onChange={(e) => {
              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  purchased_from: e.target.value,
                });
                {
                  setUpdateAssetDetails({
                    ...updateAssetDetails,
                    purchased_from: e.target.value,
                  });
                }
               
              }  else {
                e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
              }

            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div>
        {/* RFID */}
        {/* <div className="flex-1 w-[200px] ">
          <label className="font-bold">
            RFID
            <span className="text-red-1 font-bold">*</span>
          </label>
          <input
            type="text"
            value={assetBasicDetails?.rfid}
            placeholder="RFID Not Required"
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                rfid: e.target.value,
              });
              {
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  rfid: e.target.value,
                });
              }
            }}
            disabled={true}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div> */}

        {/* Device No */}
        <div
          title="Enter Registered Device Number"
          className="flex-1 w-[200px] "
        >
          <label className="font-bold">Device No</label>
          <input
            type="text"
            value={assetBasicDetails?.device_no || null}
            placeholder="Device Hash Not Required"
            onChange={(e) => {

              const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {
                
                const trimmedValue = e.target.value.toUpperCase().trim(); // Remove spaces
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  device_no: trimmedValue,
                });
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  device_no: trimmedValue,
                });
              } else {
                e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
              }

            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div>
        {assetBasicDetails?.device_no && <>
        
        {/* installation_date */}
        <div
          title="Enter Device Installation Date"
          className="flex-1 w-[200px] "
        >
          <label className="font-bold">Device Installation Date</label>
          <input
            min="2000-01-01"
            type="date"
            value={formatDateForInput(assetBasicDetails?.installation_date)}
            placeholder="Device Hash Not Required"
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                installation_date: e.target.value,
              });
              {
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  installation_date: e.target.value,
                });
              }
            }}
            // disabled={assetBasicDetails.device_no ? false : true}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div>
        </>}
        {/* YOM */}
        <div title="Enter Year of Manufacture" className="flex-1 w-[200px] ">
          {/* <label className="font-bold">
            Year of Manufacturing
            <span className="text-red-1 font-bold">*</span>
          </label>
          <input
            type="number" min="1900" max="2099" step="1" 
            value={assetBasicDetails?.yom}
            placeholder="Year of Manufacturing"
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                yom: e.target.value,
              });
              {
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  yom: e.target.value,
                });
              }
            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          /> */}
          <label htmlFor="year-picker" className="font-bold">
            Year of Manufacturing
          </label>
          <select
            id="year-picker"
            className={`text-black focus:outline-none border-none focus:border-none w-full mt-2 p-2 bg-light-4 font-medium rounded-md text-xs
       `}
            value={assetBasicDetails.yom}
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                yom: e.target.value,
              });
            }}
          >
            <option value="" disabled>
              Select Year
            </option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        {/* Site Location */}
        <div title="Enter Site Location" className="flex-1 w-[200px] ">
          <label className="font-bold">Site Location</label>
          <input
            type="text"
            value={assetBasicDetails?.site_location}
            placeholder="e.g. Chennai"
            required
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                site_location: e.target.value,
              });
              {
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  site_location: e.target.value,
                });
              }
            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div>
        {/* Height of Machine */}
        <div
          title="Enter height of the machine in ft."
          className="flex-1 w-[200px] "
        >
          <label className="font-bold text-black">
            Height of Machine(ft.)
          </label>
          <input
            type="number"
            min={0}
            maxLength={25}
            value={assetBasicDetails?.hieght_machine}
            placeholder="e.g. 10 ft"
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                hieght_machine: e.target.value,
              });
              {
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  hieght_machine: e.target.value,
                });
              }
            }}
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div>
        {/* Capacity of machine */}
        <div
          title="Enter Capacity of Machine in kg."
          className="flex-1 w-[200px] "
        >
          <label className="font-bold text-black">
            Capacity of Machine(kg.)
          </label>
          <input
            type="number"
            min={0}
            maxLength={25}
            value={assetBasicDetails?.capacity}
            placeholder="e.g. 50 k.g."
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                capacity: e.target.value,
              });
              {
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  capacity: e.target.value,
                });
              }
            }}
            className="text-black focus:outline-none focus:ring-1 focus:ring-green-2 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
          />
        </div>
        {/* Rating */}
        <div title="Enter Ratings" className="flex-1 w-[200px] ">
          <label className="font-bold">Ratings(out of 5)</label>
          <select
            type="text"
            value={assetBasicDetails?.rating}
            placeholder="e.g. 10 ft"
            onChange={(e) => {
              setAssetBasicDetails({
                ...assetBasicDetails,
                rating: e.target.value,
              });
              {
                setUpdateAssetDetails({
                  ...updateAssetDetails,
                  rating: e.target.value,
                });
              }
            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        {/* Cateogry */}
        <div class="flex-1 w-[200px]" title="Enter Category">
          <label className="font-bold">
            Category
            <span className="text-red-1 font-bold">*</span>
          </label>
          {/* <select
            // className="border-2 rounded-md p-2 text-xs text-black"
          value={assetBasicDetails?.category || ''}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
            onChange={handleSelectChange}
          >
            {!assetBasicDetails?.category && assetBasicDetails?.category === 'nill' && <option>Select Category</option>}
            {categories.map((category) => (
              <option
                key={category}
                value={category}
                selected={assetBasicDetails?.category === category}
              >
                {category}
              </option>
            ))}
          </select> */}
          {/* <select
  value={assetBasicDetails?.category}
  onChange={(e) => {
    const selectedCategory = e.target.value;
    setAssetBasicDetails({
      ...assetBasicDetails,
      category: selectedCategory,
    });
    setUpdateAssetDetails({
      ...updateAssetDetails,
      category: selectedCategory,
    });
  }}
  className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
>
  {categories.map((category) => (
    <option key={category} value={category}>
      {category}
    </option>
  ))}
</select> */}

          <select
            value={
              assetBasicDetails?.category === "nill" ||
              !assetBasicDetails?.category
                ? ""
                : assetBasicDetails?.category
            }
            onChange={(e) => {
              const selectedCategory = e.target.value;
              setAssetBasicDetails({
                ...assetBasicDetails,
                category: selectedCategory,
              });
              setUpdateAssetDetails({
                ...updateAssetDetails,
                category: selectedCategory,
              });
            }}
            className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          >
            <option value="" disabled>
              Select Category
            </option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        {/* Description */}
        <div className="flex-1 w-[200px] " title="Enter Desciption">
          <label className="font-bold">Description</label>
          <textarea
            rows="2"
            maxLength={250}
            value={assetBasicDetails?.description}
            placeholder="Description must not be greater than 250 words."
            onChange={(e) => {
              const emojiRegex =
                /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
              if (!emojiRegex.test(e.target.value)) {
                setAssetBasicDetails({
                  ...assetBasicDetails,
                  description: e.target.value,
                });
                {
                  setUpdateAssetDetails({
                    ...updateAssetDetails,
                    description: e.target.value,
                  });
                }
              } else {
                e.target.value = e.target.value.replace(emojiRegex, ""); // Remove emojis from input
              }
            }}
            className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
          />
        </div>

        {/* machine sold function */}

        {/* Admin */}

        {perms.indexOf("ADMIN.ALL") > -1 ? (
          <>
            {id ? (
              <>
                <div className="flex-1 w-[200px]" title="Machine Sold">
                  <label className="font-bold">Machine Sold</label>
                  <div className="flex gap-4">
                    {/* Radio button for 'Yes' */}
                    <label className="flex items-center">
                      <input
                        title="Select Yes if machine is sold"
                        type="radio"
                        name="is_sold"
                        value={true}
                        checked={assetBasicDetails.is_sold === true}
                        onChange={handleIsSold}
                        className="mr-2 focus:ring-0"
                        onClick={() => setCheckAuthority(true)}
                      />
                      Yes
                    </label>

                    {/* Radio button for 'No' */}
                    <label className="flex items-center">
                      <input
                        title="Select No if machine is not sold"
                        type="radio"
                        name="is_sold"
                        value={false}
                        checked={
                          assetBasicDetails.is_sold === false ||
                          assetBasicDetails.is_sold === null
                        }
                        onChange={handleIsSold}
                        className="mr-2 focus:ring-0"
                        onClick={() => setCheckAuthority(false)}
                      />
                      No
                    </label>
                  </div>
                  {/* Avijit */}
                  {/* Machine Sold Date */}
                  {/* {console.log(assetBasicDetails.is_sold)} */}

                  {assetBasicDetails.is_sold === true && (
                    <>
                      <div
                        className="flex-1 w-[200px] mt-3"
                        title="Machine Sold Date"
                      >
                        <label className="font-bold">Machine Sold Date</label>
                        <input
                          type="date"
                          value={formatDateForInput(
                            assetBasicDetails?.asset_sold_date
                          )} //avijit
                          onChange={(e) => {
                            setAssetBasicDetails({
                              ...assetBasicDetails,
                              asset_sold_date: e.target.value, //avijit
                            });
                            {
                              setUpdateAssetDetails({
                                ...updateAssetDetails,
                                asset_sold_date: e.target.value,
                              });
                            }
                          }}
                          className="text-black   focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                        />
                      </div>
                    </>
                  )}
                </div>
                {checkAuthority && (
                  <>
                    <div className="z-[9999] text-xs p-4 h-[250px] w-[500px] fixed top-[20%] text-black left-[30%] rounded bg-white">
                      <button
                        className=""
                        onClick={() => setCheckAuthority(false)}
                      >
                        Close
                      </button>
                      <div className="items-center justify-center flex flex-col gap-6">
                        <h1
                          title="Enter OTP number"
                          className="font-extrabold text-[#8d8d8d]"
                        >
                          Enter OTP
                        </h1>
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{ color: "black", width: "50px" }}
                            />
                          )}
                        />
                        <button
                          title="Click to verify OTP"
                          className="bg-light-1 text-white w-[67px] rounded h-7"
                          onClick={handleSoldVerification}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                    <div
                      className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000021] z-50 "
                      onClick={() => setCheckAuthority(false)}
                    />
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default BasicDetails;
