import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import UpdateForm from "./components/UpdateForm";

const UpdateLogistics = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  // Fetch logistics data by ID
  const getLogisticsData = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-logistic-id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });

      if (res.ok) {
        const data = await res.json();
        setFormData(data); // Initialize formData with fetched data
      } else {
        console.error("Failed to fetch logistics data.");
      }
    } catch (error) {
      console.error("Error fetching logistics data:", error);
    }
  };

  // Update logistics data
  const updateLogisticsData = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/update-logistic`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ formData, id }),
      });

      if (res.ok) {
        alert("Logistics data updated successfully!");
      } else {
        alert("Failed to update logistics data.");
      }
    } catch (error) {
      console.error("Error updating logistics data:", error);
    }
    console.log(formData);
  };

  useEffect(() => {
    getLogisticsData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateLogisticsData();
  };

  const handleCancel = () => {
    window.history.back();
  };

  console.log(formData);

  return (
    <>
      <Layout>
        <form
          action=""
          className="border w-full h-full p-4 gap-4 flex flex-col justify-between bg-white  shadow-md rounded-[4px]"
          onSubmit={handleSubmit}
        >
          <div className="flex justify-between">
            <div className="flex justify-between flex-col gap-2">
              <UpdateForm
                setFormData={setFormData}
                title="Asset No."
                keyValueType="assets_no"
                value={formData?.assets_no || "N/A"}
              />
              <UpdateForm
                title="RSO No"
                keyValueType="lease_RSO_nos"
                value={formData?.lease_RSO_nos || "N/A"}
                formData={formData}
                setFormData={setFormData}
              />

              {/* <UpdateForm handleInputChange={handleInputChange}
              title="Arrival Date"
              type="date"
              keyValueType="arrival_date"
              value={formData?.arrival_date || ""}
            /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Arrival Date
                </h1>
                <input
                  type="date"
                  value={formData?.arrival_date || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, arrival_date: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Amount Paid By Customer"
                type="number"
                keyValueType="amount_paid_by_customer"
                value={formData?.amount_paid_by_customer}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Amount Paid By Customer
                </h1>
                <input
                  type="number"
                  value={formData?.amount_paid_by_customer || "N/A"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      amount_paid_by_customer: e.target.value,
                    });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm handleInputChange={handleInputChange}
              title="Awb Number"
              type="number"
              keyValueType="awb_number"
              value={formData?.awb_number}
            /> */}

              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Awb Number
                </h1>
                <input
                  type="number"
                  value={formData?.awb_number || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, awb_number: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Bill Amount"
                type="number"
                keyValueType="bill_amount"
                value={formData?.bill_amount}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Bill Amount
                </h1>
                <input
                  type="number"
                  value={formData?.bill_amount || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, bill_amount: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Courier Company"
                type="text"
                keyValueType="courier_company"
                value={formData?.courier_company}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Courier Company
                </h1>
                <input
                  type="text"
                  value={formData?.courier_company || "N/A"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      courier_company: e.target.value,
                    });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Date Of Dispatch"
                type="date"
                keyValueType="date_of_dispatch"
                value={formData?.date_of_dispatch}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Date Of Dispatch
                </h1>
                <input
                  type="date"
                  value={formData?.date_of_dispatch || "N/A"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      date_of_dispatch: e.target.value,
                    });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Dc Date"
                type="date"
                keyValueType="dc_date"
                value={formData?.dc_date}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Dc Date
                </h1>
                <input
                  type="date"
                  value={formData?.dc_date || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, dc_date: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Dc No"
                type="date"
                keyValueType="dc_no"
                value={formData?.dc_no}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Dc No
                </h1>
                <input
                  type="number"
                  value={formData?.dc_no || "N/A"}
                  placeholder="dc number"
                  onChange={(e) => {
                    setFormData({ ...formData, dc_no: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Demob as per Invoice"
                type="date"
                keyValueType="demob_as_per_invoice"
                value={formData?.demob_as_per_invoice}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Demob as per Invoice
                </h1>
                <input
                  type="number"
                  value={formData?.demob_as_per_invoice || "N/A"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      demob_as_per_invoice: e.target.value,
                    });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
            </div>
            <div className="flex justify-between flex-col gap-2">
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Destination"
                type="text"
                keyValueType="destination"
                value={formData?.destination}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Destination
                </h1>
                <input
                  type="text"
                  value={formData?.destination || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, destination: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Mob as per invoice"
                type="text"
                keyValueType="mob_as_per_invoice"
                value={formData?.mob_as_per_invoice}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Mob as per invoice
                </h1>
                <input
                  type="number"
                  value={formData?.mob_as_per_invoice || "N/A"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mob_as_per_invoice: e.target.value,
                    });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Mob Demob State"
                type="text"
                keyValueType="mob_demob_state"
                value={formData?.mob_demob_state}
              /> */}

              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Mob Demob State
                </h1>
                {/* <input
                  type="text"
                  value={formData?.mob_demob_state}
                  onChange={(e) => {
                    setFormData({ ...formData, mob_demob_state: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                /> */}
                <select
                  name=""
                  id=""
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mob_demob_state: e.target.value,
                    });
                  }}
                  value={formData?.mob_demob_state || "N/A"}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                >
                  <option value="mob">Mob</option>
                  <option value="demob">Demob</option>
                </select>
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Origin"
                type="text"
                keyValueType="origin"
                value={formData?.origin}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Origin
                </h1>
                <input
                  type="text"
                  value={formData?.origin || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, origin: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Rate/km"
                type="text"
                keyValueType="rate_per_km"
                value={formData?.rate_per_km}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Rate/km
                </h1>
                <input
                  type="text"
                  value={formData?.rate_per_km || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, rate_per_km: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Transportation Cost"
                type="text"
                keyValueType="transportation_cost"
                value={formData?.rate_per_km}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Transportation Cost
                </h1>
                <input
                  type="number"
                  value={formData?.transportation_cost || "N/A"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      transportation_cost: e.target.value,
                    });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Transporter bill date"
                type="text"
                keyValueType="transporter_bill_date"
                value={formData?.transporter_bill_date}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Transporter Bill Date
                </h1>
                <input
                  type="date"
                  value={formData?.transporter_bill_date || "N/A"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      transporter_bill_date: e.target.value,
                    });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Transporter Bill No"
                type="text"
                keyValueType="transporter_bill_no"
                value={formData?.transporter_bill_no}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Transporter Bill No
                </h1>
                <input
                  type="text"
                  value={formData?.transporter_bill_no || "N/A"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      transporter_bill_no: e.target.value,
                    });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
              {/* <UpdateForm
                handleInputChange={handleInputChange}
                title="Total Km"
                type="text"
                keyValueType="total_km"
                value={formData?.total_km}
              /> */}
              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Total Km
                </h1>
                <input
                  type="number"
                  value={formData?.total_km || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, total_km: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Truck ft
                </h1>
                <input
                  type="number"
                  value={formData?.truck_ft || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, truck_ft: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>

              <div className="flex justify-between">
                <h1 className="text-[10px] flex items-center justify-center">
                  Truck No
                </h1>
                <input
                  type="text"
                  value={formData?.truck_no || "N/A"}
                  onChange={(e) => {
                    setFormData({ ...formData, truck_no: e.target.value });
                  }}
                  className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
                />
              </div>
            </div>
          </div>
          <div className="flex gap-3">
        
            <button
              className="bg-white text-[10px] h-7 w-[96px] shadow-md border"
              onClick={handleCancel}
            >
              Back
            </button>
            <button className="shadow-md text-white bg-blue-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition">
              Submit
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default UpdateLogistics;
