// import React, { useState } from 'react'
// import { setBaseUrl } from '../../config'
// import { CircularProgress } from '@mui/material';
// import { toast } from 'react-toastify';

// const RecievedPart = ({getWallet}) => {
//     const token = sessionStorage.getItem("sales_token");
//     const [loading, setLoading] = useState(false);
//     const [recievedPart, setRecievedPart] = useState({
//         received:0,
//         id: 0
//     })

//       const removePartFun = async () => {
//         const sumbitData = [recievedPart];
//         console.log(sumbitData);
//         try {
//           const res = await fetch(`${setBaseUrl}/sale-service/part-recived`, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               "auth-token": token,
//             },
//             body: JSON.stringify(sumbitData),
//           });
//           setLoading(false);
//           if (res.status === 200) {
//             toast.success("Part successfully recived");
//           } else {
//             toast.error("Error in reciving parts");
//           }
//         } catch (error) {
//           console.error(error);
//           setLoading(false);
//           toast.error("Error in reciving part! Please Check again");
//         }
//       };
//   return (
//     <div className="flex flex-col gap-4 border border-gray-300 p-6  mt-4 bg-white w-full">
//     {/* Header */}
//     <h3 className="text-lg font-semibold text-gray-800">Received Part</h3>
//     {/* Select Part Available in getWallet*/}
//     <div className="flex flex-col">
//       <label htmlFor="" className="text-sm font-medium text-gray-700">
//         Select Part Number
//       </label>
//       <select
//           name=""
//           id=""
//             onChange={(e) => {
//                 setRecievedPart({ ...recievedPart, id: e.target.value });
//             }}
//           className="border text-xs border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 outline-none"
//         >
//             <option value="">Select</option>
//             {getWallet?.map((item, index) => (
//                 <option key={index} value={item.id}>
//                     {item.part_no}, {item.part_name}
//                 </option>
//             ))}
          
//         </select>
//     </div>

//     {/* Quantity */}
//     <div className="flex flex-col">
//       <label htmlFor="quantity" className="text-sm font-medium text-gray-700">
//         Quantity
//       </label>
//       <input
//         type="number"
//         id="quantity"
//         min={0}
//         maxLength={25}
//         placeholder="Enter Quantity"
//         onChange={(e) => {
//             setRecievedPart({
//                 ...recievedPart,
//                 received: e.target.value,
//             });
//         }}
//         className="border border-gray-300 rounded-md p-2 mt-1 focus:ring-2 focus:ring-blue-400 focus:border-blue-400 outline-none"
//       />
//     </div>

//     {/* Installed Parts */}
//     <button
//     onClick={removePartFun}
//     className="bg-blue-600 hover:bg-blue-700 text-white text-sm font-semibold py-2 px-4 rounded-md transition duration-200">
//    {loading ?  <CircularProgress size={18} sx={{ color: "#fff" }} />  : 'Received Parts'} 
//     </button>
//   </div>
//   )
// }

// export default RecievedPart


import React, { useState } from 'react';
import { setBaseUrl } from '../../config';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';

const ReceivedPartTable = ({ getWallet }) => {
    const token = sessionStorage.getItem("sales_token");
    const [loading, setLoading] = useState(false);
    const [receivedPart, setReceivedPart] = useState({
        received: 0,
        id: 0
    });

    const removePartFun = async () => {
        const submitData = [receivedPart];
        console.log(submitData);
        try {
            const res = await fetch(`${setBaseUrl}/sale-service/part-recived`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token,
                },
                body: JSON.stringify(submitData),
            });
            setLoading(false);
            if (res.status === 200) {
                toast.success("Part successfully received");
            } else {
                toast.error("Error in receiving parts");
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            toast.error("Error in receiving part! Please Check again");
        }
    };

    return (
        <div className=" w-full overflow-x-auto">
            {/* <h3 className="text-xs font-semibold text-gray-800 ">Received Part</h3> */}
            
            <table className="w-full border-collapse border border-gray-300">
                {/* <thead>
                    <tr className="bg-gray-100 text-gray-700">
                        <th className="border border-gray-300 p-2 text-left">Select Part Number</th>
                        <th className="border border-gray-300 p-2 text-left">Quantity</th>
                        <th className="border border-gray-300 p-2 text-left">Action</th>
                    </tr>
                </thead> */}
                <tbody>
                    <tr>
                        <td className="border border-gray-300 p-2">
                            <select
                                onChange={(e) => {
                                    setReceivedPart({ ...receivedPart, id: e.target.value });
                                }}
                               className="border border-gray-300 w-[150px] rounded-md p-2 text-xs focus:ring-2 focus:ring-blue-400 focus:border-blue-400 outline-none "
                            >
                                <option value="">Select</option>
                                {getWallet?.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.part_no}, {item.part_name}
                                    </option>
                                ))}
                            </select>
                        </td>
                        
                        <td className="border border-gray-300 p-2">
                            <input
                                type="number"
                                min={0}
                                maxLength={25}
                                placeholder="Enter Quantity"
                                onChange={(e) => {
                                    setReceivedPart({ ...receivedPart, received: e.target.value });
                                }}
                                className="border border-gray-300 w-[200px] rounded-md p-2 text-xs focus:ring-2 focus:ring-blue-400 focus:border-blue-400 outline-none "
                            />
                        </td>
                        
                        <td className="border border-gray-300 p-2 text-center">
                            <button
                                onClick={removePartFun}
                                className="buttons bg-green-2 text-white"
                            >
                                {loading ? <CircularProgress size={18} sx={{ color: "#fff" }} /> : 'Received Parts'}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ReceivedPartTable;
