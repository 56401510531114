import React, { useContext, useEffect, useState } from 'react';
import { LoaderContext } from '../../../Context/LoaderContext';
import { setBaseUrl } from '../../../config';
import axios from 'axios';
import { currency, formatDate } from './Tabs/FormatDate';
import { Link, useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';

function LeaseTab({ asset_id }) {
  console.log(asset_id);

  const setLoader = useContext(LoaderContext);
  const [leaseDetails, setLeaseDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllLease = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/get_all`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": asset_id,
        },
      });
      setLeaseDetails(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllLease();
  }, [asset_id]);

  const sortList = (a, b) => {
    const statusOrder = {
      'active': 1,
      'inactive': 2,
      'never assigned': 3,
    };

    return (statusOrder[a.lease_status] || 4) - (statusOrder[b.lease_status] || 4);
  };

  const navigate = useNavigate();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <h1 className="text-base font-semibold text-dark-6">Lease Details</h1>
      <TableContainer component={Paper}>
        <Table aria-label="Lease details table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Odoo Order Id</TableCell>
              <TableCell align="left" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Lease Status</TableCell>
              <TableCell align="left" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Rental Start Date</TableCell>
              <TableCell align="left" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Rental End Date</TableCell>
              <TableCell align="left" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Total Claimable Account</TableCell>
              {/* <TableCell align="left" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Total Data</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {leaseDetails.sort(sortList).map((lease) => (
              <TableRow
                key={lease.id}
                hover
                onClick={() => navigate(`/lease/leaseDetails/${lease.id}`)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell sx={{ fontSize: '12px' }}>{lease?.odoo_order_id}</TableCell>
                <TableCell
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color:
                      lease?.lease_status === 'inactive'
                        ? 'text.secondary'
                        : lease?.lease_status === 'active'
                        ? 'green.main'
                        : lease?.lease_status === 'never assigned'
                        ? 'red.main'
                        : 'green.main',
                  }}
                >
                  {lease?.lease_status}
                </TableCell>
                <TableCell sx={{ fontSize: '12px' }}>{formatDate(lease?.rental_start_date)}</TableCell>
                <TableCell sx={{ fontSize: '12px' }}>
                  {lease?.rental_extended_date
                    ? formatDate(lease?.rental_extended_date)
                    : formatDate(lease?.rental_end_date)}
                </TableCell>
                <TableCell sx={{ fontSize: '12px' }}>
                  ₹{currency(parseInt(lease?.total_claimable_amount))}
                </TableCell>
                {/* <TableCell sx={{ fontSize: '12px' }}>{lease?.total_data}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default LeaseTab;
