import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import InvoiceTable from "./NewLease/InvoiceTable";

import Select from "react-select";
import Document from "../viewer/Document";
import ImageViewer from "../viewer/ImageViewer";
import Image from "../viewer/Image";
import deleteDocument from "../../apis/files/deleteDocument";
import AddDocument from "../uploads/AddDocument";
import upload_document from "../../utils/DocumentUploader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";
import { capitalizeFirstLetter } from "./AssetDetails/Tabs/FormatDate";

function UpdateLease({ tabNo, setTabNo, leaseId, setLeaseId }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [leasType, setLeaseType] = useState(false);
  const [allAssets, setAllAssets] = useState([]);
  const [allOperators, setAllOperators] = useState([]);
  const [operatorsById, setOperatorsById] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [addOperatorDate, setAddOperatorDate] = useState(new Date());
  const [deleteOperatorDate, setDeleteOperatorDate] = useState(
    moment(new Date()).format("DD-MM-YYYY")
  );
  const [leaseInfo, setLeaseInfo] = useState({
    lease_type: "",
    customer_po_no: "",
    currency: "",
    rental_start_date: "",
    rental_location: "",
    rental_end_date: "",
    rental_extended_date: "",
    operator: "",
    customer: "",
    // lease_status: "",
    contract_value: "",
    transportation_charge: "",
    total_working_days: "",
    nominal_hours_per_day: "",
    normal_amount: "",
    overtime_amount: "",
    reimbursements: "",
    total_claimable_amount: "",
    internal_reference_number: "",
    monthly_rental_amount: "",
    id: id,
    asset_id: "",
    sale_person: "",
    closed_date: "",
    normal_rate: "",
    overtime_rate: ""
  });
  //set invoice info
  //define the object schema
  const invoiceObjectSchema = {
    invoice_name: "",
    invoice_date: "",
    invoice_id: "",
    operator_name: "",
    document: "",
  };
  const [invoiceInfo, setInvoiceInfo] = useState([]);
  const addInvoiceField = () => {
    let modifiedInvoice = [...invoiceInfo, invoiceObjectSchema];
    setInvoiceInfo(modifiedInvoice);
  };

  //target the photos input element by using useRef hook
  const photoInputRef = React.useRef(null);
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [showImage, setShowImage] = useState(null);

  //target the document input element by using useRef hook
  const documentInputRef = React.useRef(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [showDocument, setShowDocument] = useState(null);

  //show existing images
  const [alreadyUploadedImages, setAlreadyUploadedImages] = useState([]);
  //show existing documents
  const [alreadyUploadedDocuments, setAlreadyUploadedDocuments] = useState([]);

  const [updateLeaseData, setUpdateLeaseData] = useState({
    id: id,
  });


  const [salesPerson, setSalesPerson] = useState(null);

  const getSalesPerson = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-sale-persons`, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      const data = await res.json();
      setSalesPerson(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSalesPerson();
  }, []);

  // const ToggleSwitch = () => {
  //   const [isChecked, setIsChecked] = useState(false);

  //   const handleToggle = () => {
  //     setIsChecked(!isChecked);
  //   };

  const [isChecked, setIsChecked] = useState(false);
  const handleToggle = () => {
    setIsChecked(true);
  };

  const get_specific_details = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });

      if (!data) return; // ✅ Prevents errors if data is undefined

      // ✅ Update lease type if applicable
      if (data.lease_type === "wet_lease") {
        setLeaseType(true);
      }

      // ✅ Update lease info safely
      setLeaseInfo((prev) => ({
        ...prev,
        ...data,
        operator: data.operators?.length || 0, // ✅ Safely handle operator count
        asset_id: data.asset_id || prev.asset_id, // 🔹 Preserve previous values if missing
      }));

      // ✅ Update attachments safely
      if (Array.isArray(data.attachments) && data.attachments.length > 0) {
        setAlreadyUploadedDocuments(data.attachments);
      }

      // ✅ Update photos safely
      if (Array.isArray(data.photos) && data.photos.length > 0) {
        const existingPhotos = data.photos.map((item) => item.image_uri);
        setAlreadyUploadedImages(existingPhotos);
      }
    } catch (error) {
      console.error("Error fetching lease details:", error);
    }
  };


  const getAllAssets = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/asset/get_all`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      setAllAssets(data);
    } catch (error) {
      // console.error(error);
    }
  };

  const getInvoices = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/get-invoice`, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
          "lease-id": id,
        },
      });
      // console.log(data);
      setInvoiceInfo(data);
    } catch (error) {
      // console.error(error);
    }
  };

  const getOperatorsById = async () => {
    try {
      const { data } = await axios.get(
        `${setBaseUrl}/lease/get-operator-by-lease-id`,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "lease-id": id,
          },
        }
      );
      // console.log(data);
      let operatorOptions = data.map((item) => {
        return {
          value: item.id,
          label: item.operator_name,
        };
      });
      // console.log(operatorOptions);
      setOperatorsById(operatorOptions);
      return data;
    } catch (error) {
      // console.error(error);
    }
  };

  const getAllOperators = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/get_all`, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
          "available": 1
        },
      });
      // console.log(data);
      const idSpecificOperators = await getOperatorsById();
      // console.log(idSpecificOperators);
      let filteredOperatorList = data.filter((item) => {
        for (let i = 0; i < idSpecificOperators.length; i++) {
          // console.log(item.id);
          // console.log(idSpecificOperators[i].id);
          if (item.id === idSpecificOperators[i].operator_id) {
            // console.log("mile gache");
            return;
          }
        }
        // console.log("maleni");
        return item;
      });
      // console.log(filteredOperatorList);
      let operatorOptions = filteredOperatorList.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setAllOperators(operatorOptions);
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    get_specific_details();
    getAllAssets();
    getInvoices();
    getOperatorsById();
    getAllOperators();
  }, []);

  //set the photo to send to the server and url to show the image
  const handlePhotoChange = (e) => {
    setUploadedPhoto(e.target.files);
    let photoFiles = e.target.files;
    let photoArray = [];
    for (const key in photoFiles) {
      if (photoFiles.hasOwnProperty(key)) {
        photoArray.push(photoFiles[key]);
      }
    }
    setShowImage(photoArray);
  };
  //invoke the image upload input on button click
  const handlePhotoInput = (e) => {
    photoInputRef.current.click();
  };

  //set the document to send to the server
  const handleDocumentChange = (e) => {
    setUploadedDocument(e.target.files);
    let docFiles = e.target.files;
    let docArray = [];
    for (const key in docFiles) {
      if (docFiles.hasOwnProperty(key)) {
        docArray.push(docFiles[key]);
      }
    }
    setShowDocument(docArray);
  };

  //invoke the document upload input on button click
  const handleDocumentUploadInput = (e) => {
    documentInputRef.current.click();
  };

  const handleLeaseTypeSelect = (e) => {
    setLeaseInfo({ ...leaseInfo, lease_type: e.target.value });
    setUpdateLeaseData({ ...updateLeaseData, lease_type: e.target.value });
    if (e.target.value == "wet") setLeaseType(true);
    // else if (e.target.value == "customer_certified") setLeaseType(true);
    else setLeaseType(false);
  };

  // - - - - - - - - - - - functtions for data updates starts- - - - - - - - - - - -

  //upload image
  const upload_image = async (lease_id) => {
    const formData = new FormData();
    formData.append("lease_id", lease_id);
    formData.append("types", "lease");
    if (uploadedPhoto) {
      for (const key in uploadedPhoto) {
        if (uploadedPhoto.hasOwnProperty(key))
          formData.append("photo", uploadedPhoto[key]);
      }
    }
    try {
      // setLoader(true)
      const { data } = await axios.post(
        `${setBaseUrl}/lease/upload_photo`,
        formData,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      // console.error(error);
    } finally {
      // setLoader(false)
    }
  };

  //update invoice
  const updateInvoices = async () => {
    for (let i = 0; i < invoiceInfo.length; i++) {
      // console.log(leaseId);
      // let formData = new FormData();
      // formData.append("invoice_no",invoiceInfo[i].invoice_id)
      // formData.append("invoice_name", invoiceInfo[i].invoice_name)
      // formData.append("invoice_date", invoiceInfo[i].invoice_date)
      // formData.append("operator_name", invoiceInfo[i].operator_name)
      // formData.append("document", invoiceInfo[i].document)
      // formData.append("lease_id", leaseId)

      try {
        const { data } = await axios.put(
          `${setBaseUrl}/lease/update-invoice`,
          invoiceInfo[i],
          {
            headers: {
              // "Content-Type": "application/json",
              "x-access-tokens": sessionStorage.getItem("token"),
            },
          }
        );
      } catch (error) {
        alert(error.message);
        return false;
      }
    }
    return true;
  };

  // console.log(addOperatorDate, "add operator");
  // console.log(deleteOperatorDate, "delete operator");
  const assignOperators = async () => {
    if (selectedOptions.length == 0) return true;
    for (let i = 0; i < selectedOptions.length; i++) {
      try {
        const { data } = await axios.post(
          `${setBaseUrl}/lease/assign-operator`,
          {
            lease_id: id,
            // operator_name: selectedOptions[i].label,
            operator_id: selectedOptions[i].value,
            joining_date: moment(addOperatorDate).format("YYYY-MM-DD"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": sessionStorage.getItem("token"),
            },
          }
        );
      } catch (error) {
        alert(error.message);
        return false;
      }
    }
    return true;
  };

  const deleteOperators = async (mapping_id) => {
    try {
      const { data } = await axios.delete(
        `${setBaseUrl}/lease/delete-mapping-operator-by-id`,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "mapping-id": mapping_id,
            "leaving-date": moment(deleteOperatorDate).format("DD-MM-YYYY"),
          },
        }
      );
      getOperatorsById();
    } catch (error) { }
  };

  // const handleUpdate = async () => {
  //   console.log(leaseInfo);
  //   try {
  //     setLoader(true);
  //     const { data } = await axios.put(
  //       `${setBaseUrl}/lease/update`,
  //       leaseInfo,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     if (uploadedDocument) {
  //       let ifDocumentUploaded = await upload_document(
  //         id,
  //         "lease",
  //         uploadedDocument
  //       );
  //       if (!ifDocumentUploaded) {
  //         return;
  //       }
  //     }
  //     if (uploadedPhoto) await upload_image(id);
  //     if (setLeaseType) {
  //       await updateInvoices();
  //       await assignOperators();
  //     }

  //     alert("Data updated successfully");
  //     navigate("/lease/leaseDetails/" + id);
  //   } catch (error) {
  //     // console.error(error);
  //   } finally {
  //     setLoader(false);
  //   }
  // };
  const handleUpdate = async () => {
    // console.log(leaseInfo);
    // console.log(updateLeaseData)
    try {
      setLoader(true); // Set loading state to true

      const { data } = await axios.put(
        `${setBaseUrl}/lease/update`,
        updateLeaseData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );

      // Handle document upload
      if (uploadedDocument) {
        let ifDocumentUploaded = await upload_document(
          id,
          "lease",
          uploadedDocument
        );
        if (!ifDocumentUploaded) {
          return;
        }
      }

      // Handle photo upload
      if (uploadedPhoto) await upload_image(id);

      // Update lease type and assign operators if necessary
      if (setLeaseType) {
        await updateInvoices();
        await assignOperators();
      }

      // Show success toast
      toast.success("Data updated successfully!");

      // Navigate to lease details page
      navigate("/lease/lease-details/" + id);
    } catch (error) {
      // Show error toast
      toast.error(
        "An error occurred while updating the lease. Please try again."
      );
      console.error(error);
    } finally {
      setLoader(false); // Set loading state to false when the API call finishes
    }
  };
  const [openDelete, setOpenDelete] = useState(false);

  const handleDelete = async () => {
    // console.log("Deleting lease with ID:", id);
    try {
      // Ensure id is passed correctly

      const res = await axios.delete(`${setBaseUrl}/lease/delete`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        data: { id }, // Only stringify the ID
      });

      if (res.status === 200) {
        alert("Deleted Successfully");
      } else {
        throw new Error(`Error: ${res.status} - ${res.statusText}`);
      }
    } catch (error) {
      console.error("Error deleting lease:", error);
      alert("Deletion failed!!");
      setOpenDelete(false);
    }
  };


  let numb = 0;
  if (leaseInfo?.lease_status?.startsWith("expiring")) {
    // Match a number within the string "expiring in X days"
    const match = leaseInfo?.lease_status.match(/\d+/);

    if (match) {
      numb = parseInt(match[0], 10);
    }
  }

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <div id="update-lease" className=" ">
          <div className="flex  justify-start items-center">
            <button
              className="bg-white text-[10px] h-7 w-[96px] shadow-md"
              onClick={() => navigate("/lease/lease-details/" + id)}
            >
              Back
            </button>
            <p className="inline py-5 pl-5 text-xs font-medium text-slate-700 dark:text-slate-200">
              Update Lease
            </p>
          </div>

          <div className="flex animSlideup relative">
            <div className="intro-y box basis-1/2 bg-white dark:bg-slate-900 rounded-lg mx-2 border">
              <div className="p-5 text-xs">
                <div className="mt-0">
                  <label>Lease Type</label>
                  <select
                  value={leaseInfo?.lease_type || ''}
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo, lease_type: e.target.value
                      });

                      setUpdateLeaseData({
                        ...updateLeaseData, lease_type: e.target.value
                      })
                    }}
                  >
                    <option value="">Select</option>
                    <option
                      value="dry"
                      selected={
                        leaseInfo.lease_type === "dry" ? true : false
                      }
                    >
                      Dry
                    </option>
                    <option
                      value="wet"
                      selected={
                        leaseInfo.lease_type === "wet" ? true : false
                      }
                    >
                      Wet
                    </option>
                    {/* <option
                      value="customer_certified"
                      selected={
                        leaseInfo.lease_type === "customer_certified"
                          ? true
                          : false
                      }
                    >
                      Customer Certified
                    </option> */}
                  </select>
                </div>
                <div className="mt-4">
                  <label className="">Asset</label>
                  <select
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    onChange={(e) => {
                      setLeaseInfo({ ...leaseInfo, asset_id: e.target.value });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        asset_id: e.target.value,
                      });
                    }}
                  >
                    {allAssets.map((item) => (
                      <option
                        value={item.id}
                        selected={item.id === leaseInfo.asset_id ? true : false}
                      >
                        {item?.asset_no}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-4">
                  <label className="">Customer PO No</label>
                  <input
                    value={leaseInfo.customer_po_no}
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        customer_po_no: e.target.value,
                      });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        customer_po_no: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="eg: RE673212"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Currency</label>
                  <input
                    type="text"
                    value={leaseInfo.currency}
                    onChange={(e) => {
                      setLeaseInfo({ ...leaseInfo, currency: e.target.value });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        currency: e.target.value,
                      });
                    }}
                    placeholder="Currency (eg: $)"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Rental Start Date</label>
                  <input
                    value={leaseInfo.rental_start_date}
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        rental_start_date: e.target.value,
                      });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        rental_start_date: e.target.value,
                      });
                    }}
                    type="date"
                    placeholder="07-03-2021"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Rental End Date</label>
                  <input
                    value={leaseInfo.rental_end_date}
                    min={leaseInfo.rental_start_date}
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        rental_end_date: e.target.value,
                      });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        rental_end_date: e.target.value,
                      });
                    }}
                    type="date"
                    placeholder="07-03-2021"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Rental Location</label>
                  <input
                    value={leaseInfo.rental_location}
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        rental_location: e.target.value,
                      });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        rental_location: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="e.g. Chennai"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                {/* <div className="mt-4">
                  <label className="">Extended Rental End Date</label>
                  <input
                    value={leaseInfo?.rental_extended_date}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        rental_extended_date: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'rental_extended_date': e.target.value,
                      })}
                    }
                    type="date"
                    placeholder="07-03-2021"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div> */}
                <div className="flex flex-col mt-4">
                  <label className="">Sales Person</label>
                  <select
                    value={leaseInfo?.sale_person}
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        sale_person: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'sale_person': e.target.value,
                      })
                    }
                    }
                  >

                    <option value="">Select</option>
                    {salesPerson?.map((sale) => (
                      <option value={sale.name} key={sale.id}>
                        {sale.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mt-4">
                  <label className="">Number of Operators</label>
                  <input
                    value={leaseInfo?.operator ? leaseInfo?.operator : ''}
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        operator: e.target.value,
                      });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        operator: e.target.value,
                      });
                    }}
                    type="number"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>

                <div>
                  {" "}
                  <div className="mt-4">
                    <label className="">Select Operators</label>
                    <Select
                      defaultValue={[...operatorsById]}
                      isMulti
                      onChange={(e) => setSelectedOptions(e)}
                      name="colors"
                      options={allOperators}
                      className="basic-multi-select"
                      isOptionDisabled={() =>
                        selectedOptions.length >= leaseInfo?.operator
                      }
                      classNamePrefix="select"
                    />
                  </div>
                  {/* <div className="p-2">
                    <div className="text-xs font-bold"> Assigned Operators</div>
                    <div className="">
                      <span className="">Joining Date</span>
                      <input type="date" className="" value={addOperatorDate} />
                    </div>
                    {operatorsById.map((item) => (
                      <div className="w-full flex justify-between p-2">
                        <p className="font-[400]">{item.label}</p>
                        <div className="">
                          <span className="">Joining Date</span>
                          <input
                            type="date"
                            className=""
                            value={deleteOperatorDate}
                          />
                        </div>
                        <button
                          className="text-white bg-red-1  buttons"
                          onClick={() => deleteOperators(item.value)}
                        >
                          delete
                        </button>
                      </div>
                    ))}
                  </div> */}
                  <div className="p-2">
                    <div className="text-xs font-bold"> Assigned Operators</div>
                    <div className="flex justify-between flex-col items-start">
                      <span className="">Joining Date</span>
                      <input
                        type="date"
                        className="text-xs mt-1 px-2 py-1  border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none dark:bg-gray-700 dark:text-white"
                        value={moment(addOperatorDate).format("YYYY-MM-DD")}
                        // value=
                        onChange={(e) =>
                          setAddOperatorDate(new Date(e.target.value))
                        }
                      />
                    </div>
                    {/* {operatorsById.map((item) => (
                      <div
                        key={item.value}
                        className="w-full flex justify-between p-2 items-center"
                      >
                        <p className="font-[400]">{item.label}</p>
                        <div className="">
                          <span className="">Leaving Date</span>
                          <input
                            type="date"
                            className=""
                            value={deleteOperatorDate}
                            onChange={(e) => {

                              setDeleteOperatorDate(new Date(e.target.value));
                              // Update the joining date in state
                              // const updatedOperators = operatorsById.map((op) =>
                              //   op.value === item.value
                              //     ? { ...op, joining_date: e.target.value }
                              //     : op
                              // );
                              // setOperatorsById(updatedOperators);
                            }}
                          />
                        </div>
                        <button
                          className="text-white bg-red-1 buttons"
                          onClick={() => deleteOperators(item.value)}
                        >
                          delete
                        </button>
                      </div>
                    ))} */}
                    {operatorsById.map((item) => (
                      <div
                        key={item.value}
                        className="w-full flex items-center my-2 justify-between p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md border border-gray-200 dark:border-gray-700 hover:shadow-lg transition-all"
                      >
                        {/* Operator Name */}
                        <p className="font-semibold text-gray-700 dark:text-gray-300">
                          {item.label}
                        </p>

                        {/* Leaving Date Input */}
                        <div className="flex flex-col items-start">
                          <span className="text-xs text-gray-500 dark:text-gray-400">
                            Leaving Date
                          </span>
                          <input
                            type="date"
                            className="mt-1 px-2 py-1 text-xs border border-gray-300 dark:border-gray-600 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none dark:bg-gray-700 dark:text-white"
                            value={deleteOperatorDate}
                            onChange={(e) =>
                              setDeleteOperatorDate(new Date(e.target.value))
                            }
                          />
                        </div>

                        {/* Delete Button */}
                        <button
                          className="px-4 py-2 bg-red-500 text-white text-sm font-semibold rounded-md shadow-md hover:bg-red-600 transition-all"
                          onClick={() => deleteOperators(item.value)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="intro-y box basis-1/2 bg-white dark:bg-slate-900 rounded-lg mx-2 border">
              <div className="p-5 text-xs">
                <div className="mt-0">
                  <label>Customer</label>
                  <input
                    value={leaseInfo.customer}
                    onChange={(e) => {
                      setLeaseInfo({ ...leaseInfo, customer: e.target.value });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        customer: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="eg: John Doe"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="flex gap-6 mt-4">
                  <div className="flex-grow-[1]">
                    <label for="rso">Status:</label>
                    <input
                      type="text"
                      value={
                        leaseInfo.lease_status === "inactive" || leaseInfo.lease_status === 'never assigned'
                          ? "Free"
                          : leaseInfo.lease_status === "expired" ? "Exceeded" : leaseInfo.lease_status?.startsWith(
                            "expiring"
                          )
                            ? `Active (${numb} days)` : capitalizeFirstLetter(leaseInfo.lease_status)
                      }
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>

                  <div className="flex-grow-1 text-xs">
                    <label htmlFor="toggle" className="block font-semibold">
                      Close Contract Date:
                    </label>
                    <input
                      type="date"
                      name="closed_date"
                      value={leaseInfo.closed_date || ""}
                      onChange={(e) => {
                        setLeaseInfo({
                          ...leaseInfo,
                          closed_date: e.target.value,
                        });
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          closed_date: e.target.value,
                        });
                      }}
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <div className="flex-grow-[1]">
                    <label for="rso">RSO ID:</label>
                    <input
                      type="text"
                      name="rso"
                      value={leaseInfo.odoo_order_id}
                      onChange={(e) => {
                        setLeaseInfo({
                          ...leaseInfo,
                          odoo_order_id: e.target.value,
                        });
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          odoo_order_id: e.target.value,
                        });
                      }}
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>


                  <div className="flex-grow-[1]">
                    <label for="nominal_hours_per_day">Normal Rate</label>
                    <input
                      type="number"
                      name="normal_rate"
                      min={0}
                      placeholder="Normal Rate"
                      value={parseFloat(leaseInfo.normal_rate).toFixed(2) || ""}
                      // onChange={(e) => {
                      //   setLeaseInfo({
                      //     ...leaseInfo,
                      //     normal_rate: e.target.value,
                      //   });
                      //   setUpdateLeaseData({
                      //     ...updateLeaseData,
                      //     normal_rate: e.target.value,
                      //   });
                      // }}
                      disabled
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>

                  <div className="flex-grow-[1]">
                    <label for="overtime_rate">Overtime Rate</label>
                    <input
                      type="number"
                      name="overtime_rate"
                      min={0}
                      placeholder="Overtime Rate"
                      value={parseFloat(leaseInfo.overtime_rate).toFixed(2) || ""}
                      // onChange={(e) => {
                      //   setLeaseInfo({
                      //     ...leaseInfo,
                      //     overtime_rate: e.target.value,
                      //   });
                      //   setUpdateLeaseData({
                      //     ...updateLeaseData,
                      //     overtime_rate: e.target.value,
                      //   });
                      // }}
                      disabled
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>
                  <div className="flex-grow-[1]">
                    <label for="nominal_hours_per_day">Nominal Hours/day</label>
                    {/* <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength={2}
                      name="nominal_hours_per_day"
                      min={0}
                      placeholder="Nominal Hours/day"
                      value={leaseInfo.nominal_hours_per_day || ""}
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (value.length > 2) {
                          value = value.slice(0, 2); // Limit input to two characters
                        }

                        if (value !== "" && parseInt(value, 10) > 24) {
                          value = "24"; // Restrict input to max 31
                        }

                        setLeaseInfo({
                          ...leaseInfo,
                          nominal_hours_per_day: e.target.value,
                        });
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          nominal_hours_per_day: e.target.value,
                        });
                      }}
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    /> */}
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength={2}
                      name="nominal_hours_per_day"
                      min={0}
                      placeholder="Nominal Hours/day"
                      value={leaseInfo.nominal_hours_per_day || ""}
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (value.length > 2) {
                          value = value.slice(0, 2); // Limit input to two characters
                        }

                        if (value !== "" && parseInt(value, 10) > 24) {
                          value = "24"; // Restrict input to max 24 (previously 31)
                        }

                        setLeaseInfo({
                          ...leaseInfo,
                          nominal_hours_per_day: value, // Use cleaned value
                        });
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          nominal_hours_per_day: value, // Use cleaned value
                        });
                      }}
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />

                  </div>
                  <div className="flex-grow-[1]">
                    <label for="Internal Reference Number">
                      Internal Reference Number
                    </label>
                    <input
                      type="number"
                      name="internal_reference_number"
                      title="internal reference number or OG number"
                      min={0}
                      placeholder="Internal Reference Number"
                      value={leaseInfo.internal_reference_number || ""}
                      onChange={(e) => {
                        setLeaseInfo({
                          ...leaseInfo,
                          internal_reference_number: e.target.value,
                        });
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          internal_reference_number: e.target.value,
                        });
                      }}
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>
                  <div className="flex-grow-[1]">
                    <label for="total_working_days">
                      Total Working Days/Month
                    </label>
                    {/* <input
                         type="text"
                         inputMode="numeric"
                         pattern="[0-9]*"
                         maxLength={2}
                      name="total_working_days"
                      min={0}
                      placeholder="Total Working Days/Month"
                      value={leaseInfo.total_working_days || ""}
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (value.length > 2) {
                          value = value.slice(0, 2); // Limit input to two characters
                        }
          
                        if (value !== "" && parseInt(value, 10) > 31) {
                          value = "31"; // Restrict input to max 31
                        }

                        

                        setLeaseInfo({
                          ...leaseInfo,
                          total_working_days: e.target.value,
                        });
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          total_working_days: e.target.value,
                        });
                      }}
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    /> */}
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength={2}
                      name="total_working_days"
                      min={0}
                      placeholder="Total Working Days/Month"
                      value={leaseInfo.total_working_days || ""}
                      onChange={(e) => {
                        let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                        if (value.length > 2) {
                          value = value.slice(0, 2); // Limit input to two characters
                        }

                        if (value !== "" && parseInt(value, 10) > 31) {
                          value = "31"; // Restrict input to max 31
                        }

                        setLeaseInfo({
                          ...leaseInfo,
                          total_working_days: value, // Use cleaned value
                        });
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          total_working_days: value, // Use cleaned value
                        });
                      }}
                      className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />

                  </div>
                  <label className="">Contract Value</label>
                  <input
                    value={leaseInfo.contract_value}
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        contract_value: e.target.value,
                      });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        contract_value: e.target.value,
                      });
                    }}
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />

                  <label className="">Monthly rental amount</label>
                  <input
                
                    maxLength={25}
                    min={0}
                    value={leaseInfo.monthly_rental_amount}
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        monthly_rental_amount: e.target.value,
                      });
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        monthly_rental_amount: e.target.value,
                      });
                    }}
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                {/* <div className="mt-4">
                  <label className="">Transportation Charges</label>
                  <input
                    value={leaseInfo.transportation_charge}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        transportation_charge: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'transportation_charge': e.target.value,
                      })}
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div> */}
                <div className="mt-4">
                  <label className="">Normal Amount</label>
                  <input
                    value={
                      leaseInfo.normal_amount
                        ? parseFloat(leaseInfo.normal_amount).toFixed(2)
                        : ""
                    }
                    disabled
                    onChange={(e) => {
                      setLeaseInfo({
                        ...leaseInfo,
                        normal_amount: e.target.value,
                      });
                      // setUpdateLeaseData({
                      //   ...updateLeaseData,
                      //   'normal_amount': e.target.value,
                      // })
                    }}
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Overtime Amount</label>
                  <input
                    value={
                      leaseInfo.overtime_amount
                        ? parseFloat(leaseInfo.overtime_amount).toFixed(2)
                        : ""
                    }
                    disabled
                    onChange={(e) =>
                      setLeaseInfo({
                        ...leaseInfo,
                        overtime_amount: e.target.value,
                      })
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                {/* <div className="mt-4">
                  <label className="">Reimbursements</label>
                  <input
                    value={leaseInfo.reimbursements}
                    disabled
                    onChange={(e) =>
                      setLeaseInfo({
                        ...leaseInfo,
                        reimbursements: e.target.value,
                      })
                    }
                    type="number"
                    placeholder="₹ 00,000/-"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div> */}
                <div className="mt-4">
                  <label className="">Total Claimable Amount(₹)</label>
                  <input
                    value={
                      leaseInfo.total_claimable_amount
                        ? parseFloat(leaseInfo.total_claimable_amount).toFixed(
                          2
                        )
                        : ""
                    }
                    disabled
                    onChange={(e) =>
                      setLeaseInfo({
                        ...leaseInfo,
                        total_claimable_amount: e.target.value,
                      })
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-10 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
              </div>
            </div>
          </div>
          {leasType && (
            <div className="basis-full bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
              <p className="p-4 font-medium text-xs">Invoice Details</p>
              <div className="mt-4 p-4">
                {invoiceInfo.map((item, index) => (
                  <InvoiceTable
                    index={index}
                    setInvoiceData={setInvoiceInfo}
                    allInvoiceData={invoiceInfo}
                  />
                ))}
                {/* Add invoice button */}
              </div>
            </div>
          )}
          <AddDocument
            title="Lease Documents"
            uploadedDocument={uploadedDocument}
            setUploadedDocument={setUploadedDocument}
            alreadyUploadedDocuments={alreadyUploadedDocuments}
            setAlreadyUploadedDocuments={setAlreadyUploadedDocuments}
            forSection="lease"
          />
          <div className="flex animSlideup relative">
            <div className="basis-full bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 border justify-between">
              <div className="flex p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 className="font-medium text-xs mr-auto ">Lease Photo</h2>
                <button
                  onClick={handlePhotoInput}
                  className="button border rounded-lg py-2 px-2 font-medium text-xs text-right text-gray-800 dark:border-gray-400 dark:text-gray-300 hidden sm:flex"
                >
                  <input
                    ref={photoInputRef}
                    type="file"
                    multiple
                    accept="image/jpeg, image/png"
                    className="hidden"
                    wfd-id="id57"
                    onChange={handlePhotoChange}
                  />
                  +Add photo
                </button>
              </div>
              <div className="p-5 text-xs">
                {uploadedPhoto && (
                  <div className="flex flex-col">
                    <span className="text-xs">Uploading....</span>
                    {showImage.length > 0 &&
                      showImage.map((img) => <p>{img.name}</p>)}
                  </div>
                )}
              </div>
              {alreadyUploadedImages != null && (
                <div className="p-5 text-xs">
                  <span className="text-xs">Existing</span>
                  {alreadyUploadedImages.map((img) => (
                    // <p>{img.substring(img.length - 17)}</p>
                    <Image image_uri={img} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div class="flex mt-5 border bg-white fixed bottom-0 left-0 right-0 pr-6 h-12 items-center justify-end text-xs gap-3 ">
          <button
            className="shadow-md text-white bg-red-1 buttons"
            onClick={() => setOpenDelete(true)}
          >
            Delete
          </button>

          <button
            onClick={handleUpdate}
            className=" shadow-md text-white bg-blue-2  buttons"
          >
            Save
          </button>
        </div>
        {openDelete && (
          <>
            <div className=" fixed z-[9999] left-[35%] top-[20%] w-[400px] flex flex-col justify-center items-center gap-5 h-[300px] bg-white">
              <label htmlFor="" className="text-xs text-light-2">
                Do you really want to delete this lease?
              </label>
              <div className="flex text-xs gap-2">
                <button
                  className="border border-dark-6 text-dark-10 h-7 w-[70px]"
                  onClick={() => setOpenDelete(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-red-600 text-white h-7 w-[70px]"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
            <div
              className="fixed top-0 left-0 bottom-0 right-0 z-0 bg-[#0000000f]"
              onClick={() => setOpenDelete(false)}
            />
          </>
        )}
      </>
    );
  }
}

export default UpdateLease;
