import React, { useState, useEffect } from "react";

import { Button } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { setBaseUrl } from "../../../config";
import { useParams } from "react-router-dom";
import { IoIosAlert } from "react-icons/io";

import { BsBoxArrowInRight, BsCalendarDate } from "react-icons/bs";
import { FaFileExcel, FaFilter } from "react-icons/fa";
import * as XLSX from "xlsx";
import moment from "moment";
import {
  formatDate,
  formatTime,
  formatTimeDuration,
} from "../../../customFunctions/FormatDate";

const TimeLineDuration = ({
  devId,
  setShowMachine,
  handleBottomTab,
  timeLine,
}) => {
  // Latest
  const [openDetails, setOpenDetails] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [dateTimeData, setDateTimeData] = useState(null);
  const [openDateTime, setOpenDateTime] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [filteredDates, setFilteredDates] = useState([]);

  // Get all available dates from timeline data
  const allDates = Object.keys(timeLine.working_time || {})
    .sort()
    .reverse();

  // Filter dates based on selected range
  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      const filtered = allDates.filter((date) => {
        return date >= dateRange.start && date <= dateRange.end;
      });
      setFilteredDates(filtered);
    } else {
      setFilteredDates(allDates);
    }
  }, [dateRange, timeLine]);

  const header = [
    { label: "Date", width: "w-28" },
    { label: "Start Time", width: "w-24" },
    { label: "End Time", width: "w-24" },
    { label: "Duration", width: "w-24" },
    { label: "Status", width: "w-28" },
    { label: "Instances", width: "w-20" },
    { label: "Actions", width: "w-24" },
  ];

  const fetchMachineTimelineDate = async (date) => {
    if (!date) return;

    setLoading(true);
    setOpenDateTime(true);
    try {
      const res = await fetch(`${setBaseUrl}/asset/get-device-timeline-date`, {
        method: "GET",
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
          "timeline-date": date,
          "device-id": devId,
        },
      });

      const data = await res.json();
      if (res.status === 200) {
        setDateTimeData(data);
      } else {
        setDateTimeData(null);
      }
    } catch (error) {
      console.error("Error fetching machine timeline data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = () => {
    const dataToExport = filteredDates.map((date) => ({
      Date: formatDate(date),
      "Start Time": timeLine.working_time[date]?.start
        ? formatTime(timeLine.working_time[date].start)
        : "N/A",
      "End Time": timeLine.working_time[date]?.end
        ? formatTime(timeLine.working_time[date].end)
        : "N/A",
      Duration: formatTimeDuration(
        timeLine.working_time[date]?.duration || "0:00"
      ),
      "Total Instances": timeLine.instance_time[date]?.length || 0,
      Status: timeLine.working_time[date]?.warn ? "Needs Review" : "Normal",
      "Dispute Start": timeLine.working_time[date]?.dispute_time?.start
        ? formatTime(timeLine.working_time[date].dispute_time.start)
        : "N/A",
      "Dispute End": timeLine.working_time[date]?.dispute_time?.end
        ? formatTime(timeLine.working_time[date].dispute_time.end)
        : "N/A",
      "Dispute Duration": timeLine.working_time[date]?.dispute_time?.duration
        ? formatTimeDuration(timeLine.working_time[date].dispute_time.duration)
        : "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Timeline Data");

    const fileName =
      dateRange.start && dateRange.end
        ? `Timeline_${dateRange.start}_to_${dateRange.end}.xlsx`
        : `Timeline_Data.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetDateFilter = () => {
    setDateRange({ start: "", end: "" });
  };

  // const handleNextPrev = (direction) => {
  //   if (!currentDate) return;

  //   const currentDateObj = new Date(currentDate);
  //   if (direction === "next") {
  //     currentDateObj.setDate(currentDateObj.getDate() + 1);
  //   } else {
  //     currentDateObj.setDate(currentDateObj.getDate() - 1);
  //   }
  //   const newDate = currentDateObj.toISOString().split("T")[0];
  //   setCurrentDate(newDate);
  //   fetchMachineTimelineDate(newDate);
  // };

  // Previous One
  let dates = [];

  for (let i = 0; i < Object.keys(timeLine.working_time).length; i++) {
    dates.push(Object.keys(timeLine.working_time)[i]);
  }

  // const header = [
  //   "Date",
  //   "Start Time",
  //   "End Time",
  //   "Duration",
  //   "Remarks",
  //   "Total Instance",
  // ];
  // const [openDetails, setOpenDetails] = useState(null); // Tracks selected date
  // const [currentDate, setCurrentDate] = useState(null);
  // const [dateTimeData, setDateTimeData] = useState(null);
  // const [openDateTime, setOpenDateTime] = useState(false);
  // const [loading, setLoading] = useState(false);

  // const fetchMachineTimelineDate = async (date) => {
  //   // console.log(date)
  //   setLoading(true);
  //   setOpenDateTime(true);
  //   try {
  //     const res = await fetch(`${setBaseUrl}/asset/get-device-timeline-date`, {
  //       method: "GET",
  //       headers: {
  //         "x-access-tokens": sessionStorage.getItem("token"),
  //         "timeline-date": date,
  //         "device-id": devId,
  //       },
  //     });

  //     const data = await res.json();
  //     if (res.status === 200) {
  //       setDateTimeData(data);
  //     } else {
  //       setDateTimeData(null);
  //     }
  //     // console.log(data)
  //     setLoading(false);
  //     console.log(dateTimeData, "res");
  //   } catch (error) {
  //     console.error("Error fetching machine timeline data: ", error);
  //   }
  // };

  // const handleExportExcel = () => {
  //   const excelData = dates.reverse().map((date) => ({
  //     Date: date,
  //     "Start Time": timeLine.working_time[date]?.start || "N/A",
  //     "End Time": timeLine.working_time[date]?.end || "N/A",
  //     Duration: formatTimeDuration(
  //       timeLine.working_time[date]?.duration || "0:00"
  //     ),
  //     "Total Instance": timeLine.instance_time[date]?.length || 0,
  //     Verification: timeLine.working_time[date]?.warn ? `Warning` : "",
  //   }));

  //   const worksheet = XLSX.utils.json_to_sheet(excelData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Timeline Data");
  //   XLSX.writeFile(workbook, "TimelineData.xlsx");
  // };

  const handleNextPrev = (direction) => {
    const currentDateObj = new Date(currentDate);
    if (direction === "next") {
      currentDateObj.setDate(currentDateObj.getDate() + 1); // Increment by 1 day
    } else if (direction === "prev") {
      currentDateObj.setDate(currentDateObj.getDate() - 1); // Decrement by 1 day
    }
    const newDate = currentDateObj.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    setCurrentDate(newDate);
    fetchMachineTimelineDate(newDate); // Fetch data for the new date
  };

  const parseTelData = (telData) => {
    // Convert the tel_data string into a JavaScript object
    const parsedData = JSON.parse(telData);

    // Extracting relevant values
    return {
      SOC: parsedData.SOC,
      ExVolt: parsedData.ExVOLT,
      VCELL: parsedData.VCELL,
      MODE: parsedData?.MODE,
    };
  };

  const renderPwrDataPush = (item) => {
    if (item.type_data === "pwr-data-push") {
      const { SOC, ExVolt, VCELL, MODE } = parseTelData(item.tel_data);

      return (
        <>
          <div className="flex items-center gap-6">
            <div className="">SOC: {parseInt(SOC)}%</div>
            <div className="">ExVolt: {parseFloat(ExVolt).toFixed(2)}V</div>
            <div className="">VCELL: {parseFloat(VCELL).toFixed(2)}V</div>
            <div>MODE: {MODE} </div>
          </div>
        </>
      );
    }
    return item.type_data === "vehicle-engine-status" ? item.tel_data : "";
  };

  return (
    <>
      <div className="pb-20">
        {/* Export excel Button  */}
        {/* First Part */}
        <div className="pb-6">
          {/* Header Section */}
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mb-4">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div>
                <h1 className="text-lg font-semibold text-gray-800">
                  Working Time Records
                </h1>
                <p className="text-sm text-gray-500">
                  {filteredDates.length} records found
                  {dateRange.start && dateRange.end && (
                    <span className="ml-2">
                      ({formatDate(dateRange.start)} to{" "}
                      {formatDate(dateRange.end)})
                    </span>
                  )}
                </p>
              </div>

              <div className="flex flex-col sm:flex-row gap-3">
                <div className="relative">
                  <button
                    onClick={() => setShowDateFilter(!showDateFilter)}
                    className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg text-sm hover:bg-gray-50"
                  >
                    <FaFilter className="text-gray-500" />
                    <span>Filter Dates</span>
                  </button>

                  {/* Open Filter */}
                  {showDateFilter && (
                    <>
                      <div className="absolute right-0 mt-2 w-72 bg-white rounded-lg shadow-lg border border-gray-200 z-[9999] p-4">
                        <div className="space-y-3">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Start Date
                            </label>
                            <input
                              type="date"
                              name="start"
                              value={dateRange.start}
                              onChange={handleDateChange}
                              max={dateRange.end || undefined}
                              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              End Date
                            </label>
                            <input
                              type="date"
                              name="end"
                              value={dateRange.end}
                              onChange={handleDateChange}
                              min={dateRange.start || undefined}
                              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                          </div>
                          <div className="flex justify-between">
                            <button
                              onClick={resetDateFilter}
                              className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
                            >
                              Reset
                            </button>
                            <button
                              onClick={() => setShowDateFilter(false)}
                              className="px-4 py-1 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className="fixed z-0 top-0 left-0 bottom-0 right-0 bg-[#00000003]"
                        onClick={() => setShowDateFilter(false)}
                      />
                    </>
                  )}
                </div>

                <button
                  onClick={handleExportExcel}
                  disabled={!filteredDates.length}
                  className={`flex items-center gap-2 px-4 py-2 rounded-lg text-sm ${
                    !filteredDates.length
                      ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                      : "bg-green-600 text-white hover:bg-green-700"
                  }`}
                  title={
                    !filteredDates.length
                      ? "No data to export"
                      : "Export to Excel"
                  }
                >
                  <FaFileExcel />
                  <span>Export</span>
                </button>
              </div>
            </div>
          </div>

          {/* Main Table */}
          <div className="h-[42.7vh] relative  bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
            <div className="overflow-x-auto overflow-y-auto h-full">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100  sticky top-0 z-10">
                  <tr>
                    {header.map((col) => (
                      <th
                        key={col.label}
                        className={`px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${col.width}`}
                      >
                        {col.label}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredDates.length > 0 ? (
                    filteredDates.map((date) => (
                      <tr
                        key={date}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                          <div className="flex items-center gap-2">
                            <BsCalendarDate className="text-gray-400" />
                            {formatDate(date)}
                          </div>
                        </td>

                        <td className="px-4 py-3 text-sm text-gray-700">
                          {timeLine?.working_time[date]?.dispute_time ? (
                            <div className="space-y-1">
                              <div className="font-medium">
                                {formatTime(timeLine.working_time[date].start)}
                              </div>
                              <div className="text-xs text-amber-600 bg-amber-50 px-1 rounded">
                                Dispute:{" "}
                                {formatTime(
                                  timeLine.working_time[date].dispute_time.start
                                )}
                              </div>
                            </div>
                          ) : timeLine?.working_time[date]?.start ? (
                            <span className="font-medium">
                              {formatTime(timeLine.working_time[date].start)}
                            </span>
                          ) : (
                            <span className="text-gray-400">N/A</span>
                          )}
                        </td>

                        <td className="px-4 py-3 text-sm text-gray-700">
                          {timeLine?.working_time[date]?.dispute_time ? (
                            <div className="space-y-1">
                              <div className="font-medium">
                                {formatTime(timeLine.working_time[date].end)}
                              </div>
                              <div className="text-xs text-amber-600 bg-amber-50 px-1 rounded">
                                Dispute:{" "}
                                {formatTime(
                                  timeLine.working_time[date].dispute_time.end
                                )}
                              </div>
                            </div>
                          ) : timeLine?.working_time[date]?.end ? (
                            <span className="font-medium">
                              {formatTime(timeLine.working_time[date].end)}
                            </span>
                          ) : (
                            <span className="text-gray-400">N/A</span>
                          )}
                        </td>

                        <td className="px-4 py-3 text-sm">
                          {timeLine?.working_time[date]?.dispute_time ? (
                            <div className="space-y-1">
                              <div className="font-medium text-gray-700">
                                {formatTimeDuration(
                                  timeLine.working_time[date].duration
                                )}
                              </div>
                              <div className="text-xs text-amber-600 bg-amber-50 px-1 rounded">
                                Dispute:{" "}
                                {formatTimeDuration(
                                  timeLine.working_time[date].dispute_time
                                    .duration
                                )}
                              </div>
                            </div>
                          ) : timeLine?.working_time[date]?.duration ? (
                            <span className="font-medium text-gray-700">
                              {formatTimeDuration(
                                timeLine.working_time[date].duration
                              )}
                            </span>
                          ) : (
                            <span className="text-gray-400">0:00</span>
                          )}
                        </td>

                        <td className="px-4 py-3 text-sm">
                          {timeLine?.working_time[date]?.warn ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                              Needs Review
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Normal
                            </span>
                          )}
                        </td>

                        <td className="px-4 py-3 text-sm text-center font-medium text-gray-700">
                          {timeLine?.instance_time[date]?.length || 0}
                        </td>

                        <td className="px-4 py-3 text-sm">
                          <button
                            onClick={() => {
                              setCurrentDate(date);
                              setOpenDetails(date);
                              fetchMachineTimelineDate(date);
                            }}
                            className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-green-2 hover:bg-green-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            <BsBoxArrowInRight className="mr-1.5 h-3 w-3" />
                            Details
                          </button>
                        </td>
                        {/* Date Details Modal */}
                        {openDetails === date && (
                          <>
                            <div className="fixed inset-0 z-50 overflow-y-auto">
                              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                {/* Overlay */}
                                <div
                                  className="fixed inset-0 transition-opacity"
                                  aria-hidden="true"
                                >
                                  <div
                                    className="absolute inset-0 bg-gray-500 opacity-75"
                                    onClick={() => setOpenDetails(null)}
                                  ></div>
                                </div>

                                {/* Modal Content */}
                                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                        <div className="flex justify-between items-center mb-4">
                                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Details for: {formatDate(date)}
                                          </h3>
                                          <button
                                            type="button"
                                            className="buttons border-green-2 text-green-2 hover:bg-green-2 hover:text-white rounded-md px-4 py-2 text-sm font-medium"
                                            onClick={() => setOpenDetails(null)}
                                          >
                                            Close
                                          </button>
                                        </div>

                                        <div className="">
                                          <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
                                            <div
                                              className="overflow-y-auto"
                                              style={{ maxHeight: "400px" }}
                                            >
                                              <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50 sticky top-0">
                                                  <tr>
                                                    <th
                                                      scope="col"
                                                      className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                                                    >
                                                      Instance No
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                                                    >
                                                      Start Time
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                                                    >
                                                      End Time
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                  {timeLine.instance_time[
                                                    date
                                                  ]?.map((item, i) => (
                                                    <tr
                                                      key={i}
                                                      className="hover:bg-gray-50 transition-colors duration-150"
                                                    >
                                                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        {i + 1}
                                                      </td>
                                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                                          {item.start
                                                            ? item.start
                                                                .split(" ")[1]
                                                                .split(".")[0]
                                                            : "0:00"}
                                                        </span>
                                                      </td>
                                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                                          {item.end
                                                            ? item.end
                                                                .split(" ")[1]
                                                                .split(".")[0]
                                                            : "0:00"}
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                              {(!timeLine.instance_time[date] ||
                                                timeLine.instance_time[date]
                                                  ?.length === 0) && (
                                                <div className="text-center py-8 text-gray-500">
                                                  No data available
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={header.length}
                        className="px-4 py-8 text-center"
                      >
                        <div className="flex flex-col items-center justify-center text-gray-500">
                          <svg
                            className="w-12 h-12 mb-2 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1.5}
                              d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <p className="text-sm">
                            {dateRange.start || dateRange.end
                              ? "No records found for selected date range"
                              : "No timeline data available"}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Second Part */}
        <input
          type="date"
          value={currentDate || ""}
          className="mx-4 mt-2"
          onChange={(e) => {
            let date = e.target.value;
            setCurrentDate(date);
            fetchMachineTimelineDate(date);
          }}
        />
        {/* Modal */}
        {openDateTime && (
          <>
            <div className="flex items-center gap-2 mt-2 px-4">
              <Button
                variant="outlined"
                sx={{
                  color: "#7C7C7C",
                  borderColor: "#7C7C7C",
                  fontSize: 12,
                }}
                onClick={() => setOpenDateTime(false)}
              >
                Close
              </Button>
              <Button variant="outlined" onClick={() => handleNextPrev("prev")}>
                Prev
              </Button>
              <Button variant="outlined" onClick={() => handleNextPrev("next")}>
                Next
              </Button>
            </div>

            {dateTimeData ? (
              <>
                <div className=" z-50 left-[10%] top-[10%] w-[100%] bg-white p-6 rounded-[4px]">
                  <div className="flex mt-6 items-center justify-between mb-4">
                    <h2 className="text-xs font-semibold text-dark-6">
                      {/* Details for: { dateTimeData.date ? formatDate(dateTimeData.date) : ''} */}
                    </h2>
                  </div>

                  <div className=" pt-1 hide-scrollbar px-6 ">
                    {loading ? (
                      <div className="text-xs px-6">Loading...</div>
                    ) : (
                      <TableContainer component={Paper}>
                        <Table
                          size="small"
                          stickyHeader
                          aria-label="timeline table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Time</TableCell>
                              <TableCell>Data Type</TableCell>
                              <TableCell>Telematrics Data</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dateTimeData?.map((item, i) => (
                              <TableRow key={i}>
                                <TableCell>{formatDate(item.date)}</TableCell>
                                <TableCell>
                                  {formatTime(item.time_now)}
                                </TableCell>
                                <TableCell>
                                  {item.type_data === "pwr-data-push"
                                    ? "Power Data"
                                    : "Vehicle Engine"}
                                </TableCell>
                                <TableCell>{renderPwrDataPush(item)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="text-xs px-6">
                <br />
                {loading ? (
                  "Loading..."
                ) : (
                  <> No data available for {formatDate(currentDate)}</>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TimeLineDuration;

// <div className="">
// {/* Table Container */}
// <div className="bg-white shadow-md overflow-hidden rounded-[8px] border items-left flex flex-col px-2 py-3 ">
//   <h1 className=" items-center  mb-4  px-4 flex justify-between">
//     <span className="text-base font-bold text-dark-6 ">
//       Working Time
//     </span>

//     {/* Incomplete Function
//   Task 1:
//   We needd a start Date and End Date,
//   According to date list will update and display also the excel will be export based on that list

//   */}
//     <div className="flex items-center gap-2">
//       {/* <input
//         type="date"
//         className="flex text-xs items-center px-4 py-2 border w-[140px] rounded-[8px] justify-center hover:bg-green-2 hover:text-white border-green-2 gap-1  text-green-2 text-center "
//         title="Select Date to Check the timeline data "
//       /> */}

//       <button
//         className="flex  items-center px-4 py-2 border w-[140px] rounded-[8px] justify-center hover:bg-green-2 hover:text-white border-green-2 gap-1  text-green-2 text-center "
//         onClick={handleExportExcel}
//         title="Click to export in excel"
//       >
//         <FaFileExcel /> Export Excel
//       </button>
//     </div>
//   </h1>
//   <div className="h-[42.7vh] relative overflow-hidden bg-white rounded-lg border border-gray-200 shadow-xs">
//     <div className="overflow-y-auto h-full">
//       <table className="min-w-full divide-y divide-gray-200">
//         {/* Fixed Header */}
//         <thead className="bg-gray-50 sticky top-0 z-10">
//           <tr>
//             {header.map((col) => (
//               <th
//                 key={col}
//                 className="px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
//               >
//                 {col}
//               </th>
//             ))}
//             <th className="px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">

//             </th>
//           </tr>
//         </thead>

//         {/* Scrollable Body */}
//         <tbody className="bg-white divide-y divide-gray-200">
//           {dates.reverse().map((date) => (
//             <React.Fragment key={date}>
//               <tr className="hover:bg-gray-50 transition-colors duration-150">
//                 {/* Date Column */}
//                 <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
//                   {formatDate(date)}
//                 </td>

//                 {/* Start Time Column */}
//                 <td className="px-4 py-3 text-sm text-gray-500">
//                   {timeLine?.working_time[date]?.dispute_time ? (
//                     <div className="space-y-1">
//                       <div>
//                         {moment(
//                           timeLine?.working_time[date]?.start
//                         ).format("HH:mm")}
//                       </div>
//                       <div className="text-xs text-amber-600">
//                         {formatTime(
//                           timeLine?.working_time[date]?.dispute_time
//                             ?.start
//                         )}{" "}
//                         (dispute)
//                       </div>
//                     </div>
//                   ) : timeLine?.working_time[date]?.start ? (
//                     formatTime(timeLine?.working_time[date]?.start)
//                   ) : (
//                     "0:00"
//                   )}
//                 </td>

//                 {/* End Time Column */}
//                 <td className="px-4 py-3 text-sm text-gray-500">
//                   {timeLine?.working_time[date]?.dispute_time ? (
//                     <div className="space-y-1">
//                       <div>
//                         {formatTime(
//                           timeLine?.working_time[date]?.end
//                         )}
//                       </div>
//                       <div className="text-xs text-amber-600">
//                         {formatTime(
//                           timeLine?.working_time[date]?.dispute_time
//                             ?.end
//                         )}{" "}
//                         (dispute)
//                       </div>
//                     </div>
//                   ) : timeLine?.working_time[date]?.end ? (
//                     formatTime(timeLine?.working_time[date]?.end)
//                   ) : (
//                     "0:00"
//                   )}
//                 </td>

//                 {/* Duration Column */}
//                 <td className="px-4 py-3 text-sm text-gray-500">
//                   {timeLine?.working_time[date]?.dispute_time ? (
//                     <div className="space-y-1">
//                       <div>
//                         {formatTimeDuration(
//                           timeLine?.working_time[date]?.duration
//                         )}
//                       </div>
//                       <div className="text-xs text-amber-600">
//                         {formatTimeDuration(
//                           timeLine?.working_time[date]?.dispute_time
//                             ?.duration
//                         )}{" "}
//                         (dispute)
//                       </div>
//                     </div>
//                   ) : timeLine?.working_time[date]?.duration ? (
//                     formatTimeDuration(
//                       timeLine?.working_time[date]?.duration
//                     )
//                   ) : (
//                     "0:00"
//                   )}
//                 </td>

//                 {/* Warning Column */}
//                 <td className="px-4 py-3 text-sm">
//                   {timeLine?.working_time[date]?.warn && (
//                     <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
//                       Please Check
//                     </span>
//                   )}
//                 </td>

//                 {/* Actions Column */}
//                 <td className="px-4 py-3 text-sm text-gray-500 text-left items-center  ">

//                       {timeLine?.instance_time[date]?.length || 0}
//                 </td>
//                 <td className="px-4 py-3 text-sm text-gray-500 items-end flex justify-end">
//                 <button
//                       onClick={() => setOpenDetails(date)}
//                       className="inline-flex items-center px-3 py-1 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                     >
//                       <BsBoxArrowInRight className="mr-1.5 h-3 w-3" />
//                       Open
//                     </button>
//                 </td>
//               </tr>

//               {/* Modal */}
// {openDetails === date && (
//   <>
//     <div className="fixed inset-0 z-50 overflow-y-auto">
//       <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//         {/* Overlay */}
//         <div
//           className="fixed inset-0 transition-opacity"
//           aria-hidden="true"
//         >
//           <div
//             className="absolute inset-0 bg-gray-500 opacity-75"
//             onClick={() => setOpenDetails(null)}
//           ></div>
//         </div>

//         {/* Modal Content */}
//         <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
//           <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//             <div className="sm:flex sm:items-start">
//               <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
//                 <div className="flex justify-between items-center mb-4">
//                   <h3 className="text-lg leading-6 font-medium text-gray-900">
//                     Details for: {formatDate(date)}
//                   </h3>
//                   <button
//                     type="button"
//                     className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//                     onClick={() => setOpenDetails(null)}
//                   >
//                     Close
//                   </button>
//                 </div>

//                 <div className="">
//                   <TableContainer
//                     component={Paper}
//                     style={{ maxHeight: 400 }}
//                   >
//                     <Table size="small" stickyHeader>
//                       <TableHead>
//                         <TableRow>
//                           <TableCell className="font-semibold">
//                             Instance No
//                           </TableCell>
//                           <TableCell className="font-semibold">
//                             Start Time
//                           </TableCell>
//                           <TableCell className="font-semibold">
//                             End Time
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {timeLine.instance_time[
//                           date
//                         ]?.map((item, i) => (
//                           <TableRow key={i} hover>
//                             <TableCell>
//                               {i + 1}
//                             </TableCell>
//                             <TableCell>
//                               {item.start
//                                 ? item.start
//                                     .split(" ")[1]
//                                     .split(".")[0]
//                                 : "0:00"}
//                             </TableCell>
//                             <TableCell>
//                               {item.end
//                                 ? item.end
//                                     .split(" ")[1]
//                                     .split(".")[0]
//                                 : "0:00"}
//                             </TableCell>
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </>
// )}
//             </React.Fragment>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   </div>
// </div>
// </div>
