// import React, { useState } from "react";
// import { Tabs, Tab, Card, CardContent, Typography, TextField, Button, RadioGroup, FormControlLabel, Radio } from "@mui/material";
// import { setBaseUrl } from "../../../config.js";
// import { toast } from "react-toastify";

// const ApprovalForm = ({ assetNo, id, bookingDetails }) => {
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [approvalStatus, setApprovalStatus] = useState({});
//   const [comments, setComments] = useState({});

//   if (!bookingDetails || bookingDetails.length === 0) {
//     return (
//       <div className="p-6">
//         <Typography variant="h5" className="text-red-600">No Booking Details Found</Typography>
//       </div>
//     );
//   }

//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//   };

//   // ✅ Handle form submission
//   const handleSubmit = async (booking) => {
//     if (approvalStatus[booking.booking_id] === undefined) {
//       toast.error("Please select approval status!");
//       return;
//     }

//     const payload = {
//       booking_id: booking.booking_id,
//       follow_up_comments: comments[booking.booking_id] || "",
//       is_approved: approvalStatus[booking.booking_id], // true for approve, false for decline
//     };

//     try {
//       const res = await fetch(`${setBaseUrl}/lease/set-comment-follow-up-approve`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//         },
//         body: JSON.stringify(payload),
//       });

//       if (!res.ok) throw new Error(`API Error: ${res.status}`);

//       if (res.status === 200) {
//         toast.success(`Booking ${booking.booking_id} has been ${approvalStatus[booking.booking_id] ? "Approved" : "Declined"} successfully!`);
//         window.location.reload();
//       }
//     } catch (error) {
//       console.error("Error submitting approval:", error);
//       toast.error("Failed to submit approval.");
//     }
//   };

//   return (
//     <div className="p-6">
//       <Typography variant="h5" className="mb-4 font-bold text-blue-600">
//         Booking Approval for Asset: {assetNo}
//       </Typography>

//       {/* Tabs Navigation */}
//       <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
//         {bookingDetails.map((booking, index) => (
//           <Tab key={booking.booking_id} label={`Booking ${index + 1}`} />
//         ))}
//       </Tabs>

//       {/* Display Selected Booking */}
//       {bookingDetails.map((booking, index) => (
//         <div key={booking.booking_id} hidden={selectedTab !== index}>
//           <Card sx={{ fontSize: "12px"}} className="shadow-md border mb-4 mt-4">
//             <CardContent className="flex flex-col gap-2">
//               <Typography variant="body1"><strong>Customer:</strong> {booking.booking_customer_name}</Typography>
//               <Typography variant="body1"><strong>Location:</strong> {booking.booking_location}</Typography>
//               <Typography variant="body1"><strong>Rate per Day:</strong> ₹{booking.booking_estimated_rate_per_day}</Typography>
//               <Typography variant="body1"><strong>Need From:</strong> {new Date(booking.booking_need_from).toLocaleDateString()}</Typography>
//               <Typography variant="body1"><strong>Needed Up To:</strong> {new Date(booking.booking_needed_up_to).toLocaleDateString()}</Typography>
//               <Typography variant="body1"><strong>Transport Paid:</strong> ₹{booking.transpot_paid_by_customer_by_percentage_or_amount}</Typography>
//             </CardContent>
//           </Card>

//           {/* Approval Form */}
//           <Typography variant="h6" className="mt-4 font-bold">Approval</Typography>
//           <RadioGroup
//             row
//             value={approvalStatus[booking.booking_id] ?? ""}
//             onChange={(e) => setApprovalStatus({ ...approvalStatus, [booking.booking_id]: e.target.value === "true" })}
//           >
//             <FormControlLabel value="true" control={<Radio color="success" />} label="Approve" />
//             <FormControlLabel value="false" control={<Radio color="error" />} label="Decline" />
//           </RadioGroup>

//           <TextField
//             label="Follow-up Comments"
//             fullWidth
//             multiline
//             rows={3}
//             value={comments[booking.booking_id] || ""}
//             onChange={(e) => setComments({ ...comments, [booking.booking_id]: e.target.value })}
//             className="mt-4"
//           />

//           {/* Submit Button */}
//           <div className="flex justify-between mt-4">
//             <Button variant="contained" color="primary" sx={{ fontSize: "12px" }} onClick={() => handleSubmit(booking)}>
//               Submit
//             </Button>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ApprovalForm;

import React, { useState } from "react";
import { setBaseUrl } from "../../../config.js";
import { toast } from "react-toastify";
import moment from "moment";
import { currency } from "../AssetDetails/Tabs/FormatDate.jsx";

const ApprovalForm = ({ assetNo, bookingDetails }) => {
  const [approvalStatus, setApprovalStatus] = useState({});
  const [comments, setComments] = useState({});

  if (!bookingDetails || bookingDetails.length === 0) {
    return (
      <div className="p-6">
        <h5 className="text-red-600 text-lg font-bold">No Booking Details Found</h5>
      </div>
    );
  }

  // ✅ Handle form submission
  const handleSubmit = async (booking) => {
    if (approvalStatus[booking.booking_id] === undefined) {
      toast.error("Please select approval status!");
      return;
    }

    const payload = {
      booking_id: booking.booking_id,
      follow_up_comments: comments[booking.booking_id] || "",
      is_approved: approvalStatus[booking.booking_id], // true for approve, false for decline
    };

    try {
      const res = await fetch(`${setBaseUrl}/lease/set-comment-follow-up-approve`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      });

      if (!res.ok) throw new Error(`API Error: ${res.status}`);

      if (res.status === 200) {
        toast.success(`Booking ${booking.booking_id} has been ${approvalStatus[booking.booking_id] ? "Approved" : "Declined"} successfully!`);
        window.location.reload();
      }
    } catch (error) {
      console.error("Error submitting approval:", error);
      toast.error("Failed to submit approval.");
    }
  };

  return (
    <div className="p-4">
      <h5 className="text-blue-600 text-lg font-bold mb-4">Booking Approval for Asset: {assetNo}</h5>

      {/* Table Layout */}
      <div className="overflow-x-auto w-[65rem]">
        <table className="w-full border text-[12px]">
          <thead className="bg-gray-200 text-left">
            <tr className="border-b">
            <th className="p-2">Sales Person</th>
              <th className="p-2">Customer</th>
              <th className="p-2">Location</th>
              <th className="p-2">Creation Date</th>

              <th className="p-2">Rate/Day</th>
              <th className="p-2">Need From</th>
              <th className="p-2">Needed Up To</th>
              <th className="p-2">Duration</th>
              <th className="p-2">Transport Paid</th>
              <th className="p-2">Approval</th>
              <th className="p-2">Comments</th>
              <th className="p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {bookingDetails.map((booking) => (
              <tr key={booking.booking_id} className="border-b">
                <td className="p-2">{booking.sale_person_name || "N/A"}</td>
                <td className="p-2">{booking.booking_customer_name}</td>
                <td className="p-2">{booking.booking_location}</td>
                <td className="p-2">{moment(booking.create_at).format("DD-MM-YYYY")}</td>
                <td className="p-2">₹{currency(booking.booking_estimated_rate_per_day)}</td>
                <td className="p-2">{moment(booking.booking_need_from).format("DD-MM-YYYY")}</td>
                <td className="p-2">{moment(booking.booking_needed_up_to).format("DD-MM-YYYY")}</td>
                <td className="p-2">{booking.duration_of_lease}</td>
                <td className="p-2">₹{currency(booking.transpot_paid_by_customer_by_percentage_or_amount)}/-</td>

                {/* Approval Status */}
                <td className="p-2">
                  <label className="mr-2">
                    <input
                      type="radio"
                      name={`approval-${booking.booking_id}`}
                      value="true"
                      checked={approvalStatus[booking.booking_id] === true}
                      onChange={() => setApprovalStatus({ ...approvalStatus, [booking.booking_id]: true })}
                      className="mr-1"
                    />
                    Approve
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`approval-${booking.booking_id}`}
                      value="false"
                      checked={approvalStatus[booking.booking_id] === false}
                      onChange={() => setApprovalStatus({ ...approvalStatus, [booking.booking_id]: false })}
                      className="mr-1"
                    />
                    Decline
                  </label>
                </td>

                {/* Comments Input */}
                <td className="p-2">
                  <textarea
                    className="w-full border rounded p-1 text-[12px]"
                    rows="2"
                    placeholder="Add Comments"
                    value={comments[booking.booking_id] || ""}
                    onChange={(e) => setComments({ ...comments, [booking.booking_id]: e.target.value })}
                  />
                </td>

                {/* Submit Button */}
                <td className="p-2">
                  <button
                    className="bg-blue-2 text-white buttons"
                    onClick={() => handleSubmit(booking)}
                  >
                    Submit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ApprovalForm;
