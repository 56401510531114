import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import Loader from "../Loader";
import { LoaderContext } from "../../Context/LoaderContext";
import { PermissionContext } from "../../Context/PermissionsContext";
import { useNavigate, useParams } from "react-router-dom";
import ifscIco from "../../components/OperatorDetails/assets/ifscIco.svg";
import odooIco from "../../components/OperatorDetails/assets/adhaarIcon.svg";
import adhaarIco from "../../components/OperatorDetails/assets/adhaarIcon2.svg";
import joiningIco from "../../components/OperatorDetails/assets/joiningIcon.svg";
import moneyIco from "../../components/OperatorDetails/assets/moneyIco.svg";
import phoneIco from "../../components/OperatorDetails/assets/phoneIcon.svg";
import profileIco from "../../components/OperatorDetails/assets/profileIco.svg";
import bankAcIco from "../../components/OperatorDetails/assets/bankAcIco.svg";
import pfAcIco from "../../components/OperatorDetails/assets/pfIco.svg";
import CardForDetails from "./CardForDetails";
import {
  currency,
  formatAadhaar,
  formatDate,
} from "../Modals/AssetDetails/Tabs/FormatDate";

function OperatorDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [perms, setPerms] = useContext(PermissionContext);
  const [operatorDetail, setOperatorDetail] = useState({});
  const loader = useContext(LoaderContext)[0];
  const setLoader = useContext(LoaderContext)[1];
  const [reload, setReload] = useState(false);
  const [history, setHistory] = useState(false);
  const getOperatorDetail = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/operator/get_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      // console.log(data);
      setOperatorDetail(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getOperatorDetail();
  }, []);

  // console.log(formatDate(operatorDetail?.date_list[0]?.joining_date));

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div id="operator-details" className="">
        <div className="flex border  fixed bottom-0 left-0 right-0 items-center h-12">
          <button
            className="ml-16 text-xs rounded text-light-1 border-light-1 border  w-[67px] h-7 "
            onClick={() => navigate("/operators")}
          >
            back
          </button>

          <div class="sm:flex items-center ml-auto mt-0 gap-2 hidden text-sm">
            {(perms.indexOf("OPERATOR.ALL") !== -1 ||
              perms.indexOf("OPERATOR.CRU") !== -1 ||
              perms.indexOf("ADMIN.ALL") !== -1) && (
              <button
                onClick={() => navigate(`/operators/updateOperator/${id}`)}
                class="text-xs text-white bg-light-1 rounded w-[67px] h-7 "
              >
                Update
              </button>
            )}
            <button
              class="border rounded border-light-1 text-light-1 text-xs w-[67px] h-7 mr-2"
              onClick={() => setReload((prev) => !prev)}
            >
              Reload
            </button>
          </div>
        </div>

        <div className="flex justify-evenly space-y-4 p-6 mt-3 bg-white dark:bg-slate-700 border rounded-[8px] text-xs">
          {/* User Details Card */}
          {/* <div className="border w-[160px] h-[160px] mt-5 rounded-full">

          </div> */}

          {/* <div className="flex flex-col space-y-3 border rounded-[8px]">
            <h1 className="font-bold ">Operator's Informations</h1>
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-2">
                
                <span className="dark:text-white text-gray-500">Name:</span>

                <span className="font-bold text-base">
                  {operatorDetail?.name}
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex items-center space-x-2">
                
                <span className="dark:text-white text-gray-500">
                  Mobile No:
                </span>
                <span className="">
                  {operatorDetail?.phone?.types}-
                  {operatorDetail?.phone?.phone_no}
                </span>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              
              <span className="dark:text-white text-gray-500">Aadhaar No:</span>
              <span className="">{operatorDetail?.aadhar_no}</span>
            </div>
            <div className="flex items-center space-x-2">
              
              <span className="dark:text-white text-gray-500">
                Odoo Employee No:
              </span>
              <span className="">{operatorDetail?.odoo_employee_no}</span>
            </div>
            <div className="flex items-center space-x-2">
              
              <span className="dark:text-white text-gray-500">RSO No:</span>
              <span className="flex gap-2">
                {operatorDetail?.lease_RSO_no?.map((data) => (
                  <>
                    <div className="">{data},</div>
                  </>
                ))}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              
              <span className="dark:text-white text-gray-500">Asset No:</span>
              <span className="flex gap-2">
                {operatorDetail?.asset_no?.map((data) => (
                  <>
                    <div className="">{data},</div>
                  </>
                ))}
              </span>
            </div>
          </div> */}
          <div className="border rounded-[8px]">
            <h1 className="font-bold mb-4 pt-4 pl-4">Operator's Information</h1>
            <table className="table-auto w-full text-left border-collapse">
              <tbody>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 ">
                    Name:
                  </td>
                  <td className="py-2 px-4 font-medium text-xs">
                    {operatorDetail?.name}
                  </td>
                </tr>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 ">
                    Mobile No:
                  </td>
                  <td className="py-2 px-4">
                    {operatorDetail?.phone?.types}-
                    {operatorDetail?.phone?.phone_no}
                  </td>
                </tr>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 ">
                    Aadhaar No:
                  </td>
                  <td className="py-2 px-4">{operatorDetail?.aadhar_no}</td>
                </tr>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 ">
                    Odoo Employee No:
                  </td>
                  <td className="py-2 px-4">
                    {operatorDetail?.odoo_employee_no}
                  </td>
                </tr>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 ">
                    RSO No:
                  </td>
                  <td className="py-2 px-4">
                    {operatorDetail?.lease_RSO_no?.map((data, index) => (
                      <span key={index} className="mr-2">
                        {data},
                      </span>
                    ))}
                  </td>
                </tr>
                <tr className="border-t">
                  <td className="py-2 px-4 font-medium text-gray-500 ">
                    Asset No:
                  </td>
                  <td className="py-2 px-4">
                    {operatorDetail?.asset_no?.map((data, index) => (
                      <span key={index} className="mr-2">
                        {data},
                      </span>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <hr className="h-[100px] border " /> */}

          {/* Employment Details Card */}
         

          <div className="border rounded-[8px] ">
            <h1 className="font-bold mb-4 pt-4 pl-4">Financial Information</h1>
            <table className="table-auto w-full text-left border-collapse">
              <tbody>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 dark:text-white">
                    PF Account No:
                  </td>
                  <td className="py-2 px-4 font-bold">
                    {parseInt(operatorDetail?.pf_account_no) || "--"}
                  </td>
                </tr>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 dark:text-white">
                    Bank Account No:
                  </td>
                  <td className="py-2 px-4">
                    {operatorDetail?.bank_details?.account_no || "--"}
                  </td>
                </tr>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 dark:text-white">
                    IFSC Code:
                  </td>
                  <td className="py-2 px-4">
                    {operatorDetail?.bank_details?.ifsc_code || "--"}
                  </td>
                </tr>
                <tr className="border-t border-b">
                  <td className="py-2 px-4 font-medium text-gray-500 dark:text-white">
                    Net Inhand Salary:
                  </td>
                  <td className="py-2 px-4">
                    {operatorDetail?.net_inhand_salary == null ||
                    isNaN(operatorDetail?.net_inhand_salary)
                      ? "--"
                      : `₹${currency(
                          operatorDetail?.net_inhand_salary || "--"
                        )}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <hr className="h-[100px] border " /> */}
          {/* Date of Joining */}

          {/* History Data */}
        </div>
        <div className="w-[300px] text-xs  p-10">
          {operatorDetail?.date_list?.length > 0 && (
            <>
              {/* First Date Display */}
              <div
                className="flex flex-col space-y-3 "
                key={operatorDetail.date_list[0].joining_date}
              >
                <div className="flex items-center space-x-2">
                  <span className="dark:text-white text-gray-500">
                    Date of Joining:
                  </span>
                  <span className="font-bold">
                    {operatorDetail.date_list[0]?.joining_date
                      ? formatDate(operatorDetail.date_list[0].joining_date)
                      : "--"}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <span className="dark:text-white text-gray-500">
                    Date of Leaving:
                  </span>
                  <span>
                    {operatorDetail.date_list[0]?.leaving_date
                      ? formatDate(operatorDetail.date_list[0].leaving_date)
                      : "--"}
                  </span>
                </div>

                {/* Show More Button */}
                <button
                  className="px-2 py-1 bg-light-1 text-white dark:bg-white dark:text-black cursor-pointer rounded"
                  onClick={() => setHistory(!history)}
                >
                  Show More
                </button>
              </div>

              {/* Show More Tab */}
              {history && (
                <>
                  <div className="absolute right-24 text-xs top-[65%] h-[300px] w-[300px] left-[25%] overflow-y-auto bg-white dark:bg-slate-900 border rounded-xl overflow-hidden p-4">
                    <button
                      className="absolute right-4 text-light-1 cursor-pointer border-light-1 border rounded h-5 w-[60px]"
                      onClick={() => setHistory(false)}
                    >
                      Close
                    </button>

                    <div className="space-y-2 p-2 mt-6">
                      {operatorDetail?.date_list
                        ?.slice(1)
                        .map((date, index) => (
                          <div key={index} className="flex flex-col space-y-2">
                            <div className="flex items-center space-x-2">
                              <span className="dark:text-white text-gray-500">
                                Date of Joining:
                              </span>
                              <span>
                                {date?.joining_date
                                  ? formatDate(date.joining_date)
                                  : "--"}
                              </span>
                            </div>
                            <div className="flex items-center space-x-2">
                              <span className="dark:text-white text-gray-500">
                                Date of Leaving:
                              </span>
                              <span>
                                {date?.leaving_date
                                  ? formatDate(date.leaving_date)
                                  : "--"}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000000] z-50 "
                    onClick={() => setHistory(false)}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default OperatorDetails;

// {operatorDetail?.date_list?.map((date) => {
//   <>
//   <div key={date} className="flex flex-col gap-6 space-y-3 ">

//     {console.log(formatDate(date.joining_date))}
//   {date.leaving_date ? <>{console.log(formatDate(date.leaving_date))}</> : "--"}

//   </div>
//   </>
// })}
