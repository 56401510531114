import React, { useState } from "react";
import NewMachine from "./components/NewMachine";
import BasicFormData from "./components/BasicFormData";
import { useGetAsset, useGetCustomer } from "./api/newLeaseApi";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import { Router, useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";

const CreateComponent = ({ leaseData, setLeaseData }) => {
  const [loading, setLoading] = useState(false); // Create loading state
  let [machineData, setMachineData] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({
    customer: false,
    customer_po_number: false,

    internal_reference_number: false,
  });

  const [formErrorMachine, setFormErrorMachine] = useState({
    asset: false,
    monthly_rental_amount: false,
    rental_start_date: false,


  })

  // Add machine logic
  const addMachine = (e) => {
    e.preventDefault();

    const newMachine = {
      asset: "", // Asset will be selected later,
      rental_location: "",

      rental_start_date: "",
      rental_end_date: "",
      select_number_of_operators: "",
      select_operators: [], // This will store the selected operators
      total_working_hours_in_month: "",
      total_working_days: "",
      nominal_hours_per_day: "",
      normal_rate: "",
      contract_value: "",
      overtime_rate: "",
      expected_revenue_without_overtime: "",
      total_working_days: "",
      monthly_rental_amount: "",
      normal_amount: "",
      overtime_amount: "",
      total_claimable_amount: "",
    };

    // Add the new machine to the machineData array
    setMachineData([...machineData, newMachine]);
    setCount((prevCount) => prevCount + 1); // Increment the machine count
  };

  // console.log(formErrors)
  

  let assignOperator = [];



  for (let i = 0; i < machineData.length; i++) {
    assignOperator.push(machineData[i].select_operators);
  }

  const validateLeaseData = (data) => {
    const errors = [];

    if (!data.customer) {
      errors.push("Please Add Customer.");
      setFormErrors({ customer: true });
    }
    if (!data.customer_po_number) {
      errors.push("Please Add Customer PO Number.");
      setFormErrors({ customer_po_number: true });
    }
    if (!data.machines || data.machines.length === 0) {
      errors.push("Please Add Machines.");
    } else {
      data.machines.forEach((machine, index) => {
        if (!machine.asset) {
          errors.push(`Machine ${index + 1}: Please Add Asset.`);
          // setFormErrors({ machine: { asset_no: true } });
          setFormErrorMachine({...formErrorMachine, asset: true,})
        }
        if (machine.rental_start_date && machine.rental_end_date) {
          if (machine.rental_end_date < machine.rental_start_date) {
            errors.push(
              `Machine ${
                index + 1
              }: Rental End Date must be greater than Start Date.`
            );
          
          }
        } else {
          errors.push(
            `Machine ${index + 1}: Please Add Rental Start and End Date.`
          );
          setFormErrorMachine({
            ...formErrorMachine, rental_start_date:true
          })
        }
        if (!machine.monthly_rental_amount) {
          errors.push(
            `Machine ${index + 1}: Please Add Monthly Rental Amount.`
          );
          setFormErrorMachine({ ...formErrorMachine,
            monthly_rental_amount: true,
          
          })
        }
      });
    }

    return errors.length ? errors : null;
  };

  // console.log(machineData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const submittedData = {
      ...leaseData,
      machines: machineData,
    };

    // Perform validation
    const validationErrors = validateLeaseData(submittedData);
    if (validationErrors) {
      validationErrors.forEach((error) => toast.error(error));
      setLoading(false);
      return;
    }
    console.log(submittedData)

    try {
      // API call to submit the lease data
      // const { data } = await axios.post(
      //   `${setBaseUrl}/lease/create`,
      //   submittedData,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-access-tokens": sessionStorage.getItem("token"),
      //     },
      //   }
      // );

      const res = await fetch(`${setBaseUrl}/lease/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(submittedData),
      })
      // Handle successful response

      
      if(res.status === 200) {
        toast.success("Lease created successfully!");
        navigate(`/lease`);
      } else {
        toast.error("Failed to create the lease. Please try again");
        console.error("Failed");
      }
    } catch (error) {
      // Enhanced API error handling
      if (error.response) {
        toast.error(
          error.response.data?.message || "Failed to create the lease."
        );
      } else if (error.request) {
        toast.error("No response from the server. Please try again.");
      } else {
        toast.error("An unexpected error occurred.");
      }
      console.error("Error details:", error);
    } finally {
      setLoading(false); // Ensure loading state is cleared
    }
  };

  const { customerArray } = useGetCustomer(`${setBaseUrl}/lease/get-customer`);





  return (
    <>
      <div className="mt-2">
        <form
          className="text-xs space-y-2 bg-white p-5 shadow-md"
          onSubmit={handleSubmit}
        >
          {/* <h1>Add Basic Details</h1> */}
          <div className="flex flex-col md:flex-row justify-start gap-10 border p-5">
            <div className="flex flex-col">
              <BasicFormData
                name="Customer Name"
                type=""
                keyNameType="customer"
                label={leaseData.customer}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
                customerArray={customerArray}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <BasicFormData
                name="Customer PO No"
                type="text"
                keyNameType="customer_po_number"
                label={leaseData.customer_po_number}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />
              <BasicFormData
                name="Internal Reference Number"
                type="text"
                keyNameType="internal_reference_number"
                label={leaseData.internal_reference_number}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
              />

              <BasicFormData
                name="Lease Type"
                keyNameType="lease_type"
                label={leaseData.lease_type}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              {/* <BasicFormData
                name="Asset Location"
                type="text"
                keyNameType="asset_location"
                label={leaseData.asset_location}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              /> */}
            </div>
            <div className="flex flex-col">
              <BasicFormData
                name="Currency"
                type="text"
                keyNameType="currency"
                label={leaseData.currency}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              {/* <BasicFormData
                name="Transport Charges(MOB)"
                type="number"
                keyNameType="transport_charges_with_mob"
                label={leaseData.transport_charges_with_mob}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />
              <BasicFormData
                name="Transport Charges(De-Mob)"
                type="number"
                keyNameType="transport_charges_without_mob"
                label={leaseData.transport_charges_without_mob}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              /> */}
              {/* <BasicFormData
                name="Claimable Amount"
                type="text"
                keyNameType="total_claimable_amount"
                label={leaseData.total_claimable_amount}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
                
              /> */}
              <BasicFormData
                name="Reimbursements"
                type="number"
                keyNameType="reimbursements"
                label={leaseData.reimbursements}
                leaseData={leaseData}
                setLeaseData={setLeaseData}
              />

              <BasicFormData
                name="Sale Person"
                type="text"
                keyNameType="sale_person"
                label={leaseData.sale_person}
                leaseData={leaseData}
                setLeaseData={setLeaseData}

              />
            </div>
          </div>

          {/* <h1>Add New Machine</h1> */}
          <button
            title="add new machine to the rental"
            className="bg-green-2 rounded-[2px] shadow-md  text-white px-2 py-1"
            onClick={addMachine}
          >
            Add Machine
          </button>

          {/* Pass machine data and other relevant props to NewMachine */}
          <NewMachine
            count={count}
            setCount={setCount}
            machineData={machineData}
            setMachineData={setMachineData}
            selectedOperator={selectedOperator}
            setSelectedOperator={setSelectedOperator}
            // formErrors={formErrors}
            // setFormErrors={setFormErrors}
            formErrorMachine={formErrorMachine}
            setFormErrorMachine={setFormErrorMachine}
          />

          <div className="flex gap-3">
            <button
              className="border-light-2 text-text-1 border cursor-pointer rounded-[2px] shadow-md px-2 py-1 "
              onClick={() => navigate("/lease")}
              title="return to lease table"
            >
              Cancel
            </button>
            <button
              title="create a new lease"
              className={`rounded-[2px] shadow-md px-2 py-1 
              ${
                leaseData.customer || machineData.asset
                  ? "bg-green-2 text-white cursor-pointer"
                  : "bg-light-2 text-light-4 cursor-not-allowed pointer-events-none"
              }`}
              type="submit"
              disabled={(!leaseData.customer && !machineData.asset) || loading}
            >
              {loading ? 
                 <CircularProgress size={18} sx={{ color: "#fff" }} />
              
              : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateComponent;
