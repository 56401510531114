// import React, { useEffect, useRef, useState } from 'react'
// import { useParams } from 'react-router-dom';
// import { setBaseUrl } from '../../config';
// import Layout from '../../components/Layout';

// const LogisticsDetails = () => {
//     const { id } = useParams();
//       const [formData, setFormData] = useState([]);
// const hasGetLogistics = useRef(false)


//       const getLogisticsData = async () => {
//         try {
//           const res = await fetch(`${setBaseUrl}/lease/get-logistic-id`, {
//             headers: {
//               "Content-Type": "application/json",
//               "x-access-tokens": sessionStorage.getItem("token"),
//               id: id,
//             },
//           });

//           if (res.ok) {
//             const data = await res.json();
//             setFormData(data); // Initialize formData with fetched data
//           } else {
//             console.error("Failed to fetch logistics data.");
//           }
//         } catch (error) {
//           console.error("Error fetching logistics data:", error);
//         }
//       };


//        useEffect(() => {
//           if (!hasGetLogistics.current) {
//             hasGetLogistics.current = true;
//             getLogisticsData();
//           }
//         }, []);

//         console.log(formData)

//   return (
//     <Layout>LogisticsDetails</Layout>
//   )
// }

// export default LogisticsDetails
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { setBaseUrl } from '../../config';
import Layout from '../../components/Layout';
import { currency } from '../../customFunctions/FormatDate';


const LogisticsDetails = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState(null);
    const hasGetLogistics = useRef(false);
    const navigate = useNavigate();

    const getLogisticsData = async () => {
        try {
            const res = await fetch(`${setBaseUrl}/lease/get-logistic-id`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-tokens": sessionStorage.getItem("token"),
                    id: id,
                },
            });

            if (res.ok) {
                const data = await res.json();
                setFormData(data);
            } else {
                console.error("Failed to fetch logistics data.");
            }
        } catch (error) {
            console.error("Error fetching logistics data:", error);
        }
    };

    useEffect(() => {
        if (!hasGetLogistics.current) {
            hasGetLogistics.current = true;
            getLogisticsData();
        }
    }, []);

    console.log(formData);

    if (!formData) {
        return <Layout><p className="text-center text-xs text-gray-500">Loading logistics details...</p></Layout>;
    }

    const {
        assets_no,
        lease_RSO_nos,
        asset_total,
        customer,
        arrival_date,
        awb_number,
        courier_company,
        date_of_dispatch,
        destination,
        mob_demob_state,
        origin,
        total_km,
        transportation_cost,
        bill_amount,
        dc_date,
        dc_no,
        rate_per_km, transporter_bill_date,
        transporter_bill_no, truck_ft, truck_no
    } = formData;

    return (
        <Layout>
            <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
                <h2 className="text-base font-semibold text-gray-800 mb-6">Logistics Details</h2>

                <div className="container mx-auto p-6">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 text-xs">
                        {/* Logistic ID Section */}
                        {/* <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Logistic ID:</label>
                            <p className="text-gray-600 mt-1">{id}</p>
                        </div> */}

                        {/* Assets No Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Assets No:</label>
                            <p className="text-gray-600 mt-1">{assets_no.join(', ')}</p>
                        </div>

                        {/* RSO Number Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">RSO Number:</label>
                            <p className="text-gray-600 mt-1">{lease_RSO_nos.join(', ')}</p>
                        </div>

                        {/* Asset Total Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Asset Total:</label>
                            <p className="text-gray-600 mt-1">₹{asset_total[0] === "nill" || asset_total[0] === null || asset_total[0] === undefined ? "" : currency(parseFloat(asset_total[0]).toFixed(2))}/-, ₹{asset_total[1] === "nill" || asset_total[1] === null || asset_total[1] === undefined ? 0 : currency(parseFloat(asset_total[1]).toFixed(2))}/-</p>
                        </div>

                        {/* Customer Section */}
                        {/* <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Customer(s):</label>
                            <p className="text-gray-600 mt-1">{customer.join(', ')}</p>
                        </div> */}

                        {/* Arrival Date Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Arrival Date:</label>
                            <p className="text-gray-600 mt-1">{arrival_date || 'N/A'}</p>
                        </div>

                        {/* AWB Number Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">AWB Number:</label>
                            <p className="text-gray-600 mt-1">{awb_number || 'N/A'}</p>
                        </div>

                        {/* Courier Company Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Courier Company:</label>
                            <p className="text-gray-600 mt-1">{courier_company || 'N/A'}</p>
                        </div>

                        {/* Date of Dispatch Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Date of Dispatch:</label>
                            <p className="text-gray-600 mt-1">{date_of_dispatch || 'N/A'}</p>
                        </div>

                        {/* Destination Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Destination:</label>
                            <p className="text-gray-600 mt-1">{destination || 'N/A'}km</p>
                        </div>

                        {/* Mob/Demob State Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Mob/Demob State:</label>
                            <p className="text-gray-600 mt-1">{mob_demob_state || 'N/A'}</p>
                        </div>

                        {/* Origin Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Origin:</label>
                            <p className="text-gray-600 mt-1">{origin || 'N/A'}</p>
                        </div>

                        {/* Total Km Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Total Km:</label>
                            <p className="text-gray-600 mt-1">{total_km || 'N/A'}</p>
                        </div>

                        {/* Transportation Cost Section */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Transportation Cost:</label>
                            <p className="text-gray-600 mt-1">₹{transportation_cost ? transportation_cost : 'N/A'}/-</p>
                        </div>

                        {/* Bill Aoumnt */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Bill Amount: </label>
                            <p className="text-gray-600 mt-1">₹{bill_amount || 'N/A'}/-</p>
                        </div>


                        {/* DC Date */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">DC Date: </label>
                            <p className="text-gray-600 mt-1">{dc_date || 'N/A'}</p>
                        </div>

                        {/* DC Number */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">DC Number: </label>
                            <p className="text-gray-600 mt-1">{dc_no || 'N/A'}</p>
                        </div>

                        {/* Rate/KM */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Rate/Km: </label>
                            <p className="text-gray-600 mt-1">₹{rate_per_km || 'N/A'}</p>
                        </div>


                        {/* Rate/KM */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Transporter Bill Date: </label>
                            <p className="text-gray-600 mt-1">{transporter_bill_date || 'N/A'}</p>
                        </div>


                        {/* transporter_bill_no */}
                        <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Transporter Bill Number: </label>
                            <p className="text-gray-600 mt-1">{transporter_bill_no || 'N/A'}</p>
                        </div>


                           {/* truck_ft */}
                           <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Truck FT: </label>
                            <p className="text-gray-600 mt-1">{truck_ft || 'N/A'}</p>
                        </div>


                               {/* truck_ft */}
                               <div className="p-4 border rounded-lg bg-gray-50 shadow-sm">
                            <label className="font-medium text-gray-700">Truck Number: </label>
                            <p className="text-gray-600 mt-1">{truck_no || 'N/A'}</p>
                        </div>

                    </div>
                </div>

                <div className="mt-8 flex justify-between">
                    <button
                        onClick={() => navigate('/logistics')}
                        className="border  buttons"
                    >
                        Back
                    </button>
                    <button className="buttons bg-green-2 text-white" onClick={() => navigate(`/update-logistics/${id}`)} >
                        Update
                    </button>
                </div>
            </div>
        </Layout>
    );
};

export default LogisticsDetails;
