import React, { useEffect, useState, useMemo, useRef, useContext } from "react";

import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import TablePagination from "@mui/material/TablePagination";
import Layout from "../../components/Layout";
import { setBaseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { formatDate } from "../../components/Modals/AssetDetails/Tabs/FormatDate";
import { PermissionContext } from "../../Context/PermissionsContext";
import { NavbarContext } from "../../Context/NavbarContext";

const Logistics = () => {
  const [logisticsData, setLogisticsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const [perms, setPerms] = useContext(PermissionContext);

  const [navState, setNavState] = useContext(NavbarContext);

  useEffect(() => {
    if (sessionStorage.getItem("asset_tracker_logged_in") !== "true") {
      navigate("/login");
    }
    // assets();
    setNavState(4);
  }, []);

  useEffect(() => {
    let getPermissionsFromSession = JSON.parse(
      sessionStorage.getItem("permissions")
    );
    setPerms(getPermissionsFromSession);
  }, []);

  const hasLogistics = useRef(false);

  const getLogistics = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-logistic`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      const data = await res.json();
      setLogisticsData(data);
      setFilteredData(data); // Initialize filteredData with full dataset
    } catch (error) {
      console.error("Error fetching logistics data:", error);
      alert("Something went wrong, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasLogistics.current) {
      hasLogistics.current = true;
      getLogistics();
    }
  }, []);

  const debounce = (fn, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => fn(...args), delay);
    };
  };

  useEffect(() => {
    setFilteredData(logisticsData);
  }, [logisticsData]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    debouncedFilter(value);
  };

  const debouncedFilter = useMemo(
    () =>
      debounce((searchValue) => {
        const searchLower = searchValue.toLowerCase();
        const filtered = logisticsData.filter((item) => {
          const hasMatchingLogisticMap = item.LogisticMap?.some(
            (logisticItem) =>
              (logisticItem.odoo_order_id || "")
                .toLowerCase()
                .includes(searchLower) ||
              (logisticItem.asset_no || "").toLowerCase().includes(searchLower)
          );

          const hasMatchingOtherFields = (item.date_of_dispatch || "")
            .toLowerCase()
            .includes(searchLower);

          // Specific filtering for "mob" and excluding "demob"
          const isMobDemobMatch =
            searchLower === "mob"
              ? item.mob_demob_state?.toLowerCase() === "mob"
              : searchLower === "demob"
              ? item.mob_demob_state?.toLowerCase() === "demob"
              : (item.mob_demob_state || "")
                  .toLowerCase()
                  .includes(searchLower);

          return (
            hasMatchingLogisticMap || hasMatchingOtherFields || isMobDemobMatch
          );
        });
        setFilteredData(filtered);
      }, 300),
    [logisticsData]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = filteredData
    .flatMap(
      (item) =>
        item.LogisticMap?.map((logisticItem) => ({
          rsoNo: logisticItem.odoo_order_id || "N/A",
          asset: logisticItem.asset_no || "N/A",
          dispatchDate: item.date_of_dispatch || "N/A",
          mobDemob: item.mob_demob_state || "N/A",
        })) || []
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Apply pagination here
    .map((row, index) => ({
      ...row,
      id: `${page * rowsPerPage + index + 1}`, // Use the correct counter value for this row
      counter: page * rowsPerPage + index + 1, // Correctly calculate row number for the page
    }));

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <span title="Row Number" arrow placement="top">
          <span>#</span>
        </span>
      ),
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },
    {
      field: "rsoNo",
      headerName: "RSO No",
      width: 300,
      renderHeader: () => (
        <span title="RSO Number" arrow placement="top">
          <span>RSO No</span>
        </span>
      ),
    },
    {
      field: "asset",
      headerName: "Asset",
      width: 300,
      renderHeader: () => (
        <span title="Asset Number" arrow placement="top">
          <span>Asset</span>
        </span>
      ),
    },
    {
      field: "dispatchDate",
      headerName: "Dispatch Date",
      width: 300,
      renderHeader: () => (
        <span title="Date of Dispatch" arrow placement="top">
          <span>Dispatch Date</span>
        </span>
      ),
      renderCell: (params) => <>{formatDate(params.row.dispatchDate)}</>, // Use the dispatchDate field
    },
    {
      field: "mobDemob",
      headerName: "Mob/DeMob",
      width: 300,
      renderHeader: () => (
        <span title="Mobility or Demobilization State" arrow placement="top">
          <span>Mobilization or Demobilization</span>
        </span>
      ),
    },
  ];

  const handleRowClick = (params) => {
    navigate(`/update-logistics/${params.row.id}`);
  };

  const handleExport = () => {
    try {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Flatten the data for export
      const exportData = filteredData.flatMap(
        (item) =>
          item.LogisticMap?.map((logisticItem) => ({
            "RSO No": logisticItem.odoo_order_id || "N/A",
            "Asset No": logisticItem.asset_no || "N/A",
            "Dispatch Date": formatDate(item.date_of_dispatch) || "N/A",
            "Mob/Demob": item.mob_demob_state || "N/A",
          })) || []
      );

      // Convert data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Logistics");

      // Generate and download Excel file
      XLSX.writeFile(workbook, "logistics_data.xlsx");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      alert("Failed to export data. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="p-6">
        <div className="flex justify-between gap-4 items-center space-x-4 mb-4">
          <h1 className="text-dark-6 text-xs font-bold">Logistics Table</h1>
        </div>

        <Paper className="p-4">
          <div className="mb-4 flex justify-between">
            <FormControl variant="outlined" fullWidth>
              <input
              title="search logistics"
                variant="outlined"
                placeholder="Search"
                value={search}
                onChange={handleSearchChange}
                size="small"
                className="border-light-5 border bg-gray-50 h-[30px] w-[300px] p-[5px] rounded-[4px] text-[12px] items-center"
              />
            </FormControl>
            <div className="flex gap-3">
              <span title="Add New Logistics" arrow placement="top">
                <button
                  variant="contained"
                  color="primary"
                className="buttons bg-blue-2 text-white"
                  onClick={() => navigate("/new-logistics")}
                >
                  Add New
                </button>
              </span>
              <span title="Export to Excel" arrow placement="top">
                <button
                 className="buttons bg-white text-black border"
                  onClick={handleExport}
                >
                  Export
                </button>
              </span>
            </div>
          </div>

          <div style={{ height: "60vh", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={loading}
              pageSize={rowsPerPage}
              paginationMode="server"
              pagination={false}
              onRowClick={handleRowClick} // Row click handler
              components={{
                NoRowsOverlay: () => (
                  <div style={{ padding: "1rem", textAlign: "center" }}>
                    {search
                      ? "No results match your search."
                      : "No data available."}
                  </div>
                ),
              }}
              sx={{
                "& .MuiDataGrid-footerContainer": {
                  display: "none", // Hides the footer container
                },
                "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
                "& .MuiDataGrid-columnHeaders": {
                  fontSize: "12px",
                  backgroundColor: "#000",
                  fontWeight: "bold",
                  color: "#006DB6",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                  height: 20,
                },
                "& .MuiDataGrid-cell": { fontSize: "12px" },
                "& .MuiDataGrid-virtualScroller": {
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none",
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              }}
            />
          </div>

          <TablePagination
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 30, 50, filteredData.length]}
            labelRowsPerPage="Rows:"
          />
        </Paper>
      </div>
    </Layout>
  );
};

export default Logistics;
