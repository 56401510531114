import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../../Context/PermissionsContext";
import { FaSort } from "react-icons/fa";
import { useContext } from "react";
import {
  capitalizeFirstLetter,
  currency,
  customerGST,
  customerName,
  formatDate,
} from "../../Modals/AssetDetails/Tabs/FormatDate";
import { sortAssetDetails, toggleSortOrder } from "../../../utils/sortUtils";

import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

export const CurrentItems = ({
  leaseDetails,
  setLoader,
  deleted,
  setDeleted,
  selected,
  selectedItem,
  setSelectedItem,
  selectTableData,
  sortHeader,
  sort,
  setSort,
  sortOrder,
  setSortOrder,
  filteredDataStack,
  setFilteredDataStack,
  // sortedLeaseDetails,
  // setSortedLeaseDetails,
  tableArray,
  setSelectTableData,
}) => {
  const [perms] = useContext(PermissionContext);
  const navigate = useNavigate();
  const [tableAnimation, setTableAnimation] = useState(false);
  const [openTable, setopenTable] = useState(false);
  // Delete Lease
  const deleteLease = async (id) => {
    console.log(id);
    try {
      setLoader(true);
      const { data } = axios.delete(
        `${setBaseUrl}/lease/delete`,

        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
          data: {
            id: id,
          },
        }
      );
      setDeleted(!deleted);
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoader(false);
      return;
    }
  };

  // console.log(filteredDataStack)

  const handleClick = (id) => {
    navigate("/lease/leaseDetails/" + id);
  };

  const handleUpdate = (id) => {
    navigate("/lease/leaseUpdate/" + id);
  };
  useEffect(() => {
    setTimeout(() => {
      setTableAnimation(true);
    }, 400);
  }, [deleted]);


 

  useEffect(() => {
    const filteredData = leaseDetails.filter((item) => {
      return (
        // selectedItem === "all Lease" ||
        // (`${item?.lease_status}`.indexOf(selectedItem) !== -1 &&
        //   selectedItem !== "active") ||
        //   ((item?.lease_status === "active" ||
        //     item?.lease_status.startsWith("expiring")) &&
        //     selectedItem === "active")
        selectedItem === "all Lease" ||
        (item?.lease_status.indexOf(selectedItem[1][0]) !== -1 &&
          selectedItem[1][0] !== "active") ||
        ((item?.lease_status === "active" ||
          item?.lease_status.startsWith("expiring")) &&
          selectedItem[1][0] === "active") ||
        item?.lease_status === selectedItem[1]
      );
    });

    // Storing the filtered data into our stack
    setFilteredDataStack(filteredData);
  }, [selectedItem]);
  // console.log("HEllo");
  // const filteredData = sortedLeaseDetails.filter((item) => {
  //   return (
  //     selectedItem === "all Lease" ||
  //     (`${item?.lease_status}`.indexOf(selectedItem) !== -1 && selectedItem !== "active") ||
  //     (item?.lease_status === "active" && selectedItem === "active")
  //   );
  // });

  // // Storing the filtered data into the stack
  // setFilteredDataStack(filteredData);

  // Selected items
  const [allSelected, setAllSelected] = useState(false);
  const handleSelectAllChange = (event) => {
    const newAllSelected = event.target.checked;
    setAllSelected(newAllSelected);

    const updatedItems = leaseDetails.map((item) => ({
      ...item,
      selected: newAllSelected,
    }));
    setFilteredDataStack(updatedItems);
  };

  const handleItemChange = (itemId) => {
    const updatedItems = filteredDataStack.map((item) =>
      item.id === itemId ? { ...item, selected: !item.selected } : item
    );

    setFilteredDataStack(updatedItems);

    // Update `allSelected` if all items are selected after the change
    setAllSelected(updatedItems.every((item) => item.selected));
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = selectTableData;

  // Calculate indices for the current rows
  const indexOfLastRow = Math.min(
    currentPage * rowsPerPage,
    filteredDataStack.length
  ); // Clamp to total length
  const indexOfFirstRow = Math.min(
    indexOfLastRow - rowsPerPage + 1,
    filteredDataStack.length
  ); // Adjust for the last page

  // Adjust to ensure only valid rows are displayed
  const currentRows = filteredDataStack.slice(
    indexOfFirstRow - 1,
    indexOfLastRow
  ); // Adjust for zero-based index

  const totalPages = Math.ceil(filteredDataStack.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };


   const sortedLeaseDetails = leaseDetails.sort((a,b) => {})

  return (
    <>
      {/* Pagination Controls */}
      <div className="flex justify-end items-center mt-4">
        {openTable && (
          <>
            {/* Dropdown Menu */}
            <div
              className={`absolute mt-[10.5%] border transition-all ease-in-out duration-300 z-[9999]   text-xs
                  flex flex-col justify-center rounded px-2
                 bg-white w-fit shadow-2xl h-fit items-start transform opacity-0 scale-95 ${
                   selectTableData === null ? "right-[10.2%]" : "right-[11%]"
                 }`}
              style={{
                opacity: openTable ? 1 : 0,
                transform: openTable ? "scale(1)" : "scale(0.95)",
              }}
            >
              {tableArray.map((table) => (
                <button
                  key={table}
                  className="hover:bg-[#EEE] w-full py-1 border-b px-2"
                  onClick={() => setSelectTableData(table)}
                >
                  {table}
                </button>
              ))}
              {/* <span className="text-xs p-1 cursor-pointer hover:bg-[#EEE]" onClick={() => {setSelectTableData(null); setopenTable(false)}}>all data</span> */}
            </div>

            {/* Backdrop */}
            <div
              className="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-5 z-50 transition-opacity duration-300"
              style={{ opacity: openTable ? 1 : 0 }}
              onClick={() => setopenTable(false)}
            />
          </>
        )}
        <button
          className=" filter-items rounded-[8px] bg-light-8 text-xs border text-light-2  justify-center  items-center flex gap-1 cursor-pointer"
          onClick={() => setopenTable(true)}
        >
          <IoIosArrowDown className="w-4 h-4" />
        </button>

        <button className="p-1.5 flex items-center rounded cursor-pointer text-xs">
          {/* {`${indexOfFirstRow + 1} - ${indexOfLastRow} of ${assetDetails.length}`} */}

          {`${indexOfFirstRow}-${indexOfLastRow} of ${filteredDataStack.length}`}
        </button>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="p-1.5 flex items-center rounded cursor-pointer text-xs disabled:opacity-50"
        >
          <IoIosArrowBack className="h-4 w-4 bg-light-8 rounded-full text-light-2 -1" />
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="p-1.5 flex items-center rounded cursor-pointer text-xs disabled:opacity-50"
        >
          <IoIosArrowForward className="h-4 w-4 bg-light-8 rounded-full text-light-2 -1" />
        </button>
      </div>

      {/* table  */}
      <div className={` bg-white p-2 rounded-[6px] w-full ${indexOfLastRow === 10 ? 'h-fit':"h-[67vh]"} my-2  shadow-md hide-scrollbar  max-lg:overflow-x-auto overflow-y-auto`}>
        {leaseDetails === undefined || leaseDetails.length === 0 ? (
          <div className="text-xs h-[50vh] flex rounded-lg justify-center items-center dark:text-white">
            No Data available...
          </div>
        ) : (
          <>
            <div>
              <table
                className={`table ${
                  tableAnimation ? "show-rows" : ""
                } border-separate border-spacing-y-0 text-center text-xs w-full relative`}
              >
                <thead className="h-10 sticky  dark:bg-gray-700">
                  <tr>
                    <th className="border-b">
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={handleSelectAllChange}
                        className="focus:outline-none focus:ring-0 rounded-[3px] text-blue-2 bg-transparent "
                      />
                    </th>
                    <th>Serial No</th>
                    {sortHeader.map((header) => (
                      <th
                        key={header}
                        className="cursor-pointer"
                        onClick={() => {
                          if (sort === header) {
                            setSortOrder(toggleSortOrder(sortOrder));
                          } else {
                            setSort(header); // Set the new sorting column
                            setSortOrder("asc"); // Default to ascending on new sort column
                          }
                        }}
                      >
                        <span className={`text-center items-center flex justify-center ${sort === header ? 'text-blue-2':'text-dark-6'}`}>
                          {header}

                          {sort === header ? (
                            sortOrder === "asc" ? (
                              "▲"
                            ) : (
                              "▼"
                            )
                          ) : (
                            <FaSort />
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-900">
                  {currentRows
                    ?.
                    sort((a, b) => {
                      // Sorting logic for RSO No
                      if (sort === "RSO No") {
                        const extractDetails = (value) => {
                          const match = value?.match(/RSO-(\d{4})-(\d+)/);
                          // Extract the year and sequence number, defaulting to 0 if no match
                          return match ? { year: parseInt(match[1], 10), sequence: parseInt(match[2], 10) } : { year: 0, sequence: 0 };
                        };
                      
                        // Extract details for comparison
                        const aDetails = extractDetails(a.odoo_order_id);
                        const bDetails = extractDetails(b.odoo_order_id);
                      
                        // Sort by year first
                        if (aDetails.year !== bDetails.year) {
                          return sortOrder === "asc"
                            ? aDetails.year - bDetails.year
                            : bDetails.year - aDetails.year;
                        }
                      
                        // If years are equal, sort by sequence number
                        return sortOrder === "asc"
                          ? aDetails.sequence - bDetails.sequence
                          : bDetails.sequence - aDetails.sequence;
                      }
                      else if (sort === "Asset No") {
                        const aValue = a.asset_no || "";
                        const bValue = b.asset_no || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.asset_no)
                          : bValue.localeCompare(a.asset_no);
                      } else if (sort === "Customer Name") {
                        const aValue = a.customer || "";
                        const bValue = b.customer || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.customer)
                          : bValue.localeCompare(a.customer);
                      } else if (sort === "Start Date") {
                        const aValue = a.rental_start_date || "";
                        const bValue = b.rental_start_date || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.rental_start_date)
                          : bValue.localeCompare(a.rental_start_date);
                      } else if (sort === "End Date") {
                        const aValue = a.rental_end_date || "";
                        const bValue = b.rental_end_date || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.rental_end_date)
                          : bValue.localeCompare(a.rental_end_date);
                      } else if (sort === "Total Amount") {
                        const aValue = a.rental_end_date || "";
                        const bValue = b.rental_end_date || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.rental_end_date)
                          : bValue.localeCompare(a.rental_end_date);
                      } else if (sort === "Status") {
                        const aValue = a.lease_status || "";
                        const bValue = b.lease_status || "";
                        return sortOrder === "asc"
                          ? aValue.localeCompare(b.lease_status)
                          : bValue.localeCompare(a.lease_status);
                      }
                      return 0; // No sorting if RSO No isn't selected
                    })
                    .map((item, index) => {
                      let numb = 0;
                      if (item?.lease_status?.startsWith("expiring")) {
                        // Match a number within the string "expiring in X days"
                        const match = item?.lease_status.match(/\d+/);

                        if (match) {
                          numb = parseInt(match[0], 10);
                        }
                      }
                      if (selectTableData - 1 >= index || !selectTableData) {
                        return (
                          <tr
                            className="cursor-pointer  divide-y text-center items-center hover:bg-[#22222207] h-10 px-6"
                            key={item.odoo_order_id}
                          >
                            <td className=" w-[50px] border-b">
                              <input
                                type="checkbox"
                                checked={item.selected}
                                onChange={() => handleItemChange(item.id)}
                                className="focus:outline-none focus:ring-0 rounded-[3px] text-blue-2 bg-transparent"
                              />
                            </td>
                            <td className=" w-[60px]">{index + 1}</td>
                            <td className="">{item.asset_no} </td>
                            <td
                              className=""
                              onClick={() => handleClick(item.id)}
                            >
                              {item.odoo_order_id || "--"}
                            </td>
                            <td
                              className=" w-[250px]"
                              onClick={() => handleClick(item.id)}
                            >
                              {item.customer.split(" - ")[0]}
                            </td>
                            <td
                              className=""
                              onClick={() => handleClick(item.id)}
                            >
                              {item.customer.split(" - ")[1]}
                            </td>
                            <td
                              className=""
                              onClick={() => handleClick(item.id)}
                            >
                              ₹{" "}{currency(item.total_claimable_amount)}
                            </td>
                            <td
                              className=""
                              onClick={() => handleClick(item.id)}
                            >
                              {formatDate(item.rental_start_date)}
                            </td>
                            <td
                              className=""
                              onClick={() => handleClick(item.id)}
                            >
                              {item.closed_date
                                ? formatDate(item.closed_date)
                                : item.rental_extended_date
                                ? formatDate(
                                    item.rental_extended_date.split(" ")[0]
                                  )
                                : formatDate(item.rental_end_date)}
                            </td>
                            <td
                              className={`font-bold  p-1  ${
                                item.lease_status === "never assigned"
                                  ? "text-red-600"
                                  : item.lease_status === "inactive"
                                  ? "text-slate-500"
                                  : item.lease_status === "expired"
                                  ? "text-[#421515]"
                                  : "text-green-500"
                              }`}
                              onClick={() => handleClick(item.id)}
                            >
                              <button className="border p-1 rounded-[8px] text-[10px] shadow-md w-[59px]">

                              {item.lease_status.startsWith("expiring")
                                ? `Active (${numb} days)`
                                : item.lease_status === "inactive"
                                ? "Closed"
                                : capitalizeFirstLetter(item.lease_status)}
                                </button>
                            </td>

                            {/* <td className="border-b font-bold"><button onClick={deleteLease} className="border border-red-500 text-red-500 p-1">Delete</button></td> */}
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};
