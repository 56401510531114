import React, { useEffect, useState } from "react";
import SalesLayout from "./SalesLayout";
import { useNavigate, useParams } from "react-router-dom";
import { setBaseUrl } from "../../config";

import ServiceWallet from "../../components/ServicePerson/ServiceWallet";
import InstalledPart from "../../components/ServicePerson/InstalledPart";
import RemovedPart from "../../components/ServicePerson/RemovedPart";
import RecievedPart from "../../components/ServicePerson/RecievedPart";
import ServiceHeader from "../../components/ServicePerson/ServiceHeader";
import ServiceAsset from "../../components/ServicePerson/ServiceAsset";
import RequestPart from "../../components/ServicePerson/RequestPart";
import ClosureForm from "../../components/ServicePerson/ClosureForm";
import InstalledParts from "../../components/ServicePerson/InstalledParts";
import RejectionForm from "../../components/ServicePerson/RejectionForm";
import TableParts from "../../components/ServicePerson/TableParts";
import ServiceComments from "../../components/ServicePerson/ServiceComments";
import RequestForClosure from "../../components/ServicePerson/RequestForClosure";
import LocalPart from "../../components/ServicePerson/LocalPart";
import { useToast } from "../../Context/ToastContext";
import MaintenancePhoto from "../../components/Modals/Maintenance/MaintenancePhoto";

const ServiceDetails = () => {
  const { id } = useParams();
  const token = sessionStorage.getItem("sales_token");
  const [maintenance, setMaintenance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const [openInstalledParts, setOpenInstalledParts] = useState(false);
  const [assign, setAssign] = useState("Not-Assign");
  const [activeTab, setActiveTab] = useState('requestReceived');
  const [inventory, setInventory] = useState([]);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [openClosure, setOpenClosure] = useState(false) // request for closure
  const [rejectComment, setRejectComment] = useState(null)
  const navigate = useNavigate()

  const { addToast } = useToast()
  // Accept Service
  const handleServiceAction = async (isAccepted) => {
    try {
      const res = await fetch(`${setBaseUrl}/sale-service/accept-maintenance`, {
        method: "POST",
        headers: {
          "auth-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_accepeted: isAccepted,
          maintenance_id: id,
          comment: rejectComment
        }),
      });

      if (res.status === 200) {
        addToast(
          isAccepted ? "Maintenance Accepted" : "Maintenance Rejected",
          "success"
        );
        setAssign(isAccepted ? "Accept" : "Reject");
        if (isAccepted === false) {
          navigate('/sales-services/dashboard')
        }
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        throw new Error("Failed to update maintenance status");
      }
    } catch (error) {
      console.error("Service Action Error:", error);
      addToast("Maintenance action failed", "error");
    }
  };

  // Fetch maintenance details
  const getDetails = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/sale-service/get-own-maintance-id`, {
        headers: {
          "auth-token": token,
          "maintenace-id": id,
        },
      });
      const data = await res.json();
      setMaintenance(data?.data);
    } catch (error) {
      console.error("Failed to fetch maintenance details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch inventory
  const getInventory = async () => {
    setLoadingInventory(true);
    try {
      const res = await fetch(`${setBaseUrl}/sale-service/get-all-inventory-part`, {
        headers: {
          "auth-token": token,
        },
      });
      const data = await res.json();
      setInventory(data);
    } catch (error) {
      console.error("Failed to fetch inventory:", error);
    } finally {
      setLoadingInventory(false);
    }
  };

  useEffect(() => {
    getDetails();
    getInventory();
  }, []);



  if (loading) {
    return (
      <SalesLayout>
        <div className="text-center text-gray-600 flex items-center justify-center">Loading...</div>
      </SalesLayout>
    );
  }

  if (!maintenance) {
    return (
      <SalesLayout>
        <div className="text-center text-gray-600">No details available.</div>
      </SalesLayout>
    );
  }

  const renderAssignmentModal = () => (
    <div className="z-[9999] bg-white p-6 w-[400px] h-auto right-[5%] border rounded-lg shadow-lg fixed top-[20%]">
      <div className="mb-4">
        <h3 className="text-sm font-semibold text-gray-700 bg-gray-200 p-2 rounded-md">Breakdown Description</h3>
        <p className="text-xs text-gray-600 mt-2">{maintenance?.description || "No description available."}</p>

      </div>
      <span className="text-sm font-medium text-gray-800 block mb-4">Will you accept this assignment?</span>
      <div className="flex items-center gap-4">
        <button
          className="bg-green-2 hover:bg-green-3 w-full text-white px-4 py-2 rounded-md transition-all"
          onClick={() => handleServiceAction(true)}
        >
          Accept
        </button>
        <button
          className="border border-red-500 text-red-500 w-full hover:bg-red-50 px-4 py-2 rounded-md transition-all"
          onClick={() => setAssign('Reject')}
        >
          Reject
        </button>
      </div>
      {assign === "Reject" && <RejectionForm setRejectComment={setRejectComment} handleServiceAction={handleServiceAction} />}
    </div>


  );

  const renderMainContent = () => (
    <div className="flex items-start gap-2 bg-white p-4 rounded-[16px] mt-4">
      <ClosureForm maintenance={maintenance} id={id} />
      <TableParts inventory={inventory} maintenance={maintenance} id={id} />
    </div>
  );

  return (
    <SalesLayout>
      <ServiceAsset assetID={maintenance.asset_id} maintenanceData={maintenance} />
      <div className="p-4 border bg-light-8 mt-4 w-full">
        <ServiceHeader maintenanceData={maintenance} />


        <div className="flex items-end justify-end mt-2 gap-2">
          {/* Open Status Button */}
          <button
            title="Mark as Open"
            className={`buttons border w-[130px] ${maintenance.status === 'open' ? 'bg-green-2 text-white' : 'bg-text-1 text-white'}`}
          >
            Open
          </button>

          {/* In Progress Status Button */}
          <button
            title="Mark as In Progress"
            disabled={maintenance.status !== 'in_progress'}
            className={`buttons border w-[130px] ${maintenance.status === 'in_progress' ? 'bg-green-2 text-white' : 'bg-text-1 text-white'} ${maintenance.status !== 'in_progress' && 'cursor-not-allowed opacity-50'}`}
          >
            In Progress
          </button>

          {/* Close Status Button */}
          <button
            title="Complete and Request Closure"
            onClick={() => setOpenClosure(true)}
            disabled={maintenance.status === "closed" || !maintenance.is_accepeted ? true : false}
            className={`buttons border w-[130px] ${maintenance.status === "closed" ? 'bg-red-1 text-white' : 'border border-red-1 text-red-1'}`}
          >
            {maintenance.status === "closed" ? "Closed" : " Request Closure"}
          </button>
        </div>


        {maintenance.is_accepeted === true ? renderMainContent() : <>{renderAssignmentModal()} {renderAssignmentModal()} </>}


        {maintenance?.parts && (
          <InstalledParts maintenanceData={maintenance.parts} openInstalledParts={openInstalledParts} setOpenInstalledParts={setOpenInstalledParts} />
        )}

        <div className="border mt-4 p-4 gap-2 bg-white rounded-md shadow-sm">
          {maintenance?.photos && <MaintenancePhoto photos={maintenance?.photos} />}
          <span className="text-base text-blue-2 w-full p-2 mt-4 bg-gray-100 block">Breakdown Description</span>
          <p className="text-sm p-2 text-gray-700 border-b">{maintenance?.description || 'No details available'}</p>
          <h3 className="text-base text-blue-2 bg-gray-100 p-2 w-full">Discussion</h3>
          <ServiceComments comments={maintenance.comments} />
        </div>
      </div>
      {openWallet && <ServiceWallet id={id} setOpenWallet={setOpenWallet} />}
      {openClosure &&
        <RequestForClosure maintenance_id={id} setOpenClosure={setOpenClosure} />
      }


    </SalesLayout>
  );
};

export default ServiceDetails;