import { Link } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { FiChevronRight } from "react-icons/fi";

const Breadcrumb = ({ title1, label1, title2, number }) => {
  const breadcrumbs = [
    { label: title1, to: `/${label1}`, icon: <IoMdHome className="text-gray-500" /> },
    { label: title2, to: null },
    { label: number, to: null },
  ];

  return (
    <nav className="font-sans mb-2">
      <ol className="flex items-center space-x-2 text-sm text-gray-600">
        {breadcrumbs.map((crumb, index) => (
          <li key={index} className="flex items-center">
            {crumb.to ? (
              <Link
                to={crumb.to}
                className="flex items-center transition-colors duration-200 hover:text-blue-600"
              >
                {crumb.icon && <span className="mr-1">{crumb.icon}</span>}
                {crumb.label}
              </Link>
            ) : (
              <span className="font-medium text-gray-800">{crumb.label}</span>
            )}
            {index < breadcrumbs.length - 1 && (
              <FiChevronRight className="mx-2 text-gray-400" size={14} />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;