import React, { useState } from 'react'

const RsoUpdateDropdown = ({
    rsos, value, selectedRso, setSelectedRso
}) => {
  



    const [searchTerm, setSearchTerm] = useState("");
      const [showDropdown, setShowDropdown] = useState(false);
    
      // Filter options based on search input
      const filteredOptions = rsos.filter(rso =>
        rso.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    
      // Handle selection
      // Handle selection
      const handleSelect = (rso) => {
        if (!selectedRso || !Array.isArray(selectedRso)) {
          setSelectedRso([rso]); // Initialize with selected rso if undefined
        } else if (!selectedRso.some(selected => selected.value === rso.value)) {
          setSelectedRso([...selectedRso, rso]);
        }
      };
    
    
      // Remove selected rso
      const removeAsset = (assetToRemove) => {
        setSelectedRso(selectedRso.filter(rso => rso.value !== assetToRemove.value));
      };


  return (
      <div className="relative w-[350px]">
      {/* Selected Items */}
      <div
        className="w-full bg-gray-100 border-b border-gray-300 px-3 py-2 rounded-md cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedRso && selectedRso?.length > 0 ? (
          <div className="flex flex-wrap gap-2 z-[9999]">
            {selectedRso.map((rso) => (
              <span key={rso.value} className="bg-blue-500 text-white px-2 py-1 text-xs rounded flex items-center gap-1">
                {rso.label}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeAsset(rso);
                  }}
                  className="ml-1 text-white hover:text-red-300"
                >
                  ✕
                </button>
              </span>
            ))}
          </div>
        ) : (
          <span className="text-gray-400">Select RSO Number</span>
        )}
      </div>

      {/* Dropdown Menu */}
      {showDropdown && (
        <>
          <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-[10]">
            {/* Search Input */}
            <input
              type="text"
              className="w-full p-2 border-b border-gray-300 focus:outline-none"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {/* Options List */}
            <ul className="max-h-40 overflow-y-auto">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((rso) => (
                  <li
                    key={rso.value}
                    className="px-3 py-2 hover:bg-blue-100 cursor-pointer"
                    onClick={() => handleSelect(rso)}
                  >
                    {rso.label}
                  </li>
                ))
              ) : (
                <li className="px-3 py-2 text-gray-500">No results found</li>
              )}
            </ul>
          </div>
          <div className="bg-[#20202010] z-0 fixed top-0 left-0 bottom-0 right-0" onClick={(e) => setShowDropdown(false)} />
        </>
      )}
    </div>
  )
}

export default RsoUpdateDropdown