import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../../../config";

const UserLogData = ({id}) => {
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(id)

  const getUserLogData = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/dashboard/get-log-data-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          "asset-id": id,
        },
      });
      const data = await res.json();
      setLogData(data);
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    getUserLogData();
  };

  // console.log(logData);

  return (
    <>
      <button
        onClick={handleOpen}
        className="bg-light-2 text-xs text-white p-1.5 w-[100px] "
      >
        Display Data
      </button>
    <div className="container mx-auto p-6 text-xs ">
      {loading ? (
        <p className="mt-4 text-center text-light-1 font-semibold">Loading...</p>
      ) : (
        <div className="overflow-x-auto mt-4">
          <table className="min-w-full bg-white border border-gray-200 shadow rounded-lg">
            <thead className="bg-gray-100 border-b">
              <tr>
                <th className="text-left py-3 px-4 font-semibold text-gray-600">Act By</th>
                <th className="text-left py-3 px-4 font-semibold text-gray-600">Action Type</th>
                
                <th className="text-left py-3 px-4 font-semibold text-gray-600">Fields Are</th>
                
              </tr>
            </thead>
            <tbody>
              {logData.length > 0 ? (
                logData.map((data, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-b transition duration-200"
                  >
                    <td className="py-3 px-4 text-gray-700">{data.act_by}</td>
                    <td className="py-3 px-4 text-gray-700">{data.action_type}</td>
                   
                    <td className="py-3 px-4 text-gray-700 w-[300px]">{data.fields_are?.split(",id,")}
                    </td>
                    
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="py-4 px-4 text-center text-gray-500">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </>
  );
};

export default UserLogData;
