import { useState, useEffect } from "react";

export const useGetAssetWorkingTimeline = (url, id) => {
  const [assetData, setAssetData] = useState(null)
  const [commercialData, setCommercialData] = useState(null)
  const [reveniewData, setReveniewData] = useState(null)
    const [timeLine, setTimeLine] = useState({
        instance_time: {},
        working_time: [],
      });

      const [loading, setLoading] = useState(false)

      const fetchTimeLineData = async () => {
        setLoading(true)
        try {
          const response = await fetch(url, {
            headers: {
              "x-access-tokens": sessionStorage.getItem("token"),
              'id': id,
              'section': 'asset_dashboard'
              // 'section': section
            },
          });
    
          if (response.ok) {
            const items = await response.json();
            console.log(items)
            setTimeLine({
              instance_time: items.tele_data.instance_data,
              working_time: items.tele_data.working_time,
            });

            setAssetData(items)
  
          } else {
            console.error("Failed to fetch timeline data");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
            setLoading(false)
        }
      };
    
      useEffect(() => {
        fetchTimeLineData();
      }, [id]);


    return {timeLine: timeLine, assetData: assetData, reveniewData: reveniewData}
}