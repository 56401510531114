


import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setBaseUrl } from "../../config";
import { NavbarContext } from "../../Context/NavbarContext";
import { PermissionContext } from "../../Context/PermissionsContext";
import OuterLoader from "../../pages/OuterLoder";
import backgVideo from "../../assets/videos/Access-banner.mp4";
import DurbinLogo from "../../assets/images/DurbinLogo.jpg";
import useLocalstorage from "../../utils/useLocalstorage";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SalesLogin = () => {
  const [navState, setNavState] = useContext(NavbarContext);
  const [perms, setPerms] = useContext(PermissionContext);
  const { setValues, getValues } = useLocalstorage();
  const navigate = useNavigate();
  const emailInputRef = useRef(null);

  const [data, setData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

//   useEffect(() => {
//     setNavState(1);
//     checkIfLoggedIn();
//     focusEmailInput();
//   }, []);

  const focusEmailInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  const checkIfLoggedIn = () => {
    const isLoggedIn = sessionStorage.getItem("token");
    if (isLoggedIn) {
      navigate("/sales-services/dashboard");
    }
  };


  const LoginFunction = async () => {
    try {
      setLoader(true);
      const res = await fetch(`${setBaseUrl}/sale-service/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

   



      setLoader(false);

      const response = await res.json();
      console.log(response.data);
    //   navigateToPage(permissions);
    if(res.status === 200) {

      const token_id = response.data.user_token;
      const role = response.data.role;

      sessionStorage.setItem("sales_token", token_id);
      sessionStorage.setItem("sales_role", role);
      sessionStorage.setItem("sales_email", data.email)
      sessionStorage.setItem("sales_person_logged_in", "true")

        navigate("/sales-services/dashboard")
        toast.success("Sales person successfully logged in");
    }
    } catch (error) {
      setLoader(false);
      toast.error("Please Enter Valid Email and Password!");
      console.error(error)
    }
  };

//   const navigateToPage = (permissions) => {
//     try {
//       if (permissions.includes("ADMIN.ALL")) {
//         toast.success("User Successfully Logged In");
//         navigate("/assets");
//       } else if (
//         ["ASSETS.ALL", "ASSETS.VIEW", "ASSETS.CRU"].some((perm) =>
//           permissions.includes(perm)
//         )
//       ) {
//         toast.success("User Successfully Logged In");
//         navigate("/assets");
//       } else {
//         navigate("/login");
//       }
//     } catch (err) {
//       console.error("Error navigating to page:", err);
//     }
//   };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

//   const isValidPassword = (password) => {
//     return (
//       password.length >= 4 &&
//       /[A-Z]/.test(password) &&
//       /[a-z]/.test(password) &&
//       /\d/.test(password) &&
//       /[!@#$%^&*]/.test(password)
//     );
//   };

  if (loader) return <OuterLoader />;

  return (
    <div className="flex justify-center items-center h-screen text-xs">
      <div className="relative w-full h-full">
        <video
          className="w-full h-full object-cover"
          src={backgVideo}
          autoPlay
          loop
          muted
        />
        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black bg-opacity-30">
          <form
            className="bg-white text-black p-6 w-[320px] rounded-lg shadow-lg"
            onSubmit={(e) => {
              e.preventDefault();
              if (
                data.email &&
                data.password 
                // &&
                // isValidEmail(data.email) &&
                // isValidPassword(data.password)
              ) {
                LoginFunction();
              }
            }}
          >
           
             <div className="flex flex-col items-center">
              <img
                src={DurbinLogo}
                alt="Durbin Logo"
                className="w-20 h-20 rounded-full mb-2"
              />
              <h1 className="text-lg font-bold text-gray-700">Asset Tracker</h1>
              <p className="text-sm font-semibold text-blue-500">
                Sales Person Login
              </p>
            </div>

            <div className="mb-4">
              <input
                ref={emailInputRef}
                type="email"
                id="email"
                placeholder="Email"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                className={`w-full px-4 py-2 text-sm border rounded-lg focus:outline-none ${
                  data.email
                  
                //   && !isValidEmail(data.email)
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
              />
              {data.email && !isValidEmail(data.email) && (
                <p className="text-red-500 text-xs mt-1">
                  Invalid email format
                </p>
              )}
            </div>
             {/* Forgot Password */}
             <div className="flex justify-end mt-1">
              <span
                className="text-xs text-blue-500 hover:underline cursor-pointer"
                onClick={() => navigate("/sales-services/forgot-password/")}
              >
                Forgot Password?
              </span>
            </div>

            <div className="mb-4 relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Password"
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                className={`w-full px-4 py-2 text-sm border rounded-lg focus:outline-none`}
              />
              {data.password && (
                <div
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-dark-6"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <RemoveRedEyeOutlinedIcon />
                  )}
                </div>
              )}
            </div>

            {/* {data.password && !isValidPassword(data.password) && (
              <p className="text-red-1 text-[10px] mt-1">
                Password must be at least 8 characters, contain uppercase,
                lowercase, number & special character.
              </p>
            )} */}

            {data.password && (
              <p className="text-red-1 text-[10px] mt-1">
                 Password must be at least 8 characters, contain uppercase,
                 lowercase, number & special character.
              </p>
            )}

            <button
              type="submit"
              className={`
                
                w-full py-3 mt-6 text-white font-bold bg-blue-500 rounded-lg hover:bg-blue-600 transition-all duration-300
             `}
            //   disabled={
            //     !(
            //       data.email &&
            //       data.password &&
            //       isValidEmail(data.email) &&
            //       isValidPassword(data.password)
            //     )
            //   }
            disabled={
              !(data.email && data.password)
            }
            >
              {loader ? <span className="animate-spin">⏳</span> : "Login"}
            </button>
            <span className="text-xs mt-4">
              Don't have an account?
              <span
                className="text-blue-2 underline font-extrabold cursor-pointer"
                onClick={() => navigate("/sales-services/register/")}
              >
                Register
              </span>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SalesLogin;

