// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { setBaseUrl } from "../../config";
// // import { LoaderContext } from "../../Context/LoaderContext";
// import Loader from "../Loader";
// import { useNavigate, useParams } from "react-router-dom";
// import { formatDate, formatDateForInput, formatDateInput } from "./AssetDetails/Tabs/FormatDate";
// import Wallet from "./Wallet";

// function UpdateOperator() {
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [loader, setLoader] = useState(false);
//   const [isPhoneValid, setIsPhoneValid] = useState(null);
//   const [isAadharValid, setIsAadharValid] = useState(null);
//   const [operatorDetail, setOperatorDetail] = useState({
//     aadhar_no: "",
//     advance_paid_amount: '',
//     dob: "", //added Avijit
//     blood_group: "", // avijit
//     family: "", // avijit
//     photo: "", //avijit
//     emergency_mobile: "", //avijit
//     bank_details: {
//       account_no: "",
//       id: "",
//       ifsc_code: "",
//     },
//     id: "",
//     date_list: {
//       joining_date: "",
//       leaving_date: "",
//     },
//     name: "",
//     net_inhand_salary: "",
//     odoo_employee_no: "",
//     pf_account_no: "",
//     phone: {
//       id: "",
//       phone_no: "",
//       types: "+91",
//     },
//     lease_RSO_no: "",
//     asset_no: "",
//     wallet_arr: []
//   });

//   const getOperatorDetail = async () => {
//     try {
//       setLoader(true);
//       const { data } = await axios.get(`${setBaseUrl}/operator/get_by_id`, {
//         headers: {
//           "Content-Type": "application/json",
//           "x-access-tokens": sessionStorage.getItem("token"),
//           id: id,
//         },
//       });
//       // console.log(data);
//       setOperatorDetail(data);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoader(false);
//     }
//   };

//   const handlePhone = (e) => {
//     // Allow only numbers and restrict the length to 10
//     let phoneValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
//     if (phoneValue.length > 10) {
//       phoneValue = phoneValue.substring(0, 10); // Limit to 10 digits
//     }

//     setOperatorDetail({
//       ...operatorDetail,
//       phone: {
//         ...operatorDetail.phone,
//         phone_no: phoneValue,
//       },
//     });

//     setUpdateOperator({
//       ...updateOperator,
//       "phone": {
//         "phone_no": phoneValue,
//         "types": "+91"
//       }
//     })

//     // Validate phone number length
//     let phoneDigitCount = phoneValue.length;
//     if (phoneDigitCount === 10) {
//       setIsPhoneValid(null); // Valid phone number
//     } else {
//       setIsPhoneValid(
//         "Phone number must contain 10 digits. You gave: " + phoneDigitCount
//       );
//     }
//   };

//   const handleAdhaar = (e) => {
//     setOperatorDetail({
//       ...operatorDetail,
//       aadhar_no: e.target.value,
//     });

//     setUpdateOperator({
//       ...updateOperator,
//       "aadhar_no": e.target.value
//     })

//     let aadhar_no_value = e.target.value;
//     let aadhar_no_value_length = aadhar_no_value.length;
//     var expr =
//       /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
//     if (expr.test(aadhar_no_value)) {
//       setIsAadharValid(null);
//     } else {
//       setIsAadharValid(
//         "Adhaar number must contain 12 digits. You gave>> " +
//         aadhar_no_value_length
//       );
//     }
//   };

//   useEffect(() => {
//     getOperatorDetail();
//   }, []);

//   const [updateOperator, setUpdateOperator] = useState({
//     id: id,

//   })
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoader(true);
//     if (isPhoneValid !== null) {
//       alert("Please enter a valid phone number.");
//       return;
//     }
//     if (isAadharValid !== null) {
//       alert("Please enter a valid adhar number.");
//       return;
//     }

//     // console.log(updateOperator)
//     try {
//       const { data } = await axios.put(
//         `${setBaseUrl}/operator/update`,
//         updateOperator,
//         {
//           headers: {
//             "x-access-tokens": sessionStorage.getItem("token"),
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       // console.log(data);
//       const response = data.data;
//       navigate(`/operators/details/${response.id}`);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoader(false);
//     }
//   };

//   const handleDOB = (e) => {
//     // Allow only numbers and restrict the length to 10
//     let dobValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
//     if (dobValue.length > 10) {
//       dobValue = dobValue.substring(0, 10); // Limit to 10 digits
//     }

//     setOperatorDetail({
//       ...operatorDetail,
//       dob: dobValue,
//     });

//     setUpdateOperator({
//       ...updateOperator,
//       "dob": dobValue
//     })
//   };

//   if (loader) {
//     return <Loader />;
//   } else {
//     return (
//       <>
//         <div id="update-operator" className="mb-20">
//           <div class="flex text-xs">

//             <p class="inline py-5 pl-5 text-xs font-medium text-slate-700 dark:text-slate-200">
//               Update Operator
//             </p>
//           </div>

//           <div class="flex flex-col gap-6 md:flex-row ">
//             <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
//               <div class="p-2 text-xs">
//                 <div class="mt-4">
//                   <label class="">Name</label>
//                   <input
//                     type="text"
//                     value={operatorDetail?.name}
//                     onChange={(e) => {
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         name: e.target.value,
//                       })
//                       setUpdateOperator({
//                         ...updateOperator,
//                         "name": e.target.value,
//                       })
//                     }
//                     }
//                     placeholder="Op Name(eg: Me X)"
//                     className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div>

//                 <div class="mt-4">
//                   <label class="">Mobile No.</label>
//                   <input
//                     value={operatorDetail?.phone?.phone_no}
//                     onChange={handlePhone}
//                     type="text" // Change type to 'text' to allow for proper manipulation of the value
//                     placeholder="Mobile No."
//                     maxLength={10} // Ensures the input is limited to 10 characters
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
//                   />
//                 </div>
//                 {isPhoneValid !== null && (
//                   <p className="text-[#24a062] font-bold mt-4">
//                     {isPhoneValid}
//                   </p>
//                 )}
//                 <div class="mt-4">
//                   <label>Aadhaar no.</label>
//                   <input
//                     value={operatorDetail?.aadhar_no}
//                     onChange={handleAdhaar}
//                     type="text"
//                     placeholder="Aadhaar no."
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div>
//                 {isAadharValid !== null && (
//                   <p className="text-[#24a062] font-bold mt-4">
//                     {isAadharValid}
//                   </p>
//                 )}
//                 {/* DOB */}
//                 <div class="mt-4">
//                   <label>Date of Birth</label>
//                   <input
//                     value={operatorDetail?.dob}
//                     onChange={handleDOB}
//                     type="date"
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div>

//                 {/* Blood Group */}
//                 {/* <div class="mt-4">
//                   <label class="">Blood Group</label>
//                   <select
//                     onChange={(e) =>
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         blood_group: e.target.value,
//                       })
//                     }
//                     type="text"
//                     placeholder=""
//                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   >
//                     <option>A+</option>
//                     <option>A-</option>
//                     <option>B+</option>
//                     <option>B-</option>
//                     <option>O+</option>
//                     <option>O-</option>
//                     <option>AB+</option>
//                   </select>
//                 </div> */}

//                 {/* Family */}
//                 {/* <div class="mt-4">
//                 <label>Emergency Member Name</label>
//                 <input
//                   value={operatorDetail?.family}
//                   onChange={(e) => setOperatorDetail({ ...operatorDetail, family:e.target.value})}
//                   type="text"
//                  placeholder="name"
//                   className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                 />
//               </div> */}
//                 {/* Emergency Mobile No */}
//                 {/* <div class="mt-4">
//                 <label>Emergency Mobile No</label>
//                 <input
//                   value={operatorDetail?.emergency_mobile}
//                   onChange={(e) => setOperatorDetail({ ...operatorDetail, emergency_mobile:e.target.value})}
//                   type="number"
//                  placeholder="Mobile No"
//                   className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                 />
//               </div> */}
//                 {/* photo */}
//                 {/* <div class="mt-4">
//                   <label class="">Add Profile Photo</label>
//                   <input
//                     onChange={(e) =>
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         photo: e.target.value,
//                       })
//                     }
//                     value={operatorDetail?.photo}
//                     type="file"
//                     placeholder="image"
//                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div> */}
//                 {/* Odoo Employee No */}
//                 <div class="mt-4">
//                   <label class="">Odoo Employee No.</label>
//                   <input
//                     value={operatorDetail?.odoo_employee_no}
//                     onChange={(e) => {
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         odoo_employee_no: e.target.value,
//                       })
//                       setUpdateOperator({
//                         ...updateOperator,
//                         "odoo_employee_no": e.target.value,
//                       })
//                     }
//                     }
//                     type="text"
//                     placeholder="Odoo employee number"
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div>

// {/* <div class="mt-4">
//     <label class="">Working Role</label>
//     <input title="Advanced payments"
//     value={operatorDetail.role || "N/A"}
//       onChange={(e) => {
//         setOperatorDetail({
//           ...operatorDetail,
//           role: e.target.value
//         })
//         setUpdateOperator({
//           ...updateOperator,
//           "role": e.target.value
//         })
//       }
//       }
//       type="text"
//       placeholder="Advanced Payment Information"
//       className="shadow-none focus:outline-none text-blackfocus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
//     />
//   </div> */}
//                        {/* <div class="mt-4">
//                     <label class="">Sales Person</label>
//                     <input title="Advanced payments"
//                     value={operatorDetail.sales_person || "N/A"}
//                       onChange={(e) => {
//                         setOperatorDetail({
//                           ...operatorDetail,
//                           sales_person: e.target.value
//                         })
//                         setUpdateOperator({
//                           ...updateOperator,
//                           "sales_person": e.target.value
//                         })
//                       }
//                       }
//                       type="text"
//                       placeholder="Advanced Payment Information"
//                       className="shadow-none focus:outline-none text-blackfocus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
//                     />
//                   </div> */}
//               </div>
//             </div>
//             <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
//               <div class="p-5 text-xs">
//                 <div class="mt-4">
//                   <label class="">Bank Account No.</label>
//                   <input
//                     value={operatorDetail?.bank_details.account_no}
//                     onChange={(e) => {
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         bank_details: {
//                           ...operatorDetail.bank_details,
//                           account_no: e.target.value,
//                         },
//                       })
//                       setUpdateOperator({
//                         ...updateOperator,
//                         "bank_details": {
//                           "account_no": e.target.value,
//                         }
//                       })
//                     }
//                     }
//                     type="text"
//                     placeholder="Bank account no."
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div>

//                 <div class="mt-4 ">
//                   <label class="">Bank IFSC Code</label>
//                   <input
//                     value={operatorDetail?.bank_details.ifsc_code}
//                     onChange={(e) => {
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         bank_details: {
//                           ...operatorDetail.bank_details,
//                           ifsc_code: e.target.value,
//                         },
//                       })
//                       setUpdateOperator({
//                         ...updateOperator,
//                         "bank_details": {
//                           "ifsc_code": e.target.value,
//                         }
//                       })
//                     }
//                     }
//                     type="text"
//                     placeholder="Bank IFSC Code"
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div>
//                 <div class="mt-4">
//                   <label class="">Pf Account No.</label>
//                   <input
//                     value={operatorDetail?.pf_account_no}
//                     onChange={(e) => {
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         pf_account_no: e.target.value,
//                       })
//                       setUpdateOperator({
//                         ...updateOperator,
//                         "pf_account_no": e.target.value
//                       })
//                     }
//                     }
//                     type="text"
//                     placeholder="PF Account No."
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div>
//                 <div class="mt-4">
//                     <label class="">Advanced Payment Information</label>
//                     <input title="Advanced payments"
//                     value={operatorDetail.advance_paid_amount || "N/A"}
//                       onChange={(e) => {
//                         setOperatorDetail({
//                           ...operatorDetail,
//                           advance_paid_amount: e.target.value
//                         })
//                         setUpdateOperator({
//                           ...updateOperator,
//                           "advance_paid_amount": e.target.value
//                         })
//                       }
//                       }
//                       type="text"
//                       placeholder="Advanced Payment Information"
//                       className="shadow-none focus:outline-none text-blackfocus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
//                     />
//                   </div>
//               </div>
//             </div>
//             <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
//               <div class="p-5 text-sm">
//                 <div class="mt-0">
//                   <label>Date of Joining</label>
//                   <input
//                     type="date"
//                     id="dateInput"
//                     value={formatDateForInput(
//                       operatorDetail?.date_list[0]?.joining_date
//                     )}
//                     onChange={(e) => {
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         joining_date: e.target.value
//                       })
//                       setUpdateOperator({
//                         ...updateOperator,
//                         "joining_date": e.target.value
//                       })
//                     }
//                     }
//                     placeholder="07-03-2021"
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />
//                 </div>

//                 <div class="mt-4">
//                   <label class="">Leaving Date</label>
//                   <input
//                     value={formatDateForInput(
//                       operatorDetail?.date_list[0]?.leaving_date
//                     )}
//                     onChange={(e) => {
//                       setOperatorDetail({
//                         ...operatorDetail,
//                         leaving_date: e.target.value
//                       })
//                       setUpdateOperator({
//                         ...updateOperator,
//                         "leaving_date": e.target.value
//                       })
//                     }
//                     }
//                     type="date"
//                     placeholder="PF Account No."
//                     className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
//                   />

//                   <Wallet operatorDetail={operatorDetail} setOperatorDetail={setOperatorDetail} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div class="flex mt-5 border bg-white fixed bottom-0 left-0 right-0 pr-6 h-12 items-center justify-end text-xs gap-3 ">
//           <button
//             onClick={() => navigate(`/operators/details/${id}`)}
//             className="  shadow-md text-black bg-white border px-3 text-xs h-7 w-[96px] rounded-[2px]"
//           >
//             Back
//           </button>
//           <button
//             onClick={handleSubmit}
//             className=" shadow-md text-white bg-green-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition"
//           >
//             Save
//           </button>
//         </div>
//       </>
//     );
//   }
// }

// export default UpdateOperator;
//updateOperator

import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
// import { LoaderContext } from "../../Context/LoaderContext";
import Loader from "../Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Wallet from "./Wallet";
import { toast } from "react-toastify";
import ViewWallet from "./ViewWallet";
import moment from "moment";
import { formatDate, formatDateForInput, formatDateInput } from "../../customFunctions/FormatDate";

function UpdateOperator() {
  const location = useLocation();
  // const isRejoining = location.state?.rejoining || false;
  const isRejoining = location?.search || false;
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(null);
  const [isAadharValid, setIsAadharValid] = useState(null);
  const [updateOperator, setUpdateOperator] = useState({});
  const [uploadPhoto, setUploadPhoto] = useState({
    types: 'operator',
    operator_id: id,
    photo: null,
  });

  const [operatorDetail, setOperatorDetail] = useState({
    aadhar_no: "",
    advance_paid_amount: "",
    dob: "", //added Avijit
    blood_group: "", // avijit
    family: "", // avijit
    photo: "", //avijit
    emergency_mobile: "", //avijit,
    operator_sale_person: "",
    net_inhand_salary: "",
    operator_role: "",
    bank_details: {
      account_no: "",
      id: "",
      ifsc_code: "",
    },
    id: "",
    date_list: {
      joining_date: "",
      leaving_date: "",
    },
    name: "",

    odoo_employee_no: "",
    pf_account_no: "",
    phone: {
      id: "",
      phone_no: "",
      phone_code: "+91",
    },
    lease_RSO_no: "",
    asset_no: "",
    wallet_arr: [],
    operator_sale_person_id: ''
  });

  const getOperatorDetail = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${setBaseUrl}/operator/get_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      // console.log(data);
      setOperatorDetail(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  // const handlePhone = (e) => {
  //   // Allow only numbers and restrict the length to 10
  //   let phoneValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
  //   if (phoneValue.length > 10) {
  //     phoneValue = phoneValue.substring(0, 10); // Limit to 10 digits
  //   }

  //   setOperatorDetail({
  //     ...operatorDetail,
  //     phone: {
  //       ...operatorDetail.phone,
  //       phone_no: phoneValue,
  //     },
  //   });

  //   setUpdateOperator({
  //     ...updateOperator,
  //     phone: {
  //       phone_no: phoneValue,
  //       phone_code: "+91",
  //     },
  //   });

  //   // Validate phone number length
  //   let phoneDigitCount = phoneValue.length;
  //   if (phoneDigitCount === 10) {
  //     setIsPhoneValid(null); // Valid phone number
  //   } else {
  //     setIsPhoneValid(
  //       "Phone number must contain 10 digits. You gave: " + phoneDigitCount
  //     );
  //   }
  // };



  console.log(uploadPhoto)
  const [imageLoader, setImageLoader] = useState(false)
  const UpdateImageFunction = async () => {

    const formData = new FormData();
    formData.append("photo", uploadPhoto.photo); // Append file properly
    formData.append("types", uploadPhoto.types);
    formData.append("operator_id", uploadPhoto.operator_id);
    setImageLoader(true)

    if(formData.photo === null) return;

    try {
      const res = await fetch(`${setBaseUrl}/operator/upload-file`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: formData, // Send FormData, NOT JSON

      })

      const result = await res.json();
      if (res.ok) {
        toast.success(`Upload successful`);
          window.location.reload();
      } else {
        toast.error(`Upload failed something went wrong!`);
      }
    } catch (error) {
      toast.error("Error uploading file:", error);
      alert("Failed to upload file.");
    }
  }


  const handlePhone = (e) => {
    let phoneValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

    // Ensure input does not exceed 10 characters
    if (phoneValue.length > 10) {
      phoneValue = phoneValue.slice(0, 10);
    }

    setOperatorDetail((prev) => ({
      ...prev,
      phone: {
        ...(prev.phone || {}),
        phone_no: phoneValue,
      },
    }));

    setUpdateOperator((prev) => ({
      ...prev,
      phone: {
        ...(prev.phone || {}),
        phone_no: phoneValue,
        phone_code: "+91",
      },
    }));

    setIsPhoneValid(phoneValue.length === 10 ? null : `Phone number must contain 10 digits. You gave: ${phoneValue.length}`);
  };

  // Prevent typing non-numeric characters and limit to 10 characters
  const preventNonNumeric = (e) => {
    const input = e.target.value;

    // Allow only numbers, backspace, delete, and arrow keys
    if (!/^[0-9]$/.test(e.key) && !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)) {
      e.preventDefault();
    }

    // Prevent entering more than 10 characters
    if (input.length >= 10 && !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)) {
      e.preventDefault();
    }
  };

  // Prevent pasting non-numeric content and limit to 10 digits
  const preventPasteNonNumeric = (e) => {
    let pastedData = e.clipboardData.getData("text").replace(/\D/g, ""); // Remove non-numeric characters

    // If pasted data is longer than 10 digits, trim it
    if (pastedData.length > 10) {
      e.preventDefault();
      e.target.value = pastedData.slice(0, 10);
    } else if (!/^\d+$/.test(pastedData)) {
      e.preventDefault();
    }
  };


  // const handleAdhaar = (e) => {
  //   setOperatorDetail({
  //     ...operatorDetail,
  //     aadhar_no: e.target.value,
  //   });

  //   setUpdateOperator({
  //     ...updateOperator,
  //     aadhar_no: e.target.value,
  //   });

  //   let aadhar_no_value = e.target.value;
  //   let aadhar_no_value_length = aadhar_no_value.length;
  //   var expr =
  //     /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
  //   if (expr.test(aadhar_no_value)) {
  //     setIsAadharValid(null);
  //   } else {
  //     setIsAadharValid(
  //       "Adhaar number must contain 12 digits. You gave>> " +
  //       aadhar_no_value_length
  //     );
  //   }
  // };


  const handleAdhaar = (e) => {
    let aadharValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

    // Format Aadhaar as XXXX-XXXX-XXXX
    if (aadharValue.length > 4 && aadharValue.length <= 8) {
      aadharValue = `${aadharValue.slice(0, 4)}-${aadharValue.slice(4)}`;
    } else if (aadharValue.length > 8) {
      aadharValue = `${aadharValue.slice(0, 4)}-${aadharValue.slice(4, 8)}-${aadharValue.slice(8, 12)}`;
    }

    setOperatorDetail((prev) => ({
      ...prev,
      aadhar_no: aadharValue,
    }));

    setUpdateOperator((prev) => ({
      ...prev,
      aadhar_no: aadharValue,
    }));

    // Aadhaar number validation
    let isValidAadhaar = /^[0-9]{4}-[0-9]{4}-[0-9]{4}$/.test(aadharValue);
    setIsAadharValid(isValidAadhaar ? null : `Aadhaar number must be 12 digits. You gave ${aadharValue.replace(/\D/g, "").length}`);
  };


  const preventNonNumericAdhaar = (e) => {
    const input = e.target.value.replace(/\D/g, "").length; // Remove hyphens while counting

    if (!/^[0-9]$/.test(e.key) && !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)) {
      e.preventDefault();
    }

    // Prevent entering more than 12 digits (excluding hyphens)
    if (input >= 12 && !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const preventPasteNonNumericAdhaar = (e) => {
    let pastedData = e.clipboardData.getData("text").replace(/\D/g, ""); // Remove non-numeric characters

    // If pasted data is longer than 12 digits, trim it
    if (pastedData.length > 12) {
      e.preventDefault();
      e.target.value = `${pastedData.slice(0, 4)}-${pastedData.slice(4, 8)}-${pastedData.slice(8, 12)}`;
    } else if (!/^\d+$/.test(pastedData)) {
      e.preventDefault();
    }
  };


  useEffect(() => {
    getOperatorDetail();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if(!uploadPhoto.photo === null) {
     
      UpdateImageFunction()
    } 

    if (isPhoneValid !== null) {
      alert("Please enter a valid phone number.");
      setLoader(false);
      return;
    }
    if (isAadharValid !== null) {
      alert("Please enter a valid Aadhaar number.");
      setLoader(false);
      return;
    }

    const submittedData = { ...updateOperator, id: id };
    // console.log(submittedData);
    try {
      setUpdateLoader(true);
      const { data } = await axios.put(
        `${setBaseUrl}/operator/update`,
        submittedData,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      const response = data.data;
      toast.success("Operator updated successfully.");
      navigate(`/operators/details/${id}`);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update operator.");
    } finally {
      setUpdateLoader(false);
      setLoader(false);
    }
  };

  const handleDOB = (e) => {
    // Allow only numbers and restrict the length to 10
    let dobValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (dobValue.length > 10) {
      dobValue = dobValue.substring(0, 10); // Limit to 10 digits
    }

    setOperatorDetail({
      ...operatorDetail,
      dob: dobValue,
    });

    setUpdateOperator({
      ...updateOperator,
      dob: dobValue,
    });
  };

  const [salesPerson, setSalesPerson] = useState(null);

  const getSalesPerson = async () => {
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-sale-persons`, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      const data = await res.json();
      setSalesPerson(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSalesPerson();
  }, []);

  // console.log(updateOperator)

  // const handleDateChange = (type, value) => {
  //   const updatedDateList = [...operatorDetail.date_list];
  //   const lastIndex = updatedDateList.length - 1;

  //   if (type === "leaving_date") {
  //     // 1. Ensure leaving date is not earlier than joining date
  //     if (new Date(value) < new Date(updatedDateList[lastIndex].joining_date)) {
  //       toast.error("Leaving date cannot be earlier than the joining date.");
  //       return;
  //     }

  //     // 2. Ensure leaving date is not in the future
  //     if (new Date(value) > new Date()) {
  //       toast.error("Leaving date cannot be greater than today.");
  //       return;
  //     }

  //     // 3. Convert leaving date to correct format before updating state
  //     const formattedLeavingDate = formatDateForSubmission(value);

  //     // 4. If the last entry has no leaving date, update it
  //     if (!updatedDateList[lastIndex].leaving_date) {
  //       updatedDateList[lastIndex].leaving_date = formattedLeavingDate;
  //     } else {
  //       // 5. Ensure the new record is only added when the previous entry is complete
  //       if (
  //         updatedDateList[lastIndex].joining_date &&
  //         updatedDateList[lastIndex].leaving_date
  //       ) {
  //         updatedDateList.push({
  //           joining_date: "",
  //           leaving_date: "",
  //           operator_date_log_id: crypto.randomUUID(),
  //         });
  //       }
  //     }
  //   }

  //   if (type === "joining_date") {
  //     // 6. Ensure new joining date is after the last recorded leaving date
  //     if (
  //       updatedDateList.length > 1 &&
  //       updatedDateList[lastIndex - 1].leaving_date &&
  //       new Date(value) <= new Date(updatedDateList[lastIndex - 1].leaving_date)
  //     ) {
  //       toast.error(
  //         "New joining date must be greater than the previous leaving date."
  //       );
  //       return;
  //     }

  //     updatedDateList[lastIndex].joining_date = formatDateForSubmission(value);
  //   }

  //   setOperatorDetail({ ...operatorDetail, date_list: updatedDateList });
  //   setUpdateOperator({ ...updateOperator, date_list: updatedDateList });
  // };
  // const handleDateChange = (type, value) => {
  //   let updatedDateList = [...(operatorDetail.date_list || [])]; // Ensure date_list is an array
  //   let lastIndex = updatedDateList.length - 1;

  //   // If date_list is empty, initialize it with a new entry
  //   if (updatedDateList.length === 0) {
  //     updatedDateList.push({
  //       joining_date: type === "joining_date" ? formatDateForSubmission(value) : "",
  //       leaving_date: type === "leaving_date" ? formatDateForSubmission(value) : "",

  //     });
  //     lastIndex = 0; // Update lastIndex to reflect new entry
  //   }

  //   if (type === "leaving_date") {
  //     if (new Date(value) < new Date(updatedDateList[lastIndex].joining_date)) {
  //       toast.error("Leaving date cannot be earlier than the joining date.");
  //       return;
  //     }

  //     if (new Date(value) > new Date()) {
  //       toast.error("Leaving date cannot be greater than today.");
  //       return;
  //     }

  //     updatedDateList[lastIndex].leaving_date = formatDateForSubmission(value);
  //   }

  //   if (type === "joining_date") {
  //     if (
  //       lastIndex > 0 &&
  //       updatedDateList[lastIndex - 1].leaving_date &&
  //       new Date(value) <= new Date(updatedDateList[lastIndex - 1].leaving_date)
  //     ) {
  //       toast.error(
  //         "New joining date must be greater than the previous leaving date."
  //       );
  //       return;
  //     }

  //     updatedDateList[lastIndex].joining_date = formatDateForSubmission(value);
  //   }

  //   // Update state with a new reference to trigger re-render
  //   setOperatorDetail({ ...operatorDetail, date_list: [...updatedDateList] });
  //   setUpdateOperator({ ...updateOperator, date_list: [...updatedDateList] });
  // };

  // const handleDateChange = (type, value) => {
  //   let updatedDateList = {...(operatorDetail.date_list || [])}; // Ensure date_list is an array
  //   let lastIndex = updatedDateList.length - 1;

  //   // If date_list is empty, initialize it with a new entry
  //   if (updatedDateList?.length === 0) {
  //     updatedDateList?.push({
  //       joining_date: type === "joining_date" ? formatDateForSubmission(value) : "",
  //       leaving_date: type === "leaving_date" ? formatDateForSubmission(value) : "",
  //       // operator_date_log_id: crypto.randomUUID(), // Generate ID if not present
  //     });
  //     lastIndex = 0; // Update lastIndex to reflect new entry
  //   }

  //   if (type === "leaving_date") {
  //     if (new Date(value) < new Date(updatedDateList[lastIndex]?.joining_date)) {
  //       toast.error("Leaving date cannot be earlier than the joining date.");
  //       return;
  //     }

  //     if (new Date(value) > new Date()) {
  //       toast.error("Leaving date cannot be greater than today.");
  //       return;
  //     }

  //     // Ensure the correct ID is preserved
  //     const operatorLogId = updatedDateList[lastIndex]?.operator_date_log_id;

  //     // Update leaving date while keeping ID and old joining date
  //     updatedDateList[lastIndex] = {
  //       ...updatedDateList[lastIndex],
  //       leaving_date: formatDateForSubmission(value),
  //       operator_date_log_id: operatorLogId, // Retain the existing ID
  //     };

  //     // Send the required payload (you can pass this data to an API call)
  //     const payload = {
  //       operator_date_log_id: operatorLogId,
  //       joining_date: updatedDateList[lastIndex]?.joining_date, // Old joining date
  //       leaving_date: formatDateForSubmission(value), // New leaving date
  //     };

  //     // console.log("Updating record:", payload);
  //     // API CALL: send payload to backend if required
  //   }

  //   if (type === "joining_date") {
  //     if (
  //       lastIndex > 0 &&
  //       updatedDateList[lastIndex - 1].leaving_date &&
  //       new Date(value) <= new Date(updatedDateList[lastIndex - 1]?.leaving_date)
  //     ) {
  //       toast.error(
  //         "New joining date must be greater than the previous leaving date."
  //       );
  //       return;
  //     }

  //     updatedDateList[lastIndex].joining_date = formatDateForSubmission(value);
  //   }

  //   // Update state with a new reference to trigger re-render
  //   setOperatorDetail({ ...operatorDetail, date_list: {...updatedDateList} });
  //   setUpdateOperator({ ...updateOperator, date_list: {...updatedDateList} });
  // };

  // const handleDateChange = (type, value) => {
  //   let updatedDateList = [...(operatorDetail.date_list || [])];
  //   let lastIndex = updatedDateList.length - 1;

  //   if (updatedDateList.length === 0) {
  //     toast.error("No previous joining date found.");
  //     return;
  //   }

  //   let lastEntry = { ...updatedDateList[lastIndex] }; // Get the last entry

  //   if (type === "joining_date") {
  //     if (
  //       lastIndex > 0 &&
  //       updatedDateList[lastIndex - 1].leaving_date &&
  //       new Date(value) <= new Date(updatedDateList[lastIndex - 1].leaving_date)
  //     ) {
  //       toast.error("New joining date must be after the previous leaving date.");
  //       return;
  //     }

  //     lastEntry.joining_date = value;
  //   }

  //   if (type === "leaving_date") {
  //     if (!lastEntry.joining_date) {
  //       toast.error("Please set a joining date first.");
  //       return;
  //     }

  //     if (new Date(value) < new Date(lastEntry.joining_date)) {
  //       toast.error("Leaving date cannot be before the joining date.");
  //       return;
  //     }

  //     if (new Date(value) > new Date()) {
  //       toast.error("Leaving date cannot be in the future.");
  //       return;
  //     }

  //     lastEntry.leaving_date = value;
  //   }

  //   updatedDateList[lastIndex] = lastEntry; // Update the last entry

  //   // Prepare the object for updating
  //   const updatePayload = {
  //     id: operatorDetail.id,
  //     operator_date_log_id: lastEntry.operator_date_log_id,
  //     // joining_date: lastEntry.joining_date,
  //     leaving_date: lastEntry.leaving_date,
  //   };

  //   console.log("Sending update payload:", updatePayload); // Debugging

  //   // Update state
  //   setOperatorDetail({ ...operatorDetail, date_list: [...updatedDateList] });
  //   setUpdateOperator( updatePayload );
  // };

  // const handleDateChange = (type, value) => {
  //   let updatedDateList = [...(operatorDetail.date_list || [])];
  //   let lastIndex = updatedDateList.length - 1;

  //   if (updatedDateList.length === 0) {
  //     toast.error("No previous joining date found.");
  //     return;
  //   }

  //   let lastEntry = { ...updatedDateList[lastIndex] }; // Get the last entry

  //   if (type === "joining_date") {
  //     if (
  //       lastIndex > 0 &&
  //       updatedDateList[lastIndex - 1].leaving_date &&
  //       new Date(value) <= new Date(updatedDateList[lastIndex - 1].leaving_date)
  //     ) {
  //       toast.error("New joining date must be after the previous leaving date.");
  //       return;
  //     }
  //     lastEntry.joining_date = value;
  //   }

  //   if (type === "leaving_date") {
  //     if (!lastEntry.joining_date) {
  //       toast.error("Please set a joining date first.");
  //       return;
  //     }

  //     if (new Date(value) < new Date(lastEntry.joining_date)) {
  //       toast.error("Leaving date cannot be before the joining date.");
  //       return;
  //     }

  //     if (new Date(value) > new Date()) {
  //       toast.error("Leaving date cannot be in the future.");
  //       return;
  //     }

  //     lastEntry.leaving_date = value;
  //   }

  //   updatedDateList[lastIndex] = lastEntry; // Update the last entry

  //   // Prepare the update payload based on conditions
  //   let updatePayload = {};

  //   if (!lastEntry.leaving_date) {
  //     // Condition 1: If leaving date is missing, send only operator_date_log_id and leaving_date
  //     updatePayload = {
  //       operator_date_log_id: lastEntry.operator_date_log_id,
  //       leaving_date: lastEntry.leaving_date,
  //     };
  //   } else if (lastEntry.joining_date && lastEntry.leaving_date) {
  //     // Condition 2: If both joining and leaving dates exist, send only joining & leaving dates
  //     updatePayload = {
  //       joining_date: lastEntry.joining_date,
  //       leaving_date: lastEntry.leaving_date,
  //     };
  //   }

  //   // console.log("Sending update payload:", updatePayload); // Debugging

  //   // Update state
  //   setOperatorDetail({ ...operatorDetail, date_list: [...updatedDateList] });
  //   setUpdateOperator(updatePayload);
  // };

  // console.log(
  //   operatorDetail?.date_list[operatorDetail.date_list?.length - 1]
  //     ?.joining_date
  // );

  // console.log(
  //   operatorDetail?.date_list[operatorDetail?.date_list?.length - 1]
  //     ?.leaving_date
  // );

  const handleDateChange = (type, value) => {
    let updatedDateList = [...(operatorDetail.date_list || [])];
    let lastIndex = updatedDateList.length - 1;

    let lastEntry =
      updatedDateList.length > 0
        ? { ...updatedDateList[updatedDateList.length - 1] }
        : { joining_date: "", leaving_date: "", operator_date_log_id: null };

    // { ...updatedDateList[lastIndex] }; // Get the last entry

    if (type === "joining_date") {
      lastEntry.joining_date = value;
    }
    if (type === "leaving_date") {
      lastEntry.leaving_date = value;
    }

    // updatedDateList[lastIndex] = lastEntry; // Update the last entry
    // If `updatedDateList` was empty, push the new entry
    if (updatedDateList.length === 0) {
      updatedDateList.push(lastEntry);
    } else {
      updatedDateList[updatedDateList.length - 1] = lastEntry;
    }

    // **Prepare update payload based on conditions**
    let updatePayload = {};

    if (isRejoining) {
      if (lastEntry.joining_date) {
        updatePayload["joining_date"] = `${new Date(lastEntry.joining_date).toISOString()}`.split('T')[0]
      }
      if (lastEntry.leaving_date && lastEntry.leaving_date !== lastEntry.leaving_date) {
        updatePayload["leaving_date"] = `${new Date(lastEntry.leaving_date).toISOString()}`.split('T')[0]
      }

      // const lastRecord = operatorDetail?.date_list.length
      //   ? operatorDetail.date_list[operatorDetail.date_list.length - 1]
      //   : null;

      // const lastLeavingDate = lastRecord?.leaving_date
      //   ? new Date(lastRecord.leaving_date)
      //   : null;
      // const newJoiningDate = new Date(lastEntry.joining_date);

      // if (!lastLeavingDate) {
      //   console.error("Rejoining not allowed: Operator has not left yet.");
      //   return;
      // } else if (newJoiningDate <= lastLeavingDate) {
      //   console.error("Rejoining date must be greater than last leaving date.");
      //   return;
      // } else {
      //   if (lastEntry.joining_date) {
      //     updatePayload["joining_date"] = newJoiningDate
      //       .toISOString()
      //       .split("T")[0];
      //   }
      //   if (lastEntry.leaving_date) {
      //     updatePayload["leaving_date"] = new Date(lastEntry.leaving_date)
      //       .toISOString()
      //       .split("T")[0];
      //   }
      // }
    } else if (operatorDetail?.date_list.length === 0) {
      updatePayload = {
        joining_date: lastEntry.joining_date,
        leaving_date: lastEntry.leaving_date,
      };
    } else if (
      operatorDetail.date_list[operatorDetail.date_list.length - 1]
        .joining_date &&
      operatorDetail.date_list[operatorDetail.date_list.length - 1]
        .leaving_date === null
    ) {
      updatePayload = {
        leaving_date: lastEntry.leaving_date,
        operator_date_log_id: lastEntry.operator_date_log_id,
      };
    } else {
      if (lastEntry.joining_date && lastEntry.leaving_date) {
        updatePayload = {
          joining_date: `${new Date(lastEntry.joining_date).toISOString().split("T")[0]
            }`,
          leaving_date: `${new Date(lastEntry.leaving_date).toISOString().split("T")[0]
            }`,
          operator_date_log_id: lastEntry.operator_date_log_id,
        };
      }
    }

    // Update state
    // setOperatorDetail({ ...operatorDetail, date_list: [...updatedDateList] });
    setUpdateOperator(updatePayload);
  };


  const formatAadhar = (number) => {
    let cleaned = number.replace(/\D/g, ""); // Ensure only numbers
    return cleaned
      .replace(/(\d{4})(\d{4})?(\d{4})?/, (match, p1, p2, p3) =>
        [p1, p2, p3].filter(Boolean).join("-")
      );
  };

  const [deleteImage, setDeleteImage] = useState(null)



  const deletePhoto = async () => {
    if (!deleteImage) return;

    try {
      const response = await fetch(`${setBaseUrl}/operator/delete_photo`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ id: deleteImage }), // Ensure correct payload format
      });

      if (response.ok) {
        toast.success("Photo deleted successfully.");

        // Update the operatorDetail state by removing the deleted image
        const updatedFileLinks = operatorDetail.file_Links.filter(
          (file) => file.id !== deleteImage
        );

        setOperatorDetail({ ...operatorDetail, file_Links: updatedFileLinks });
        setDeleteImage(null);
      } else {
        toast.error("Failed to delete photo.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete photo.");
    }
  };

  // console.log(salesPerson)

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <div id="update-operator" className="mb-20">
          <div class="flex text-xs">
            <p class="inline py-5 pl-5 text-xs font-medium text-slate-700 dark:text-slate-200">
              Update Operator
            </p>
          </div>

          <div class="flex flex-col gap-6 md:flex-row ">
            <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
              <div class="p-2 text-xs">
                {/* Profile Image */}
                <div className="mt-4">
                  <label className="text-sm font-medium text-gray-700">Profile Image</label>

                  {operatorDetail?.file_Links?.length > 0 && (
                    <>
                      <img
                        src={`https://${operatorDetail.file_Links[0].file_link}`}
                        alt="Profile"
                        className="h-16 w-16 border rounded-md mt-2 object-cover"
                      />
                      <button
                        className="border border-red-500 text-red-500 px-3 py-1 rounded-md mt-2 hover:bg-red-500 hover:text-white transition"
                        onClick={() => {
                          setDeleteImage(operatorDetail.file_Links[0].id);
                          setUploadPhoto({ photo: null });
                          deletePhoto(); // Call the delete function
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}

                  <input
                    type="file"
                    onChange={(e) => {
                      setUploadPhoto({
                        ...uploadPhoto,
                        photo: e.target.files[0],
                      });
                    }}
                    accept="image/*"
                    className="focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />
                </div>

                {/* Operator Name */}
                <div class="mt-4">
                  <label class="">Name</label>
                  <input
                    type="text"
                    value={operatorDetail?.name}
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        name: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        name: e.target.value,
                      });
                    }}
                    placeholder="Op Name(eg: Me X)"
                    className=" focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div>

                {/* Mobile Number */}
                <div class="mt-4">
                  <label class="">Mobile No.</label>
                  {/* <input
                    min={0}
                    type="text"
                    placeholder="Enter your phone number"
                    maxLength={10}
                    value={operatorDetail.phone?.phone_no || ""}
                    onChange={handlePhone}
                    onKeyDown={preventNonNumeric} // Prevent invalid typing
                    onPaste={preventPasteNonNumeric}  // Ensures the input is limited to 10 characters
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  /> */}
                  <input
                    type="text"
                    placeholder="Enter your phone number"
                    maxLength={10} // Ensures users can't enter more than 10 characters
                    value={operatorDetail?.phone?.phone_no || ""}
                    onChange={handlePhone}
                    onKeyDown={preventNonNumeric} // Prevent invalid typing
                    onPaste={preventPasteNonNumeric} // Prevent invalid pasting
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />

                </div>
                {isPhoneValid !== null && (
                  <p className="text-[#24a062] font-bold mt-4">
                    {isPhoneValid}
                  </p>
                )}

                {/* Driving License */}

                <div class="mt-4">
                  <label class="">Driving License</label>
                  <input
                    type="text"
                    value={operatorDetail?.driving_license}
                    onChange={(e) => {
                      const formattedValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ""); // Converts to uppercase and removes invalid characters
                      setOperatorDetail({
                        ...operatorDetail,
                        driving_license: formattedValue,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        driving_license: formattedValue,
                      });
                    }}
                    placeholder="Edit your driving license number"
                    className="focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />

                </div>
                {/* Adhaar Number */}
                <div class="mt-4">
                  <label>Aadhaar no.</label>
                  {/* <input
                    value={operatorDetail?.aadhar_no}
                    onChange={handleAdhaar}
                    type="text"
                    maxLength={12}
                    placeholder="Aadhaar no."
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  /> */}
                  <input
                    value={operatorDetail?.aadhar_no ? formatAadhar(operatorDetail?.aadhar_no) : ""}
                    onChange={handleAdhaar}
                    type="text"
                    maxLength={14} // Max length is 14 to accommodate hyphens
                    placeholder="XXXX-XXXX-XXXX"
                    onKeyDown={preventNonNumericAdhaar} // Prevent invalid typing
                    onPaste={preventPasteNonNumericAdhaar} // Prevent pasting invalid values
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                {isAadharValid !== null && (
                  <p className="text-[#24a062] font-bold mt-4">
                    {isAadharValid}
                  </p>
                )}
                {/* DOB */}
                <div class="mt-4">
                  <label>Date of Birth</label>
                  <input
                    value={operatorDetail?.dob}
                    onChange={handleDOB}
                    type="date"
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div>
                <div title="Operator Role" className="mt-4">
                  <label class="">Operator Role</label>
                  <select
                    name=""
                    value={operatorDetail.operator_role || "N/A"}
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        operator_role: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        operator_role: e.target.value,
                      });
                    }}
                    placeholder="Operator role"
                    className="shadow-none focus:outline-none text-blackfocus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                    id=""
                  >
                    <option value="">Select role</option>
                    <option value="Operator">Operator</option>
                    <option value="Technician">Technician</option>
                    <option value="Supervisor">Supervisor</option>
                  </select>
                </div>

                <div title="Operator sales person" className="mt-4">
                  <label class="">Operator Sales Person</label>
                  <br /> <span className="text-xs "> Selected Operators: {" "} <strong>{operatorDetail.sale_person || "N/A"}</strong> </span>
                  <select
                    name=""
                    // value={ operatorDetail?.sale_person || '' }
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        operator_sale_person_id: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        operator_sale_person_id: e.target.value,
                      });
                    }}
                    id=""
                    placeholder="Operator Sales Person"
                    className="shadow-none focus:outline-none text-blackfocus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  >
                    <option value="">Select</option>
                    {salesPerson?.map((sale) => (
                      <option value={sale.id} key={sale.id}>
                        {sale.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Blood Group */}
                {/* <div class="mt-4">
                  <label class="">Blood Group</label>
                  <select
                    onChange={(e) =>
                      setOperatorDetail({
                        ...operatorDetail,
                        blood_group: e.target.value,
                      })
                    }
                    type="text"
                    placeholder=""
                   className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  >
                    <option>A+</option>
                    <option>A-</option>
                    <option>B+</option>
                    <option>B-</option>
                    <option>O+</option>
                    <option>O-</option>
                    <option>AB+</option>
                  </select>
                </div> */}

                {/* Family */}
                {/* <div class="mt-4">
                <label>Emergency Member Name</label>
                <input
                  value={operatorDetail?.family}
                  onChange={(e) => setOperatorDetail({ ...operatorDetail, family:e.target.value})}
                  type="text"
                 placeholder="name"
                  className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                />
              </div> */}
                {/* Emergency Mobile No */}
                {/* <div class="mt-4">
                <label>Emergency Mobile No</label>
                <input
                  value={operatorDetail?.emergency_mobile}
                  onChange={(e) => setOperatorDetail({ ...operatorDetail, emergency_mobile:e.target.value})}
                  type="number"
                 placeholder="Mobile No"
                  className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                />
              </div> */}
                {/* photo */}
                {/* <div class="mt-4">
                  <label class="">Add Profile Photo</label>
                  <input
                    onChange={(e) =>
                      setOperatorDetail({
                        ...operatorDetail,
                        photo: e.target.value,
                      })
                    }
                    value={operatorDetail?.photo}
                    type="file"
                    placeholder="image"
                   className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div> */}
                {/* Odoo Employee No */}
                <div class="mt-4">
                  <label class="">Odoo Employee No.</label>
                  <input
                    value={operatorDetail?.odoo_employee_no}
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        odoo_employee_no: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        odoo_employee_no: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Odoo employee number"
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div>
              </div>
            </div>
            <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
              <div class="p-5 text-xs">
                <div title="Bank account number" class="mt-4">
                  <label class="">Bank Account Number</label>
                  <input
                    value={operatorDetail?.bank_details?.account_no || ""}
                    onChange={(e) => {
                      const updatedValue = e.target.value;

                      setOperatorDetail((prev) => ({
                        ...prev,
                        bank_details: {
                          ...prev.bank_details,
                          account_no: updatedValue,
                        },
                      }));

                      setUpdateOperator((prev) => ({
                        ...prev,
                        bank_details: {
                          ...prev.bank_details, // Preserve existing bank details
                          account_no: updatedValue,
                        },
                      }));
                    }}
                    type="text"
                    placeholder="Bank account no."
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div>

                <div title="Bank isfc code" class="mt-4 ">
                  <label class="">Bank IFSC Code</label>
                  <input
                    value={operatorDetail?.bank_details?.ifsc_code || ""}
                    onChange={(e) => {
                      const updatedValue = e.target.value;

                      setOperatorDetail((prev) => ({
                        ...prev,
                        bank_details: {
                          ...prev.bank_details,
                          ifsc_code: updatedValue,
                        },
                      }));

                      setUpdateOperator((prev) => ({
                        ...prev,
                        bank_details: {
                          ...prev.bank_details, // Preserve existing bank details
                          ifsc_code: updatedValue,
                        },
                      }));
                    }}
                    type="text"
                    placeholder="Bank IFSC Code"
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div>

                <div title="pf account number of the operator " class="mt-4">
                  <label class="">Pf Account No.</label>
                  <input
                    value={operatorDetail?.pf_account_no}
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        pf_account_no: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        pf_account_no: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="PF Account No."
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div>
                {/* net_inhand_salary */}
                <div class="mt-4">
                  <label class="">Net Inhand Salary</label>
                  <input
                    title="Net Inhand Salary"
                    min={0}
                    maxLength={25}
                    value={operatorDetail.net_inhand_salary || "N/A"}
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        net_inhand_salary: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        net_inhand_salary: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Net Inhand Salary"
                    className="shadow-none focus:outline-none text-blackfocus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div>
                <div class="mt-4">
                  <label class="">Advanced Payment Information</label>
                  <input

                    title="Advanced payments"
                    value={operatorDetail.advance_paid_amount || ""}
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        advance_paid_amount: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        advance_paid_amount: e.target.value,
                      });
                    }}
                    min={0}
                    maxLength={25}
                    type="number"
                    placeholder="Advanced Payment Information"
                    className="shadow-none focus:outline-none text-blackfocus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs"
                  />
                </div>

                {operatorDetail?.wallet_arr && (
                  <ViewWallet
                    operatorDetail={operatorDetail}
                    setOperatorDetail={setOperatorDetail}
                  />
                )}

                <Wallet
                  operatorDetail={operatorDetail}
                  setOperatorDetail={setOperatorDetail}
                />
              </div>
            </div>
            <div class="intro-y box basis-1/3 bg-white border p-4 dark:bg-slate-900 rounded-lg mx-2">
              <div className="bg-white p-4 h-[300px] overflow-y-auto flex flex-col gap-2 border dark:bg-gray-900">
                <span className="text-xs font-bold mb-2 text-gray-700 dark:text-gray-300">
                  Working History
                </span>
                <table className="w-full text-xs ">
                  <thead className="border-b">
                    <tr className="">
                      <th className="py-2 font-medium text-gray-500">Joined</th>
                      <th className="py-2 font-medium text-gray-500">Left</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(operatorDetail)} */}
                    {operatorDetail?.date_list?.length > 0 ? (
                      operatorDetail?.date_list?.map((date, index) => {
                        return (
                          <tr key={index} className=" text-center">
                            <td className="p-2 border-b ">
                              {date?.joining_date
                                ? moment(date.joining_date).format("DD-MM-YYYY")
                                : "--"}
                            </td>
                            <td className="p-2 border-b ">
                              {date?.leaving_date
                                ? formatDate(date.leaving_date)
                                : "--"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="items-center flex justify-center">
                        no available data
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <div class="p-5 text-sm">
                <div class="mt-0">
                  <label>Date of Joining</label>
                  <input
                    type="date"
                    id="dateInput"
                    value={
                      operatorDetail?.["date_list"][0].joining_date
                        ? formatDateForInput(operatorDetail?.["date_list"][0].joining_date)
                        : ''
                    }
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        joining_date: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        joining_date: e.target.value,
                      });
                    }}
                    placeholder="07-03-2021"
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div>

                <div class="mt-4">
                  <label class="">Leaving Date</label>
                  <input
                    v value={
                      operatorDetail?.["date_list"][0].leaving_date
                        ? formatDateForInput(operatorDetail?.["date_list"][0].leaving_date)
                        : ''
                    }
                    onChange={(e) => {
                      setOperatorDetail({
                        ...operatorDetail,
                        leaving_date: e.target.value,
                      });
                      setUpdateOperator({
                        ...updateOperator,
                        leaving_date: e.target.value,
                      });
                    }}
                    type="date"
                    placeholder="PF Account No."
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs  shadow-none"
                  />
                </div>

             
              </div> */}

              {/* <div className="p-5 text-sm">
                <div className="mt-0">
                  <label>Date of Joining</label>
                  <input
                    type="date"
                    value={
                      operatorDetail?.date_list.length  && !isRejoining
                        ? formatDateForInput(
                            operatorDetail.date_list[
                              operatorDetail.date_list.length - 1
                            ].joining_date
                          )
                        : updateOperator.joining_date
                    }
                    onChange={(e) =>
                      handleDateChange("joining_date", e.target.value)
                    }
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />
                </div>

                <div className="mt-4">
                  <label>Leaving Date</label>
                  <input
                    type="date"
                    value={
                      operatorDetail?.date_list.length  && !isRejoining
                        ? formatDateForInput(
                            operatorDetail.date_list[
                              operatorDetail.date_list.length - 1
                            ].leaving_date
                          )
                        : updateOperator.leaving_date
                    }
                    onChange={(e) =>
                      handleDateChange("leaving_date", e.target.value)
                    }
                    min={
                      operatorDetail?.date_list.length
                        ? formatDateForInput(
                            operatorDetail.date_list[
                              operatorDetail.date_list.length - 1
                            ].joining_date
                          )
                        : ""
                    } // Disables selecting a date earlier than joining date
                    max={formatDateForInput(new Date())}
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
              </div> */}
              <div className="p-5 text-sm">
                <div className="mt-0">
                  <label>Date of Joining</label>
                  <input
                    type="date"
                    value={
                      operatorDetail?.date_list.length && !isRejoining
                        ? formatDateForInput(
                          operatorDetail.date_list[
                            operatorDetail.date_list.length - 1
                          ].joining_date
                        )
                        : updateOperator.joining_date
                    }
                    onChange={(e) =>
                      handleDateChange("joining_date", e.target.value)
                    }
                    min={
                      operatorDetail?.date_list.length
                        ? formatDateForInput(
                          operatorDetail.date_list[
                            operatorDetail.date_list.length - 1
                          ].leaving_date
                        )
                        : ""
                    } // Ensures rejoining date is after last leaving date
                    disabled={
                      operatorDetail?.date_list.length &&
                      operatorDetail.date_list[
                        operatorDetail.date_list.length - 1
                      ].leaving_date === null
                    } // Disables if operator hasn't left yet
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />
                </div>

                <div className="mt-4">
                  <label>Leaving Date</label>

                  <input
                    type="date"
                    value={
                      operatorDetail?.date_list.length && !isRejoining
                        ? formatDateForInput(
                          operatorDetail.date_list[
                            operatorDetail.date_list.length - 1
                          ].leaving_date
                        )
                        : updateOperator.leaving_date
                    }
                    onChange={(e) =>
                      handleDateChange("leaving_date", e.target.value)
                    }
                    min={
                      operatorDetail?.date_list.length
                        ? formatDateForInput(
                          operatorDetail.date_list[
                            operatorDetail.date_list.length - 1
                          ].joining_date
                        )
                        : ""
                    } // Disables selecting a date earlier than joining date
                    max={formatDateForInput(new Date())}
                    className="text-black focus:outline-none focus:ring-0 focus:border-none w-full border-none mt-2 p-2 bg-light-4 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-5 border bg-white fixed bottom-0 left-0 right-0 pr-6 h-12 items-center justify-end text-xs gap-3 ">
          <button
            title="Return to operator details"
            onClick={() => navigate(`/operators/details/${id}`)}
            className="  shadow-md text-black bg-white border px-3 text-xs h-7 w-[96px] rounded-[2px]"
          >
            Back
          </button>
          <button
            title="Click and save"
            onClick={handleSubmit}
            className=" shadow-md text-white bg-green-2 px-3 text-xs h-7 w-[96px] rounded-[2px] transition"
          >
            {updateLoader ? <Loader /> : "Save "}
          </button>
        </div>
      </>
    );
  }
}

export default UpdateOperator;
