import React from 'react'
import { MapContainer, TileLayer } from "react-leaflet";
import LocationMarker from './LocationMarker';

const DeviceMap = ({mapPosition, setMapPosition}) => {
  return (
    <div className=''>
        <MapContainer
        center={mapPosition}
        zoom={13}
        scrollWheelZoom={true}
        style={{height: "500px", width: '30vw', zIndex:0, objectFit:'cover' }}
        >

        <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
        <LocationMarker 
        position={mapPosition}
        setPosition={setMapPosition}
        />
        </MapContainer>
    </div>
  )
}

export default DeviceMap



// import React, { useEffect, useRef, useState } from 'react';
// import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';


// const DeviceMap = ({ mapPosition, setMapPosition }) => {
//   const mapContainerRef = useRef(null); // Reference for the map container
//   const [currentLocation, setCurrentLocation] = useState(mapPosition); // Default position
//   const mapboxAccessToken = process.env.REACT_APP_MAP_BOX_TOKEN; // Replace with your Mapbox token

//   console.log(mapPosition);
//   // Get the current device location using the Geolocation API
//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.watchPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           console.log("latitude", latitude);
//           console.log("longitude", longitude);
//           setCurrentLocation([longitude, latitude]); // Update the current location state
//           setMapPosition([longitude, latitude]); // Update the parent state too
//         },
//         (error) => {
//           console.error("Error getting location: ", error);
//         },
//         { enableHighAccuracy: true, maximumAge: 10000, timeout: 5000 } // Adjust settings for more accurate position
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//     }
//   }, []); // Run once when the component is mounted

//   // Initialize and update the map whenever the location changes
//   useEffect(() => {
//     if (mapContainerRef.current && mapPosition) {
//       mapboxgl.accessToken = mapboxAccessToken;

//       const map = new mapboxgl.Map({
//         container: mapContainerRef.current,
//         style: 'mapbox://styles/mapbox/streets-v11', // You can choose a different Mapbox style
//         center: mapPosition, // Use the current location as the map center
//         zoom: 13,
//       });

//       // Add a marker for the current position
//       const marker = new mapboxgl.Marker()
//         .setLngLat(mapPosition)
//         .addTo(map)
//         .setDraggable(true); // Make the marker draggable

//       // Update the map position when dragging the marker
//       marker.on('dragend', () => {
//         const newPos = marker.getLngLat();
//         setMapPosition([newPos.lng, newPos.lat]);
//       });

//       // Clean up the map on component unmount
//       return () => {
//         map.remove();
//       };
//     }
//   }, [mapPosition, setMapPosition, mapboxAccessToken]); // Re-run when mapPosition changes

//   return (
//     <div style={{ height: '250px', width: '78vh' }}>
//       {/* Map container */}
//       <div ref={mapContainerRef} style={{ height: '100%', width: '100%' }} />
//     </div>
//   );
// };

// export default DeviceMap;
