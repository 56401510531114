import React, { useState } from "react";
import { toast } from "react-toastify";
import { setBaseUrl } from "../../config";
import { CircularProgress } from "@mui/material";
import { useToast } from "../../Context/ToastContext";

const InstalledPartForm = ({ inventory, id }) => {
  const [installedPart, setInstalledPart] = useState({
    part_id: "",
    quantity: 0,
    maintenance_id: id,
  });
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("sales_token");
  const { addToast } = useToast()

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    if (name === "quantity") {
      setInstalledPart((prev) => ({ ...prev, [name]: parseInt(value) }));
    } else {

      setInstalledPart((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!installedPart.part_id || !installedPart.quantity) {
      return toast.error("Please fill all fields");
    }

    let Submitted =  installedPart
    
    Submitted["quantity"] = parseInt(Submitted["quantity"] )
    Submitted = [Submitted]

    

    setLoading(true);
    try {
      const response = await fetch(`${setBaseUrl}/sale-service/install-part`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(Submitted),
      });

      setLoading(false);
      if (response.status === 200) {
        addToast("Part installed successfully", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      } else {
        addToast("Error in installing part", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      addToast("Error in installing part! Please try again", "error");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto p-4 border bg-white border-gray-300 rounded-lg shadow-md">
      <h3 className="text-sm font-semibold text-gray-800 mb-4">Installed Part</h3>

      <div className="mb-4">
        <label htmlFor="part_id" className="block text-xs mb-1">Select Part Number</label>
        <select
          id="part_id"
          name="part_id"
          value={installedPart.part_id}
          onChange={handleChange}
          className="w-full border rounded-md p-2 text-xs focus:ring-2 focus:ring-blue-400 focus:border-blue-400 outline-none"
        >
          <option value="">Select</option>
          {inventory.map((item) => (
            <option key={item.id} value={item.id}>
              {item.part_no}, {item.part_name}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="quantity" className="block text-xs mb-1">Quantity</label>
        <input
          id="quantity"
          name="quantity"
          type="number"
          min={1}
          value={installedPart.quantity}
          onChange={handleChange}
          placeholder="Enter Quantity"
          className="w-full border rounded-md p-2 text-xs focus:ring-2 focus:ring-blue-400 focus:border-blue-400 outline-none"
        />
      </div>

      <button
        type="submit"
        className="w-full bg-green-600 text-white py-2 rounded-md hover:bg-green-700 transition duration-300"
        disabled={loading}
      >
        {loading ? <CircularProgress size={18} sx={{ color: "#fff" }} /> : "Install Part"}
      </button>
    </form>
  );
};

export default InstalledPartForm;