import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { NavbarContext } from "../../Context/NavbarContext";
import { PermissionContext } from "../../Context/PermissionsContext";
import { 
  FaUser, 
  FaBell, 
  FaSun, 
  FaMoon,
  FaCog,
  FaQuestionCircle,
  FaSignOutAlt,
  FaEdit
} from "react-icons/fa";

const Navbar = ({ theme, setTheme }) => {
  const user = sessionStorage.getItem("user");
  const [perms] = useContext(PermissionContext);
  const [navState] = useContext(NavbarContext);
  const [activeTab, setActiveTab] = useState("");
  const [openUserLogo, setOpenUserLogo] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const tabs = [
      "", // 0 index unused
      "Dashboard",
      "Assets",
      "Maintenance",
      "Lease",
      "Telematics",
      "Operators",
      "Settings",
      "Logistics",
      "Error",
      "Booking",
      "Inventory"
    ];
    setActiveTab(navState >= 1 && navState < tabs.length ? tabs[navState] : "");
  }, [navState]);

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "" : "dark";
    setTheme(newTheme);
    if (newTheme) {
      sessionStorage.setItem("theme", "dark");
    } else {
      sessionStorage.removeItem("theme");
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <nav className="fixed top-0 left-0 right-0 h-16 bg-white dark:bg-gray-900 shadow-md z-50 flex items-center justify-between px-6">
      {/* Left Section - Logo and Active Tab */}
      <div className="flex items-center space-x-8">
        <div className="flex items-center">
          <img src={logo} alt="Company Logo" className="h-8 w-auto" />
          <span className="ml-2 text-xl font-semibold text-gray-800 dark:text-white">
            Asset<span className="text-green-2">Tracker</span>
          </span>
        </div>
        
        {/* {activeTab && (
          <div className="hidden md:block">
            <span className="text-sm font-medium text-green-600 dark:text-green-400 border-b-2 border-green-600 pb-1">
              {activeTab}
            </span>
          </div>
        )} */}
      </div>

      {/* Right Section - Controls */}
      <div className="flex items-center space-x-4">
        {/* Theme Toggle */}
        {/* <button
          onClick={toggleTheme}
          className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          aria-label="Toggle theme"
        >
          {theme === "dark" ? (
            <FaSun className="text-yellow-400" size={18} />
          ) : (
            <FaMoon className="text-gray-600" size={18} />
          )}
        </button> */}

        {/* Notifications */}
        <div className="relative">
          <button
            onClick={() => setShowNotifications(!showNotifications)}
            className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors relative"
            aria-label="Notifications"
          >
            <FaBell className="text-gray-600 dark:text-gray-300" size={18} />
            <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-500"></span>
          </button>
          
          {showNotifications && (
            <>
              <div className="absolute right-0 mt-2 w-72 bg-white dark:bg-gray-800 rounded-md shadow-lg py-1 z-50">
                <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
                  <p className="text-sm font-medium text-gray-800 dark:text-white">
                    Notifications
                  </p>
                </div>
                <div className="px-4 py-3 text-center text-sm text-gray-500 dark:text-gray-400">
                  No new notifications
                </div>
              </div>
              <div 
                className="fixed inset-0 z-40" 
                onClick={() => setShowNotifications(false)}
              />
            </>
          )}
        </div>

        {/* User Profile (Maintaining your existing dropdown structure) */}
        <div className="relative">
          <button
            onClick={() => setOpenUserLogo(!openUserLogo)}
            className="flex items-center space-x-2 focus:outline-none"
            aria-label="User menu"
          >
            <div className="h-8 w-8 rounded-full bg-green-100 dark:bg-green-900 flex items-center justify-center">
              <FaUser className="text-green-600 dark:text-green-300" size={14} />
            </div>
            <span className="hidden md:inline text-sm font-medium text-gray-700 dark:text-gray-200">
              {user}
            </span>
          </button>

          {openUserLogo && (
            <>
              <div
                className="absolute w-fit p-4 z-[9999] top-14 right-0 bg-white dark:bg-dark-8 dark:text-white shadow-lg rounded-md overflow-hidden transition-all duration-200 ease-out"
              >
                {/* User Profile Section */}
                <div className="px-4 py-3 bg-gray-50 dark:bg-dark-8">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="h-9 w-9 rounded-full bg-green-100 flex items-center justify-center">
                        <FaUser className="h-5 w-5" />
                      </div>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium">{user}</p>
                      <p className="text-xs">
                        {perms.indexOf("ADMIN.ALL") !== -1 ? "Administrator" : "User"}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Menu Items */}
                <div className="py-1 dark:text-white">
                  <Link
                    to="#"
                    className="flex items-center px-4 py-2 text-sm hover:bg-green-50 hover:text-green-600 dark:hover:text-white dark:hover:bg-dark-6 transition-colors duration-150"
                  >
                    <FaUser className="mr-3" size={14} />
                    Position
                  </Link>

                  <Link
                    to="#"
                    className="flex items-center px-4 py-2 text-sm hover:bg-green-50 dark:hover:bg-dark-6 hover:text-green-600 dark:text-white transition-colors duration-150"
                  >
                    <FaQuestionCircle className="mr-3" size={14} />
                    Help Center
                  </Link>

                  {perms.indexOf("ADMIN.ALL") !== -1 && (
                    <Link
                      to="/settings"
                      className="flex items-center px-4 py-2 text-sm hover:bg-green-50 dark:hover:bg-dark-6 dark:hover:text-white hover:text-green-600 transition-colors duration-150"
                    >
                      <FaCog className="mr-3" size={14} />
                      Settings
                    </Link>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="px-4 py-3 dark:bg-dark-8 bg-gray-50 flex justify-between gap-2">
                  <button
                    onClick={() => navigate("/change-password")}
                    className="flex-1 px-3 py-1 bg-green-2 text-white text-sm rounded-md"
                  >
                    
                    Edit
                  </button>
                  <button
                    onClick={handleLogout}
                    className="flex-1 px-3 py-1 bg-white text-red-1 border border-red-1 rounded-md text-sm"
                  >
                    
                    Logout
                  </button>
                </div>
              </div>

              {/* Overlay */}
              <div
                className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm z-40"
                onClick={() => setOpenUserLogo(false)}
              />
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;