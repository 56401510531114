import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import { setBaseUrl } from "../../../config";
import axios from "axios";
import MaintenanceTable from "../../tables/MaintenanceTable/MaintenanceTable";
import MaintenanceTab from "./MaintenanceTab";
import { useNavigate, useParams } from "react-router-dom";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import { LoaderContext } from "../../../Context/LoaderContext";
import Loader from "../../Loader";
import LeaseTab from "./LeaseTab";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { PermissionContext } from "../../../Context/PermissionsContext";
import Documents from "../../InnerTabs/Documents";
import DeviceEvents from "../Telematics/DeviceEvents";
import Timeline from "../../Timeline";
import { NoImage } from "./Tabs/NoImage";
import AssetDashboards from "./Tabs/AssetDashboards";
import CommercialDetails from "./Tabs/CommercialDetails";
import PhotoData from "./Tabs/PhotoData";
import AssetCommercial from "./Tabs/AssetCommercial";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssetHeader from "./Tabs/AssetHeader";
import TimeLineDuration from "./TimeLineDuration.jsx";
import PhotoDataTab from "./bottomTabs/PhotoDataTab.jsx";
import RemittenceTable from "./Tabs/RemittenceTable.jsx";
import UserLogData from "./bottomTabs/UserLogData.jsx";

function AssetDetails({ setShowCurrentTab }) {
  const [perms] = useContext(PermissionContext);
  const [loader, setLoader] = useContext(LoaderContext);
  const [assetDetails, setAssetBasicDetails] = useState({});
  const [commercialDetails, setcommercialDetails] = useState({});
  const [innerTab, setInnerTab] = useState(1);
  // Timeline Duration Reference

  const [imageUrl, setImageUrl] = useState([]);
  const [documentUrl, setDocumentUrl] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [commercialDetailsExist, setcommercialDetailsExist] = useState(false);
  const [epocToHumanDate, setEpocToHumanDate] = useState("");
  const [reload, setReload] = useState(false);
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [revenueView, setRevenueView] = useState(null);
  const [error, setError] = useState(null)
  // console.log(sessionStorage.getItem("currentTab"))
  let currentTab = sessionStorage.getItem("currentTab")
  console.log(currentTab)
  
  useEffect(() => {
    const currentTab = sessionStorage.getItem("currentTab");

    if (currentTab === "5") {
      setRevenueView("revenue");
    } else {
      setRevenueView(null); // Reset to null if currentTab is not 5
    }
  }, [innerTab]);
  
  // console.log(revenueView)
  const getAssetDetails = async () => {
    // console.log(id);
   

    try {
      setLoader(true);
      setLoading(true);
      const response = await fetch(`${setBaseUrl}/asset/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
          section: revenueView,
        },
      });

      const data = await response.json()
      // console.log(data);
      setDeviceId(data.device_id);

      if (revenueView && response.status === 200) {
        setLoading(false);
      }
      setEpocToHumanDate((prev) => {
        let humanDateFormat = new Date(0);
        humanDateFormat.setUTCSeconds(data.created_at);
        return humanDateFormat;
      });
      if (data.commercial_detail !== undefined) {
        let lenOfCommDetail = Object.keys(data.commercial_detail).length;
        // if commercial details exist in data then we set commercial details state and set commercialDetailsExist to true
        if (lenOfCommDetail > 0) {
          setcommercialDetails(data.commercial_detail);
          setcommercialDetailsExist(true);
        }
      }
      // console.log(data?.photo_data[0]?.image_uri);
      setAssetBasicDetails(data);
      setImageUrl(data?.photo_data);
      setDocumentUrl(data?.attachment_data);
    } catch (error) {
      console.error(error);
      // setError(error)
      // setLoading(false)
      // alert("There having some issue please reload!!")
    } finally {
      setLoader(false);
    }
  };

  // console.log(commercialDetails[0].total_landed_cost);

  const handleCurrentTab = (tab) => {
    sessionStorage.setItem("currentTab", tab);
    setInnerTab(tab);
  };

  useEffect(() => {
    // console.log(typeof tab);
    if (tab == "true") {
      setInnerTab(1);
    } else {
      let getCurrentTabName = sessionStorage.getItem("currentTab");
      if (getCurrentTabName == 2) {
        setInnerTab(2);
      } else if (getCurrentTabName == 3) {
        setInnerTab(3);
      } else if (getCurrentTabName == 4) {
        setInnerTab(4);
      } else if (getCurrentTabName == 5) {
        setInnerTab(5);
      } else if (getCurrentTabName == 6) {
        setInnerTab(6);
      } else {
        setInnerTab(1);
      }
    }
    getAssetDetails();
  }, [innerTab, reload]);

  const [showMachine, setShowMachine] = useState(false);
  const [bottomTab, setBottomTab] = useState(1); // Default tab
  const { bottom } = useParams();

  const handleBottomTab = (tab) => {
    setBottomTab(tab);
    sessionStorage.setItem("bottomTab", tab);
  };

  // useEffect to initialize the tab state based on session storage or URL params
  useEffect(() => {
    let storedTab = sessionStorage.getItem("bottomTab");

    if (bottom === "true") {
      setBottomTab(1); // Override with tab 1 if bottom is true
    } else if (storedTab) {
      setBottomTab(Number(storedTab)); // Set tab based on session storage
    }
  }, [bottom]);

  // console.log(imageUrl)

  let imageArray = [];
  if(imageUrl) {

    for (let i = 0; i < imageUrl.length; i++) {
      imageArray.push(imageUrl[i]);
    }
  }

  // console.log(imageArray)

  return (
    <>
      <div className="">
        {/* Next Item  */}
        <AssetHeader assetDetails={assetDetails} />
        <div className="  bg-white  rounded-2xl">
          <div
            class="nav-tabs flex flex-col mx-7 
            dark:bg-slate-900  sm:flex-row  justify-center lg:justify-start text-xs"
          >
            <span
              onClick={() => handleCurrentTab(1)}
              className={`asset-details-tab py-2 sm:mr-8 cursor-pointer   hover:scale-105  transition-transform duration-300  dark:text-white 
                ${innerTab === 1 ? "assetDetailsActive" : ""}`}
            >
              Dashboard
            </span>
            {perms.indexOf("ADMIN.ALL") > -1 ? (
              <>
                <span
                  onClick={() => handleCurrentTab(2)}
                  className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white
                 ${innerTab === 2 ? "assetDetailsActive" : ""}`}
                >
                  Commercial Details
                </span>
              </>
            ) : (
              ""
            )}

            <span
              onClick={() => handleCurrentTab(3)}
              className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                innerTab === 3 ? "assetDetailsActive" : ""
              }`}
            >
              Maintenance
            </span>
            <span
              onClick={() => handleCurrentTab(4)}
              className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                innerTab === 4 ? "assetDetailsActive" : ""
              }`}
            >
              Lease
            </span>

            {perms.indexOf("ADMIN.ALL") > -1 ? (
              <>
                {commercialDetails.length > 0 && commercialDetails !== null ? (
                  <>
                    <span
                      onClick={() => {
                        handleCurrentTab(5);
                        setRevenueView("revenue");
                      }}
                      className={`asset-details-tab py-2 sm:mr-8 cursor-pointer  hover:scale-105  transition-transform duration-300 dark:text-white ${
                        innerTab === 5 ? "assetDetailsActive" : ""
                      }`}
                    >
                      Revenue
                    </span>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>

          {loader ? (
            <Loader />
          ) : (
            <>
              <div className=" mt-5">
                {/* tab 1 */}
                {innerTab === 1 && (
                  <>
                    <>
                      {/* Asset Details Data */}

                      {/* Photos Data */}
                      <div className="flex justify-between gap-5 flex-col md:flex-row ">
                        <CommercialDetails
                          assetDetails={assetDetails}
                          imageUrl={imageUrl}
                          NoImage={NoImage}
                        />

                        <div className="flex flex-col ">
                          <DeviceEvents devId={deviceId} />
                          {deviceId && (
                            <>
                              <Link
                                to="timeline"
                                smooth
                                duration={500}
                                className=""
                              >
                                <Timeline
                                  devId={deviceId}
                                  setShowMachine={setShowMachine}
                                  handleBottomTab={handleBottomTab}
                                />
                              </Link>
                            </>
                          )}
                        </div>
                      </div>

                      {/* Bottom Tabs */}
                      <div className="flex items-center text-xs pl-2 pb-20">
                        <Link to="photos">
                          <button
                            className={`border px-4 py-1 ${
                              bottomTab === 1 ? "bg-[#8a8989] text-white" : ""
                            }`}
                            onClick={() => handleBottomTab(1)}
                          >
                            Photos & Videos
                          </button>
                        </Link>
                        <button
                          className={`border px-4 py-1 ${
                            bottomTab === 2 ? "bg-[#8a8989] text-white" : ""
                          }`}
                          onClick={() => handleBottomTab(2)}
                        >
                          Documents
                        </button>
                        <button
                          className={`border px-4 py-1 ${
                            bottomTab === 4 ? "bg-[#8a8989] text-white" : ""
                          }`}
                          onClick={() => {
                            handleBottomTab(4);
                            setShowMachine(true);
                          }}
                        >
                          Machine Runtime Data
                        </button>
                        <button
                          className={`border px-4 py-1 ${
                            bottomTab === 3 ? "bg-[#8a8989] text-white" : ""
                          }`}
                          onClick={() => handleBottomTab(3)}
                        >
                          Log Data
                        </button>
                      </div>
                      {bottomTab === 1 && (
                        <>
                          <div id="photos">
                            <PhotoDataTab imageArray={imageArray} />
                          </div>
                        </>
                      )}

                      {bottomTab === 2 && (
                        <>
                          <Documents documentUrl={documentUrl} />
                          UserLogData
                        </>
                      )}

                      {bottomTab === 3 && (
                        <>
                          <UserLogData id={assetDetails.id} />
                        </>
                      )}

                      {bottomTab === 4 && deviceId && showMachine && (
                        <>
                          <div id="timeline">
                            <TimeLineDuration
                              devId={deviceId}
                              setShowMachine={setShowMachine}
                            />
                          </div>
                        </>
                      )}
                    </>
                  </>
                )}

                {/* tab 2 */}
                {perms.indexOf("ADMIN.ALL") > -1 ? (
                  <>
                    {innerTab === 2 && (
                      <>
                        <div
                          id="assetD-dashboard"
                          class="assetD-tabs flex flex-col justify-between space-y-5 mt-4 mb-16 "
                        >
                          {/*Left column  */}

                          <AssetCommercial
                            commercialDetails={commercialDetails}
                            epocToHumanDate={epocToHumanDate}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}

                {/* tab 3 */}
                {innerTab === 3 && (
                  <div id="assetD-maintenance" class="assetD-tabs mt-4">
                    <div className="text-right dark:text-white">
                      <NewAssetBtn tabName="maintenance" />
                    </div>
                    <MaintenanceTab asset_id={id} />
                  </div>
                )}

                {/* tab 4 */}
                {innerTab === 4 && (
                  <div
                    id="assetD-maintenance"
                    className="text-black dark:text-white assetD-tabs"
                  >
                    <div className="text-right">
                      <NewAssetBtn tabName="lease" />
                    </div>
                    <LeaseTab asset_id={id} />
                  </div>
                )}

                { commercialDetails !== null  ? (
                  <>
                    {perms.indexOf("ADMIN.ALL") > -1 ? (
                      <>
                        {innerTab === 5 && (
                          <>
                            {loading ? (
                              "loading..."
                            ) : (
                              <>
                                {" "}
                                <AssetDashboards
                                  commercialDetails={commercialDetails[0]}
                                />
                                { assetDetails?.revnew_data && (
                                  <RemittenceTable
                                    revnew_data={assetDetails.revnew_data}
                                    asset_sold_date={
                                      assetDetails.asset_sold_date
                                    }
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ): ''}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex items-end justify-end z-[9999] space-x-4 h-12 border bg-white fixed bottom-0 pb-2 left-0 w-screen">
        <div className="flex items-center gap-2 mr-6">
          {(perms.indexOf("ADMIN.ALL") > -1 ||
            perms.indexOf("ASSETS.ALL") > -1 ||
            perms.indexOf("ASSETS.CRU") > -1) && (
            <>
              <button
                className="bg-light-1 text-white text-xs  w-[65px] h-7   rounded
                  "
                onClick={() => navigate("/assets/updateAsset/" + id)}
              >
                Update
              </button>
              <button
                className="border text-light-1 border-light-1 dark:text-white  text-xs  w-[65px] h-7   rounded
                  "
                onClick={() => setReload((prev) => !prev)}
              >
                Reload
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AssetDetails;
