// src/utils/sortUtils.js

export const sortAssetDetails = (assetDetails, sort, sortOrder) => {
    return [...assetDetails].sort((a, b) => {
      if (!sort) return 0; // No sorting if sort is null
  
      const aValue = a[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || ""; // Convert the sort string to match the object key
      const bValue = b[sort.toLowerCase().replace(" & ", "_").replace(" ", "_")] || "";
  
      if (aValue < bValue) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
  };
  
  export const toggleSortOrder = (prevOrder) => {
    return prevOrder === "asc" ? "desc" : "asc";
  };
  