import React, { useEffect, useState } from "react";
import Select from "react-select";
import { setBaseUrl } from "../../../config";

const NewForm = ({
  title,
  type,
  keyValueType,
  newLogistic,
  setNewLogistic,
  RSO_No,
  getRsoId,
  dataFetched,
  setDataFetched,
}) => {
  // const [dataFetched, setDataFetched] = useState(false);

  const [filteredOptions, setFilteredOptions] = useState(RSO_No);
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      {title === "Mob Demob State" ? (
        <>
          <div className="flex justify-between items-center   text-[10px]">
            <h1 className="">{title}</h1>
            <select
              className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
              onChange={(e) => {
                setNewLogistic({
                  ...newLogistic,
                  [keyValueType]: e.target.value,
                });
              }}
              value={newLogistic[keyValueType]}
              // onClick={() => getRsoId()}
            >
              <option value="mob">Select</option>
              <option value="mob">Mob</option>
              <option value="demob">Demob</option>
            </select>
          </div>
        </>
      ) : title === "Select Multiple RSO" ? (
        <>
          <div className="flex justify-between items-center text-[10px]">
            <h1 className="">{title}</h1>

            <Select
              isMulti
              name="select_multiple_rso"
              options={filteredOptions}
              openMenuOnClick={false}
              onMenuOpen={() => {
                if (!dataFetched) {
                  getRsoId();
                  setDataFetched(true);
                }
              }}
              onInputChange={(inputValue) => {
                setSearchTerm(inputValue);

                if (inputValue.length >= 1) {
                  const filtered = RSO_No.filter((rso) =>
                    rso?.label?.toLowerCase().includes(inputValue.toLowerCase())
                  );
                  // console.log(filtered); // Debug filtered options
                  setFilteredOptions(filtered);
                } else {
                  setFilteredOptions(RSO_No);
                }
              }}
              value={filteredOptions.filter((option) =>
                newLogistic.select_multiple_rso?.includes(option.value)
              )} // Ensure correct value structure
              onChange={(selectedOptions) => {
                console.log(selectedOptions); // Debug selected options
                const rsoValues = selectedOptions.map((option) => option.value);
                setNewLogistic({
                  ...newLogistic,
                  select_multiple_rso: rsoValues,
                });
              }}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "350px",
                  fontSize: 12,
                }),
                control: (provided, state) => ({
                  ...provided,
                  width: "350px",
                  height: "30px",
                  border:
                    state.isFocused || state.isHovered
                      ? "none"
                      : "1px solid transparent",
                  borderBottom: "1px solid #D1D5DB",
                  boxShadow: state.isFocused
                    ? "0 0 0 0.2px rgba(156, 163, 175, 0.25)"
                    : null,
                  outline: "none",
                  borderRadius: 0,
                  backgroundColor: "#EEE",
                }),
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center text-[10px]">
            <h1 className="">{title}</h1>
            <input
              type={type || "text"}
              placeholder={title}
              value={newLogistic[keyValueType]}
              className="w-[350px] text-[10px] focus:border-0 focus:outline-none focus:ring-0 bg-light-4 rounded border-none h-8  "
              onChange={(e) => {
                setNewLogistic({
                  ...newLogistic,
                  [keyValueType]: e.target.value,
                });
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default NewForm;
