import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { setBaseUrl } from "../../config";

const Logistics = () => {
  const [logisticsData, setLogisticsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getLogistics = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${setBaseUrl}/lease/get-logistic`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      const data = await res.json();
      setLogisticsData(data);
    } catch (error) {
      console.error("Error fetching logistics data:", error);
      alert("Something error please reach sometimes later or reload!!!!")
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLogistics();
  }, []);

  // console.log(logisticsData)
  return (
    <Layout>
      <h1 className="text-base font-bold text-gray-700 mb-4">Logistics Data</h1>
      <Link to={"/new-logistics"}>
        <button className="bg-light-1 text-xs p-1 text-white">New</button>
      </Link>

      <div className="overflow-x-auto mt-1 text-xs">
        <table className="min-w-full border-collapse border border-gray-300 text-xs">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 border border-gray-300">RSO Number</th>
              <th className="p-2 border border-gray-300">Asset</th>
              <th className="p-2 border border-gray-300">Date of Dispatch</th>
              <th className="p-2 border border-gray-300">Mob/Demob</th>
              <th className="p-2 border border-gray-300">From Location</th>
              <th className="p-2 border border-gray-300">Transporter Name</th>
              <th className="p-2 border border-gray-300">
                Transporter Bill No
              </th>
              <th className="p-2 border border-gray-300">
                Transporter Bill Date
              </th>
              <th className="p-2 border border-gray-300">E-Way Bill Number</th>
              <th className="p-2 border border-gray-300">Total KM</th>
              <th className="p-2 border border-gray-300">Bill Amount</th>
              <th className="p-2 border border-gray-300">
                Transportation Cost
              </th>
              <th className="p-2 border border-gray-300">
                Amount Paid by Customer
              </th>
              <th className="p-2 border border-gray-300">Rate/km</th>
              <th className="p-2 border border-gray-300">Arrival Date</th>
              <th className="p-2 border border-gray-300">Destination</th>
              <th className="p-2 border border-gray-300">DC No.</th>
              <th className="p-2 border border-gray-300">DC Date</th>
              <th className="p-2 border border-gray-300">Truck No</th>
              <th className="p-2 border border-gray-300">Truck Ft</th>
              <th className="p-2 border border-gray-300">MOB/Invoice</th>
              <th className="p-2 border border-gray-300">De-MOB/Invoice</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="11" className="text-center p-4">
                  Loading...
                </td>
              </tr>
            ) : (
              logisticsData.length > 0 &&
              logisticsData?.map((item, index) => (
                <React.Fragment key={index}>
                  {item.LogisticMap.map((logisticItem, logisticIndex) => (
                    <tr
                    key={logisticIndex}
                    className="hover:bg-gray-50"
                    onClick={() =>
                      navigate(`/update-logistics/${item.id}`)
                    }
                    >
                      
                      <td className="p-2 border border-gray-300">
                        {logisticItem.odoo_order_id}
                      </td>
                      <td className="p-2 border border-gray-300">
                        {logisticItem.asset_no}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.date_of_dispatch}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.mob_demob_state}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.origin}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.transporter_bill_no}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.transporter_bill_date}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.courier_company}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.awb_number}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.total_km}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        ${item.bill_amount}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        ${item.transportation_cost}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        ${item.amount_paid_by_customer}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.rate_per_km}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.arrival_date}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.destination}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.dc_no}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.dc_date}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.truck_no}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.truck_ft}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.mob_as_per_invoice}
                      </td>
                      <td
                        className="p-2 border border-gray-300"
                        rowSpan={item.LogisticMap.length}
                      >
                        {item.demob_as_per_invoice}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Logistics;
