import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setBaseUrl } from "../config";
import { NavbarContext } from "../Context/NavbarContext";
import { PermissionContext } from "../Context/PermissionsContext";
import OuterLoader from "../pages/OuterLoder";
import useLocalstorage from "../utils/useLocalstorage";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";
import {useToast} from "../Context/ToastContext"
const Login = () => {
  const [, setNavState] = useContext(NavbarContext);
  const [, setPerms] = useContext(PermissionContext);
  const { setValues, getValues } = useLocalstorage();
  const navigate = useNavigate();
  const emailInputRef = useRef(null);

  const [data, setData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const {addToast} = useToast()

  useEffect(() => {
    setNavState(1);
    checkIfLoggedIn();
    focusEmailInput();
  }, []);

  const focusEmailInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  const checkIfLoggedIn = () => {
    const isLoggedIn = sessionStorage.getItem("token");
    if (isLoggedIn) {
      navigate("/assets");
    }
  };

  const setPermissions = async (token_id) => {
    try {
      const { data } = await axios.get(
        `${setBaseUrl}/company/view-permissions`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": token_id,
          },
        }
      );

      if (data.permissions) {
        setValues(data.permissions);
        setPerms(data.permissions);
      }

      return data.message !== "valid token is missing";
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const LoginFunction = async () => {
    try {
      setLoader(true);
      const res = await axios.post(`${setBaseUrl}/company/login`, {
        email: data.email,
        password: data.password,
      });

      const getPermsSuccess = await setPermissions(res.data.token);
      if (!getPermsSuccess) return;

      const isOldUser = res.data.is_password_changed_once


      if (isOldUser === false || isOldUser === null) {
        sessionStorage.setItem("user", data.email);
        navigate("/change-password-new-user");
        return;
      }

      sessionStorage.setItem("token", res.data.token);
      sessionStorage.setItem("user", data.email);
      sessionStorage.setItem("asset_tracker_logged_in", "true");

      const permissions = getValues();
      setLoader(false);
      navigateToPage(permissions);
    } catch (error) {
      setLoader(false);
      addToast("Please Enter Valid Email and Password!", "error");
    }
  };

  const navigateToPage = (permissions) => {
    try {
      if (permissions.includes("ADMIN.ALL")) {
        addToast("User Successfully Logged In", "success");
        navigate("/assets");
      } else if (
        ["ASSETS.ALL", "ASSETS.VIEW", "ASSETS.CRU"].some((perm) =>
          permissions.includes(perm)
        )
      ) {
        addToast("User Successfully Logged In", "success");
        navigate("/assets");
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error("Error navigating to page:", err);
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  if (loader) return <OuterLoader />;

  return (
  
    <Layout>
      <div className="flex items-center justify-center flex-col">

    {/* Form */}
        <form
          className="text-black bg-white w-[389.42px] h-[430.66px] rounded-[16px] border-[1.5px] border-light-"
          onSubmit={(e) => {
            e.preventDefault();
            if (
              data.email &&
              data.password &&
              isValidEmail(data.email)
       
            ) {
              LoginFunction();
            }
          }}
        >
          {/* Form Header */}
          <div className="flex flex-col  text-left items-start border-b-[1.5px] p-6 border-light">

            <header className="text-[32px] font-bold">Login</header>
            <span className="font-normal text-[12px]">Enter your email below to login to your account</span>
          </div>

          {/* Input Form */}
          <div className="flex flex-col p-10">

            {/* Email Input */}
            <div className="my-4 ">
              <label htmlFor="" className="text-[13.09px] font-extrabold">Email</label>
              <input
              title="Enter email address"
                ref={emailInputRef}
                type="email"
                id="email"
                placeholder="example@company.com"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                className={`w-[314.81px] h-[40px] px-4 py-2 text-sm border rounded-lg focus:outline-none ${data.email && !isValidEmail(data.email)
                  ? "border-red-500"
                  : "border-gray-300"
                  }`}
              />
              {data.email && !isValidEmail(data.email) && (
                <p className="text-red-500 text-xs mt-1">
                  Invalid email format
                </p>
              )}
            </div>

            {/* Password */}
            <div className="mb-4 relative text-xs">
              <span className="flex justify-between mb-0.5">
            <label htmlFor="" className="text-[13.09px] font-extrabold">Password</label>
            <span className="underline" onClick={() => navigate('/forgot-password')}>Forgot your password? </span>
              </span>
              <input
              title="Enter password"
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="password"
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                className={`w-[314.81px] h-[40px] px-4 py-2 text-sm border rounded-lg focus:outline-none ${data.password
                  //  && !isValidPassword(data.password)
                  ? "border-red-500"
                  : "border-gray-300"
                  }`}
              />
              {data.password && (
                <div
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer items-center mt-3 text-dark-6"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <RemoveRedEyeOutlinedIcon />
                  )}
                </div>
              )}
            </div>


            {/* Submit Button */}
            <button
              type="submit"
              className={`w-full py-3 rounded-[8px] mb-3 font-normal text-white text-sm transition-all duration-300 ${data.email &&
                data.password &&
                isValidEmail(data.email)
                // &&            isValidPassword(data.password)
                ? "bg-green-2 hover:bg-green-3"
                : "bg-dark-6 cursor-not-allowed"
                }`}
              disabled={
                !(
                  data.email &&
                  data.password &&
                  isValidEmail(data.email)
                  // &&                  isValidPassword(data.password)
                )
              }
            >
              {loader ? <span className="animate-spin">⏳</span> : "Sign In"}
            </button>
           
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Login;
