import React from 'react'
import { capitalizeFirstLetter, formatDate, formatMaintenanceType } from '../../customFunctions/FormatDate';


const ServiceHeader = ({ maintenanceData }) => {

    const today = new Date();

    // Remove the time portion from today's date to ensure accurate comparison
    const todayDateOnly = new Date(today.setHours(0, 0, 0, 0));

    // Check if complaintDate exists, remove time portion for comparison
    const complaintDate = maintenanceData?.issue_date ? new Date(maintenanceData?.issue_date) : null;
    const complaintDateOnly = complaintDate ? new Date(complaintDate.setHours(0, 0, 0, 0)) : null;

    // Ensure complaintDate is valid before calculating breakdown
    const breakdown = complaintDateOnly && !isNaN(complaintDateOnly)
        ? Math.floor((todayDateOnly - complaintDateOnly) / (1000 * 60 * 60 * 24))
        : 0;
    // Now handle the breakdown label logic
    const breakdownLabel = breakdown > 0 ? `${breakdown} days` : breakdown === 0 ? "Today" : "Not Filled";



    const HeaderData = [
        {
            title: 'Ticket ID',
            label: <>
                {maintenanceData.ticket_no}
            </>,

        },
        {
            title: 'Breakdown Title',
            label: maintenanceData.complaint
        },
        {
            title: 'Date of Complain',
            label: maintenanceData.compaint_date && formatDate(maintenanceData?.compaint_date) || "Not Filled"
        },
        {
            title: 'Breakdown Date',
            label: <span className='flex justify-between'>
                <span>{maintenanceData?.issue_date ? formatDate(maintenanceData?.issue_date) : "Not Filled"}</span>
                <span>{breakdownLabel}</span>
            </span>,
        },

        {
            title: 'Breakdown Type',
            label: capitalizeFirstLetter(maintenanceData?.complaint_type) || "Not Filled"
        },
        {
            title: 'Service Category',
            label: maintenanceData?.types !== "types" && formatMaintenanceType(maintenanceData?.types),
        },
        {
            title: 'Deadline',
            label: maintenanceData?.ready_date && formatDate(maintenanceData?.ready_date )|| "Not Filled"
        },
        {
            title: 'Priority',
            label: capitalizeFirstLetter(maintenanceData?.priority) || "Not Filled"
        },
     
   
    ]

    return (
        <div>
            {/* Maintenance Header */}
            <div className="grid grid-cols-5  gap-4 ">
                {HeaderData.reduce((acc, _, index, array) => {
                    if (index % 2 === 0) {
                        acc.push(array.slice(index, index + 2));
                    }
                    return acc;
                }, []).map((pair, index) => (
                    <div key={index} className="bg-white  rounded-lg p-2 text-xs border">
                        {pair.map((item, idx) => (
                            <div key={idx} className="mb-2">
                                <p className="text-xs text-gray-500">{item.title}</p>
                                <p className={`font-semibold ${item.highlight || "text-gray-800"}`}>
                                    {item.label}
                                </p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ServiceHeader