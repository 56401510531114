import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { useNavigate } from 'react-router-dom'
import { setBaseUrl } from '../../config'
import { toast } from 'react-toastify'

const Inventory = () => {
  // If not logged in, redirect to login page
  const navigate = useNavigate()
  useEffect(() => {
      if (sessionStorage.getItem("asset_tracker_logged_in") !== "true") {
        navigate("/login");
      }
     
    }, []);

  const [inventory, setInventory] = useState([])
  const [loading, setLoading] = useState(false)

  const getInventory = async () => {
    
    try {
      const res = await fetch(`${setBaseUrl}/maintenance/get-all-inventory-part`, {
        headers: {
          'x-access-tokens': sessionStorage.getItem('token')
        }
      })
    
      const data = await res.json()
      if(res.status === 200) {
        setInventory(data)

      }
    
    } catch (error) {
      console.error(error)
      toast.error('Failed to get inventory')
    }
    

  }

useEffect(() => {
  getInventory()
}, [])

  return (
    <Layout>
      <h3 className="text-lg font-semibold text-gray-800">
      Inventory
      </h3>
  
      <table className="min-w-full mt-4 bg-white border border-gray-300 rounded-lg shadow-lg">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="p-3 text-left">Part ID</th>
            <th className="p-3 text-left">Part Number</th>
            <th className="p-3 text-left">Part Name</th>
            <th className="p-3 text-left">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {inventory.length > 0 ? inventory?.map(item => (
            <tr key={item.id}>
              <td className="p-3 text-left">{item.id}</td>
              <td className="p-3 text-left">{item.part_no}</td>
              <td className="p-3 text-left">{item.part_name}</td>
              <td className="p-3 text-left">{item.quantity}</td>
            </tr>
          )): <span className='px-3 py-3'>Inventory not found</span> }
        </tbody>
      </table>
    </Layout>
  )
}

export default Inventory