import React from 'react'
import { capitalizeFirstLetter, currency, formatDate } from '../../AssetDetails/Tabs/FormatDate'

const LeaseDashboard = ({leaseSpecificDetails}) => {
  return (
    <>
    

<div className="flex flex-col  md:flex-row text-xs w-[93vw]
   justify-between px-2  mt-2 dark:bg-slate-900 bg-white ">
        {/* Frame 1 */}
        <div className="w-2/6">

        <h1 className="">Custormer Details</h1>
        <div className="flex-1 borde mt-2  py-4 round-2xl ">
          <div className="p-2 flex justify-between border-b border-r border-l border-t round-t-lg h-8">
            <span className="text-gray-500">Customer PO No</span>
            <span className="block font-semibold text-xs">
                {leaseSpecificDetails?.customer_po_no === "null" ? '--': leaseSpecificDetails?.customer_po_no}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l  h-8">
            <span className="text-gray-500">Customer</span>
            <span className="block font-semibold text-xs">
                {leaseSpecificDetails?.customer}
            </span>
          </div>
         
               
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500">Status</span>
            <span className={`block font-semibold text-xs
                 ${leaseSpecificDetails?.lease_status === 'inactive'? 'text-gray-500': 
                    leaseSpecificDetails?.lease_status === "never assigned" ? "text-red-600"  : "text-green-500"}`}>
            {capitalizeFirstLetter(leaseSpecificDetails?.lease_status)}
            </span>
          </div>
           <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500">Rental Start Date</span>
            <span className="block font-semibold text-xs">
            {formatDate(leaseSpecificDetails?.rental_start_date)}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500"> Rental End Date</span>
            <span
              className={`block font-semibold text-xs`}
            >
              {formatDate(leaseSpecificDetails?.rental_end_date)}
              
            </span>
          </div>

          <div className="p-2 flex justify-between border-b border-r border-l  round-b-lg h-8">
            <span className="text-gray-500">Rental Extended End Date</span>
            <span className="block font-semibold text-xs">
              {leaseSpecificDetails?.rental_extended_date ? 
                <>
            {formatDate(leaseSpecificDetails?.rental_extended_date)}
                </> 
                : "--"
                }
            </span>
          </div>
        </div>
        </div>

        
        {/* Frame 2 */}
        <div className="w-2/6">

        <h1 className="">Pay Term</h1>
        <div className="flex-1  mt-2 py-4 round-2xl">
        <div className="p-2 flex justify-between border   h-8">
            <span className="text-gray-500">Cuerrency</span>
            <span className="block font-semibold text-xs">
            {leaseSpecificDetails?.currency}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l border-t round-t-lg h-8">
            <span className="text-gray-500">Contract Value</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.contract_value)}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l  h-8">
            <span className="text-gray-500">Transportation Charges</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.transportation_charge)}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500">Normal Amount</span>
            <span className="block font-semibold text-xs">
            ₹{(currency(leaseSpecificDetails?.normal_amount))}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500"> Overtime Amount</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.overtime_amount)}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500">  Reimbursements</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.reimbursements)}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500">  Total Claimable Amount</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.total_claimable_amount)}
            </span>
          </div>
        </div>
        </div>

{/* Frame 3 */}
        <div className="w-2/6">

        <h1 className="">Invoice Details</h1>
        <div className="flex-1 mt-2 py-4 ">
          <div className="p-2 flex justify-between border-b border-r border-l border-t  h-8">
            <span className="text-gray-500">Contract Value</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.contract_value)}
            </span>
          </div>
          {/* <div className="p-2 flex justify-between border-b border-r border-l  h-8">
            <span className="text-gray-500">Transportation Charges</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.transportation_charge)}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500">Normal Amount</span>
            <span className="block font-semibold text-xs">
            ₹{(currency(leaseSpecificDetails?.normal_amount))}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500"> Overtime Amount</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.overtime_amount)}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l   h-8">
            <span className="text-gray-500">  Reimbursements</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.reimbursements)}
            </span>
          </div>
          <div className="p-2 flex justify-between border-b border-r border-l  round-b-lg h-8">
            <span className="text-gray-500">  Total Claimable Amount</span>
            <span className="block font-semibold text-xs">
            ₹{currency(leaseSpecificDetails?.total_claimable_amount)}
            </span>
          </div> */}
        </div>
        </div>

   
     
      </div>
    </>
  )
}

export default LeaseDashboard
